import { createTheme } from '@mui/material';

export const XqTheme = createTheme({
  typography: {
    fontFamily: 'Graphik,sans-serif',
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#f7f7f7',
    },
    primary: {
      light: 'rgba(62, 182, 80, 0.38)',
      main: 'rgba(62, 182, 80, 1)', // #3eb650
      dark: 'rgba(62, 182, 80, 0.74)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(196, 145, 226, 1)',
      main: 'rgba(169, 77, 224, 1)',
      dark: 'rgba(169, 77, 224, 1)',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: 'rgba(225, 83, 33, 1)',
      dark: 'rgba(225, 83, 33, 1)',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    info: {
      main: '#000',
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          background: '#D4D4D4',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: '8px 16px',
          fontWeight: 'bold',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: {
            height: 4,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '16px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'var(--panache)',
          },
          '&.Mui-selected': {
            '&:hover': {
              background: 'inherit',
            },
          },
        },
      },
    },
  },
});
