import { Document } from '@xq/domain';

import { IStorageAdapter } from '../storages';

export interface IDocumentService {
  uploadDocumentToStorage(file: File, uploadTo: string): Promise<Document>;
  deleteDocumentFromStorage(id: string): Promise<boolean>;
  getDocumentByIdFromStorage(id: string): Promise<Document>;
}

export class DocumentService implements IDocumentService {
  private static instance: DocumentService;

  private constructor(private readonly storageService: IStorageAdapter) {}

  static getInstance(storageService: IStorageAdapter) {
    if (!DocumentService.instance) {
      DocumentService.instance = new DocumentService(storageService);
    }
    return DocumentService.instance;
  }

  getDocumentByIdFromStorage(id: string) {
    return this.storageService.getById(id);
  }

  async uploadDocumentToStorage(file: File, uploadTo: string) {
    const uploadedDocument = await this.storageService.upload(file, uploadTo);

    return this.getDocumentByIdFromStorage(uploadedDocument.id);
  }

  async copyDocumentToStorage(file: Document, uploadTo: string) {
    const uploadedDocument = await this.storageService.copy(file, uploadTo);

    return this.getDocumentByIdFromStorage(uploadedDocument.id);
  }

  async deleteDocumentFromStorage(id: string) {
    return this.storageService.delete(id);
  }

  async moveDocumentFromStorage(fileId: string, destinationId: string) {
    return this.storageService.move(fileId, destinationId);
  }

  async isInSchoolStructure(fileId: string) {
    return this.storageService.isInSchoolStructure(fileId);
  }
}
