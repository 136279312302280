import { firebaseService } from '@xq/infrastructure';
import {
  GetCurrentUserGoogleClassroomsRequestDto,
  GetCurrentUserGoogleClassroomsResponseDto,
  GoogleClassroomRole,
} from '@xq/shared/data-access';
import { callWithRetry } from '@xq/shared/utils';

export class GetCurrentUserGoogleClassroomsUseCase {
  async execute(includes?: GoogleClassroomRole[]) {
    const getCurrentUserGoogleClassrooms =
      firebaseService.prepareFunctionCaller<
        GetCurrentUserGoogleClassroomsRequestDto,
        GetCurrentUserGoogleClassroomsResponseDto
      >('getCurrentUserGoogleClassrooms');

    const token = gapi.client.getToken();

    return callWithRetry(() =>
      getCurrentUserGoogleClassrooms({
        token: token.access_token,
        includes: includes,
      })
    );
  }
}
