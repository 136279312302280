import { useTranslation } from 'react-i18next';
import { LexicalComposer } from '@lexical/react/LexicalComposer';

import { Mention } from '@xq/domain';

import {
  EmojiToolbarPlugin,
  standardTextEditorConfig,
  StyledStandardEditor,
  TextEditor,
} from '../../../../TextEditor';
import { PostActivityIconButton } from '../PostActivityIconButton/PostActivityIconButton';

type CommentInputProps = {
  onAddComment: (message: string, mentions: Mention[]) => void;
  mentionSuggestions: Mention[];
  showEmoji?: boolean;
};

export const CommentInput = ({
  mentionSuggestions,
  onAddComment,
  showEmoji = false,
}: CommentInputProps) => {
  const { t } = useTranslation();

  return (
    <LexicalComposer initialConfig={standardTextEditorConfig}>
      <StyledStandardEditor data-cy="comment-input">
        {showEmoji && <EmojiToolbarPlugin />}
        <TextEditor
          mentionSuggestions={mentionSuggestions}
          placeholder={t('leave_comment')}
          CustomPlugin={
            <PostActivityIconButton
              onClick={onAddComment}
              mentionSuggestions={mentionSuggestions}
            />
          }
        />
      </StyledStandardEditor>
    </LexicalComposer>
  );
};
