import { firebaseService } from '@xq/infrastructure';
import { callWithRetry } from '@xq/shared/utils';

type DeleteAllNotificationsUseCasePayload = {
  userId: string;
};

export class DeleteAllNotificationsUseCase {
  static async execute({ userId }: DeleteAllNotificationsUseCasePayload) {
    const deleteAllNotifications = firebaseService.prepareFunctionCaller<
      DeleteAllNotificationsUseCasePayload,
      string[]
    >('deleteAllNotifications');

    return callWithRetry(() =>
      deleteAllNotifications({
        userId,
      })
    );
  }
}
