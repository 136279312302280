import { WordPressService } from '@xq/infrastructure';

export class FetchCoursesUseCase {
  wordPressService: WordPressService;

  constructor() {
    this.wordPressService = WordPressService.getInstance();
  }

  async execute() {
    return this.wordPressService.fetchCourses();
  }
}
