import { ZenDeskService } from '@xq/infrastructure';

export class UploadTicketAttachmentsUseCase {
  static async execute(files: File[]) {
    if (!files.length) return;

    return Promise.all(
      files.map(async (file) => {
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);

        return new Promise<string>((resolve, reject) => {
          reader.onload = async () => {
            const arrayBuffer = reader.result as ArrayBuffer;

            if (!arrayBuffer)
              return reject('Could not read attachment as an arrayBuffer');

            const zenDeskService = new ZenDeskService();

            try {
              const response = await zenDeskService.uploadAttachment(
                file.name,
                arrayBuffer
              );
              resolve(response.data.upload.token);
            } catch (e) {
              reject(e);
            }
          };
        });
      })
    );
  }
}
