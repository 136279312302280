export const ALL_TAGS = 'allTags';

export enum MathTagTypes {
  Math = 'Math',
  Exponential = 'Exponential',
  Linear = 'Linear',
  Quadratic = 'Quadratic',
  Cubic = 'Cubic',
  Complex = 'Complex',
  Polynomial = 'Polynomial',
  HigherOrderPolynomial = 'Higher Order Polynomial',
}
