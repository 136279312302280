import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isEmpty, uniq } from 'lodash';
import { StreamClient } from 'getstream';

import {
  computeRoles,
  getClassroomsEmails,
  getUserRole,
  UserRoles,
} from '@xq/domain';
import {
  firebaseService,
  GetStreamToken,
  loggerService,
  StreamFeedService,
} from '@xq/infrastructure';
import {
  LogoutUserUseCase,
  RefreshAuthTokenUseCase,
  SetAnalyticsUserPropertiesUseCase,
} from '@xq/usecases';
import { allEqual, callWithRetry } from '@xq/shared/utils';

import {
  useClassroomsSubscription,
  useLastActivity,
  useSchoolsSubscription,
  useUserSubscription,
} from '../hooks';
import {
  appActions,
  classroomActions,
  currentUserActions,
  fetchUsersByEmail,
  persistCurrentSchool,
  resetStore,
  selectAllClassrooms,
  selectAllSchools,
  selectCurrentUserClassroom,
  selectCurrentUserSchool,
  selectCurrentUserState,
  selectIsImpersonating,
  useAppDispatch,
  useAppSelector,
} from '../store';

export interface ApiProviderContextType {
  streamToken: string;
  feedClient: StreamClient | null;
  logout: (withNavigate?: boolean) => Promise<void>;
}

export type FbAuthUserState =
  | 'loading'
  | 'loggedIn'
  | 'loggedOut'
  | 'choseRole';

const ApiContext = createContext<ApiProviderContextType | undefined>(undefined);

export const ApiProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();

  const [streamToken, setStreamToken] = useState('');
  const [feedClient, setFeedClient] = useState<StreamClient | null>(null);

  const currentUser = useAppSelector(selectCurrentUserState);
  const classrooms = useAppSelector(selectAllClassrooms);
  const schools = useAppSelector(selectAllSchools);
  const isImpersonating = useAppSelector(selectIsImpersonating);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const currentSchool = useAppSelector(selectCurrentUserSchool);

  const { isAdmin, isStudent } = getUserRole(currentUser);

  useSchoolsSubscription({ user: currentUser });

  // todo this useMemo causes a render warning in the console fix it latter
  const roomsToSubscribeTo = useMemo(() => {
    if (!currentUser || isEmpty(currentUser)) {
      return [];
    }

    const adminClassroomsIds = isAdmin
      ? schools
          ?.map((s) => s?.classroomsIds)
          .flat()
          .filter(Boolean)
      : [];

    const ids = uniq([
      ...adminClassroomsIds,
      ...(currentUser.classroomIds || []),
    ]);

    if (!ids.length) dispatch(classroomActions.setLoading(false));

    return ids;
  }, [isAdmin, schools, currentUser?.classroomIds]);

  useLastActivity();

  useClassroomsSubscription({
    classroomIds: roomsToSubscribeTo,
    isStudent,
  });

  const { userData } = useUserSubscription({ userId: currentUser.uid });
  const fetchStreamToken = async (userId: string) => {
    const tokenResponse = (await callWithRetry(() => GetStreamToken(userId)))
      .data.token;

    if (!tokenResponse) {
      return;
    }

    const streamFeedService = StreamFeedService.getInstance(tokenResponse);

    setStreamToken(tokenResponse);
    setFeedClient(streamFeedService.client);
  };

  const logout = useCallback(
    async (withNavigate = true) => {
      try {
        await LogoutUserUseCase.execute(streamToken);
        setStreamToken('');
        setFeedClient(null);

        if (!withNavigate) return;
        window.history.pushState(
          {},
          '',
          isImpersonating ? '/demo-login' : '/login'
        );

        const impersonating = isImpersonating;

        dispatch(resetStore());

        if (impersonating) dispatch(appActions.setIsImpersonating(true));
      } catch (e) {
        loggerService.error('Error while trying to log out: ', e);
      }
    },
    [dispatch, isImpersonating, streamToken]
  );

  useEffect(() => {
    if (currentUser.uid) {
      fetchStreamToken(currentUser.uid).catch((e) => loggerService.error(e));
    }
  }, [currentUser.uid]);

  useEffect(() => {
    const usersEmail = getClassroomsEmails(classrooms);
    const promise = dispatch(fetchUsersByEmail(usersEmail));

    if (currentUser.email) {
      const { roleByClassroomId } = computeRoles(currentUser.email, classrooms);

      dispatch(currentUserActions.setRoleByClassroomId(roleByClassroomId));

      const currentRole = currentUser.currentClassroomId
        ? roleByClassroomId[currentUser.currentClassroomId]
        : null;

      if (!currentClassroom || !currentSchool || !currentRole) {
        return;
      }

      SetAnalyticsUserPropertiesUseCase.execute({
        userId: currentUser.uid,
        properties: {
          classroom: currentClassroom.name,
          school: currentSchool.name,
          role: currentRole,
        },
      });
    }

    return () => promise.abort();
  }, [
    classrooms,
    currentClassroom,
    currentSchool,
    currentUser.currentClassroomId,
    currentUser.email,
    currentUser.uid,
    isStudent,
  ]);

  const persistRoles = async (lastModifiedAt: string) => {
    if (currentUser.modifiedAt === lastModifiedAt) return;

    await RefreshAuthTokenUseCase.execute();

    const tokenResult =
      await firebaseService.auth.currentUser?.getIdTokenResult();
    const roles =
      (tokenResult?.claims?.roles as UserRoles[]) ||
      [tokenResult?.claims?.role as UserRoles].filter(Boolean);

    dispatch(currentUserActions.setRole(roles));
  };

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (userData.modifiedAt) persistRoles(userData.modifiedAt);

    const result = allEqual(
      userData.classroomIds,
      currentUser.classroomIds || []
    );

    if (!result) {
      const classroomsToRemove =
        currentUser.classroomIds?.filter(
          (c) => !userData.classroomIds.includes(c)
        ) ?? [];

      dispatch(currentUserActions.setClassroomIds(userData.classroomIds));
      dispatch(classroomActions.removeMany(classroomsToRemove));
    }
  }, [userData]);

  useEffect(() => {
    const hasUserWithNoCurrentSchool =
      currentUser.uid && !currentUser.currentSchoolId;
    const hasClassrooms = !!currentUser.classroomIds?.length;
    let schoolId;

    if (hasUserWithNoCurrentSchool && schools.length && !hasClassrooms) {
      schoolId = schools[0]?.id;
    }

    if (hasClassrooms && classrooms.length && hasUserWithNoCurrentSchool) {
      schoolId =
        schools.filter((school) => {
          return classrooms.map((cl) => cl.schoolId).includes(school.id);
        })[0]?.id || schools[0]?.id;
    }

    if (schoolId && hasUserWithNoCurrentSchool) {
      dispatch(
        persistCurrentSchool({
          userId: currentUser.uid,
          schoolId,
        })
      );
    }
  }, [
    currentUser.currentSchoolId,
    currentUser.uid,
    dispatch,
    schools,
    classrooms,
  ]);

  return (
    <ApiContext.Provider
      value={{
        streamToken,
        feedClient,
        logout,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('useApi must be used within a ApiContext');
  }

  return context;
};
