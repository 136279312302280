import { z } from 'zod';

import { FeedSlugs } from '@xq/domain';

export const DeletePostSchema = z.object({
  id: z.string(),
  feedId: z.string(),
  userId: z.string(),
  feedSlug: z.nativeEnum(FeedSlugs),
});

export type DeletePostRequestDto = z.infer<typeof DeletePostSchema>;
