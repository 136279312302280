import { createAsyncThunk } from '@reduxjs/toolkit';

import { loggerService, UserRepository } from '@xq/infrastructure';

export const fetchUsersByEmail = createAsyncThunk(
  'users/fetchUsersByEmail',
  async (emails: string[]) => {
    const userRepository = new UserRepository();

    try {
      return await userRepository.getUsersByEmail(emails);
    } catch (e) {
      loggerService.error(e);

      throw e;
    }
  }
);
