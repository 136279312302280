import { styled } from '@mui/material/styles';

export const StyledProgramSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
`;

export const StyledClassroomName = styled('div')(() => ({
  fontWeight: 'bold',
  fontSize: '20px',
  textTransform: 'uppercase',
  padding: '4px 24px 5px 0',
  marginLeft: 'auto',
}));

export const StyledSchoolName = styled('div')(() => ({
  fontWeight: 'bold',
  fontSize: '14px',
  textAlign: 'right',
  paddingRight: '24px',
}));
