import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload } from 'react-use';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { Box, FormHelperText, MenuItem, Typography } from '@mui/material';
import { mapValues } from 'lodash';

import {
  AdministrationTabs,
  classroomNameUniqueValidation,
  FormInputField,
  newClassroomActions,
  selectNewClassroomState,
  StyledButton,
  StyledFooter,
  StyledLayoutContainer,
  StyledMain,
  StyledNextButton,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  useModal,
  useNavigationWarningModal,
  selectAdministrationSchoolsWithFolders,
} from '@xq/web-components';
import { TEACHER_APP_ROUTES } from '@xq/web-config';

import styles from './create-new-classroom.module.scss';
import { CreateFormHeader } from '../../components/CreateFormHeader';

export const CreateNewClassroom = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const administrationSchools = useAppSelector(
    selectAdministrationSchoolsWithFolders
  );
  const classroomDetailsInfo = useAppSelector(selectNewClassroomState);

  const { onConfirm, dispatchModal } = useModal();

  const confirmExitFlow = async () => {
    dispatchModal({ isConfirmed: true });
    dispatch(newClassroomActions.clearNewClassroom());
    navigate(
      TEACHER_APP_ROUTES.getAdministration(AdministrationTabs.classrooms)
    );
  };

  useEffectOnce(() => {
    onConfirm.current = confirmExitFlow;
  });

  const ValidationSchema = useMemo(
    () =>
      z
        .object({
          schoolId: z.string().min(1, 'school_required'),
          name: z.string().trim().min(3, 'short_name_min_letters'),
          shortName: z
            .string()
            .trim()
            .min(1, 'short_name_min_letters')
            .max(3, 'short_name_letters'),
        })
        .partial()
        .refine(
          async (data) => {
            setIsLoading(true);
            const isValid = await classroomNameUniqueValidation(
              data.name ?? '',
              data.schoolId ?? ''
            );
            setIsLoading(false);
            return isValid;
          },
          {
            message: t('name_already_exists'),
            path: ['name'],
          }
        ),
    []
  );

  const getInitialSchoolId = () => {
    if (administrationSchools.length > 1) {
      return classroomDetailsInfo.schoolId;
    }

    if (administrationSchools.length) {
      return administrationSchools[0].id;
    }

    return '';
  };

  const formik = useFormik({
    initialValues: {
      schoolId: getInitialSchoolId(),
      name: classroomDetailsInfo.name.trim(),
      shortName: classroomDetailsInfo.shortName.trim(),
    },
    validationSchema: toFormikValidationSchema(ValidationSchema),
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(
        newClassroomActions.setNewClassroom(
          mapValues(values, (v: string) => v?.trim())
        )
      );
      navigate(TEACHER_APP_ROUTES.getAddingTeachersToNewClassroom());
    },
  });

  const pageHasEmptyField =
    !formik.values.name || !formik.values.shortName || !formik.values.schoolId;

  const disabled = pageHasEmptyField || !!Object.keys(formik.errors).length;

  useBeforeUnload();

  useNavigationWarningModal({
    when: classroomDetailsInfo.isDirty || formik.dirty,
    safeRoute: [TEACHER_APP_ROUTES.getAddingTeachersToNewClassroom()],
  });

  return (
    <StyledLayoutContainer>
      <CreateFormHeader
        activeIndex={0}
        title={t('classroom_details')}
        exitPath={TEACHER_APP_ROUTES.getAdministration(
          AdministrationTabs.classrooms
        )}
      />
      <StyledMain>
        <Box
          component="form"
          id="school-form"
          className={styles.form}
          sx={{
            '& .MuiTextField-root': { m: 1 },
          }}
          onSubmit={formik.handleSubmit}
        >
          <div className={styles.container}>
            <Typography className={styles.contentSteps}>
              {t('choose_school')}
            </Typography>
            <FormInputField
              fieldName="schoolId"
              label={t('choose_school')}
              formik={formik}
              select
              sx={{
                maxWidth: '300px',
              }}
            >
              {administrationSchools.map((school) => {
                return (
                  <MenuItem key={school.id} value={school.id}>
                    {school.name}
                  </MenuItem>
                );
              })}
            </FormInputField>
            {formik.touched.schoolId && Boolean(formik.errors.schoolId) ? (
              <FormHelperText className={styles.helperText}>
                {formik.touched.schoolId && formik.errors.schoolId}
              </FormHelperText>
            ) : null}
            <Typography className={styles.contentDescription}>
              <a
                href={TEACHER_APP_ROUTES.getContactHelpDesk()}
                target="_blank"
                rel="noreferrer"
              >
                {t('right_school')}
                {t('contact_us')}
              </a>
            </Typography>
          </div>

          <div className={styles.container}>
            <Typography className={styles.contentSteps}>
              {t('choose_classroom')}
            </Typography>
            <Typography className={styles.contentTxt}>
              {t('classroom_name_description')}
            </Typography>
            <div className={styles.fieldRow}>
              <FormInputField
                fieldName="name"
                label={t('classroom_name')}
                formik={formik}
              />
              <FormInputField
                fieldName="shortName"
                label={t('short_name')}
                formik={formik}
              />
            </div>
          </div>
        </Box>
      </StyledMain>
      <StyledFooter>
        <StyledButton
          onClick={() => {
            navigate(
              TEACHER_APP_ROUTES.getAdministration(
                AdministrationTabs.classrooms
              )
            );
          }}
        >
          {t('back')}
        </StyledButton>
        <StyledNextButton
          loading={isLoading}
          disabled={disabled}
          form="school-form"
        >
          {t('next')}
        </StyledNextButton>
      </StyledFooter>
    </StyledLayoutContainer>
  );
};

export default CreateNewClassroom;
