import { createSelector } from '@reduxjs/toolkit';
import { cond, isEmpty, isNil, stubTrue } from 'lodash';

import {
  Classroom,
  getClassroomTeacherNames,
  Program,
  School,
  User,
  UserRoles,
} from '@xq/domain';

import { selectCurrentUserState } from '../index';
import { selectAllUsers } from '../users';
import {
  selectClassroomEntities,
  selectAllClassrooms,
} from '../classrooms/classrooms-selectors';
import { selectProgramEntities } from '../program/program-selectors';
import { selectSchoolEntities } from '../school/school-selectors';

type FilterProps = {
  currentUser: User;
  currentUserSchool?: School;
  classrooms: Classroom[];
  searchText: string;
  users: User[];
};

export const selectCurrentUserClassroom = createSelector(
  [selectCurrentUserState, selectClassroomEntities],
  (currentUser, classroomEntities) => {
    if (!currentUser.currentClassroomId) {
      return null;
    }

    return classroomEntities[currentUser.currentClassroomId] ?? null;
  }
);

export const selectCurrentUserClassroomTeachers = createSelector(
  [selectCurrentUserClassroom, selectAllUsers],
  (classroom, users) => {
    return users.filter((user) =>
      classroom?.teacherEmails.includes(user.email)
    );
  }
);

export const selectCurrentUserClassroomStudents = createSelector(
  [selectCurrentUserClassroom, selectAllUsers],
  (classroom, users) => {
    return users.filter((user) =>
      classroom?.participantEmails.includes(user.email)
    );
  }
);

export const selectCurrentUserClassroomStudentIds = createSelector(
  [selectCurrentUserClassroomStudents],
  (students) => students.map((s) => s.uid)
);

export const selectCurrentUserClassrooms = createSelector(
  [selectCurrentUserState, selectClassroomEntities],
  (currentUser, classroomEntities) => {
    return currentUser.classroomIds
      ?.map((id) => classroomEntities[id])
      .filter((c): c is Classroom => !!c?.programIds.length);
  }
);

export const selectCurrentUserClassroomsBySchoolId = createSelector(
  [selectCurrentUserClassrooms, (_, schoolId?: string) => schoolId],
  (state, schoolId) => state.filter((c) => c.schoolId === schoolId)
);

export const selectCurrentUserClassroomId = createSelector(
  [selectCurrentUserState],
  (currentUser) => currentUser?.currentClassroomId
);

export const selectCurrentUserProgramId = createSelector(
  [selectCurrentUserState],
  (currentUser) => {
    if (
      !currentUser?.currentClassroomId ||
      !currentUser.currentProgramIdByClassroomId
    ) {
      return null;
    }

    return (
      currentUser.currentProgramIdByClassroomId[
        currentUser.currentClassroomId
      ] ?? null
    );
  }
);

export const selectCurrentUserClassroomPrograms = createSelector(
  [selectProgramEntities, selectCurrentUserClassroom],
  (programEntities, currentClassroom) => {
    if (isNil(currentClassroom) || isEmpty(programEntities)) return [];

    return currentClassroom.programIds
      .map((id) => programEntities[id])
      .filter((p): p is Program => !!p)
      .sort((a, b) => a.title.localeCompare(b.title));
  }
);

export const selectCurrentUserProgram = createSelector(
  [selectCurrentUserProgramId, selectProgramEntities],
  (programId, programEntities) => {
    if (!programId) {
      return null;
    }

    return programEntities[programId] ?? null;
  }
);

export const selectCurrentUserRoles = createSelector(
  [selectCurrentUserState],
  (currentUser) => currentUser.roles
);

export const selectIsCurrentUserStudent = createSelector(
  [selectCurrentUserRoles],
  (currentUserRoles) => currentUserRoles?.includes(UserRoles.student)
);

export const selectCurrentRole = createSelector(
  [selectCurrentUserState, selectCurrentUserClassroom],
  (currentUser, currentClassroom) =>
    currentUser?.roleByClassroomId?.[currentClassroom?.id || ''] ||
    currentUser?.roles?.[0]
);

export const selectIsCurrentUserAdmin = createSelector(
  [selectCurrentUserRoles],
  (currentUserRoles) => currentUserRoles?.includes(UserRoles.admin)
);

export const selectCurrentUserSchoolId = createSelector(
  [selectCurrentUserState],
  (currentUser) => currentUser.currentSchoolId ?? ''
);

export const selectCurrentUserPhotoUrl = createSelector(
  [selectCurrentUserState],
  (currentUser) => currentUser.photoUrl
);

export const selectCurrentUserSchool = createSelector(
  [selectSchoolEntities, selectCurrentUserSchoolId],
  (schools, id) => schools[id]
);

const filterCurrentUserClassroomsByRole = ({
  classrooms,
  currentUser,
  currentUserSchool,
  searchText,
  users,
}: FilterProps) => {
  const selectedSchoolId = currentUser.currentSchoolId;

  const filterStrategies = cond([
    [
      () => currentUser.roles.includes(UserRoles.admin),
      (c: Classroom) => {
        return (
          c.schoolId === selectedSchoolId &&
          (currentUserSchool?.adminEmail === currentUser.email ||
            c.teacherEmails.includes(currentUser.email))
        );
      },
    ],
    [
      () => currentUser.roles.includes(UserRoles.teacher),
      (c: Classroom) => {
        return (
          c.schoolId === selectedSchoolId &&
          c.teacherEmails.includes(currentUser.email)
        );
      },
    ],
    [stubTrue, () => false],
  ]);

  const filteredClassroomsByRole = classrooms.filter((c) =>
    filterStrategies(c)
  );

  if (searchText) {
    const escapeRegExp = (value: string): string => {
      return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    };

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const filteredClassroomsByText = filteredClassroomsByRole.filter(
      (classroom) => {
        const teacherNames = getClassroomTeacherNames(users, classroom);

        return (
          searchRegex.test(teacherNames) || searchRegex.test(classroom.name)
        );
      }
    );

    return filteredClassroomsByText;
  }

  return filteredClassroomsByRole;
};

export const selectCurrentUserClassroomsByRole = createSelector(
  [
    selectAllClassrooms,
    selectAllUsers,
    selectCurrentUserState,
    selectCurrentUserSchool,
    (_, searchText: string) => searchText,
  ],
  (classrooms, users, currentUser, currentUserSchool, searchText) => {
    return filterCurrentUserClassroomsByRole({
      classrooms,
      users,
      currentUser,
      currentUserSchool,
      searchText,
    }).filter((c) => !c.isArchived);
  }
);

export const selectCurrentUserArchivedClassroomsByRole = createSelector(
  [
    selectAllClassrooms,
    selectAllUsers,
    selectCurrentUserState,
    selectCurrentUserSchool,
    (_, searchText: string) => searchText,
  ],
  (classrooms, users, currentUser, currentUserSchool, searchText) => {
    return filterCurrentUserClassroomsByRole({
      classrooms,
      users,
      currentUser,
      currentUserSchool,
      searchText,
    }).filter((c) => c.isArchived);
  }
);
