import { StreamClient } from 'getstream';

import { FeedMapper, FeedService, StreamFeedService } from '@xq/infrastructure';
import { ReactionKinds } from '@xq/domain';
import { StreamEmojiReaction } from '@xq/shared/data-access';

export type GetActivityReactionsProps = {
  streamToken: string;
  activityId: string;
  count: number;
};

export class GetActivityReactionsUseCase {
  static async execute({
    activityId,
    streamToken,
    count,
  }: GetActivityReactionsProps) {
    const client = StreamFeedService.getInstance(streamToken).client;

    let shouldCall = true;
    let result: StreamEmojiReaction[] = [];
    let idLt = undefined;
    const limit = 50;

    while (shouldCall) {
      const response = await FeedService.getInstance(
        client as StreamClient
      ).getReactions<StreamEmojiReaction>(
        activityId,
        ReactionKinds.emoji,
        idLt
      );

      result = result.concat(response);

      const totalResults = result.length;

      idLt = result[result.length - 1].id;

      if (totalResults >= count || totalResults >= limit) {
        shouldCall = false;
      }
    }

    return FeedMapper.toDomainReactions(result);
  }
}
