import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { School } from '@xq/domain';

import { fetchSchools, fetchSchoolById } from './school-actions';

export const schoolAdapter = createEntityAdapter<School>({
  selectId: (school) => school.id,
});

export const schoolSlice = createSlice({
  name: 'school',
  initialState: schoolAdapter.getInitialState({
    loading: false,
  }),
  reducers: {
    setMany: schoolAdapter.setMany,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(fetchSchools.pending, fetchSchoolById.pending),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(fetchSchools.fulfilled, fetchSchoolById.fulfilled),
      (state, { payload }) => {
        state.loading = false;
        schoolAdapter.setMany(state, payload);
      }
    );
  },
});

export const schoolActions = schoolSlice.actions;
