import { z } from 'zod';

import { Entities } from '../enums';

export const DeleteEntitiesSchema = z.object({
  entity: z.nativeEnum(Entities),
  ids: z.array(z.string()).min(1),
});

export type DeleteEntitiesRequestDto = z.infer<typeof DeleteEntitiesSchema>;
