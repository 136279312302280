import { z } from 'zod';

export const FbWizardContentSchema = z.object({
  id: z.string(),
  slug: z.string(),
  createdAt: z.date(),
  positionSelector: z.string(),
});

export type FbWizardContentDto = z.infer<typeof FbWizardContentSchema>;
