import { Button, Grid, styled } from '@mui/material';
import { Swiper } from 'swiper/react';

export const StyledGrid = styled(Grid)`
  height: 100%;
  overflow: auto;
`;

export const StyledJournal = styled('div')`
  background-color: var(--white);
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;

  @media (max-width: 640px) {
    grid-template-rows: max-content 1fr max-content;
    margin: 0;
  }
`;

export const StyledHeader = styled('h2')`
  font-size: var(--twenty-pixels-font-size);
  text-transform: uppercase;
  display: block;
  margin-left: 16px;
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
`;

export const StyledMobileDriveButtons = styled('div')`
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const StyledMobileContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 10px;
`;

export const StyledHideButton = styled(Button)`
  margin: 10px 16px 10px 10px;
  padding: 0 20px;
`;
