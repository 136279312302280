import { createSelector } from '@reduxjs/toolkit';

import { getUserRole, UserRoles } from '@xq/domain';

import { selectClassroomsState, selectCurrentUserState } from '../index';
import { classroomsAdapter } from './classrooms-slice';

export const {
  selectEntities: selectClassroomEntities,
  selectById: selectClassroomById,
  selectAll: selectAllClassrooms,
} = classroomsAdapter.getSelectors(selectClassroomsState);

export const selectClassroomUsersByRole = createSelector(
  [
    selectClassroomEntities,
    (_, classroomId: string) => classroomId,
    (_, ___: string, role: UserRoles) => role,
  ],
  (entities, classroomId, role) => {
    const currentRoom = entities[classroomId];

    if (currentRoom) {
      const cases = {
        [UserRoles.teacher]: currentRoom.teacherEmails,
        [UserRoles.student]: currentRoom.participantEmails,
        [UserRoles.admin]: [],
        [UserRoles.viewer]: currentRoom.viewerEmails,
      };
      return cases[role];
    }
    return [];
  }
);
export const selectStudentFolderId = createSelector(
  [
    selectClassroomEntities,
    (_, classroomId?: string) => classroomId,
    (_, __, programId?: string) => programId,
    (_, __, ___, studentEmail?: string) => studentEmail,
  ],
  (entities, classroomId, programId, studentEmail) => {
    if (!classroomId || !programId || !studentEmail) return;

    const classroom = entities[classroomId as string];

    return (
      classroom?.studentFolderIdByEmailByProgramId?.[studentEmail]
        ?.folderByProgramId?.[programId] ?? ''
    );
  }
);

export const selectFilteredTeacherClassrooms = createSelector(
  [selectAllClassrooms, selectCurrentUserState],
  (classrooms, currentUser) => {
    const { isAdmin } = getUserRole(currentUser);
    return classrooms?.filter((c) => {
      const roleCondition = isAdmin
        ? c.teacherEmails.includes(currentUser?.email)
        : true;
      return !c.isArchived && roleCondition;
    });
  }
);

export const selectIsLoadingClassrooms = createSelector(
  [selectClassroomsState],
  (state) => state.loading
);
