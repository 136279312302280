import { shouldUpdateLastActivity, User } from '@xq/domain';
import { UserRepository } from '@xq/infrastructure';
import { useEffect } from 'react';
import { useAppSelector, selectCurrentUserState } from '../store';

const userRepository = new UserRepository();

const updateLastActivity = (currentUser: User) => {
  if (!shouldUpdateLastActivity(currentUser)) return;
  userRepository.updateLastActivity(currentUser.uid);
};

export const useLastActivity = () => {
  const currentUser = useAppSelector(selectCurrentUserState);

  useEffect(() => {
    function handleClick() {
      updateLastActivity(currentUser);
    }

    function handleChange() {
      updateLastActivity(currentUser);
    }

    function handleScroll() {
      updateLastActivity(currentUser);
    }

    document.addEventListener('click', handleClick);
    document.addEventListener('change', handleChange);
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('change', handleChange);
      document.removeEventListener('scroll', handleScroll);
    };
  }, [currentUser.uid, currentUser.lastActivity]);
};
