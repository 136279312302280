import { collection, getDocs } from 'firebase/firestore';
import {
  CollectionNames,
  FbWizardContentDto,
  WizardContentMapper,
} from '@xq/shared/data-access';

import {
  firebaseService,
  IFirebaseService,
} from '../../services/FirebaseService';
import { WizardContent } from '@xq/domain';

export class WizardContentRepository {
  private firebase: IFirebaseService = firebaseService;

  private collectionName = CollectionNames.wizardContent;

  private collection = collection(this.firebase.db, this.collectionName);

  private mapper = new WizardContentMapper();

  async getWizardContent(): Promise<WizardContent[]> {
    return (await getDocs(this.collection)).docs.map((p) =>
      this.mapper.toDomain({
        ...p.data(),
        id: p.id,
      } as FbWizardContentDto)
    );
  }
}
