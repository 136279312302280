import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FeedSlugs, JournalLink, MathTagTypes } from '@xq/domain';

import { fetchActivities, postActivity } from '../activity';
import { FEED_CALL_LIMIT_SIZE, FEED_PAGE_SIZE } from '../../utils';

type FeedState = {
  feedId: string;
  activityIds: string[];
  slug: FeedSlugs;
  hasNextPage: boolean;
  reachedCallLimit: boolean;
  id_lt: string;
  loading: boolean;
  currentSelectedUserId: string;
  autoTagTypeForFilter?: MathTagTypes | string;
  selectableElementId: string;
  contentTitle: string;
  journalLink: JournalLink | null;
};

const initialState: FeedState = {
  feedId: '',
  activityIds: [],
  slug: FeedSlugs.student,
  hasNextPage: true,
  reachedCallLimit: false,
  id_lt: '',
  loading: false,
  currentSelectedUserId: '',
  autoTagTypeForFilter: undefined,
  selectableElementId: '',
  contentTitle: '',
  journalLink: null,
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    initFeed(
      state,
      action: PayloadAction<{
        feedId: string;
        slug: FeedSlugs;
        selectableElementId?: string;
        contentTitle?: string;
      }>
    ) {
      return {
        ...initialState,
        ...action.payload,
      };
    },
    updateCurrentSelectedUserId(state, action: PayloadAction<string>) {
      state.currentSelectedUserId = action.payload;
    },
    updateReachedCallLimit(state, action: PayloadAction<boolean>) {
      state.reachedCallLimit = action.payload;
    },
    updateJournalLink(state, action: PayloadAction<JournalLink | null>) {
      state.journalLink = action.payload;
    },
    updateSelectableElementId(
      state,
      action: PayloadAction<{
        id: string;
        title: string;
      }>
    ) {
      state.selectableElementId = action.payload.id;
      state.contentTitle = action.payload.title;
    },
    updateAutoTagTypeForFilter(
      state,
      action: PayloadAction<string | undefined>
    ) {
      state.autoTagTypeForFilter = action.payload;
    },
    addActivityIds(state, action: PayloadAction<string[]>) {
      state.activityIds = [
        ...new Set([...action.payload, ...state.activityIds]),
      ];
    },
    removeActivityIds(state, action: PayloadAction<string[]>) {
      state.activityIds = state.activityIds.filter(
        (id) => !action.payload.includes(id)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActivities.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchActivities.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(
      fetchActivities.fulfilled,
      (state, { payload: { activities } }) => {
        const newActivities = [
          ...new Set([...state.activityIds, ...activities.map((ac) => ac.id)]),
        ];
        const hasNextPage = activities.length >= FEED_PAGE_SIZE;
        const reachedCallLimit =
          hasNextPage && newActivities.length < FEED_CALL_LIMIT_SIZE
            ? false
            : hasNextPage && newActivities.length % FEED_CALL_LIMIT_SIZE === 0;

        state.activityIds = newActivities;
        state.id_lt = activities[activities.length - 1]?.id || '';
        state.loading = false;
        state.hasNextPage = hasNextPage;
        state.reachedCallLimit = !hasNextPage || reachedCallLimit;
      }
    );
    builder.addCase(postActivity.fulfilled, (state, { payload }) => {
      state.activityIds = [
        ...new Set([payload.addedActivity.id, ...state.activityIds]),
      ];
    });
  },
});

export const feedActions = feedSlice.actions;
