import { ReactElement } from 'react';
import { Tooltip } from '@mui/material';

type ClassroomTooltipProps = {
  children: ReactElement;
  title: ReactElement;
  active: boolean;
};

export const ClassroomTooltip = ({
  children,
  title,
  active,
}: ClassroomTooltipProps) => (
  <Tooltip
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: 'transparent',
          borderRadius: 0,
          height: 40,
          margin: '0 14px 0 -5px !important',
          display: 'flex',
        },
      },
    }}
    PopperProps={{
      style: {
        backgroundColor: active
          ? 'var(--green-primary)'
          : 'var(--blackcurrant)',
      },
    }}
    title={title}
    placement="right"
    data-tooltip-id="tooltip-id"
  >
    {children}
  </Tooltip>
);
