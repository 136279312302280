import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { cx } from '@emotion/css';
import { useFormik } from 'formik';

import { loggerService } from '@xq/infrastructure';
import { GoogleFilesMimeTypes } from '@xq/domain';
import { enqueueSnackbar, SnackbarVariant, useAppDispatch } from '../../store';

import styles from './create-document.module.scss';
import { ReactComponent as GoogleDriveIcon } from '../../assets/drive-icon.svg';
import { useUserFiles } from '../../context/user-files-context/user-files-context';
import { FileTypeLabel, FILE_TYPES_OPTIONS } from './fileTypeOptions';
import { FormInputField } from '../../FormInputField';
import { DialogCloseButton } from '../../ui';
import { useGoogleScopesChecker } from '../../hooks';

export const CreateDocument = () => {
  const { t } = useTranslation();
  const { createFile } = useUserFiles();

  const dispatch = useAppDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      type: GoogleFilesMimeTypes['application/vnd.google-apps.document'],
    },
    validate: ({ name }) => {
      return {
        ...(!name && { name: 'this_field_is_required' }),
      };
    },
    onSubmit: async ({ name, type }) => {
      setLoading(true);

      try {
        const file = await createFile(name, type);

        if (file) {
          window.open(file.webViewLink, '_blank', 'noopener,noreferrer');
        }
      } catch (e) {
        loggerService.error(e);
        dispatch(
          enqueueSnackbar({
            message: t('create_file_fail'),
            options: {
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      } finally {
        setLoading(false);
        setModalOpen(false);
      }
    },
  });

  const { requestScopes } = useGoogleScopesChecker();

  const renderNameValidationError = () => {
    if (formik.touched.name && formik.errors.name) {
      return t(formik.errors.name as any);
    }

    return '';
  };

  const renderRadioBtnLabel = (label: FileTypeLabel, iconLink: string) => {
    return (
      <span>
        <img
          className={styles.fileIcon}
          src={iconLink}
          alt={t('file_thumbnail')}
        />
        {t(label)}
      </span>
    );
  };

  useEffect(() => {
    if (!modalOpen) {
      formik.resetForm();
    }
  }, [modalOpen]);

  return (
    <>
      <Button
        // disabled={isImpersonating}
        color="info"
        variant="outlined"
        className={styles.createFileBtn}
        onClick={async () => {
          if (await requestScopes()) setModalOpen(true);
        }}
      >
        {t('create_file')}
        <GoogleDriveIcon className={styles.googleDriveIcon} />
      </Button>

      <Dialog
        className={styles.createFileDialog}
        classes={{
          paper: styles.paper,
        }}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
          <DialogTitle>
            {t('create_file')}
            <GoogleDriveIcon className={styles.googleDriveIcon} />
            <DialogCloseButton onClose={() => setModalOpen(false)} />
          </DialogTitle>
          <DialogContent>
            <div>
              <Typography variant="subtitle1">
                {t('please_enter_name_and_select_type_of_the_file')}:
              </Typography>

              <FormInputField
                className={styles.fileNameControl}
                fieldName="name"
                label={t('file_name')}
                formik={formik}
                helperText={renderNameValidationError()}
                required
                disabled={loading}
              />

              <FormControl>
                <FormLabel>{t('file_type')}</FormLabel>
                <RadioGroup
                  aria-label="file type"
                  value={formik.values.type}
                  name="type"
                  onChange={formik.handleChange}
                >
                  {FILE_TYPES_OPTIONS.map(({ label, value, iconLink }) => (
                    <FormControlLabel
                      key={label}
                      value={value}
                      label={renderRadioBtnLabel(label, iconLink)}
                      disabled={loading}
                      control={
                        <Radio
                          className={cx({
                            [styles.checkedRadio]: value === formik.values.type,
                          })}
                        />
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          </DialogContent>

          <DialogActions>
            <LoadingButton type="submit" loading={loading} variant="contained">
              {t('create')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
