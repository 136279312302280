import { filter, includes } from 'lodash';
import { z } from 'zod';

import { IdProps } from './Interfaces';
import { MathTagTypes } from './Tags';

export enum MimeTypes {
  'application/vnd.google-apps.document' = 'application/vnd.google-apps.document',
  'application/vnd.google-apps.spreadsheet' = 'application/vnd.google-apps.spreadsheet',
  'application/vnd.google-apps.presentation' = 'application/vnd.google-apps.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf' = 'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation' = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword' = 'application/msword',
  'application/vnd.ms-excel' = 'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint' = 'application/vnd.ms-powerpoint',
  'application/vnd.google-apps.folder' = 'application/vnd.google-apps.folder',
  'application/vnd.google-apps.form' = 'application/vnd.google-apps.form',
  'application/vnd.google-apps.jam' = 'application/vnd.google-apps.jam',
  'application/zip' = 'application/zip',
}

export enum GoogleFilesMimeTypes {
  'application/vnd.google-apps.document' = 'application/vnd.google-apps.document',
  'application/vnd.google-apps.spreadsheet' = 'application/vnd.google-apps.spreadsheet',
  'application/vnd.google-apps.presentation' = 'application/vnd.google-apps.presentation',
  'application/vnd.google-apps.form' = 'application/vnd.google-apps.form',
  'application/vnd.google-apps.jam' = 'application/vnd.google-apps.jam',
  'application/vnd.google-apps.folder' = 'application/vnd.google-apps.folder',
}

export const FOLDER_MIME_TYPE = 'application/vnd.google-apps.folder';

export const LastModifyingUserSchema = z.object({
  displayName: z.string(),
  emailAddress: z.string(),
  kind: z.string(),
  me: z.boolean(),
  permissionId: z.string(),
  photoLink: z.string(),
});

export const DocumentPropertiesSchema = z.object({
  isForReview: z.boolean().optional(),
  templateId: z.string().nullable().optional(),
  isTemplate: z.boolean().optional(),
  categories: z.number().array().optional(),
});

export const DocumentSchema = z.object({
  id: z.string(),
  createdTime: z.string(),
  modifiedTime: z.string(),
  name: z.string(),
  mimeType: z.string(),
  webViewLink: z.string(),
  iconLink: z.string(),
  size: z.string().optional(),
  ownerId: z.string().optional(),
  ownedByMe: z.boolean(),
  parents: z.string().array(),
  autoTags: z.nativeEnum(MathTagTypes).array().optional(),
  lastModifyingUser: LastModifyingUserSchema.optional(),
  properties: DocumentPropertiesSchema.optional(),
});

export type LastModifyingUser = z.infer<typeof LastModifyingUserSchema>;

export type DocumentProperties = z.infer<typeof DocumentPropertiesSchema>;

export type Document = z.infer<typeof DocumentSchema>;

export const getDocumentId = ({ userId, programId, classroomId }: IdProps) =>
  `${userId}-${classroomId}-${programId}`;

export const isTaggableDocument = (mimeType: string) =>
  mimeType in MimeTypes &&
  mimeType !== MimeTypes['application/vnd.google-apps.form'];

export const extractTaggableDocuments = (
  documents: { mimeType: string; id: string }[]
) => documents.filter((d) => isTaggableDocument(d.mimeType));

export const isViewableMimeType = (mimeType: string) => {
  const nonViewable = [
    MimeTypes['application/pdf'],
    MimeTypes['application/vnd.google-apps.folder'],
    MimeTypes['application/vnd.google-apps.form'],
    MimeTypes['application/vnd.google-apps.jam'],
  ];
  return includes(
    filter(Object.values(MimeTypes), (t) => !includes(nonViewable, t)),
    mimeType
  );
};

export const isDocumentForReview = (documents: Document[]) =>
  documents.some((d) => d.properties?.isForReview);

export const convertDocumentSizeToMb = (size: string) => +size / (1024 * 1024);
