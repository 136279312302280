import { FeedMapper, FeedService } from '@xq/infrastructure';
import { StreamClient } from 'getstream';
import { LogAnalyticsEventUseCase } from '../../LogAnalyticsEventUseCase';

interface PostCommentProps {
  client: StreamClient;
  activityId: string;
  text: string;
}

export class PostCommentUseCase {
  static async execute({ client, activityId, text }: PostCommentProps) {
    const response = await FeedService.getInstance(client).addComment(
      activityId,
      text
    );

    return FeedMapper.toDomainComments([response])[0];
  }
}
