import { firebaseService, loggerService } from '@xq/infrastructure';
import {
  ValidateEmailsRequestDto,
  ValidateEmailsResponseDto,
} from '@xq/shared/data-access';
import { callWithRetry } from '@xq/shared/utils';

export class ValidateEmailsUseCase {
  static async execute(payload: ValidateEmailsRequestDto) {
    const validateEmails = firebaseService.prepareFunctionCaller<
      ValidateEmailsRequestDto,
      ValidateEmailsResponseDto
    >('validateEmails');
    try {
      const response = await callWithRetry(() => validateEmails(payload));

      return response.data;
    } catch (error) {
      loggerService.error(error);
      return { invalidEmails: [], alreadyViewers: [] };
    }
  }
}
