import { Chip, styled } from '@mui/material';
import Close from '@mui/icons-material/Close';

export interface TagProps {
  className?: string;
  label: string;
  onDelete?: () => void;
}

const StyledChip = styled(Chip)`
  background-color: var(--link-water);
  color: var(--smalt-blue);

  &:focus {
    background-color: var(--link-water);
    color: var(--smalt-blue);
  }
`;

const StyledClose = styled(Close)`
  color: var(--smalt-blue);
  height: 14px;
  width: 14px;
  margin-right: 8px;

  &:hover {
    background: var(--smalt-blue);
    border-radius: 50%;
  }
`;

export const Tag = (props: TagProps) => (
  <StyledChip
    label={props.label}
    onDelete={props.onDelete}
    color="primary"
    className={props.className}
    deleteIcon={<StyledClose data-cy="tag-chip-delete" />}
    data-cy="math-tag-chip"
  />
);
