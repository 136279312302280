import { StreamClient } from 'getstream';

import { FeedService, CommentData, FeedMapper } from '@xq/infrastructure';

export class EditCommentUseCase {
  static async execute(payload: CommentData, client: StreamClient) {
    const feedService = FeedService.getInstance(client);

    const { data } = await feedService.editComment(payload);

    return FeedMapper.toDomainComments([data])[0];
  }
}
