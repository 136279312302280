import { createSelector } from '@reduxjs/toolkit';
import { chain } from 'lodash';

import { programAdapter } from './program-slice';
import { selectProgramState } from '../index';

export const {
  selectAll: selectAllPrograms,
  selectById: selectProgramById,
  selectEntities: selectProgramEntities,
} = programAdapter.getSelectors(selectProgramState);

export const selectProgramsGroupedByCourse = createSelector(
  [selectAllPrograms],
  (programs) =>
    chain(programs)
      .filter((p) => Boolean(p.courseId))
      .groupBy('courseId')
      .value()
);

export const selectProgramBySlug = createSelector(
  [selectProgramEntities, (_, programSlug: string) => programSlug],
  (programs, programSlug) => {
    const programId = Object.keys(programs).find(
      (id) => programs[id]?.slug === programSlug
    );

    return programId ? programs[programId] : undefined;
  }
);

export const selectProgramsByIds = createSelector(
  [selectProgramEntities, (_, programIds: string[]) => programIds],
  (programs, programIds) => programIds.map((id) => programs[id]).filter(Boolean)
);

export const selectClassroomProgramsGroupedByCourse = createSelector(
  [selectAllPrograms, (_, programIds: string[]) => programIds],
  (programs, programIds) =>
    chain(programs)
      .filter((p) => Boolean(p.courseId) && programIds.includes(p.id))
      .groupBy('courseId')
      .value()
);

export const selectUnassignedPrograms = createSelector(
  [selectAllPrograms, (_, programIds: string[]) => programIds],
  (programs, programIds) => programs.filter((p) => !programIds.includes(p.id))
);
