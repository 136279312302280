import { styled } from '@mui/material/styles';

export const StyledLayoutContainer = styled('div')`
  display: grid;
  grid-template-rows: max-content 1fr;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const StyledHeaderNames = styled('div')`
  margin-left: auto;
`;

export const StyledInfoContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  paddingTop: '24px',
  paddingLeft: '24px',
}));
