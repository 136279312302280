import { firebaseService } from '@xq/infrastructure';
import {
  ImportGoogleClassroomRequestDto,
  ImportGoogleClassroomResponseDto,
} from '@xq/shared/data-access';
import { callWithRetry } from '@xq/shared/utils';

export class ImportGoogleClassroomUseCase {
  async execute(classroomIds: string[], schoolId: string) {
    const importGoogleClassroom = firebaseService.prepareFunctionCaller<
      ImportGoogleClassroomRequestDto,
      ImportGoogleClassroomResponseDto
    >('importGoogleClassroom');

    const token = gapi.client.getToken();

    return callWithRetry(() =>
      importGoogleClassroom({
        token: token.access_token,
        classroomIds,
        schoolId,
      })
    );
  }
}
