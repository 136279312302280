import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  TextFormatType,
} from 'lexical';
import {
  $isListNode,
  ListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { $isHeadingNode } from '@lexical/rich-text';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  mergeRegister,
  $getNearestNodeOfType,
  $findMatchingParent,
} from '@lexical/utils';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

import styles from './toolbar-plugin.module.scss';
import { ToolbarButton } from './ToolbarButton';
import { EmojiDropdownPickerPlugin } from '../emoji';

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
} as const;

type ToolbarPluginProps = {
  hideFormatTextButtons?: boolean;
};

export const ToolbarPlugin = ({
  children,
  hideFormatTextButtons = false,
}: PropsWithChildren<ToolbarPluginProps>) => {
  const [editor] = useLexicalComposerContext();

  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph');

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [emojiDropdownAnchorEl, setEmojiDropdownAnchorEl] = useState<
    (EventTarget & Element) | null
  >(null);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      })
    );
  }, [activeEditor, updateToolbar]);

  const formatText = (payload: TextFormatType) => {
    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, payload);
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarBtnContainer}>
        <ToolbarButton
          isActive={isOpen}
          onClick={(e) => {
            setEmojiDropdownAnchorEl(e.currentTarget);
            setIsOpen(!isOpen);
          }}
        >
          <InsertEmoticonIcon />
          <EmojiDropdownPickerPlugin
            anchorElement={emojiDropdownAnchorEl}
            isOpen={isOpen}
          />
        </ToolbarButton>
        {!hideFormatTextButtons && (
          <>
            <ToolbarButton isActive={isBold} onClick={() => formatText('bold')}>
              <FormatBold className={styles.format} />
            </ToolbarButton>
            <ToolbarButton
              isActive={isItalic}
              onClick={() => formatText('italic')}
            >
              <FormatItalicIcon className={styles.format} />
            </ToolbarButton>
            <ToolbarButton
              isActive={isUnderline}
              onClick={() => formatText('underline')}
            >
              <FormatUnderlinedIcon className={styles.format} />
            </ToolbarButton>
            <ToolbarButton
              isActive={blockType === 'bullet'}
              onClick={formatBulletList}
            >
              <FormatListBulletedIcon className={styles.format} />
            </ToolbarButton>
            <ToolbarButton
              isActive={blockType === 'number'}
              onClick={formatNumberedList}
            >
              <FormatListNumberedIcon className={styles.format} />
            </ToolbarButton>
          </>
        )}
      </div>

      {children}
    </div>
  );
};
