import { User } from '@firebase/auth';
import { useEffect, useState } from 'react';

import { firebaseService, SchoolRepository } from '@xq/infrastructure';
import {
  CreateUserUseCase,
  RefreshAuthTokenUseCase,
  CheckUserRolesByReposUseCase, CreateSchoolDriveStructureUseCase,
} from '@xq/usecases';
import { UserRecord } from '@xq/shared/data-access';
import { generateUserRole, UserRoles, SchoolStatusEnum } from '@xq/domain';

import { FbAuthUserState } from '../ApiProvider/ApiProvider';
import {
  appActions,
  getCurrentUserData,
  useAppDispatch,
} from '../store';

const createUserRecord = async (fbAuthUser: User) => {
  const { displayName, email, photoURL: photoUrl, uid } = fbAuthUser;

  return {
    displayName,
    email,
    photoUrl,
    uid,
  };
};

const createUser = async (record: UserRecord) => {
  await CreateUserUseCase.execute(record);
  await RefreshAuthTokenUseCase.execute();
};

export const useFirebaseAuthUser = () => {
  const [userAuthState, setUserAuthState] =
    useState<FbAuthUserState>('loading');
  const [authUser, setAuthUser] = useState<User | null>(null);
  const dispatch = useAppDispatch();
  const schoolRepository = new SchoolRepository();

  const handleImpersonating = async (email: string) => {
    let isDemoUser = false;
    if (await schoolRepository.isDemoUser(email)) {
      isDemoUser = true;
      dispatch(appActions.setIsImpersonating(isDemoUser));
      dispatch(
        appActions.setBannersVisibility({
          demo: isDemoUser,
        })
      );
    }
    return isDemoUser;
  };

  useEffect(() => {
    const unsubscribe = firebaseService.auth.onAuthStateChanged(
      async (fbAuthUser) => {
        if (fbAuthUser) {
          setAuthUser(fbAuthUser);
          const isDemoUser = await handleImpersonating(fbAuthUser.email || '');
          const { isViewer, isTeacher, isStudent, isAdmin, userExists } =
            await CheckUserRolesByReposUseCase.execute(
              fbAuthUser.email,
              fbAuthUser.uid
            );

          if (isStudent) {
            setUserAuthState('loggedOut');
            return;
          }

          const record = await createUserRecord(fbAuthUser);

          const tokenResult = await fbAuthUser.getIdTokenResult(true);

          const roles: UserRoles[] = tokenResult?.claims?.roles as UserRoles[] || [];

          if ((!userExists && !isDemoUser) || !roles?.length) {
            if (!isViewer && !isTeacher && !isAdmin && !isStudent) {
              setUserAuthState('choseRole');
              return;
            }

            const role = generateUserRole({
              isTeacher,
              impersonate: isDemoUser,
              isViewer,
              isAdmin,
              isStudent,
            });

            await createUser({
              ...record,
              role: role || UserRoles.teacher,
            } as UserRecord);

            dispatch(appActions.setIsNewUser(true));
          }

          await dispatch(getCurrentUserData({ fbAuthUser }));
          setUserAuthState('loggedIn');
        } else {
          setUserAuthState('loggedOut');
        }
      }
    );

    return () => unsubscribe();
  }, []);

  return {
    userAuthState,
    setUserAuthState,
    authUser,
  };
};
