/// <reference types="google.picker"/>
/// <reference types="google.accounts" />

const developerKey = process.env.NX_PUBLIC_FIREBASE_API_KEY || '';
const appId = process.env.NX_PUBLIC_GOOGLE_APP_ID || '';

export type DriveFolder = {
  name: string;
  folderId: string;
};

export interface IGooglePickerService {
  accessToken: string;
  isMobile?: boolean;
  createPicker(driveFolders: DriveFolder[]): void;
  showPicker(): void;
  onFilesSelect(files: Array<gapi.client.drive.File>): Promise<void>;
}

export class GooglePickerService implements IGooglePickerService {
  accessToken: string;

  isMobile: boolean;

  private static instance: IGooglePickerService;

  private picker: google.picker.Picker | null;

  onFilesSelectCallback: (
    files: Array<gapi.client.drive.File>
  ) => Promise<void>;

  constructor(
    accessToken: string,
    onFilesSelectCallback: (
      files: Array<gapi.client.drive.File>
    ) => Promise<void>,
    isMobile = false
  ) {
    this.picker = null;
    this.accessToken = accessToken;
    this.isMobile = isMobile;
    this.onFilesSelectCallback = onFilesSelectCallback;
  }

  showPicker() {
    this.picker?.setVisible(true);
  }

  onFilesSelect(
    files: google.picker.ResponseObject[google.picker.Response.DOCUMENTS]
  ) {
    return this.onFilesSelectCallback(files);
  }

  createPicker(driveFolders: DriveFolder[]) {
    const googleDriveView = new google.picker.DocsView(
      google.picker?.ViewId.DOCS
    )
      .setMode(google.picker?.DocsViewMode.LIST)
      .setIncludeFolders(true);

    const builder = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setAppId(appId)
      .setOAuthToken(this.accessToken)
      .setDeveloperKey(developerKey)
      .addView(googleDriveView)
      .setOrigin(window.location.origin)
      .setCallback(async (data: google.picker.ResponseObject) => {
        if (data.action === google.picker.Action.PICKED) {
          await this.onFilesSelect(data.docs);
        }
      });

    if (this.isMobile) {
      builder.setSize(500, 500);
    }

    for (const { folderId, name } of driveFolders) {
      const view = new google.picker.DocsView(google.picker?.ViewId.DOCS)
        .setMode(google.picker?.DocsViewMode.LIST)
        // missing method in the type definitions for google.picker
        // eslint-disable-next-line
        // @ts-ignore
        .setLabel(name)
        .setParent(folderId)
        .setIncludeFolders(true);

      builder.addView(view);
    }

    this.picker = builder.build();
  }

  public static getInstance(
    accessToken: string,
    onFilesSelect: (files: Array<gapi.client.drive.File>) => Promise<void>
  ): IGooglePickerService {
    if (!GooglePickerService.instance) {
      GooglePickerService.instance = new GooglePickerService(
        accessToken,
        onFilesSelect
      );
    }
    return GooglePickerService.instance;
  }
}
