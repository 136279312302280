import { useCallback, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';

import { CheckUserScopesUseCase, LoginUserUseCase } from '@xq/usecases';
import { loggerService } from '@xq/infrastructure';

import { selectCurrentUserState, useAppSelector } from '../store';

const NX_DRIVE_FILE_SCOPE = process.env.NX_PUBLIC_DRIVE_FILE_SCOPE || '';

export const useGoogleScopesChecker = (scope?: string) => {
  const [loading, setLoading] = useState(false);

  const currentUser = useAppSelector(selectCurrentUserState);

  const googleScope = scope || NX_DRIVE_FILE_SCOPE;

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      await new LoginUserUseCase().execute({
        code: codeResponse.code,
        currentUserEmail: currentUser.email,
      });
    },
    onError: (errorResponse) => {
      loggerService.error(`Login in Google failed with: ${errorResponse}`);
    },
    flow: 'auth-code',
    select_account: true,
    scope: googleScope,
  });

  const requestScopes = useCallback(async () => {
    setLoading(true);

    try {
      if (!(await CheckUserScopesUseCase.execute(googleScope))) {
        await login();
        return false;
      }
      return true;
    } catch (e) {
      await login();
      loggerService.error(`Failed to fetch google scope permissions: ${e}`);
      return false;
    } finally {
      setLoading(false);
    }
  }, [scope]);

  return { requestScopes, loading };
};
