import { createSelector } from '@reduxjs/toolkit';

import { selectFollowedUpUsersState } from '../index';

export const selectFollowedUpUsersByUserId = createSelector(
  [
    selectFollowedUpUsersState,
    (_, userId: string) => userId,
    (_, __, classroomId: string | undefined) => classroomId,
    (_, __, ___, programId: string | undefined) => programId,
  ],
  (state, userId, classroomId, programId) => {
    if (!userId || !classroomId || !programId || !state[userId]) {
      return [];
    }

    return state[userId][`${classroomId}-${programId}`] ?? [];
  }
);
