import { useTranslation } from 'react-i18next';

import { Tag } from '../Tag/Tag';
import { EmailErrors, InvalidEmail } from '../validations';
import styles from './add-users-to-classroom.module.scss';

type EmailsListProps = {
  validEmails: string[];
  invalidEmails: InvalidEmail[];
  handleValidEmailDelete: (element: string) => void;
  handleInvalidEmailDelete: (element: InvalidEmail) => void;
  roleLabel: string;
  roleLabelPlural: string;
  wrongRoleError: string;
};

export const EmailsList = ({
  validEmails,
  invalidEmails,
  handleValidEmailDelete,
  handleInvalidEmailDelete,
  roleLabel,
  roleLabelPlural,
  wrongRoleError,
}: EmailsListProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.listUsersContainer}>
      <div className={styles.listUsers}>
        <p className={styles.listUsersTitle}>
          {t('user_list', { role: roleLabel })}
        </p>
        {validEmails.length || invalidEmails.length ? (
          <p>
            {validEmails.length} {roleLabel}, {invalidEmails.length}{' '}
            {t('errors')}
          </p>
        ) : (
          <p>{t('no_users_added', { role: roleLabelPlural })}</p>
        )}
        {invalidEmails.map((invalidEmail, index) => {
          return (
            <div className={styles.redPillAndError} key={index}>
              <Tag
                label={invalidEmail.email}
                className={styles.incorrectEmailPill}
                onDelete={() => {
                  handleInvalidEmailDelete(invalidEmail);
                }}
              />
              <span className={styles.errorMessage}>
                {invalidEmail.error === EmailErrors.wrong_role
                  ? wrongRoleError
                  : t(EmailErrors[invalidEmail.error])}
              </span>
            </div>
          );
        })}
        {validEmails.map((correctEmail, index) => {
          return (
            <Tag
              key={index}
              label={correctEmail}
              className={styles.pill}
              onDelete={() => handleValidEmailDelete(correctEmail)}
            />
          );
        })}
      </div>
    </div>
  );
};
