import { Activity, Classroom } from '@xq/domain';

import { EditPostUseCase } from './EditPostUseCase';

interface SetReviewStateProps {
  activity: Activity;
  newReviewState: boolean;
  currentClassroom: Classroom;
  programId: string;
  streamToken: string;
}

export class SetReviewStateUseCase {
  static async execute({
    activity,
    newReviewState,
    currentClassroom,
    streamToken,
    programId,
  }: SetReviewStateProps) {
    const editPostUseCase = new EditPostUseCase();
    await editPostUseCase.execute(
      {
        id: activity.id,
        message: activity.message,
        userId: activity.actor?.id,
        classroomId: currentClassroom.id,
        foreign_id: activity.foreign_id,
        isForReview: newReviewState,
        programId,
        feedSlug: activity.feedSlug,
      },
      streamToken
    );
  }
}
