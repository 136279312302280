import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDistance } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Avatar } from '@mui/material';

import { Activity, Mention, User } from '@xq/domain';

import styles from './post-heder.module.scss';
import { MemoizedPostMenus } from '../PostMenus/PostMenus';

type PostContentProps = {
  activityOwner?: User;
  isActivityOwnerTeacherOrAdmin?: boolean;
  activity: Activity;
  mentionSuggestions: Mention[];
};

export const PostHeader = ({
  activityOwner,
  isActivityOwnerTeacherOrAdmin,
  activity,
  mentionSuggestions,
}: PostContentProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.userInfo}>
      <Avatar
        className={styles.profilePicture}
        src={activityOwner?.photoUrl ?? ''}
        alt={t('user_profile_photo')}
      />

      <div>
        <div className={styles.userName}>
          {isActivityOwnerTeacherOrAdmin ? t('apple') : null}{' '}
          <span className={styles.displayName}>
            {activityOwner?.displayName || t('unknown_user')}
          </span>
        </div>

        <div className={styles.timeAgo}>
          {formatDistance(
            zonedTimeToUtc(activity?.time as string, 'UTC'),
            new Date(),
            {
              addSuffix: true,
              includeSeconds: true,
            }
          )}
        </div>
      </div>

      <MemoizedPostMenus
        activity={activity}
        mentionSuggestions={mentionSuggestions}
        attachedFiles={activity.attachedFiles}
      />
    </div>
  );
};

export const MemoizedPostHeader = memo(PostHeader);
