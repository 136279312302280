import SendIcon from '@mui/icons-material/Send';

import { Mention } from '@xq/domain';

import {
  useIsEditorEmpty,
  useSubmitEditorState,
  StyledEditorIconButton,
} from '../../../../TextEditor';

type PostActivityIconButtonProps = {
  onClick: (content: string, mentions: Mention[]) => void;
  mentionSuggestions: Mention[];
};

export const PostActivityIconButton = ({
  mentionSuggestions,
  onClick,
}: PostActivityIconButtonProps) => {
  const { isEditorEmpty } = useIsEditorEmpty();
  const { submitEditorState } = useSubmitEditorState(
    mentionSuggestions,
    onClick
  );

  return (
    <StyledEditorIconButton
      onClick={submitEditorState}
      disabled={isEditorEmpty}
      data-cy="post-icon-button"
    >
      <SendIcon />
    </StyledEditorIconButton>
  );
};
