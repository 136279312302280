import { useEffect } from 'react';
import {
  doc,
  DocumentReference,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore';

import { Classroom, Program } from '@xq/domain';
import { CollectionNames } from '@xq/shared/data-access';
import {
  FBProgramProgress,
  firebaseService,
  loggerService,
  ProgramProgressMapper,
  subscriptionManager,
} from '@xq/infrastructure';
import { allEqual } from '@xq/shared/utils';

import { useAppDispatch, userProgramProgressActions } from '../store';
import { useMemoCompare } from './useMemoCompare';

type SubscribePayload = {
  query: DocumentReference;
  programId?: string;
};

type Props = {
  userIds: string[];
  classroom: Classroom | null;
  programs: Program[] | null;
};

export const useStudentsProgramProgressSubscription = ({
  userIds,
  classroom,
  programs,
}: Props) => {
  const dispatch = useAppDispatch();

  const shouldCreateQuery = useMemoCompare(userIds, (prev, next) => {
    if (!prev) {
      return false;
    }

    return allEqual(next, prev);
  });

  useEffect(() => {
    if (!classroom || !programs?.length) {
      return;
    }

    const queries: SubscribePayload[] = userIds
      .map((id: string) =>
        programs.map((p) => ({
          programId: p?.id,
          query: doc(
            firebaseService.db,
            `${CollectionNames.users}/${id}/${CollectionNames.programProgress}/${p?.slug}-${classroom.id}`
          ),
        }))
      )
      .flat();

    const subscriptions: Unsubscribe[] = [];

    const subscribe = ({ query, programId }: SubscribePayload) => {
      const sub = onSnapshot(
        query,
        (snapshot) => {
          if (
            snapshot.exists() &&
            snapshot.ref.parent.parent?.id &&
            programId
          ) {
            const programProgress = ProgramProgressMapper.toDomain(
              snapshot.data() as FBProgramProgress
            );

            dispatch(
              userProgramProgressActions.setProgramProgress({
                userId: snapshot.ref.parent.parent.id,
                classroomId: classroom.id,
                programId,
                programProgress,
              })
            );
          }
        },
        (e) => {
          loggerService.error('PROGRAM PROGRESS SUBSCRIPTION ERROR: ', e);
        }
      );

      subscriptions.push(sub);
    };

    queries.forEach(subscribe);
    subscriptionManager.add(subscriptions);

    return () => subscriptionManager.remove(subscriptions);
  }, [shouldCreateQuery, classroom, programs]);
};
