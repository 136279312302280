import type { AdditionalResources, Phase, Document } from '@xq/domain';
import { ContentType } from '@xq/domain';

type InResourcesProps = {
  inResources?: boolean;
  phasesContent: PhasesContent;
  templates?: Document[];
};

type GetTabsProps = InResourcesProps;

export type PhasesContent = {
  [ContentType.PhaseContent]: Phase | null;
  [ContentType.AdditionalResources]: AdditionalResources[];
  [ContentType.StudentContent]: Phase | null;
  [ContentType.TeacherResources]: Document[] | null;
};

export const TABS_ORDER = [
  ContentType.PhaseContent,
  ContentType.AdditionalResources,
  ContentType.StudentContent,
];

export const IN_RESOURCES_TABS_ORDER = [
  ContentType.PhaseContent,
  ContentType.StudentContent,
  ContentType.AdditionalResources,
  ContentType.TeacherResources,
];

export type TabsLabels =
  | 'student_content'
  | 'additional-resources'
  | 'teacher_guide'
  | 'phase-content'
  | 'teacher-resources';

export type PhaseTabsConfig = {
  name: ContentType;
  label: TabsLabels;
  isVisible: () => boolean;
};

export const getTabConfig = ({
  inResources,
  phasesContent,
}: InResourcesProps): PhaseTabsConfig[] => [
  {
    name: ContentType.StudentContent,
    label: 'student_content',
    isVisible: () =>
      !!phasesContent?.[ContentType.StudentContent]?.subPhaseValues?.length,
  },
  {
    name: ContentType.AdditionalResources,
    label: 'additional-resources',
    isVisible: () => !!phasesContent?.[ContentType.AdditionalResources].length,
  },
  {
    name: ContentType.PhaseContent,
    label: inResources ? 'teacher_guide' : 'phase-content',
    isVisible: () =>
      !!phasesContent?.[ContentType.PhaseContent]?.subPhaseValues?.length,
  },
  {
    name: ContentType.TeacherResources,
    label: 'teacher-resources',
    isVisible: () => !!inResources,
  },
];

export const getFilteredTabs = ({
  phasesContent,
  inResources = false,
}: GetTabsProps) => {
  const sortingArr = inResources ? IN_RESOURCES_TABS_ORDER : TABS_ORDER;

  return getTabConfig({ inResources, phasesContent })
    .filter((t) => t.isVisible())
    .map(({ label, name }) => ({ name, label }))
    .sort((a, b) => sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name));
};
