import { lazy, PropsWithChildren, useCallback } from 'react';
import { isEmpty } from 'lodash';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import {
  ErrorPage,
  GuardedRoute,
  selectCurrentUserState,
  useAppSelector,
  selectIsImpersonating,
  LoaderLogo,
  useFirebaseAuthUser,
} from '@xq/web-components';

import { UserRoles } from '@xq/domain';
import { TEACHER_APP_ROUTES } from '@xq/web-config';

import { ReactComponent as logo } from '../images/teacher-logo-no-sub.svg';
import { getAuthorisedRoutes, getUnAuthorisedRoutes } from './routes';
const ChooseRole = lazy(() => import('./Screens/ChooseRole/ChooseRole'));

export const AppRouteProvider = ({ children }: PropsWithChildren) => {
  const currentUser = useAppSelector(selectCurrentUserState);
  const isImpersonating = useAppSelector(selectIsImpersonating);
  const { userAuthState, setUserAuthState, authUser } = useFirebaseAuthUser();
  const isNotViewer = !currentUser?.roles?.includes(UserRoles.viewer);

  const getRoutes = useCallback(() => {
    if (userAuthState === 'choseRole' && isEmpty(currentUser)) {
      return [
        {
          path: '*',
          element: <Navigate to={TEACHER_APP_ROUTES.getChooseRole()} replace />,
          errorElement: <ErrorPage isTeacherApp />,
        },
        {
          path: TEACHER_APP_ROUTES.getChooseRole(),
          element: (
            <GuardedRoute isAllowed={isNotViewer}>
              <ChooseRole logo={logo} />
            </GuardedRoute>
          ),
          errorElement: <ErrorPage isTeacherApp />,
        },
      ];
    }

    if (userAuthState === 'loggedOut') {
      return getUnAuthorisedRoutes(
        isImpersonating,
        userAuthState,
        setUserAuthState,
        authUser
      );
    }

    return getAuthorisedRoutes(isNotViewer, userAuthState, authUser);
  }, [userAuthState, isImpersonating, isNotViewer, currentUser]);

  if (userAuthState === 'loading') {
    return <LoaderLogo />;
  }

  const router = createBrowserRouter(getRoutes());

  return <RouterProvider router={router} />;
};
