import {
  ClassroomRepository,
  SchoolRepository,
  UserRepository,
} from '@xq/infrastructure';

const classroomRepository = new ClassroomRepository();
const schoolRepository = new SchoolRepository();
const userRepository = new UserRepository();

export class CheckUserRolesByReposUseCase {
  static async execute(email: string | null, uid: string) {
    const isViewer = await classroomRepository.checkUserRoleByFieldPath(
      email,
      'viewerEmails'
    );
    const isStudent = await classroomRepository.checkUserRoleByFieldPath(
      email,
      'participantEmails'
    );
    const isTeacher = await classroomRepository.checkUserRoleByFieldPath(
      email,
      'teacherEmails'
    );
    const isAdmin = await schoolRepository.isAdmin(email);

    const userExists = await userRepository.userExists(uid);

    return { isViewer, isStudent, isTeacher, isAdmin, userExists };
  }
}
