import { z } from 'zod';

export const ValidateEmailsValidation = z.object({
  teacherEmails: z.string().array(),
  participantEmails: z.string().array(),
});

export type ValidateEmailsRequestDto = z.infer<typeof ValidateEmailsValidation>;

export type ValidateEmailsResponseDto = {
  invalidEmails: string[];
  alreadyViewers: { email: string; classroomNames: string[] }[];
};
