import { Avatar, ListItem, ListItemText, styled } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

type ListItemProps = {
  showClassroomIcon: boolean;
};

export const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'showClassroomIcon',
})<ListItemProps>(({ showClassroomIcon }) => {
  return {
    alignItems: 'self-start',
    position: 'relative',
    justifyContent: 'space-between',
    marginTop: 0,
    paddingLeft: '16px',
    paddingRight: '23px',
    paddingTop: '10px',
    ...(showClassroomIcon && {
      '&:before': {
        content: 'attr(data-classroom-index)',
        position: 'absolute',
        backgroundColor: 'var(--green-primary)',
        padding: '4.8px',
        fontSize: '8px',
        fontWeight: 'bold',
        top: '10px',
        left: '13px',
        width: '16px',
        height: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: 'calc(100% - 16px - 23px)',
        height: '1px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        display: 'block',
        marginLeft: '16px',
      },
    }),
  };
});

type NotificationProps = {
  isRead: boolean;
};

export const StyledNotificationText = styled('div')<NotificationProps>(
  ({ isRead }) => ({
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: isRead ? 'var(--gray-suit)' : 'var(--white)',
    cursor: 'pointer',
    wordBreak: 'break-word',
  })
);

export const StyledNewContainer = styled('div')<NotificationProps>(
  ({ isRead }) => ({
    display: isRead ? 'none' : 'flex',
  })
);

export const StyledNew = styled('div')`
  background-color: var(--purple-medium);
  width: 32px;
  height: 16px;
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: var(--white);
  font-weight: bold;
  border-radius: 4px;
  line-height: 17px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

export const StyleItemText = styled(ListItemText)`
  margin-top: 0;
`;

export const StyledNotificationContainer = styled('div')`
  padding: 0 16px;
`;

export const StyledNotificationName = styled('span')`
  font-weight: bold;
  cursor: pointer;
`;

export const StyledNotificationTime = styled('p')`
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(213, 213, 213, 0.5);
  cursor: pointer;
`;

export const StyledDeleteIcon = styled(DeleteOutlineIcon)`
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
  color: var(--white);
  cursor: pointer;
`;

export const StyledNotificationActionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledNotification = styled('div')`
  display: flex;
`;

export const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;
