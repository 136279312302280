import { Grid, styled, Button } from '@mui/material';

export const StyledGrid = styled(Grid)`
  position: relative;
  height: 100vh;
  overflow: auto;
`;

export const StyledPhases = styled('div')`
  display: grid;
  grid-template-rows: repeat(2, max-content) 1fr;
  width: 100%;
  height: 100%;
  background: var(--white);
  margin-left: 24px;
  margin-right: 16px;

  @media (max-width: 640px) {
    margin: 0;
    padding-top: 12px;
    grid-template-rows: max-content 1fr;
  }
`;

export const StyledProgramSelectContainer = styled('div')`
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

export const StyledFullscreenButton = styled(Button)`
  color: var(--black);
  text-transform: none;
  margin-right: 15px;
`;
