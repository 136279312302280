import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cond } from 'lodash';

import { useModal } from '../context';
import { useCallbackPrompt } from './useCallbackPrompt';

type UseModalProps = {
  when: boolean;
  safeRoute: string[];
};

export const useNavigationWarningModal = ({
  when,
  safeRoute,
}: UseModalProps) => {
  const [showPrompt] = useCallbackPrompt(when, safeRoute);

  const { t } = useTranslation();

  const {
    dispatchModal,
    state: { isOpen, isConfirmed },
    onClose,
  } = useModal();

  useEffect(() => {
    cond([
      [
        () => showPrompt,
        () => {
          dispatchModal({
            isOpen: true,
            bodyText: t('progress-loss-warning'),
            actionButtonText: t('continue'),
          });
        },
      ],
    ])();
  }, [isConfirmed, showPrompt, isOpen]);
};
