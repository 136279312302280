import { createSelector } from '@reduxjs/toolkit';

import { User, computeRoles } from '@xq/domain';

import { selectUsersState } from '../index';
import { selectAllClassrooms } from '../classrooms';
import { usersAdaptor } from './users-slice';

const { selectAll, selectById, selectEntities } =
  usersAdaptor.getSelectors(selectUsersState);

export const selectAllUsers = createSelector(
  [selectAll, selectAllClassrooms],
  (users, classrooms) => {
    return users.map((user) => {
      return {
        ...user,
        ...computeRoles(user.email, classrooms),
      };
    });
  }
);

export const selectUsersEntities = createSelector(
  [selectEntities, selectAllClassrooms],
  (userEntities, classrooms) => {
    const output: Record<string, User> = Object.entries(userEntities)
      .filter((entries): entries is [string, User] => !!entries[1])
      .map(([id, user]): [string, User] => [
        id,
        { ...user, ...computeRoles(user.email, classrooms) },
      ])
      .reduce(
        (previousValue, [id, user]) => ({ ...previousValue, [id]: user }),
        {}
      );

    return output;
  }
);

export const selectUserById = createSelector(
  [selectById, selectAllClassrooms],
  (user, classrooms) => {
    if (!user) {
      return undefined;
    }

    return {
      ...user,
      ...computeRoles(user.email, classrooms),
    };
  }
);

export const selectAllClassroomsStudents = createSelector(
  [selectAllClassrooms, selectAllUsers],
  (classrooms, users) => {
    return classrooms
      .map((classroom) => {
        return classroom.participantEmails.reduce((previousValue, email) => {
          const user = users.find((u) => u.email === email);

          return [...previousValue, ...(user ? [user] : [])];
        }, [] as User[]);
      })
      .flat(1);
  }
);

export const selectAllClassroomsTeachers = createSelector(
  [selectAllClassrooms, selectAllUsers],
  (classrooms, users) => {
    return classrooms
      .map((classroom) => {
        return classroom.teacherEmails.reduce((previousValue, email) => {
          const user = users.find((u) => u.email === email);

          return [...previousValue, ...(user ? [user] : [])];
        }, [] as User[]);
      })
      .flat(1);
  }
);

export const selectUsersByEmail = createSelector(
  [selectAllUsers, (_, emails: string[]) => emails],
  (state, emails) => {
    return state.filter((user) => emails.includes(user.email));
  }
);

export const selectUsersByIds = createSelector(
  [selectUsersEntities, (_, ids: string[]) => ids],
  (users, ids) => {
    return ids
      .map((id) => users[id])
      .filter((user): user is User => Boolean(user));
  }
);

export const selectUsersEntitiesByIds = createSelector(
  [selectUsersEntities, (_, ids: string[]) => ids],
  (users, ids) => {
    return ids
      .map((id) => users[id])
      .filter((user): user is User => Boolean(user))
      .reduce(
        (previousValue, user) => ({
          ...previousValue,
          [user.uid]: user,
        }),
        {} as Record<string, User>
      );
  }
);

export const selectAllTeacherAndStudentsFromClassroom = createSelector(
  [
    selectAllUsers,
    (_, emails: string[]) => emails,
    (_, __, classroomId: string) => classroomId,
    (_, __, ___, exceptionEmails: string[]) => exceptionEmails,
  ],
  (state, emails, classroomId, excludeUsersByEmail) => {
    return state.filter(
      (u) =>
        emails.includes(u.email) &&
        u.classroomIds.includes(classroomId) &&
        !excludeUsersByEmail.includes(u.email)
    );
  }
);
