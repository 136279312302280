import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';

import { InsertHTMLPlugin } from './plugins';
import { readonlyEditorConfig } from './textEdtiorConfig';

type ReadOnlyTextEditorProps = {
  htmlString: string;
};

export const ReadOnlyTextEditor = ({ htmlString }: ReadOnlyTextEditorProps) => {
  return (
    <LexicalComposer initialConfig={readonlyEditorConfig}>
      <PlainTextPlugin
        contentEditable={<ContentEditable />}
        placeholder={<span />}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <ListPlugin />
      <InsertHTMLPlugin htmlString={htmlString} />
    </LexicalComposer>
  );
};
