import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ContentType, ProgramPost } from '@xq/domain';
import { loggerService } from '@xq/infrastructure';

import { fetchProgramPost } from './program-post-actions';
import {
  PhasesContent,
  TabsLabels,
} from '../../context/phase-context/getPhaseTabs';

export type PhaseTab = {
  name: ContentType;
  label: TabsLabels;
};

type SelectedSubPhase = {
  selectedSubPhase: string | null;
  subPhases: Record<string, string | null>;
};

type SelectedTab = {
  selectedTab: string | null;
  tabs: Record<string, SelectedSubPhase>;
};

type SelectedPhase = Record<string, SelectedTab>;

export type SelectedPhasePayload = {
  phaseIndex: string;
  tabIndex: string;
  subPhaseIndex: string;
  subPhaseContentIndex: string;
};

export type PhaseView = 'tabs' | 'list';

type ProgramPostSliceState = {
  loading: boolean;
  selectedSlug: string;
  phasesContent: PhasesContent[];
  phaseTabs: PhaseTab[];
  phaseSelection: SelectedPhase;
  phaseView: PhaseView;
};

export const programPostAdapter = createEntityAdapter<ProgramPost>({
  selectId: (program) => program.slug,
});

export const programPostSlice = createSlice({
  name: 'program-post',
  initialState: programPostAdapter.getInitialState<ProgramPostSliceState>({
    loading: true,
    selectedSlug: '',
    phasesContent: [],
    phaseTabs: [],
    phaseSelection: {},
    phaseView: 'tabs',
  }),
  reducers: {
    setPhaseView: (state, { payload }: PayloadAction<PhaseView>) => {
      state.phaseView = payload;
    },
    setSelectedSlug: (state, { payload }: PayloadAction<string>) => {
      state.selectedSlug = payload;
    },
    setPhasesContent: (state, { payload }: PayloadAction<PhasesContent[]>) => {
      state.phasesContent = payload;
    },
    setPhaseTabs: (state, { payload }: PayloadAction<PhaseTab[]>) => {
      state.phaseTabs = payload;
    },
    resetSelectedPhase: (state) => {
      state.phaseSelection = state.phasesContent.reduce(
        (prevValue, phaseContent, currentIndex) => ({
          ...prevValue,
          [currentIndex]: {
            selectedTab: null,
            tabs: Object.keys(phaseContent).reduce(
              (previousValue, tab) => ({
                ...previousValue,
                [tab]: null,
              }),
              {}
            ),
          },
        }),
        {}
      );
    },
    setSelectedPhase: (
      state,
      {
        payload: { phaseIndex, subPhaseIndex, tabIndex, subPhaseContentIndex },
      }: PayloadAction<SelectedPhasePayload>
    ) => {
      state.phaseSelection[phaseIndex] = {
        selectedTab: tabIndex,
        tabs: {
          ...state.phaseSelection[phaseIndex]?.tabs,
          [tabIndex]: {
            selectedSubPhase: subPhaseIndex,
            subPhases: {
              ...state.phaseSelection[phaseIndex]?.tabs[tabIndex]?.subPhases,
              ...(subPhaseIndex !== '-1' && {
                [subPhaseIndex]: subPhaseContentIndex,
              }),
            },
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProgramPost.pending, (state, action) => {
      if (!state.entities[action.meta.arg.slug]) {
        state.loading = true;
      }
    });
    builder.addCase(fetchProgramPost.fulfilled, (state, { payload }) => {
      state.loading = false;
      programPostAdapter.setOne(state, payload);
    });
    builder.addCase(fetchProgramPost.rejected, (state, { error }) => {
      state.loading = false;
      loggerService.error(error);
    });
  },
});

export const programPostActions = programPostSlice.actions;
