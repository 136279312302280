import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ClassroomIntegrationState,
  Pages,
} from './classroom-integration.interfaces';
import { fetchCurrentUserGoogleClassrooms } from './classroom-integration-actions';

const initialState: ClassroomIntegrationState = {
  showIntegrationWizard: false,
  page: Pages.welcome,
  selectedClassroomIds: [],
  selectedSchoolId: '',
  selectedSchoolIdFromList: '',
  isLoadingGoogleClassrooms: false,
  isLoadingUpdateClassroom: false,
  googleClassrooms: [],
};

export const classroomIntegrationSlice = createSlice({
  name: 'classroom-integration',
  initialState: initialState,
  reducers: {
    setIntegrationWizard: (
      state,
      { payload }: PayloadAction<{ show: boolean }>
    ) => {
      state.showIntegrationWizard = payload.show;
    },
    setPage: (state, { payload }: PayloadAction<{ page: Pages }>) => {
      state.page = payload.page;
    },
    hideIntegrationWizard: (state) => {
      state.showIntegrationWizard = false;
      state.page = Pages.welcome;
    },
    reset: () => initialState,
    goBack: (state) => {
      const pageOrder = [
        Pages.welcome,
        Pages.selectSchool,
        Pages.yourClassrooms,
        Pages.assignModule,
      ];
      const currentPageIndex = pageOrder.indexOf(state.page);

      if (currentPageIndex > 0) {
        state.page = pageOrder[currentPageIndex - 1];
      }
    },
    setLoadingUpdateClassroom: (
      state,
      { payload }: PayloadAction<{ loading: boolean }>
    ) => {
      state.isLoadingUpdateClassroom = payload.loading;
    },
    selectedClassroomIds: (
      state,
      { payload }: PayloadAction<{ ids: string[] }>
    ) => {
      state.selectedClassroomIds = payload.ids;
    },
    selectedSchool: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.selectedSchoolId = payload.id;
    },
    selectedSchoolFromList: (
      state,
      { payload }: PayloadAction<{ id: string }>
    ) => {
      state.selectedSchoolIdFromList = payload.id;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUserGoogleClassrooms.pending, (state) => {
      state.isLoadingGoogleClassrooms = true;
    });
    builder.addCase(
      fetchCurrentUserGoogleClassrooms.fulfilled,
      (state, { payload }) => {
        state.googleClassrooms = payload;
        state.isLoadingGoogleClassrooms = false;
      }
    );
    builder.addCase(fetchCurrentUserGoogleClassrooms.rejected, (state) => {
      state.isLoadingGoogleClassrooms = false;
    });
  },
});

export const classroomIntegrationActions = classroomIntegrationSlice.actions;
