import { IClassroomRepository, ClassroomRepository } from '@xq/infrastructure';

export class PersistFeedCommentsCountUseCase {
  classroomRepository: IClassroomRepository;

  constructor() {
    this.classroomRepository = new ClassroomRepository();
  }

  async execute(
    userId: string,
    classroomId: string,
    operator: string,
    deletedCommentsCount?: number
  ): Promise<void> {
    return this.classroomRepository.persistFeedCommentsCount(
      userId,
      classroomId,
      operator,
      deletedCommentsCount
    );
  }
}
