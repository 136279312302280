import { ValidateEmailsResponseDto } from '@xq/shared/data-access';
import {
  emailBackendValidation,
  emailDomainValidation,
  emailUniqueValidation,
  InvalidEmail,
  ValidationTestResult,
} from './index';

type EmailFullValidationProps = {
  newEmails: string[];
  addedEmails: string[];
  emailsInUse: string[];
  previousInvalidEmails: InvalidEmail[];
  backendValidation: (emails: string[]) => Promise<ValidateEmailsResponseDto>;
  domains: string[];
};

export const emailFullValidation = async ({
  newEmails,
  domains,
  backendValidation,
  emailsInUse,
  addedEmails,
  previousInvalidEmails,
}: EmailFullValidationProps): Promise<ValidationTestResult> => {
  const { valid: uniqueValid, inValid: uniqueInValid } = emailUniqueValidation({
    newEmails,
    addedEmails,
    emailsInUse,
    previousInvalidEmails,
  });

  const { valid: domainValid, inValid: domainInValid } = emailDomainValidation({
    newEmails: uniqueValid,
    domains,
  });

  const { valid: backendValid, inValid: backendInValid } =
    await emailBackendValidation({
      newEmails: domainValid,
      backendValidation,
      previousInvalidEmails,
    });

  return {
    valid: backendValid,
    inValid: [...uniqueInValid, ...domainInValid, ...backendInValid],
  };
};
