import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import type { Program } from '@xq/domain';

import { fetchPrograms } from './program-actions';

export const programAdapter = createEntityAdapter<Program>({
  selectId: (program) => program.id,
});

export const programSlice = createSlice({
  name: 'program',
  initialState: programAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrograms.fulfilled, (state, action) => {
      programAdapter.setMany(state, action.payload);
    });
  },
});

export const programActions = programSlice.actions;
