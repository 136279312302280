import { styled } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const StyledText = styled('p')`
  display: flex;
  justify-content: center;
  margin-bottom: 0;
`;

export const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 0 20px;
`;

export const ErrorIcon = styled(WarningIcon)`
  color: var(--hot-cinnamon);
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

export const SuccessIcon = styled(CheckCircleOutlineIcon)`
  color: var(--green-primary);
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;
