import { MenuItem, Select, styled } from '@mui/material';

export const StyledSelect = styled(Select)<{ ismobile: 'true' | 'false' }>`
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 800;
  ${({ ismobile }) =>
    ismobile === 'true' &&
    `
    max-width: calc(50vw - 50px);
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 800;
    margin: 10px 5px;
  `}
`;

export const StyledSelectProgramItem = styled(MenuItem)`
  max-width: 450px;
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
`;

export const StyledMenuTitle = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
`;
