import { useParams, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { isPhaseComplete, ProgramProgress } from '@xq/domain';

import styles from './ribbon-navigation.module.scss';
import { RibbonItem } from './RibbonItem';
import { usePhase } from '../context';
import {
  programPostActions,
  SelectedPhasePayload,
  selectIsPrinting,
  selectPhaseSelections,
  selectProgramPost,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { cx } from '@emotion/css';
import { StudentWizardStep } from '../hooks/useStudentWizardGuide';

export interface RibbonNavigationProps {
  activePhaseIndex?: number;
  isTeacher?: boolean;
  programProgress?: ProgramProgress;
}

export interface IPhaseTitlesBySlug {
  [key: string]: { title: string; subTitle: string };
}

const phaseTitlesBySlug: IPhaseTitlesBySlug = {
  preparation: { title: 'Preparation', subTitle: '' },
  'phase-i-activate': { title: 'Activate', subTitle: 'Phase Ⅰ' },
  'phase-ii-pitch': { title: 'Pitch', subTitle: 'Phase II' },
  'phase-iii-prototype': { title: 'Prototype', subTitle: 'Phase III' },
  'phase-iv-finish-share': { title: 'Finish and Share', subTitle: 'Phase IV' },
  'phase-v-reflect': { title: 'Reflect', subTitle: 'Phase V' },
};

export const RibbonNavigation = ({
  programProgress,
  isTeacher,
  activePhaseIndex,
}: RibbonNavigationProps) => {
  const navigate = useNavigate();
  const { phaseIndex = '0' } = useParams();
  const { inResources, phasePath } = usePhase();
  const dispatch = useAppDispatch();

  const programPost = useAppSelector(selectProgramPost);
  const phaseSelections = useAppSelector(selectPhaseSelections);
  const isPrinting = useAppSelector(selectIsPrinting);

  const onRibbonClick = (index: number) => {
    const selectedPhase = phaseSelections[index];
    const selectedTab = selectedPhase?.tabs?.[selectedPhase.selectedTab ?? ''];
    const subPhaseIndex = selectedTab?.selectedSubPhase ?? '-1';
    const subPhaseContentIndex = selectedTab?.subPhases[subPhaseIndex] ?? '-1';

    const payload: SelectedPhasePayload = {
      phaseIndex: index.toString(),
      tabIndex: selectedPhase?.selectedTab ?? '0',
      subPhaseIndex: subPhaseIndex ?? '0',
      subPhaseContentIndex,
    };

    navigate(
      phasePath(
        payload.phaseIndex,
        payload.tabIndex,
        payload.subPhaseIndex,
        payload.subPhaseContentIndex
      )
    );

    dispatch(programPostActions.setSelectedPhase(payload));
  };

  const phases = isTeacher
    ? programPost?.teacherPhaseValues || []
    : programPost?.studentPhaseValues || [];

  const maxWidth =
    document.getElementById('resourceContainer')?.offsetWidth || 0;

  return (
    <Grid
      id={StudentWizardStep.PHASE_CONTENT_PHASES}
      className={cx({
        [styles.ribbonContainer]: !isPrinting,
        [styles.printRibbonContainer]: isPrinting,
      })}
      maxWidth={isPrinting ? maxWidth : undefined}
    >
      {phases.map((phase, index) => (
        <RibbonItem
          allDone={
            inResources
              ? false
              : isPhaseComplete(phases, phase.id, programProgress)
          }
          isFirst={index === 0}
          isLast={index === phases.length - 1}
          activePhaseIndex={activePhaseIndex}
          titles={phaseTitlesBySlug[phase.slug]}
          index={index}
          key={phase.id}
          onRibbonClick={(id) => onRibbonClick(id)}
          currentPhaseIndex={+phaseIndex}
          totalItems={phases?.length || 0}
        />
      ))}
    </Grid>
  );
};
