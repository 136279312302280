import SvgIcon from '@mui/material/SvgIcon';
import { styled, useMediaQuery } from '@mui/material';
import { MOBILE_MEDIA_QUERY } from '../utils/constants';

export const StyledIcon = styled(SvgIcon)`
  width: 20px;
  height: 24px;
`;

export const NotificationsIcon = () => {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  return (
    <StyledIcon
      fill={isMobile ? 'black' : 'white'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 24"
    >
      <path
        d="M9.99996 23.6667C11.2833 23.6667 12.3333 22.6167 12.3333 21.3334H7.66663C7.66663 22.6167 8.71663 23.6667 9.99996 23.6667ZM17 16.6667V10.8334C17 7.25169 15.0983 4.25335 11.75 3.46002V2.66669C11.75 1.69835 10.9683 0.916687 9.99996 0.916687C9.03162 0.916687 8.24996 1.69835 8.24996 2.66669V3.46002C4.91329 4.25335 2.99996 7.24002 2.99996 10.8334V16.6667L0.666626 19V20.1667H19.3333V19L17 16.6667ZM14.6666 17.8334H5.33329V10.8334C5.33329 7.94002 7.09496 5.58335 9.99996 5.58335C12.905 5.58335 14.6666 7.94002 14.6666 10.8334V17.8334Z"
        fill={isMobile ? 'black' : 'white'}
      />
    </StyledIcon>
  );
};
