import { createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { selectSchoolState } from '../index';
import { schoolAdapter } from './school-slice';

export const {
  selectEntities: selectSchoolEntities,
  selectById: selectSchoolById,
  selectAll: selectAllSchools,
} = schoolAdapter.getSelectors(selectSchoolState);

export const selectClassroomIntegrationSchools = createSelector(
  [selectAllSchools],
  (state) => {
    return [...state].sort((x, y) => {
      const a = x.name.toUpperCase();
      const b = y.name.toUpperCase();
      return a === b ? 0 : a > b ? 1 : -1;
    });
  }
);

export const selectCreateClassroomSchools = createSelector(
  [selectClassroomIntegrationSchools],
  (state) =>
    state.map((school, index) => ({
      ...school,
      index,
      name: school.name + ' (' + school.state + ')',
    }))
);

export const selectAdministrationSchoolsWithFolders = createSelector(
  [selectCreateClassroomSchools],
  (schools) => schools.filter((s) => s.driveRootFolderId)
);

export const selectSchoolsIsLoading = createSelector(
  [selectSchoolState],
  (state) => state.loading
);

export const selectSchoolsClassroomIds = createSelector(
  [selectAllSchools],
  (state) => uniq(state.map((s) => s.classroomsIds).flat())
);
