import { SubPhase } from './SubPhase';

export enum ContentType {
  StudentContent,
  AdditionalResources,
  PhaseContent,
  TeacherResources,
}

export interface AdditionalResources {
  title: string;
  src: string;
  slug: string;
}

export interface Phase {
  id: number;
  name: string;
  slug: string;
  subPhaseValues?: SubPhase[];
  additionalResources?: AdditionalResources[];
  contentType: ContentType.StudentContent | ContentType.PhaseContent;
}

export const getActivePhaseTitle = (allPhases: Phase[], activePhase?: Phase) =>
  activePhase?.name || allPhases?.[0]?.name || '';

export const getActiveSubPhaseTitle = (
  phases: Phase[],
  activeSubPhaseId?: number | null,
  activePhase?: Phase
) => {
  const firstSubPhaseTitle = phases?.[0]?.subPhaseValues?.[0]?.title || '';

  return activeSubPhaseId
    ? activePhase?.subPhaseValues?.find((v) => v.id === activeSubPhaseId)
        ?.title || firstSubPhaseTitle
    : phases?.[phases.length - 1]?.subPhaseValues?.[0]?.title ||
        firstSubPhaseTitle;
};

export const SELECTABLE_PARAGRAPH_REGEX =
  /<p\s+[^>]*data-selectable-paragraph[^>]*>.*?<\/p>/g;
