export enum JournalFeedScopes {
  CAN_VIEW = 'can_view_journal_feed',
  CAN_POST = 'can_post_journal_feed',
  CAN_EDIT_POST = 'can_edit_post',
  CAN_DELETE_POST = 'can_delete_post',
  CAN_MENTION = 'can_mention',
  CAN_VIEW_TAGS = 'can_view_tags',
  CAN_FILTER_BY_TAGS = 'can_filter_by_tags',
  CAN_COMMENT = 'can_comment',
  CAN_MARK_FOR_REVIEW = 'can_mark_for_review',
  CAN_UPLOAD_FILE = 'can_upload_file',
  CAN_REACT_WITH_EMOJIS = 'can_react_with_emojis_journal_feed',
}
