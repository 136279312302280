import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GetTeacherTemplatesBySchoolIdProps,
  loggerService,
  SchoolRepository,
  UserRepository,
  wordPressService,
} from '@xq/infrastructure';

import { TEMPLATE_SOURCE } from './templates-slice';

export const fetchTemplates = createAsyncThunk(
  'templates/fetchTemplates',
  async ({ teacherId, schoolId }: GetTeacherTemplatesBySchoolIdProps) => {
    const schoolRepository = new SchoolRepository();

    const userRepository = new UserRepository();

    try {
      return (
        await Promise.all([
          schoolRepository.getTemplatesBySchoolId(schoolId).then((templates) =>
            templates.map((t) => ({
              ...t,
              source: TEMPLATE_SOURCE.SCHOOL,
              schoolId,
            }))
          ),
          userRepository
            .getTeacherTemplatesBySchoolId({
              teacherId,
              schoolId,
            })
            .then((templates) =>
              templates.map((t) => ({
                ...t,
                source: TEMPLATE_SOURCE.TEACHER,
                schoolId,
              }))
            ),
        ])
      ).flat();
    } catch (e) {
      loggerService.error(e);

      throw e;
    }
  }
);

export const fetchWordPressTemplates = createAsyncThunk(
  'templates/fetchWordPressTemplates',
  async () => {
    const response = await wordPressService.fetchTemplates();

    return wordPressService.extractFileIdsFromPhaseContent(response);
  }
);
