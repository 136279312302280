import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import type { Document } from '@xq/domain';
import { WPTemplate } from '@xq/shared/data-access';

import { fetchTemplates, fetchWordPressTemplates } from './templates-actions';

export const TEMPLATE_SOURCE = {
  SCHOOL: 'SCHOOL',
  TEACHER: 'TEACHER',
} as const;

export type Template = Document & {
  source: keyof typeof TEMPLATE_SOURCE;
  schoolId: string;
};

export const templatesAdapter = createEntityAdapter<Template>({
  selectId: (document) => document.id,
});

export const templatesSlice = createSlice({
  name: 'templates',
  initialState: templatesAdapter.getInitialState<{
    templatedIdsByProgram: Record<string, string[]>;
    wpTemplates: Record<string, WPTemplate>;
  }>({
    templatedIdsByProgram: {},
    wpTemplates: {},
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      templatesAdapter.setAll(state, action.payload);
    });
    builder.addCase(
      fetchWordPressTemplates.fulfilled,
      (state, { payload: { templatedIdsByProgram, templates } }) => {
        state.templatedIdsByProgram = templatedIdsByProgram;
        state.wpTemplates = templates;
      }
    );
  },
});

export const templatesActions = templatesSlice.actions;
