import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@mui/material';

import { Activity, Mention } from '@xq/domain';

import { StyledLoadMore } from './Comments.styles';
import { selectCommentsByActivityId, useAppSelector } from '../../../../store';
import { CommentList } from './CommentList';
import { useFeed } from '../../../../context';

type CommentsProps = {
  activity: Activity;
  showComments: boolean;
  mentionSuggestions: Mention[];
};

export const Comments = ({
  activity,
  showComments,
  mentionSuggestions,
}: CommentsProps) => {
  const { t } = useTranslation();

  const { getComments } = useFeed();

  const comments = useAppSelector((state) =>
    selectCommentsByActivityId(state, activity.id)
  );
  const commentsCount = activity.reaction_counts?.comment ?? 0;
  const loadedCommentsCount = comments.length;
  const isLastPage = loadedCommentsCount >= commentsCount;

  const getNextComments = async (idLt: string) => {
    if (isLastPage) {
      return;
    }

    getComments(activity.id, idLt);
  };

  useEffect(() => {
    if (showComments && commentsCount > 0) {
      getComments(activity.id);
    }
  }, [commentsCount, activity.id, showComments]);

  return (
    <Collapse in={showComments}>
      <div
        style={{
          paddingTop: '8px',
        }}
      >
        <CommentList
          activity={activity}
          comments={comments}
          mentionSuggestions={mentionSuggestions}
        />
      </div>

      {!isLastPage && commentsCount >= loadedCommentsCount && (
        <StyledLoadMore
          onClick={() => getNextComments(comments[loadedCommentsCount - 1].id)}
        >
          {t('load_more_comments')}
        </StyledLoadMore>
      )}
    </Collapse>
  );
};

export const MemoizedComments = memo(Comments);
