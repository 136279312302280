import { useCallback } from 'react';
import { CLEAR_EDITOR_COMMAND, LexicalNode, ParagraphNode } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { Mention } from '@xq/domain';

import { extractMentions, JsonNode } from '../plugins/mentions';

export const useSubmitEditorState = (
  mentionSuggestions: Mention[],
  cb: (content: string, mentions: Mention[]) => Promise<void> | void
) => {
  const [editor] = useLexicalComposerContext();

  const filterEmptyNodes = (nodes: [string, LexicalNode][]) => {
    for (const [, node] of nodes) {
      if (node instanceof ParagraphNode) {
        const content = node.getTextContent().trim();

        if (content) {
          break;
        }

        node.remove();
      }
    }
  };

  const submitEditorState = useCallback(() => {
    editor.update(async () => {
      const editorState = editor.getEditorState();
      const nodes = Array.from(editorState._nodeMap);

      filterEmptyNodes(nodes);
      filterEmptyNodes(nodes.reverse());

      const htmlString = $generateHtmlFromNodes(editor, null);
      const parsedJson = JSON.parse(
        JSON.stringify(editorState.toJSON())
      ) as JsonNode;
      const extractedMentions = extractMentions(parsedJson);

      const mentions = extractedMentions
        .map((m) =>
          mentionSuggestions.find((s) => {
            return s.value === m.mentionValue;
          })
        )
        .filter(Boolean);

      editor.setEditable(false);
      await cb(htmlString, mentions);
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      editor.setEditable(true);
    });
  }, [cb, editor]);

  return {
    submitEditorState,
  };
};
