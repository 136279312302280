import { useEffect, useState } from 'react';
import { IconButton, Popover } from '@mui/material';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { sortBy } from 'lodash';

import { JournalFeedScopes, Reaction } from '@xq/domain';

import styles from './activities-reactions.module.scss';
import { EmojiCounter } from '../../../../EmojiCounter/EmojiCounter';
import { RestrictView } from '../../../../RestrictView/RestrictView';
import {
  selectCurrentUserState,
  selectUsersEntities,
  useAppSelector,
} from '../../../../store';
import { useFeed } from '../../../../context';
import { EMOJIS } from '../../../../utils';

type ActivitiesReactionsProps = {
  activityId: string;
  emojiCount: number;
};

export const ActivitiesReactions = ({
  activityId,
  emojiCount,
}: ActivitiesReactionsProps) => {
  const { updateReaction, deleteReaction, getReactions } = useFeed();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [reactions, setReactions] = useState<Reaction[]>([]);

  const users = useAppSelector(selectUsersEntities);
  const currentUser = useAppSelector(selectCurrentUserState);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateReactions = async (emoji: string) => {
    const ownReactions = reactions.filter(
      (reaction) => reaction.creatorId === currentUser.uid
    );
    const currentReaction = ownReactions.find(
      (reaction) => reaction.body === emoji
    );
    const newEmojiCount = currentReaction ? emojiCount - 1 : emojiCount + 1;

    if (currentReaction) {
      await deleteReaction(activityId, currentReaction.id, newEmojiCount);
    } else {
      await updateReaction(activityId, emoji, newEmojiCount);
    }

    if (newEmojiCount === 0) {
      setReactions([]);
    }
  };

  const getReactionsByActivity = async (activityId: string, count: number) => {
    const data = await getReactions(activityId, count);

    setReactions(data);
  };

  useEffect(() => {
    if (emojiCount > 0) {
      getReactionsByActivity(activityId, emojiCount);
    }
  }, [emojiCount, activityId]);

  const onSelectReaction = async (emoji: string) => {
    handleClose();
    updateReactions(emoji);
  };

  const counters = sortBy(reactions, (reaction) =>
    EMOJIS.indexOf(reaction.body)
  ).map((reaction) => ({
    emoji: reaction.body,
    by: users[reaction.creatorId]?.displayName ?? '',
  }));

  const open = Boolean(anchorEl);

  return (
    <div className={styles.emojiReactionsWrapper} tabIndex={0}>
      <RestrictView
        scopes={[
          JournalFeedScopes.CAN_REACT_WITH_EMOJIS,
        ]}
      >
        <IconButton
          onClick={handleClick}
          className={styles.insertEmoticonIcon}
          color="info"
        >
          <InsertEmoticonIcon />
        </IconButton>

        <Popover
          classes={{
            paper: styles.emojiSelector,
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {EMOJIS.map((emoji) => (
            <IconButton
              color="info"
              key={emoji}
              onClick={() => onSelectReaction(emoji)}
            >
              {emoji}
            </IconButton>
          ))}
        </Popover>
      </RestrictView>

      <EmojiCounter counters={counters} />
    </div>
  );
};
