export enum NotificationSupportedVerbs {
  addedForReview = 'addedForReview',
  removedFromReview = 'removedFromReview',
  addedDocumentForReview = 'addedDocumentForReview',
  addedCalculatorForReview = 'addedCalculatorForReview',
  removedCalculatorFromReview = 'removedCalculatorFromReview',
  removedDocumentFromReview = 'removedDocumentFromReview',
  reviewedDocument = 'reviewedDocument',
  reviewedCalculator = 'reviewedCalculator',
  sentAChatMessage = 'sentAChatMessage',
  taggedTeacherJournalFeed = 'taggedTeacherJournalFeed',
  taggedStudentJournalFeed = 'taggedStudentJournalFeed',
  generatedStudentTemplates = 'generatedStudentTemplates',
  generatedTeacherTemplates = 'generatedTeacherTemplates',
  generatedSchoolTemplates = 'generatedSchoolTemplates',
  generatedClassroomTemplates = 'generatedClassroomTemplates',
  finishedPhase = 'finishedPhase',
  newSchool = 'newSchool',
  updatedSchool = 'updatedSchool',
  systemCheckError = 'systemCheckError',
  initSchoolStructureError = 'initSchoolStructureError',
}

export const isTemplateNotification = (verb: NotificationSupportedVerbs) =>
  [
    NotificationSupportedVerbs.generatedStudentTemplates,
    NotificationSupportedVerbs.generatedTeacherTemplates,
    NotificationSupportedVerbs.generatedSchoolTemplates,
    NotificationSupportedVerbs.generatedClassroomTemplates,
  ].includes(verb);
