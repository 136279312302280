import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import { cx } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';

import { CreateClassroomUseCase } from '@xq/usecases';
import { loggerService } from '@xq/infrastructure';
import { ProviderType } from '@xq/domain';
import {
  AdministrationTabs,
  enqueueSnackbar,
  InfoField,
  LoadingOverlay,
  newClassroomActions,
  selectNewClassroomState,
  selectSchoolById,
  SnackbarVariant,
  StyledButton,
  StyledFooter,
  StyledLayoutContainer,
  StyledMain,
  StyledNextButton,
  useAppDispatch,
  useAppSelector,
  useNavigationWarningModal,
} from '@xq/web-components';
import { TEACHER_APP_ROUTES } from '@xq/web-config';

import styles from './classroom-summary.module.scss';
import { CreateFormHeader } from '../../components/CreateFormHeader';

export const ClassroomSummary = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const classroom = useAppSelector(selectNewClassroomState);
  const school = useAppSelector((state) =>
    selectSchoolById(state, classroom.schoolId)
  );

  useBeforeUnload();

  const onClickConfirm = async () => {
    try {
      setLoading(true);
      const { data } = await CreateClassroomUseCase.execute({
        ...omit(classroom, 'isDirty'),
        providerType: ProviderType.Manual,
      });

      dispatch(
        enqueueSnackbar({
          message: t('create_classroom_success', { name: data?.name }),
        })
      );
      await dispatch(newClassroomActions.clearNewClassroom());

      navigate(
        TEACHER_APP_ROUTES.getAdministration(AdministrationTabs.classrooms)
      );
    } catch (e) {
      loggerService.error(e);

      dispatch(
        enqueueSnackbar({
          message: t('create_classroom_error'),
          options: {
            variant: SnackbarVariant.ERROR,
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const isConfirmEnabled = useMemo(() => {
    return (
      classroom.shortName &&
      classroom.name &&
      classroom.schoolId &&
      classroom.teacherEmails.length
    );
  }, [classroom]);

  useNavigationWarningModal({
    when: classroom.isDirty,
    safeRoute: [TEACHER_APP_ROUTES.getAddingStudentsToNewClassroom()],
  });

  return (
    <LoadingOverlay isLoading={loading} height={'100%'} width={'100%'}>
      <StyledLayoutContainer>
        <CreateFormHeader
          completeIndexes={[0, 1, 2]}
          title={t('classroom_summary')}
          exitPath={TEACHER_APP_ROUTES.getAdministration(
            AdministrationTabs.classrooms
          )}
        />
        <StyledMain>
          <div className={styles.infoContainer}>
            <h1 className={cx(styles.title)}>{t('school_information')}</h1>

            <InfoField
              classes={{
                root: cx(styles.pb16, styles.pr16),
              }}
              label={t('classroom_name')}
              text={classroom.name}
            />
            <InfoField label={t('short_name')} text={classroom.shortName} />
            <InfoField
              classes={{
                root: cx(styles.pb16, styles.pr16),
              }}
              label={t('school_name')}
              text={school?.name}
            />
            <div>{/*placeholder*/}</div>
            <InfoField label={t('city_town')} text={school?.city} />
            <InfoField label={t('state')} text={school?.state} />

            <h1 className={cx(styles.title, styles.participantsTitle)}>
              {t('participants')}
            </h1>

            <InfoField
              label={t('teacher(s)')}
              text={`${classroom.teacherEmails.length} ${t(
                'teachers'
              ).toLowerCase()}`}
            />
            <InfoField
              label={t('student(s)')}
              text={`${classroom.participantEmails.length} ${t(
                'students'
              ).toLowerCase()}`}
            />
          </div>
        </StyledMain>

        <StyledFooter>
          <StyledButton
            onClick={() => {
              navigate(TEACHER_APP_ROUTES.getAddingStudentsToNewClassroom());
            }}
          >
            {t('back')}
          </StyledButton>
          <StyledNextButton
            loading={loading}
            onClick={onClickConfirm}
            disabled={!isConfirmEnabled}
            data-cy="confirm-classroom-button"
          >
            {t('confirm')}
          </StyledNextButton>
        </StyledFooter>
      </StyledLayoutContainer>
    </LoadingOverlay>
  );
};
