import { z } from 'zod';

import { MathTagTypes } from '../Tags';
import { FeedSlugs } from './Feed';

export type ReactionCounts = {
  comment: number;
  emoji: number;
};

export interface Actor {
  created_at: string;
  data: { name: string };
  id: string;
  updated_at: string | null;
}

export type JournalLink = {
  text: string;
  link: string;
};

export const AttachedFileSchema = z.object({
  id: z.string(),
  name: z.string(),
  size: z.string().optional(),
  iconLink: z.string(),
  mimeType: z.string(),
  webViewLink: z.string(),
});

export type AttachedFile = z.infer<typeof AttachedFileSchema>;

export interface Activity {
  id: string;
  actor: Actor;
  feedSlug: FeedSlugs;
  programId?: string;
  classroomId: string;
  autoTags: MathTagTypes[];
  object: unknown;
  message: string;
  isForReview: boolean;
  hidden?: boolean;
  verb: string;
  reaction_counts: ReactionCounts;
  foreign_id?: string;
  time?: string;
  journalLink?: JournalLink;
  attachedFiles: AttachedFile[];
}

export const remove = (prevActivities: Activity[], deleted: string) => {
  return prevActivities.filter((activity) => activity.id !== deleted);
};
