import { FeedService } from '@xq/infrastructure';
import { StreamClient } from 'getstream';

interface DeleteCommentProps {
  client: StreamClient;
  commentId: string;
}

export class DeleteCommentUseCase {
  static async execute({ client, commentId }: DeleteCommentProps) {
    return FeedService.getInstance(client).deleteComment(commentId);
  }
}
