import { StreamClient } from 'getstream';

import { FeedSlugs } from '@xq/domain';
import { FeedService } from '@xq/infrastructure';

export class ConnectToPersonalFeedUseCase {
  static execute(feedClient: StreamClient, slug: FeedSlugs, userId: string) {
    const feed = FeedService.getInstance(feedClient).createJournalFeed(
      [],
      slug,
      userId
    );

    return { feedId: feed.feedId, slug: feed.slug };
  }
}
