import type { Document } from '@xq/domain';
import { cx } from '@emotion/css';

import styles from './document-list.module.scss';
import { DeleteTagProps, DocumentItem } from './DocumentIem/DocumentItem';

type DocumentListProps = {
  className?: string;
  documents: Document[];
  canDeleteTag?: boolean;
  canViewTag?: boolean;
  onDeleteDocument?: (document: Document) => void;
  onMarkForReview?: (document: Document) => void;
  onDeleteTag?: (props: DeleteTagProps) => void;
  isResourcesTemplatesList?: boolean;
  onScroll?: () => void;
};

export const DocumentList = ({
  className,
  documents,
  onDeleteDocument,
  canDeleteTag,
  canViewTag,
  onMarkForReview,
  onDeleteTag,
  isResourcesTemplatesList = false,
  onScroll,
}: DocumentListProps) => {
  return (
    <div
      className={cx(styles.documentListContainer, className)}
      onScroll={onScroll}
      data-cy="document-list"
    >
      {documents.map((document) => (
        <DocumentItem
          key={document.id}
          document={document}
          canDeleteTag={canDeleteTag}
          canViewTag={canViewTag}
          deleteDocument={onDeleteDocument}
          onMarkForReview={onMarkForReview}
          onDeleteTag={onDeleteTag}
          isResourcesTemplatesList={isResourcesTemplatesList}
        />
      ))}
    </div>
  );
};
