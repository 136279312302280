import styles from './info-field.module.scss';

type InfoFieldProps = {
  classes?: {
    root: string;
  };
  label: string;
  text?: string;
};

export const InfoField = ({ label, text = '', classes }: InfoFieldProps) => {
  return (
    <div className={classes?.root}>
      <div className={styles.label}>{label}</div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};
