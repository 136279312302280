import { User } from '@xq/domain';

const createdAt = new Date().toISOString();
const modifiedAt = new Date().toISOString();

export const viewers: Partial<User>[] = [
  {
    createdAt,
    modifiedAt,
    classroomIds: [],
    displayName: '',
    email: 'first_time_login_viewer@xqlabsdev.io',
    uid: '',
    photoUrl:
      'https://lh3.googleusercontent.com/a/AATXAJyaU5cEL_0LJL7GZSDg33W-R0KEb2O9ZEi8H3XG=s96-c',
  },
  {
    createdAt,
    modifiedAt,
    classroomIds: [],
    displayName: '',
    email: 'viewer.role@xqlabs.io',
    uid: '',
    photoUrl:
      'https://lh3.googleusercontent.com/a/AATXAJyaU5cEL_0LJL7GZSDg33W-R0KEb2O9ZEi8H3XG=s96-c',
    // roleByClassroomId: {},
    // refreshToken: process.env.NX_E2E_VIEWER_REFRESH_TOKEN,
  },
];

const testViewerEmails = [
  'first_time_login_viewer@xqlabsdev.io',
  'viewer.role@xqlabs.io',
] as const;

export type TestViewerEmail = (typeof testViewerEmails)[number];

export const TEST_VIEWER_EMAILS = testViewerEmails.reduce(
  (acc, email) => ({
    ...acc,
    [email]: email,
  }),
  {} as Record<TestViewerEmail, TestViewerEmail>
);
