export class AppError extends Error {
  status: number;

  constructor(public type: AppErrorType, msg: string = type.toString()) {
    super(msg);
  }
}

export enum AppErrorType {
  FileNotFound,
}
