import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';

export class StorageService {
  private static instance: StorageService | null;

  static getInstance() {
    if (!StorageService.instance) {
      StorageService.instance = new StorageService();
    }
    return StorageService.instance;
  }

  getDownloadLink(path: string) {
    const storage = getStorage();
    const archiveRef = ref(storage, path);

    return getDownloadURL(archiveRef);
  }

  deleteFile(path: string) {
    const storage = getStorage();
    const archiveRef = ref(storage, path);

    return deleteObject(archiveRef);
  }
}
