import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

const StyledTeacherTag = styled('div')`
  font-weight: bold;
  font-size: 9px;
  line-height: 16px;
  letter-spacing: 0.4px;
  min-width: 56px;
  min-height: 16px;
  background-color: var(--green-primary);
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;

  p {
    font-size: 9px;
    margin: 0 auto;
    font-weight: bold;
    text-transform: uppercase;
  }

  padding: 2px 3px 2px 3px;
  margin-left: auto;
`;

export const TeacherLabel = () => {
  const { t } = useTranslation();

  return (
    <StyledTeacherTag>
      <p>{t('teacher')}</p>
    </StyledTeacherTag>
  );
};
