import { styled } from '@mui/material';

export const StyledDocumentViewer = styled('iframe')`
  width: 100%;
  border: none;
  height: 100%;
`;

export const StyledActionsHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const StyledContainer = styled('div')`
  display: grid;
  grid-template-rows: max-content 1fr;
  width: 100%;
`;
