import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './resource-item.module.scss';
import AttachFileIcon from '../../images/additional-resources.svg';

export type ResourceItem = {
  title: string;
  link: string;
};

type ResourceItemProps = {
  resource: ResourceItem;
};

const ResourceContent = ({ resource }: ResourceItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.resourceContainer}>
      <div>
        <img src={AttachFileIcon} alt={t('attach_file_icon')} />
      </div>
      <div className={styles.title}>{resource.title}</div>
    </div>
  );
};

export const ResourceLinkItem = ({ resource }: ResourceItemProps) => {
  return (
    <div className={styles.root}>
      <Link to={resource.link} className={styles.anchor}>
        <ResourceContent resource={resource} />
      </Link>
    </div>
  );
};

export const ResourceAnchorItem = ({ resource }: ResourceItemProps) => {
  return (
    <div className={styles.root}>
      <a href={resource.link} target="_blank" className={styles.anchor}>
        <ResourceContent resource={resource} />
      </a>
    </div>
  );
};
