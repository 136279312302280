import { z } from 'zod';

export const CreateTicketSchema = z.object({
  comment: z.object({
    body: z.string(),
    uploads: z.string().array().optional(),
  }),
  priority: z.string().optional(),
  subject: z.string(),
  requester: z.object({
    name: z.string(),
    email: z.string(),
  }),
});

export type CreateTicketRequestDto = z.infer<typeof CreateTicketSchema>;
