import { SubPhaseContent } from './SubPhaseContent';

export type SubPhase = {
  title: string;
  content: string;
  subPhaseContent: SubPhaseContent[];
  slug: string;
  id: number;
  phaseId: number;
  isStickyPost: boolean;
  desmosCalculator: string | null;
};

export const getActiveSubphaseId = (
  lastCompletedSubPhaseIndex?: number,
  currentSubPhases?: SubPhase[]
) =>
  lastCompletedSubPhaseIndex !== undefined
    ? currentSubPhases?.[
        lastCompletedSubPhaseIndex !== -1
          ? lastCompletedSubPhaseIndex + 1 <= currentSubPhases.length - 1
            ? lastCompletedSubPhaseIndex + 1
            : lastCompletedSubPhaseIndex
          : 0
      ]?.id
    : null;
