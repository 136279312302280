import {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper/types';
import { useMediaQuery } from '@mui/material';
import { useHash } from 'react-use';
import { useTour } from '@reactour/tour';

import { FeedSlugs, User } from '@xq/domain';
import {
  StyledGrid,
  StyledHeader,
  StyledJournal,
  StyledMobileDriveButtons,
  StyledSwiper,
} from './Journal.styles';
import { TabPanel } from '../TabPanel/TabPanel';
import { StudentFileList } from '../StudentFileList/StudentFileList';
import { StudentCalculatorList } from '../DesmosCalculator/StudentCalculatorList';
import { FeedProvider, UserFilesProvider } from '../context';
import { useStudentsFilesSubscription } from '../hooks';
import { MOBILE_MEDIA_QUERY, MY_FILES_PATH, MY_CALCULATORS_PATH } from '../utils';
import {
  selectCurrentUserClassroom,
  selectCurrentUserProgram,
  selectIsCurrentUserStudent,
  selectStudentFolderId,
  useAppSelector,
  selectIsFullscreen,
  selectIsNewUser,
  selectProgramPostLoading,
  selectShowedWizard,
  appActions,
  useAppDispatch,
  fetchUserCalculators,
} from '../store';
import { JournalFeed } from '../StreamFeedComponents/JournalFeed/JournalFeed';
import { JournalTab, JournalTabs } from './JournalTabs';
import { CreateDocument } from './CreateDocument/CreateDocument';
import { DriveFolderButton } from './DriveFolderButton';
import { JournalFab } from '../JournalFab';

type JournalProps = {
  className?: string;
  currentStudent: User;
  setIsJournalCollapsed?: Dispatch<SetStateAction<boolean>>;
  isJournalCollapsed?: boolean;
  activeTabIndex: JournalTab;
  setActiveTabIndex: Dispatch<SetStateAction<JournalTab>>;
};

export const Journal = ({
  className,
  currentStudent,
  isJournalCollapsed,
  setIsJournalCollapsed,
  activeTabIndex,
  setActiveTabIndex,
}: JournalProps) => {
  const { setIsOpen } = useTour();

  const dispatch = useAppDispatch();

  const classroom = useAppSelector(selectCurrentUserClassroom);
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const isCurrentUserStudent = useAppSelector(selectIsCurrentUserStudent);
  const isFullscreen = useAppSelector(selectIsFullscreen);
  const isNewUser = useAppSelector(selectIsNewUser);
  const programPostLoading = useAppSelector(selectProgramPostLoading);

  const showedWizard = useAppSelector(selectShowedWizard);

  const { t } = useTranslation();
  const [commentId, updateHash] = useHash();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const swiperRef = useRef<Swiper | null>(null);

  useStudentsFilesSubscription({
    userIds: [currentStudent.uid],
    programId: currentProgram?.id,
    classroomId: classroom?.id,
  });

  const rootFolderId = useAppSelector((state) =>
    selectStudentFolderId(
      state,
      classroom?.id,
      currentProgram?.id,
      currentStudent?.email
    )
  );

  const firstName = useMemo(
    () => currentStudent?.displayName?.split(' ')[0],
    [currentStudent]
  );

  const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setActiveTabIndex(newValue);
    updateHash('');
  };

  const handleChangeIndex = (swiper: Swiper) => {
    if (activeTabIndex !== swiper.activeIndex) {
      setActiveTabIndex(swiper.activeIndex);
    }
  };

  useEffect(() => {
    dispatch(fetchUserCalculators(
      {
        classroomId: classroom?.id as string,
        programId: currentProgram?.id as string,
      }
    ));
  }, [currentStudent.uid, classroom?.id, currentProgram?.id]);

  useEffect(() => {
    const indexs = {
      [`#${MY_FILES_PATH}`]: JournalTab.MyFiles,
      [`#${MY_CALCULATORS_PATH}`]: JournalTab.DesmosCal,
    };

    setActiveTabIndex(indexs[commentId] ?? JournalTab.MyJournal);
  }, [commentId]);

  useEffect(() => {
    if (!swiperRef.current?.destroyed)
      swiperRef.current?.slideTo(activeTabIndex);
  }, [activeTabIndex]);

  useEffect(() => {
    if (
      !currentProgram ||
      !classroom ||
      programPostLoading ||
      !isNewUser ||
      isMobile ||
      showedWizard
    )
      return;

    const timer = setTimeout(() => {
      setIsOpen(true);
      dispatch(appActions.setShowedWizard(true));
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [classroom, currentProgram, isNewUser, programPostLoading]);

  if (!currentProgram || !classroom) {
    return null;
  }

  const renderHeader = () => {
    if (!isCurrentUserStudent) {
      return (
        <StyledHeader>
          {firstName}
          {t('my_project_journal')}
        </StyledHeader>
      );
    }

    return isMobile ? null : (
      <StyledHeader id="my_journal_title">{t('my_journal')}</StyledHeader>
    );
  };

  if (isFullscreen) return null;

  return isJournalCollapsed && !isFullscreen ? (
    <JournalFab
      show={Boolean(isJournalCollapsed)}
      onClick={() => {
        setIsJournalCollapsed?.(false);
      }}
    />
  ) : (
    <UserFilesProvider
      parentFolderId={rootFolderId}
      feedSlug={FeedSlugs.student}
    >
      <StyledGrid item xs={12} sm={12} md={6} lg={5} xl={5}>
        <StyledJournal className={className}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {renderHeader()}
            </div>
            {rootFolderId ? (
              <JournalTabs
                activeTabIndex={activeTabIndex}
                onTabChange={handleChange}
                rootFolderId={rootFolderId}
              />
            ) : null}
          </div>

          <StyledSwiper
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            onSlideChange={handleChangeIndex}
            breakpoints={{
              800: {
                allowTouchMove: false,
              },
            }}
          >
            <SwiperSlide>
              <TabPanel value={activeTabIndex} index={JournalTab.MyJournal}>
                <FeedProvider feedSlug={FeedSlugs.student}>
                  <JournalFeed user={currentStudent} />
                </FeedProvider>
              </TabPanel>
            </SwiperSlide>

            <SwiperSlide>
              <TabPanel value={activeTabIndex} index={JournalTab.MyFiles}>
                <StudentFileList
                  studentId={currentStudent.uid}
                  showFileUpload={Boolean(rootFolderId)}
                  rootFolderId={rootFolderId}
                />
              </TabPanel>
            </SwiperSlide>
            <SwiperSlide>
            <TabPanel value={activeTabIndex} index={JournalTab.DesmosCal}>
              <StudentCalculatorList
                studentId={currentStudent.uid}
              />
            </TabPanel>
          </SwiperSlide>
          </StyledSwiper>
          {isMobile && activeTabIndex === JournalTab.MyFiles ? (
            <StyledMobileDriveButtons>
              <CreateDocument />
              <DriveFolderButton driveFolderId={rootFolderId} />
            </StyledMobileDriveButtons>
          ) : null}
        </StyledJournal>
      </StyledGrid>
    </UserFilesProvider>
  );
};

export const MemoizedJournal = memo(Journal);
