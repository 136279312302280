import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const WPAPI: any = require('wpapi'); // eslint-disable-line @typescript-eslint/no-unsafe-assignment

import { ContentType, Phase, ProgramPost } from '@xq/domain';
import {
  AssociateSubPhaseContentPayload,
  Audiences,
  ContentTypes,
  FilterPhasePayload,
  MapCategoryToPhasePayload,
  PhaseSlugs,
  WordPressCategory,
  WordPressPhaseContent,
  WordPressWizardContent,
  WPTemplate,
} from '@xq/shared/data-access';

import { loggerService } from './LoggerService';

export interface IParam {
  param(acf_program: string, programSlug: string | []): any;
  id(id: string): Promise<WordPressPhaseContent>;
}

export interface IWpapi {
  categories(): Promise<WordPressCategory[]>;
  posts(): IParam;
}

type XQDescriptionResponseData = {
  welcome_message: string;
  welcome_title: string;
  welcome_button_text: string;
};

type WpProgramsType = {
  [key: string]: {
    module_ordering: number;
  };
};

const xqRootWP =
  process.env.NX_PUBLIC_WORDPRESS_DOMAIN || 'https://xqpbllstg.wpengine.com/';
const apiEntry = 'wp-json';

export class WordPressService {
  private static instance: WordPressService;

  private wordPressAPI: IWpapi;

  constructor() {
    try {
      this.wordPressAPI = new WPAPI({
        endpoint: `${xqRootWP}${apiEntry}`,
      }) as IWpapi;
    } catch (e) {
      throw new Error("can't initialize Word press Api.");
    }
  }

  private async fetchPhaseCategories(): Promise<WordPressCategory[]> {
    return this.wordPressAPI.categories();
  }

  private async getAdditionalResources(programSlug: string, itemId: number) {
    const contentType = ContentTypes.justInTime;
    return this.fetchPhaseContent(programSlug, contentType, itemId).then(
      (addResRes) =>
        addResRes.map((res) => {
          return {
            title: res.title.rendered,
            src: res.link,
            slug: res.slug,
          };
        })
    );
  }

  private associateSubPhaseContent({
    programContent,
    subPhase,
    programSlug,
  }: AssociateSubPhaseContentPayload) {
    const childrenSubPhases = programContent.filter(
      (c) => +c.acf['parent-phase'] === subPhase.id
    );

    return childrenSubPhases.map(async (subPhaseContent) => {
      const {
        id,
        title,
        content: { rendered },
        acf,
        meta,
      } = subPhaseContent;
      const desmosCalculator = meta.desmos_calc_state?.state
        ? JSON.stringify(meta.desmos_calc_state?.state)
        : null;

      return {
        content: rendered.replace(
          /href="\//gi,
          `target="_blank" href="${xqRootWP}`
        ),
        id,
        desmosCalculator,
        slug: programSlug,
        subPhaseId: subPhase.id,
        title: acf['external-title'] || title.rendered,
        isStickyPost: acf['sticky-post'] ?? false,
      };
    });
  }

  private async mapCategoryToPhase({
    category,
    programSlug,
    programContent,
    contentType,
  }: MapCategoryToPhasePayload): Promise<Phase> {
    const subPhases = programContent.filter(
      (c) => c.categories.includes(category.id) && !c.acf['parent-phase']
    );

    const additionalResources = await this.getAdditionalResources(
      programSlug,
      category.id
    );

    return {
      id: category.id,
      name: category.name,
      slug: category.slug,
      additionalResources,
      contentType,
      subPhaseValues: await Promise.all(
        subPhases.map(async (subPhase) => {
          const title =
            subPhase.acf['external-title'] || subPhase.title.rendered;
          const desmosCalculator = subPhase.meta.desmos_calc_state?.state
            ? JSON.stringify(subPhase.meta.desmos_calc_state?.state)
            : null;
          const calculatorMock =
            '{"version":10,"randomSeed":"c2921d1092590917bdb46e39122c4fad","graph":{"viewport":{"xmin":-14.681842979715304,"ymin":-7.975003719684615,"xmax":5.318157020284696,"ymax":23.15792954090822}},"expressions":{"list":[{"type":"expression","id":"1","color":"#c74440","latex":"ff"},{"type":"expression","id":"2","color":"#2d70b3","latex":"f=1","hidden":true},{"type":"expression","id":"3","color":"#388c46"}]}}';

          return {
            phaseId: category.id,
            id: subPhase.id,
            title,
            slug: subPhase.slug,
            isStickyPost: subPhase.acf['sticky-post'] ?? false,
            desmosCalculator,
            content: subPhase.content.rendered.replace(
              /href="\//gi,
              `target="_blank" href="${xqRootWP}`
            ),
            subPhaseContent: await Promise.all(
              this.associateSubPhaseContent({
                programContent,
                programSlug,
                subPhase,
              })
            ),
          };
        })
      ),
    };
  }

  private filterPhase({ audience, slug }: FilterPhasePayload) {
    return audience === Audiences.teacher
      ? slug !== PhaseSlugs.UnCategorized
      : slug !== PhaseSlugs.Preparation && slug !== PhaseSlugs.UnCategorized;
  }

  async getContent(
    rawContent: WordPressCategory[],
    programSlug: string,
    audience: string,
    contentType: ContentType.StudentContent | ContentType.PhaseContent
  ) {
    const phasesContent = rawContent
      .filter((item) => this.filterPhase({ slug: item.slug, audience }))
      .map(async (category) => {
        const programContent = await this.fetchPhaseContent(
          programSlug,
          ContentTypes.main,
          [category.id],
          audience
        );

        return this.mapCategoryToPhase({
          category,
          programSlug,
          programContent,
          contentType,
        });
      });

    try {
      const phaseValues = await Promise.all(phasesContent);
      return {
        slug: programSlug,
        phaseValues,
      };
    } catch (e) {
      loggerService.error(e);
      throw Error(`Promise failed: ${e}`);
    }
  }

  private async fetchProgramSummary(slug: string) {
    return this.wordPressAPI
      .posts()
      .param('acf_type', ContentTypes.summary)
      .param('acf_program', slug);
  }

  async fetchWizardContent(): Promise<WordPressWizardContent[]> {
    try {
      let shouldCall = true;
      let result: WordPressWizardContent[] = [];
      let page = 1;

      while (shouldCall) {
        const query = this.wordPressAPI
          .posts()
          .param('acf_type', ContentTypes.wizard)
          .param('page', page)
          .param('per_page', 10);

        const response = await query;

        result = result.concat(response);

        if (!result.length || result.length >= response?._paging?.total) {
          shouldCall = false;
        } else {
          page += 1;
        }
      }

      return result;
    } catch (e) {
      loggerService.error(`${e}`);
      return [];
    }
  }

  async getProgram(
    programSlug: string,
    isTeacher?: boolean
  ): Promise<ProgramPost> {
    const rawPhases = await this.fetchPhaseCategories();
    const prep = rawPhases.splice(
      rawPhases.findIndex((ph) => ph.slug === PhaseSlugs.Preparation),
      1
    );

    const rawContent = [...prep, ...rawPhases];

    const studentPhaseValues = await this.getContent(
      rawContent,
      programSlug,
      Audiences.student,
      ContentType.StudentContent
    );
    const teacherPhaseValues = isTeacher
      ? await this.getContent(
          rawContent,
          programSlug,
          Audiences.teacher,
          ContentType.PhaseContent
        )
      : null;

    const summary: string = (await this.fetchProgramSummary(programSlug))?.[0]
      ?.content?.rendered;

    return teacherPhaseValues
      ? {
          slug: programSlug,
          studentPhaseValues: studentPhaseValues.phaseValues,
          teacherPhaseValues: teacherPhaseValues.phaseValues,
          summary,
        }
      : {
          slug: programSlug,
          studentPhaseValues: studentPhaseValues.phaseValues,
        };
  }

  fetchSlugContent(slug: string): Promise<WordPressPhaseContent[]> {
    return this.wordPressAPI.posts().param('slug', slug);
  }

  async fetchTemplates() {
    try {
      let shouldCall = true;
      let result: WordPressPhaseContent[] = [];
      let page = 1;
      const categoryIds =
        (await this.fetchPhaseCategories()).map((c) => c.id) ?? [];

      while (shouldCall) {
        const query = this.wordPressAPI
          .posts()
          .param('acf_type', ContentTypes.template)
          .param('categories', categoryIds)
          .param('page', page)
          .param('per_page', 100);

        const response = await query;

        result = result.concat(response);

        if (!result.length || result.length >= response?._paging?.total) {
          shouldCall = false;
        } else {
          page += 1;
        }
      }

      return result;
    } catch (e) {
      loggerService.error(`${e}`);
      return [];
    }
  }

  extractFileIdsFromPhaseContent(phaseContent: WordPressPhaseContent[]): {
    templatedIdsByProgram: Record<string, string[]>;
    templates: Record<string, WPTemplate>;
  } {
    const urlExp =
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
    const googleDriveExp = /d\/([^/<\s]+)/g;
    const templates: Record<string, WPTemplate> = {};

    const templatedIdsByProgram = phaseContent.reduce(
      (previousValue, content) => {
        const urls = content.excerpt.rendered.match(urlExp);
        const program = content.acf?.program ?? null;
        const audience = content.acf?.audience;

        if (!program || !urls) {
          return previousValue;
        }

        const fileIds = [...new Set(urls)]
          .map((result) => result.match(googleDriveExp))
          .flat()
          .filter(Boolean)
          .map((v) => v.replace(/d\//g, ''));

        fileIds.forEach((id) => {
          templates[id] = {
            audience,
            program,
          };
        });

        return {
          ...previousValue,
          [program]: previousValue[program]
            ? previousValue[program].concat(fileIds)
            : fileIds,
        };
      },
      {} as Record<string, string[]>
    );

    return {
      templatedIdsByProgram,
      templates,
    };
  }

  private async fetchPhaseContent(
    programSlug: string,
    type = ContentTypes.justInTime,
    categories?: Array<number | string> | number | string,
    audience?: string
  ): Promise<WordPressPhaseContent[]> {
    return this.wordPressAPI
      .posts()
      .param('acf_type', type)
      .param('acf_program', programSlug)
      .param('acf_audience', audience)
      .param('categories', categories || [])
      .param('per_page', 100);
  }

  static getInstance(): WordPressService {
    if (!WordPressService.instance) {
      WordPressService.instance = new WordPressService();
    }
    return WordPressService.instance;
  }

  async fetchPostById(id: string): Promise<WordPressPhaseContent> {
    const res = await this.wordPressAPI.posts().id(id);
    return res;
  }

  async fetchCourses() {
    try {
      const courses = await axios.get(
        `${xqRootWP}${apiEntry}/courses-config/v1/courses/all`
      );
      return courses.data;
    } catch (e) {
      loggerService.error(`${e}`);
    }
  }

  async fetchPrograms(): Promise<WpProgramsType | undefined> {
    try {
      const programs = await axios.get(
        `${xqRootWP}${apiEntry}/modules-config/v1/modules/all`
      );
      return programs.data;
    } catch (e) {
      loggerService.error(`${e}`);
    }
  }

  async fetchXQDescription(): Promise<XQDescriptionResponseData | undefined> {
    try {
      const res = await axios.get(
        `${xqRootWP}${apiEntry}/xq-general-settings/v1/all`
      );
      return res.data.welcome_widget;
    } catch (e) {
      loggerService.error(`${e}`);
    }
  }
}

export const wordPressService = new WordPressService();
