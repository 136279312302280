import { z } from 'zod';
import isURL from 'validator/lib/isURL';
import isFQDN from 'validator/lib/isFQDN';
import isEmail from 'validator/lib/isEmail';

import { School } from '@xq/domain';

export const CreateSchoolSchema = z.object({
  name: z.string().min(3, 'must_contain_at_least_3_character(s)'),
  domains: z
    .string()
    .min(3, 'must_contain_at_least_3_character(s)')
    .refine((v) => (v ? isFQDN(v) : true), { message: 'school_invalid_domain' })
    .array(),
  adminEmail: z.string().refine((v) => (v ? isEmail(v) : true), {
    message: 'invalid_email',
  }),
  state: z.string(),
  city: z.string().optional(),
  website: z.string().refine((v) => isURL(v), { message: 'invalid_url' }),
});

export type CreateSchoolRequestDto = z.infer<typeof CreateSchoolSchema>;

export type CreateSchoolResponseDto = School | null;
