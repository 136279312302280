import { DocumentService, GoogleDriveAdapter } from '@xq/infrastructure';
import { Document } from '@xq/domain';

export class GetFileFromDriveUseCase {
  static async execute(fileId: string): Promise<Document> {
    return DocumentService.getInstance(
      GoogleDriveAdapter.getInstance()
    ).getDocumentByIdFromStorage(fileId);
  }
}
