export interface ICleverApiService {
  getToken(): string;
}

export class CleverApiService implements ICleverApiService {
  private static instance: ICleverApiService;

  public getToken(): string {
    return 'TEST_TOKEN';
  }

  public static getInstance(): ICleverApiService {
    if (!CleverApiService.instance) {
      CleverApiService.instance = new CleverApiService();
    }
    return CleverApiService.instance;
  }
}

export const cleverApiService = new CleverApiService();
