import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { useTitle } from 'react-use';
import { useMediaQuery } from '@mui/material';

import { Document, getUserRole, MyFilesScopes } from '@xq/domain';
import { DocumentMapper } from '@xq/shared/data-access';
import {
  LoadingStateType,
  loggerService,
  MyFilesLoadingStateService,
} from '@xq/infrastructure';

import styles from './student-file-list.module.scss';
import { FilesLoader } from '../FilesLoader/FilesLoader';
import { useModal, useNotifier, useUserFiles } from '../context';
import { FileWithGooglePermissions } from '../FileUpload/FileUpload';
import { RestrictView } from '../RestrictView/RestrictView';
import { DriveFolderButton } from '../Journal/DriveFolderButton';
import { MOBILE_MEDIA_QUERY, PageTitles } from '../utils';
import {
  selectCurrentUserState,
  useAppSelector,
  toggleMarkForReview,
  useAppDispatch,
  selectSortedUserDocuments,
} from '../store';
import { DocumentList } from '../DocumentList/DocumentList';
import { DeleteTagProps } from '../DocumentList/DocumentIem/DocumentItem';
import { StudentWizardStep } from '../hooks/useStudentWizardGuide';
import { useGoogleScopesChecker } from '../hooks';

type StudentFileListProps = {
  showFileUpload: boolean;
  rootFolderId?: string;
  studentId: string;
};

const loadingStateTracker = MyFilesLoadingStateService.getInstance();

export const StudentFileList = ({
  showFileUpload,
  rootFolderId,
  studentId,
}: StudentFileListProps) => {
  useTitle(PageTitles.MY_FILES);

  const { t } = useTranslation();
  const { fileListUpload, deleteFile } = useUserFiles();
  const {
    notifyStudentForDocumentedReviewed,
    notifyTeachersForDocumentReview,
  } = useNotifier();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const { onConfirm, dispatchModal } = useModal();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(selectCurrentUserState);

  const { isStudent, isAdmin, isTeacher } = getUserRole(currentUser);

  const [loadingFiles, setLoadingFiles] = useState<Document[]>([]);

  const currentClassroomId = currentUser.currentClassroomId ?? '';
  const currentProgramId =
    currentUser.currentProgramIdByClassroomId[currentClassroomId] ?? '';

  const { requestScopes, loading: loadingScopesChecker } =
    useGoogleScopesChecker();

  const documents = useAppSelector((state) =>
    selectSortedUserDocuments(
      state,
      studentId,
      currentClassroomId,
      currentProgramId
    )
  );

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const currentFiles = event?.target?.files;

    if (!currentFiles) return;

    setLoadingFiles(
      Array.from(currentFiles).map((f) => DocumentMapper.fromLocal(f))
    );

    await fileListUpload({ fileList: currentFiles });

    setLoadingFiles([]);
  };

  if (isNil(currentUser)) {
    return null;
  }

  const deleteDocument = async (fileId: string) => {
    if (!currentUser?.uid) {
      throw new Error('No current user');
    }

    const document = documents.find(({ id }) => id === fileId);

    if (!document) {
      throw new Error('File to be deleted is undefined');
    }

    dispatchModal({ loading: true });

    try {
      const { trackedPromise: deleteFileExecution } =
        loadingStateTracker.trackLoadingState(
          deleteFile(fileId, currentUser.uid),
          {
            name: document.name,
            type: LoadingStateType.DELETING,
          }
        );

      await deleteFileExecution;
    } catch (e) {
      loggerService.error(e);
    }

    dispatchModal({ loading: false });
  };

  const deleteDocumentHandler = (file: Document) => {
    onConfirm.current = () => deleteDocument(file.id);
    dispatchModal({ isOpen: true, bodyText: t('delete_file_modal_text_') });
  };

  const toggleMarkForReviewHandler = (document: Document) => {
    const isForReview = !document.properties?.isForReview;
    dispatch(
      toggleMarkForReview({
        fileId: document.id,
        classroomId: currentClassroomId,
        isForReview,
        programId: currentProgramId,
        userId: studentId,
      })
    );

    if (isStudent) {
      return notifyTeachersForDocumentReview(document.name, isForReview);
    }

    return notifyStudentForDocumentedReviewed(document.name, studentId);
  };

  // const deleteTagHandler = ({ tag, tags, document }: DeleteTagProps) => {
  //   deleteMathTagFromFile({
  //     mathTagForDelete: tag,
  //     document: {
  //       ...document,
  //       autoTags: tags,
  //     },
  //     userId: studentId,
  //   });
  // };

  return (
    <div className={styles.container}>
      <FilesLoader files={loadingFiles} />

      <DocumentList
        className={styles.documentListContainer}
        documents={documents}
        canDeleteTag={isTeacher || isAdmin}
        canViewTag={!isStudent}
        onDeleteDocument={deleteDocumentHandler}
        onMarkForReview={toggleMarkForReviewHandler}
        // onDeleteTag={deleteTagHandler}
      />

      <RestrictView scopes={[MyFilesScopes.CAN_UPLOAD]}>
        {!isMobile && (
          <div className={styles.actionBtnContainer}>
            {showFileUpload && (
              <div id={StudentWizardStep.MY_FILES_UPLOAD_FILE}>
                <FileWithGooglePermissions
                  onFileSelect={handleFileUpload}
                  text={t('upload_file')}
                  requestScopes={requestScopes}
                  loading={loadingScopesChecker}
                />
              </div>
            )}
            <DriveFolderButton driveFolderId={rootFolderId} />
          </div>
        )}
      </RestrictView>
    </div>
  );
};
