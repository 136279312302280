import { useState } from 'react';
import { User } from '@firebase/auth';

import {
  ClassroomRepository,
  firebaseService,
  SchoolRepository,
  UserRepository,
} from '@xq/infrastructure';

import {
  CheckUserRolesByReposUseCase,
  CreateUserUseCase,
  RefreshAuthTokenUseCase,
} from '@xq/usecases';
import { UserRecord } from '@xq/shared/data-access';
import {
  Errors,
  generateUserRole,
  shouldUpdateLastActivity,
  UserRoles,
} from '@xq/domain';

import { appActions, getCurrentUserData, useAppDispatch } from '../store';
import { FbAuthUserState } from '../ApiProvider/ApiProvider';
import { fetchWizardContent } from '../store/wizard';
import { useEffectOnce } from '../hooks/useEffectOnce';

const schoolRepository = new SchoolRepository();
const userRepository = new UserRepository();
const classroomRepo = new ClassroomRepository();

let authStatus: FbAuthUserState = 'loading';

export const useFirebaseAuthStudentUser = () => {
  const dispatch = useAppDispatch();
  const [userAuthState, setUserAuthState] =
    useState<FbAuthUserState>('loading');
  const [authUser, setAuthUser] = useState<User | null>(null);

  const createUser = async (fbAuthUser: User & { role: UserRoles }) => {
    const { displayName, email, photoURL: photoUrl, uid, role } = fbAuthUser;
    const record = {
      displayName,
      email,
      photoUrl,
      uid,
      role: role || UserRoles.student,
    };

    await CreateUserUseCase.execute(record as UserRecord);
    await RefreshAuthTokenUseCase.execute();
  };

  const handleImpersonating = async (email: string) => {
    let isDemoUser = false;
    if (await schoolRepository.isDemoUser(email)) {
      isDemoUser = true;
      dispatch(appActions.setIsImpersonating(isDemoUser));
      dispatch(
        appActions.setBannersVisibility({
          demo: isDemoUser,
        })
      );
    }

    return isDemoUser;
  };

  useEffectOnce(() => {
    const unsubscribe = firebaseService.auth.onAuthStateChanged(
      async (fbAuthUser) => {
        if (fbAuthUser) {
          setAuthUser(fbAuthUser);
          const isDemoUser = await handleImpersonating(fbAuthUser.email || '');
          const hasValidRooms =
            await classroomRepo.checkForValidStudentClassrooms(
              fbAuthUser.email as string
            );
          const { isViewer, isStudent, isTeacher, isAdmin, userExists } =
            await CheckUserRolesByReposUseCase.execute(
              fbAuthUser.email,
              fbAuthUser.uid
            );
          const isWrongRoleByClassroom =
            !userExists &&
            !isDemoUser &&
            (isViewer || isTeacher || isAdmin || !isStudent);

          if (
            (userExists && (!hasValidRooms || !isStudent)) ||
            isWrongRoleByClassroom ||
            isTeacher ||
            isAdmin ||
            isViewer
          ) {
            authStatus = 'loggedOut';
            setUserAuthState('loggedOut');
          }

          if (!userExists && !isDemoUser) {
            const role = generateUserRole({
              isTeacher,
              isViewer,
              isAdmin,
              isStudent,
            });

            await createUser({
              ...fbAuthUser,
              role,
            });

            dispatch(appActions.setIsNewUser(true));
          }

          const user = await dispatch(
            getCurrentUserData({ fbAuthUser })
          ).unwrap();

          if (user && shouldUpdateLastActivity(user)) {
            await userRepository.updateLastActivity(user.uid);
          }

          dispatch(fetchWizardContent());

          authStatus = 'loggedIn';
          setUserAuthState('loggedIn');
        } else {
          authStatus = 'loggedOut';
          setUserAuthState('loggedOut');
        }
      }
    );

    return () => unsubscribe();
  });
  return {
    userAuthState: authStatus !== userAuthState ? authStatus : userAuthState,
    setUserAuthState,
    authUser,
  };
};
