import { cx } from '@emotion/css';
import {
  StyledContainer,
  StyledFab,
  StyledArrowIcon,
} from './JournalFab.styles';

interface JournalFabProps {
  show: boolean;
  onClick: () => void;
}

export const JournalFab = ({ onClick, show }: JournalFabProps) => {
  return (
    <StyledContainer
      className={cx({
        visible: show,
      })}
    >
      <StyledFab color="primary" onClick={onClick}>
        <StyledArrowIcon fontSize={'small'} />
      </StyledFab>
    </StyledContainer>
  );
};
