import {
  collection,
  CollectionReference,
  documentId,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

import { Program } from '@xq/domain';
import {
  FbProgramDto,
  ProgramMapper,
  CollectionNames,
} from '@xq/shared/data-access';

import {
  firebaseService,
  IFirebaseService,
} from '../../services/FirebaseService';
import { chunk } from 'lodash';

export class ProgramRepository {
  private firebase: IFirebaseService = firebaseService;

  private programsCollection: CollectionReference;

  private programMapper = new ProgramMapper();

  constructor() {
    this.programsCollection = collection(
      this.firebase.db,
      CollectionNames.programs
    );
  }

  async getActivePrograms(): Promise<Program[]> {
    return (
      await getDocs(
        query(this.programsCollection, where('isActive', '==', true))
      )
    ).docs.map((p) =>
      this.programMapper.toDomain({
        ...p.data(),
        id: p.id,
      } as FbProgramDto)
    );
  }

  async filterInactiveProgramsByIds(programIds: string[]) {
    if (!programIds.length) {
      return [];
    }

    return (
      await Promise.all(
        chunk(programIds, 10).map((ids) =>
          getDocs(
            query(
              this.programsCollection,
              where(documentId(), 'in', ids),
              where('isActive', '==', true)
            )
          )
        )
      )
    )
      .map((snapshot) => snapshot.docs.map((doc) => doc.id))
      .flat();
  }
}
