import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { selectTaggedPost } from '../index';

export type TaggedPostState = Record<string, string[]>;

const initialTaggedPost: TaggedPostState = {};

export const taggedPostSlice = createSlice({
  name: 'tagged-post',
  initialState: initialTaggedPost,
  reducers: {
    addTaggedPost: (state, { payload }: PayloadAction<TaggedPostState>) => {
      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    removeTagFromPost: (
      state,
      { payload }: PayloadAction<{ postId: string; tag: string }>
    ) => {
      state[payload.postId] = state[payload.postId]?.filter(
        (t) => t !== payload.tag
      );
    },
  },
});

export const selectTaggedPostById = createSelector(
  [selectTaggedPost, (_, postId: string) => postId],
  (state, postId) => {
    return state[postId] ?? [];
  }
);

export const taggedPostActions = taggedPostSlice.actions;
