import { useState, useEffect } from 'react';

import { Mention } from '@xq/domain';

const lookupService = {
  search(
    string: string,
    suggestions: Mention[],
    callback: (results: Mention[]) => void
  ): void {
    setTimeout(() => {
      const results = suggestions.filter((mention) =>
        mention.name.toLowerCase().includes(string.toLowerCase())
      );
      callback(results);
    }, 500);
  },
};

export function useMentionLookupService(
  mentionString: string | null,
  suggestions: Mention[]
) {
  const [results, setResults] = useState<Mention[]>([]);

  useEffect(() => {
    if (mentionString == null) {
      setResults([]);
      return;
    }

    if (suggestions === null) {
      return;
    }

    lookupService.search(mentionString, suggestions, (newResults) => {
      setResults(newResults);
    });
  }, [mentionString, suggestions]);

  return results;
}
