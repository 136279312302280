import { useEffect } from 'react';
import { v4 } from 'uuid';

type EmbeddedContentOptions = {
  content: string;
  embedUrl: string;
  contentUrlString: string;
};

export const useEmbeddedContent = ({
  embedUrl,
  contentUrlString,
  content,
}: EmbeddedContentOptions) => {
  useEffect(() => {
    const scriptId = v4();

    if (content.includes(contentUrlString)) {
      const scriptTag = document.createElement('script');
      scriptTag.src = embedUrl;
      scriptTag.async = true;
      scriptTag.id = scriptId;
      setTimeout(() => {
        document.body.appendChild(scriptTag);
      }, 0);
    }

    return () => {
      document.getElementById(scriptId)?.remove();
    };
  }, [content, embedUrl, contentUrlString]);
};
