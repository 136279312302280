import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { smoothScroll } from '../utils';

export const useSubPhasesRef = (panelIndex: string) => {
  const { tabIndex = '0', subPhaseIndex = '0', phaseIndex = '0' } = useParams();
  const subPhasesRef = useRef<HTMLDivElement[]>([]);

  const currentSubPhasesRef = subPhasesRef.current;

  useEffect(() => {
    if (!window.location.hash && panelIndex === tabIndex) {
      setTimeout(() => {
        if (
          currentSubPhasesRef[+subPhaseIndex] &&
          currentSubPhasesRef[+subPhaseIndex].parentNode
        ) {
          smoothScroll({
            duration: 500,
            element: currentSubPhasesRef[+subPhaseIndex]
              .parentNode as HTMLElement,
            to: currentSubPhasesRef[+subPhaseIndex].offsetTop,
          });
        }
      }, 100);
    }
  }, [subPhaseIndex, currentSubPhasesRef, window.location.hash]);

  useEffect(() => {
    subPhasesRef.current = [];
  }, [phaseIndex, panelIndex]);

  const assignSubPhaseRef = useCallback(
    (element: HTMLDivElement, index: number) => {
      subPhasesRef.current[index] = element;
    },
    []
  );

  return {
    assignSubPhaseRef,
  };
};
