import Close from '@mui/icons-material/Close';
import { IconButton, styled } from '@mui/material';

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 18px;
  right: 16px;
`;

type DialogCloseButtonProps = {
  onClose: () => void;
};

export const DialogCloseButton = ({ onClose }: DialogCloseButtonProps) => {
  return (
    <StyledIconButton color="info" onClick={onClose}>
      <Close fontSize="medium" />
    </StyledIconButton>
  );
};
