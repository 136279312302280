import { useEffect, useState } from 'react';
import { Outlet, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  enqueueSnackbar,
  fetchSchoolById,
  fetchUserCalculators,
  persistCurrentClassroomId,
  ProgramSelect,
  RestrictView,
  selectCurrentUserClassroom,
  selectCurrentUserProgram,
  selectCurrentUserState,
  selectSchoolById,
  SnackbarVariant,
  StyledHeaderNames,
  StyledInfoContainer,
  StyledLayoutContainer,
  StyledTab,
  StyledTabsContainer,
  useAppDispatch,
  useAppSelector,
  useRouteMatch,
} from '@xq/web-components';
import { GenerateTemplatesToProgramUseCase } from '@xq/usecases';
import { ClassroomsScopes, Errors } from '@xq/domain';
import { TEACHER_APP_ROUTES } from '@xq/web-config';

import {
  StyledClassroomName,
  StyledProgramSection,
  StyledSchoolName,
} from './Classroom.styles';

export const Classroom = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { classroomId = '' } = useParams<{ classroomId: string }>();

  const classroom = useAppSelector(selectCurrentUserClassroom);
  const school = useAppSelector((state) =>
    selectSchoolById(state, classroom?.schoolId ?? '')
  );
  const program = useAppSelector(selectCurrentUserProgram);
  const currentUser = useAppSelector(selectCurrentUserState);

  const routeMatch = useRouteMatch([
    TEACHER_APP_ROUTES.getDashboard(),
    TEACHER_APP_ROUTES.getJournalActivity(),
    TEACHER_APP_ROUTES.getStudentFiles(),
    TEACHER_APP_ROUTES.getStudentCalculators(),
  ]);
  const currentTab = routeMatch?.pattern?.path;

  const [isLoadingGenerateTemplates, setIsLoadingGenerateTemplates] =
    useState(false);

  const generateTemplatesToProgram = async () => {
    if (classroom && program) {
      setIsLoadingGenerateTemplates(true);

      try {
        const response = await GenerateTemplatesToProgramUseCase.execute({
          classroomId: classroom.id,
          programId: program.id,
        });

        if (response === Errors.generateIsAlreadyStarted) {
          dispatch(
            enqueueSnackbar({
              message: t('generated_templates_already_started'),
              options: {
                variant: SnackbarVariant.WARNING,
              },
            })
          );
        } else {
          dispatch(
            enqueueSnackbar({
              message: t('generated_templates_started'),
            })
          );
        }
      } catch (e) {
        dispatch(
          enqueueSnackbar({
            message: t('generated_templates_fail'),
            options: {
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      } finally {
        setIsLoadingGenerateTemplates(false);
      }
    }
  };

  useEffect(() => {
    if (currentUser.uid) {
      const promise = dispatch(
        persistCurrentClassroomId({
          userId: currentUser.uid,
          currentClassroomId: classroomId,
        })
      );

      return () => promise.abort();
    }
  }, [classroomId, currentUser.currentClassroomId, currentUser.uid]);

  useEffect(() => {
    if (classroom?.schoolId && !school) {
      const promise = dispatch(fetchSchoolById(classroom?.schoolId as string));

      return () => promise.abort();
    }
  }, [classroom?.schoolId, school]);

  useEffect(() => {
    if (!classroom?.id || !program?.id) return;
    dispatch(fetchUserCalculators(
      {
        classroomId: classroom?.id,
        programId: program?.id,
      }
    ));
  }, [classroom?.id, program?.id]);

  if (!classroom) {
    return null;
  }

  const renderProgramSection = () => {
    return (
      <StyledProgramSection>
        <ProgramSelect />
        {Boolean(classroom.participantEmails.length) && (
          <RestrictView scopes={[ClassroomsScopes.CAN_GENERATE_TEMPLATES]}>
            <LoadingButton
              variant="outlined"
              color="info"
              loading={isLoadingGenerateTemplates}
              onClick={generateTemplatesToProgram}
            >
              {t('generate_templates')}
            </LoadingButton>
          </RestrictView>
        )}
      </StyledProgramSection>
    );
  };

  return (
    <StyledLayoutContainer>
      <div>
        <StyledInfoContainer>
          {renderProgramSection()}
          <StyledHeaderNames>
            <StyledSchoolName>{school?.name}</StyledSchoolName>
            <StyledClassroomName data-cy="classroom-name">
              {classroom?.name}
            </StyledClassroomName>
          </StyledHeaderNames>
        </StyledInfoContainer>
        <StyledTabsContainer>
          <Tabs value={currentTab} textColor="primary">
            <StyledTab
              label={t('dashboard')}
              value={TEACHER_APP_ROUTES.getDashboard()}
              to={TEACHER_APP_ROUTES.getDashboard(classroomId)}
              data-cy="dashboard"
              component={Link}
            />
            <StyledTab
              label={t('journal_activity')}
              value={TEACHER_APP_ROUTES.getJournalActivity()}
              to={TEACHER_APP_ROUTES.getJournalActivity(
                classroomId,
                '0',
                '0',
                '-1',
                '-1'
              )}
              data-cy="journal_activity"
              component={Link}
            />
            <StyledTab
              label={t('student_files')}
              value={TEACHER_APP_ROUTES.getStudentFiles()}
              to={TEACHER_APP_ROUTES.getStudentFiles(classroomId)}
              data-cy="student_files"
              component={Link}
            />

            <StyledTab
              label='Student Calculators'
              value={TEACHER_APP_ROUTES.getStudentCalculators()}
              to={TEACHER_APP_ROUTES.getStudentCalculators(classroomId)}
              data-cy="student_calculators"
              component={Link}
            />
          </Tabs>
        </StyledTabsContainer>
      </div>
      <Outlet />
    </StyledLayoutContainer>
  );
};
