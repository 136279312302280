import { Checkbox, FormControlLabel, styled } from '@mui/material';
import { ChangeEvent, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { EventTypes } from '../../enums/EventTypes';
import { CheckableProgramContent } from './StudentPhaseAccordion';

const StyledMarkThisSubPhaseAsDoneCheckBox = styled('span')`
  font-weight: bold;
  color: var(--shark);
  font-size: 14px;
  display: flex;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 5px;
`;

type StudentAccordionFooterProps = {
  isDisabled: boolean;
  isChecked: boolean;
  handleClick: (
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>
  ) => void;
  checkable: CheckableProgramContent;
};

export const StudentAccordionFooter = ({
  isDisabled,
  isChecked,
  handleClick,
  checkable,
}: StudentAccordionFooterProps) => {
  const { t } = useTranslation();

  const handleEnter = (
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>
  ) => {
    if (
      event.type === EventTypes.click ||
      (event.type === EventTypes.keyDown &&
        (event as KeyboardEvent).key === 'Enter')
    ) {
      handleClick(event);
    }
  };

  return (
    <StyledFormControlLabel
      aria-label="Acknowledge"
      control={
        <Checkbox
          onChange={handleClick}
          color="primary"
          disabled={isDisabled}
          checked={isChecked}
          onKeyDown={handleEnter}
          tabIndex={0}
          name={checkable}
        />
      }
      label={
        <StyledMarkThisSubPhaseAsDoneCheckBox>
          {t('mark_as_done')}
        </StyledMarkThisSubPhaseAsDoneCheckBox>
      }
    />
  );
};
