import {
  AnalyticsEventScope,
  FirebaseService,
  GoogleAnalyticsService,
} from '@xq/infrastructure';

export class LogAnalyticsEventUseCase {
  private static googleAnalyticsService = new GoogleAnalyticsService(
    FirebaseService.getInstance()
  );

  static execute(event: AnalyticsEventScope) {
    this.googleAnalyticsService.logEvent(event);
  }
}
