import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';

import { Mention } from '@xq/domain';

import {
  InsertHTMLPlugin,
  EmojisPlugin,
  EmojiPickerPlugin,
  MentionsPlugin,
  LinkPlugin,
  LexicalAutoLinkPlugin,
} from './plugins';

type TextEditorProps = {
  mentionSuggestions: Mention[];
  placeholder?: string;
  htmlString?: string;
  CustomPlugin?: JSX.Element;
};

export const TextEditor = ({
  mentionSuggestions,
  placeholder = '',
  htmlString,
  CustomPlugin,
}: TextEditorProps) => {
  return (
    <>
      <RichTextPlugin
        contentEditable={<ContentEditable />}
        placeholder={<div className="editorPlaceholder">{placeholder}</div>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      {/* Official Plugins */}
      <HistoryPlugin />
      <ClearEditorPlugin />
      <ListPlugin />
      {/* Custom Plugins */}
      <LinkPlugin />
      <LexicalAutoLinkPlugin />
      <MentionsPlugin mentions={mentionSuggestions} />
      <EmojiPickerPlugin />
      <EmojisPlugin />
      {htmlString ? <InsertHTMLPlugin htmlString={htmlString} /> : null}
      {CustomPlugin ?? null}
    </>
  );
};
