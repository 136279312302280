import { StreamClient } from 'getstream';
import { sum } from 'lodash';

import {
  NotificationFeedMapper,
  NotificationFeedService,
} from '@xq/infrastructure';
import { NOTIFICATION_FEED_SLUGS } from '@xq/domain';

export class GetNotificationsUseCase {
  static async execute(
    client: StreamClient,
    {
      markAsReadIds,
      beforeId,
    }: {
      markAsReadIds?: string[] | boolean;
      beforeId?: string;
    }
  ) {
    const feeds = await Promise.all(
      NOTIFICATION_FEED_SLUGS.map(async (slug) => {
        const notificationFeedService =
          NotificationFeedService.getInstance(client);

        const response = await notificationFeedService.getNotifications({
          setReadIds: markAsReadIds,
          beforeId,
          slug,
        });

        return NotificationFeedMapper.toDomainNotifications(response);
      })
    );

    return {
      result: [...feeds.flatMap((f) => f.result)],
      unread: sum(feeds.map((f) => f.unread)),
    };
  }
}
