import { restClient } from './RestClient';
import { AxiosResponse } from 'axios';

type LoginUserResponse = {
  accessToken: string;
  idToken: string;
  userEmail: string;
};

export const LoginUser = (
  code: string
): Promise<AxiosResponse<LoginUserResponse>> =>
  restClient.get(`/loginUser?code=${code}`);
