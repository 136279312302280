import { ClassroomRepository } from '@xq/infrastructure';

export class GetUserCalculatorsByClassroomIdByProgramIdUseCase {
  private classroomRepository: ClassroomRepository;
  constructor() {
    this.classroomRepository = new ClassroomRepository();
  }

  async execute(
    payload: { classroomId: string; programId: string }
  ) {
    return this.classroomRepository.getUserCalculatorsByClassroomIdByProgramId(
      payload
    )
  }
}
