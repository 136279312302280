import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Close from '@mui/icons-material/Close';

import {
  StyledBody,
  StyledCancelButton,
  StyledCloseIconWrapper,
  StyledDeleteButton,
  StyledModalBody,
  StyledFooter,
  StyledGenericIcon,
  StyledHeader,
} from './ModalBody.styles';
import { StyledWarningIcon } from '../Layout';

interface ModalBodyProps {
  handleCancel?: () => void;
  handleClose?: () => void;
  handleAction(): void;
  titleText?: string;
  bodyText: string | ReactNode;
  actionButtonText?: string;
  Icon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
  loading: boolean;
  cancelButtonText?: string;
  showActionButton?: boolean;
  showIcon?: boolean;
  width: string;
}

export const ModalBody = ({
  handleCancel,
  handleClose,
  handleAction,
  bodyText,
  titleText,
  actionButtonText,
  Icon,
  loading,
  cancelButtonText,
  showActionButton = true,
  showIcon = true,
  width,
}: ModalBodyProps) => {
  const { t } = useTranslation();

  const renderIcon = useMemo(() => {
    if (!showIcon) return null;

    return Icon ? <StyledGenericIcon /> : <StyledWarningIcon />;
  }, [Icon, showIcon]);

  return (
    <StyledModalBody style={{ width }}>
      <StyledCloseIconWrapper>
        <IconButton color="inherit" onClick={handleClose}>
          <Close />
        </IconButton>
      </StyledCloseIconWrapper>
      {renderIcon}
      <StyledHeader>{titleText ?? t('are_you_sure')}</StyledHeader>
      <StyledBody>{bodyText}</StyledBody>
      <StyledFooter>
        {handleCancel ? (
          <StyledCancelButton
            variant="contained"
            onClick={() => handleCancel?.()}
          >
            {cancelButtonText || t('cancel')}
          </StyledCancelButton>
        ) : null}
        {showActionButton ? (
          <StyledDeleteButton
            loading={loading}
            variant="contained"
            onClick={() => handleAction()}
            data-cy="modal-action-btn"
          >
            {actionButtonText || t('delete')}
          </StyledDeleteButton>
        ) : null}
      </StyledFooter>
    </StyledModalBody>
  );
};
