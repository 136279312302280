export type TagFieldAction = 'add' | 'remove' | 'removeAll';

export type TagData = {
  resourceId: string;
  classroomId: string;
  programId?: string;
  tags: string[];
  action: TagFieldAction;
};

export enum UserTagType {
  JournalTagFiles = 'journalTagFilesPerClassroom',
  JournalTagPosts = 'journalTagPostsPerClassroom',
  FeedTagFiles = 'feedTagFilesPerClassroom',
  FeedTagPosts = 'feedTagPostsPerClassroom',
}

type FieldAction<TFieldValue> = (
  action: TagFieldAction,
  tags: string[]
) => TFieldValue;

export const getUserTagField = <TFieldValue>(
  fieldAction: FieldAction<TFieldValue>
) => {
  return {
    [UserTagType.JournalTagFiles]: ({
      resourceId,
      classroomId,
      programId,
      action,
      tags,
    }: TagData) => {
      return {
        [`${UserTagType.JournalTagFiles}.${classroomId}-${programId}.${resourceId}`]:
          fieldAction(action, tags),
      };
    },
    [UserTagType.JournalTagPosts]: ({
      resourceId,
      classroomId,
      programId,
      action,
      tags,
    }: TagData) => {
      return {
        [`${UserTagType.JournalTagPosts}.${classroomId}-${programId}.${resourceId}`]:
          fieldAction(action, tags),
      };
    },
    [UserTagType.FeedTagFiles]: ({
      resourceId,
      classroomId,
      action,
      tags,
    }: TagData) => {
      return {
        [`${UserTagType.FeedTagFiles}.${classroomId}.${resourceId}`]:
          fieldAction(action, tags),
      };
    },
    [UserTagType.FeedTagPosts]({
      resourceId,
      classroomId,
      action,
      tags,
    }: TagData) {
      return {
        [`${UserTagType.FeedTagPosts}.${classroomId}.${resourceId}`]:
          fieldAction(action, tags),
      };
    },
  };
};
