import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useBeforeUnload } from 'react-use';
import { mapValues, omit, uniq } from 'lodash';

import {
  useAppSelector,
  fetchSchools,
  useAppDispatch,
  selectCurrentUserState,
  useNavigationWarningModal,
  schoolNameUniqueInStateValidation,
  selectNewSchoolState,
  newSchoolActions,
  AdministrationTabs,
  StyledLayoutContainer,
  StyledFooter,
  StyledNextButton,
  StyledButton,
  useModal,
  useEffectOnce,
  StyledSmallTitle,
  StyledInnerContainer,
  selectAllSchools,
} from '@xq/web-components';
import { extractUserDomain, schoolDomainValidation } from '@xq/domain';
import { TEACHER_APP_ROUTES } from '@xq/web-config';
import { CreateSchoolSchema } from '@xq/shared/data-access';

import { StyledTwoColumnBox } from '../../components/Layout/StyledTwoColumnBox';
import { SchoolInfoEdit } from '../../components/SchoolInfoEdit/SchoolInfoEdit';
import { SchoolDomainInfoEdit } from '../../components/SchoolDomainInfoEdit/SchoolDomainInfoEdit';
import { CreateFormHeader } from '../../components/CreateFormHeader';

export const CreateNewSchool = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const schools = useAppSelector(selectAllSchools);

  const currentUser = useAppSelector(selectCurrentUserState);
  const { domains, name, state, city, website } =
    useAppSelector(selectNewSchoolState);

  const { onConfirm, dispatchModal } = useModal();

  const confirmExitFlow = async () => {
    dispatchModal({ isConfirmed: true });
    dispatch(newSchoolActions.resetNewSchool());
    navigate(TEACHER_APP_ROUTES.getAdministration(AdministrationTabs.schools));
  };

  useEffectOnce(() => {
    onConfirm.current = confirmExitFlow;
  });

  useBeforeUnload();

  const ValidationSchema = useMemo(
    () =>
      CreateSchoolSchema.omit({ adminEmail: true })
        .extend({
          domains: schoolDomainValidation,
        })
        .refine(
          async (data) => {
            if (!data?.name || !data?.state) return true;

            setIsLoading(true);

            const result = await schoolNameUniqueInStateValidation({
              schoolName: data.name,
              state: data.state,
              city: data.city || '',
            });

            setIsLoading(false);

            return result;
          },
          {
            message: t('school_already_exists'),
            path: ['name'],
          }
        ),
    []
  );

  const userDomain = extractUserDomain(currentUser.email);

  const formik = useFormik({
    initialValues: {
      name: name.trim() || '',
      state: state?.trim() || '',
      city: city?.trim() || '',
      domain: userDomain,
      website: website?.trim() || '',
      domains: domains?.length ? domains : [''],
    },
    validationSchema: toFormikValidationSchema(ValidationSchema),
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values) => {
      const newDomains = values?.domains?.map((d) => d?.trim()) || [];

      dispatch(
        newSchoolActions.setNewSchool({
          domains: newDomains.filter(Boolean),
          ...mapValues(omit(values, 'domains'), (v: string) => v?.trim()),
        })
      );
      navigate(TEACHER_APP_ROUTES.getWhitelistDomains());
    },
  });

  useEffect(() => {
    const promise = dispatch(fetchSchools(currentUser.email));

    return () => promise.abort();
  }, [currentUser.email]);

  const pageHasEmptyField =
    !formik.values.name.length || !formik.values.state.length;

  const hasDuplicatedDomains = () => {
    const enteredDomains = formik?.values?.domains?.filter(Boolean);
    return enteredDomains
      ? uniq(enteredDomains).length !== enteredDomains?.length
      : false;
  };

  const disabled = Boolean(
    pageHasEmptyField ||
      !!Object.keys(formik.errors).length ||
      hasDuplicatedDomains()
  );

  const shouldShowNavigationModal = Boolean(
    formik.values.name ||
      formik.values.state ||
      formik.values.city ||
      formik.values.website ||
      formik.values.domains.some(Boolean)
  );

  useBeforeUnload();

  useNavigationWarningModal({
    when: shouldShowNavigationModal,
    safeRoute: [TEACHER_APP_ROUTES.getWhitelistDomains()],
  });

  return (
    <StyledLayoutContainer>
      <CreateFormHeader
        activeIndex={0}
        title={t('create_school_details')}
        exitPath={TEACHER_APP_ROUTES.getAdministration(
          AdministrationTabs.schools
        )}
        showExitButton={Boolean(schools.length)}
      />
      <form
        id="school-form"
        data-cy="newSchoolForm"
        onSubmit={formik.handleSubmit}
      >
        <StyledTwoColumnBox>
          <StyledInnerContainer>
            <StyledSmallTitle>{t('school_naming')}</StyledSmallTitle>

            <SchoolInfoEdit formik={formik} />
          </StyledInnerContainer>

          <StyledInnerContainer>
            <StyledSmallTitle>{t('google_drive_domain')}</StyledSmallTitle>

            <SchoolDomainInfoEdit formik={formik} />
          </StyledInnerContainer>
        </StyledTwoColumnBox>
      </form>

      <StyledFooter>
        {schools.length ? (
          <StyledButton
            onClick={() => {
              navigate(
                TEACHER_APP_ROUTES.getAdministration(AdministrationTabs.schools)
              );
            }}
          >
            {t('back')}
          </StyledButton>
        ) : null}
        <StyledNextButton
          loading={isLoading}
          disabled={disabled}
          form="school-form"
        >
          {t('next')}
        </StyledNextButton>
      </StyledFooter>
    </StyledLayoutContainer>
  );
};
