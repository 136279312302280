import { Document } from '@xq/domain';
import { DocumentMapper } from '@xq/shared/data-access';

import { IStorageAdapter } from './index';
import { GoogleDriveUploadService } from '../services/GoogleDriveUploadService';
import { loggerService } from '../services/LoggerService';

export class GoogleDriveAdapter implements IStorageAdapter {
  private static instance: GoogleDriveAdapter;

  static getInstance() {
    if (!GoogleDriveAdapter.instance) {
      GoogleDriveAdapter.instance = new GoogleDriveAdapter();
    }
    return GoogleDriveAdapter.instance;
  }

  async upload(file: File, folderId: string) {
    try {
      const uploadedFile = (
        await GoogleDriveUploadService.upLoadToDrive(file, folderId)
      ).result;
      return DocumentMapper.fromDrive(uploadedFile);
    } catch (error) {
      loggerService.error(
        'There was an error while trying to upload a file to drive: ',
        error
      );
      throw error;
    }
  }

  async copy(file: Document, folderId: string) {
    try {
      const uploadedFile = (
        await GoogleDriveUploadService.copyFromDrive(file, folderId)
      ).result;
      return DocumentMapper.fromDrive(uploadedFile);
    } catch (error) {
      loggerService.error(
        'There was an error while trying to upload a file to drive: ',
        error
      );
      throw error;
    }
  }

  async getById(id: string) {
    try {
      const file = (await GoogleDriveUploadService.getFile(id)).result;
      return DocumentMapper.fromDrive(file);
    } catch (error) {
      loggerService.error(
        'There was an error while trying to retrieve a file from drive: ',
        error
      );
      throw error;
    }
  }

  async getAll(options: { token: string; id: string }) {
    try {
      const { token, id } = options;
      const list = await GoogleDriveUploadService.listFiles(token, id);

      return {
        files: list?.files?.map((file) => DocumentMapper.fromDrive(file)) || [],
        nextPageToken: list?.nextPageToken || '',
      };
    } catch (error) {
      loggerService.error(
        'There was an error while trying to retrieve files from drive: ',
        error
      );
      throw error;
    }
  }

  async delete(id: string) {
    try {
      return await GoogleDriveUploadService.deleteFromDrive(id);
    } catch (error) {
      loggerService.error(
        'There was an error while trying to delete a file from drive: ',
        error
      );
      throw error;
    }
  }

  async move(fileId: string, destinationId: string) {
    try {
      return await GoogleDriveUploadService.moveFile(fileId, destinationId);
    } catch (error) {
      loggerService.error(
        'There was an error while trying to move a file from drive: ',
        error
      );
      throw error;
    }
  }

  async isInSchoolStructure(fileId: string) {
    try {
      return await GoogleDriveUploadService.isInSchoolStructure(fileId);
    } catch (error) {
      loggerService.error(
        'There was an error while trying to check if the file is in school structure: ',
        error
      );
      throw error;
    }
  }
}
