import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikProps,
  FormikProvider,
} from 'formik';

import {
  FormInputField,
  StyledSmallTitle,
  DomainInputField,
} from '@xq/web-components';
import { School } from '@xq/domain';

import { StyledFieldRow } from '../Layout/FieldRow';
import { SchoolDomainExplanation } from '../SchoolDomainExplanation/SchoolDomainExplanation';

type SchoolDomainInfoEditProps<T> = {
  formik: FormikProps<T>;
};

export const SchoolDomainInfoEdit = <
  T extends Pick<School, 'domains'> & { domain: string }
>({
  formik,
}: SchoolDomainInfoEditProps<T>) => {
  const { t } = useTranslation();

  const canAddDomain = (index: number, domainsCount: number) =>
    domainsCount < 5 && index === domainsCount - 1;

  const renderDomains = ({ push, remove }: FieldArrayRenderProps) => {
    return (
      <StyledFieldRow>
        {formik.values.domains.map((domain, index) => (
          <DomainInputField
            key={index}
            canAdd={canAddDomain(index, formik.values.domains?.length || 0)}
            insert={() => push('')}
            remove={() => remove(index)}
            index={index}
            domain={domain}
            formik={formik}
            label={t('school_domain_optional')}
          />
        ))}
      </StyledFieldRow>
    );
  };

  return (
    <div>
      <FormHelperText>{t('domain_name_explanation')}</FormHelperText>

      <StyledFieldRow style={{ marginBottom: 34, marginTop: 10 }}>
        <FormInputField
          disabled
          fieldName="domain"
          label={t('domain_link')}
          formik={formik}
        />
      </StyledFieldRow>

      <StyledSmallTitle>{t('school_domain_optional')}</StyledSmallTitle>

      <FormHelperText>{t('school_domain_explanation')}</FormHelperText>

      <SchoolDomainExplanation />

      <FormikProvider value={formik}>
        <FieldArray name="domains" render={renderDomains} />
      </FormikProvider>
    </div>
  );
};
