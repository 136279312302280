import { createSelector } from '@reduxjs/toolkit';

import { selectFeedState } from '../index';

export const selectFeedIsLoading = createSelector(
  [selectFeedState],
  (state) => {
    return state.loading;
  }
);

export const selectFeedHasNextPage = createSelector(
  [selectFeedState],
  (state) => {
    return state.hasNextPage;
  }
);

export const selectFeedReachedCallLimit = createSelector(
  [selectFeedState],
  (state) => {
    return state.reachedCallLimit;
  }
);

export const selectFeedCurrentUserId = createSelector(
  [selectFeedState],
  (state) => {
    return state.currentSelectedUserId;
  }
);

export const selectFeedReachedCallLimitBy = createSelector(
  [selectFeedState],
  (state) => {
    return state.reachedCallLimit;
  }
);

export const selectFeedActivityIds = createSelector(
  [selectFeedState],
  (state) => {
    return state.activityIds;
  }
);

export const selectFeedId = createSelector([selectFeedState], (state) => {
  return state.feedId;
});

export const selectFeedAutoTagTypeForFilter = createSelector(
  [selectFeedState],
  (state) => {
    return state.autoTagTypeForFilter;
  }
);

export const selectFeedCurrentSelectedUserId = createSelector(
  [selectFeedState],
  (state) => {
    return state.currentSelectedUserId;
  }
);

export const selectFeedSelectableElementId = createSelector(
  [selectFeedState],
  (state) => {
    return state.selectableElementId;
  }
);

export const selectFeedContentTitle = createSelector(
  [selectFeedState],
  (state) => {
    return state.contentTitle;
  }
);

export const selectFeedJournalLink = createSelector(
  [selectFeedState],
  (state) => {
    return state.journalLink;
  }
);
