import { useEffect } from 'react';
import { SelectChangeEvent, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  StyledMenuTitle,
  StyledSelect,
  StyledSelectSchoolItem,
} from './SelectSchool.styles';
import {
  selectAllSchools,
  selectCurrentUserState,
  useAppDispatch,
  useAppSelector,
  selectCurrentUserSchoolId,
  persistCurrentSchool,
} from '../store';
import { MOBILE_MEDIA_QUERY } from '../utils';

export const SelectSchool = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUserState);
  const schools = useAppSelector(selectAllSchools);
  const selectedSchoolId = useAppSelector(selectCurrentUserSchoolId);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const handleChange = async (event: SelectChangeEvent<any>) => {
    if (!currentUser.currentSchoolId) {
      return;
    }

    dispatch(
      persistCurrentSchool({
        userId: currentUser.uid,
        schoolId: event.target.value,
      })
    );
  };

  useEffect(() => {
    const schoolExists = schools.find((s) => s.id === selectedSchoolId);

    if (!schoolExists && schools.length) {
      dispatch(
        persistCurrentSchool({
          userId: currentUser.uid,
          schoolId: schools[0].id,
        })
      );
    }
  }, [currentUser.uid, schools, selectedSchoolId]);

  if (!schools.length) return null;

  return (
    <StyledSelect
      disableUnderline={true}
      IconComponent={ExpandMoreIcon}
      value={!schools.length || selectedSchoolId || ''}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      onChange={handleChange}
      ismobile={`${isMobile}`}
    >
      {schools.map((s) => (
        <StyledSelectSchoolItem value={s.id} key={s.id}>
          <StyledMenuTitle>{s.name}</StyledMenuTitle>
        </StyledSelectSchoolItem>
      ))}
    </StyledSelect>
  );
};
