import { useState, useEffect, useMemo } from 'react';
import { format } from 'date-fns-tz';
import { Divider, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  TENOR_EMBED_URL,
  TENOR_URL,
  TITKTOK_EMBED_URL,
  TITKTOK_URL,
} from '@xq/web-config';
import {ContentType, getUserRole, SubPhaseContent} from '@xq/domain';

import {useNotifier, usePhase} from '../../context';
import {
  StyledAccordion,
  StyledAccordSummary,
  StyledSubAccordionContent,
  StyledSubAccordionTitle,
  StyledAccordionsContainer,
} from './SubAccordion.styles';
import { decodeEntities } from '../../utils/decodeEntities';
import { useEmbeddedContent } from '../../hooks';
import { DesmosDialog } from '../../DesmosCalculator/DesmosDialog';

import {
  selectCurrentUserState,
  useAppSelector,
  useAppDispatch,
  selectPhaseContent,
  selectUserCalculatorByClassroomIdByProgramIdBySubPhaseId,
  setUserCalculator,
  selectIsTeacherApp,
} from '../../store';
import {
  selectCurrentUserClassroom,
  selectCurrentUserProgram,
} from '../../store/current-user/current-user-selectors';
import { useParams } from "react-router-dom";

type SubAccordionProps = {
  left?: JSX.Element;
  subAccordionLabel?: JSX.Element;
  footer?: JSX.Element;
  onToggle: () => void;
  opened: boolean;
  subPhaseContent: SubPhaseContent;
  renderContent?: (content: string, title: string) => JSX.Element;
  wpTemplateCalculator?: string;
};

export const SubAccordion = ({
  left,
  subAccordionLabel,
  footer,
  onToggle,
  opened,
  subPhaseContent,
  renderContent,
  wpTemplateCalculator = '',
}: SubAccordionProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(opened);
  const [isDesmosDialogOpen, setIsDesmosDialogOpen] = useState<boolean>(false);
  const currentUser = useAppSelector(selectCurrentUserState);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const { isStudent  } = getUserRole(currentUser);
  const { notifyTeachersForCalculatorReview } = useNotifier();
  const dispatch = useAppDispatch();
  const isTeacherApp = useAppSelector(selectIsTeacherApp);
  const { phaseIndex = '0', studentId = '' } = useParams();
  const phaseContent = useAppSelector((state) =>
    selectPhaseContent(state, +phaseIndex)
  );

  const userCalculator = useAppSelector((state) =>
    selectUserCalculatorByClassroomIdByProgramIdBySubPhaseId(
      state,
      isTeacherApp ? studentId : currentUser.uid,
      currentClassroom?.id ?? '',
      currentProgram?.id ?? '',
      subPhaseContent.id,
    )
  );

  const { contentClickHandler } = usePhase();

  const handleCalculatorSave = async (calculatorState: string) => {
    dispatch(setUserCalculator({
      userId: currentUser.uid,
      classroomId: currentClassroom?.id as string,
      programId: currentProgram?.id as string,
      phaseId: (phaseContent?.[ContentType.PhaseContent]?.id) as number,
      wpPostId: subPhaseContent.id,
      calculatorState,
      title: subPhaseContent.title,
      forReview: !!(userCalculator?.forReview),
      updatedAt: new Date(),
    }));
  };

  const handleMarkForReview = async (calculatorState: string, forReview: boolean) => {
    dispatch(setUserCalculator({
      userId: currentUser.uid,
      classroomId: currentClassroom?.id as string,
      programId: currentProgram?.id as string,
      phaseId: (phaseContent?.[ContentType.PhaseContent]?.id) as number,
      wpPostId: subPhaseContent.id,
      calculatorState,
      title: subPhaseContent.title,
      forReview,
      updatedAt: new Date(),
    }));

    if (isStudent) {
      return notifyTeachersForCalculatorReview(subPhaseContent.title, forReview);
    }
  }

  const content = useMemo(
    () => decodeEntities(subPhaseContent.content),
    [subPhaseContent.content]
  );

  useEmbeddedContent({
    content,
    contentUrlString: TITKTOK_URL,
    embedUrl: TITKTOK_EMBED_URL,
  });

  useEmbeddedContent({
    content,
    contentUrlString: TENOR_URL,
    embedUrl: TENOR_EMBED_URL,
  });

  useEffect(() => {
    setIsOpened(opened);
  }, [opened]);

  const renderAccordionContent = () => {
    if (renderContent) {
      return (
        <StyledSubAccordionContent>
          {renderContent(content, subPhaseContent.title)}
        </StyledSubAccordionContent>
      );
    }

    return (
      <StyledSubAccordionContent
        onClick={contentClickHandler}
        dangerouslySetInnerHTML={{ __html: content }}
        title={subPhaseContent.title}
      />
    );
  };

  return (
    <StyledAccordionsContainer>
      {/*{subPhaseContent.id}*/}
      <StyledAccordion
        onChange={() => {
          onToggle();
          setIsOpened(!isOpened);
        }}
        expanded={subPhaseContent.isStickyPost || isOpened}
        disabled={subPhaseContent.isStickyPost}
      >
        <StyledAccordSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                visibility: subPhaseContent.isStickyPost ? 'hidden' : 'visible',
              }}
            />
          }
        >
          {left}
          <StyledSubAccordionTitle>
            {decodeEntities(subPhaseContent.title)}
          </StyledSubAccordionTitle>
          {subAccordionLabel}
        </StyledAccordSummary>
        <AccordionDetails>
          {renderAccordionContent()}
          {userCalculator?.calculatorState || wpTemplateCalculator ? (
              <div>
                <DesmosDialog
                  forReview={!!userCalculator?.forReview}
                  onMarkForReview={handleMarkForReview}
                  title={subPhaseContent.title}
                  onSave={handleCalculatorSave}
                  content={content}
                  isOpen={isDesmosDialogOpen}
                  desmosCalculator={userCalculator?.calculatorState || wpTemplateCalculator}
                  closeDialog={() => setIsDesmosDialogOpen(false)}
                />
                <div style={{ marginBottom: '10px', marginLeft: '20px' }}>
                  <Button
                    onClick={() => setIsDesmosDialogOpen(true)}
                    variant="contained"
                    sx={{ display: 'inline-block' }}
                  >
                    Open Calculator
                  </Button>
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '15px',
                      display: 'inline-block',
                      opacity: '0.6',
                    }}
                  >
                {userCalculator?.updatedAt ? `Last edited on ${format(new Date(userCalculator.updatedAt), 'MM/dd/yyyy, H:mm')}` : ''}
              </span>
                </div>
              </div>
            ) : null
          }
          {footer}
        </AccordionDetails>
        <Divider />
      </StyledAccordion>
    </StyledAccordionsContainer>
  );
};
