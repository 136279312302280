import { firebaseService, loggerService } from '@xq/infrastructure';
import { callWithRetry } from '@xq/shared/utils';

export class ModifyPermissionsUseCase {
  static async execute(data: {
    emails: string[];
    fileId: string;
    shouldRemove: boolean;
    schoolId?: string;
  }): Promise<boolean> {
    const { emails, fileId, shouldRemove, schoolId } = data;

    const modifyDrivePermissions = firebaseService.prepareFunctionCaller(
      'modifyDrivePermissions'
    );

    try {
      await callWithRetry(() =>
        modifyDrivePermissions({
          emails,
          fileId,
          shouldRemove,
          schoolId
        })
      );
      return true;
    } catch (e) {
      loggerService.error('Modifying drive permissions has failed', e);
      throw new Error(e as string);
    }
  }
}
