import { loggerService } from '@xq/infrastructure';
import { IsSchoolNameUniqueInStateUseCase } from '@xq/usecases/IsSchoolNameUniqueInStateUseCase';

export type SchoolNameUniqueInStateValidationProps = {
  schoolName: string;
  state: string;
  city: string;
};

export const schoolNameUniqueInStateValidation = async ({
  schoolName,
  state,
  city,
}: SchoolNameUniqueInStateValidationProps) => {
  try {
    return await IsSchoolNameUniqueInStateUseCase.execute(
      schoolName,
      state,
      city
    );
  } catch (e) {
    loggerService.error(e);
    return false;
  }
};
