import { styled } from '@mui/material';

export const Container = styled('div')`
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  left: 0;
`;

export const LinesContainer = styled('div')`
  display: grid;
  position: relative;
  grid-template-rows: 26px 1fr;
  height: 100%;
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
`;

export const StyledUpperLine = styled('div')`
  border-width: 1px;
  border-style: solid;
  border-color: transparent transparent var(--moss-green) var(--moss-green);
`;

export const StyledBottomLine = styled('div')`
  border-left: 1px solid var(--moss-green);
`;

export const StyledDot = styled('div')`
  width: 5px;
  height: 5px;
  background-color: var(--green-primary);
  position: absolute;
  top: 23px;
  right: 0;
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
`;
