import { Dispatch, memo, SetStateAction, MouseEvent, useState } from 'react';
import {
  Box,
  ListItemText,
  DrawerProps,
  MenuItem,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { useHash } from 'react-use';

import { Notification } from '@xq/domain';

import { NotificationListItem } from '../NotificationListItem/NotificationListItem';
import { verbToPath } from '../utils/verbToPath';
import {
  StyledDrawer,
  StyledIconButton,
  StyledListItem,
  StyledMarkAllButton,
  StyledMenu,
  StyledMessageContainer,
  StyledMoreHorizIcon,
  StyledNotificationHeader,
  StyledNotificationsMenu,
  StyledNotificationTitle,
  StyledScrollContainer,
} from './NotificationsDrawer.styles';
import {
  currentUserActions,
  selectCurrentUserState,
  selectLastNotificationId,
  selectNotifications,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { useNotifications } from '../context';
import { MOBILE_MEDIA_QUERY } from '../utils';
import {
  isTemplateNotification,
  NotificationSupportedVerbs,
} from '@xq/shared/data-access';

export interface NotificationPaths {
  getJournal?: (id?: string) => string;
  getChats?: (id: string) => string;
  getJournalFeed?: () => string;
  getJournalPhase?: (
    phaseIndex: string,
    tabIndex: string,
    subPhaseIndex: string,
    subPhaseContentIndex: string
  ) => string;
  getStudentFiles?: () => string;
}

interface INotificationsProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  drawerAnchor: DrawerProps['anchor'];
  isStudentFeed?: boolean;
  paths: NotificationPaths;
}

export const NotificationsDrawer = ({
  isOpen,
  setIsOpen,
  drawerAnchor,
  paths,
  isStudentFeed = false,
}: INotificationsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [, updateHash] = useHash();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const [anchorEl, setAnchorEl] = useState<
    Element | (() => Element)
  >();

  const currentUser = useAppSelector(selectCurrentUserState);
  const notifications = useAppSelector(selectNotifications);

  const lastNotificationId = useAppSelector(selectLastNotificationId);

  const {
    markAsReadNotifications,
    getNotifications,
    markAllAsRead,
    removeAllNotifications,
  } = useNotifications();

  const notificationClick = (notification: Notification) => {
    setIsOpen(false);
    if (notification.group && !notification.isRead) {
      markAsReadNotifications([notification.notificationId]);
    }

    if (notification.classroomId) {
      dispatch(
        currentUserActions.setCurrentClassroomId(notification.classroomId)
      );

      if (notification.programId) {
        dispatch(
          currentUserActions.setCurrentProgramId({
            programId: notification.programId,
            classroomId: notification.classroomId,
          })
        );
      }
    }

    if (isTemplateNotification(notification.verb as NotificationSupportedVerbs))
      return;

    const { path, hash } = verbToPath(paths, notification, isMobile);

    navigate(path);
    updateHash(hash);
  };

  const openMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(undefined);
  };

  const markAllNotificationsAsRead = () => {
    markAllAsRead();
    setIsOpen(false);
    closeMenu();
  };

  return (
    <div key={drawerAnchor}>
      <StyledDrawer
        anchor={drawerAnchor}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Box role="presentation">
          <StyledNotificationHeader>
            <StyledNotificationTitle>
              {t('notifications')}
            </StyledNotificationTitle>
            <StyledNotificationsMenu>
              <StyledIconButton tabIndex={0} onClick={openMenu}>
                <StyledMoreHorizIcon />
              </StyledIconButton>
              <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
              >
                <MenuItem onClick={markAllNotificationsAsRead}>
                  <StyledMarkAllButton
                    tabIndex={0}
                    onClick={markAllNotificationsAsRead}
                  >
                    {t('mark_as_read')}
                  </StyledMarkAllButton>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    removeAllNotifications(currentUser.uid);
                    closeMenu();
                  }}
                >
                  <StyledMarkAllButton>{t('clear_all')}</StyledMarkAllButton>
                </MenuItem>
              </StyledMenu>
            </StyledNotificationsMenu>
          </StyledNotificationHeader>
          <StyledScrollContainer>
            <Virtuoso
              components={{
                EmptyPlaceholder: () => (
                  <StyledListItem disableGutters>
                    <StyledMessageContainer>
                      <ListItemText>{t('no_notificactions')}</ListItemText>
                    </StyledMessageContainer>
                  </StyledListItem>
                ),
              }}
              data={notifications}
              endReached={(lastNotificationIndex) => {
                const lastNotification = notifications[lastNotificationIndex];

                if (
                  lastNotificationId &&
                  !isTemplateNotification(
                    lastNotification.verb as NotificationSupportedVerbs
                  )
                ) {
                  getNotifications(lastNotificationId);
                }
              }}
              itemContent={(index, notification) => {
                return (
                  <NotificationListItem
                    key={
                      notification.notificationId || notification.id || index
                    }
                    isStudentFeed={isStudentFeed}
                    notification={notification}
                    handleNotificationClick={() => {
                      notificationClick(notification);
                    }}
                  />
                );
              }}
            />
          </StyledScrollContainer>
        </Box>
      </StyledDrawer>
    </div>
  );
};

export const MemoizedNotificationsDrawer = memo(NotificationsDrawer);
