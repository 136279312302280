import {
  collection,
  deleteField,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  getDoc,
  Timestamp,
  setDoc,
} from 'firebase/firestore';
import { chunk } from 'lodash';

import { User } from '@xq/domain';
import {
  FbUserDto,
  UserMapper,
  CollectionNames,
  DocumentMapper,
  FbFileDto,
} from '@xq/shared/data-access';

import {
  firebaseService,
  IFirebaseService,
} from '../../services/FirebaseService';
import {
  IUserRepository,
  SetFileProps,
  DeleteFileProps,
  ToggleMarkForReviewFileProps,
  GetTeacherTemplatesBySchoolIdProps,
} from './index';

export class UserRepository implements IUserRepository {
  private firebase: IFirebaseService = firebaseService;

  private collectionName = CollectionNames.users;

  private filesPath = CollectionNames.usersFiles;

  private collection = collection(this.firebase.db, this.collectionName);

  private mapper = new UserMapper();

  private async getUserSnapshot(id: string) {
    return getDoc(doc(firebaseService.db, `${CollectionNames.users}/${id}`));
  }

  async userExists(userId: string) {
    if (!userId) return false;

    const userSnapshot = await getDoc(
      doc(this.firebase.db, `${this.collectionName}/${userId}`)
    );

    return !userSnapshot || !userSnapshot?.exists() ? false : true;
  }

  async getUserById(uid: string): Promise<User | null> {
    if (!uid) {
      return null;
    }

    const snapshot = await this.getUserSnapshot(uid);

    if (!snapshot.exists()) {
      return null;
    }

    return this.mapper.toDomain(snapshot.data() as FbUserDto);
  }

  async getUsersByEmail(emails: string[]): Promise<User[]> {
    const queries = chunk(emails, 10).map((emailsChunk) =>
      getDocs(query(this.collection, where('email', 'in', emailsChunk)))
    );
    const snapshots = await Promise.all(queries);
    const result: User[] = [];

    snapshots.forEach((snapshot) => {
      snapshot.docs.forEach((doc) => {
        const user = this.mapper.toDomain(doc.data() as FbUserDto);
        result.push(user);
      });
    });

    return result;
  }

  async getCurrentUser(): Promise<User | null> {
    const uid = this?.firebase?.auth?.currentUser?.uid;
    if (uid) {
      return this.getUserById(uid);
    }

    return null;
  }

  async persistUserCurrentClassroomId(
    userId: string,
    currentClassroomId: string
  ): Promise<void> {
    const userSnapshot = await this.getUserSnapshot(userId);

    if (userSnapshot.exists()) {
      await updateDoc(userSnapshot.ref, { currentClassroomId });
    }
  }

  async persistUserCurrentProgramId(
    userId: string,
    classroomId: string,
    programId: string
  ): Promise<void> {
    const userSnapshot = await this.getUserSnapshot(userId);

    if (userSnapshot.exists()) {
      const data = {
        currentProgramIdByClassroomId: {
          [classroomId]: programId,
        },
      };
      await setDoc(userSnapshot.ref, data, { merge: true });
    }
  }

  async persistUserCurrentSchool(
    userId: string,
    currentSchoolId: string
  ): Promise<void> {
    const userSnapshot = await this.getUserSnapshot(userId);

    if (userSnapshot.exists()) {
      await updateDoc(userSnapshot.ref, { currentSchoolId });
    }
  }

  setFile({ fileData, userId, programId, classroomId, batch }: SetFileProps) {
    const ref = doc(
      this.firebase.db,
      `${this.collectionName}/${userId}/${this.filesPath}/${classroomId}-${programId}`
    );

    batch.set(
      ref,
      {
        [fileData.id]: DocumentMapper.toPersistence(fileData),
      },
      { merge: true }
    );
  }

  toggleMarkForReviewFile({
    fileId,
    isForReview,
    userId,
    programId,
    classroomId,
  }: ToggleMarkForReviewFileProps) {
    const ref = doc(
      this.firebase.db,
      `${this.collectionName}/${userId}/${this.filesPath}/${classroomId}-${programId}`
    );

    return updateDoc(ref, {
      [`${fileId}.properties.isForReview`]: isForReview,
    });
  }

  deleteFile({
    userId,
    classroomId,
    programId,
    fileId,
    batch,
  }: DeleteFileProps) {
    const ref = doc(
      this.firebase.db,
      `${this.collectionName}/${userId}/${this.filesPath}/${classroomId}-${programId}`
    );

    batch.update(ref, { [fileId]: deleteField() });
  }

  private dateToTimestamp(date: Date): Timestamp {
    return Timestamp.fromDate(date);
  }

  async updateLastActivity(userId: string) {
    const userSnapshot = await this.getUserSnapshot(userId);

    if (userSnapshot?.exists()) {
      await updateDoc(userSnapshot.ref, {
        lastActivity: this.dateToTimestamp(new Date()),
      });
    }
  }

  async getTeacherTemplatesBySchoolId({
    teacherId,
    schoolId,
  }: GetTeacherTemplatesBySchoolIdProps) {
    const snapshot = await getDoc(
      doc(
        this.firebase.db,
        `${this.collectionName}/${teacherId}/${CollectionNames.templates}/${schoolId}`
      )
    );

    if (!snapshot.exists()) return [];

    return Object.values<FbFileDto>(snapshot.data()).map((fileDto) =>
      DocumentMapper.toDomain(fileDto)
    );
  }
}
