import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';

import {
  HorizontalAnchorOrigin,
  removeSnackbar,
  selectSnackbarState,
  SnackbarVariant,
  useAppSelector,
  VerticalAnchorOrigin,
  SnackbarOptions,
  useAppDispatch,
} from '../store';

export const useSnackbarNotifier = () => {
  const dispatch = useAppDispatch();

  const { snackbars } = useAppSelector(selectSnackbarState);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const defaultOptions: SnackbarOptions = useMemo(
    () => ({
      variant: SnackbarVariant.SUCCESS,
      anchorOrigin: {
        vertical: VerticalAnchorOrigin.BOTTOM,
        horizontal: HorizontalAnchorOrigin.RIGHT,
      },
      autoHideDuration: 10000, // milliseconds
    }),
    []
  );

  useEffect(() => {
    snackbars.forEach(({ key, message, options, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      enqueueSnackbar(message, {
        ...{ ...defaultOptions, ...options },
        key,
        preventDuplicate: true,
        onExited: (_, exitKey) => {
          dispatch(removeSnackbar(exitKey.toString()));
        },
      });
    });
  }, [snackbars, closeSnackbar, enqueueSnackbar, dispatch, defaultOptions]);
};
