import {
  FBWordPressPhaseContent,
  WordPressPhaseContent,
} from '../dto/word-press-phase-content.dto';

class WpPhaseContentMapper {
  toPersistence({
    acf,
    author,
    categories,
    content,
    date,
    excerpt,
    guid,
    id,
    link,
    modified,
    slug,
    status,
    template,
    title,
    type,
  }: WordPressPhaseContent): FBWordPressPhaseContent {
    return {
      acf,
      author,
      categories,
      content,
      date,
      excerpt,
      guid,
      id,
      link,
      modified,
      slug,
      status,
      template,
      title,
      type,
    };
  }
}

export const wpContentMapper = new WpPhaseContentMapper();
