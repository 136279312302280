import { MenuItem } from '@mui/material';
import { cx } from '@emotion/css';
import { TypeaheadOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import styles from './mentions-plugin.module.scss';

export class MentionTypeaheadOption extends TypeaheadOption {
  name: string;
  value: string;

  constructor(name: string, value: string) {
    super(name);
    this.name = name;
    this.value = value;
  }
}

export function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
}) {
  return (
    <MenuItem
      key={option.key}
      tabIndex={-1}
      className={cx(styles.mentionItem, {
        [styles.selectedMentionItem]: isSelected,
      })}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <span className="text">{option.name}</span>
    </MenuItem>
  );
}
