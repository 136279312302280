import { Errors, User, UserRoles } from '@xq/domain';
import {
  ClassroomRepository,
  CreateFileProps,
  GoogleDriveUploadService,
  loggerService,
} from '@xq/infrastructure';
import { GoogleFilesMimeTypes } from '@xq/domain';
import { GetAccessTokenUseCase } from '../UserUseCases/GetAccessTokenUseCase';

export class GoogleDriveCheckUseCase {
  async execute(currentUser: User) {
    await GetAccessTokenUseCase.execute();
    const repo = new ClassroomRepository();
    const isTeacher = currentUser.roles.includes(UserRoles.teacher);
    const classroomId =
      currentUser.currentClassroomId || currentUser.classroomIds[0];
    const teacherFolderIds = async () =>
      (
        await repo.getClassroomsBySchoolId(
          currentUser.currentSchoolId as string
        )
      )
        .filter((r) => r.participantEmails.includes(currentUser.email))
        .map((cl) => cl.feedFolderId);
    const studentFolderIds = async () =>
      Object.values(
        (await repo.getStudentFolderIdByEmailByProgramId(classroomId))[
          currentUser.email
        ].folderByProgramId
      );

    const folderIds = isTeacher
      ? await teacherFolderIds()
      : await studentFolderIds();

    for (const fileId of folderIds) {
      try {
        const testFile = (
          await GoogleDriveUploadService.createFile(<CreateFileProps>{
            folderId: fileId,
            fileName: 'test',
            mimeType:
              GoogleFilesMimeTypes['application/vnd.google-apps.document'],
          })
        ).result;
        await GoogleDriveUploadService.getFile(testFile.id as string);
        await GoogleDriveUploadService.deleteFromDrive(testFile.id as string);
      } catch (e) {
        loggerService.error(
          `System check google drive error isSystemCheckError for user with ID ${
            currentUser.uid
          }:
          ${JSON.stringify(e)}`,
          { systemCheckError: true, userId: currentUser.uid }
        );
        loggerService.error(`Original error: ${e}`);
        return false;
      }
    }

    return true;
  }
}
