import { ButtonBase, Grid, styled } from '@mui/material';

export const StyledRoot = styled(Grid)`
  display: flex;
  height: 100%;
  overflow: auto;
  padding: 16px;
`;

export const StyledGrid = styled(Grid)`
  position: relative;
  height: 100%;
  overflow: auto;
`;

export const StyledContainer = styled('div')`
  background-color: var(--white);
  height: 100%;
  overflow: auto;
  padding: 8px 16px;
`;

export const StyledPhase = styled(ButtonBase)`
  display: block;
` as typeof ButtonBase;

export const StyledPhaseTitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledPhaseTitle = styled('div')`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 16px 0;

  &.active {
    color: var(--green-primary);
    font-weight: 700;
  }
`;
