import styles from './NoActivityMessage.module.scss';

interface NoActivityMessageProps {
  message: string;
}

export const NoActivitiesMessage = ({ message }: NoActivityMessageProps) => {
  return (
    <div className={styles.container}>
      <p>{message}</p>
    </div>
  );
};
