import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import styles from './horizontal-stepper.module.scss';

export interface HorizontalStepperProps {
  numberOfSteps?: number;
  activeIndex?: number;
  completeIndexes?: Array<number>;
}
const chooseStepperIcon = (
  index: number,
  completeIndexes: Array<number>,
  activeIndex?: number
) => {
  if (activeIndex === index) {
    return <ModeStandbyIcon fontSize="large" className={styles.darkIcon} />;
  } else if (completeIndexes.includes(index)) {
    return <CheckCircleIcon fontSize="large" className={styles.darkIcon} />;
  }

  return <PanoramaFishEyeIcon fontSize="large" className={styles.lightIcon} />;
};

export const HorizontalStepper = ({
  numberOfSteps = 3,
  activeIndex,
  completeIndexes = [],
}: HorizontalStepperProps) => {
  const steps = Array.from(Array(numberOfSteps).keys());
  return (
    <div className={styles.stepperContainer}>
      {steps.map((step, i) => (
        <div className={styles.fragmentWrapper} key={i}>
          {chooseStepperIcon(i, completeIndexes, activeIndex)}
          {i < steps.length - 1 ? (
            <div
              className={
                completeIndexes.includes(i)
                  ? styles.darkDivider
                  : styles.divider
              }
            />
          ) : null}
        </div>
      ))}
    </div>
  );
};
