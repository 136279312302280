import {
  ResourceAnchorItem,
  ResourceLinkItem,
} from '../ResourceItem/ResourceItem';
import type { ResourceItem } from '../ResourceItem/ResourceItem';

export enum ResourceTypeComp {
  Link,
  Anchor,
}

type ResourceListPanelProps = {
  resources: ResourceItem[];
  resourceTypeComp?: ResourceTypeComp;
  onScroll?: () => void;
};

const COMPONENT_MAP = {
  [ResourceTypeComp.Link]: (key: number, resource: ResourceItem) => (
    <ResourceLinkItem key={key} resource={resource} />
  ),
  [ResourceTypeComp.Anchor]: (key: number, resource: ResourceItem) => (
    <ResourceAnchorItem key={key} resource={resource} />
  ),
};

export const ResourceListPanel = ({
  resources,
  resourceTypeComp = ResourceTypeComp.Link,
  onScroll,
}: ResourceListPanelProps) => {
  return (
    <div
      onScroll={onScroll}
      style={{
        overflow: 'auto',
      }}
    >
      {resources?.map((resource, i) =>
        COMPONENT_MAP[resourceTypeComp](i, resource)
      )}
    </div>
  );
};
