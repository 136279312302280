import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { User } from '@xq/domain';

import { fetchUsersByEmail } from './users-actions';

export const usersAdaptor = createEntityAdapter<User>({
  selectId: (user) => user.uid,
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdaptor.getInitialState({}),
  reducers: {
    userUpdated: usersAdaptor.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsersByEmail.fulfilled, (state, action) => {
      if (action.payload.length) {
        usersAdaptor.setMany(state, action.payload);
      }
    });
  },
});

export const usersActions = usersSlice.actions;
