import { z } from 'zod';

export const CreateSchoolStructureSchema = z.object({
  tokenType: z.string().nullish().optional(),
  accessToken: z.string(),
  email: z.string(),
  schoolId: z.string(),
});

export type CreateSchoolStructureRequestDto = z.infer<
  typeof CreateSchoolStructureSchema
>;

export type CreateSchoolStructureResponseDto = {
  message: string;
  status?: number;
};
