export * from './classroomNameUniqueValidation';
export * from './emailBackendValidation';
export * from './emailDomainValidation';
export * from './emailDuplicatedValidation';
export * from './emailFormatValidation';
export * from './emailFullValidation';
export * from './emailUniqueValidation';
export * from './schoolNameUniqueInStateValidation';

export enum EmailErrors {
  invalid_format = 'invalid_format',
  duplicated = 'duplicated',
  wrong_role = 'wrong_role',
  invalid_domain = 'invalid_domain',
  already_viewer = 'already_viewer',
}

export interface InvalidEmail {
  email: string;
  error: EmailErrors;
  classroomNames?: string[];
}

export type ValidationTestResult = {
  valid: string[];
  inValid: InvalidEmail[];
};
