import { createSelector } from '@reduxjs/toolkit';

import { selectNotificationsState } from '../index';
import { notificationAdapter } from './notifications-slice';
import { selectUsersEntities } from '../users';
import {
  isTemplateNotification,
  NotificationSupportedVerbs,
} from '@xq/shared/data-access';

export const {
  selectEntities: selectNotificationEntities,
  selectById: selectNotificationById,
  selectAll: selectAllNotifications,
} = notificationAdapter.getSelectors(selectNotificationsState);

export const selectNotificationsIsLoading = createSelector(
  [selectNotificationsState],
  (state) => state.loading
);

export const selectNotificationsUnreadCount = createSelector(
  [selectNotificationsState],
  (state) => {
    return Object.values(state.entities).filter((notification) => {
      return !notification?.isRead
    }).length;
  }
);

export const selectLastNotificationId = createSelector(
  [selectNotificationsState],
  (state) => state.lastNotificationId
);

export const selectBeingDeleted = createSelector(
  [selectNotificationsState],
  (state) => state.beingDeleted
);

export const selectNotifications = createSelector(
  [selectAllNotifications, selectUsersEntities, selectBeingDeleted],
  (notifications, userEntities, beingDeleted) => {
    return notifications.filter(
      (n) =>
        Boolean(n) &&
        !beingDeleted.includes(n.id) &&
        (userEntities[n.actorId] ||
          isTemplateNotification(n.verb as NotificationSupportedVerbs))
    );
  }
);
