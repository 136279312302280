import { createAsyncThunk } from '@reduxjs/toolkit';
import { loggerService } from '@xq/infrastructure';
import { FetchCoursesUseCase } from '@xq/usecases';

export const fetchCourses = createAsyncThunk(
  'course/fetchCourses',
  async () => {
   const fetchCourses = new FetchCoursesUseCase();

    try {
      return await fetchCourses.execute();
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  }
);
