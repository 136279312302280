import {
  collection,
  getDocs,
  limit,
  query,
  where,
  documentId,
} from 'firebase/firestore';

import {
  FbSchoolDto,
  CollectionNames,
  DocumentMapper,
  FbFileDto,
  schoolMapper,
} from '@xq/shared/data-access';
import { insertIf } from '@xq/shared/utils';

import { firebaseService } from '../../services/FirebaseService';
import { ISchoolRepository } from './SchooRepository.interface';

export class SchoolRepository implements ISchoolRepository {
  private schoolsCollection = collection(
    firebaseService.db,
    CollectionNames.schools
  );

  private getTemplatesCollection = (schoolId: string) => {
    return collection(
      firebaseService.db,
      `${CollectionNames.schools}/${schoolId}/${CollectionNames.schoolsFiles}`
    );
  };

  async getSchoolsByDomain(email = '') {
    const domain = email.substring(email.lastIndexOf('@') + 1);
    const q = query(
      this.schoolsCollection,
      where('domains', 'array-contains', domain)
    );
    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc) =>
      schoolMapper.toDomain({
        ...doc.data(),
        id: doc.id,
      } as FbSchoolDto)
    );
  }

  async getSchoolsByAdminEmail(email: string) {
    const q = query(this.schoolsCollection, where('adminEmail', '==', email));

    return (await getDocs(q)).docs.map((doc) =>
      schoolMapper.toDomain({
        ...doc.data(),
        id: doc.id,
      } as FbSchoolDto)
    );
  }

  async getSchoolById(schoolId: string) {
    const q = query(
      this.schoolsCollection,
      where(documentId(), '==', schoolId),
      limit(1)
    );

    return (await getDocs(q)).docs.map((doc) =>
      schoolMapper.toDomain({
        ...doc.data(),
        id: doc.id,
      } as FbSchoolDto)
    );
  }

  async isSchoolNameUniqueInState(
    schoolName: string,
    state: string,
    city: string
  ) {
    const q = query(
      this.schoolsCollection,
      where('name', '==', schoolName),
      where('state', '==', state),
      ...insertIf(Boolean(city), [where('city', '==', city)]).flat()
    );

    return !(await getDocs(q)).docs.length;
  }

  async getTemplatesBySchoolId(schoolId: string) {
    const querySnapshot = await getDocs(this.getTemplatesCollection(schoolId));

    return querySnapshot.docs.map((doc) =>
      DocumentMapper.toDomain({ ...doc.data(), id: doc.id } as FbFileDto)
    );
  }

  async isAdmin(email: string | null) {
    const queryResult = query(
      this.schoolsCollection,
      where('adminEmail', '==', email),
      limit(1)
    );

    return Boolean((await getDocs(queryResult)).docs?.length);
  }

  async isDemoUser(email: string) {
    const demoSchoolId = process.env.NX_PUBLIC_DEMO_SCHOOL_ID ?? '';

    if (!demoSchoolId) {
      console.warn('DEMO SCHOOL ID IS NOT SET');
      return false;
    }

    const demoSchool = (await this.getSchoolById(demoSchoolId))[0];
    const demoTeacherEmail = process.env.NX_PUBLIC_DEMO_USER_EMAILS || '';
    const demoStudentEmails = demoSchool?.demoStudentWhiteList
      ? Object.values(demoSchool?.demoStudentWhiteList)
      : [];
    return demoStudentEmails.includes(email) || email === demoTeacherEmail;
  }
}
