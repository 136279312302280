import { WizardContent, WizardContentSchema } from '@xq/domain';

import { FbWizardContentDto, FbWizardContentSchema } from '../dto';
import { Mapper } from './mapper';

export class WizardContentMapper extends Mapper<
  WizardContent,
  FbWizardContentDto
> {
  toDomain(program: FbWizardContentDto): WizardContent {
    return WizardContentSchema.parse({
      ...program,
      createdAt: this.convertTimestamp(program.createdAt)?.toString() ?? null,
    });
  }

  toPersistence(program: WizardContent): FbWizardContentDto {
    return FbWizardContentSchema.parse({
      ...program,
      createdAt: this.transformToDate(program.createdAt),
    });
  }
}
