import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import {
  ModifyPermissionsUseCase,
  PersistFeedCommentsCountUseCase,
  PersistLastPostUseCase,
} from '@xq/usecases';
import { ClassroomRepository } from '@xq/infrastructure';
import { AttachedFile, User } from '@xq/domain';

import { usersActions } from '../users';

const classroomRepo = new ClassroomRepository();

type PersistStudentLastPost = {
  student: User;
};

export const persistLastPost = createAsyncThunk(
  'feed/persistLastPost',
  async ({ student }: PersistStudentLastPost, { dispatch }) => {
    if (isNil(student)) {
      throw new Error('Student is undefined');
    }

    const persistLastPostUseCase = new PersistLastPostUseCase();
    const date = new Date();

    await persistLastPostUseCase.execute(student.uid, date);
    dispatch(
      usersActions.userUpdated({
        id: student.uid,
        changes: {
          lastPost: date.toString(),
        },
      })
    );
  }
);

type PersistActivitySummaryCount = {
  userId: string;
  classroomId: string;
  programSlug: string;
  postsOperator?: '+' | '-';
  commentsOperator?: '+' | '-';
  deletedCount?: number;
};

export const persistActivitySummaryCount = createAsyncThunk(
  'feed/persistActivitySummaryCount',
  async ({
    userId,
    classroomId,
    programSlug,
    commentsOperator,
    postsOperator,
    deletedCount,
  }: PersistActivitySummaryCount) => {
    await classroomRepo.persistActivitySummaryCount({
      userId: userId,
      classroomId: classroomId,
      slug: programSlug,
      commentsOperator,
      postsOperator,
      deletedCount,
    });
  }
);

type PersistFeedCommentsCount = {
  studentId: string;
  classroomId: string;
  operator: '+' | '-';
  deletedCommentsCount?: number;
};

export const persistFeedCommentsCount = createAsyncThunk(
  'feed/persistFeedCommentsCount',
  async ({
    studentId,
    classroomId,
    operator,
    deletedCommentsCount,
  }: PersistFeedCommentsCount) => {
    const persistFeedCommentsCountUseCase =
      new PersistFeedCommentsCountUseCase();

    await persistFeedCommentsCountUseCase.execute(
      studentId,
      classroomId,
      operator,
      deletedCommentsCount
    );
  }
);

type ModifyFilePermissions = {
  emails: string[];
  attachedFiles: AttachedFile[];
  shouldRemove: boolean;
  schoolId?: string;
};

export const modifyFilePermissions = createAsyncThunk(
  'feed/modifyFilePermissions',
  async ({ attachedFiles, emails, shouldRemove, schoolId }: ModifyFilePermissions) => {
    const fileRequests = attachedFiles.map((attachedFile) =>
      ModifyPermissionsUseCase.execute({
        emails,
        shouldRemove,
        fileId: attachedFile.id,
        schoolId,
      })
    );

    await Promise.all(fileRequests);
  }
);
