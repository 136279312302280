import { css, styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

const editorPlaceholder = css`
  position: absolute;
  top: 10px;
  left: 8px;
  color: var(--black);
  opacity: 0.6;
  user-select: none;
  pointer-events: none;
`;

export const StyledOutlinedEditor = styled('div')`
  position: relative;
  border: 1px solid rgba(157, 157, 157, 0.2);

  .editorPlaceholder {
    ${editorPlaceholder}
  }
`;

export const StyledStandardEditor = styled('div')`
  position: relative;
  width: 100%;

  .editorPlaceholder {
    ${editorPlaceholder}
    top: unset;
    bottom: 15px;
    left: 16px;
  }
`;

export const StyledMobileEditor = styled('div')`
  position: relative;
  border-bottom: 1px solid rgba(157, 157, 157, 0.2);

  .editorPlaceholder {
    ${editorPlaceholder}
  }
`;

export const StyledEditorIconButton = styled(IconButton)`
  bottom: 6px;
  right: 0;
  position: absolute;
`;

StyledEditorIconButton.defaultProps = {
  color: 'primary',
};
