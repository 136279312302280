import { createAsyncThunk } from '@reduxjs/toolkit';

import { SchoolRepository, loggerService } from '@xq/infrastructure';

export const fetchSchools = createAsyncThunk(
  'schools/fetchSchools',
  async (email: string) => {
    const schoolRepository = new SchoolRepository();

    try {
      return await schoolRepository.getSchoolsByDomain(email);
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  }
);

export const fetchSchoolById = createAsyncThunk(
  'schools/fetchSchoolsById',
  async (schoolId: string) => {
    const schoolRepository = new SchoolRepository();

    try {
      return await schoolRepository.getSchoolById(schoolId);
    } catch (e) {
      loggerService.error(e);

      throw e;
    }
  }
);
