import { createSelector } from '@reduxjs/toolkit';

import { selectClassroomIntegrationState } from '../index';

export const selectClassroomIntegrationIntegrationWizard = createSelector(
  [selectClassroomIntegrationState],
  (state) => state.showIntegrationWizard
);

export const selectClassroomIntegrationPage = createSelector(
  [selectClassroomIntegrationState],
  (state) => state.page
);

export const selectGoogleClassroomsIsLoading = createSelector(
  [selectClassroomIntegrationState],
  (state) => state.isLoadingGoogleClassrooms
);

export const selectUpdateClassroomIsLoading = createSelector(
  [selectClassroomIntegrationState],
  (state) => state.isLoadingUpdateClassroom
);

export const selectClassroomIntegrationSelectedClassroomIds = createSelector(
  [selectClassroomIntegrationState],
  (state) => state.selectedClassroomIds
);

export const selectClassroomIntegrationSelectedSchoolIdFromList =
  createSelector(
    [selectClassroomIntegrationState],
    (state) => state.selectedSchoolIdFromList
  );

export const selectClassroomIntegrationClassrooms = createSelector(
  [selectClassroomIntegrationState],
  (state) => state.googleClassrooms
);
