import { z } from 'zod';

import { DocumentPropertiesSchema, DocumentSchema } from '@xq/domain';

export const FbFileSchema = DocumentSchema.omit({
  lastModifyingUser: true,
}).extend({
  properties: DocumentPropertiesSchema.partial().optional(),
});

export type FbFileDto = z.infer<typeof FbFileSchema>;
