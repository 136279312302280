import {
  CopyStudentTemplateRequestDto,
  CopyStudentTemplateResponseDto,
} from '@xq/shared/data-access';
import { firebaseService } from '@xq/infrastructure';
import { callWithRetry } from '@xq/shared/utils';

export class CopyStudentTemplateUseCase {
  static async execute(payload: CopyStudentTemplateRequestDto) {
    const copyStudentTemplate = firebaseService.prepareFunctionCaller<
      CopyStudentTemplateRequestDto,
      CopyStudentTemplateResponseDto
    >('copyStudentTemplate');

    return callWithRetry(() => copyStudentTemplate(payload));
  }
}
