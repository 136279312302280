import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
} from '@mui/material';

import {
  RestrictView,
  selectCurrentUserState,
  useAppSelector,
} from '@xq/web-components';
import { Document, getUserRole, MyFilesScopes, User } from '@xq/domain';
import { MarkForReview } from '@xq/web-components/MarkForReview/MarkForReview';

import styles from './student-files-accordion.module.scss';
import { FileForReviewLabel } from '@xq/web-components/FileForReviewLabel/FileForReviewLabel';
import { NoFilesLabel } from '@xq/web-components/NoFilesLabel/NoFilesLabel';

type StudentFilesProps = {
  student: User;
  documents: Document[];
  markForReview: (document: Document, userId: string) => void;
};

export const StudentFilesAccordion = ({
  student,
  documents,
  markForReview,
}: StudentFilesProps) => {
  const { t } = useTranslation();

  const currentUser = useAppSelector(selectCurrentUserState);

  const { isViewer } = getUserRole(currentUser);

  const filesForReview = useMemo(
    () => documents.filter((d) => !!d.properties?.isForReview).length,
    [documents]
  );

  return (
    <>
      <Accordion
        elevation={0}
        classes={{
          expanded: styles.accordionExpanded,
        }}
      >
        <AccordionSummary
          classes={{
            expanded: styles.accordionSummaryExpanded,
            content: styles.summaryContent,
          }}
          sx={{
            pointerEvents: !documents.length ? 'none' : 'auto',
          }}
          expandIcon={documents.length ? <ExpandMoreIcon /> : null}
        >
          <Avatar
            className={styles.studentAvatar}
            src={student.photoUrl ?? ''}
          />
          <div className={styles.studentName}>{student.displayName}</div>
          {!!filesForReview && <FileForReviewLabel count={filesForReview} />}
          {!documents.length && <NoFilesLabel />}
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          {documents.map((document) => (
            <div key={document.id} className={styles.documentRow}>
              <div className={styles.fileImageContainer}>
                {document.iconLink ? (
                  <img
                    className={styles.fileIcon}
                    src={document.iconLink}
                    alt={t('file_thumbnail')}
                  />
                ) : (
                  <DescriptionIcon className={styles.descriptionIcon} />
                )}
              </div>

              <div
                className={styles.fileInfoContainer}
                style={{ opacity: !document.webViewLink ? 0.5 : 1 }}
              >
                <a
                  className={styles.fileName}
                  target="_blank"
                  href={document.webViewLink || undefined}
                  rel="noreferrer"
                  style={{
                    cursor: !document.webViewLink ? 'default' : 'pointer',
                  }}
                >
                  {document.name}
                </a>
                {!document.webViewLink && (
                  <p style={{ margin: 0 }}>{t('not_generated')}</p>
                )}
                {document.webViewLink && (
                  <div className={styles.fileModifiedTime}>
                    {t('updated_on')}{' '}
                    {format(new Date(document.modifiedTime), 'MM/dd/yyyy h:mm')}
                  </div>
                )}
              </div>

              <RestrictView scopes={[MyFilesScopes.CAN_MARK_FOR_REVIEW]}>
                {document.properties?.isForReview && (
                  <MarkForReview
                    isForReview={Boolean(document.properties?.isForReview)}
                    onMarkForReview={() => markForReview(document, student.uid)}
                    isDisabled={isViewer}
                  />
                )}
              </RestrictView>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
      <Divider />
    </>
  );
};
