import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FollowedUpUsersState = Record<string, Record<string, string[]>>;

const initialStateFollowedUpUsersState: FollowedUpUsersState = {};

export const followedUpUsersSlice = createSlice({
  name: 'followed-up-users',
  initialState: initialStateFollowedUpUsersState,
  reducers: {
    setFollowedUpUsers: (
      state,
      {
        payload,
      }: PayloadAction<{
        userId: string;
        followedUpUsers: Record<string, string[]>;
      }>
    ) => {
      state[payload.userId] = {
        ...state[payload.userId],
        ...payload.followedUpUsers,
      };
    },
  },
});

export const followedUpUsersActions = followedUpUsersSlice.actions;
