import { z } from 'zod';
import { MathTagTypes } from '@xq/domain';

export const FbUserSchema = z.object({
  classroomIds: z.array(z.string().min(1)),
  classFeedCommentsCountByClassroomId: z
    .record(z.string(), z.number())
    .optional(),
  createdAt: z.date(),
  currentClassroomId: z.string().optional(),
  currentProgramIdByClassroomId: z.record(z.string(), z.string()).optional(),
  currentSchoolId: z.string().optional(),
  displayName: z.string(),
  email: z.string(),
  feedCommentsCount: z.number().optional(),
  feedTagFilesPerClassroom: z
    .record(
      z.string(),
      z.record(z.string(), z.nativeEnum(MathTagTypes).array())
    )
    .optional(),
  feedTagPostsPerClassroom: z
    .record(
      z.string(),
      z.record(z.string(), z.nativeEnum(MathTagTypes).array())
    )
    .optional(),
  journalTagFilesPerClassroom: z
    .record(
      z.string(),
      z.record(z.string(), z.nativeEnum(MathTagTypes).array())
    )
    .optional(),
  journalTagPostsPerClassroom: z
    .record(
      z.string(),
      z.record(z.string(), z.nativeEnum(MathTagTypes).array())
    )
    .optional(),
  lastPost: z.date().optional(),
  lastActivity: z.date().optional(),
  modifiedAt: z.date(),
  photoUrl: z.string().nullable().optional(),
  uid: z.string(),
});

export type FbUserDto = z.infer<typeof FbUserSchema>;
