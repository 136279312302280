import { useTranslation } from 'react-i18next';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {
  StyledButtonContent,
  StyledPhaseFooter,
  StyledPhaseFooterButton,
} from './PhaseContentFooter.styles';
import { useSubPhase } from '../../context';
import { selectIsPrinting, useAppSelector } from '../../store';

export const PhaseContentFooter = () => {
  const { t } = useTranslation();
  const { hasNext, hasPrevious, onNextSubPhase, onPreviousSubPhase } =
    useSubPhase();

  const isPrinting = useAppSelector(selectIsPrinting);

  if (isPrinting) return null;

  return (
    <StyledPhaseFooter>
      <StyledPhaseFooterButton
        visible={`${hasPrevious}`}
        disabled={!hasPrevious}
        size="small"
        onClick={onPreviousSubPhase}
        startIcon={<NavigateBeforeIcon />}
      >
        <StyledButtonContent visible={`${hasPrevious}`}>
          {t('previous')}
        </StyledButtonContent>
      </StyledPhaseFooterButton>
      <StyledPhaseFooterButton
        visible={`${hasNext}`}
        disabled={!hasNext}
        onClick={onNextSubPhase}
        size="small"
        endIcon={<NavigateNextIcon />}
      >
        <StyledButtonContent visible={`${hasNext}`}>
          {t('next')}
        </StyledButtonContent>
      </StyledPhaseFooterButton>
    </StyledPhaseFooter>
  );
};
