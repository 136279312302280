import { createAsyncThunk } from '@reduxjs/toolkit';

import { loggerService, ProgramRepository } from '@xq/infrastructure';

export const fetchPrograms = createAsyncThunk(
  'program/fetchPrograms',
  async () => {
    const programRepository = new ProgramRepository();

    try {
      return await programRepository.getActivePrograms();
    } catch (e) {
      loggerService.error(e);

      throw e;
    }
  }
);
