import { z } from 'zod';

import { UserTagType } from '@xq/domain';

export const RemoveTagFromResourceSchema = z.object({
  resourceId: z.string(),
  userId: z.string(),
  classroomId: z.string(),
  programId: z.string().optional(),
  tags: z.string().array(),
  tagType: z.nativeEnum(UserTagType),
});

export type RemoveTagFromResource = z.infer<typeof RemoveTagFromResourceSchema>;

export const RemoveTagFromResourcesSchema = z.object({
  resources: RemoveTagFromResourceSchema.array(),
});

export type RemoveTagFromResourcesRequestDto = z.infer<
  typeof RemoveTagFromResourcesSchema
>;
