import { Document } from '@xq/domain';
import { z } from 'zod';

export const CopyStudentTemplateSchema = z.object({
  templateId: z.string(),
  programId: z.string(),
  classroomId: z.string(),
  userEmail: z.string(),
  parentId: z.string(),
  fileName: z.string(),
  categories: z.number().array(),
  schoolId: z.string(),
});

export type CopyStudentTemplateRequestDto = z.infer<
  typeof CopyStudentTemplateSchema
>;

export type CopyStudentTemplateResponseDto = Document | undefined;
