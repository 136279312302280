import { useEffect } from 'react';
import { SelectChangeEvent, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  StyledMenuTitle,
  StyledSelect,
  StyledSelectProgramItem,
} from './ProgramsSelect.styles';
import {
  persistCurrentProgramId,
  selectCurrentUserClassroomPrograms,
  selectCurrentUserProgramId,
  selectCurrentUserState,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { MOBILE_MEDIA_QUERY } from '../utils';

export const ProgramsSelect = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUserState);
  const programs = useAppSelector(selectCurrentUserClassroomPrograms);
  const selectedProgramId = useAppSelector(selectCurrentUserProgramId);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const selectedValue =
    !programs.length || !selectedProgramId ? '' : selectedProgramId;

  const handleChange = (event: SelectChangeEvent<any>) => {
    if (!currentUser.currentClassroomId) {
      return;
    }

    dispatch(
      persistCurrentProgramId({
        userId: currentUser.uid,
        classroomId: currentUser.currentClassroomId,
        programId: event.target.value,
      })
    );
  };

  useEffect(() => {
    const programExists = programs.find((p) => p.id === selectedProgramId);

    if (!programExists && programs.length && currentUser.currentClassroomId) {
      dispatch(
        persistCurrentProgramId({
          userId: currentUser.uid,
          classroomId: currentUser.currentClassroomId,
          programId: programs[0].id,
        })
      );
    }
  }, [selectedProgramId, programs, currentUser.currentClassroomId]);

  if (!programs.length) return null;

  return (
    <StyledSelect
      disableUnderline
      IconComponent={ExpandMoreIcon}
      value={selectedValue}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      onChange={handleChange}
      ismobile={`${isMobile}`}
      id="programs-select"
    >
      {programs.map((p) => (
        <StyledSelectProgramItem value={p.id} key={p.id}>
          <StyledMenuTitle>{p.title}</StyledMenuTitle>
        </StyledSelectProgramItem>
      ))}
    </StyledSelect>
  );
};
