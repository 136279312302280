import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NewSchool {
  name: string;
  state: string;
  city?: string;
  domains?: string[];
  website: string;
  isDirty: boolean;
}

const initialState: NewSchool = {
  domains: [],
  name: '',
  state: '',
  city: '',
  website: '',
  isDirty: false,
};

export const newSchoolSlice = createSlice({
  name: 'new-school',
  initialState: initialState,
  reducers: {
    setNewSchool(state, { payload }: PayloadAction<Partial<NewSchool>>) {
      return { ...state, ...payload, isDirty: true };
    },
    resetNewSchool() {
      return initialState;
    },
  },
});

export const newSchoolActions = newSchoolSlice.actions;
