import { styled } from '@mui/material';

export const FilesLoaderStyles = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  max-width: calc(100% - 40px);

  .file-loader-wrapper {
    margin: 14px 0;
  }

  .label-container {
    color: #808080;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 14px;
  }

  .file-name {
    font-weight: bold;
  }

  .progress-bar {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
