import styled from '@emotion/styled';
import { EmojiOption } from './EmojiOption';

const StyledEmojiColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledEmojiRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledEmojiItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: var(--grey-light);
  }
`;

export function EmojiMenuItem({
  onClick,
  options,
}: {
  onClick: (key: string) => void;
  options: EmojiOption[];
}) {
  return (
    <StyledEmojiColumn>
      <StyledEmojiRow>
        {options.map(({ emoji, key }) => (
          <StyledEmojiItem onClick={() => onClick(key)} key={key}>
            <p style={{ cursor: 'pointer' }}>{emoji}</p>
          </StyledEmojiItem>
        ))}
      </StyledEmojiRow>
    </StyledEmojiColumn>
  );
}
