import { z } from 'zod';

export const GenerateTemplatesToProgramSchema = z.object({
  programId: z.string(),
  classroomId: z.string(),
});

export type GenerateTemplatesToProgramRequestDto = z.infer<
  typeof GenerateTemplatesToProgramSchema
>;
