import { z } from 'zod';

export const FindMathFileBySchoolSchema = z.object({
  driveRootFolderId: z.string(),
  schoolId: z.string(),
});

export type FindMathFileBySchoolRequestDto = z.infer<
  typeof FindMathFileBySchoolSchema
>;
