import { KeyboardEvent, MouseEvent, useState } from 'react';

import { onKeyDownEnter } from '../../utils/onKeyDownEnter';
import { EventTypes } from '../../enums/EventTypes';

export const useActionsMenuState = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openMenu = (
    event: MouseEvent<SVGSVGElement> | KeyboardEvent<SVGSVGElement>
  ) => {
    if (event.type === EventTypes.keyDown) {
      if (onKeyDownEnter(event)) setAnchorEl(event.target as Element);
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return {
    isDialogOpen,
    setIsDialogOpen,
    openMenu,
    closeMenu,
    anchorEl,
  };
};
