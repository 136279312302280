import { useTranslation } from 'react-i18next';
import StudentResourceIcon from '@mui/icons-material/SchoolOutlined';
import TeacherResourceIcon from '@mui/icons-material/Portrait';

import { Audiences } from '@xq/shared/data-access';

import { selectTemplateAudience, useAppSelector } from '../../../store';
import { HeaderTooltip } from '../../../HeaderTooltip/HeaderTooltip';

type TemplateAudienceIconProps = {
  templateId: string;
};

export const TemplateAudienceIcon = ({
  templateId,
}: TemplateAudienceIconProps) => {
  const audience = useAppSelector((state) =>
    selectTemplateAudience(state, templateId)
  );

  const { t } = useTranslation();

  const isTeacherAudience = audience === Audiences.teacher;

  return (
    <HeaderTooltip
      title={
        isTeacherAudience
          ? t('teacher_resource')
          : t('copy_of_student_resource')
      }
      placement="top"
    >
      {isTeacherAudience ? <TeacherResourceIcon /> : <StudentResourceIcon />}
    </HeaderTooltip>
  );
};
