import { StreamClient } from 'getstream';

import { FeedService, StreamFeedService } from '@xq/infrastructure';
import { UpdatePostRequestDto } from '@xq/shared/data-access';

export class EditPostUseCase {
  async execute(postData: UpdatePostRequestDto, streamToken: string) {
    const client = StreamFeedService.getInstance(streamToken).client;
    const feedService = FeedService.getInstance(client as StreamClient);

    await feedService.updatePost(postData);
  }
}
