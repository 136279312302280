import { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { ContentType } from '@xq/domain';

import {
  SubPhaseContextProvider,
  selectFocusedSubPhaseContentIndex,
  selectFocusedSubPhaseIndex,
  selectPhaseContent,
  useAppSelector,
} from '@xq/web-components';
import { TEACHER_APP_ROUTES } from '@xq/web-config';

type StudentContentItemProps = {
  panelIndex: number;
  content: ContentType.StudentContent | ContentType.PhaseContent;
};

export const ContentItem = ({
  panelIndex,
  content,
  children,
}: PropsWithChildren<StudentContentItemProps>) => {
  const { phaseIndex = '0' } = useParams();
  const phaseContent = useAppSelector((state) =>
    selectPhaseContent(state, +phaseIndex)
  );
  const focusedSubPhaseIndex = useAppSelector((state) =>
    selectFocusedSubPhaseIndex(state, phaseIndex, panelIndex.toString())
  );
  const focusedSubPhaseContentIndex = useAppSelector((state) =>
    selectFocusedSubPhaseContentIndex(
      state,
      phaseIndex,
      panelIndex.toString(),
      focusedSubPhaseIndex.toString()
    )
  );

  const phase = phaseContent?.[content];

  if (!phase) {
    return null;
  }

  return (
    <SubPhaseContextProvider
      phasePath={TEACHER_APP_ROUTES.getResourceListView}
      phase={phase}
      tabIndex={panelIndex.toString()}
      subPhaseIndex={focusedSubPhaseIndex.toString()}
      subPhaseContentIndex={focusedSubPhaseContentIndex.toString()}
    >
      {children}
    </SubPhaseContextProvider>
  );
};
