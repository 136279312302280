import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, IconButton, useMediaQuery } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import { cx } from '@emotion/css';

import {
  Document,
  getUserRole,
  isViewableMimeType,
  MathTagTypes,
  MyFilesScopes,
} from '@xq/domain';
import { STUDENT_APP_ROUTES } from '@xq/web-config';

import styles from './document-item.module.scss';
import { Tag } from '../../Tag/Tag';
import { FileSize } from '../../FileSize/FileSize';
import { useModal } from '../../context';
import {
  selectCurrentUserClassroom,
  selectCurrentUserProgram,
  selectCurrentUserSchool,
  selectCurrentUserState,
  selectStudentFolderId,
  useAppSelector,
  enqueueSnackbar,
  useAppDispatch,
  SnackbarVariant,
} from '../../store';
import { MarkForReview } from '../../MarkForReview/MarkForReview';
import { ReactComponent as uploadFileIcon } from '../../images/uploaded-file.svg';
import { RestrictView } from '../../RestrictView/RestrictView';
import { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } from '../../utils';
import { TemplateAudienceIcon } from './TemplateAudienceIcon';
import {
  CopyStudentTemplateUseCase,
  LogAnalyticsEventUseCase,
} from '@xq/usecases';
import { StudentWizardStep } from '../../hooks';
import { loggerService } from '@xq/infrastructure';
import { FilesLoader } from '../../FilesLoader/FilesLoader';

export type DeleteTagProps = {
  document: Document;
  tags: MathTagTypes[];
  tag: MathTagTypes;
};

type DocumentItemProps = {
  document: Document;
  canDeleteTag?: boolean;
  canViewTag?: boolean;
  deleteDocument?: (document: Document) => void;
  onMarkForReview?: (document: Document) => void;
  onDeleteTag?: (props: DeleteTagProps) => void;
  isResourcesTemplatesList?: boolean;
};

export const DocumentItem = ({
  document,
  canDeleteTag = false,
  canViewTag = false,
  deleteDocument = () => ({}),
  onMarkForReview = () => ({}),
  onDeleteTag = () => ({}),
  isResourcesTemplatesList = false,
}: DocumentItemProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { onConfirm, dispatchModal } = useModal();

  const [generatingFile, setGeneratingFile] = useState(false);

  const currentUser = useAppSelector(selectCurrentUserState);

  const currentClassroom = useAppSelector(selectCurrentUserClassroom);

  const currentProgram = useAppSelector(selectCurrentUserProgram);

  const currentSchool = useAppSelector(selectCurrentUserSchool);

  const parentId = useAppSelector((state) =>
    selectStudentFolderId(
      state,
      currentClassroom?.id,
      currentProgram?.id,
      currentUser.email
    )
  );

  const { isStudent, isViewer } = getUserRole(currentUser);

  const deleteTag = async (tag: MathTagTypes) => {
    onDeleteTag({
      tag,
      tags: document.autoTags ?? [],
      document,
    });
  };

  const onDeleteTagHandler = (tag: MathTagTypes) => {
    dispatchModal({ isOpen: true, bodyText: t('delete_mathTag') });
    onConfirm.current = () => deleteTag(tag);
  };

  const isDocumentOwner =
    document.ownerId === currentUser.uid && document.ownedByMe;

  const isStudentDocument = document.ownedByMe && !isStudent;

  const renderDocumentImage = () => {
    return (
      <div className={styles.fileImageContainer}>
        {document.iconLink ? (
          <img
            className={styles.fileIcon}
            src={document.iconLink}
            alt={t('file_thumbnail')}
          />
        ) : (
          <DescriptionIcon className={styles.descriptionIcon} />
        )}
        {(isDocumentOwner || isStudentDocument) && (
          <Icon className={styles.uploadFileIcon} component={uploadFileIcon} />
        )}
      </div>
    );
  };

  const renderMarkForReview = () => {
    if ((!isStudent && !document.properties?.isForReview) || !document.webViewLink) {
      return null;
    }

    return (
      <RestrictView scopes={[MyFilesScopes.CAN_MARK_FOR_REVIEW]}>
        <div
          className={styles.markForReviewContainer}
          id={StudentWizardStep.MY_FILES_MARK_FOR_REVIEW}
        >
          <MarkForReview
            className={styles.markForReviewBtn}
            isForReview={Boolean(document.properties?.isForReview)}
            onMarkForReview={() => onMarkForReview(document)}
            isDisabled={isViewer}
          />
        </div>
      </RestrictView>
    );
  };

  const navigate = useNavigate();

  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  const isTeacherViewingNotGeneratedFile = !isStudent && !document.webViewLink;

  const logViewFileEvent = () =>
    LogAnalyticsEventUseCase.execute({
      number_of_files_viewed: {
        classroom: currentClassroom?.name,
        school: currentSchool?.name,
        users: isStudent ? 'Students' : 'Teachers',
      },
    });

  const renderDocumentAction = () => {
    const isViewableInApp =
      isViewableMimeType(document.mimeType) && document.webViewLink;

    const canViewInApp = isStudent && isViewableInApp && !isMobile && !isTablet;

    if (canViewInApp) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            opacity: isTeacherViewingNotGeneratedFile ? 0.5 : 1,
          }}
        >
          <button
            onClick={() => {
              logViewFileEvent();

              navigate(STUDENT_APP_ROUTES.getDocumentViewer(document.id));
            }}
            style={{
              cursor: isTeacherViewingNotGeneratedFile ? 'default' : 'pointer',
            }}
            className={cx({
              [styles.fileName]: true,
              [styles.fileNameButton]: true,
            })}
          >
            {document.name}
          </button>
          {isTeacherViewingNotGeneratedFile && (
            <p style={{ margin: '0 0 0 12px' }}>{t('not_generated')}</p>
          )}
        </div>
      );
    } else if (!document.webViewLink) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            opacity: isTeacherViewingNotGeneratedFile ? 0.5 : 1,
          }}
        >
          <button
            style={{
              cursor: isTeacherViewingNotGeneratedFile ? 'default' : 'pointer',
            }}
            onClick={async () => {
              if (currentClassroom && currentProgram && parentId) {
                try {
                  setGeneratingFile(true);
                  const response = await CopyStudentTemplateUseCase.execute({
                    templateId: document.id,
                    classroomId: currentClassroom?.id,
                    programId: currentProgram?.id,
                    categories: document.properties?.categories || [],
                    fileName: document.name,
                    userEmail: currentUser.email,
                    parentId,
                    schoolId: currentClassroom?.schoolId,
                  });

                  const link = response.data?.webViewLink;

                  if (!link) return;

                  logViewFileEvent();

                  return window.open(link, '_blank', 'noopener,noreferrer');
                } catch (e) {
                  loggerService.error(e);

                  dispatch(
                    enqueueSnackbar({
                      message: t('generate_student_template_error'),
                      options: {
                        variant: SnackbarVariant.ERROR,
                      },
                    })
                  );
                } finally {
                  setGeneratingFile(false);
                }
              }
            }}
            className={cx({
              [styles.fileName]: true,
              [styles.fileNameButton]: true,
            })}
          >
            {document.name}
          </button>
          {isTeacherViewingNotGeneratedFile && (
            <p style={{ margin: '0 0 0 12px' }}>{t('not_generated')}</p>
          )}
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            opacity: isTeacherViewingNotGeneratedFile ? 0.5 : 1,
          }}
        >
          <a
            className={styles.fileName}
            target="_blank"
            href={document.webViewLink}
            rel="noreferrer"
            onClick={async () => {
              logViewFileEvent();
            }}
            style={{
              cursor: isTeacherViewingNotGeneratedFile ? 'default' : 'pointer',
            }}
          >
            {document.name}
          </a>
          {isTeacherViewingNotGeneratedFile && (
            <p style={{ margin: '0 0 0 12px' }}>{t('not_generated')}</p>
          )}
        </div>
      );
    }
  };

  return (
    <div className={styles.documentRow}>
      {generatingFile ? (
        <FilesLoader files={[document]} loadingLabel={t('generating')} />
      ) : (
        <>
          <div className={styles.fileTitleContainer}>
            {renderDocumentImage()}

            {renderDocumentAction()}

            {isDocumentOwner && (
              <IconButton
                className={styles.deleteButton}
                tabIndex={0}
                onClick={() => deleteDocument(document)}
                onKeyDown={() => deleteDocument(document)}
                data-cy="delete-file-icon"
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}

            {isResourcesTemplatesList && document.properties?.templateId ? (
              <TemplateAudienceIcon
                templateId={document.properties.templateId}
              />
            ) : null}
          </div>

          {/*commented due to requirement to remove auto-tagging and tagging 23.10.2023*/}
          {/*{Boolean(document.autoTags?.length && canViewTag) && (*/}
          {/*  <div className={styles.tagsContainer}>*/}
          {/*    {document.autoTags?.map((tag, index) => (*/}
          {/*      <Tag*/}
          {/*        key={index}*/}
          {/*        className={styles.tag}*/}
          {/*        label={tag}*/}
          {/*        {...(canDeleteTag && {*/}
          {/*          onDelete: () => onDeleteTagHandler(tag),*/}
          {/*        })}*/}
          {/*      />*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*)}*/}

          {!isResourcesTemplatesList ? (
            <div className={styles.fileFooterContainer}>
              <div>
                {isDocumentOwner && `${t('uploaded_file')}, `}
                {isStudentDocument && `${t('uploaded_by_student')}, `}
                {t('size')}: <FileSize size={document.size} />
              </div>
              <div>
                {t('added_on')}:{' '}
                {format(new Date(document.createdTime), 'MM/dd h:mm')}
              </div>
            </div>
          ) : null}

          {renderMarkForReview()}
        </>
      )}
    </div>
  );
};
