import { IconButton, styled } from '@mui/material';

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  margin: 10px;
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0;
`;

export const StyledButtonContainer = styled('div')`
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const StyledTextContent = styled('div')`
  text-align: center;
  padding: 20px;
  color: var(--wizard-guide-body-text-color);
`;

export const StyledContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
