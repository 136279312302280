import { useTranslation } from 'react-i18next';

import { Activity, MathTagTypes, UserRoles } from '@xq/domain';

import { StyledFilesContainer, StyledSingleTag } from './FeedAutoTags.styles';
import { DocumentTags } from './DocumentTags';
import {
  selectCurrentRole,
  selectTaggedPostById,
  useAppSelector,
} from '../store';
import { useModal, useUserFiles } from '../context';
import { FilePreview } from '../FilePreview/FilePreview';

export interface FeedAutoTagsProps {
  activity: Activity;
  showDocumentTags: boolean;
}

export const FeedAutoTags = ({
  activity,
  showDocumentTags,
}: FeedAutoTagsProps) => {
  const { t } = useTranslation();
  const { onConfirm, dispatchModal } = useModal();
  const { deleteMathFromPost } = useUserFiles();

  const currentRole = useAppSelector(selectCurrentRole);

  const canDeleteTag = currentRole !== UserRoles.viewer;

  const postTags = useAppSelector((state) =>
    selectTaggedPostById(state, activity.id)
  );

  const handleDeleteTag = async (tag: MathTagTypes) => {
    await deleteMathFromPost(activity, tag);
  };

  const renderTags = () => {
    if (!showDocumentTags) {
      return null;
    }

    return postTags.map((tag, index) => (
      <StyledSingleTag
        key={index}
        label={tag}
        {...(canDeleteTag && {
          onDelete: () => {
            dispatchModal({
              isOpen: true,
              bodyText: t('delete_math_tag_from_post'),
            });
            onConfirm.current = () => handleDeleteTag(tag as MathTagTypes);
          },
        })}
      />
    ));
  };

  return (
    <>
      <div>{renderTags()}</div>
      <StyledFilesContainer>
        {activity.attachedFiles.map((file) => (
          <FilePreview key={file.id} file={file}>
            {showDocumentTags && (
              <DocumentTags
                activity={activity}
                canDeleteTag={canDeleteTag}
                attachedFile={file}
              />
            )}
          </FilePreview>
        ))}
      </StyledFilesContainer>
    </>
  );
};
