import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { Comment, FeedSlugs, Mention } from '@xq/domain';
import { loggerService } from '@xq/infrastructure';

import { FeedDialog } from '../FeedDialog/FeedDialog';
import { useFeed, useModal } from '../../context';
import { StyledPostOptions } from './ActionsMenu.styles';
import { ActionsMenu } from './ActionsMenu';
import { useActionsMenuState } from './useActionsMenuState';

type CommentActionsMenuProps = {
  activityId: string;
  comment: Comment;
  mentionSuggestions: Mention[];
};

export const CommentActionsMenu = ({
  activityId,
  comment,
  mentionSuggestions,
}: CommentActionsMenuProps) => {
  const { t } = useTranslation();
  const { onConfirm, dispatchModal } = useModal();
  const { feedSlug } = useFeed();
  const { deleteComment, editComment } = useFeed();
  const { isDialogOpen, setIsDialogOpen, closeMenu, openMenu, anchorEl } =
    useActionsMenuState();

  const onEditCommentClick = () => {
    setIsDialogOpen(true);
    closeMenu();
  };

  const onEditItem = async (message: string) => {
    if (message.length) {
      await editComment({
        text: message,
        commentId: comment.id,
        hidden: Boolean(comment.hidden),
      });
      setIsDialogOpen(false);
    }
  };

  const confirmDelete = async () => {
    dispatchModal({ loading: true });

    try {
      await deleteComment({ commentId: comment.id, activityId });
    } catch (e) {
      loggerService.error(e);
    }

    dispatchModal({ isOpen: false, loading: false });
  };

  const onDeleteCommentClick = () => {
    const bodyText =
      feedSlug === FeedSlugs.student
        ? t('delete_comment_confirm_modal_text_journal')
        : t('delete_comment_confirm_modal_text');

    onConfirm.current = confirmDelete;
    dispatchModal({
      isOpen: true,
      bodyText,
    });
    closeMenu();
  };

  return (
    <>
      <ActionsMenu
        closeMenu={closeMenu}
        openMenu={openMenu}
        anchorEl={anchorEl}
        isDialogOpen={isDialogOpen}
      >
        <MenuItem onClick={onEditCommentClick} data-cy="edit-comment">
          <CreateIcon style={{ marginRight: 10 }} fontSize="inherit" />
          <StyledPostOptions>{t('edit_comment')}</StyledPostOptions>
        </MenuItem>
        <MenuItem onClick={onDeleteCommentClick} data-cy="delete-comment">
          <DeleteOutlineIcon style={{ marginRight: 10 }} fontSize="inherit" />
          <StyledPostOptions>{t('delete_comment')}</StyledPostOptions>
        </MenuItem>
      </ActionsMenu>
      <FeedDialog
        editedItem={{
          id: comment.id,
          userId: comment.creatorId,
          text: comment.body,
          createdAt: comment.createdAt,
        }}
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        editItem={onEditItem}
        mentionSuggestions={mentionSuggestions}
        hideFormatTextButtons
      />
    </>
  );
};
