import { User, UserSchema } from '@xq/domain';

import { FbUserDto, FbUserSchema } from '../dto/fb-user.dto';
import { Mapper } from './mapper';

export class UserMapper extends Mapper<User, FbUserDto> {
  toDomain(user: FbUserDto): User {
    return UserSchema.parse({
      ...user,
      photoUrl: user.photoUrl ?? '',
      classFeedCommentsCountByClassroomId:
        user.classFeedCommentsCountByClassroomId ?? {},
      createdAt: this.convertTimestamp(user.createdAt)?.toString() ?? null,
      modifiedAt: this.convertTimestamp(user.modifiedAt)?.toString() ?? null,
      lastPost: user.lastPost
        ? this.convertTimestamp(user.lastPost).toString()
        : null,
      lastActivity: user.lastActivity
        ? this.convertTimestamp(user.lastActivity).toString()
        : null,
      currentSchoolId: user.currentSchoolId ?? null,
      feedCommentsCount: user.feedCommentsCount ?? 0,
      currentClassroomId: user.currentClassroomId ?? null,
      currentProgramIdByClassroomId: user.currentProgramIdByClassroomId ?? {},
      feedTagPostsPerClassroom: user.feedTagPostsPerClassroom ?? null,
      feedTagFilesPerClassroom: user.feedTagFilesPerClassroom ?? null,
      journalTagFilesPerClassroom: user.journalTagFilesPerClassroom ?? null,
      journalTagPostsPerClassroom: user.journalTagPostsPerClassroom ?? null,
      roles: [],
      roleByClassroomId: {},
    });
  }

  toPersistence(user: User): FbUserDto {
    return FbUserSchema.parse({
      ...user,
      createdAt: this.transformToDate(user.createdAt),
      modifiedAt: this.transformToDate(user.modifiedAt),
      currentSchoolId: user.currentSchoolId ?? '',
      currentClassroomId: user.currentClassroomId ?? '',
    });
  }
}
