import { useEffect, useState } from 'react';
import { $rootTextContent } from '@lexical/text';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

export const useIsEditorEmpty = () => {
  const [editor] = useLexicalComposerContext();

  const [isEditorEmpty, setIsEditorEmpty] = useState(true);

  useEffect(
    function checkEditorEmptyState() {
      return editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const textContent = $rootTextContent().trim();
          setIsEditorEmpty(!textContent);
        });
      });
    },
    [editor]
  );

  return {
    isEditorEmpty,
  };
};
