import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchProgramUseCase } from '@xq/usecases';
import { loggerService } from '@xq/infrastructure';

type FetchProgramsPostProps = {
  slug: string;
  isTeacher?: boolean;
};

export const fetchProgramPost = createAsyncThunk(
  'program-post/fetchProgramsPost',
  ({ slug, isTeacher }: FetchProgramsPostProps) => {
    const fetchProgramUseCase = new FetchProgramUseCase();

    try {
      return fetchProgramUseCase.execute(slug, isTeacher);
    } catch (e) {
      loggerService.error(e);

      throw e;
    }
  }
);
