import { Phase } from './Phase';
import { ProgramProgress } from '../ProgramProgress';

export interface ProgramPost {
  slug: string;
  phaseValues?: Phase[];
  studentPhaseValues?: Phase[];
  teacherPhaseValues?: Phase[];
  course?: string;
  summary?: string;
}

export const getActivePhaseIndex = (
  programPost?: ProgramPost,
  userProgramProgress?: ProgramProgress
) => {
  const index =
    programPost?.studentPhaseValues?.findIndex(
      (phase) => userProgramProgress?.currentPhaseId === phase.id
    ) || 0;

  return index >= 0 ? index : 0;
};
