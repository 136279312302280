import { useHash } from 'react-use';
import { StepType } from '@reactour/tour';
import { useMemo } from 'react';
import { WizardContentType } from '@xq/domain';
import { delay } from '@xq/shared/utils';
import { useAppSelector } from '../store';
import { MOBILE_MEDIA_QUERY, MY_FILES_PATH } from '../utils';
import { selectWizardContentByType } from '../store/wizard';
import { useMediaQuery } from '@mui/material';

export enum StudentWizardStep {
  PHASE_CONTENT_PHASES = 'phase-content-phases',
  PHASE_CONTENT_ADDITIONAL_RESOURCES = 'phase-content-additional-resources',
  PHASE_CONTENT_COMPLETE_PHASE = 'phase-content-complete-phase',
  MY_JOURNAL_WRITE_POST = 'my-journal-write-post',
  MY_JOURNAL_MARK_FOR_REVIEW = 'my-journal-mark-for-review',
  MY_FILES_CREATE_FILE = 'my-files-create-file',
  MY_FILES_UPLOAD_FILE = 'my-files-upload-file',
  MY_FILES_MARK_FOR_REVIEW = 'my-files-mark-for-review',
  MOBILE_MAIN_MENU = 'mobile-main-menu',
  MOBILE_COMPLETE_PHASE = 'mobile-complete-phase',
  MOBILE_EXPLORE_PHASES = 'mobile-explore-phases',
  MOBILE_WRITE_POST = 'mobile-write-post',
  MOBILE_ADD_POST_OR_FILE = 'mobile-add-post-or-file',
}

export const STUDENT_WEB_WIZARD_GUIDE_STEPS = [
  StudentWizardStep.PHASE_CONTENT_PHASES,
  StudentWizardStep.PHASE_CONTENT_ADDITIONAL_RESOURCES,
  StudentWizardStep.PHASE_CONTENT_COMPLETE_PHASE,
  StudentWizardStep.MY_JOURNAL_WRITE_POST,
  StudentWizardStep.MY_JOURNAL_MARK_FOR_REVIEW,
  StudentWizardStep.MY_FILES_CREATE_FILE,
  StudentWizardStep.MY_FILES_UPLOAD_FILE,
  StudentWizardStep.MY_FILES_MARK_FOR_REVIEW,
];

export const STUDENT_MOBILE_WIZARD_GUIDE_STEPS = [
  StudentWizardStep.MOBILE_MAIN_MENU,
  StudentWizardStep.MOBILE_EXPLORE_PHASES,
  StudentWizardStep.MOBILE_COMPLETE_PHASE,
  StudentWizardStep.MOBILE_ADD_POST_OR_FILE,
  StudentWizardStep.MOBILE_WRITE_POST,
];

export const useStudentWizardGuide = () => {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const type = isMobile ? WizardContentType.MOBILE : WizardContentType.WEB;

  const wizardContent = useAppSelector((state) =>
    selectWizardContentByType(state, type)
  );

  const STUDENT_WIZARD_GUIDE_STEPS =
    type === WizardContentType.WEB
      ? STUDENT_WEB_WIZARD_GUIDE_STEPS
      : STUDENT_MOBILE_WIZARD_GUIDE_STEPS;

  const steps: StepType[] = useMemo(
    () =>
      wizardContent
        .sort(({ slug: firstSlug }, { slug: secondSlug }) => {
          const firstIndex = STUDENT_WIZARD_GUIDE_STEPS.indexOf(firstSlug);
          const secondIndex = STUDENT_WIZARD_GUIDE_STEPS.indexOf(secondSlug);
          return firstIndex - secondIndex;
        })
        .map(({ positionSelector, body }) => ({
          selector: positionSelector,
          content: body,
        })),
    [type, wizardContent]
  );

  const [hash, updateHash] = useHash();

  const onStepChange = async (stepSlug: StudentWizardStep) => {
    const map = new Map([
      [
        stepSlug === StudentWizardStep.MY_FILES_CREATE_FILE &&
          hash !== MY_FILES_PATH,
        async () => {
          updateHash(MY_FILES_PATH);
          await delay(1000);
        },
      ],
      [
        stepSlug === StudentWizardStep.MY_JOURNAL_MARK_FOR_REVIEW,
        async () => {
          updateHash('');
          await delay(1000);
        },
      ],
    ]);

    for (const [isApplicable, execute] of map) {
      if (isApplicable) await execute();
    }
  };

  return { steps, onStepChange, STUDENT_WIZARD_GUIDE_STEPS } as const;
};
