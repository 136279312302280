import { z } from 'zod';

import { Classroom, ProviderType } from '@xq/domain';

export const CreateClassroomSchema = z.object({
  name: z.string().min(3, 'must_contain_at_least_3_character(s)'),
  schoolId: z.string().min(1),
  shortName: z.string().min(1).max(3, 'can_contain_max_3_characters'),
  teacherEmails: z.array(z.string().min(1)),
  participantEmails: z.array(z.string().min(1)),
  viewerEmails: z.array(z.string().min(1)).optional(),
  providerType: z.nativeEnum(ProviderType),
  programIds: z.array(z.string().min(1)).optional(),
  latestGeneratedTemplatesByProgramId: z
    .record(z.string(), z.array(z.record(z.string(), z.string())))
    .optional(),
});

export type CreateClassroomRequestDto = z.infer<typeof CreateClassroomSchema>;

export type CreateClassroomResponseDto = Classroom | null;
