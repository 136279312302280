import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

export const StyledFooter = styled('footer')`
  padding: 24px;
  background-color: var(--white);
`;

export const StyledNextButton = styled(LoadingButton)`
  margin-left: 24px;
`;

StyledNextButton.defaultProps = {
  type: 'submit',
  color: 'primary',
  variant: 'contained',
};
