import { convertTimestamp } from '@xq/shared/utils';

export abstract class Mapper<DomainEntity, PersistModel> {
  abstract toDomain(raw: PersistModel): DomainEntity;

  abstract toPersistence(entity: DomainEntity): PersistModel;

  transformToDate(date: string | null) {
    return date ? new Date(date) : new Date();
  }

  convertTimestamp<T extends object>(value: T) {
    return convertTimestamp(value);
  }
}
