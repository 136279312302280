export const pause = (duration: number) =>
  new Promise((resolve) => setTimeout(resolve, duration));

export const callWithRetry = async <T>(
  operation: () => Promise<T>,
  retries = 1,
  delayDuration = 1000
): Promise<T> => {
  try {
    return await operation();
  } catch (e) {
    if (retries > 1) {
      throw e;
    }
    await pause(delayDuration);

    return callWithRetry(operation, retries + 1);
  }
};
