import 'swiper/css';
import { useMemo, StrictMode, Suspense } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from '@sentry/react';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import { Provider } from 'react-redux';

import {
  XqTheme,
  ApiProvider,
  store,
  calculateHeight,
  ErrorPage,
  initGoogleAPIs,
  initSentry,
  LoaderLogo,
  SnackbarVariant,
  closeSnackbar,
  NotificationsProvider,
  ModalProvider,
  useAppDispatch,
  NotifierContextProvider,
} from '@xq/web-components';
import { initializeI18n } from '@xq/web-config';

import { AppRouteProvider } from './app/AppRouteProvider';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';

const NX_GOOGLE_CLIENT_ID = process.env.NX_PUBLIC_GOOGLE_CLIENT_ID || '';

initGoogleAPIs();
initSentry(process.env.NX_PUBLIC_SENTRY_DSN_TEACHER || '');
initializeI18n(process.env['NX_PUBLIC_NODE_ENV'] === 'development');

const RootComponent = () => {
  const MaterialTheme = useMemo(() => XqTheme, []);
  const dispatch = useAppDispatch();

  const onCloseSnackbar = (key: string) => {
    dispatch(closeSnackbar(key));
  };

  const closeSnackbarAction = (key: SnackbarKey) => (
    <CloseIcon
      cursor="pointer"
      onClick={() => {
        onCloseSnackbar(key.toString());
      }}
    />
  );

  return (
    <GoogleOAuthProvider clientId={NX_GOOGLE_CLIENT_ID}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={MaterialTheme}>
          <CssBaseline />
          <ApiProvider>
            <NotificationsProvider>
              <ModalProvider>
                <NotifierContextProvider>
                  <SnackbarProvider
                    maxSnack={5}
                    iconVariant={{
                      [SnackbarVariant.SUCCESS]: <SuccessIcon />,
                      [SnackbarVariant.ERROR]: <ErrorIcon />,
                    }}
                    action={closeSnackbarAction}
                    classes={{
                      variantSuccess: 'successSnackbarBody',
                      variantError: 'errorSnackbarBody',
                    }}
                  >
                    <AppRouteProvider>
                      <ErrorBoundary
                        fallback={(e) => (
                          <ErrorPage error={e.error} isTeacherApp={true} />
                        )}
                      />
                    </AppRouteProvider>
                  </SnackbarProvider>
                </NotifierContextProvider>
              </ModalProvider>
            </NotificationsProvider>
          </ApiProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </GoogleOAuthProvider>
  );
};

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

calculateHeight();

root.render(
  <StrictMode>
    <Provider store={store}>
      <Suspense fallback={<LoaderLogo />}>
        <RootComponent />
      </Suspense>
    </Provider>
  </StrictMode>
);
