import { createSelector } from '@reduxjs/toolkit';

import { programPostAdapter } from './index';
import { selectProgramPostState } from '../index';
import { isNil } from 'lodash';

export const {
  selectEntities: selectProgramPostEntities,
  selectById: selectProgramPostById,
  selectAll: selectAllProgramPosts,
} = programPostAdapter.getSelectors(selectProgramPostState);

export const selectProgramPostSelectedSlug = createSelector(
  [selectProgramPostState],
  (state) => state.selectedSlug
);

export const selectProgramPostLoading = createSelector(
  [selectProgramPostState],
  (state) => state.loading
);

export const selectProgramPost = createSelector(
  [selectProgramPostState],
  (state) => state.entities[state.selectedSlug]
);

export const selectProgramPostPhaseIds = createSelector(
  [selectProgramPost],
  (programPost) =>
    programPost?.studentPhaseValues?.map((phase) => phase.id) ?? []
);

export const selectProgramPostBySlug = createSelector(
  [selectProgramPostState, (_, slug) => slug],
  (state, slug) => state.entities[slug]
);

export const selectPhasesContent = createSelector(
  [selectProgramPostState],
  (state) => state.phasesContent
);

export const selectPhaseContent = createSelector(
  [selectProgramPostState, (_, phaseIndex: number) => phaseIndex],
  (state, phaseIndex) => state.phasesContent[phaseIndex]
);

export const selectPhaseTabs = createSelector(
  [selectProgramPostState],
  (state) => state.phaseTabs
);

export const selectPhaseSelections = createSelector(
  [selectProgramPostState],
  (state) => state.phaseSelection
);

export const selectPhaseView = createSelector(
  [selectProgramPostState],
  (state) => state.phaseView
);

export const selectFocusedSubPhaseIndex = createSelector(
  [
    selectPhaseSelections,
    (_, phaseIndex: string) => phaseIndex,
    (_, __, tabIndex: string) => tabIndex,
  ],
  (phaseSelection, phaseIndex, tabIndex) => {
    const selectedSubPhase = Number(
      phaseSelection[phaseIndex]?.tabs[tabIndex]?.selectedSubPhase
    );

    return Number.isNaN(selectedSubPhase) ? -1 : selectedSubPhase;
  }
);

export const selectFocusedSubPhaseContentIndex = createSelector(
  [
    selectPhaseSelections,
    (_, phaseIndex: string) => phaseIndex,
    (_, __, tabIndex: string) => tabIndex,
    (_, __, ___, subPhaseIndex: string) => subPhaseIndex,
  ],
  (phaseSelection, phaseIndex, tabIndex, subPhaseIndex) => {
    const selectedSubPhaseContent = Number(
      phaseSelection[phaseIndex]?.tabs[tabIndex]?.subPhases?.[subPhaseIndex]
    );

    return Number.isNaN(selectedSubPhaseContent) ? -1 : selectedSubPhaseContent;
  }
);

export const selectCurrentTabLabel = createSelector(
  [selectProgramPostState, (_, phaseIndex: string) => phaseIndex],
  (state, phaseIndex) => {
    const phaseSelectedTab = state.phaseSelection[+phaseIndex]?.selectedTab;

    if (isNil(phaseSelectedTab)) return;

    return state.phaseTabs[+phaseSelectedTab]?.label;
  }
);
