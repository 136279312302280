import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { cx } from '@emotion/css';

import {
  selectPhaseContent,
  useAppSelector,
  usePhase,
  decodeEntities,
  ResourceListPanel,
  DocumentList,
  StudentContentPanel,
  PhaseContentPanel,
  ResourceItem,
  selectOrderedTemplatesByProgramAndSchool,
  selectCurrentUserSchoolId,
  selectProgramPostSelectedSlug,
  selectCurrentUserState,
  SelectSchool,
} from '@xq/web-components';

import styles from './phase-content-list.module.scss';
import { ContentItem } from './ContentItem';
import { ContentType, getUserRole } from '@xq/domain';

export const PhaseContentList = () => {
  const { t } = useTranslation();
  const { phaseIndex = '0', tabIndex = '0' } = useParams();
  const { phaseTabs, resourcePath, getSearchParams } = usePhase();
  const [activePanel, setActivePanel] = useState(+tabIndex);

  const phaseContent = useAppSelector((state) =>
    selectPhaseContent(state, +phaseIndex)
  );

  const onPanelClick = (index: number) => {
    if (index !== activePanel) {
      setActivePanel(index);
    }
  };

  const resources: ResourceItem[] =
    phaseContent?.[ContentType.AdditionalResources].map((r) => ({
      title: decodeEntities(r.title),
      link: `${resourcePath(r.slug)}${getSearchParams()}`,
    })) ?? [];

  const currentUser = useAppSelector(selectCurrentUserState);

  const currentSchoolId = useAppSelector(selectCurrentUserSchoolId);

  const selectedSlug = useAppSelector(selectProgramPostSelectedSlug);

  const templates = useAppSelector((state) =>
    selectOrderedTemplatesByProgramAndSchool(
      state,
      selectedSlug,
      currentSchoolId || ''
    )
  );

  const panelMap = {
    [ContentType.PhaseContent]: (index: number) => (
      <ContentItem panelIndex={index} content={ContentType.PhaseContent}>
        <PhaseContentPanel
          panelIndex={index}
          onScroll={() => onPanelClick(index)}
        />
      </ContentItem>
    ),
    [ContentType.StudentContent]: (index: number) => (
      <ContentItem panelIndex={index} content={ContentType.StudentContent}>
        <StudentContentPanel
          panelIndex={index}
          onScroll={() => onPanelClick(index)}
        />
      </ContentItem>
    ),
    [ContentType.AdditionalResources]: (index: number) => (
      <ResourceListPanel
        resources={resources}
        onScroll={() => onPanelClick(index)}
      />
    ),
    [ContentType.TeacherResources]: (index: number) => {
      const { isViewer } = getUserRole(currentUser);

      return (
        <div>
          {isViewer && (
            <div onClick={() => onPanelClick(index)}>
              <SelectSchool />
            </div>
          )}
          <DocumentList
            isResourcesTemplatesList
            documents={templates}
            onScroll={() => onPanelClick(index)}
          />
        </div>
      );
    },
  };

  return (
    <div className={styles.listContainer}>
      {phaseTabs.map((phaseTab, index) => (
        <div
          key={index}
          className={cx(styles.panelContainer, {
            [styles.unActive]: activePanel !== index,
          })}
          onClick={() => onPanelClick(index)}
        >
          <h1 className={`XqTypography-h1 ${styles.panelLabel}`}>
            {t(phaseTab.label)}
          </h1>
          {panelMap[phaseTab.name](index)}
        </div>
      ))}
    </div>
  );
};
