import { z } from 'zod';

export const ImpersonateUserSchema = z.object({
  email: z.string(),
  teacherApp: z.boolean().optional(),
});

export type ImpersonateUserRequestDto = z.infer<typeof ImpersonateUserSchema>;

export type ImpersonateUserResponseDto = {
  idToken: string;
  accessToken: string;
};
