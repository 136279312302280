import { z } from 'zod';

const wpProgramSchema = z.object({
  course_ID: z.string(),
  isActive: z.string().optional(),
  ordering: z.string().optional(),
  duration: z.string().optional(),
  slug: z.string(),
  title: z.string(),
});

export const EditProgramSchema = z.record(wpProgramSchema);

export type EditProgramRequestDto = z.infer<typeof EditProgramSchema>;
export type wpProgramType = z.infer<typeof wpProgramSchema>;
