import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Popper, ClickAwayListener } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

import styles from './link-to-post-tooltip.module.scss';

type LinkToPostTooltipProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  rootElement: HTMLElement | null;
  onTooltipClick: () => void;
};

export const LinkToPostTooltip = ({
  isOpen,
  setIsOpen,
  rootElement,
  onTooltipClick,
}: LinkToPostTooltipProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!isOpen || !rootElement) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        className={styles.tooltip}
        onClick={onTooltipClick}
        open={isOpen}
        anchorEl={rootElement}
        placement="top"
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
          },
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ]}
      >
        <span className={styles.content}>
          <LinkIcon /> <span>{t('link_to_post')}</span>
        </span>
        <span data-popper-arrow className={styles.arrow} />
      </Popper>
    </ClickAwayListener>
  );
};
