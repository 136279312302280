import { firebaseService } from '@xq/infrastructure';
import { GenerateTemplatesToProgramRequestDto } from '@xq/shared/data-access';
import { callWithRetry } from '@xq/shared/utils';

export class GenerateTemplatesToProgramUseCase {
  static async execute({
    programId,
    classroomId,
  }: GenerateTemplatesToProgramRequestDto) {
    const generateTemplatesToProgram = firebaseService.prepareFunctionCaller<
      GenerateTemplatesToProgramRequestDto,
      string
    >('generateTemplatesToProgram');

    const response = await callWithRetry(() =>
      generateTemplatesToProgram({
        classroomId,
        programId,
      })
    );

    return response.data;
  }
}
