import { SvgIcon, styled } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import {
  SvgIconPropsColorOverrides,
  SvgIconPropsSizeOverrides,
} from '@mui/material/SvgIcon/SvgIcon';

const StyledIcon = styled(SvgIcon)`
  width: 50px;
  height: 24.9px;
`;

export type XqIconProps = {
  fontSize: OverridableStringUnion<
    'inherit' | 'large' | 'medium' | 'small',
    SvgIconPropsSizeOverrides
  >;
  color?: OverridableStringUnion<
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    SvgIconPropsColorOverrides
  >;
};

export function XqIconPrimaryColor({ color, fontSize }: XqIconProps) {
  return (
    <StyledIcon fontSize={fontSize} viewBox="0 0 63 39" color={color}>
      <path d="M52.9657 21.1673L58.7963 30.1881C61.1183 27.2415 62.563 23.5711 62.692 19.5648L53.2495 19.5389C53.1979 20.1076 53.1205 20.6504 52.9657 21.1673ZM36.1447 18.7118C36.2995 14.1109 40.0662 10.4147 44.6842 10.4147C49.3281 10.4147 53.1205 14.1368 53.2237 18.7635L62.692 18.7893C62.6146 8.91559 54.5911 0.928711 44.71 0.928711C34.8547 0.928711 26.857 8.83804 26.7022 18.6859L36.1447 18.7118ZM44.71 27.5516C40.1436 27.5516 36.4285 23.9847 36.1705 19.4872L26.7022 19.4614C26.9602 29.2059 34.9063 37.0377 44.71 37.0377C46.7481 37.0377 48.7089 36.6758 50.5406 36.0555L44.8648 27.5516C44.8132 27.5516 44.7616 27.5516 44.71 27.5516ZM15.3248 10.0529L10.6809 2.6088H0.283875L10.1133 18.3758L15.3248 10.0529ZM16.2277 28.172L20.82 35.5385H31.2171L21.4392 19.8491L16.2277 28.172ZM20.949 19.0737L31.2171 2.6088H20.9232L15.7892 10.8025L10.6035 19.1253L3.97315 29.7228L3.94735 29.697L0.283875 35.5385H10.6809L11.4807 34.272L15.4022 28.0169L15.7892 27.3965L20.949 19.0737ZM61.0925 35.2025L58.2546 30.8343L52.6561 22.1237L51.4694 20.2885L43.9102 24.7084L45.7678 27.4741L51.3146 35.7453L52.8883 38.0716H62.95L61.0925 35.2025Z" />
    </StyledIcon>
  );
}
