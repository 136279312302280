import {
  DownloadDriveArchiveRequestDto,
  DownloadDriveArchiveResponseDto,
} from '@xq/shared/data-access';
import { firebaseService } from '@xq/infrastructure';
import { callWithRetry } from '@xq/shared/utils';

export class DownloadDriveArchiveUseCase {
  static async execute(payload: DownloadDriveArchiveRequestDto) {
    const downloadDriveArchive = firebaseService.prepareFunctionCaller<
      DownloadDriveArchiveRequestDto,
      DownloadDriveArchiveResponseDto
    >('downloadDriveArchive');

    return callWithRetry(() => downloadDriveArchive(payload));
  }
}
