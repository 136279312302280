export enum Errors {
  defaultError = 'default_error',
  accountHasNoRooms = 'account_has_no_rooms',
  classroomHasNoFolder = 'classroom_has_no_folder',
  structureInitializationFailed = 'structure_initialization_failed',
  structureAlreadyCreated = 'structure_already_created',
  userWrongRole = 'user_wrong_role',
  noGoogleDriveScope = 'no_google_drive_scope',
  accessTokenError = 'access_token_error',
  dbTokenError = 'db_token_error',
  generateIsAlreadyStarted = 'generate_is_already_started',
}
