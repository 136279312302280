import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarState } from './snackbar.interface';
import { v4 as uuidv4 } from 'uuid';

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: { snackbars: [] as SnackbarState[] },
  reducers: {
    enqueueSnackbar: {
      reducer: (state, { payload }: PayloadAction<SnackbarState>) => {
        state.snackbars.push(payload);
      },
      prepare(snackbar: SnackbarState) {
        const payload = {
          ...snackbar,
          key: uuidv4(),
        };
        return { payload };
      },
    },
    closeSnackbar: {
      reducer: (state, { payload }: PayloadAction<SnackbarState>) => {
        state.snackbars = state.snackbars.map((snackbar) => {
          const shouldDismiss =
            payload.dismissAll || snackbar.key === payload.key;
          return shouldDismiss
            ? { ...snackbar, dismissed: true }
            : { ...snackbar };
        });
      },
      prepare: (key: string) => ({
        payload: { key, dismissAll: !key, message: '' },
      }),
    },
    removeSnackbar: (state, { payload }: PayloadAction<string>) => {
      state.snackbars = state.snackbars.filter(
        (snackbar) => snackbar.key !== payload
      );
    },
  },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } =
  snackbarSlice.actions;
