import {  createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import type { Course } from '@xq/domain';
import { fetchCourses } from './courses-actions';

type loading = boolean;
type CoursesState = Course & { loading: loading };

export const coursesAdapter = createEntityAdapter<CoursesState>({
  selectId: (course) => course.id,
});

export const coursesSlice = createSlice({
  name: 'courses',
  initialState: coursesAdapter.getInitialState({ loading: false }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourses.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchCourses.fulfilled, (state, action) => {
      const courses = Object.keys(action.payload).map(
        (course) => ({
          id: action.payload[course].course_ID,
          title: action.payload[course].course_name,
          description: action.payload[course].course_description,
          duration: action.payload[course].course_duration,
        })
      );
      coursesAdapter.setMany(state, courses as CoursesState[]);
      state.loading = false;
    });
  },
});

export const coursesActions = coursesSlice.actions;
