import { StreamClient, StreamUser } from 'getstream';

import { Activity, User } from '@xq/domain';
import { FeedService, FeedMapper } from '@xq/infrastructure';
import { StreamActivity } from '@xq/shared/data-access';

export class AddActivityUseCase {
  static async execute(
    client: StreamClient,
    activity: Activity,
    streamUser: StreamUser,
    currentUser: User
  ) {
    const feedService = FeedService.getInstance(client);
    const addedActivity = (await feedService.addActivity(
      activity,
      streamUser
    )) as StreamActivity;

    return FeedMapper.toDomainActivity(addedActivity, currentUser);
  }
}
