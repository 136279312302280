import { z } from 'zod';

import { Program } from '@xq/domain';

export const CreateProgramSchema = z.object({
  title: z.string(),
  slug: z.string(),
});

export type CreateProgramRequestDto = z.infer<typeof CreateProgramSchema>;

export type CreateProgramResponseDto = Program | null;
