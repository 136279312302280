import { createSelector } from '@reduxjs/toolkit';
import { partition } from 'lodash';

import {
  getDocumentId,
  orderDocumentsByDate,
  orderDocumentsByTemplateId,
  Document,
} from '@xq/domain';
import { Audiences, WPTemplate } from '@xq/shared/data-access';

import { selectUserDocumentsState, UserDocumentsState } from '../index';
import {
  selectTemplatedIdsByCurrentUser,
  selectWpTemplates,
} from '../templates/templates-selectors';
import { selectProgramPostPhaseIds } from '../program-post/index';

export const sortUserDocuments = (
  state: UserDocumentsState,
  templateIds: string[],
  templates: Record<string, WPTemplate>,
  userId: string,
  classroomId: string,
  programId: string
) => {
  const id = getDocumentId({ userId, programId, classroomId });
  const documents = state[id] ?? [];

  const [templateDocuments, userDocuments] = partition(
    documents,
    (d) => d.properties?.isTemplate
  );

  const orderedTemplates = orderDocumentsByTemplateId(
    templateDocuments.filter((doc) => {
      const template = templates[doc.properties?.templateId ?? ''];

      return !template || template.audience !== Audiences.teacher;
    }),
    templateIds
  );

  const orderedUserDocuments = orderDocumentsByDate(userDocuments);

  return orderedTemplates.concat(orderedUserDocuments);
};

export const selectSortedUserDocuments = createSelector(
  [
    selectUserDocumentsState,
    selectTemplatedIdsByCurrentUser,
    selectWpTemplates,
    (_, userId: string) => userId,
    (_, __, classroomId: string) => classroomId,
    (_, __, ___, programId: string) => programId,
  ],
  (state, templateIds, templates, userId, classroomId, programId) => {
    return sortUserDocuments(
      state,
      templateIds,
      templates,
      userId,
      classroomId,
      programId
    );
  }
);

export type PhaseTypeIdentifier = number | 'other';

export const selectDocumentsByPhase = createSelector(
  [
    selectUserDocumentsState,
    selectProgramPostPhaseIds,
    (_, userIds: string[]) => userIds,
    (_, __, classroomId: string) => classroomId,
    (_, __, ___, programId: string) => programId,
  ],
  (state, phaseIds, userIds, classroomId, programId) => {
    const mapData = (phaseId: PhaseTypeIdentifier) => {
      const filterFn = (doc: Document) => {
        const categories = doc.properties?.categories;

        if (phaseId === 'other') {
          return (
            !categories ||
            categories.length > 1 ||
            !phaseIds.some((id) => categories?.includes(id))
          );
        }

        return (
          categories && categories.length === 1 && categories.includes(phaseId)
        );
      };

      return userIds.reduce((previousValue, userId) => {
        const id = getDocumentId({ userId, programId, classroomId });
        const documents = state[id] ?? [];

        return {
          ...previousValue,
          [userId]: orderDocumentsByDate(
            documents.filter(filterFn),
            'modifiedTime'
          ),
        };
      }, {} as Record<string, Document[]>);
    };

    const phases: PhaseTypeIdentifier[] = [...phaseIds, 'other'];

    return phases.reduce((prevPhase, phaseId) => {
      return {
        ...prevPhase,
        [phaseId]: mapData(phaseId),
      };
    }, {} as Record<string, Record<string, Document[]>>);
  }
);

export const selectUserDocumentById = createSelector(
  [
    selectUserDocumentsState,
    (_, userId: string) => userId,
    (_, __, classroomId: string) => classroomId,
    (_, __, ___, programId: string) => programId,
    (_, __, ___, ____, documentId: string) => documentId,
  ],
  (state, userId, classroomId, programId, documentId) => {
    const id = getDocumentId({ userId, programId, classroomId });
    const documents = state[id] ?? [];

    return documents.find((d) => d.id === documentId);
  }
);
