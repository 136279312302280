import {
  CreateClassroomRequestDto,
  CreateClassroomResponseDto,
} from '@xq/shared/data-access';
import { firebaseService } from '@xq/infrastructure';
import { callWithRetry } from '@xq/shared/utils';

export class CreateClassroomUseCase {
  static execute(payload: CreateClassroomRequestDto) {
    const createClassroom = firebaseService.prepareFunctionCaller<
      CreateClassroomRequestDto,
      CreateClassroomResponseDto
    >('createClassroom');

    return callWithRetry(() => createClassroom(payload));
  }
}
