import { z } from 'zod';

import {
  GenerateTemplateStatusEnum,
  ProviderLastSyncStatus,
  ProviderType,
} from '@xq/domain';

export const FbClassroomSchema = z.object({
  archivedOn: z.date().nullable().optional(),
  classroomFolderId: z.string().optional(),
  chatFolderId: z.string().optional(),
  createdAt: z.date(),
  feedFolderId: z.string().optional(),
  folderIdByProgramId: z.record(z.string(), z.string()).optional(),
  chatFolderIdByStudentId: z.record(z.string(), z.string()).optional(),
  id: z.string(),
  isArchived: z.boolean(),
  modifiedAt: z.date(),
  name: z.string(),
  participantEmails: z.array(z.string().min(1)),
  programIds: z.array(z.string().min(1)),
  providerIdentifier: z.string().nullable(),
  providerLastSyncStatus: z.nativeEnum(ProviderLastSyncStatus),
  providerModifiedAt: z.date().optional(),
  providerType: z.nativeEnum(ProviderType),
  schoolId: z.string(),
  shortName: z.string(),
  teacherEmails: z.array(z.string().min(1)),
  viewerEmails: z.array(z.string().min(1)),
  generateStudentTemplatesStatus: z
    .record(z.string(), GenerateTemplateStatusEnum)
    .optional(),
});

export type FbClassroomDto = z.infer<typeof FbClassroomSchema>;
