import { firebaseService } from '@xq/infrastructure';
import { Errors } from '@xq/domain';
import { callWithRetry } from '@xq/shared/utils';

export class GetAccessTokenUseCase {
  static async execute() {
    try {
      const getAccessToken = firebaseService.prepareFunctionCaller<
        undefined,
        string
      >('getAccessToken');
      const res = await callWithRetry(() => getAccessToken());

      gapi.client.setToken({ access_token: res.data });

      return res;
    } catch (e) {
      throw new Error(Errors.dbTokenError);
    }
  }
}
