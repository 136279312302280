import { Tooltip, TooltipProps } from '@mui/material';

import styles from './header-tooltip.module.scss';

export const HeaderTooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <Tooltip
      classes={{ tooltip: styles.tooltip }}
      placement="bottom-start"
      {...props}
    >
      {children}
    </Tooltip>
  );
};
