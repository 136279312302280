import { useTranslation } from 'react-i18next';
import { useMemo } from "react";
import { format } from 'date-fns';
import { Icon } from '@mui/material';

import {
  getUserRole,
  Calculator,
} from '@xq/domain';

import { ReactComponent as calcIcon } from '../images/desmost-icon.svg';
import {
  selectCurrentUserClassroom,
  selectCurrentUserSchool,
  selectCurrentUserState,
  useAppSelector,
} from '../store';
import { MarkForReview } from '../MarkForReview/MarkForReview';
import { decodeEntities } from '../utils/decodeEntities';
import {
  LogAnalyticsEventUseCase,
} from '@xq/usecases';
import {StyledBottomRow, StyledContainer, StyledDate, StyledRowContent, StyledTiltle} from './CalculatorItem.styles';

type CalculatorItemProps = {
  calculator: Calculator;
  onMarkForReview?: (calculator: Calculator) => void;
  openCalculator: (calculator: Calculator) => Promise<void>;
};

export const CalculatorItem = ({
 calculator,
 onMarkForReview = () => ({}),
 openCalculator
}: CalculatorItemProps) => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUserState);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const currentSchool = useAppSelector(selectCurrentUserSchool);
  const { isStudent, isViewer } = getUserRole(currentUser);

  const decodedTitle = useMemo(
    () => decodeEntities(calculator.title),
    [calculator.title]
  );

  const renderMarkForReview = () => {
    if (!isStudent) {
      return null;
    }

    return (
      <MarkForReview
        isForReview={Boolean(calculator?.forReview)}
        onMarkForReview={() => onMarkForReview(calculator)}
        isDisabled={isViewer}
      />
    );
  };

  // TODO analytics for calculator
  const logViewFileEvent = () =>
    LogAnalyticsEventUseCase.execute({
      number_of_files_viewed: {
        classroom: currentClassroom?.name,
        school: currentSchool?.name,
        users: isStudent ? 'Students' : 'Teachers',
      },
    });

  return (
    <StyledContainer>
      <StyledRowContent onClick={() => openCalculator(calculator)}>
        <Icon component={calcIcon} />
        <StyledTiltle>{decodedTitle}</StyledTiltle>
      </StyledRowContent>
      <StyledBottomRow>
        {renderMarkForReview()}
        <StyledDate>
          {'Last edited on ' + format(new Date(calculator.updatedAt), 'MM/dd/yyyy, H:mm')}
        </StyledDate>
      </StyledBottomRow>
    </StyledContainer>
  );
};
