import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Document, getDocumentId } from '@xq/domain';

export type UserDocumentsState = Record<string, Document[]>;

const initialUserDocumentsState: UserDocumentsState = {};

export const userDocumentsSlice = createSlice({
  name: 'user-documents',
  initialState: initialUserDocumentsState,
  reducers: {
    setDocuments: (
      state,
      {
        payload: { userId, programId, classroomId, documents },
      }: PayloadAction<{
        userId: string;
        classroomId: string;
        programId: string;
        documents: Document[];
      }>
    ) => {
      const id = getDocumentId({ userId, programId, classroomId });
      state[id] = documents.filter((d) => d.id);
    },
  },
});

export const userDocumentsActions = userDocumentsSlice.actions;
