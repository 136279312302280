import { FormikProps } from 'formik';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

type FormInputFieldProps<T> = {
  fieldName: string;
  formik: FormikProps<T>;
} & TextFieldProps;

export const FormInputField = <T extends Record<string, unknown>>({
  fieldName,
  label,
  formik,
  select,
  children,
  helperText,
  sx,
  className,
  required,
  disabled,
  multiline,
  inputProps,
  onBlur,
  onChange,
  variant,
  placeholder,
  minRows,
  type,
}: PropsWithChildren<FormInputFieldProps<T>>) => {
  const { t } = useTranslation();

  return (
    <TextField
      id={fieldName}
      label={label}
      name={fieldName}
      select={select}
      sx={sx}
      value={formik?.values?.[fieldName]}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        } else {
          formik.handleChange(e);
        }
      }}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        } else {
          formik.handleBlur(e);
        }
      }}
      error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
      helperText={
        (formik.touched[fieldName] &&
          !formik.isValidating &&
          formik.errors[fieldName] &&
          t(`${formik.errors[fieldName]}` as any)) ||
        helperText
      }
      className={className}
      required={required}
      disabled={disabled}
      multiline={multiline}
      inputProps={inputProps}
      variant={variant}
      placeholder={placeholder}
      minRows={minRows}
      type={type}
    >
      {children}
    </TextField>
  );
};
