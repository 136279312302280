import { Suspense, useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cond } from 'lodash';

import {
  Banner,
  useAppSelector,
  selectCurrentUserState,
  useSnackbarNotifier,
  useAppDispatch,
  Modal,
  AdministrationTabs,
  appActions,
  selectIsImpersonating,
  selectVisibleBanners,
  fetchWordPressTemplates,
  selectIsFullscreen,
  logAnalyticsEventOnTitleChange,
  selectCurrentUserClassroom,
  selectCurrentUserSchool,
  LoaderLogo,
} from '@xq/web-components';
import { getUserRole } from '@xq/domain';
import { TEACHER_APP_ROUTES } from '@xq/web-config';

import { StyledAppContainer, StyledGrid } from './App.styles';
import { SideBar } from './components/SideBar/SideBar';

const VIEWER_BANNER_COLOR = 'var(--mamba)';

const SIDE_BAR_PIXELS_WIDTH = '96';

const App = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUserState);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const currentSchool = useAppSelector(selectCurrentUserSchool);
  const { isAdmin, isTeacher, isViewer } = getUserRole(currentUser);
  const isAdminOrTeacher = isAdmin || isTeacher;

  const visibleBanners = useAppSelector(selectVisibleBanners);
  const isImpersonating = useAppSelector(selectIsImpersonating);
  const isFullscreen = useAppSelector(selectIsFullscreen);

  useSnackbarNotifier();

  const appHeight = useMemo(() => {
    const defaultHeight = '(var(--vh) * 100)';
    const singleBannerHeight = 42;
    const visibleBannersCount =
      Object.values(visibleBanners).filter(Boolean).length;
    const bannerHeight = !visibleBannersCount
      ? '0px'
      : `${visibleBannersCount * singleBannerHeight}px`;
    return `calc(${defaultHeight} - (${bannerHeight}))`;
  }, [visibleBanners]);

  const checkUserData = async () => {
    const isRoot = location.pathname === TEACHER_APP_ROUTES.getRoot();

    if (!isRoot) return;

    const hasRooms = Boolean(currentUser.classroomIds?.length);

    const shouldRouteToClassroomSetup = isAdminOrTeacher && !hasRooms;

    const shouldRouteToFirstClassroom =
      (isAdminOrTeacher || isViewer) && hasRooms;

    const routeRedirects = cond([
      [
        () => {
          const createdAt =
            format(new Date(currentUser.createdAt as string), 'MM/dd/yyyy h:mm');
          const modifiedAt =
            format(new Date(currentUser.modifiedAt as string), 'MM/dd/yyyy h:mm');
          return createdAt === modifiedAt;
        },
        () => {
          navigate(TEACHER_APP_ROUTES.getResources());
        },
      ],
      [
        () => shouldRouteToClassroomSetup,
        () => {
          navigate(
            TEACHER_APP_ROUTES.getAdministration(AdministrationTabs.classrooms)
          );
        },
      ],
      [
        () => shouldRouteToFirstClassroom,
        () => {
          navigate(
            TEACHER_APP_ROUTES.getDashboard(currentUser?.classroomIds[0])
          );
        },
      ],
    ]);
    routeRedirects();
  };

  useEffect(() => {
    dispatch(appActions.setBannersVisibility({ viewer: isViewer }));
    if (isAdminOrTeacher || isViewer) {
      checkUserData();
    }
  }, [isViewer, isAdminOrTeacher]);

  useEffect(() => {
    dispatch(appActions.setXqApp('teacher'));
    const promise = dispatch(fetchWordPressTemplates());
    return () => promise.abort();
  }, []);

  useEffect(() => {
    if (!currentClassroom || !currentSchool) return;

    logAnalyticsEventOnTitleChange({
      classroomName: currentClassroom.name,
      schoolName: currentSchool.name,
    });
  }, [currentClassroom, currentSchool]);

  if (location.pathname === TEACHER_APP_ROUTES.getRoot()) {
    return <LoaderLogo />
  }

  return (
    <StyledAppContainer>
      <Modal />
      <Banner
        text={t('viewer_banner_message')}
        onClose={() => {
          dispatch(
            appActions.setBannersVisibility({
              viewer: false,
            })
          );
        }}
        backgroundColor={VIEWER_BANNER_COLOR}
        isHidden={!(currentUser && isViewer) || !visibleBanners.viewer}
      />
      <Banner
        text={t('demo_user_banner_message', {
          demoUserName: currentUser.displayName,
        })}
        onClose={() => {
          dispatch(
            appActions.setBannersVisibility({
              demo: false,
            })
          );
        }}
        backgroundColor={VIEWER_BANNER_COLOR}
        isHidden={!isImpersonating || !visibleBanners.demo}
      />
      <SideBar maxHeight={appHeight} photoUrl={currentUser?.photoUrl || ''} />
      <StyledGrid
        height={appHeight}
        width={`calc(100% - ${isFullscreen ? '0' : SIDE_BAR_PIXELS_WIDTH}px)`}
        container
      >
        <Suspense fallback={<LoaderLogo />}>
          <Outlet />
        </Suspense>
      </StyledGrid>
    </StyledAppContainer>
  );
};

export default App;
