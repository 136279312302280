import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@mui/material';

import { usePhase } from '../context';
import { useTour } from '@reactour/tour';
import { PhaseTab } from '../store';
import { ContentType } from '@xq/domain';
import { useMemo } from 'react';

type PhaseTabsProps = {
  activeTabIndex: number | boolean;
  handleTabChange: (index: number) => void;
};

export const PhaseTabs = ({
  activeTabIndex,
  handleTabChange,
}: PhaseTabsProps) => {
  const { t } = useTranslation();
  const { phaseTabs } = usePhase();

  const { isOpen } = useTour();

  const tabs: PhaseTab[] = useMemo(
    () =>
      isOpen && !phaseTabs.find((t) => t.label === 'additional-resources')
        ? [
            ...phaseTabs,
            {
              label: 'additional-resources',
              name: ContentType.AdditionalResources,
            },
          ]
        : phaseTabs,
    [isOpen, phaseTabs]
  );

  return (
    <Tabs
      sx={{
        padding: '0 16px',
      }}
      value={activeTabIndex}
      onChange={(_, index) => handleTabChange(index)}
      indicatorColor="primary"
      textColor="primary"
      variant="standard"
    >
      {tabs.map((tab, index) => {
        return (
          <Tab
            sx={{
              textTransform: 'none',
            }}
            key={index}
            id={`phase-content-${tab.label}`}
            label={t(tab.label)}
            value={index}
          />
        );
      })}
    </Tabs>
  );
};
