import { createSelector } from '@reduxjs/toolkit';

import { commentAdapter } from './index';
import { selectCommentState } from '../index';

export const {
  selectAll: selectAllComments,
  selectEntities: selectCommentEntities,
  selectById: selectCommentById,
} = commentAdapter.getSelectors(selectCommentState);

export const selectCommentsByActivityId = createSelector(
  [selectAllComments, (_, activityId: string) => activityId],
  (comments, activityId) => {
    return comments.filter((c) => c.activityId === activityId);
  }
);
