import { useCallback, useState } from 'react';

import { loggerService } from '@xq/infrastructure';

import { enqueueSnackbar, SnackbarVariant, useAppDispatch } from '../store';

export type Status = 'loading' | 'idle' | 'success' | 'error';

type UseCallProps<TProps, TResult> = {
  successMessage: string;
  errorMessage: string;
  callback: (props: TProps) => Promise<TResult>;
  autoHideDuration?: number | null;
};

export const useToaster = <TProps, TResult = unknown>({
  successMessage,
  errorMessage,
  callback,
  autoHideDuration,
}: UseCallProps<TProps, TResult>) => {
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState<Status>('idle');

  const execute = useCallback(
    async (props: TProps) => {
      setStatus('loading');

      try {
        const result = await callback(props);

        dispatch(
          enqueueSnackbar({
            message: successMessage,
            options: {
              autoHideDuration: autoHideDuration || 10000,
            },
          })
        );

        setStatus('success');

        return result;
      } catch (e) {
        loggerService.error(e);

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              variant: SnackbarVariant.ERROR,
              autoHideDuration: autoHideDuration || 10000,
            },
          })
        );

        setStatus('error');
      }
    },
    [callback, dispatch, errorMessage, successMessage]
  );

  return {
    status,
    execute,
  };
};
