import {
  ChangeEvent,
  forwardRef,
  SyntheticEvent,
  KeyboardEvent,
  useMemo,
} from 'react';

import {
  isSubPhaseComplete,
  isSubPhaseContentComplete,
  SubPhase,
  SubPhaseContent,
  isPhaseComplete,
  ProgramProgress,
} from '@xq/domain';

import styles from './student-phase-accordion.module.scss';
import { XqAccordion } from '../../XqAccordion/XqAccordion';
import { XqAccordionContent } from '../../XqAccordion/XqAccordionContent';
import { StudentLeftHeaderContent } from './StudentLeftHeaderContent';
import { StudentAccordionFooter } from './StudentAccordionFooter';
import {
  selectIsPrinting,
  useAppSelector,
  selectProgramPost,
} from '../../store';
import { usePhase, useSubPhase } from '../../context';
import { StudentWizardStep } from '../../hooks';

export enum CheckableProgramContent {
  SUB_PHASE = 'subPhase',
  SUB_PHASE_CONTENT = 'subPhaseContent',
}

interface StudentPhaseAccordionProps {
  subPhase: SubPhase;
  programProgress: ProgramProgress;
  label: string;
  opened: boolean;
  onAccordionToggle(event: SyntheticEvent, isOpened: boolean): void;
  handleSubPhaseCheck(
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>,
    subPhaseContent?: SubPhaseContent
  ): void;
  isSubAccordionOpened: (index: number) => boolean;
}

export const StudentPhaseAccordion = forwardRef<
  HTMLDivElement,
  StudentPhaseAccordionProps
>(
  (
    {
      handleSubPhaseCheck,
      label,
      onAccordionToggle,
      opened,
      subPhase,
      programProgress,
      isSubAccordionOpened,
    },
    ref
  ) => {
    const { phase } = useSubPhase();

    const programPost = useAppSelector(selectProgramPost);

    const isCurrentSubPhase = programProgress.currentPhaseId === phase.id;

    const isPhaseCompleted = isPhaseComplete(
      programPost?.studentPhaseValues || [],
      phase?.id || 0,
      programProgress
    );

    const isSubPhaseDone = useMemo(
      () =>
        isSubPhaseComplete(
          subPhase.phaseId,
          subPhase.id,
          programProgress.phases
        ) ?? false,
      [subPhase, programProgress.phases]
    );

    const isSubPhaseContentDone = (subPhaseContent?: SubPhaseContent) =>
      !!subPhaseContent &&
      isSubPhaseContentComplete(
        subPhase.phaseId,
        subPhase.id,
        subPhaseContent.id,
        programProgress.phases
      );

    const isPreviousPhase = programProgress.currentPhaseId
      ? Boolean(programProgress.currentPhaseId >= phase.id)
      : false;

    const isDisabled =
      (!isCurrentSubPhase && !isPhaseCompleted) ||
      (isSubPhaseDone && !isCurrentSubPhase) ||
      !isPreviousPhase;

    const isPrinting = useAppSelector(selectIsPrinting);

    const { hasExpandedSubphase } = usePhase();

    if (isPrinting && !opened && hasExpandedSubphase) return null;

    return (
      <div ref={ref} id={StudentWizardStep.PHASE_CONTENT_COMPLETE_PHASE}>
        <XqAccordion
          opened={subPhase.isStickyPost || opened}
          disabled={subPhase.isStickyPost}
          onAccordionToggle={onAccordionToggle}
          label={label}
          left={
            <StudentLeftHeaderContent
              value={isSubPhaseDone}
              isDisabled={isDisabled}
              handleClick={(e) => {
                handleSubPhaseCheck(e);
              }}
              checkable={CheckableProgramContent.SUB_PHASE}
            />
          }
        >
          <XqAccordionContent
            classes={{ root: styles.accordionRoot }}
            subPhase={subPhase}
            footer={(subPhaseContent?: SubPhaseContent) => {
              const isChecked = subPhaseContent
                ? isSubPhaseContentDone(subPhaseContent)
                : isSubPhaseDone;

              return (
                <StudentAccordionFooter
                  handleClick={(e) => {
                    handleSubPhaseCheck(e, subPhaseContent);
                  }}
                  isDisabled={isDisabled}
                  isChecked={isChecked}
                  checkable={CheckableProgramContent.SUB_PHASE_CONTENT}
                />
              );
            }}
            left={(subPhaseContent?: SubPhaseContent) => (
              <StudentLeftHeaderContent
                value={
                  subPhaseContent
                    ? isSubPhaseContentDone(subPhaseContent)
                    : false
                }
                isDisabled={isDisabled}
                handleClick={(e) => {
                  handleSubPhaseCheck(e, subPhaseContent);
                }}
                checkable={CheckableProgramContent.SUB_PHASE_CONTENT}
              />
            )}
            isSubAccordionOpened={isSubAccordionOpened}
          />
        </XqAccordion>
      </div>
    );
  }
);
