import { LoadingStateService } from './LoadingStateService';

export class MyFeedLoadingStateService extends LoadingStateService {
  protected static instance: LoadingStateService;

  static getInstance() {
    if (!MyFeedLoadingStateService.instance) {
      MyFeedLoadingStateService.instance = new MyFeedLoadingStateService();
    }

    return MyFeedLoadingStateService.instance;
  }
}
