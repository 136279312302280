import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import {
  FeedSlugs,
  Document,
  JournalFeedScopes,
  Mention,
} from '@xq/domain';
import { DocumentObject, loggerService } from '@xq/infrastructure';

import styles from './feed-input.module.scss';
import { MarkForReview } from '../../../../MarkForReview/MarkForReview';
import { FileListPreview } from '../../../../FileListPreview/FileListPreview';
import { FileUploadButtons } from './FileUploadButtons';
import { RestrictView } from '../../../../RestrictView/RestrictView';
import { useFeed } from '../../../../context';
import {
  TextEditor,
  StyledOutlinedEditor,
  ToolbarPlugin,
  outlinedTextEditorConfig,
} from '../../../../TextEditor';
import { PostActivityButton } from '../PostActivityButton/PostActivityButton';
import {
  useFileSelect,
  useGoogleScopesChecker,
  useMentionSuggestions,
} from '../../../../hooks';
import {
  StyledClearJournalLinkBtn,
  StyledJournalLink,
} from '../../../../Layout';
import {
  selectUserById,
  selectCurrentUserClassroom,
  useAppSelector,
  selectCurrentUserState,
  selectFeedJournalLink,
  useAppDispatch,
  feedActions,
} from '../../../../store';
import { StudentWizardStep } from '../../../../hooks';

export type FeedInputProps = {
  placeholder?: string;
};

export const FeedInput = ({ placeholder }: FeedInputProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { feedSlug, createPost } = useFeed();
  const mentionSuggestions = useMentionSuggestions(feedSlug);
  const {
    removeFile,
    uploadFiles,
    selectedFiles,
    addFiles,
    addFilesFromPicker,
    loadingFiles,
  } = useFileSelect();

  const { studentId = '' } = useParams();

  const currentUser = useAppSelector(selectCurrentUserState);
  const journalLink = useAppSelector(selectFeedJournalLink);

  const currentStudent = useAppSelector((state) =>
    selectUserById(state, studentId || currentUser.uid)
  );

  const currentClassroom = useAppSelector(selectCurrentUserClassroom);

  const [loading, setLoading] = useState(false);
  const [isForReview, setIsForReview] = useState(false);

  const { requestScopes, loading: loadingScopesChecker } =
    useGoogleScopesChecker();

  const isAttachButtonDisabled = selectedFiles.length >= 10;

  const clearJournalLink = () => {
    dispatch(feedActions.updateJournalLink(null));
  };
  const addActivity = useCallback(
    async (message: string, mentions: Mention[], documents: Document[]) => {
      try {
        setLoading(true);
        await createPost({
          isForReview,
          mentions,
          message,
          selectedFiles: documents,
          journalLink,
        });

        setIsForReview(false);
        clearJournalLink();
      } catch (e) {
        loggerService.error(e);
        throw new Error(`Error while adding activity: ${e}`);
      } finally {
        setLoading(false);
      }
    },
    [createPost, isForReview, journalLink]
  );

  const handlePostActivity = useCallback(
    async (message: string, mentions: Mention[]) => {
      const documents = selectedFiles.filter((f) => f.isFromDrive);
      const uploadedFiles = await uploadFiles();

      await addActivity(message, mentions, documents.concat(uploadedFiles));
    },
    [addActivity, selectedFiles, uploadFiles]
  );

  const onSelectFilesFromPicker = (files: DocumentObject[]) => {
    const currentProgramId = currentClassroom
      ? currentStudent?.currentProgramIdByClassroomId?.[currentClassroom.id]
      : '';

    const destinationId =
      feedSlug === FeedSlugs.student && currentProgramId
        ? currentClassroom?.studentFolderIdByEmailByProgramId?.[
            currentStudent?.email || ''
          ]?.folderByProgramId?.[currentProgramId]
        : currentClassroom?.feedFolderId;

    return addFilesFromPicker(files, destinationId);
  };

  return (
    <div id={StudentWizardStep.MY_JOURNAL_WRITE_POST}>
      <RestrictView
        scopes={[JournalFeedScopes.CAN_POST]}
      >
        <LexicalComposer initialConfig={outlinedTextEditorConfig}>
          <StyledOutlinedEditor data-cy="input-post">
            {journalLink && (
              <StyledJournalLink>
                <StyledClearJournalLinkBtn onClick={clearJournalLink}>
                  <ClearRoundedIcon fontSize="small" />
                </StyledClearJournalLinkBtn>
                {journalLink.text}
              </StyledJournalLink>
            )}
            <div style={{ position: 'relative' }}>
              <TextEditor
                mentionSuggestions={mentionSuggestions}
                placeholder={placeholder ?? t('create_post')}
                CustomPlugin={
                  <ToolbarPlugin>
                    <div className={styles.customPluginContainer}>
                      <FileUploadButtons
                        addFiles={addFiles}
                        addFilesFromPicker={onSelectFilesFromPicker}
                        isAttachButtonDisabled={isAttachButtonDisabled}
                        loading={loadingScopesChecker}
                        requestScopes={requestScopes}
                      />
                      <PostActivityButton
                        onClick={handlePostActivity}
                        loading={loading}
                        label={t('post')}
                        mentionSuggestions={mentionSuggestions}
                        disabled={
                          selectedFiles.length > 10 || loadingScopesChecker
                        }
                      />
                    </div>
                  </ToolbarPlugin>
                }
              />
            </div>
            {feedSlug === FeedSlugs.student && (
              <div
                id={StudentWizardStep.MY_JOURNAL_MARK_FOR_REVIEW}
                className={styles.markForReviewContainer}
              >
                <MarkForReview
                  isForReview={isForReview}
                  onMarkForReview={() =>
                    setIsForReview((prevState) => !prevState)
                  }
                />
              </div>
            )}
          </StyledOutlinedEditor>
        </LexicalComposer>
      </RestrictView>

      <FileListPreview
        uploadedFiles={selectedFiles}
        handleDeleteFile={removeFile}
        uploadingFiles={loadingFiles}
      />
    </div>
  );
};
