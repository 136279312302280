import { User } from '@xq/domain';

const createdAt = new Date().toISOString();
const modifiedAt = new Date().toISOString();

export const admins: Partial<User>[] = [
  {
    createdAt,
    modifiedAt,
    classroomIds: [],
    displayName: '',
    email: 'e2e_admin_test@xqlabsdev.io',
    uid: '',
    photoUrl:
      'https://lh3.googleusercontent.com/a/AATXAJyaU5cEL_0LJL7GZSDg33W-R0KEb2O9ZEi8H3XG=s96-c',
  },
  {
    createdAt,
    modifiedAt,
    classroomIds: [],
    displayName: '',
    email: 'test.admin@xqlabs.io',
    uid: '',
    photoUrl:
      'https://lh3.googleusercontent.com/a/AATXAJyaU5cEL_0LJL7GZSDg33W-R0KEb2O9ZEi8H3XG=s96-c',
  },
];

const testAdminEmails = [
  'e2e_admin_test@xqlabsdev.io',
  'test.admin@xqlabs.io',
] as const;

export type TestAdminEmail = (typeof testAdminEmails)[number];

export const TEST_ADMIN_EMAILS = testAdminEmails.reduce(
  (acc, email) => ({
    ...acc,
    [email]: email,
  }),
  {} as Record<TestAdminEmail, TestAdminEmail>
);
