import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { cond, countBy, stubTrue } from 'lodash';

import {
  Activity,
  AttachedFile,
  Comment,
  Document,
  FeedSlugs,
  getJournalActivityId,
  getJournalId,
  getUserRole,
  Mention,
} from '@xq/domain';
import { FeedMapper, loggerService } from '@xq/infrastructure';
import {
  GetActivityCommentsUseCase,
  GetActivityReactionsUseCase,
  HandleJournalSubscriptionUseCase,
  HandleNewActivityProps,
  LogAnalyticsEventUseCase,
  ReactWithEmojiUseCase,
  RemoveEmojiReactionUseCase,
} from '@xq/usecases';

import { useApi } from '../../ApiProvider/ApiProvider';
import {
  activityActions,
  createComment,
  deleteComment as deleteCommentAction,
  deleteJournalActivity,
  editActivity,
  feedActions,
  fetchActivities as fetchActivitiesAction,
  fetchActivityById,
  fetchComments,
  hideActivity,
  markForReview,
  modifyFilePermissions,
  persistActivitySummaryCount,
  persistFeedCommentsCount,
  persistLastPost,
  postActivity,
  selectCurrentUserClassroom,
  selectCurrentUserProgram,
  selectCurrentUserState,
  selectFeedSelectableElementId,
  selectUsersEntities,
  updateComment,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { useNotifier } from '../notifier-context/notifier-context';
import {
  AddCommentProps,
  DeleteCommentProps,
  EditCommentProps,
  FeedProviderProps,
  FeedState,
  HandleAddActivityProps,
  SendNotificationProps,
} from './feed-context.interface';
import { StreamActivity } from '@xq/shared/data-access';

const FeedContext = createContext<FeedState | undefined>(undefined);

export const FeedProvider = ({
  children,
  feedSlug,
}: PropsWithChildren<FeedProviderProps>) => {
  const { feedClient, streamToken } = useApi();
  const { studentId } = useParams();
  const dispatch = useAppDispatch();
  const { notifyJournalFeed, notifyForReview } = useNotifier();

  const currentUser = useAppSelector(selectCurrentUserState);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const userEntities = useAppSelector(selectUsersEntities);
  const selectableElementId = useAppSelector(selectFeedSelectableElementId);

  const { isStudent } = getUserRole(currentUser);

  const feedId = useMemo(() => {
    const getFeedId = cond([
      [
        (feedSlug: FeedSlugs) => feedSlug === FeedSlugs.student,
        () =>
          currentClassroom?.id && currentProgram?.id
            ? getJournalId({
                userId: studentId || currentUser.uid,
                classroomId: currentClassroom.id,
                programId: currentProgram.id,
              })
            : '',
      ],
      [
        (feedSlug: FeedSlugs) => feedSlug === FeedSlugs.phaseJournal,
        () =>
          currentClassroom?.id && currentProgram?.id && selectableElementId
            ? getJournalActivityId({
                classroomId: currentClassroom?.id,
                programId: currentProgram?.id,
                journalActivityId: selectableElementId,
              })
            : '',
      ],
      [stubTrue, () => ''],
    ]);

    return getFeedId(feedSlug);
  }, [
    currentProgram?.id,
    currentClassroom?.id,
    studentId,
    currentUser.uid,
    feedSlug,
    selectableElementId,
  ]);

  const fetchActivities = useCallback(() => {
    const { abort } = dispatch(
      fetchActivitiesAction({
        feedId,
        streamToken,
      })
    );

    return { abort };
  }, [feedId, streamToken, dispatch]);

  const fetchActivity = useCallback(
    (activityId: string) => {
      const { abort } = dispatch(
        fetchActivityById({
          activityId,
          streamToken,
        })
      );

      return { abort };
    },
    [dispatch, streamToken]
  );

  const activitiesCallback = useCallback(
    ({ data }: HandleNewActivityProps) => {
      const { new: newData, deleted } = data;

      if ((newData.length || deleted.length) && feedId) {
        const payload = deleted.length
          ? deleted
          : newData.map((a) => a.id as string);

        if (newData.length) {
          const newActivity = FeedMapper.toDomainActivity(
            newData[0] as StreamActivity
          );

          dispatch(activityActions.addActivity(newActivity));
        }

        const feedSlugList = [
          FeedSlugs.student,
          FeedSlugs.phaseJournal,
        ];

        if (feedSlugList.includes(feedSlug)) {
          dispatch(feedActions.addActivityIds(payload));

          if (deleted.length) {
            dispatch(feedActions.removeActivityIds(payload));
            dispatch(activityActions.removeActivities(deleted));
          }
        }
      }
    },
    [feedId, feedSlug, dispatch]
  );

  const subscribeToFeed = useCallback(
    (feedId: string) => {
      if (feedClient && feedId) {
        return HandleJournalSubscriptionUseCase.execute({
          feedId,
          feedClient,
          activitiesCallback,
        });
      }
    },
    [activitiesCallback, feedId, feedClient]
  );

  const sendNotification = useCallback(
    ({ addedActivity, mentions, isForReview }: SendNotificationProps) => {
      const notifyStrategies = new Map([
        [
          () => feedSlug === FeedSlugs.student && !!mentions.length,
          () => notifyJournalFeed(mentions, addedActivity.id),
        ],
        [
          () => feedSlug === FeedSlugs.student && isForReview,
          () => notifyForReview(addedActivity.id, true),
        ],
      ]);

      Array.from(notifyStrategies.entries()).forEach(([cond, execute]) => {
        if (cond()) {
          execute();
        }
      });
    },
    [notifyForReview, notifyJournalFeed, feedSlug]
  );

  const mentionUsers = useCallback(
    (id: string, mentions: Mention[]) => {
      if (feedSlug === FeedSlugs.student) {
        notifyJournalFeed(mentions, id);
      }
    },
    [feedSlug, notifyJournalFeed]
  );

  const createPost = useCallback(
    async ({
      isForReview,
      mentions,
      message,
      selectedFiles,
      journalLink,
    }: HandleAddActivityProps) => {
      if (!currentClassroom || !feedClient) {
        return;
      }

      const { addedActivity } = await dispatch(
        postActivity({
          currentClassroom,
          currentProgram,
          currentUser,
          feedClient,
          isForReview,
          feedSlug,
          message,
          selectedFiles,
          feedId,
          journalLink,
        })
      ).unwrap();

      sendNotification({ addedActivity, mentions, isForReview });
    },
    [
      currentClassroom,
      currentProgram,
      feedClient,
      currentUser,
      feedId,
      feedSlug,
      sendNotification,
      dispatch,
    ]
  );

  const deletePost = useCallback(
    async (activity: Activity) => {
      if (!currentClassroom || !feedClient) {
        return;
      }

      let allComments: Comment[] = [];
      let shouldFetchComments = true;
      let idLt = undefined;

      while (shouldFetchComments) {
        const comments = await GetActivityCommentsUseCase.execute({
          activityId: activity.id,
          streamToken,
          idLt,
        });

        allComments = allComments.concat(comments);

        if (
          !allComments.length ||
          allComments.length >= activity.reaction_counts.comment
        ) {
          shouldFetchComments = false;
        } else {
          idLt = allComments[allComments.length - 1].id;
        }
      }

      const deletedCommentsUserIds = allComments.map((c) => c.creatorId);
      const usersWithDeletedComments = countBy(deletedCommentsUserIds);

      if (feedSlug === FeedSlugs.student) {
        if (currentProgram?.id) {
          await dispatch(
            deleteJournalActivity({
              activity,
              classroomId: currentClassroom.id,
              programId: currentProgram?.id,
              feedClient,
            })
          );

          if (isStudent) {
            for (const [, count] of Object.entries(usersWithDeletedComments)) {
              try {
                await dispatch(
                  persistActivitySummaryCount({
                    classroomId: currentClassroom.id,
                    userId: currentUser.uid,
                    commentsOperator: '-',
                    programSlug: currentProgram?.slug ?? '',
                    deletedCount: count,
                  })
                );
              } catch (e) {
                loggerService.error(`Could not delete post: `, e);
              }
            }

            dispatch(
              persistActivitySummaryCount({
                classroomId: currentClassroom.id,
                postsOperator: '-',
                userId: currentUser.uid,
                programSlug: currentProgram.slug,
              })
            );
          }
        }
      }

      if (isStudent) {
        dispatch(
          persistLastPost({
            student: currentUser,
          })
        );
      }
    },
    [
      currentClassroom,
      feedClient,
      feedSlug,
      isStudent,
      streamToken,
      currentProgram?.id,
      currentProgram?.slug,
      dispatch,
      currentUser,
    ]
  );

  const hidePost = useCallback(
    async (activity: Activity, attachedFiles: AttachedFile[]) => {
      if (!currentClassroom) {
        return;
      }

      await dispatch(
        hideActivity({
          activity: activity,
          currentClassroomId: currentClassroom.id,
          hidden: !activity.hidden,
          programId: currentProgram?.id,
          streamToken: streamToken,
        })
      );

      const postOwner = userEntities[activity.actor.id];

      if (postOwner && attachedFiles?.length > 0) {
        const emails = currentClassroom.participantEmails.filter(
          (email) => email !== postOwner.email
        );

        await dispatch(
          modifyFilePermissions({
            emails,
            attachedFiles,
            shouldRemove: !activity.hidden,
          })
        );
      }
    },
    [currentClassroom, dispatch, currentProgram?.id, streamToken, userEntities]
  );

  const editPost = useCallback(
    async (activity: Activity, editedValue: string, documents: Document[]) => {
      if (!currentClassroom) {
        return;
      }

      await dispatch(
        editActivity({
          activity,
          classroomId: currentClassroom.id,
          streamToken,
          editedValue,
          feedSlug,
          programId: currentProgram?.id ?? '',
          attachedFiles: FeedMapper.toAttachedFiles(documents),
        })
      );

      if (isStudent) {
        await dispatch(
          persistLastPost({
            student: currentUser,
          })
        );
      }
    },
    [
      currentClassroom,
      currentProgram?.id,
      currentUser,
      streamToken,
      feedSlug,
      dispatch,
      isStudent,
    ]
  );

  const setForReview = useCallback(
    async (activity: Activity) => {
      if (!currentClassroom || !currentProgram?.id) {
        return;
      }

      const newReviewState = !activity.isForReview;

      dispatch(
        markForReview({
          activity,
          currentClassroom,
          streamToken,
          newReviewState,
          programId: currentProgram?.id,
        })
      );

      if (isStudent) {
        await dispatch(
          persistLastPost({
            student: currentUser,
          })
        );
      }

      notifyForReview(activity.id, newReviewState);
    },
    [
      currentClassroom,
      currentProgram?.id,
      dispatch,
      streamToken,
      isStudent,
      notifyForReview,
      currentUser,
    ]
  );

  const updateReaction = useCallback(
    async (activityId: string, emoji: string, count: number) => {
      if (!currentClassroom || !feedClient) {
        return Promise.resolve();
      }

      await ReactWithEmojiUseCase.execute(feedClient, activityId, emoji);

      dispatch(
        activityActions.updateEmojiReactionCount({
          activityId: activityId,
          emojiCount: count,
        })
      );
    },
    [currentClassroom, feedClient]
  );

  const deleteReaction = useCallback(
    async (activityId: string, emojiId: string, count: number) => {
      if (!currentClassroom || !feedClient) {
        return Promise.resolve();
      }

      await RemoveEmojiReactionUseCase.execute(feedClient, emojiId);

      dispatch(
        activityActions.updateEmojiReactionCount({
          activityId: activityId,
          emojiCount: count,
        })
      );
    },
    [currentClassroom, feedClient]
  );

  const getComments = useCallback(
    (activityId: string, idLt?: string) => {
      const { abort } = dispatch(
        fetchComments({
          streamToken,
          activityId,
          idLt,
        })
      );

      return { abort };
    },
    [dispatch, streamToken]
  );

  const addComment = useCallback(
    async ({ comment, activity, mentions }: AddCommentProps) => {
      if (!feedClient || !currentClassroom) {
        return;
      }

      try {
        await dispatch(
          createComment({
            activityId: activity.id,
            client: feedClient,
            text: comment,
          })
        );

        const { isStudent } = getUserRole(currentUser);

        mentionUsers(activity.id, mentions);

        if (isStudent) {
          await dispatch(
            persistLastPost({
              student: currentUser,
            })
          );

          if (feedSlug === FeedSlugs.student && currentProgram?.slug) {
            await dispatch(
              persistActivitySummaryCount({
                classroomId: currentClassroom.id,
                userId: currentUser.uid,
                commentsOperator: '+',
                programSlug: currentProgram.slug,
              })
            );

            LogAnalyticsEventUseCase.execute({
              number_of_journal_comments: {
                users: isStudent ? 'Students' : 'Teachers',
              },
            });
          } else {
            await dispatch(
              persistFeedCommentsCount({
                studentId: currentUser.uid,
                classroomId: currentClassroom.id,
                operator: '+',
              })
            );

            LogAnalyticsEventUseCase.execute({
              number_of_class_feed_comments: {
                users: isStudent ? 'Students' : 'Teachers',
              },
            });
          }
        }
      } catch (error) {
        loggerService.error(error);
      }
    },
    [
      feedSlug,
      currentClassroom,
      currentProgram?.slug,
      currentUser,
      feedClient,
      isStudent,
      mentionUsers,
      dispatch,
    ]
  );

  const editComment = useCallback(
    async ({ text, commentId, hidden }: EditCommentProps) => {
      if (!feedClient) {
        return;
      }
      await dispatch(
        updateComment({
          client: feedClient,
          text,
          commentId,
          hidden,
        })
      );
    },
    [feedClient, dispatch]
  );

  const deleteComment = useCallback(
    async ({ commentId, activityId }: DeleteCommentProps) => {
      if (!feedClient || !currentClassroom) {
        return;
      }

      await dispatch(
        deleteCommentAction({
          commentId,
          activityId,
          client: feedClient,
        })
      );

      if (isStudent && currentProgram?.slug) {
        await dispatch(
          persistLastPost({
            student: currentUser,
          })
        );

        if (feedSlug === FeedSlugs.student) {
          await dispatch(
            persistActivitySummaryCount({
              classroomId: currentClassroom.id,
              userId: currentUser.uid,
              commentsOperator: '-',
              programSlug: currentProgram.slug,
            })
          );
        } else {
          await dispatch(
            persistFeedCommentsCount({
              studentId: currentUser.uid,
              classroomId: currentClassroom.id,
              operator: '-',
            })
          );
        }
      }
    },
    [
      currentClassroom,
      feedClient,
      currentUser,
      currentProgram?.slug,
      feedSlug,
      isStudent,
      dispatch,
    ]
  );

  const getReactions = useCallback(
    (activityId: string, count: number) => {
      return GetActivityReactionsUseCase.execute({
        streamToken,
        activityId,
        count,
      });
    },
    [streamToken]
  );

  const isHideMenuVisible = useCallback(
    (creatorId: string) => {
      const isCommentPostedByLoggedUser = creatorId === currentUser.uid;

      const posterIsTeacher = currentClassroom?.teacherEmails.includes(
        userEntities[creatorId]?.email || ''
      );

      return (
        !isStudent &&
        !isCommentPostedByLoggedUser &&
        !posterIsTeacher
      );
    },
    [
      currentClassroom?.teacherEmails,
      currentUser.uid,
      feedSlug,
      isStudent,
      userEntities,
    ]
  );

  return (
    <FeedContext.Provider
      value={{
        feedSlug,
        feedId,
        fetchActivities,
        fetchActivity,
        subscribeToFeed,
        deleteComment,
        mentionUsers,
        setForReview,
        editComment,
        addComment,
        updateReaction,
        deleteReaction,
        createPost,
        deletePost,
        editPost,
        hidePost,
        getReactions,
        getComments,
        isHideMenuVisible,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};

export const useFeed = () => {
  const context = useContext(FeedContext);

  if (!context) {
    throw new Error('useFeed must be used within a FeedProvider');
  }

  return context;
};
