import { useEffect, useRef } from 'react';

export function useMemoCompare<T>(
  next: T,
  compare: (a: T | undefined, b: T) => boolean
) {
  const previousRef = useRef<T>();
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}
