import { z } from 'zod';
import { differenceInMinutes } from 'date-fns';
import { cond } from 'lodash';

import { UserRoles } from './UserRoles';
import { MathTagTypes } from '../Tags';

export const UserSchema = z.object({
  accessToken: z.string().optional(),
  classroomIds: z.array(z.string().min(1)),
  classFeedCommentsCountByClassroomId: z.record(z.string(), z.number()),
  createdAt: z.string().nullable(),
  currentClassroomId: z.string().nullable(),
  currentProgramIdByClassroomId: z.record(z.string(), z.string()),
  currentSchoolId: z.string().nullable(),
  displayName: z.string(),
  email: z.string(),
  feedCommentsCount: z.number(),
  feedTagFilesPerClassroom: z
    .record(
      z.string(),
      z.record(z.string(), z.nativeEnum(MathTagTypes).array())
    )
    .nullable(),
  feedTagPostsPerClassroom: z
    .record(
      z.string(),
      z.record(z.string(), z.nativeEnum(MathTagTypes).array())
    )
    .nullable(),
  journalTagFilesPerClassroom: z
    .record(
      z.string(),
      z.record(z.string(), z.nativeEnum(MathTagTypes).array())
    )
    .nullable(),
  journalTagPostsPerClassroom: z
    .record(
      z.string(),
      z.record(z.string(), z.nativeEnum(MathTagTypes).array())
    )
    .nullable(),
  lastPost: z.string().nullable(),
  lastActivity: z.string().nullable(),
  modifiedAt: z.string().nullable(),
  photoUrl: z.string().nullable().optional(),
  roleByClassroomId: z.record(z.string(), z.nativeEnum(UserRoles)),
  roles: z.nativeEnum(UserRoles).array(),
  uid: z.string(),
});

export type User = z.infer<typeof UserSchema>;

export const getUserRole = (user: User) => ({
  isTeacher: user.roles?.includes(UserRoles.teacher),
  isViewer: user.roles?.includes(UserRoles.viewer),
  isAdmin: user.roles?.includes(UserRoles.admin),
  isStudent: user.roles?.includes(UserRoles.student),
});

export const extractUserDomain = (email: string) =>
  email ? email.split('@')[1].trim() : '';

type RoleConditionFlags = {
  isAdmin: boolean;
  isTeacher: boolean;
  isViewer: boolean;
  isStudent: boolean;
  impersonate?: boolean;
};

export const generateUserRole = ({
  isStudent,
  isAdmin,
  isViewer,
  impersonate,
  isTeacher,
}: RoleConditionFlags) =>
  cond([
    [() => isAdmin, () => UserRoles.admin],
    [() => isStudent, () => UserRoles.student],
    [() => isTeacher, () => UserRoles.teacher],
    [() => isViewer, () => UserRoles.viewer],
    [() => Boolean(impersonate), () => UserRoles.teacher],
  ])();

export const shouldUpdateLastActivity = (currentUser: User) => {
  const { isStudent } = getUserRole(currentUser);

  if (!currentUser.uid || !isStudent) return false;

  const lastActivity = currentUser.lastActivity
    ? new Date(currentUser.lastActivity)
    : new Date();

  const diffInMin = differenceInMinutes(new Date(), lastActivity);

  return diffInMin >= 15;
};
