import { ChangeEvent, FC } from 'react';
import { Button } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';

import { StyledPrimaryLoadingButton } from '../Layout';

type FileInputProps = {
  text?: string | JSX.Element;
  onFileSelect(event: ChangeEvent<HTMLInputElement>): void;
  hideEndIcon?: boolean;
  className?: string;
  acceptedFiles?: string;
  acceptMultipleFiles?: boolean;
  disabled?: boolean;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
};

type DriveFileInputProps = FileInputProps & {
  requestScopes: () => Promise<boolean>;
  loading: boolean;
};

export const FileInput = ({
  onFileSelect,
  text,
  hideEndIcon,
  className,
  acceptedFiles,
  acceptMultipleFiles = true,
  color = 'primary',
  variant = 'contained',
  disabled = false,
}: FileInputProps) => {
  const onFileSelectHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const file: File | null = fileInput?.files && fileInput.files[0];

    if (!file) {
      return;
    }

    onFileSelect(event);
  };

  return (
    <Button
      variant={variant}
      component="label"
      className={className}
      endIcon={hideEndIcon ? null : <PublishIcon />}
      color={color}
      disabled={disabled}
    >
      {text}
      <input
        type="file"
        disabled={disabled}
        accept={
          acceptedFiles ||
          'audio/*, video/*, image/*, .pdf, text/csv, .xls, .xlsx, .docx, .pptx'
        }
        onChange={onFileSelectHandler}
        hidden
        multiple={acceptMultipleFiles}
      />
    </Button>
  );
};

export const FileWithGooglePermissions = (props: DriveFileInputProps) => {
  const driveScopeChecker = async (event: ChangeEvent<HTMLInputElement>) => {
    const hasScopes = await props.requestScopes();

    if (hasScopes) {
      props.onFileSelect(event);
    }
  };

  if (props.loading) {
    return (
      <StyledPrimaryLoadingButton
        className={props?.className}
        loading={props.loading}
      >
        {props.text}
      </StyledPrimaryLoadingButton>
    );
  }

  return <FileInput {...props} onFileSelect={driveScopeChecker} />;
};
