import { z } from 'zod';

export const LastModifyingUserSchema = z.object({
  displayName: z.string().nullable().optional(),
  emailAddress: z.string().nullable().optional(),
  kind: z.string().nullable().optional(),
  me: z.boolean().nullable().optional(),
  permissionId: z.string().nullable().optional(),
  photoLink: z.string().nullable().optional(),
});

export type LastModifyingUser = z.infer<typeof LastModifyingUserSchema>;
