import { NOTIFICATION_FEED_SLUGS } from '@xq/domain';
import { NotificationFeedService } from '@xq/infrastructure';
import { StreamClient } from 'getstream';

export class UnsubscribeFromNotificationFeedUseCase {
  static async execute(client: StreamClient) {
    return Promise.all(
      NOTIFICATION_FEED_SLUGS.map((slug) => {
        const notificationFeedService =
          NotificationFeedService.getInstance(client);

        return notificationFeedService.unsubscribeToFeed(slug);
      })
    );
  }
}
