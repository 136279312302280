import { Typography, styled, Accordion, AccordionSummary } from '@mui/material';

export const StyledSubAccordionContent = styled('div')`
  margin-top: -20px;
  margin-left: 2px;

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const StyledSubAccordionTitle = styled(Typography)`
  font-weight: bold;
  color: var(--shark);
  font-size: 18px;
  display: flex;
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  margin-left: 16px !important;

  &.Mui-expanded.Mui-disabled {
    background-color: transparent;
  }
`;

export const StyledAccordionsContainer = styled('div')`
  overflow: auto;
  position: relative;
  margin-left: 4px;
`;

export const StyledAccordSummary = styled(AccordionSummary)`
  padding: 0;

  &.Mui-disabled {
    opacity: 1;
  }
`;
