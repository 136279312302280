import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NewClassroom {
  schoolId: string;
  name: string;
  shortName: string;
  teacherEmails: Array<string>;
  participantEmails: Array<string>;
  isDirty: boolean;
}

const initialState: NewClassroom = {
  schoolId: '',
  name: '',
  shortName: '',
  teacherEmails: [],
  participantEmails: [],
  isDirty: false,
};

export const newClassroomSlice = createSlice({
  name: 'new-classroom',
  initialState: initialState,
  reducers: {
    setNewClassroom(state, { payload }: PayloadAction<Partial<NewClassroom>>) {
      return { ...state, ...payload, isDirty: true };
    },
    clearNewClassroom() {
      return initialState;
    },
    addParticipantEmails(state, { payload }: PayloadAction<string[]>) {
      state.isDirty = true;
      state.participantEmails = state.participantEmails.concat(payload);
    },
    removeParticipantEmails(state, { payload }: PayloadAction<string[]>) {
      state.isDirty = true;
      state.participantEmails = state.participantEmails.filter(
        (email) => !payload.includes(email)
      );
    },
    addTeacherEmails(state, { payload }: PayloadAction<string[]>) {
      state.isDirty = true;
      state.teacherEmails = state.teacherEmails.concat(payload);
    },
    removeTeacherEmails(state, { payload }: PayloadAction<string[]>) {
      state.isDirty = true;
      state.teacherEmails = state.teacherEmails.filter(
        (email) => !payload.includes(email)
      );
    },
  },
});

export const newClassroomActions = newClassroomSlice.actions;
