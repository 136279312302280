import { IconButton, styled } from '@mui/material';

export const StyledJournalLink = styled('div')`
  position: relative;
  border-radius: 3px;
  padding: 8px;
  margin: 8px 8px 0;
  background-color: var(--panache);
`;

export const StyledClearJournalLinkBtn = styled(IconButton)`
  margin-left: auto;
  margin-top: -8px;
  margin-right: -8px;
  float: right;
`;

StyledClearJournalLinkBtn.defaultProps = {
  color: 'info',
};
