import { useEffect } from 'react';
import {
  doc,
  DocumentReference,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore';

import {
  CollectionNames,
  DocumentMapper,
  FbFileDto,
} from '@xq/shared/data-access';
import {
  firebaseService,
  loggerService,
  subscriptionManager,
} from '@xq/infrastructure';
import { allEqual } from '@xq/shared/utils';

import { useAppDispatch, userDocumentsActions } from '../store';
import { useMemoCompare } from './useMemoCompare';

type Props = {
  userIds: string[];
  classroomId?: string;
  programId?: string;
};

export const useStudentsFilesSubscription = ({
  userIds,
  classroomId,
  programId,
}: Props) => {
  const dispatch = useAppDispatch();

  const shouldCreateQuery = useMemoCompare(userIds, (prev, next) => {
    if (!prev) {
      return false;
    }

    return allEqual(next, prev);
  });

  useEffect(() => {
    if (!classroomId || !programId) {
      return;
    }

    const queries = userIds.map((id: string) =>
      doc(
        firebaseService.db,
        `${CollectionNames.users}/${id}/${CollectionNames.usersFiles}/${classroomId}-${programId}`
      )
    );

    const subscriptions: Unsubscribe[] = [];

    const subscribe = (query: DocumentReference) => {
      const sub = onSnapshot(
        query,
        (snapshot) => {
          if (snapshot.exists() && snapshot.ref.parent.parent?.id) {
            const documents = Object.values<FbFileDto>(snapshot.data()).map(
              (fileDto) => DocumentMapper.toDomain(fileDto)
            );

            dispatch(
              userDocumentsActions.setDocuments({
                userId: snapshot.ref.parent.parent.id,
                classroomId,
                programId,
                documents,
              })
            );
          }
        },
        (e) => {
          loggerService.error('STUDENT FILES SUBSCRIPTION ERROR: ', e);
        }
      );

      subscriptions.push(sub);
    };

    queries.forEach(subscribe);
    subscriptionManager.add(subscriptions);

    return () => subscriptionManager.remove(subscriptions);
  }, [shouldCreateQuery, classroomId, programId]);
};
