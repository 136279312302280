import type { Unsubscribe } from 'firebase/firestore';

class SubscriptionManager {
  private subscriptions: Unsubscribe[] = [];

  add(subscriptions: Unsubscribe[]) {
    this.subscriptions.push(...subscriptions);
  }

  remove(subscriptions: Unsubscribe[]) {
    for (const sub of subscriptions) {
      const index = this.subscriptions.indexOf(sub);

      if (index) {
        this.subscriptions.splice(index, 1);
        sub();
      }
    }
  }

  removeAll() {
    for (const sub of this.subscriptions) {
      sub();
    }

    this.subscriptions = [];
  }
}

export const subscriptionManager = new SubscriptionManager();
