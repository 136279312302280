import { writeBatch } from 'firebase/firestore';

import {
  ClassroomRepository,
  firebaseService,
  tagsService,
  UserRepository,
} from '@xq/infrastructure';
import { UserTagType } from '@xq/domain';

type DeleteFileUseCaseProps = {
  fileId: string;
  userId: string;
  classroomId: string;
  programId?: string;
  tagType: UserTagType.JournalTagFiles | UserTagType.FeedTagFiles;
};

export class DeleteFileUseCase {
  static async execute({
    classroomId,
    tagType,
    programId,
    userId,
    fileId,
  }: DeleteFileUseCaseProps) {
    const batch = writeBatch(firebaseService.db);

    const userRepo = new UserRepository();

    if (programId) {
      userRepo.deleteFile({
        batch,
        fileId,
        userId,
        classroomId,
        programId,
      });

      return batch.commit();
    }
  }
}
