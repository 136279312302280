import { createAsyncThunk } from '@reduxjs/toolkit';
import { StreamClient } from 'getstream';

import {
  DeleteAllNotificationsUseCase,
  DeleteNotificationUseCase,
  GetNotificationsUseCase,
} from '@xq/usecases';
import { loggerService } from '@xq/infrastructure';

type FetchNotificationsPayload = {
  feedClient: StreamClient;
  beforeId?: string;
  markAsReadIds?: string[] | boolean;
};

type DeleteNotificationPayload = {
  feedClient: StreamClient;
  id: string;
};

type DeleteAllNotificationsPayload = {
  userId: string;
};

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async ({
    feedClient,
    beforeId,
    markAsReadIds,
  }: FetchNotificationsPayload) => {
    try {
      return await GetNotificationsUseCase.execute(feedClient, {
        beforeId,
        markAsReadIds,
      });
    } catch (e) {
      loggerService.error(e);
      return {
        unread: 0,
        result: [],
      };
    }
  }
);

export const deleteNotification = createAsyncThunk(
  'notifications/deleteNotification',
  async ({ feedClient, id }: DeleteNotificationPayload) => {
    try {
      return (await DeleteNotificationUseCase.execute(feedClient, id)).filter(
        Boolean
      )[0];
    } catch (e) {
      loggerService.error(e);
    }
  }
);

export const deleteAllNotifications = createAsyncThunk(
  'notifications/deleteAllNotifications',
  async ({ userId }: DeleteAllNotificationsPayload) => {
    return (await DeleteAllNotificationsUseCase.execute({ userId })).data;
  }
);
