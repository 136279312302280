import {
  CalculatorScopes,
  ChatScopes,
  ClassroomsScopes,
  JournalFeedScopes,
  MyFilesScopes,
  NotificationsScopes,
} from '../scopes';

export const studentScopes = [
  ChatScopes.CAN_VIEW,
  JournalFeedScopes.CAN_COMMENT,
  JournalFeedScopes.CAN_DELETE_POST,
  JournalFeedScopes.CAN_EDIT_POST,
  JournalFeedScopes.CAN_POST,
  JournalFeedScopes.CAN_VIEW,
  MyFilesScopes.CAN_CREATE,
  MyFilesScopes.CAN_DELETE,
  MyFilesScopes.CAN_UPLOAD,
  MyFilesScopes.CAN_VIEW,
  MyFilesScopes.CAN_MARK_FOR_REVIEW,
  NotificationsScopes.CAN_VIEW,
  NotificationsScopes.CAN_DELETE,
  ClassroomsScopes.CAN_VIEW_OWN,
  JournalFeedScopes.CAN_REACT_WITH_EMOJIS,
  CalculatorScopes.CAN_MARK_CALCULATOR_FOR_REVIEW
];
