import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';

import {
  Activity,
  Document,
  FeedSlugs,
  getUserRole,
  MathTagTypes,
  UserTagType,
  GoogleFilesMimeTypes,
} from '@xq/domain';
import {
  AppError,
  AppErrorType,
  loggerService,
  tagsService,
  FeedService,
} from '@xq/infrastructure';
import {
  CreateFileInDriveUseCase,
  DeleteFileFromDriveUseCase,
  DeleteFileUseCase,
  LogAnalyticsEventUseCase,
  PersistStudentFileUseCase,
  UploadToDriveUseCase,
} from '@xq/usecases';

import {
  selectCurrentUserClassroom,
  selectCurrentUserProgram,
  selectCurrentUserSchool,
  selectCurrentUserState,
  taggedPostActions,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { useApi } from '../../ApiProvider/ApiProvider';
import { useModal } from '../modal-context/modal-context';
import { useGoogleScopesChecker } from '../../hooks';

type UserFilesProviderProps = {
  parentFolderId?: string;
  feedSlug: FeedSlugs;
};

// commented due to requirement to remove auto-tagging and tagging 23.10.2023
// type DeleteMathTagFromFileProps = {
//   mathTagForDelete: MathTagTypes;
//   document: Document;
//   userId: string;
// };

type FileListUploadProps = {
  fileList: FileList | File[];
  shouldTagAndPersist?: boolean;
  folderId?: string;
  shouldLogAnalyticsEvent?: boolean;
};

type UploadFileProps = {
  userId: string;
  file: File;
  shouldTagAndPersist?: boolean;
  folderId?: string;
  shouldLogAnalyticsEvent?: boolean;
};

export type UserFilesState = {
  createFile: (
    fileName: string,
    mimeType: GoogleFilesMimeTypes
  ) => Promise<Document | undefined>;
  uploadFile: (props: UploadFileProps) => Promise<Document | undefined>;
  deleteFile: (fileId: string, userId: string) => Promise<void>;
  // commented due to requirement to remove auto-tagging and tagging 23.10.2023
  // tagFiles: (files: FileForTag[]) => Promise<void>;
  fileListUpload: (props: FileListUploadProps) => Promise<Document[]>;
  // commented due to requirement to remove auto-tagging and tagging 23.10.2023
  // deleteMathTagFromFile: (props: DeleteMathTagFromFileProps) => Promise<void>;
  deleteMathFromPost: (
    activity: Activity,
    mathTagForDelete: MathTagTypes
  ) => Promise<void>;
};

const UserFilesContext = createContext<UserFilesState | undefined>(undefined);

export const UserFilesProvider = ({
  children,
  parentFolderId,
  feedSlug,
}: PropsWithChildren<UserFilesProviderProps>) => {
  const { feedClient } = useApi();
  const { dispatchModal } = useModal();
  const dispatch = useAppDispatch();
  const { requestScopes } = useGoogleScopesChecker();

  const currentUser = useAppSelector(selectCurrentUserState);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const currentSchool = useAppSelector(selectCurrentUserSchool);

  const createFile = useCallback(
    async (fileName: string, mimeType: GoogleFilesMimeTypes) => {
      if (parentFolderId && currentProgram?.id && currentClassroom?.id) {
        const fileValues = await CreateFileInDriveUseCase.execute({
          fileName,
          mimeType,
          folderId: parentFolderId,
        });

        await PersistStudentFileUseCase.execute({
          fileValues,
          userId: currentUser.uid,
          classroomId: currentClassroom.id,
          programId: currentProgram.id,
        });

        return fileValues;
      }
    },
    [parentFolderId, currentClassroom?.id, currentProgram?.id, currentUser.uid]
  );

  const uploadFile = useCallback(
    async ({
      file,
      userId,
      folderId,
      shouldLogAnalyticsEvent,
      shouldTagAndPersist,
    }: UploadFileProps) => {
      if ((!parentFolderId && !folderId) || !currentClassroom?.id) return;

      const fileValues = await UploadToDriveUseCase.execute(
        file,
        parentFolderId ? parentFolderId : folderId || ''
      );

      if (!shouldTagAndPersist) {
        return fileValues;
      }

      const { isStudent } = getUserRole(currentUser);

      if (isStudent) {
        LogAnalyticsEventUseCase.execute({
          number_of_files_uploaded: {
            classroom: currentClassroom?.name,
            school: currentSchool?.name,
          },
        });
      }

      if (
        shouldLogAnalyticsEvent &&
        feedSlug === FeedSlugs.student &&
        currentProgram?.id
      ) {
        return PersistStudentFileUseCase.execute({
          fileValues,
          userId,
          classroomId: currentClassroom.id,
          programId: currentProgram.id,
        });
      }
    },
    [currentClassroom?.id, currentProgram?.id, feedSlug, parentFolderId]
  );

  const deleteFile = useCallback(
    async (fileId: string, userId: string) => {
      const hasScopes = await requestScopes();
      if (!currentClassroom?.id || !hasScopes) {
        return;
      }

      try {
        await DeleteFileFromDriveUseCase.execute(fileId);
      } catch (e) {
        loggerService.error(e);
      }

      const tagType =
        feedSlug === FeedSlugs.student
          ? UserTagType.JournalTagFiles
          : UserTagType.FeedTagFiles;

      await DeleteFileUseCase.execute({
        fileId,
        userId,
        classroomId: currentClassroom.id,
        programId: currentProgram?.id,
        tagType,
      });
    },
    [currentClassroom?.id, currentProgram?.id, feedSlug]
  );

  // commented due to requirement to remove auto-tagging and tagging 23.10.2023
  // const tagFiles = useCallback(
  //   async (filesForTag: FileForTag[]) => {
  //     if (currentProgram?.id && feedClient && currentClassroom?.id) {
  //       return tagsService.tagFiles({
  //         files: filesForTag,
  //         userId: currentUser.uid,
  //         classroomId: currentClassroom.id,
  //         programId: currentProgram.id,
  //         client: feedClient,
  //         feedSlug,
  //       });
  //     }
  //   },
  //   [
  //     currentClassroom?.id,
  //     currentProgram?.id,
  //     currentUser.uid,
  //     feedSlug,
  //     feedClient,
  //   ]
  // );

  const fileListUpload = useCallback(
    async ({
      fileList,
      shouldLogAnalyticsEvent = true,
      shouldTagAndPersist = true,
      folderId,
    }: FileListUploadProps) => {
      if (!fileList.length) {
        return [];
      }

      const result = await Promise.all(
        Array.from(fileList).map(async (file) => {
          try {
            const driveFileResponse = await uploadFile({
              file,
              userId: currentUser.uid,
              shouldLogAnalyticsEvent,
              folderId,
              shouldTagAndPersist,
            });

            // commented due to requirement to remove auto-tagging and tagging 23.10.2023
            // const mimeType = driveFileResponse?.mimeType;
            // if (shouldTagAndPersist && driveFileResponse?.id && mimeType) {
            //   tagFiles([
            //     {
            //       id: driveFileResponse.id,
            //       mimeType,
            //     },
            //   ]);
            // }

            return driveFileResponse;
          } catch (e) {
            loggerService.error(e);
            const err = e as AppError;
            if (err?.status === 404) {
              throw new AppError(AppErrorType.FileNotFound, err.message);
            }
          }
        })
      );

      return result.filter(Boolean);
    },
    [uploadFile, currentUser.uid]
    // [uploadFile, currentUser.uid, tagFiles]
  );

  // commented due to requirement to remove auto-tagging and tagging 23.10.2023
  // const deleteMathTagFromFile = useCallback(
  //   async ({
  //     mathTagForDelete,
  //     document,
  //     userId,
  //   }: DeleteMathTagFromFileProps) => {
  //     if (!currentClassroom?.id) {
  //       return;
  //     }
  //
  //     const tagType =
  //       feedSlug === FeedSlugs.student
  //         ? UserTagType.JournalTagFiles
  //         : UserTagType.FeedTagFiles;
  //
  //     dispatchModal({ loading: true });
  //
  //     try {
  //       await tagsService.removeTagFromFile([
  //         {
  //           resourceId: document.id,
  //           tags:
  //             document.autoTags && document.autoTags.length > 1
  //               ? [mathTagForDelete]
  //               : [],
  //           userId,
  //           classroomId: currentClassroom.id,
  //           programId: currentProgram?.id,
  //           tagType,
  //         },
  //       ]);
  //     } catch (e) {
  //       loggerService.error(e);
  //     }
  //
  //     dispatchModal({ loading: false });
  //   },
  //   [currentClassroom?.id, currentProgram?.id, dispatch, feedSlug]
  // );

  const deleteMathFromPost = useCallback(
    async (activity: Activity, mathTagForDelete: MathTagTypes) => {
      if (!feedClient || !currentClassroom?.id) {
        return;
      }

      dispatch(
        taggedPostActions.removeTagFromPost({
          tag: mathTagForDelete,
          postId: activity.id,
        })
      );

      const feedService = FeedService.getInstance(feedClient);
      const mathTags = activity.autoTags.filter(
        (tag) => tag !== mathTagForDelete
      );

      dispatchModal({ loading: true });

      try {
        await feedService.updatePost({
          id: activity.id,
          mathTags,
          userId: activity.actor.id,
          feedSlug,
          classroomId: currentClassroom.id,
        });

        const tagType =
          feedSlug === FeedSlugs.student
            ? UserTagType.JournalTagPosts
            : UserTagType.FeedTagPosts;

        await tagsService.removeUserTagsFromPost([
          {
            tags: activity.autoTags.length > 1 ? [mathTagForDelete] : [],
            userId: activity.actor.id,
            classroomId: currentClassroom.id,
            programId: currentProgram?.id,
            resourceId: activity.id,
            tagType,
          },
        ]);
      } catch (e) {
        loggerService.error(e);
      }

      dispatchModal({ loading: false });
    },
    [feedClient, dispatch, currentClassroom?.id, currentProgram?.id, feedSlug]
  );

  return (
    <UserFilesContext.Provider
      value={{
        deleteFile,
        uploadFile,
        // tagFiles,
        createFile,
        fileListUpload,
        deleteMathFromPost,
        // deleteMathTagFromFile,
      }}
    >
      {children}
    </UserFilesContext.Provider>
  );
};

export const useUserFiles = () => {
  const context = useContext(UserFilesContext);

  if (!context) {
    throw new Error('useUserFiles must be used within a UserFilesProvider');
  }

  return context;
};
