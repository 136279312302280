import { SvgIcon, styled } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import {
  SvgIconPropsColorOverrides,
  SvgIconPropsSizeOverrides,
} from '@mui/material/SvgIcon/SvgIcon';

const StyledClassroomSetupIcon = styled(SvgIcon)`
  width: 50px;
  height: 24.9px;
`;

export type ClassroomSetupIconProps = {
  fontSize: OverridableStringUnion<
    'inherit' | 'large' | 'medium' | 'small',
    SvgIconPropsSizeOverrides
  >;
  color?: OverridableStringUnion<
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    SvgIconPropsColorOverrides
  >;
  to?: string;
};

export function ClassroomSetupIconPrimaryColor({
  color,
  fontSize,
  to,
}: ClassroomSetupIconProps) {
  return (
    <StyledClassroomSetupIcon
      fontSize={fontSize}
      viewBox="0 0 18 15.5"
      color={color}
      to={to}
    >
      <path d="M8 3.25V0.25H0.5V13.75H15.5V3.25H8ZM6.5 12.25H2V10.75H6.5V12.25ZM6.5 9.25H2V7.75H6.5V9.25ZM6.5 6.25H2V4.75H6.5V6.25ZM6.5 3.25H2V1.75H6.5V3.25ZM14 12.25H8V4.75H14V12.25ZM12.5 6.25H9.5V7.75H12.5V6.25ZM12.5 9.25H9.5V10.75H12.5V9.25Z" />
    </StyledClassroomSetupIcon>
  );
}
