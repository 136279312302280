import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload } from 'react-use';

import {
  AddEmails,
  emailBackendValidation,
  InvalidEmail,
  newClassroomActions,
  selectNewClassroomState,
  AdministrationTabs,
  selectSchoolById,
  StyledButton,
  StyledFooter,
  StyledLayoutContainer,
  StyledMain,
  StyledNextButton,
  useAppDispatch,
  useAppSelector,
  useNavigationWarningModal,
} from '@xq/web-components';
import { ValidateEmailsUseCase } from '@xq/usecases';
import { UserRoles } from '@xq/domain';
import { TEACHER_APP_ROUTES } from '@xq/web-config';

import { CreateFormHeader } from '../../components/CreateFormHeader';

export const AddStudentsToNewClassroom = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const newClassroomState = useAppSelector(selectNewClassroomState);
  const school = useAppSelector((state) =>
    selectSchoolById(state, newClassroomState.schoolId)
  );

  const [invalidEmails, setInvalidEmails] = useState<InvalidEmail[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useBeforeUnload();

  const validateStudentEmails = (emails: string[]) => {
    return ValidateEmailsUseCase.execute({
      teacherEmails: [],
      participantEmails: emails,
    });
  };

  const addStudentEmails = (emails: string[]) => {
    dispatch(newClassroomActions.addParticipantEmails(emails));
  };

  const deleteStudentEmails = (email: string[]) => {
    dispatch(newClassroomActions.removeParticipantEmails(email));
  };

  const roleLabel = t('student(s)');
  const roleLabelPlural = t('students').toLowerCase();
  const inputTitle = t('add_students_manually');
  const inputDescription = t('adding_student_description');

  const validate = async () => {
    if (!newClassroomState.participantEmails.length) {
      return navigate(TEACHER_APP_ROUTES.getClassroomSummary());
    }

    setIsLoading(true);

    const { valid, inValid } = await emailBackendValidation({
      newEmails: newClassroomState.participantEmails,
      backendValidation: validateStudentEmails,
      previousInvalidEmails: invalidEmails,
    });

    setIsLoading(false);

    if (!inValid.length) {
      return navigate(TEACHER_APP_ROUTES.getClassroomSummary());
    }

    setInvalidEmails(inValid);
    deleteStudentEmails(inValid.map((em: InvalidEmail) => em.email));
    dispatch(
      newClassroomActions.setNewClassroom({
        participantEmails: valid,
      })
    );
  };

  const { isDirty } = newClassroomState;

  const disabledNext = !!invalidEmails.length;

  useNavigationWarningModal({
    when: isDirty,
    safeRoute: [
      TEACHER_APP_ROUTES.getAddingTeachersToNewClassroom(),
      TEACHER_APP_ROUTES.getClassroomSummary(),
    ],
  });

  return (
    <StyledLayoutContainer>
      <CreateFormHeader
        activeIndex={2}
        completeIndexes={[0, 1]}
        title={t('step_three_add_students_optional')}
        exitPath={TEACHER_APP_ROUTES.getAdministration(
          AdministrationTabs.classrooms
        )}
      />
      <StyledMain>
        <AddEmails
          predefinedEmails={newClassroomState.participantEmails}
          domains={school?.domains}
          invalidEmails={invalidEmails}
          setInvalidEmails={setInvalidEmails}
          addedEmails={newClassroomState.participantEmails}
          usedEmails={newClassroomState.teacherEmails}
          validateEmails={validateStudentEmails}
          roleLabel={roleLabel}
          roleLabelPlural={roleLabelPlural}
          inputTitle={inputTitle}
          inputDescription={inputDescription}
          addEmails={addStudentEmails}
          removeEmails={deleteStudentEmails}
          showImportCSV
          wrongRoleError={t('wrong_role', { role: UserRoles.teacher })}
        />
      </StyledMain>
      <StyledFooter>
        <StyledButton
          onClick={() => {
            navigate(TEACHER_APP_ROUTES.getAddingTeachersToNewClassroom());
          }}
        >
          {t('back')}
        </StyledButton>
        <StyledNextButton
          loading={isLoading}
          disabled={disabledNext}
          onClick={validate}
          data-cy="add-students-next-button"
        >
          {t('next')}
        </StyledNextButton>
      </StyledFooter>
    </StyledLayoutContainer>
  );
};
