import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';

import styles from './text-editor.module.scss';
import { MentionNode, EmojiNode } from './plugins';

type TextEditorConfig = Parameters<
  typeof LexicalComposer
>['0']['initialConfig'];

const EDITOR_NODES = [
  ListNode,
  ListItemNode,
  MentionNode,
  EmojiNode,
  LinkNode,
  AutoLinkNode,
];

const defaultTheme: TextEditorConfig['theme'] = {
  link: styles.cursorPointer,
  text: {
    strikethrough: styles.textUnderline,
    underline: styles.textUnderline,
    underlineStrikethrough: styles.textUnderlineStrikethrough,
    bold: styles.textBold,
    italic: styles.textItalic,
  },
};

export const outlinedTextEditorConfig: TextEditorConfig = {
  namespace: 'OutlinedTextEditor',
  theme: {
    root: styles.rootOutlinedEditor,
    ...defaultTheme,
  },
  nodes: EDITOR_NODES,
  onError: (error) => {
    console.log(error);
  },
};

export const mobileTextEditorConfig: TextEditorConfig = {
  namespace: 'MobileTextEditor',
  theme: {
    root: styles.rootMobileEditor,
    ...defaultTheme,
  },
  nodes: EDITOR_NODES,
  onError: (error) => {
    console.log(error);
  },
};

export const standardTextEditorConfig: TextEditorConfig = {
  namespace: 'StandardTextEditor',
  theme: {
    root: styles.rootStandardEditor,
    ...defaultTheme,
  },
  nodes: EDITOR_NODES,
  onError: (error) => {
    console.log(error);
  },
};

export const readonlyEditorConfig: TextEditorConfig = {
  editable: false,
  namespace: 'ReadOnlyTextEditor',
  theme: {
    root: styles.rootReadonlyEditor,
    ...defaultTheme,
  },
  nodes: EDITOR_NODES,
  onError: (error) => {
    console.log(error);
  },
};
