import { Grid } from '@mui/material';
import { cx } from '@emotion/css';

import styles from './ribbon-item.module.scss';

export type RibbonItemProps = {
  titles: { title: string; subTitle: string };
  isFirst: boolean;
  isLast: boolean;
  index: number;
  allDone: boolean;
  currentPhaseIndex: number;
  activePhaseIndex?: number;
  totalItems: number;
  onRibbonClick(id: number): void;
};

export const RibbonItem = ({
  titles,
  index,
  currentPhaseIndex,
  activePhaseIndex,
  isFirst,
  isLast,
  allDone,
  totalItems,
  onRibbonClick,
}: RibbonItemProps) => {
  const isCurrent = index === currentPhaseIndex;

  const baseClasses = ` ${styles.initialPhase} ${styles.ribbonItem}`;
  const firstClasses = ` ${styles.arrowLeftOutlineBorder} ${styles.arrowRightOutline}`;
  const lastClasses = ` ${styles.arrowRightOutlineBorder} ${styles.arrowLeftOutline}`;
  const middleClasses = ` ${styles.arrowLeftOutline} ${styles.arrowRightOutline}`;
  const activeClasses = ` ${styles.activePhase} ${styles.afterActive} ${styles.beforeCompletedActive} ${styles.selectedActivePhase}`;
  const completedClasses = ` ${styles.completedPhase} ${styles.afterCompleted} ${styles.beforeCompletedActive}`;
  const currentClasses = !allDone
    ? ` ${styles.selectedInitialPhase}`
    : ` ${styles.selectedCompletedPhase}`;

  const position = isFirst ? 'isFirst' : isLast ? 'isLast' : 'isMiddle';
  const completion =
    index === activePhaseIndex ? 'isActive' : allDone ? 'allDone' : 'default';

  const mapByPosition = {
    isFirst: () => firstClasses,
    isLast: () => lastClasses,
    isMiddle: () => middleClasses,
  }[position];

  const mapByCompletion = {
    isActive: () => activeClasses,
    allDone: () => completedClasses,
    default: () => '',
  }[completion];

  return (
    <Grid
      item
      xs
      className={cx(baseClasses, mapByPosition(), mapByCompletion(), {
        [currentClasses]: isCurrent,
      })}
      style={{ zIndex: totalItems - index }}
      onClick={() => onRibbonClick(index)}
    >
      <div className={styles.ribbonTitle}>{titles?.title}</div>
      <div className={styles.ribbonSubTitle}>{titles?.subTitle}</div>
    </Grid>
  );
};
