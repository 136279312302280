import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Notification } from '@xq/domain';

import {
  deleteAllNotifications,
  deleteNotification,
  fetchNotifications,
} from './notifications-actions';

export const notificationAdapter = createEntityAdapter<Notification>({
  selectId: (notification) => notification.id,
  sortComparer: (a, b) => Date.parse(b.time) - Date.parse(a.time),
});

interface NotificationsSliceState {
  loading: boolean;
  lastNotificationId: null | string;
  beingDeleted: string[];
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationAdapter.getInitialState<NotificationsSliceState>({
    loading: false,
    lastNotificationId: null,
    beingDeleted: [],
  }),
  reducers: {
    setNotifications: notificationAdapter.setMany,
    deleteAll: notificationAdapter.removeAll,
    removeMany: notificationAdapter.removeMany,
    updateLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    markAsReadNotifications: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach((id) => {
        const notification = state.entities[id];
        if (notification && !notification.isRead) {
          notification.isRead = true;
        }
      });
    },
    markAllAsRead: (state) => {
      state.ids.forEach((id) => {
        const notification = state.entities[id];
        if (notification) {
          notification.isRead = true;
        }
      });
    },
    resetBeingDeleted: (state) => {
      state.beingDeleted = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.lastNotificationId =
        payload.result[payload.result.length - 1]?.group ?? null;
      notificationAdapter.setMany(state, payload.result);
    });
    builder.addCase(deleteNotification.fulfilled, (state, { payload }) => {
      if (payload) {
        state.entities = Object.fromEntries(
          Object.entries(state.entities).filter(
            (notification) => notification[1]?.id !== payload
          )
        );
      }
    });
    builder.addCase(deleteAllNotifications.pending, (state) => {
      state.beingDeleted = Object.values(state.entities)
        .map((e) => e?.id)
        .filter((id): id is string => Boolean(id));
    });
  },
});

export const notificationsActions = notificationsSlice.actions;
