import {
  AnyAction,
  combineReducers,
  configureStore,
  createAction,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { activitySlice } from './activity';
import { currentUserSlice } from './current-user';
import { taggedFileSlice } from './tagged-file';
import { taggedPostSlice } from './tagged-post';
import { classroomIntegrationSlice } from './classroom-integration';
import { schoolSlice } from './school';
import { programSlice } from './program';
import { classroomsSlice } from './classrooms';
import { snackbarSlice } from './snackbar';
import { usersSlice } from './users';
import { userDocumentsSlice } from './user-documents';
import { userProgramProgressSlice } from './user-program-progress';
import { followedUpUsersSlice } from './followed-up-users';
import { newClassroomSlice } from './new-classroom';
import { notificationsSlice } from './notifications';
import { programPostSlice } from './program-post';
import { newSchoolSlice } from './new-school';
import { appSlice } from './app';
import { templatesSlice } from './templates';
import { commentSlice } from './comment';
import { feedSlice } from './feed';
import { wizardContentSlice } from './wizard';
import { userCalculatorsSlice } from './user-calculators';
import { coursesSlice } from './courses';

const appReducer = combineReducers({
  activity: activitySlice.reducer,
  app: appSlice.reducer,
  classroomIntegration: classroomIntegrationSlice.reducer,
  classrooms: classroomsSlice.reducer,
  comment: commentSlice.reducer,
  courses: coursesSlice.reducer,
  currentUser: currentUserSlice.reducer,
  followedUpUsers: followedUpUsersSlice.reducer,
  newClassroom: newClassroomSlice.reducer,
  newSchool: newSchoolSlice.reducer,
  notifications: notificationsSlice.reducer,
  program: programSlice.reducer,
  programPost: programPostSlice.reducer,
  school: schoolSlice.reducer,
  snackbar: snackbarSlice.reducer,
  taggedFile: taggedFileSlice.reducer,
  taggedPost: taggedPostSlice.reducer,
  templates: templatesSlice.reducer,
  userCalculators: userCalculatorsSlice.reducer,
  userDocuments: userDocumentsSlice.reducer,
  userProgramProgress: userProgramProgressSlice.reducer,
  users: usersSlice.reducer,
  feed: feedSlice.reducer,
  wizardContent: wizardContentSlice.reducer,
});

export const resetStore = createAction('store/reset');

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction
) => {
  if (action.type === resetStore.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function selectFeedState(rootState: RootState) {
  return rootState.feed;
}

export function selectActivityState(rootState: RootState) {
  return rootState.activity;
}

export function selectTaggedFileState(rootState: RootState) {
  return rootState.taggedFile;
}

export function selectTaggedPost(rootState: RootState) {
  return rootState.taggedPost;
}

export function selectCurrentUserState(rootState: RootState) {
  return rootState.currentUser;
}

export function selectClassroomIntegrationState(rootState: RootState) {
  return rootState.classroomIntegration;
}

export function selectClassroomsState(rootState: RootState) {
  return rootState.classrooms;
}

export function selectUsersState(rootState: RootState) {
  return rootState.users;
}

export function selectUserDocumentsState(rootState: RootState) {
  return rootState.userDocuments;
}

export function selectUserProgramProgressState(rootState: RootState) {
  return rootState.userProgramProgress;
}

export function selectFollowedUpUsersState(rootState: RootState) {
  return rootState.followedUpUsers;
}

export function selectSchoolState(rootState: RootState) {
  return rootState.school;
}

export function selectProgramState(rootState: RootState) {
  return rootState.program;
}

export function selectSnackbarState(rootState: RootState) {
  return rootState.snackbar;
}

export function selectNewClassroomState(rootState: RootState) {
  return rootState.newClassroom;
}

export function selectNotificationsState(rootState: RootState) {
  return rootState.notifications;
}

export function selectProgramPostState(rootState: RootState) {
  return rootState.programPost;
}

export function selectAppState(rootState: RootState) {
  return rootState.app;
}

export function selectNewSchoolState(rootState: RootState) {
  return rootState.newSchool;
}

export function selectTemplatesState(rootState: RootState) {
  return rootState.templates;
}

export function selectCommentState(rootState: RootState) {
  return rootState.comment;
}

export function selectWizardContentState(rootState: RootState) {
  return rootState.wizardContent;
}

export function selectUserCalculators(rootState: RootState) {
  return rootState.userCalculators;
}

export function selectCourses(rootState: RootState) {
  return Object.keys(rootState.courses.entities).map((course) => ({
    ...rootState.courses.entities[course]
  }))
}

export function selectCoursesLoading(rootState: RootState) {
  return rootState.courses.loading;
}

export * from './activity';
export * from './current-user';
export * from './tagged-file';
export * from './tagged-post';
export * from './classroom-integration';
export * from './classrooms';
export * from './school';
export * from './program';
export * from './snackbar';
export * from './users';
export * from './user-documents';
export * from './user-program-progress';
export * from './followed-up-users';
export * from './new-classroom';
export * from './notifications';
export * from './program-post';
export * from './new-school';
export * from './app';
export * from './templates';
export * from './comment';
export * from './feed';
export * from './user-calculators';
export * from './courses';
