import { useEffect, useState } from 'react';
import { Divider, Tooltip, styled } from '@mui/material';

const StyledTooltip = styled(Tooltip)`
  white-space: pre-line;
  font-size: 12px;
`;

const EmojiCounterStyles = styled('div')`
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  border-radius: 16px;
  min-width: 52px;
  display: flex;

  .emoji-wrapper {
    display: flex;
  }

  .emoji-container {
    margin-left: 6px;
    margin-right: 6px;
    display: flex;
    align-items: center;
  }

  .emoji {
    font-size: 16px;
    margin-right: 4px;
  }

  .divider {
    height: 20px;
    margin-top: 2px;
  }
`;

interface EmojiCounterProps {
  counters: { emoji: string; by: string }[];
}

export const EmojiCounter = ({ counters = [] }: EmojiCounterProps) => {
  const [emojiCounter, setEmojiCounter] = useState<{
    [key: string]: { count: number; by: string[] };
  }>({});

  useEffect(() => {
    const nextEmojiCounter = counters.reduce(
      (
        accumulator: { [key: string]: { count: number; by: string[] } },
        counter
      ) => {
        if (accumulator[counter.emoji]) {
          return {
            ...accumulator,
            [counter.emoji]: {
              count: accumulator[counter.emoji].count + 1,
              by: accumulator[counter.emoji].by.concat(counter.by),
            },
          };
        }

        return {
          ...accumulator,
          [counter.emoji]: { count: 1, by: [counter.by] },
        };
      },
      {}
    );

    setEmojiCounter(nextEmojiCounter);
  }, [counters]);

  const emojiEntries = Object.entries(emojiCounter);

  const renderEmojis = emojiEntries.map(([emoji, emojiData], index) => {
    const showDivider = emojiEntries.length > 2;
    const isLast = index === emojiEntries.length - 1;
    return (
      <div key={emoji} className="emoji-wrapper">
        <StyledTooltip title={<div>{emojiData.by.join('\n')}</div>}>
          <div className="emoji-container">
            <span role="img" className="emoji" aria-label="emoji">
              {emoji}
            </span>
            <span className="counter">{emojiData.count}</span>
          </div>
        </StyledTooltip>
        {showDivider && !isLast ? (
          <Divider orientation="vertical" className="divider" />
        ) : null}
      </div>
    );
  });

  return emojiEntries.length > 0 ? (
    <EmojiCounterStyles>{renderEmojis}</EmojiCounterStyles>
  ) : null;
};
