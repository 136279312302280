import { firebaseService } from '@xq/infrastructure';
import { EmailMessageObject } from '@xq/domain';
import { callWithRetry } from '@xq/shared/utils';

export class SendEmailUseCase {
  static execute(emails: EmailMessageObject[]) {
    const sendEmail = firebaseService.prepareFunctionCaller('sendEmail');
    return callWithRetry(() => sendEmail(emails));
  }
}
