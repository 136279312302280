import { z } from 'zod';
import isURL from 'validator/lib/isURL';
import isFQDN from 'validator/lib/isFQDN';
import isEmail from 'validator/lib/isEmail';

import { School } from '@xq/domain';

export const UpdateSchoolSchema = z.object({
  id: z.string(),
  name: z.string().min(3, 'must_contain_at_least_3_character(s)').optional(),
  domains: z
    .string()
    .refine((v) => (v ? isFQDN(v) : true), { message: 'school_invalid_domain' })
    .array()
    .optional(),
  state: z.string().optional(),
  city: z.string().optional(),
  website: z
    .string()
    .refine((v) => isURL(v), { message: 'invalid_url' })
    .optional(),
});

export const UpdateSchoolWithAdminEmailSchema = UpdateSchoolSchema.extend({
  adminEmail: z.string().refine((v) => (v ? isEmail(v) : true), {
    message: 'invalid_email',
  }),
});

export const UpdateDemoSchoolSchema = UpdateSchoolWithAdminEmailSchema.extend({
  demoTeacherWhiteList: z.string().array().optional(),
  demoStudentWhiteList: z
    .object({
      userEmail: z.string().refine((v) => (v ? isEmail(v) : true), {
        message: 'invalid_email',
      }),
      studentEmail: z.string().refine((v) => (v ? isEmail(v) : true), {
        message: 'invalid_email',
      }),
    })
    .array()
    .optional(),
});

export type UpdateSchoolRequestDto = z.infer<typeof UpdateSchoolSchema>;

export type UpdateSchoolResponseDto = School | null;
