import { useEffect } from 'react';
import { chunk, cond, stubTrue } from 'lodash';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

import {
  firebaseService,
  loggerService,
  subscriptionManager,
} from '@xq/infrastructure';
import { getUserRole, User } from '@xq/domain';
import {
  CollectionNames,
  FbSchoolDto,
  schoolMapper,
} from '@xq/shared/data-access';

import { schoolActions, useAppDispatch } from '../store';

type Props = {
  user: User;
};

export const useSchoolsSubscription = ({ user }: Props) => {
  const { email, roles } = user;
  const { isAdmin, isStudent, isTeacher, isViewer } = getUserRole(user);

  const dispatch = useAppDispatch();

  const queryCond = cond([
    [
      () => isAdmin,
      () => {
        const q = query(
          collection(firebaseService.db, CollectionNames.schools),
          where('domains', 'array-contains', email.split('@').pop())
        );

        return [q];
      },
    ],
    [
      () => isTeacher,
      () => {
        return [
          query(
            collection(firebaseService.db, CollectionNames.schools),
            where('domains', 'array-contains', email.split('@').pop())
          ),
        ];
      },
    ],
    [
      () => isStudent || isViewer,
      () => {
        return chunk(user.classroomIds, 10).map((ids) => {
          return query(
            collection(firebaseService.db, CollectionNames.schools),
            where('classroomsIds', 'array-contains-any', ids)
          );
        });
      },
    ],
    [stubTrue, () => undefined],
  ]);

  useEffect(() => {
    if (!email || !roles?.length) return;

    const queryList = queryCond();

    if (!queryList) return;

    const subscriptions = queryList.map((q) => {
      return onSnapshot(
        q,
        (data) => {
          const schoolData = [
            ...data.docs.map((doc) => {
              return schoolMapper.toDomain({
                ...doc.data(),
                id: doc.id,
              } as FbSchoolDto);
            }),
          ];
          dispatch(schoolActions.setMany(schoolData));
        },
        (e) => {
          loggerService.error('SCHOOLS SUBSCRIPTION ERROR: ', e);
        }
      );
    });

    subscriptionManager.add(subscriptions);

    return () => subscriptionManager.remove(subscriptions);
  }, [email, roles]);
};
