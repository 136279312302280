import { SnackbarKey } from 'notistack';

export enum SnackbarVariant {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum VerticalAnchorOrigin {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum HorizontalAnchorOrigin {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export type SnackbarOptions = {
  variant?: SnackbarVariant;
  anchorOrigin?: {
    vertical: VerticalAnchorOrigin;
    horizontal: HorizontalAnchorOrigin;
  };
  autoHideDuration?: number | null; // milliseconds
  action?: (key: SnackbarKey) => JSX.Element;
};

export type SnackbarState = {
  message: string;
  key?: string;
  dismissed?: boolean;
  options?: SnackbarOptions;
  dismissAll?: boolean;
};
