import { createSelector } from '@reduxjs/toolkit';
import { WizardContentType } from '@xq/domain';
import { wizardContentAdapter } from './wizard-content-slice';
import { selectWizardContentState } from '../index';

export const {
  selectAll: selectAllWizardContent,
  selectById: selectWizardContentById,
  selectEntities: selectWizardContentEntities,
} = wizardContentAdapter.getSelectors(selectWizardContentState);

export const selectWizardContentByType = createSelector(
  [selectAllWizardContent, (_, type: WizardContentType) => type],
  (state, type) => {
    return state.filter((c) => c.type === type);
  }
);
