import { useTranslation } from 'react-i18next';

import { Activity, AttachedFile, MathTagTypes, Document } from '@xq/domain';

import { useModal, useUserFiles } from '../context';
import { useAppSelector, selectTaggedFileById } from '../store';
import { StyledSingleTag } from './FeedAutoTags.styles';

type DocumentTagsProps = {
  attachedFile: AttachedFile;
  canDeleteTag: boolean;
  activity: Activity;
};

export const DocumentTags = ({
  attachedFile,
  canDeleteTag,
  activity,
}: DocumentTagsProps) => {
  // commented due to requirement to remove auto-tagging and tagging 23.10.2023
  return null;
  const { t } = useTranslation();
  const { onConfirm, dispatchModal } = useModal();
  // const { deleteMathTagFromFile } = useUserFiles();

  const tags = useAppSelector((state) =>
    selectTaggedFileById(state, attachedFile.id)
  );

  const deleteTag = async (tag: MathTagTypes) => {
    // await deleteMathTagFromFile({
    //   mathTagForDelete: tag,
    //   document: {
    //     ...attachedFile,
    //     autoTags: tags,
    //   } as Document,
    //   userId: activity.actor.id,
    // });
  };

  return (
    <>
      {tags.map((tag) => (
        <StyledSingleTag
          key={`${tag}-${attachedFile.id}`}
          label={tag}
          {...(canDeleteTag && {
            onDelete: () => {
              dispatchModal({ isOpen: true, bodyText: t('delete_mathTag') });
              onConfirm.current = () => deleteTag(tag as MathTagTypes);
            },
          })}
        />
      ))}
    </>
  );
};
