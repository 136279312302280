import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  isAnyOf,
} from '@reduxjs/toolkit';

import { Activity } from '@xq/domain';

import {
  deleteJournalActivity,
  editActivity,
  hideActivity,
  markForReview,
  postActivity,
  fetchActivities,
  fetchActivityById,
} from './activity-actions';

export const activityAdapter = createEntityAdapter<Activity>();
export const activitySlice = createSlice({
  name: 'activity',
  initialState: activityAdapter.getInitialState({
    loading: false,
  }),
  reducers: {
    addActivity: activityAdapter.addOne,
    removeActivity: activityAdapter.removeOne,
    removeActivities: activityAdapter.removeMany,
    resetActivities: activityAdapter.removeAll,
    updateEmojiReactionCount: (
      state,
      {
        payload: { activityId, emojiCount },
      }: PayloadAction<{ activityId: string; emojiCount: number }>
    ) => {
      const activity = state.entities[activityId];

      if (activity && activity.reaction_counts) {
        activity.reaction_counts.emoji = emojiCount;
      }
    },
    addCommentId: (
      state,
      { payload: { activityId } }: PayloadAction<{ activityId: string }>
    ) => {
      const activity = state.entities[activityId];

      if (activity) {
        activity.reaction_counts.comment += 1;
      }
    },
    removeCommentId: (
      state,
      { payload: { activityId } }: PayloadAction<{ activityId: string }>
    ) => {
      const activity = state.entities[activityId];

      if (activity) {
        activity.reaction_counts.comment -= 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActivities.fulfilled, (state, { payload }) => {
      activityAdapter.setMany(state, payload.activities);
      state.loading = false;
    });
    builder.addCase(fetchActivityById.fulfilled, (state, { payload }) => {
      activityAdapter.setOne(state, payload.activity);
      state.loading = false;
    });
    builder.addCase(fetchActivities.rejected, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(postActivity.fulfilled, (state, { payload }) => {
      return activityAdapter.setOne(state, payload.addedActivity);
    });
    builder.addCase(editActivity.fulfilled, (state, action) => {
      const {
        payload: { activityId, editedValue, attachedFiles },
      } = action;

      const activityToUpdate = state.entities[activityId];

      if (activityToUpdate) {
        activityToUpdate.attachedFiles = attachedFiles;
        activityToUpdate.message = editedValue;
      }
    });
    builder.addCase(deleteJournalActivity.fulfilled, (state, action) => {
      const {
        payload: { activityId },
      } = action;

      activityAdapter.removeOne(state, activityId);
    });
    builder.addCase(hideActivity.fulfilled, (state, action) => {
      const {
        payload: { activityId, hidden },
      } = action;

      const activityToUpdate = state.entities[activityId];

      if (activityToUpdate) {
        activityToUpdate.hidden = hidden;
      }
    });
    builder.addCase(markForReview.fulfilled, (state, action) => {
      const {
        payload: { activityId, isForReview },
      } = action;

      const activityToUpdate = state.entities[activityId];

      if (activityToUpdate) {
        activityToUpdate.isForReview = isForReview;
      }
    });
    builder.addMatcher(
      isAnyOf(fetchActivities.pending, fetchActivityById.pending),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(fetchActivities.rejected, fetchActivityById.rejected),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const activityActions = activitySlice.actions;
