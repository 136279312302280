import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { Activity, Document, FeedSlugs, Mention } from '@xq/domain';
import { loggerService } from '@xq/infrastructure';

import { FeedDialog } from '../FeedDialog/FeedDialog';
import { useFeed, useModal } from '../../context';
import { StyledPostOptions } from './ActionsMenu.styles';
import { ActionsMenu } from './ActionsMenu';
import { useActionsMenuState } from './useActionsMenuState';

type ActionsMenuProps = {
  activity: Activity;
  mentionSuggestions: Mention[];
};

export const PostActionsMenu = ({
  activity,
  mentionSuggestions,
}: ActionsMenuProps) => {
  const { t } = useTranslation();
  const { onConfirm, dispatchModal } = useModal();
  const { feedSlug } = useFeed();
  const { deletePost, editPost, setForReview } = useFeed();
  const { isDialogOpen, setIsDialogOpen, closeMenu, openMenu, anchorEl } =
    useActionsMenuState();

  const onEditPostClick = () => {
    setIsDialogOpen(true);
    closeMenu();
  };

  const onEditItem = async (message: string, documents: Document[]) => {
    if (message.length) {
      await editPost(activity, message, documents);
      setIsDialogOpen(false);
    }
  };

  const setForReviewState = async () => {
    await setForReview(activity);
    closeMenu();
  };

  const confirmDeletePost = async () => {
    dispatchModal({ loading: true });

    try {
      await deletePost(activity);
    } catch (e) {
      loggerService.error(e);
    }

    dispatchModal({ isOpen: false, loading: false });
  };

  const onDeletePostClick = () => {
    const bodyText =
      feedSlug === FeedSlugs.student
        ? t('delete_post_confirm_modal_text_journal')
        : t('delete_post_confirm_modal_text');

    onConfirm.current = confirmDeletePost;
    dispatchModal({
      isOpen: true,
      bodyText,
    });
    closeMenu();
  };
  const mappedAttachedFiles = useMemo(
    () =>
      activity.attachedFiles.map((file) => ({
        ...file,
        isFromDrive: true,
      })),
    [activity.attachedFiles]
  );

  return (
    <>
      <ActionsMenu
        closeMenu={closeMenu}
        openMenu={openMenu}
        anchorEl={anchorEl}
        isDialogOpen={isDialogOpen}
      >
        <MenuItem onClick={onEditPostClick} data-cy="edit-post">
          <CreateIcon style={{ marginRight: 10 }} fontSize="inherit" />
          <StyledPostOptions>{t('edit_post')}</StyledPostOptions>
        </MenuItem>
        <MenuItem onClick={onDeletePostClick} data-cy="delete-post">
          <DeleteOutlineIcon style={{ marginRight: 10 }} fontSize="inherit" />
          <StyledPostOptions>{t('delete_post')}</StyledPostOptions>
        </MenuItem>
        {feedSlug === FeedSlugs.student && (
          <MenuItem onClick={setForReviewState}>
            <CheckCircleOutlineIcon
              style={{ marginRight: 10 }}
              fontSize="inherit"
            />
            <StyledPostOptions>
              {activity.isForReview
                ? t('unmark_for_review')
                : t('mark_for_review')}
            </StyledPostOptions>
          </MenuItem>
        )}
      </ActionsMenu>
      {isDialogOpen && (
        <FeedDialog
          editedItem={{
            id: activity.id,
            text: activity.message,
            userId: activity.actor.id,
            createdAt: activity.time || '',
          }}
          open={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
          editItem={onEditItem}
          mentionSuggestions={mentionSuggestions}
          attachedFiles={mappedAttachedFiles}
          showUploadButtons
        />
      )}
    </>
  );
};
