import { useState } from 'react';
import { uniq } from 'lodash';

import { loggerService } from '@xq/infrastructure';

import styles from './add-users-to-classroom.module.scss';
import { InputSection } from './InputSection';
import { EmailsList } from './EmailsList';
import { emailFullValidation, InvalidEmail } from '../validations';
import { ValidateEmailsResponseDto } from '@xq/shared/data-access';

interface AddUsersToClassroomProps {
  addedEmails: string[];
  usedEmails: string[];
  invalidEmails: InvalidEmail[];
  validateEmails: (emails: string[]) => Promise<ValidateEmailsResponseDto>;
  roleLabel: string;
  roleLabelPlural: string;
  inputTitle?: string;
  inputDescription?: string;
  domains?: string[];
  addEmails: (emails: string[]) => void;
  removeEmails: (emails: string[]) => void;
  showImportCSV?: boolean;
  setInvalidEmails: (emails: InvalidEmail[]) => void;
  wrongRoleError: string;
  showEmailsListOnlyWhenEmailsArePresent?: boolean;
  predefinedEmails?: string[];
}

export function AddEmails({
  addedEmails,
  usedEmails,
  invalidEmails,
  validateEmails,
  roleLabel,
  roleLabelPlural,
  inputTitle = '',
  inputDescription = '',
  addEmails,
  removeEmails,
  showImportCSV = false,
  setInvalidEmails,
  wrongRoleError,
  domains = [],
  showEmailsListOnlyWhenEmailsArePresent,
  predefinedEmails = [],
}: AddUsersToClassroomProps) {
  const [validationPending, setValidationPending] = useState(false);
  const [validEmails, setValidEmails] = useState<string[]>([]);

  const handleEnter = async (inputEmails: string[]) => {
    setValidationPending(true);

    try {
      const { valid, inValid } = await emailFullValidation({
        newEmails: inputEmails,
        backendValidation: validateEmails,
        previousInvalidEmails: invalidEmails,
        domains,
        addedEmails,
        emailsInUse: usedEmails,
      });

      addEmails(valid);
      setValidEmails((prevState) => prevState?.concat(valid));
      setInvalidEmails([...invalidEmails, ...inValid]);
    } catch (e) {
      loggerService.error(e);
    } finally {
      setValidationPending(false);
    }
  };

  const handleInvalidEmailDelete = (invalidEmail: InvalidEmail) => {
    setInvalidEmails(
      [...invalidEmails].filter((item) => item.email !== invalidEmail.email)
    );
  };

  const handleValidEmailDelete = (email: string) => {
    removeEmails([email]);
    setValidEmails((prevState) => prevState.filter((e) => e !== email));
  };

  const showEmailsList = () =>
    showEmailsListOnlyWhenEmailsArePresent
      ? validEmails.length || invalidEmails.length
      : true;

  return (
    <div className={styles.container}>
      <InputSection
        title={inputTitle}
        description={inputDescription}
        handleEnter={handleEnter}
        loading={validationPending}
        showImportCSV={showImportCSV}
      />
      {showEmailsList() ? (
        <EmailsList
          roleLabel={roleLabel}
          roleLabelPlural={roleLabelPlural}
          validEmails={uniq([...validEmails, ...predefinedEmails])}
          invalidEmails={invalidEmails}
          handleInvalidEmailDelete={handleInvalidEmailDelete}
          handleValidEmailDelete={handleValidEmailDelete}
          wrongRoleError={wrongRoleError}
        />
      ) : null}
    </div>
  );
}
