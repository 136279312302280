import { StreamClient } from 'getstream';

import { FeedSlugs, getJournalId, UserTagType } from '@xq/domain';
import { FeedService, tagsService } from '@xq/infrastructure';

type DeletePostJournalFeed = {
  postData: {
    id: string;
    userId: string;
    classroomId: string;
    programId: string;
  };
  client: StreamClient;
};

export class DeletePostJournalFeedUseCase {
  async execute({ postData, client }: DeletePostJournalFeed): Promise<void> {
    const feedService = FeedService.getInstance(client);

    await feedService.deletePost({
      id: postData.id,
      userId: postData.userId,
      feedId: getJournalId({
        userId: postData.userId,
        classroomId: postData.classroomId,
        programId: postData.programId,
      }),
      feedSlug: FeedSlugs.student,
    });

    await tagsService.removeUserTagsFromPost([
      {
        resourceId: postData.id,
        userId: postData.userId,
        classroomId: postData.classroomId,
        programId: postData.programId,
        tagType: UserTagType.JournalTagPosts,
        tags: [],
      },
    ]);
  }
}
