import { MouseEvent, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Avatar, Badge } from '@mui/material';
import { cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import {
  TopBar,
  RestrictView,
  useAppSelector,
  selectAllClassrooms,
  selectCurrentUserState,
  MemoizedNotificationsDrawer,
  selectNotificationsUnreadCount,
  useAppDispatch,
  programPostActions,
  selectFilteredTeacherClassrooms,
  selectIsFullscreen,
} from '@xq/web-components';

import {
  ClassroomsScopes,
  getUserRole,
  NotificationsScopes,
} from '@xq/domain';
import { TEACHER_APP_ROUTES } from '@xq/web-config';

import styles from './side-bar.module.scss';
import LogoSVG from '../../assets/svg/xq-small-logo.svg';
import NotificationsIcon from '../../assets/svg/notifications-icon.svg';
import ResourcesIcon from '../../assets/svg/resources-icon.svg';
import { ClassroomTooltip } from './ClassroomTooltip/ClassroomTooltip';
import { TooltipTitle } from './ClassroomTooltip/TooltipTitle';
import { TeacherDropdownMenu } from './TeacherDropdownMenu/TeacherDropdownMenu';

type SideBarProps = {
  photoUrl: string;
  maxHeight?: string;
};

export const SideBar = ({ photoUrl, maxHeight }: SideBarProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const currentUser = useAppSelector(selectCurrentUserState);
  const classrooms = useAppSelector(selectAllClassrooms);
  const unreadCount = useAppSelector(selectNotificationsUnreadCount);
  const filteredClassrooms = useAppSelector(selectFilteredTeacherClassrooms);
  const isFullscreen = useAppSelector(selectIsFullscreen);

  const { isAdmin, isTeacher } = getUserRole(currentUser);

  const openDropdownMenu = (e: MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const isHidden = isFullscreen || (!currentUser && !isAdmin);

  return isHidden ? null : (
    <TopBar
      maxHeight={maxHeight}
      flexDirection="col"
      className={styles.teacherNav}
    >
      <div className={styles.topNav}>
        <NavLink
          to={
            filteredClassrooms.length
              ? TEACHER_APP_ROUTES.getDashboard(filteredClassrooms[0]?.id)
              : TEACHER_APP_ROUTES.getResources()
          }
        >
          <img
            className={styles.sideBarIcon}
            src={LogoSVG}
            alt={t('XQ_logo')}
          />
        </NavLink>

        <RestrictView
          scopes={[NotificationsScopes.CAN_VIEW]}
        >
          <Badge
            badgeContent={unreadCount}
            color="secondary"
            max={99}
            classes={{ badge: styles.badge }}
          >
            <NavLink
              className={cx(styles.iconBackground, {
                [styles.activeBackground]: openDrawer,
              })}
              to={location.pathname}
              onClick={() => setOpenDrawer(true)}
              data-cy="notification-btn"
            >
              <img
                className={styles.sideBarIcon}
                src={NotificationsIcon}
                alt={t('notifications_icon')}
              />
            </NavLink>
          </Badge>

          <i className={styles.separator} />
        </RestrictView>

        <RestrictView scopes={[ClassroomsScopes.CAN_VIEW_OWN]}>
          <div data-cy="classroom-nav" className={styles.classroomsContainer}>
            {filteredClassrooms.map((classroom) => (
              <NavLink
                key={classroom.id}
                to={TEACHER_APP_ROUTES.getDashboard(classroom.id)}
                className={cx(styles.iconBackground, styles.classroomIcon, {
                  [styles.activeClassroomIcon]: location.pathname.includes(
                    TEACHER_APP_ROUTES.getClassroom(classroom.id)
                  ),
                })}
                data-cy="classroom-nav"
              >
                <ClassroomTooltip
                  key={`${classroom.id}-tooltip`}
                  active={location.pathname.includes(
                    TEACHER_APP_ROUTES.getClassroom(classroom.id)
                  )}
                  title={
                    <TooltipTitle
                      classroomName={classroom.name}
                      studentsCount={classroom.participantEmails.length}
                    />
                  }
                >
                  <div data-cy="classroom-short-name-icon">
                    {classroom.shortName}
                  </div>
                </ClassroomTooltip>
              </NavLink>
            ))}
          </div>
        </RestrictView>
      </div>

      <div className={styles.bottomNav}>
        {isAdmin || isTeacher || classrooms?.length ? (
          <NavLink
            className={({ isActive }) =>
              cx(styles.iconBackground, {
                [styles.activeBackground]: isActive,
              })
            }
            to={TEACHER_APP_ROUTES.getResources()}
            onClick={() => dispatch(programPostActions.setSelectedSlug(''))}
          >
            <img
              className={styles.sideBarIcon}
              src={ResourcesIcon}
              alt={t('resources_icon')}
            />
          </NavLink>
        ) : null}

        <NavLink
          onClick={(е) => openDropdownMenu(е)}
          className={styles.profileIconBackground}
          to="#"
          data-cy="user-profile"
        >
          <Avatar
            className={styles.iconProfile}
            src={photoUrl}
            alt={t('user_profile_photo')}
          />
        </NavLink>
        <TeacherDropdownMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        <MemoizedNotificationsDrawer
          isOpen={openDrawer}
          setIsOpen={setOpenDrawer}
          drawerAnchor="right"
          paths={{
            ...TEACHER_APP_ROUTES,
            getJournal: (id?: string) =>
              TEACHER_APP_ROUTES.getJournalPhase(id, '0', '0', '-1', '-1'),
          }}
        />
      </div>
    </TopBar>
  );
};
