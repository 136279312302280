import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetCurrentUserGoogleClassroomsUseCase } from '@xq/usecases';
import { loggerService } from '@xq/infrastructure';

import { GoogleClassroom } from './classroom-integration.interfaces';
import { GoogleClassroomRole } from '@xq/shared/data-access';

export const fetchCurrentUserGoogleClassrooms = createAsyncThunk(
  'classroom-integration/fetchCurrentUserGoogleClassrooms',
  async () => {
    const getClassroomsUseCase = new GetCurrentUserGoogleClassroomsUseCase();

    try {
      const response = await getClassroomsUseCase.execute([
        GoogleClassroomRole.Students,
        GoogleClassroomRole.Teachers,
      ]);

      return response.data as GoogleClassroom[];
    } catch (e) {
      loggerService.error(e);

      throw e;
    }
  }
);
