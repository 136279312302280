import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@mui/icons-material/Description';
import LockIcon from '@mui/icons-material/Lock';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ConstructionIcon from '@mui/icons-material/Construction';

import { getUserRole } from '@xq/domain';
import {
  AdministrationTabs,
  DropDownMenu,
  selectCurrentUserState,
  selectIsImpersonating,
  SystemCheckModalBody,
  useApi,
  useAppSelector,
  useModal,
  XqIconPrimaryColor,
} from '@xq/web-components';
import { FAQ_URL, TEACHER_APP_ROUTES } from '@xq/web-config';

import { ClassroomSetupIconPrimaryColor } from '../ClassroomSetupIcon/ClassroomSetupIcon';

type UserDropdownMenuProps = {
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
};

export const TeacherDropdownMenu = ({
  anchorEl,
  setAnchorEl,
}: UserDropdownMenuProps) => {
  const { t } = useTranslation();
  const { logout } = useApi();
  const currentUser = useAppSelector(selectCurrentUserState);
  const isImpersonating = useAppSelector(selectIsImpersonating);

  const { dispatchModal, onConfirm } = useModal();
  const { isAdmin, isTeacher } = getUserRole(currentUser);

  const classroomAdministrationPath = isAdmin
    ? TEACHER_APP_ROUTES.getAdministration(AdministrationTabs.schools)
    : TEACHER_APP_ROUTES.getAdministration(AdministrationTabs.classrooms);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    await logout();
  };

  const startDiagnostic = () => {
    dispatchModal({
      hideCancel: true,
      resetOnConfirm: false,
      titleText: t('checking_system'),
      bodyText: <SystemCheckModalBody />,
      showActionButton: true,
      actionButtonText: t('continue'),
      showIcon: false,
      isOpen: true,
      width: 'min(100vw, 600px)',
    });
  };

  return (
    <DropDownMenu.StyledMenu
      keepMounted
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={closeMenu}
      transformOrigin={{
        vertical: 350,
        horizontal: 0,
      }}
    >
      {(isTeacher || isAdmin) && !isImpersonating && (
        <DropDownMenu.StyledMenuItem onClick={closeMenu}>
          <DropDownMenu.StyledLink
            to={classroomAdministrationPath}
            data-cy="classroom-administration"
          >
            <ClassroomSetupIconPrimaryColor fontSize={'large'} />
            {t('classroom_administration')}
          </DropDownMenu.StyledLink>
        </DropDownMenu.StyledMenuItem>
      )}

      <div>
        <DropDownMenu.StyledSeparator variant="middle" />
        <DropDownMenu.StyledAnchorTag
          href="https://xqsuperschool.org/rethinktogether/"
          target="_blank"
          rel="noreferrer"
        >
          <DropDownMenu.StyledMenuItem>
            <XqIconPrimaryColor fontSize={'large'} />
            {t('xq_rethink_together')}
          </DropDownMenu.StyledMenuItem>
        </DropDownMenu.StyledAnchorTag>
      </div>

      <DropDownMenu.StyledMenuItem>
        <DropDownMenu.StyledAnchorTag
          target="_blank"
          rel="noopener"
          href={TEACHER_APP_ROUTES.getTermsOfService()}
        >
          <DescriptionIcon fontSize="small" />
          {t('terms_of_service')}
        </DropDownMenu.StyledAnchorTag>
      </DropDownMenu.StyledMenuItem>
      <DropDownMenu.StyledMenuItem>
        <DropDownMenu.StyledAnchorTag
          target="_blank"
          rel="noopener"
          href={TEACHER_APP_ROUTES.getPrivacyPolicy()}
        >
          <LockIcon fontSize="small" />
          {t('privacy_policy')}
        </DropDownMenu.StyledAnchorTag>
      </DropDownMenu.StyledMenuItem>

      <DropDownMenu.StyledMenuItem>
        <DropDownMenu.StyledAnchorTag
          href={FAQ_URL}
          target="_blank"
          rel="noreferrer"
        >
          <HelpOutlineIcon fontSize="small" />
          {t('faq')}
        </DropDownMenu.StyledAnchorTag>
      </DropDownMenu.StyledMenuItem>

      {isTeacher ? (
        <DropDownMenu.StyledMenuItem
          onClick={() => {
            onConfirm.current = () => startDiagnostic();
            dispatchModal({
              hideCancel: true,
              resetOnConfirm: false,
              titleText: t('system_diagnostic_title'),
              bodyText: t('system_diagnostic_info'),
              showActionButton: true,
              actionButtonText: t('start_diagnostic'),
              showIcon: false,
              isOpen: true,
              width: 'min(100vw, 700px)',
            });
          }}
        >
          <ConstructionIcon fontSize="small" />
          {t('system_diagnostic')}
        </DropDownMenu.StyledMenuItem>
      ) : null}

      <DropDownMenu.StyledMenuItem>
        <DropDownMenu.StyledLink to={TEACHER_APP_ROUTES.getContactHelpDesk()}>
          <MailOutlineIcon fontSize="small" />
          {t('contact_XQ')}
        </DropDownMenu.StyledLink>
      </DropDownMenu.StyledMenuItem>

      <DropDownMenu.StyledMenuItem onClick={handleLogOut}>
        <PowerSettingsNewIcon fontSize="small" />
        {t('log_out')}
      </DropDownMenu.StyledMenuItem>
      <DropDownMenu.StyledSeparator />
      <DropDownMenu.StyledMenuItem
        style={{ paddingLeft: '8px', paddingBottom: '0px' }}
      >
        <DropDownMenu.StyledUserImage
          src={currentUser?.photoUrl || ''}
          alt={t('user_profile_photo')}
        />
        <div>
          <DropDownMenu.StyledTruncateData>
            {currentUser?.displayName}
          </DropDownMenu.StyledTruncateData>
          <DropDownMenu.StyledTruncateData>
            {currentUser?.email}
          </DropDownMenu.StyledTruncateData>
        </div>
      </DropDownMenu.StyledMenuItem>
    </DropDownMenu.StyledMenu>
  );
};
