import { StreamClient } from 'getstream';

import {
  UnsubscribeFromFeedUseCase,
  SubscribeToFeedUseCase,
  HandleNewActivityProps,
} from './index';

interface SubscriptionProps {
  feedClient: StreamClient;
  feedId: string;
  activitiesCallback: ({ data, feedId }: HandleNewActivityProps) => void;
}

export class HandleJournalSubscriptionUseCase {
  static execute({
    feedClient,
    feedId,
    activitiesCallback,
  }: SubscriptionProps) {
    if (feedId) {
      SubscribeToFeedUseCase.execute(feedClient, activitiesCallback, feedId);

      return () => {
        UnsubscribeFromFeedUseCase.execute(feedClient);
      };
    }
    return;
  }
}
