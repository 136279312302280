import { cond } from 'lodash';
import {
  emailDuplicatedValidation,
  EmailErrors,
  emailFormatValidation,
  InvalidEmail,
  ValidationTestResult,
} from './index';

type ValidationProps = {
  email: string;
  addedEmails: string[];
  newEmails: string[];
  emailsInUse: string[];
  validEmails: string[];
};

const validations = cond<ValidationProps, EmailErrors | null>([
  [
    ({ email }) => !emailFormatValidation(email),
    () => EmailErrors.invalid_format,
  ],
  [
    ({ email, newEmails, addedEmails, validEmails }) =>
      emailDuplicatedValidation(email, newEmails, addedEmails, validEmails),
    () => EmailErrors.duplicated,
  ],
  [
    ({ email, emailsInUse }) => emailsInUse.includes(email),
    () => EmailErrors.wrong_role,
  ],
  [() => true, () => null],
]);

type EmailUniqueValidationProps = {
  newEmails: string[];
  previousInvalidEmails: InvalidEmail[];
  addedEmails: string[];
  emailsInUse: string[];
};

export const emailUniqueValidation = (
  props: EmailUniqueValidationProps
): ValidationTestResult => {
  const { newEmails, emailsInUse, addedEmails, previousInvalidEmails } = props;
  const validEmails: string[] = [];
  const invalidEmails: InvalidEmail[] = [];

  newEmails?.forEach((email) => {
    if (!previousInvalidEmails.map((em) => em.email).includes(email)) {
      const emailError = validations({
        email,
        emailsInUse,
        newEmails,
        addedEmails,
        validEmails,
      });

      if (emailError) {
        invalidEmails.push({ email, error: emailError });
      } else {
        validEmails.push(email);
      }
    }
  });

  return {
    valid: validEmails,
    inValid: invalidEmails,
  };
};
