import { PropsWithChildren } from 'react';
import { styled, CircularProgress } from '@mui/material';

interface LoadingOverlayProps {
  isLoading: boolean;
  height?: string;
  width?: string;
}

type SpinnerStyleProps = LoadingOverlayProps;

export const SpinnerStyle = styled('div')<SpinnerStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(p) => p.height ?? '100vh'};
  width: ${(p) => p.width ?? '100vw'};
  background-color: #fff;
  position: absolute;
  z-index: ${(p) => (p.isLoading ? 1000 : -1)};
  opacity: ${(p) => (p.isLoading ? 1 : 0)};
  transition: opacity 0.3s;
  top: 0;
  left: 0;
`;

export const LoadingOverlay = ({
  isLoading,
  children,
  width,
  height,
}: PropsWithChildren<LoadingOverlayProps>) => {
  return (
    <>
      {children}
      <SpinnerStyle width={width} height={height} isLoading={isLoading}>
        <CircularProgress aria-label="loading-overlay-progress" size={80} />
      </SpinnerStyle>
    </>
  );
};
