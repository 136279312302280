import { StreamClient, StreamUser } from 'getstream';

import { FeedSlugs, Actor, User } from '@xq/domain';
import { FeedService, loggerService } from '@xq/infrastructure';

export class StreamFeedCheckUseCase {
  async execute(feedClient: StreamClient, currentUser: User) {
    const feedId = `${currentUser.uid}-test-feed`;
    try {
      const feedService = FeedService.getInstance(feedClient);
      feedService.createJournalFeed([], FeedSlugs.phaseJournal, feedId);

      const res = await feedService.addActivity(
        {
          id: 'testActivity',
          message: `testing user ${currentUser.uid}`,
          actor: feedClient.currentUser as unknown as Actor,
          attachedFiles: [],
          autoTags: [],
          classroomId: '',
          feedSlug: FeedSlugs.phaseJournal,
          isForReview: false,
          object: {},
          reaction_counts: { comment: 0, emoji: 0 },
          verb: 'add',
        },
        feedClient.currentUser as StreamUser
      );

      await feedService.deletePost({
        id: res.id,
        feedId,
        userId: currentUser.uid,
        feedSlug: FeedSlugs.phaseJournal,
      });
    } catch (e) {
      loggerService.error(
        `System check stream error isSystemCheckError for user with ID ${currentUser.uid}: ${e}`,
        { systemCheckError: true, userId: currentUser.uid }
      );
      return false;
    }

    return true;
  }
}
