import { v4 } from 'uuid';
import { isArray } from 'lodash';
import { drive_v3 } from 'googleapis';

import { MimeTypes, Document, DocumentSchema } from '@xq/domain';

import { FbFileDto, FbFileSchema } from '../dto';

export class DocumentMapper {
  static toDomain(file: FbFileDto): Document {
    const parseCategories = (categories?: string | number[]): number[] => {
      if (typeof categories === 'string') {
        return JSON.parse(categories) as number[];
      }

      if (isArray(categories)) {
        return categories;
      }

      return [];
    };

    return DocumentSchema.parse({
      ...file,
      properties: {
        isForReview: !!file.properties?.isForReview,
        templateId: file.properties?.templateId ?? null,
        isTemplate: !!file.properties?.isTemplate,
        categories: parseCategories(file.properties?.categories),
      },
    });
  }

  static toPersistence(document: Document): FbFileDto {
    return FbFileSchema.parse(document);
  }

  static fromLocal(file: File): Document {
    const iconLink = `https://drive-thirdparty.googleusercontent.com/16/type/${file.type}`;

    return DocumentSchema.parse({
      name: file.name,
      mimeType: file.type as MimeTypes,
      id: v4(),
      iconLink,
      webViewLink: '',
      size: file.size.toString(),
      createdTime: new Date().toString(),
      modifiedTime: new Date().toString(),
      ownedByMe: true,
      parents: [],
      autoTags: [],
      properties: {
        isForReview: false,
        templateId: null,
        isTemplate: false,
        categories: [],
      },
    });
  }

  static fromDrive(fileFromDrive: drive_v3.Schema$File): Document {
    const {
      name,
      mimeType,
      id,
      iconLink,
      webViewLink,
      size,
      createdTime = '',
      modifiedTime = '',
      ownedByMe,
      parents,
      properties,
    } = fileFromDrive;

    return DocumentSchema.parse({
      name,
      mimeType: mimeType as MimeTypes,
      id,
      iconLink: iconLink ?? '',
      webViewLink: webViewLink ?? '',
      size: size ?? '',
      createdTime: createdTime,
      modifiedTime: modifiedTime,
      ownedByMe: ownedByMe ?? false,
      parents: parents ?? [],
      autoTags: [],
      ownerId: '',
      properties: {
        isForReview: properties?.['isForReview'] === 'true',
        templateId: properties?.['templateId'] ?? null,
        isTemplate: !!properties?.['isTemplate'],
        categories: properties?.['categories']
          ? JSON.parse(properties['categories'])
          : [],
      },
    });
  }
}
