import { Tooltip } from '@mui/material';

type TooltipExplanationProps = {
  title: string;
  body: string;
  children: JSX.Element;
};

export const TooltipExplanation = ({
  title,
  body,
  children,
}: TooltipExplanationProps) => {
  return (
    <Tooltip
      title={
        <div style={{ margin: 4 }}>
          <b>{title}</b>
          <p style={{ whiteSpace: 'break-spaces' }}>{body}</p>
        </div>
      }
      role="banner"
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            color: 'var(--white)',
            borderRadius: '4px',
            marginBottom: 0,
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
