export enum ReactionKinds {
  comment = 'comment',
  emoji = 'emoji',
}

export interface Reaction {
  kind: ReactionKinds;
  body: string;
  creatorId: string;
  id: string;
}
