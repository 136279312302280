import { useEffect } from 'react';
import {
  query,
  collection,
  Query,
  where,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore';
import { chunk } from 'lodash';

import { CollectionNames } from '@xq/shared/data-access';
import {
  firebaseService,
  loggerService,
  subscriptionManager,
} from '@xq/infrastructure';
import { FbUserDto, UserMapper } from '@xq/shared/data-access';
import { allEqual } from '@xq/shared/utils';
import { MathTagTypes } from '@xq/domain';

import {
  taggedFileActions,
  TaggedFileState,
  taggedPostActions,
  useAppDispatch,
  usersActions,
} from '../store';
import { useMemoCompare } from './useMemoCompare';

export const extractTaggable = (
  extractCallback: (extracted: TaggedFileState) => void,
  userId: string,
  taggable?: Record<string, Record<string, MathTagTypes[]>> | null
) => {
  if (taggable) {
    const payload = Object.values<Record<string, MathTagTypes[]>>(
      taggable
    ).reduce((acc, curr) => ({ ...acc, ...curr }), {});

    extractCallback({
      userId,
      files: payload,
    });
  }
};

type Props = {
  userIds: string[];
};

const userMapper = new UserMapper();

export const useStudentsSubscriptionByIds = ({ userIds }: Props) => {
  const dispatch = useAppDispatch();
  const shouldCreateQuery = useMemoCompare(userIds, (prev, next) => {
    if (!prev) {
      return false;
    }

    return allEqual(next, prev);
  });

  useEffect(() => {
    const queries = chunk(userIds, 10).map((ids: string[]) =>
      query(
        collection(firebaseService.db, CollectionNames.users),
        where('uid', 'in', ids)
      )
    );

    const subscriptions: Unsubscribe[] = [];

    const subscribe = (q: Query) => {
      const sub = onSnapshot(
        q,
        (data) =>
          data.docs.forEach((doc) => {
            const userData = doc.data() as FbUserDto;

            if (!userData) {
              return;
            }

            extractTaggable(
              (extracted) => dispatch(taggedFileActions.setOne(extracted)),
              userData.uid,
              {
                ...userData.feedTagFilesPerClassroom,
                ...userData.journalTagFilesPerClassroom,
              }
            );

            extractTaggable(
              (extracted) =>
                dispatch(taggedPostActions.addTaggedPost(extracted.files)),
              userData.uid,
              userData.feedTagPostsPerClassroom
            );

            dispatch(
              usersActions.userUpdated({
                id: userData.uid,
                changes: userMapper.toDomain(userData),
              })
            );
          }),
        (e) => {
          loggerService.error('STUDENTS SUBSCRIPTION ERROR: ', e);
        }
      );

      subscriptions.push(sub);
    };

    queries.forEach(subscribe);
    subscriptionManager.add(subscriptions);

    return () => subscriptionManager.remove(subscriptions);
  }, [shouldCreateQuery]);
};
