import { KeyboardEvent, PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { cx } from '@emotion/css';

import styles from './add-users-to-classroom.module.scss';
import { ImportCSVButton } from '../ImportCSVButton/ImportCSVButton';

interface InputSectionProps {
  handleEnter: (inputEmails: string[]) => void;
  title: string;
  description: string;
  loading: boolean;
  showImportCSV: boolean;
}

export const InputSection = ({
  handleEnter,
  title,
  description,
  loading,
  showImportCSV,
}: PropsWithChildren<InputSectionProps>) => {
  const { t } = useTranslation();
  const ref = useRef({ value: '' });

  return (
    <div className={styles.addUsers}>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
      <div className={styles.emailInputContainer}>
        <TextField
          className={styles.textField}
          label={t('email_addresses')}
          inputRef={ref}
          helperText={t('add_emails_seperated_by_commas')}
          InputProps={{
            onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
              const value = event.currentTarget.value;

              if (event.code === 'Enter' && value?.trim().length) {
                event.preventDefault();

                const inputEmails = value
                  ?.toString()
                  .split(/[\n, ]+/)
                  .map((x: string) => x.trim())
                  .filter((x: string) => x !== '');

                handleEnter(inputEmails);
                ref.current.value = '';
              }
            },
          }}
          multiline
          fullWidth
        />
        <CircularProgress
          size={20}
          className={cx(styles.loadingIndicator, {
            [styles.hidden]: !loading,
          })}
          aria-label="input-section-progress"
        />
        {showImportCSV && <ImportCSVButton onImport={handleEnter} />}
      </div>
    </div>
  );
};
