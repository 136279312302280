import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  loggerService,
  ToggleMarkForReviewFileProps,
  UserRepository,
} from '@xq/infrastructure';
import { LogAnalyticsEventUseCase } from '@xq/usecases';

export const toggleMarkForReview = createAsyncThunk(
  'user-documents/toggleMarkForReview',
  async (payload: ToggleMarkForReviewFileProps) => {
    const userRepo = new UserRepository();

    try {
      await userRepo.toggleMarkForReviewFile(payload);

      if (payload.isForReview) {
        LogAnalyticsEventUseCase.execute({
          number_of_mark_documents_for_review: {},
        });
      }

      return payload;
    } catch (e) {
      loggerService.error(e);

      throw e;
    }
  }
);
