import {useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as calcIcon } from '../../assets/svg/desmost-icon.svg';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider, Icon,
} from '@mui/material';

import {
  decodeEntities,
  DesmosDialog,
  RestrictView,
  selectCurrentUserState,
  useAppSelector,
} from '@xq/web-components';
import { Calculator, CalculatorScopes, getUserRole, User } from '@xq/domain';
import { MarkForReview } from '@xq/web-components/MarkForReview/MarkForReview';

import styles from './student-calculators-accordion.module.scss';
import { FileForReviewLabel } from '@xq/web-components/FileForReviewLabel/FileForReviewLabel';
import { NoFilesLabel } from '@xq/web-components/NoFilesLabel/NoFilesLabel';
import { WordPressService } from "@xq/infrastructure";

const wpService = new WordPressService();

type StudentCalculatorProps = {
  student: User;
  calculators: Calculator[];
  markForReview: (calculator: Calculator, userId: string) => void;
};

export const StudentCalculatorsAccordion = ({
  student,
  calculators,
  markForReview,
}: StudentCalculatorProps) => {
  const { t } = useTranslation();
  const [isDesmosDialogOpen, setIsDesmosDialogOpen] = useState<boolean>(false);
  const [openedCalculator, setOpenedCalculator] = useState<Calculator | null>(null);
  const [content, setContent] = useState<string>('');
  const currentUser = useAppSelector(selectCurrentUserState);
  const { isViewer } = getUserRole(currentUser);

  const calculatorsForReview = useMemo(
    () => calculators.filter((d) => !!d?.forReview).length,
    [calculators]
  );

  const openCalculatorHandler = async (calculator: Calculator) => {
    // TODO this can be done with a selector no need to fech again!!!
    const post = await wpService.fetchPostById(`${calculator.wpPostId}`);
    setContent(post.content.rendered);
    setOpenedCalculator(calculator);
    setIsDesmosDialogOpen(true);
  }

  return (
    <>
      <Accordion
        elevation={0}
        classes={{
          expanded: styles.accordionExpanded,
        }}
      >
        <AccordionSummary
          classes={{
            expanded: styles.accordionSummaryExpanded,
            content: styles.summaryContent,
          }}
          sx={{
            pointerEvents: !calculators.length ? 'none' : 'auto',
          }}
          expandIcon={calculators.length ? <ExpandMoreIcon /> : null}
        >
          <Avatar
            className={styles.studentAvatar}
            src={student.photoUrl ?? ''}
          />
          <div className={styles.studentName}>{student.displayName}</div>
          {!!calculatorsForReview && <FileForReviewLabel text={`${calculatorsForReview} CALCULATORS FOR REVIEW`} count={calculatorsForReview} />}
          {!calculators.length && <NoFilesLabel text={`0 CALCULATORS`} />}
        </AccordionSummary>

        {openedCalculator && (
          <DesmosDialog
            title={openedCalculator.title}
            content={content}
            isOpen={isDesmosDialogOpen}
            desmosCalculator={openedCalculator.calculatorState}
            closeDialog={() => setIsDesmosDialogOpen(false)}
          />
        )}

        <AccordionDetails className={styles.accordionDetails}>
          {calculators.map((calc) => (
            <div key={calc.id} className={styles.documentRow}>
              <div className={styles.fileImageContainer}>
                <Icon component={calcIcon} />
              </div>

              <div
                className={styles.fileInfoContainer}
              >
                <span
                  className={styles.fileName}
                  onClick={() => openCalculatorHandler(calc)}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {decodeEntities(calc.title)}
                </span>
                <div className={styles.fileModifiedTime}>
                  {t('updated_on')}{' '}
                  {format(new Date(calc.updatedAt), 'MM/dd/yyyy h:mm')}
                </div>
              </div>
              <RestrictView scopes={[CalculatorScopes.CAN_MARK_CALCULATOR_FOR_REVIEW]}>
                {calc?.forReview && (
                  <MarkForReview
                    isForReview={Boolean(calc?.forReview)}
                    onMarkForReview={() => markForReview(calc, student.uid)}
                    isDisabled={isViewer}
                  />
                )}
              </RestrictView>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
      <Divider />
    </>
  );
};
