import { Program } from '@xq/domain';

const createdAt = new Date().toISOString();
const modifiedAt = new Date().toISOString();

export const programs: Program[] = [
  {
    createdAt,
    modifiedAt,
    slug: 'launch',
    title: 'Launch',
    courseId: 'ALG',
    id: '0VXp1SjJcBWChyAAM1g8',
    isActive: true,
  },
  {
    createdAt,
    modifiedAt,
    slug: 'close-enough',
    title: 'Close Enough',
    courseId: 'ALG',
    id: '7IytkLXo5Ert4jzKwfbx',
    isActive: true,
  },
];
