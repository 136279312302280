import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Document } from '@xq/domain';
import { DocumentMapper } from '@xq/shared/data-access';
import { DocumentObject, loggerService } from '@xq/infrastructure';
import {
  GetFileFromDriveUseCase,
  IsFileInSchoolStructureUseCase,
  LogAnalyticsEventUseCase,
  MoveDriveFileUseCase,
} from '@xq/usecases';

import {
  enqueueSnackbar,
  selectCurrentUserClassroom,
  selectCurrentUserSchool,
  selectCurrentUserState,
  selectIsCurrentUserStudent,
  SnackbarVariant,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { useModal, useUserFiles } from '../context';

export type SelectedFile = Document & { isFromDrive?: boolean };

export const useFileSelect = (initialSelectedFiles: Document[] = []) => {
  const { fileListUpload } = useUserFiles();
  const { dispatchModal, onCancel, resetModal, onConfirm } = useModal();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [rawFiles, setRawFiles] = useState<{ id: string; file: File }[]>([]);
  const [selectedFiles, setSelectedFiles] =
    useState<SelectedFile[]>(initialSelectedFiles);
  const [loadingFiles, setLoadingFiles] = useState<Document[]>([]);

  const currentUser = useAppSelector(selectCurrentUserState);
  const isCurrentUserStudent = useAppSelector(selectIsCurrentUserStudent);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const currentSchool = useAppSelector(selectCurrentUserSchool);

  const addFiles = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const fileList = event?.target?.files;

      if (!fileList) return;

      const files = Array.from(fileList);

      const documents = files.map((f) => ({
        ...DocumentMapper.fromLocal(f),
        ownerId: currentUser.uid,
      }));

      setRawFiles((prevState) => [
        ...prevState,
        ...files.map((file, i) => ({ file, id: documents[i].id })),
      ]);

      setSelectedFiles((prevState) => [...prevState, ...documents]);
    },
    [currentUser.uid]
  );

  const handleSelectFiles = async (files: DocumentObject[]) => {
    const mappedFiles = await Promise.all(
      files.map(async (file) => {
        const fileFromDrive = await GetFileFromDriveUseCase.execute(file.id);

        return {
          ...fileFromDrive,
          isFromDrive: true,
          ownerId: currentUser.uid,
        };
      })
    );

    if (isCurrentUserStudent) {
      files.forEach(() => {
        LogAnalyticsEventUseCase.execute({
          number_of_files_uploaded: {
            classroom: currentClassroom?.name,
            school: currentSchool?.name,
          },
        });
      });

      // tagFiles(mappedFiles.map(({ id, mimeType }) => ({ id, mimeType }))).catch(
      //   (e) => loggerService.error(e)
      // );
    }

    setSelectedFiles((prevState) => prevState.concat(mappedFiles));
  };

  const addFilesFromPicker = async (
    files: DocumentObject[],
    destinationId?: string
  ) => {
    try {
      const filesForMoving: { name: string; id: string }[] = [];

      for (const { id, name } of files) {
        const shouldMove = !(await IsFileInSchoolStructureUseCase.execute(id));
        if (shouldMove) filesForMoving.push({ id, name });
      }

      if (filesForMoving.length && destinationId) {
        onCancel.current = async () => resetModal();
        onConfirm.current = async () => {
          const selectedFiles: DocumentObject[] = [];

          await Promise.all(
            filesForMoving.map(async ({ id, name }) => {
              try {
                await MoveDriveFileUseCase.execute(id, destinationId);
                const movedFile = files.find((f) => f.id === id);
                if (movedFile) selectedFiles.push(movedFile);
              } catch (e) {
                dispatch(
                  enqueueSnackbar({
                    message: t('move_file_fail', {
                      fileName: name,
                    }),
                    options: {
                      variant: SnackbarVariant.ERROR,
                    },
                  })
                );
              }
            })
          );

          if (selectedFiles.length) await handleSelectFiles(files);
        };

        dispatchModal({
          actionButtonText: t('proceed'),
          titleText: t('move_file'),
          bodyText: t('move_file_body'),
          showIcon: false,
          isOpen: true,
        });

        return;
      }

      return handleSelectFiles(files);
    } catch (e) {
      loggerService.error(e);
    }
  };

  const removeFile = useCallback(async (fileId: string) => {
    setSelectedFiles((prevState) => prevState.filter((f) => f.id !== fileId));
    setRawFiles((prevState) => prevState.filter((f) => f.id !== fileId));
  }, []);

  const uploadFiles = useCallback(async () => {
    const filesForUpload = rawFiles.map((f) => f.file);

    setRawFiles([]);
    setSelectedFiles([]);
    setLoadingFiles(filesForUpload.map((f) => DocumentMapper.fromLocal(f)));

    try {
      return await fileListUpload({ fileList: filesForUpload });
    } catch (e) {
      loggerService.error(e);
      return [];
    } finally {
      setLoadingFiles([]);
    }
  }, [fileListUpload, rawFiles]);

  return {
    rawFiles,
    selectedFiles,
    setSelectedFiles,
    addFiles,
    addFilesFromPicker,
    removeFile,
    uploadFiles,
    loadingFiles,
  };
};
