import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WarningIcon from '@mui/icons-material/Warning';
import {
  enqueueSnackbar,
  StyledButton,
  useAppDispatch,
} from '@xq/web-components';
import { DOMAINS_FOR_WHITELIST } from '@xq/web-config';
import styles from './whitelist-domains-content.module.scss';

export const WhitelistDomainsContent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <p className={styles.title}>{t('whitelist_domains')}</p>
        <p className={styles.description}>
          <WarningIcon color="warning" style={{ marginRight: 5 }} />
          {t('whitelist_domains_warning')}
        </p>
        <div className={styles.domainsList}>{DOMAINS_FOR_WHITELIST}</div>
        <StyledButton
          onClick={() => {
            navigator.clipboard.writeText(DOMAINS_FOR_WHITELIST);
            dispatch(
              enqueueSnackbar({
                message: t('copied_domains_list'),
              })
            );
          }}
          endIcon={<ContentCopyIcon />}
        >
          {t('copy_domains')}
        </StyledButton>
      </div>
    </div>
  );
};
