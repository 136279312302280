import { IClassroomRepository, ClassroomRepository } from '@xq/infrastructure';

export class PersistLastPostUseCase {
  classroomRepository: IClassroomRepository;

  constructor() {
    this.classroomRepository = new ClassroomRepository();
  }

  execute(userId: string, lastPost: Date): Promise<void> {
    return this.classroomRepository.persistLastPost(userId, lastPost);
  }
}
