import {
  ClassroomsScopes,
  DashboardScopes,
  JournalFeedScopes,
  MyFilesScopes,
} from '../scopes';

export const viewerScopes = [
  DashboardScopes.CAN_VIEW,
  DashboardScopes.CAN_UPDATE,
  JournalFeedScopes.CAN_VIEW,
  JournalFeedScopes.CAN_VIEW_TAGS,
  JournalFeedScopes.CAN_FILTER_BY_TAGS,
  MyFilesScopes.CAN_VIEW,
  MyFilesScopes.CAN_VIEW_TAGS,
  ClassroomsScopes.CAN_VIEW_OWN,
  ClassroomsScopes.CAN_GENERATE_TEMPLATES,
  MyFilesScopes.CAN_MARK_FOR_REVIEW,
];
