import { z } from 'zod';

import { FeedSlugs, AttachedFileSchema } from '@xq/domain';

export const UpdatePostSchema = z.object({
  id: z.string(),
  userId: z.string(),
  classroomId: z.string(),
  programId: z.string().optional(),
  message: z.string().optional(),
  foreign_id: z.string().optional(),
  accessToken: z.string().optional(),
  isForReview: z.boolean().optional(),
  hidden: z.boolean().optional(),
  mathTags: z.string().array().optional(),
  feedSlug: z.nativeEnum(FeedSlugs),
  attachedFiles: z.array(AttachedFileSchema).optional(),
});

export type UpdatePostRequestDto = z.infer<typeof UpdatePostSchema>;
