import { useCallback, useEffect, useState } from 'react';
import { $createTextNode, $getSelection, $isRangeSelection } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Menu } from '@mui/material';
import { chunk } from 'lodash';
import { Virtuoso } from 'react-virtuoso';

import { EmojiOption } from './EmojiOption';
import { EmojiMenuItem } from './EmojiMenuItem';
import { Emoji } from './Emoji';

type Props = {
  isOpen: boolean;
  anchorElement: (EventTarget & Element) | null;
};

export function EmojiDropdownPickerPlugin({ isOpen, anchorElement }: Props) {
  const [editor] = useLexicalComposerContext();
  const [emojis, setEmojis] = useState<EmojiOption[][]>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('./emoji-list.ts').then((file) => {
      const emojiOptions = file.default.map(
        ({ emoji, aliases, tags }: Emoji) =>
          new EmojiOption(aliases[0], emoji, {
            keywords: [...aliases, ...tags],
          })
      );

      return setEmojis(chunk(emojiOptions, 10));
    });
  }, []);

  const onSelectOption = useCallback(
    (selectedOption: EmojiOption) => {
      editor.focus();
      editor.update(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection) || selectedOption == null) {
          return;
        }

        selection.insertNodes([$createTextNode(selectedOption.emoji)]);
      });
    },
    [editor]
  );

  return (
    <Menu
      open={isOpen}
      anchorEl={anchorElement}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <Virtuoso
        data={emojis}
        style={{ minHeight: 200, maxHeight: 500, width: 400 }}
        totalCount={emojis.length}
        overscan={200}
        itemContent={(_, emojiOptions) => (
          <EmojiMenuItem
            onClick={(key) => {
              const selection = emojiOptions.find((o) => o.key === key);

              if (!selection) return;

              onSelectOption(selection);
            }}
            options={emojiOptions}
          />
        )}
      />
    </Menu>
  );
}
