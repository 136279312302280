import { z } from 'zod';

import { ClassroomSchema } from '@xq/domain';
import { atLeastOneDefined } from '@xq/shared/utils';

import { UpdateEntityActions } from '../enums';

export const UpdateClassroomValidation = z.object({
  classroomId: z.string(),
  valuesToUpdate: ClassroomSchema.partial().refine(atLeastOneDefined, {
    message: 'One of the fields must be defined',
  }),
  action: z.nativeEnum(UpdateEntityActions),
});

export type UpdateClassroomRequestDto = z.infer<
  typeof UpdateClassroomValidation
>;
