import { FeedService } from '@xq/infrastructure';
import { StreamClient } from 'getstream';

export class ReactWithEmojiUseCase {
  static async execute(
    client: StreamClient,
    activityId: string,
    emoji: string
  ) {
    return FeedService.getInstance(client).addEmojiReaction(activityId, emoji);
  }
}
