import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import SvgIcon from '@mui/icons-material/Close';
import { Button, ButtonProps, styled } from '@mui/material';

const StyledButton = styled(Button)`
  align-self: flex-start;
`;
const StyledCloseIcon = styled(SvgIcon)`
  margin-left: 9px;
`;

export const ExitButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <StyledButton color="inherit" variant="text" {...props}>
      {t('exit')}
      <StyledCloseIcon>
        <CloseIcon fontSize="small" />
      </StyledCloseIcon>
    </StyledButton>
  );
};
