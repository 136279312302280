import axios, { AxiosResponse } from 'axios';
import { CreateTicketRequestDto } from '@xq/shared/data-access';

export class ZenDeskService {
  private api = 'https://xqinstitutehelp.zendesk.com/';

  createTicket(data: CreateTicketRequestDto) {
    return axios.post(`${this.api}api/v2/requests`, { request: data });
  }

  uploadAttachment(attachmentName: string, arrayBuffer: ArrayBuffer) {
    return axios.post<string, AxiosResponse<{ upload: { token: string } }>>(
      `${this.api}api/v2/uploads.json?filename=${attachmentName}`,
      arrayBuffer,
      {
        headers: {
          'Content-Type': 'application/binary',
        },
      }
    );
  }
}
