import { WordPressService } from '@xq/infrastructure';

export class FetchProgramUseCase {
  wordPressService: WordPressService;

  constructor() {
    this.wordPressService = WordPressService.getInstance();
  }

  async execute(slug: string, isTeacher?: boolean) {
    return this.wordPressService.getProgram(slug, isTeacher);
  }
}
