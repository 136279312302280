import {
  HorizontalStepper,
  StyledHeader,
  StyledTitle,
} from '@xq/web-components';
import { useNavigate } from 'react-router-dom';
import { ExitButton } from '../ExitButton';

type CreateFormHeaderProps = {
  activeIndex?: number;
  completeIndexes?: Array<number>;
  title: string;
  onExit?(): void;
  exitPath: string;
  showExitButton?: boolean;
};

export const CreateFormHeader = ({
  activeIndex,
  completeIndexes,
  title,
  onExit,
  exitPath,
  showExitButton = true,
}: CreateFormHeaderProps) => {
  const navigate = useNavigate();

  return (
    <StyledHeader>
      <div>
        <HorizontalStepper
          activeIndex={activeIndex}
          completeIndexes={completeIndexes}
        />
        <StyledTitle>{title}</StyledTitle>
      </div>
      {showExitButton ? (
        <ExitButton
          onClick={() => {
            onExit?.();

            navigate(exitPath);
          }}
        />
      ) : null}
    </StyledHeader>
  );
};
