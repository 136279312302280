import { RealTimeMessage, StreamClient, StreamFeed } from 'getstream';
import { SubscribeCallback, Subscription } from 'faye';
import { isNil } from 'lodash';

import { FeedSlugs, NOTIFICATION_FEED_SLUGS } from '@xq/domain';

export interface GetNotificationsPayload {
  setReadIds?: string[] | boolean; // setting it true makes all notifications marked as read
  beforeId?: string;
  slug: FeedSlugs;
}

export class NotificationFeedService {
  private readonly NOTIFICATION_LIMIT = 100;

  private readonly client: StreamClient;

  private static instance: NotificationFeedService | null;

  private subscriptions: Record<FeedSlugs, Subscription | null> =
    NOTIFICATION_FEED_SLUGS.reduce((acc, slug) => {
      return {
        ...acc,
        [slug]: null,
      };
    }, {} as Record<FeedSlugs, Subscription | null>);

  private feeds: Record<FeedSlugs, StreamFeed> = {} as Record<
    FeedSlugs,
    StreamFeed
  >;

  private constructor(client: StreamClient) {
    this.client = client;

    const userId = this.client.currentUser?.id;

    if (!userId) {
      throw new Error('Current stream user is undefined');
    }

    this.feeds = NOTIFICATION_FEED_SLUGS.reduce((acc, slug) => {
      return {
        ...acc,
        [slug]: this.client.feed(slug, userId),
      };
    }, {} as Record<FeedSlugs, StreamFeed>);
  }

  getNotifications({
    setReadIds,
    beforeId = '',
    slug,
  }: GetNotificationsPayload) {
    return this.feeds[slug].get({
      limit: this.NOTIFICATION_LIMIT,
      mark_read: setReadIds,
      ...(beforeId && { id_lt: beforeId }),
    });
  }

  async subscribeToFeed(
    callback: SubscribeCallback<RealTimeMessage>,
    slug: FeedSlugs
  ) {
    const feed = this.feeds[slug];
    if (feed && !this.subscriptions[slug]) {
      const subscription = await feed.subscribe(callback);
      this.subscriptions[slug] = subscription;
    }
  }

  async unsubscribeToFeed(slug: FeedSlugs) {
    const feed = this.feeds[slug];
    if (feed && !this.subscriptions[slug]) {
      await feed.unsubscribe();
      this.subscriptions[slug] = null;
    }
  }

  delete(id: string, slug: FeedSlugs) {
    return this.feeds[slug].removeActivity(id);
  }

  static getInstance(client: StreamClient) {
    if (!NotificationFeedService.instance) {
      NotificationFeedService.instance = new NotificationFeedService(client);
    }
    return NotificationFeedService.instance;
  }

  static deleteInstance() {
    this.instance = null;
  }
}
