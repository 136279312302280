import { useCallback, useMemo } from 'react';

import { Comment, Mention, FeedSlugs, getUserRole, Activity } from '@xq/domain';

import { MemoizedCommentItem } from './CommentItem';
import { selectCurrentUserState, useAppSelector } from '../../../../store';
import { useFeed } from '../../../../context';

type CommentListProps = {
  activity: Activity;
  comments: Comment[];
  mentionSuggestions: Mention[];
};

export const CommentList = ({
  activity,
  comments,
  mentionSuggestions,
}: CommentListProps) => {
  const { feedSlug } = useFeed();

  const currentUser = useAppSelector(selectCurrentUserState);

  const { isTeacher, isViewer, isStudent } = getUserRole(currentUser);

  const isCommentVisible = useCallback(
    (comment: Comment) => {
      const isCommentPostedByLoggedUser = comment.creatorId === currentUser.uid;
      const commentIsHidden = comment.hidden;

      return (
        (feedSlug !== FeedSlugs.student &&
          (isCommentPostedByLoggedUser || isTeacher || isViewer)) ||
        !commentIsHidden
      );
    },
    [currentUser.uid, feedSlug, isTeacher, isViewer]
  );

  const filteredComments = useMemo(() => {
    return isStudent ? comments.filter((c) => isCommentVisible(c)) : comments;
  }, [comments, currentUser.uid, isStudent, isCommentVisible]);

  return (
    <>
      {filteredComments.map((comment) => (
        <MemoizedCommentItem
          key={comment.id}
          comment={comment}
          mentionSuggestions={mentionSuggestions}
          activity={activity}
        />
      ))}
    </>
  );
};
