import { Divider, IconButton } from '@mui/material';
import { $createTextNode, $getSelection, $isRangeSelection } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import styles from './emoji-toolbar-plugin.module.scss';
import { EMOJIS } from '../../../utils';

export const EmojiToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const onEmojiClick = (emoji: string) => {
    editor.focus();
    editor.update(() => {
      const selection = $getSelection();

      if (!$isRangeSelection(selection)) {
        return;
      }

      selection.insertNodes([$createTextNode(emoji)]);
    });
  };

  return (
    <div className={styles.root}>
      <Divider />
      {EMOJIS.map((emoji) => (
        <IconButton
          color="info"
          key={emoji}
          onClick={() => onEmojiClick(emoji)}
        >
          {emoji}
        </IconButton>
      ))}
      <Divider />
    </div>
  );
};
