import { StreamClient } from 'getstream';

import { FeedSlugs, getJournalActivityId } from '@xq/domain';
import { ClassroomRepository, FeedService } from '@xq/infrastructure';

type DeleteJournalPhaseActivity = {
  postData: {
    id: string;
    userId: string;
    classroomId: string;
    programId: string;
    journalActivityId: string;
  };
  client: StreamClient;
};

export class DeletePhaseJournalActivityUseCase {
  async execute({
    postData,
    client,
  }: DeleteJournalPhaseActivity): Promise<void> {
    const feedService = FeedService.getInstance(client);
    const classroomRepo = new ClassroomRepository();
    const feedId = getJournalActivityId({
      classroomId: postData.classroomId,
      programId: postData.programId,
      journalActivityId: postData.journalActivityId,
    });

    await classroomRepo.removePhaseJournalStudentResponse({
      userId: postData.userId,
      activityId: postData.id,
      feedId,
      classroomId: postData.classroomId,
    });

    await feedService.deletePost({
      id: postData.id,
      userId: postData.userId,
      feedId,
      feedSlug: FeedSlugs.phaseJournal,
    });
  }
}
