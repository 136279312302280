import { Activity } from '@xq/domain';
import { loggerService } from '@xq/infrastructure';

import { EditPostUseCase } from './EditPostUseCase';

interface HandleHidePostProps {
  activity: Activity;
  currentClassroomId: string;
  hidden: boolean;
  streamToken: string;
  programId?: string;
}

export class HandleHidePostUseCase {
  static async execute({
    activity,
    currentClassroomId,
    streamToken,
    hidden,
    programId,
  }: HandleHidePostProps) {
    const editPostUseCase = new EditPostUseCase();
    return editPostUseCase
      .execute(
        {
          id: activity.id,
          message: activity.message,
          userId: activity.actor?.id,
          classroomId: currentClassroomId,
          foreign_id: activity.foreign_id,
          hidden: hidden,
          programId: programId,
          feedSlug: activity.feedSlug,
        },
        streamToken
      )
      .catch((e) => loggerService.error("Couldn't update post", e));
  }
}
