import { classrooms } from './entities';

const classroomNames = ['Classroom 1', 'Classroom 2'] as const;

export type ClassroomName = (typeof classroomNames)[number];

export type TestClassroom = (typeof classrooms)[number];

export const TEST_CLASSROOMS = classrooms.reduce(
  (acc, classroom) => ({
    ...acc,
    [classroom.name]: classroom,
  }),
  {} as Record<ClassroomName, TestClassroom>
);
