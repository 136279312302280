import { MentionNode } from './MentionNode';

export type JsonNode =
  | { [key: string]: JsonNode }
  | JsonNode[]
  | string
  | number
  | boolean
  | null;

export const extractMentions = (json: JsonNode): MentionNode[] => {
  const mentions: MentionNode[] = [];
  if (typeof json === 'object' && json !== null) {
    for (const [key, value] of Object.entries(json)) {
      if (key === 'type' && value === 'mention') {
        mentions.push(json as unknown as MentionNode);
      } else if (typeof value === 'object' || Array.isArray(value)) {
        mentions.push(...extractMentions(value));
      }
    }
  }
  return mentions;
};
