import { ChangeEvent, useEffect, useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper/types';
import { useParams } from 'react-router-dom';

import {
  ContentType,
  getUserRole,
  ProgramProgress,
  SubPhase,
} from '@xq/domain';

import { StyledSwiper } from './TabsPanels.styles';
import { StudentContentPanel } from './TabContent/StudentContentPanel';
import { ResourceListPanel } from './ResourceListPanel/ResourceListPanel';
import { PhaseContentPanel } from './TabContent/PhaseContentPanel';
import { SubPhaseContextProvider, usePhase } from '../context';
import {
  selectCurrentUserSchoolId,
  selectOrderedTemplatesByProgramAndSchool,
  selectPhaseContent,
  selectPhasesContent,
  selectProgramPostSelectedSlug,
  useAppSelector,
  selectCurrentUserState,
} from '../store';
import type { ResourceItem } from './ResourceItem/ResourceItem';
import { decodeEntities } from '../utils/decodeEntities';
import { TabPanel } from '../TabPanel/TabPanel';
import { DocumentList } from '../DocumentList/DocumentList';
import { SelectSchool } from '../SelectSchool';

type TabsPanelsProps = {
  handleChangeTabIndex: (index: number) => void;
  programProgress?: ProgramProgress;
  handleSubPhaseCheck: (
    event: ChangeEvent<HTMLInputElement>,
    subPhase: SubPhase
  ) => void;
};

export const TabsPanels = ({
  handleChangeTabIndex,
  handleSubPhaseCheck,
  programProgress,
}: TabsPanelsProps) => {
  const swiperRef = useRef<Swiper | null>(null);

  const {
    tabIndex = '0',
    phaseIndex = '0',
    subPhaseContentIndex = '-1',
    subPhaseIndex = '0',
  } = useParams();

  const { phaseTabs, resourcePath, getSearchParams, phasePath } = usePhase();

  const phasesContent = useAppSelector(selectPhasesContent);
  const phaseContent = useAppSelector((state) =>
    selectPhaseContent(state, +phaseIndex)
  );
  const currentUser = useAppSelector(selectCurrentUserState);
  const activeTabIndex = +tabIndex;

  const handleChangeIndex = (swiper: Swiper) => {
    if (activeTabIndex !== swiper.activeIndex) {
      handleChangeTabIndex(swiper.activeIndex);
    }
  };

  useEffect(() => {
    if (
      swiperRef.current &&
      phaseContent === phasesContent[+phaseIndex] &&
      swiperRef.current?.activeIndex !== activeTabIndex
    ) {
      setTimeout(() => {
        swiperRef.current?.slideTo(activeTabIndex);
      }, 0);
    }
  }, [activeTabIndex, phaseContent, phaseIndex]);

  const renderStudentContentPanel = (index: number) => {
    const phase = phaseContent?.[ContentType.StudentContent];

    if (!phase) {
      return null;
    }

    return (
      <SubPhaseContextProvider
        phasePath={phasePath}
        phase={phase}
        tabIndex={tabIndex}
        subPhaseIndex={subPhaseIndex}
        subPhaseContentIndex={subPhaseContentIndex}
      >
        <StudentContentPanel
          panelIndex={index}
          programProgress={programProgress}
          handleSubPhaseCheck={handleSubPhaseCheck}
        />
      </SubPhaseContextProvider>
    );
  };

  const renderAdditionalResourcesPanel = () => {
    const resources: ResourceItem[] =
      phaseContent?.[ContentType.AdditionalResources].map((r) => ({
        title: decodeEntities(r.title),
        link: `${resourcePath(r.slug)}${getSearchParams()}`,
      })) ?? [];

    return <ResourceListPanel resources={resources} />;
  };

  const renderPhaseContentPanel = (index: number) => {
    const phase = phaseContent?.[ContentType.PhaseContent];

    if (!phase) {
      return null;
    }

    return (
      <SubPhaseContextProvider
        phasePath={phasePath}
        phase={phase}
        tabIndex={tabIndex}
        subPhaseIndex={subPhaseIndex}
        subPhaseContentIndex={subPhaseContentIndex}
      >
        <PhaseContentPanel
          panelIndex={index}
          handleSubPhaseCheck={handleSubPhaseCheck}
          programProgress={programProgress}
        />
      </SubPhaseContextProvider>
    );
  };

  const currentSchoolId = useAppSelector(selectCurrentUserSchoolId);

  const selectedSlug = useAppSelector(selectProgramPostSelectedSlug);

  const templates = useAppSelector((state) =>
    selectOrderedTemplatesByProgramAndSchool(
      state,
      selectedSlug,
      currentSchoolId || ''
    )
  );

  const renderTeacherResourcesPanel = () => {
    const { isViewer } = getUserRole(currentUser);

    return (
      <>
        {isViewer && <SelectSchool />}
        <DocumentList isResourcesTemplatesList documents={templates} />
      </>
    );
  };

  const panelMap = {
    [ContentType.StudentContent]: renderStudentContentPanel,
    [ContentType.AdditionalResources]: renderAdditionalResourcesPanel,
    [ContentType.PhaseContent]: renderPhaseContentPanel,
    [ContentType.TeacherResources]: renderTeacherResourcesPanel,
  };

  return (
    <StyledSwiper
      onSwiper={(swiper) => (swiperRef.current = swiper)}
      onSlideChange={handleChangeIndex}
      breakpoints={{
        800: {
          allowTouchMove: false,
        },
      }}
    >
      {phaseTabs.map((t, index) => (
        <SwiperSlide key={index}>
          <TabPanel value={activeTabIndex} index={index}>
            {panelMap[t.name](index)}
          </TabPanel>
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
};
