import { AppBar, styled } from '@mui/material';

export const StylePhaseContainer = styled('div')<{ isHeaderVisible: boolean }>`
  height: 100%;
  display: grid;
  grid-template-rows: ${({ isHeaderVisible }) =>
    isHeaderVisible ? '48px 1fr' : '0px 1fr'};
  overflow-y: auto;
  background-color: var(--white);

  @media (max-width: 640px) {
    padding-top: 0;
  }
`;

export const StyledAppBar = styled(AppBar)`
  background: var(--white);
  box-shadow: none;
`;
