import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { cx } from '@emotion/css';

import { Activity, AttachedFile, Mention } from '@xq/domain';

import styles from './post-menus.module.scss';
import GreenCheckIcon from '../../../../images/check-in-circle-green.svg';
import { HideMenu } from '../HideMenu/HideMenu';
import { selectCurrentUserState, useAppSelector } from '../../../../store';
import { useFeed } from '../../../../context';
import { PostActionsMenu } from '../../../ActionsMenu/PostActionsMenu';

type PostMenusProps = {
  activity: Activity;
  mentionSuggestions: Mention[];
  attachedFiles: AttachedFile[];
  classes?: {
    postMenu?: string;
  };
};

export const PostMenus = ({
  activity,
  mentionSuggestions,
  attachedFiles,
  classes,
}: PostMenusProps) => {
  const { t } = useTranslation();

  const { hidePost, isHideMenuVisible } = useFeed();

  const currentUser = useAppSelector(selectCurrentUserState);

  return (
    <div className={styles.postMenuContainer}>
      <div className={cx(styles.postMenu, classes?.postMenu)}>
        {activity.isForReview && (
          <div className={styles.forReviewIndicator}>
            <img src={GreenCheckIcon} alt={t('marked_for_review_indicator')} />
            <span className={styles.forReviewIndicatorText}>
              {t('for_review')}
            </span>
          </div>
        )}
        {/*<HideMenu*/}
        {/*  itemToHide={activity}*/}
        {/*  shouldShowHideMenu={isHideMenuVisible(activity.actor.id)}*/}
        {/*  handleHiddenState={() => hidePost(activity, attachedFiles)}*/}
        {/*/>*/}
        {activity.actor.id === currentUser.uid && (
          <PostActionsMenu
            activity={activity}
            mentionSuggestions={mentionSuggestions}
          />
        )}
      </div>
    </div>
  );
};

export const MemoizedPostMenus = memo(PostMenus);
