export enum Pages {
  welcome = 'welcome_to_xq_math',
  yourClassrooms = 'your_classrooms',
  assignModule = 'assign_module',
  selectSchool = 'select_school',
}

export type GoogleClassroomParticipant = {
  id: string;
  name: string;
  emailAddress: string;
};

export type GoogleClassroom = {
  id: string;
  name: string;
  teachers: GoogleClassroomParticipant[];
  students: GoogleClassroomParticipant[];
};

export type ClassroomIntegrationState = {
  showIntegrationWizard: boolean;
  page: Pages;
  selectedClassroomIds: string[];
  googleClassrooms: GoogleClassroom[];
  selectedSchoolIdFromList: string;
  isLoadingGoogleClassrooms: boolean;
  isLoadingUpdateClassroom: boolean;

  selectedSchoolId: string;
};
