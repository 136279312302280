import { styled } from '@mui/material';

import { Tag } from '../Tag/Tag';

export const StyledSingleTag = styled(Tag)`
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  height: 24px;
`;

export const StyledFilesContainer = styled('div')`
  display: grid;
  gap: 8px;
`;
