import { Button } from '@mui/material';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useTranslation } from 'react-i18next';
import { appActions, useAppDispatch } from '../store';
import styles from './exit-fullscreen-button.module.scss';

type Props = {
  onClick?: () => void;
};

export const ExitFullscreenButton = ({ onClick }: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  return (
    <Button
      variant="contained"
      className={styles.exitFullscreenButton}
      endIcon={<FullscreenExitIcon />}
      onClick={() => {
        dispatch(appActions.setIsFullscreen(false));
        onClick?.();
      }}
    >
      {t('exit_fullscreen')}
    </Button>
  );
};
