import { firebaseService, loggerService } from '@xq/infrastructure';
import { Classroom } from '@xq/domain';
import {
  UpdateClassroomRequestDto,
  UpdateEntityActions,
} from '@xq/shared/data-access';
import { callWithRetry } from '@xq/shared/utils';

type UpdateClassroomProps = {
  classroomId: string;
  valuesToUpdate: Partial<Classroom>;
  action: UpdateEntityActions;
};

export class UpdateClassroomUseCase {
  static async execute({
    classroomId,
    valuesToUpdate,
    action,
  }: UpdateClassroomProps) {
    const updateClassroom = firebaseService.prepareFunctionCaller<
      UpdateClassroomRequestDto,
      Promise<void>
    >('updateClassroom');
    try {
      await callWithRetry(() =>
        updateClassroom({ classroomId, valuesToUpdate, action })
      );
    } catch (error) {
      loggerService.error(error);
    }
  }
}
