export const TEACHER_APP_ROUTES = {
  getRoot: () => '/',
  getResources: () => '/resources',
  getResourceListView: (
    phaseIndex = ':phaseIndex',
    tabIndex = ':tabIndex',
    subPhaseIndex = ':subPhaseIndex',
    subPhaseContentIndex = ':subPhaseContentIndex'
  ) =>
    `/resources/list-view/${phaseIndex}/${tabIndex}/${subPhaseIndex}/${subPhaseContentIndex}`,
  getResourceTabsView: (
    phaseIndex = ':phaseIndex',
    tabIndex = ':tabIndex',
    subPhaseIndex = ':subPhaseIndex',
    subPhaseContentIndex = ':subPhaseContentIndex'
  ) =>
    `/resources/tab-view/${phaseIndex}/${tabIndex}/${subPhaseIndex}/${subPhaseContentIndex}`,
  getResourceContent: (slug = ':slug') => `/resource-content/${slug}`,
  getLogin: () => '/login',
  getClassroom: (classroomId = ':classroomId') => `/classroom/${classroomId}`,
  getDashboard: (classroomId = ':classroomId') =>
    `/classroom/${classroomId}/dashboard`,
  getStudentFiles: (classroomId = ':classroomId') =>
    `/classroom/${classroomId}/student-files`,
  getStudentCalculators: (classroomId = ':classroomId') =>
    `/classroom/${classroomId}/student-calculators`,
  getJournalActivity: (
    classroomId = ':classroomId',
    phaseIndex = ':phaseIndex',
    tabIndex = ':tabIndex',
    subPhaseIndex = ':subPhaseIndex',
    subPhaseContentIndex = ':subPhaseContentIndex'
  ) =>
    `/classroom/${classroomId}/journal-activity/${phaseIndex}/${tabIndex}/${subPhaseIndex}/${subPhaseContentIndex}`,
  getJournal: (studentId = ':studentId') => `/journal/${studentId}`,
  getJournalPhase: (
    studentId = ':studentId',
    phaseIndex = ':phaseIndex',
    tabIndex = ':tabIndex',
    subPhaseIndex = ':subPhaseIndex',
    subPhaseContentIndex = ':subPhaseContentIndex'
  ) =>
    `/journal/${studentId}/${phaseIndex}/${tabIndex}/${subPhaseIndex}/${subPhaseContentIndex}`,
  getTermsOfService: () => '/terms-of-service',
  getPrivacyPolicy: () => '/privacy-policy',
  getErrorScreen: () => '/login-error-screen',
  getAdministration: (adminTab = ':adminTab') => `/administration/${adminTab}`,
  getNewClassroomSetup: () => '/new-classroom-setup',
  getClassroomSummary: () => '/classroom-summary',
  getAddingTeachersToNewClassroom: () => '/add-teachers-to-classroom',
  getAddingStudentsToNewClassroom: () => '/add-students-to-classroom',
  getClassroomDetails: (classroomId = ':classroomId') =>
    `/classroom-details/${classroomId}`,
  getChooseRole: () => '/choose-role',
  getNewSchoolSetup: () => '/new-school-setup',
  getSchoolSummary: () => '/school-summary',
  getSchoolDetails: (schoolId = ':schoolId') => `/school-details/${schoolId}`,
  getDemoLogin: () => '/demo-login',
  getContactHelpDesk: () => '/contact-help-desk',
  getWhitelistDomains: () => '/whitelist-domains',
} as const;
