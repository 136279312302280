import { extractUserDomain, UserRoles } from '@xq/domain';
import {
  Analytics,
  AnalyticsCallOptions,
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { forEach } from 'lodash';

import { FirebaseService } from '../FirebaseService';
import { AnalyticsEventScope, AnalyticsUserScope } from './analyticsEvents';
import { UserParameterValue } from './analyticsParameters';

type SetPropertiesPayload = {
  userId: string;
  properties: AnalyticsUserScope;
};

export class GoogleAnalyticsService {
  analytics: Analytics;

  constructor(firebaseService: FirebaseService) {
    this.analytics = getAnalytics(firebaseService.app);
  }

  logEvent(event: AnalyticsEventScope, options?: AnalyticsCallOptions): void {
    const userEmail = getAuth().currentUser?.email;

    if (!userEmail) return;

    const testDomains = process.env.NX_PUBLIC_XQ_EXCLUDED_DOMAINS;

    const userDomain = extractUserDomain(userEmail);

    const isTestUser = testDomains?.includes(' ')
      ? testDomains.split(' ').includes(userDomain)
      : testDomains === userDomain;

    if (isTestUser) return;

    forEach(event, (eventParams, eventName) => {
      logEvent(
        this.analytics,
        eventName,
        { ...eventParams, method: 'Google' },
        options
      );
    });
  }

  setProperties({ userId, properties }: SetPropertiesPayload) {
    setUserId(this.analytics, userId);
    setUserProperties(this.analytics, { ...properties });
  }

  mapUserRoleToAnalyticsRole(role: UserRoles) {
    const map: Record<UserRoles, UserParameterValue> = {
      [UserRoles.student]: 'Students',
      [UserRoles.viewer]: 'Viewers',
      [UserRoles.teacher]: 'Teachers',
      [UserRoles.admin]: 'Admins',
    };

    return map[role];
  }
}
