/// <reference types="gapi"/>
/// <reference types="gapi.client.drive"/>
import { Document, GoogleFilesMimeTypes } from '@xq/domain';

export type CreateFileProps = {
  fileName: string;
  folderId: string;
  mimeType: GoogleFilesMimeTypes;
};

export class GoogleDriveUploadService {
  static async upLoadToDrive(
    fileData: File,
    folderId: string
  ): Promise<gapi.client.Response<gapi.client.drive.File>> {
    const boundary = `-------314159265358979323846`;
    const delimiter = `\r\n--${boundary}\r\n`;
    const closeDelim = `\r\n--${boundary}--`;
    const reader = new FileReader();
    reader.readAsBinaryString(fileData);

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const contentType = fileData.type || 'application/octet-stream';
        const metadata = {
          name: fileData.name,
          mimeType: contentType,
          parents: [folderId],
        };

        if (typeof reader.result === 'string') {
          const base64Data = btoa(reader.result);

          const multipartRequestBody =
            `${delimiter}Content-Type: application/json\r\n\r\n${JSON.stringify(
              metadata
            )}${delimiter}Content-Type: ${contentType}\r\n` +
            `Content-Transfer-Encoding: base64\r\n` +
            `\r\n${base64Data}${closeDelim}`;

          gapi.client
            .request({
              path: '/upload/drive/v3/files',
              method: 'POST',
              params: {
                uploadType: 'multipart',
                fields:
                  'id, name, mimeType, webViewLink, iconLink, size, createdTime, modifiedTime, ownedByMe, parents, properties',
              },
              headers: {
                'Content-Type': `multipart/mixed; boundary="${boundary}"`,
              },
              body: multipartRequestBody,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((e) => reject(e));
        } else {
          reject(new Error('Invalid File'));
        }
      };
    });
  }

  static async createPermissions(fileId: string, userEmails: string[]) {
    const drive = await gapi.client.drive;

    const permissionRole = 'writer';
    const permissionType = 'user';
    const serviceAccountEmail =
      process.env.NX_PUBLIC_PUBLIC_SERVICE_ACCOUNT_EMAIL;

    const emails = [...userEmails, serviceAccountEmail].filter(Boolean);

    return Promise.all(
      emails.map((emailAddress) =>
        drive.permissions.create({
          fileId,
          resource: {
            role: permissionRole,
            emailAddress,
            type: permissionType,
          },
        })
      )
    );
  }

  static async getFile(
    fileId: string
  ): Promise<gapi.client.Request<gapi.client.drive.File>> {
    const drive = await gapi.client.drive;
    return drive.files.get({ fileId, fields: '*' });
  }

  static async createFile({
    fileName,
    folderId,
    mimeType,
  }: CreateFileProps): Promise<gapi.client.Request<gapi.client.drive.File>> {
    const drive = await gapi.client.drive;

    return drive.files.create({
      resource: {
        name: fileName,
        parents: [folderId],
        mimeType,
      },
      fields: '*',
    });
  }

  static async listFiles(
    pageToken: string,
    parentFolderId: string
  ): Promise<gapi.client.drive.FileList> {
    const drive = await gapi.client.drive;

    const filesResponse = await drive.files.list({
      pageSize: 50,
      pageToken,
      fields: '*',
      q: `trashed = false and '${parentFolderId}' in parents`,
      spaces: 'drive',
      orderBy: 'createdTime desc',
    });

    return filesResponse?.result;
  }

  static async copyFromDrive(
    originalFile: Document,
    parentFolderId: string
  ): Promise<gapi.client.Response<gapi.client.drive.File>> {
    const shouldPrefixNameOfCopy = (mimeType: string) =>
      !Object.values(GoogleFilesMimeTypes).includes(
        mimeType as GoogleFilesMimeTypes
      );

    const name = shouldPrefixNameOfCopy(originalFile.mimeType)
      ? `Copy of ${originalFile.name}`
      : originalFile.name;

    const properties = {
      ...(originalFile?.properties?.isTemplate && { isTemplate: 'true' }),
      ...(originalFile.properties?.templateId && {
        templateId: originalFile.properties.templateId,
      }),
      ...(originalFile?.properties?.isForReview && { isForReview: 'true' }),
    };

    return new Promise((resolve, reject) => {
      gapi.client.drive.files
        .copy({
          fileId: originalFile.id,
          resource: {
            parents: [parentFolderId],
            name,
            properties,
          },
          fields: '*',
        })
        .then((result) => {
          if (result.status && result.status < 300 && result.status >= 200) {
            resolve(result);
          } else {
            reject(
              new Error(
                `Copy failed: ${result?.statusText} with status code ${result?.status}`
              )
            );
          }
        })
        .catch((e) => reject(e));
    });
  }

  static async moveFile(fileId: string, destinationId: string) {
    const parents = (
      await gapi.client.drive.files.get({ fileId, fields: 'parents' })
    ).result.parents;

    return gapi.client.drive.files.update({
      fileId,
      resource: {},
      addParents: destinationId,
      removeParents: parents?.join(','),
    }) as unknown as Promise<void>;
  }

  static async isInSchoolStructure(fileId: string) {
    const serviceAccountEmail =
      process.env.NX_PUBLIC_GOOGLEDRIVESA_CLIENT_EMAIL;

    const response = await gapi.client.drive.permissions.list({
      fileId,
      fields: 'permissions/emailAddress',
    });

    return Boolean(
      response.result.permissions?.find(
        (p) => p.emailAddress === serviceAccountEmail
      )
    );
  }

  static async deleteFromDrive(fileId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      gapi.client.drive.files
        .delete({ fileId })
        .then((result) => {
          if (result.status && result.status < 300 && result.status >= 200) {
            resolve(true);
          } else {
            reject(
              new Error(
                `Delete failed: ${result?.statusText} with status code ${result?.status}`
              )
            );
          }
        })
        .catch((e) => reject(e));
    });
  }
}
