import { NOTIFICATION_FEED_SLUGS } from '@xq/domain';
import { NotificationFeedService } from '@xq/infrastructure';
import { SubscribeCallback } from 'faye';
import { RealTimeMessage, StreamClient } from 'getstream';

export type NotificationFeedSubscribeCallback = RealTimeMessage;

export class SubscribeToNotificationFeedUseCase {
  static async execute(
    client: StreamClient,
    callback: SubscribeCallback<NotificationFeedSubscribeCallback>
  ) {
    return Promise.all(
      NOTIFICATION_FEED_SLUGS.map((slug) => {
        const notificationFeedService =
          NotificationFeedService.getInstance(client);

        return notificationFeedService.subscribeToFeed(callback, slug);
      })
    );
  }
}
