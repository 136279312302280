import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  DeleteCommentUseCase,
  EditCommentUseCase,
  GetActivityCommentsUseCase,
  PostCommentUseCase,
} from '@xq/usecases';

import {
  CreateCommentPayload,
  DeleteCommentPayload,
  FetchCommentsPayload,
  UpdateCommentPayload,
} from './comment.interface';
import { activityActions } from '../activity/activity-slice';

export const fetchComments = createAsyncThunk(
  'comment/fetchComments',
  async ({ idLt, streamToken, activityId }: FetchCommentsPayload) => {
    const comments = await GetActivityCommentsUseCase.execute({
      streamToken,
      activityId,
      idLt,
    });

    return comments;
  }
);

export const createComment = createAsyncThunk(
  'comment/createComment',
  async ({ activityId, client, text }: CreateCommentPayload, { dispatch }) => {
    const comment = await PostCommentUseCase.execute({
      activityId,
      client,
      text,
    });

    dispatch(
      activityActions.addCommentId({
        activityId,
      })
    );

    return comment;
  }
);

export const updateComment = createAsyncThunk(
  'comment/updateComment',
  async ({ commentId, text, client, hidden }: UpdateCommentPayload) => {
    const comment = await EditCommentUseCase.execute(
      {
        commentId,
        text,
        hidden,
      },
      client
    );

    return comment;
  }
);

export const deleteComment = createAsyncThunk(
  'comment/deleteComment',
  async (
    { commentId, activityId, client }: DeleteCommentPayload,
    { dispatch }
  ) => {
    await DeleteCommentUseCase.execute({
      commentId,
      client,
    });

    dispatch(
      activityActions.removeCommentId({
        activityId,
      })
    );

    return commentId;
  }
);
