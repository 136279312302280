export type FileSizeProps = {
  size: string | undefined;
  className?: string;
};

export const FileSize = ({ size, className }: FileSizeProps) => {
  const formatBytes = (bytes: number, decimals = 2) => {
    function pow(base: number, power: number) {
      let p = 1;
      for (let i = 0; i < power; i += 1) {
        p *= base;
      }
      return p;
    }

    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };
  const bytes: string = size || '0';

  return (
    <span className={className ?? ''}>{formatBytes(parseInt(bytes, 10))}</span>
  );
};
