import {createEntityAdapter, createSlice, PayloadAction} from '@reduxjs/toolkit';

import type { Calculator } from '@xq/domain';

import {
  fetchUserCalculators,
  setUserCalculator,
  fetchUserCalculator,
} from './user-calculators-actions';

export const userCalculatorsAdapter = createEntityAdapter<Calculator>();

export const userCalculatorsSlice = createSlice({
  name: 'userCalculators',
  initialState: userCalculatorsAdapter.getInitialState(),
  reducers: {
    setCalculators: (
      state,
      {
        payload: { classroomId, programId, calculators },
      }: PayloadAction<{
        classroomId: string;
        programId: string;
        calculators: Calculator[];
      }>
    ) => {
      calculators.forEach((c) => {
        state.entities[c.id] = c;
      });
    },
    setOne: userCalculatorsAdapter.setOne,
    setMany: userCalculatorsAdapter.setMany,
    updateOne: userCalculatorsAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserCalculators.fulfilled, (state, action) => {
      userCalculatorsAdapter.setMany(state, action.payload as Calculator[]);
    });
    builder.addCase(setUserCalculator.fulfilled, (state, action) => {
      userCalculatorsAdapter.setOne(state, action.payload as Calculator);
    });
    builder.addCase(fetchUserCalculator.fulfilled, (state, action) => {
      userCalculatorsAdapter.setOne(state, action.payload as Calculator);
    });
  },
});

export const userCalculatorsActions = userCalculatorsSlice.actions;
