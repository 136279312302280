import { ZenDeskService } from '@xq/infrastructure';
import { CreateTicketRequestDto } from '@xq/shared/data-access';

export class CreateTicketUseCase {
  static execute(ticket: CreateTicketRequestDto) {
    const zendeskService = new ZenDeskService();

    return zendeskService.createTicket(ticket);
  }
}
