import { useCallback, useEffect, useState } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import type { unstable_Blocker as Blocker } from 'react-router-dom';
import { useModal } from '../context';

export const useCallbackPrompt = (
  when: boolean,
  safeRoute: string[]
): [boolean, (bl: Blocker) => void, (bl: Blocker) => void, Blocker] => {
  const [showPrompt, setShowPrompt] = useState(false);
  const blocker = useBlocker(when);
  const { onConfirm, onCancel, resetModal, onClose } = useModal();

  const cancelNavigation = useCallback((blocker: Blocker) => {
    setShowPrompt(false);
    blocker?.reset?.();
    resetModal();
  }, []);

  const confirmNavigation = useCallback((blocker: Blocker) => {
    setShowPrompt(false);
    blocker.proceed?.();
  }, []);

  useEffect(() => {
    const blockerLocation = blocker?.location?.pathname;

    if (safeRoute.includes(blockerLocation as string)) {
      blocker.proceed?.();
      return;
    }

    if (blocker.state === 'blocked') {
      setShowPrompt(true);
      onConfirm.current = () => confirmNavigation(blocker);
      onCancel.current = () => cancelNavigation(blocker);
      onClose.current = () => cancelNavigation(blocker);
    }
  }, [blocker.state, safeRoute, showPrompt]);

  return [showPrompt, confirmNavigation, cancelNavigation, blocker];
};
