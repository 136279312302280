import { Classroom, ProviderLastSyncStatus, ProviderType } from '@xq/domain';

const createdAt = new Date().toISOString();
const modifiedAt = new Date().toISOString();
const archivedOn = new Date().toISOString();

export const classrooms: Classroom[] = [
  {
    createdAt,
    modifiedAt,
    classroomFolderId: '',
    chatFolderId: '',
    feedFolderId: '',
    folderIdByProgramId: {},
    chatFolderIdByStudentId: {},
    name: 'Classroom 1',
    participantEmails: ['anton.student@xqlabs.io'],
    viewerEmails: [],
    programIds: ['7IytkLXo5Ert4jzKwfbx', '0VXp1SjJcBWChyAAM1g8'],
    schoolId: 'siPX8SyjuHakJTaoMTzG',
    shortName: 'C1',
    teacherEmails: ['stage.teacher.1@xqlabs.io'],
    id: '',
    providerType: ProviderType.XQ,
    providerIdentifier: '',
    providerLastSyncStatus: ProviderLastSyncStatus.Success,
    isArchived: false,
    archivedOn,
    studentFolderIdByEmailByProgramId: {},
  },
  {
    createdAt,
    modifiedAt,
    classroomFolderId: '',
    chatFolderId: '',
    feedFolderId: '',
    folderIdByProgramId: {},
    chatFolderIdByStudentId: {},
    name: 'Classroom 2',
    participantEmails: [
      'anton.student@xqlabsdev.io',
      'deyan.student@xqlabs.io',
    ],
    viewerEmails: [],
    programIds: ['7IytkLXo5Ert4jzKwfbx', '0VXp1SjJcBWChyAAM1g8'],
    schoolId: 'siPX8SyjuHakJTaoMTzG',
    shortName: 'C2',
    teacherEmails: ['deyan.teacher@xqlabs.io'],
    id: '',
    providerType: ProviderType.XQ,
    providerIdentifier: '',
    providerLastSyncStatus: ProviderLastSyncStatus.Success,
    isArchived: false,
    archivedOn,
    studentFolderIdByEmailByProgramId: {},
  },
];
