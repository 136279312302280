import { useState, useEffect, memo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useHash } from 'react-use';
import { useTranslation } from 'react-i18next';
import { cx } from '@emotion/css';
import { Avatar, useMediaQuery } from '@mui/material';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';

import {
  Activity,
  getUserRole,
  JournalFeedScopes,
  Mention,
} from '@xq/domain';
import { STUDENT_APP_ROUTES, TEACHER_APP_ROUTES } from '@xq/web-config';

import styles from './feed-output.module.scss';
import { MemoizedComments } from '../Comments';
import {
  selectCurrentUserState,
  selectIsCurrentUserStudent,
  selectIsTeacherApp,
  useAppSelector,
} from '../../../../store';
import { selectUserById } from '../../../../store';
import { MemoizedPostHeader } from '../PostHeader/PostHeader';
import { useFeed } from '../../../../context';
import { ReadOnlyTextEditor } from '../../../../TextEditor';
import { FeedAutoTags } from '../../../../FeedAutoTags/FeedAutoTags';
import { ActivitiesReactions } from '../ActivitiesReactions/ActivitiesReactions';
import { StyledButton, StyledJournalLink } from '../../../../Layout';
import { CommentInput } from '../Comments/CommentInput';
import { MOBILE_MEDIA_QUERY } from '../../../../utils';
import { useMentionSuggestions } from '../../../../hooks';
import { RestrictView } from '../../../../RestrictView/RestrictView';

type FeedOutputProps = {
  activity: Activity;
};

export const FeedOutput = ({ activity }: FeedOutputProps) => {
  const { t } = useTranslation();
  const [commentId, updateHash] = useHash();
  const { studentId = '0' } = useParams();
  const navigate = useNavigate();
  const { feedSlug, addComment } = useFeed();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const [showComments, setShowComments] = useState(false);

  const isTeacherApp = useAppSelector(selectIsTeacherApp);
  const currentUser = useAppSelector(selectCurrentUserState);
  const isCurrentUserStudent = useAppSelector(selectIsCurrentUserStudent);

  const activityPosterId =
    activity?.actor?.id ||
    (activity as unknown as { actor: string }).actor.replace('SU:', '');

  const activityOwner = useAppSelector((state) =>
    selectUserById(state, activityPosterId)
  );

  const toggleComments = async () => {
    if (isMobile && isCurrentUserStudent) {
      const path = STUDENT_APP_ROUTES.getJournalFeedComments(activity.id);
      return navigate(path);
    }

    setShowComments((prevState) => !prevState);
  };

  const addCommentHandler = useCallback(
    async (message: string, mentions: Mention[]) => {
      await addComment({ comment: message, activity, mentions });
      if (!showComments) setShowComments(true);
    },
    [addComment, activity, showComments]
  );

  const onJournalLinkClick = () => {
    const journalLink = activity.journalLink?.link;
    if (journalLink) {
      const baseJournalPath = isTeacherApp
        ? TEACHER_APP_ROUTES.getJournal(activity.actor.id)
        : STUDENT_APP_ROUTES.getJournalPath();
      navigate(`${baseJournalPath}${journalLink}`);
    }
  };

  const mentionSuggestions = useMentionSuggestions(feedSlug, activity.actor.id);

  useEffect(() => {
    if (commentId === `#${activity.id}`) {
      setShowComments(true);

      return () => {
        updateHash('');
      };
    }
  }, [commentId, activity.id]);

  const isActivityOwnerTeacherOrAdmin = activityOwner
    ? getUserRole(activityOwner).isAdmin || getUserRole(activityOwner).isTeacher
    : false;

  const showDocumentTags =
    !isCurrentUserStudent && !isActivityOwnerTeacherOrAdmin;

  const commentsCount = activity.reaction_counts?.comment ?? 0;
  const emojiCount = activity.reaction_counts?.emoji ?? 0;

  useEffect(() => {
    if (commentsCount === 0 && showComments) {
      setShowComments(false);
    }
  }, [commentsCount]);

  const showJournalLink = activity.journalLink;

  return (
    <div
      className={cx(styles.feedOutputWrapper, {
        [styles.teacherPost]: isActivityOwnerTeacherOrAdmin,
      })}
      data-cy="feed-output"
    >
      <MemoizedPostHeader
        activity={activity}
        activityOwner={activityOwner}
        isActivityOwnerTeacherOrAdmin={isActivityOwnerTeacherOrAdmin}
        mentionSuggestions={mentionSuggestions}
      />
      {showJournalLink && (
        <StyledJournalLink
          className={styles.journalLink}
          onClick={onJournalLinkClick}
        >
          {activity.journalLink!.text}
        </StyledJournalLink>
      )}
      <div className={styles.message}>
        <ReadOnlyTextEditor htmlString={activity.message} />
      </div>

      <FeedAutoTags
        activity={activity}
        key={activity.id}
        showDocumentTags={showDocumentTags}
      />

      <ActivitiesReactions activityId={activity.id} emojiCount={emojiCount} />
      {commentsCount > 0 && (
        <StyledButton
          className={styles.viewCommentsButton}
          variant="text"
          onClick={toggleComments}
        >
          <CommentOutlinedIcon style={{ paddingRight: '4px' }} />
          {showComments ? t('hide_all_comments') : t('view_all_comments')} (
          {commentsCount})
        </StyledButton>
      )}
      {(commentsCount === 0 || showComments) && (
        <RestrictView
          scopes={[JournalFeedScopes.CAN_COMMENT]}
        >
          <div className={styles.userAvatarContainer}>
            <Avatar
              src={currentUser?.photoUrl ?? ''}
              alt={t('user_profile_photo')}
              style={{
                marginRight: '8px',
              }}
            />
            <CommentInput
              mentionSuggestions={mentionSuggestions}
              onAddComment={addCommentHandler}
            />
          </div>
        </RestrictView>
      )}
      <MemoizedComments
        activity={activity}
        showComments={showComments}
        mentionSuggestions={mentionSuggestions}
      />
    </div>
  );
};

export const MemoizedFeedOutput = memo(FeedOutput);
