import { Mention } from '@xq/domain';

import { StyledPrimaryLoadingButton } from '../../../../Layout';
import { useSubmitEditorState, useIsEditorEmpty } from '../../../../TextEditor';

type PostActivityButtonProps = {
  onClick: (content: string, mentions: Mention[]) => Promise<void> | void;
  loading?: boolean;
  label: string;
  variant?: 'text' | 'outlined' | 'contained';
  mentionSuggestions: Mention[];
  disabled?: boolean;
};

export const PostActivityButton = ({
  onClick,
  loading = false,
  label,
  variant,
  mentionSuggestions,
  disabled = false,
}: PostActivityButtonProps) => {
  const { isEditorEmpty } = useIsEditorEmpty();
  const { submitEditorState } = useSubmitEditorState(
    mentionSuggestions,
    onClick
  );

  return (
    <StyledPrimaryLoadingButton
      onClick={submitEditorState}
      disabled={isEditorEmpty || disabled}
      loading={loading}
      variant={variant}
      data-cy="post-button"
    >
      {label}
    </StyledPrimaryLoadingButton>
  );
};
