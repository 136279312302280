import { IconButton, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

type DomainInputFieldProps<T> = {
  canAdd: boolean;
  insert: () => void;
  remove: () => void;
  index: number;
  domain: string;
  formik: FormikProps<T & { domains?: string[] }>;
  label?: string;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
};

export const DomainInputField = <T extends Record<string, unknown>>({
  canAdd,
  domain,
  index,
  insert,
  remove,
  formik,
  label,
  variant = 'standard',
}: DomainInputFieldProps<T>) => {
  const { t } = useTranslation();

  const isDomainAlreadyAdded = (domainIndex: number) => {
    const enteredDomains = formik.values.domains;

    const currentDomain = enteredDomains?.[domainIndex];

    return currentDomain
      ? enteredDomains?.filter((d) => d === currentDomain).length > 1
      : false;
  };

  const renderDomainHelperText = (domainIndex: number) => {
    if (!formik.values.domains?.[domainIndex]) return;

    if (isDomainAlreadyAdded(domainIndex)) return t('domain_is_already_added');

    const errors = formik.errors.domains;

    return (
      formik.touched.domains &&
      !formik.isValidating &&
      t(errors?.[domainIndex] as any)
    );
  };

  return (
    <div>
      <TextField
        label={label}
        name={`domains[${index}]`}
        value={domain}
        variant={variant}
        helperText={renderDomainHelperText(index)}
        error={Boolean(
          formik.values.domains?.filter(Boolean)?.length &&
            (isDomainAlreadyAdded(index) || formik.errors.domains?.[index])
        )}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        sx={{ width: '100%' }}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() => {
                if (canAdd) {
                  insert();
                } else {
                  remove();
                }
              }}
            >
              {canAdd ? <AddCircleIcon /> : <RemoveCircleIcon />}
            </IconButton>
          ),
        }}
      />
    </div>
  );
};
