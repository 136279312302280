import { restClient } from './RestClient';
import { AxiosResponse } from 'axios';

type GetStreamTokenResponse = {
  token: string;
};

export const GetStreamToken = (
  uid: string
): Promise<AxiosResponse<GetStreamTokenResponse>> =>
  restClient.get(`/getStreamToken?userId=${uid}`);
