import { useState } from 'react';
import { formatDistance } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import Button from '@mui/material/Button';

import { Mention, Document, FeedSlugs, AttachedFile } from '@xq/domain';
import { DocumentObject } from '@xq/infrastructure';

import styles from './feed-dialog.module.scss';
import { SimpleModal } from '../../SimpleModal/SimpleModal';
import {
  selectCurrentUserClassroom,
  selectUserById,
  useAppSelector,
} from '../../store';
import { useFeed } from '../../context';
import {
  TextEditor,
  ToolbarPlugin,
  StyledOutlinedEditor,
  outlinedTextEditorConfig,
} from '../../TextEditor';
import { PostActivityButton } from '../ClassFeed/components/PostActivityButton/PostActivityButton';
import { FileListPreview } from '../../FileListPreview/FileListPreview';
import { useFileSelect, useGoogleScopesChecker } from '../../hooks';
import { FileUploadButtons } from '../ClassFeed/components/FeedInput/FileUploadButtons';

type ItemForEdit = {
  id: string;
  text: string;
  userId: string;
  createdAt: string;
};

type CustomDialogProps = {
  open: boolean;
  handleClose: () => void;
  editedItem: ItemForEdit;
  editItem: (message: string, documents: Document[]) => Promise<void>;
  mentionSuggestions: Mention[];
  attachedFiles?: AttachedFile[];
  showUploadButtons?: boolean;
  hideFormatTextButtons?: boolean;
};

export const FeedDialog = ({
  open,
  handleClose,
  editedItem,
  editItem,
  mentionSuggestions,
  attachedFiles = [],
  showUploadButtons = false,
  hideFormatTextButtons = false,
}: CustomDialogProps) => {
  const { t } = useTranslation();
  const { mentionUsers } = useFeed();
  const {
    selectedFiles,
    removeFile,
    addFiles,
    addFilesFromPicker,
    uploadFiles,
    loadingFiles,
  } = useFileSelect(attachedFiles as Document[]);
  const { feedSlug } = useFeed();

  const user = useAppSelector((state) =>
    selectUserById(state, editedItem.userId)
  );
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);

  const [isLoading, setIsLoading] = useState(false);

  const { requestScopes, loading: loadingScopesChecker } =
    useGoogleScopesChecker();

  const isAttachButtonDisabled = selectedFiles.length >= 10;

  const onSave = async (message: string, mentions: Mention[]) => {
    setIsLoading(true);

    const documents = selectedFiles.filter((f) => f.isFromDrive);
    const uploadedFiles = await uploadFiles();

    const attachedFiles = documents
      .concat(uploadedFiles)
      .map((f) => ({ ...f, ownerId: user?.uid }));
    await editItem(message, attachedFiles);

    setIsLoading(false);
    mentionUsers(editedItem.id, mentions);
  };

  const onSelectFilesFromPicker = (files: DocumentObject[]) => {
    const currentProgramId = currentClassroom
      ? user?.currentProgramIdByClassroomId?.[currentClassroom.id]
      : '';

    const destinationId =
      feedSlug === FeedSlugs.student && currentProgramId
        ? currentClassroom?.studentFolderIdByEmailByProgramId?.[
            user?.email || ''
          ]?.folderByProgramId?.[currentProgramId]
        : currentClassroom?.feedFolderId;

    return addFilesFromPicker(files, destinationId);
  };

  return (
    <SimpleModal open={open} onClose={handleClose}>
      <div className={styles.dialogContainer} data-cy="feed-dialog">
        <div className={styles.dialogWrapper}>
          <div className={styles.userInfo}>
            <img
              className={styles.profilePicture}
              src={user?.photoUrl || ''}
              alt={t('user_profile_photo')}
            />
            <div>
              <div className={styles.userName}>{t('me')}</div>
              <div className={styles.timeAgo}>
                {formatDistance(
                  zonedTimeToUtc(editedItem.createdAt as string, 'UTC'),
                  new Date(),
                  {
                    addSuffix: true,
                    includeSeconds: true,
                  }
                )}
              </div>
            </div>
          </div>

          <LexicalComposer initialConfig={outlinedTextEditorConfig}>
            <StyledOutlinedEditor data-cy="feed-dialog-input">
              <TextEditor
                mentionSuggestions={mentionSuggestions}
                placeholder={t('edit_post')}
                htmlString={editedItem.text}
                CustomPlugin={
                  <ToolbarPlugin hideFormatTextButtons={hideFormatTextButtons}>
                    {showUploadButtons && (
                      <FileUploadButtons
                        addFiles={addFiles}
                        addFilesFromPicker={onSelectFilesFromPicker}
                        isAttachButtonDisabled={isAttachButtonDisabled}
                        requestScopes={requestScopes}
                        loading={loadingScopesChecker}
                      />
                    )}
                  </ToolbarPlugin>
                }
              />
            </StyledOutlinedEditor>

            <div className={styles.filesContainer}>
              <FileListPreview
                uploadedFiles={selectedFiles}
                handleDeleteFile={removeFile}
                uploadingFiles={loadingFiles}
              />
            </div>

            <div className={styles.modalButtons}>
              <Button color="inherit" onClick={handleClose}>
                {t('cancel')}
              </Button>
              <PostActivityButton
                onClick={onSave}
                loading={isLoading}
                label={t('save')}
                variant="text"
                mentionSuggestions={mentionSuggestions}
                disabled={selectedFiles.length > 10 || loadingScopesChecker}
              />
            </div>
          </LexicalComposer>
        </div>
      </div>
    </SimpleModal>
  );
};
