import { Trans, useTranslation } from 'react-i18next';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { cx } from '@emotion/css';

import { Errors } from '@xq/domain';
import { TEACHER_APP_ROUTES } from '@xq/web-config';

import styles from './error-page.module.scss';
import TeacherLogo from './teacher-logo.svg';
import { XqIconPrimaryColor } from '../XqIcon/XqIconPrimaryColor';
import { useApi } from '../ApiProvider/ApiProvider';
import { useRouteError } from 'react-router';

interface LoginErrors {
  default_error: JSX.Element;
  account_has_no_rooms: JSX.Element;
  classroom_has_no_folder: JSX.Element;
  user_wrong_role: JSX.Element;
}

type ErrorPageProps = {
  isTeacherApp?: boolean;
  error?: Error;
};

const TEACHER_APP_URL =
  process.env.NX_PUBLIC_NODE_ENV === 'development'
    ? 'http://localhost:5000'
    : process.env.NX_PUBLIC_TEACHER_APP_URL;

export const ErrorPage = ({ isTeacherApp, error }: ErrorPageProps) => {
  const { t } = useTranslation();
  const { logout } = useApi();
  const err = useRouteError();

  const errorObj = error || (err as Error);

  const handleLogOut = async () => {
    await logout();
    window.location.reload();
  };

  const getError = () => {
    const redirectTo = isTeacherApp
      ? process.env.NX_PUBLIC_FIREBASE_STUDENT_LOGIN_URL
      : process.env.NX_PUBLIC_FIREBASE_TEACHER_LOGIN_URL;

    const errors: LoginErrors = {
      default_error: (
        <p
          className={cx(styles.errorText, {
            [styles.student]: !isTeacherApp,
          })}
        >
          {isTeacherApp ? (
            <Trans i18nKey="error_screen_message">
              <a
                className={styles.mailHyperlink}
                href={TEACHER_APP_ROUTES.getContactHelpDesk()}
              >
                {t('here')}
              </a>
            </Trans>
          ) : (
            t('student_error_screen_message')
          )}
        </p>
      ),
      account_has_no_rooms: <p>{t('no_classrooms_for_this_account')}</p>,
      classroom_has_no_folder: <p>{t('no_classroom_folder')}</p>,
      user_wrong_role: (
        <p
          className={cx(styles.errorText, {
            [styles.student]: !isTeacherApp,
          })}
        >
          <Trans
            i18nKey="login_to_portal"
            values={{
              currentXqApp: isTeacherApp
                ? t('teacher_lowercase')
                : t('student_lowercase'),
              correctXqApp: isTeacherApp
                ? t('student_lowercase')
                : t('teacher_lowercase'),
            }}
          >
            <a className={styles.mailHyperlink} href={redirectTo}>
              {t('here')}
            </a>
          </Trans>
          {!isTeacherApp ? (
            <Trans i18nKey="wrong_role_contact_xq">
              <a
                className={styles.mailHyperlink}
                href={`${TEACHER_APP_URL}${TEACHER_APP_ROUTES.getContactHelpDesk()}`}
              >
                {t('contact_XQ')}
              </a>
            </Trans>
          ) : (
            t('student_wrong_role_notify_teacher')
          )}
        </p>
      ),
    };

    const key = errorObj ? errorObj.message : Errors.defaultError;

    let result = errors[key as keyof LoginErrors];

    if (!result) {
      result = errors.default_error;
    }

    return result;
  };

  return (
    <div
      className={cx(styles.content, {
        [styles.student]: !isTeacherApp,
      })}
    >
      {isTeacherApp ? (
        <img className={styles.logo} src={TeacherLogo} alt={t('XQ_logo')} />
      ) : (
        <div className={styles.studentLogo}>
          <XqIconPrimaryColor fontSize="large" />
          <p className={styles.math}>{t('math')}</p>
        </div>
      )}

      <div className={styles.error}>
        <div className={styles.warningSign}>
          <WarningRoundedIcon />
        </div>

        <p
          className={cx(styles.oops, {
            [styles.student]: !isTeacherApp,
          })}
        >
          {t('ops')}
        </p>

        {getError()}

        <button onClick={handleLogOut} className={styles.tryAgain}>
          {t('try_agein')}
        </button>
      </div>
    </div>
  );
};
