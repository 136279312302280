import { isUndefined } from 'lodash';
import { useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { getActivePhaseIndex } from '@xq/domain';
import { TEACHER_APP_ROUTES, STUDENT_APP_ROUTES } from '@xq/web-config';
import {
  selectCurrentUserClassroom,
  selectCurrentUserProgram,
  selectIsTeacherApp,
  selectProgramPost,
  selectUserProgramProgress,
  useAppSelector,
  selectCurrentUserState,
} from '../store';

export const useNavigateToActivePhase = () => {
  const navigate = useNavigate();

  const { studentId = '' } = useParams();

  const currentUser = useAppSelector(selectCurrentUserState);
  const isTeacherApp = useAppSelector(selectIsTeacherApp);
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const classroom = useAppSelector(selectCurrentUserClassroom);
  const programPost = useAppSelector(selectProgramPost);
  const userProgramProgress = useAppSelector((state) =>
    selectUserProgramProgress(
      state,
      isTeacherApp ? studentId : currentUser.uid,
      classroom?.id ?? '',
      currentProgram?.id ?? ''
    )
  );

  const activePhaseIndex = useMemo(
    () => getActivePhaseIndex(programPost, userProgramProgress),
    [programPost, userProgramProgress]
  );

  const prevProgram = usePrevious(currentProgram);
  const prevActivePhaseIndex = usePrevious(activePhaseIndex);

  const navigateToActivePhase = () => {
    const path =
      isTeacherApp && studentId
        ? TEACHER_APP_ROUTES.getJournalPhase(
            studentId,
            activePhaseIndex.toString(),
            '0',
            '-1',
            '-1'
          )
        : STUDENT_APP_ROUTES.getJournalPhase(
            activePhaseIndex.toString(),
            '0',
            '-1',
            '-1'
          );

    navigate(path);
  };

  // We need to check if currentPrograms has actually changed or activePhaseIndex to set navigation to the activePhaseIndex
  // This also cover case when user navigates back to preserve the last activePhaseIndex when the user was in this page before
  useEffect(() => {
    const programChanged = Boolean(
      prevProgram?.slug && prevProgram?.slug !== currentProgram?.slug
    );
    const activePhaseIndexChanged = Boolean(
      !isUndefined(prevActivePhaseIndex) &&
        prevActivePhaseIndex !== activePhaseIndex
    );

    if (programChanged || activePhaseIndexChanged) {
      navigateToActivePhase();
    }
  }, [prevProgram, currentProgram, activePhaseIndex, prevActivePhaseIndex]);
};
