import { styled } from '@mui/material';

export const StyledLoadMore = styled('span')`
  font-weight: 800;
  display: inline-block;
  width: 100%;
  text-align: center;
  cursor: pointer;
  line-height: 40px;
`;
