import { firebaseService } from '@xq/infrastructure';
import { Classroom } from '@xq/domain';
import { callWithRetry } from '@xq/shared/utils';

export class UpdateImportedSingleGoogleClassroomUseCase {
  async execute(classroomId: string): Promise<Classroom> {
    const updateGoogleClassroom = firebaseService.prepareFunctionCaller(
      'updateImportedSingleGoogleClassroom'
    );
    const token = gapi.client.getToken();

    const { data } = await callWithRetry(() =>
      updateGoogleClassroom({
        token: token.access_token,
        classroomId: classroomId,
      })
    );

    return data as Classroom;
  }
}
