import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import { Activity, ALL_TAGS, MathTagTypes } from '@xq/domain';

import {
  selectActivityState,
  selectCurrentUserState,
  selectTaggedPost,
} from '../index';
import { activityAdapter } from './activity-slice';
import {
  selectAllTaggedFiles,
  extractTaggedFiles,
} from '../tagged-file/tagged-file-selector';

export const {
  selectAll: selectAllActivities,
  selectById: selectActivityById,
} = activityAdapter.getSelectors(selectActivityState);

export const selectActivitiesByIds = createSelector(
  [
    selectActivityState,
    selectTaggedPost,
    selectAllTaggedFiles,
    (_, activityIds: string[]) => activityIds,
    (_, ___: string[], tagType: MathTagTypes | string) => tagType,
  ],
  (activityState, taggedPostState, taggedFileState, activityIds, tagType) => {
    return activityIds
      .map((id) => activityState.entities[id])
      .filter((activity) => {
        if (!activity) {
          return false;
        }

        if (!tagType) {
          return true;
        }

        const fileIds = activity.attachedFiles.map((doc) => doc.id);
        const allFiles = extractTaggedFiles(taggedFileState);

        const tags = new Set(
          fileIds
            .map((id) => allFiles[id])
            .filter(Boolean)
            .flat()
        );

        const postTags = new Set(taggedPostState[activity.id as string]);

        if (tagType === ALL_TAGS && (tags.size || postTags.size)) {
          return true;
        }

        const byOwnTags = postTags.has(tagType);
        const byFilesTags = tags.has(tagType as MathTagTypes);

        return byOwnTags || byFilesTags;
      }) as Activity[];
  }
);

export const selectActivitiesByUserId = createSelector(
  [
    selectActivitiesByIds,
    selectCurrentUserState,
    (_, __, ___, userId: string) => userId,
    (_, __, ___, userId: string, isTeacherApp: boolean) => isTeacherApp,
  ],
  (activities, currentUser, userId, isTeacherApp) => {
    const filteredActivities = activities.filter((activity) => {
      return (
        (!userId || activity.actor.id === userId) &&
        (isTeacherApp ||
          activity.actor.id === currentUser.uid ||
          !activity.hidden)
      );
    });

    return orderBy(filteredActivities, ['time'], ['desc']);
  }
);

export const selectLoadingActivities = createSelector(
  [selectActivityState],
  (state) => state.loading
);
