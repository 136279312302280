import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { cx } from '@emotion/css';

import styles from './mark-for-review.module.scss';
import { onKeyDownEnter } from '../utils/onKeyDownEnter';

type MarkForReviewProps = {
  isForReview: boolean;
  onMarkForReview: () => void;
  isDisabled?: boolean;
  className?: string;
};

export const MarkForReview = ({
  onMarkForReview,
  isForReview,
  className,
  isDisabled = false,
}: MarkForReviewProps) => {
  const { t } = useTranslation();

  const markForReviewKeyboard = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (onKeyDownEnter(event)) {
      onMarkForReview();
    }
  };

  return (
    <button
      className={cx(className, styles.reviewButton, {
        [styles.active]: isForReview,
      })}
      onClick={onMarkForReview}
      aria-label="markForReview"
      tabIndex={0}
      onKeyDown={markForReviewKeyboard}
      disabled={isDisabled}
    >
      {isForReview ? t('marked_for_review') : t('mark_for_review')}
    </button>
  );
};
