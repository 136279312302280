import { GetClassroomsBySchoolIdUseCase } from '@xq/usecases/GetClassroomsBySchoolIdUseCase';
import { loggerService } from '@xq/infrastructure';

export const classroomNameUniqueValidation = async (
  classroomName: string,
  schoolId: string,
  classroomIdToSkip?: string
) => {
  if (schoolId && classroomName) {
    try {
      const fetchedClassrooms = await GetClassroomsBySchoolIdUseCase.execute(
        schoolId
      );

      const classroomNameExists = fetchedClassrooms.find(
        (classroom) =>
          classroom.name === classroomName && classroom.id !== classroomIdToSkip
      );

      return !classroomNameExists;
    } catch (e) {
      loggerService.error(e);
      return false;
    }
  }

  return true;
};
