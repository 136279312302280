import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import { NewActivity } from 'getstream';

import { FeedSlugs, Mention, User, UserRoles } from '@xq/domain';
import {
  NotificationObjects,
  NotificationSupportedVerbs,
} from '@xq/shared/data-access';

import {
  selectCurrentUserClassroom,
  selectCurrentUserClassroomTeachers,
  selectCurrentUserProgram,
  selectUsersEntities,
  useAppSelector,
} from '../../store';
import { useApi } from '../../ApiProvider/ApiProvider';

export interface NotificationItem extends NewActivity {
  objectType?: NotificationObjects;
  message?: string;
  verb: NotificationSupportedVerbs | string;
  notificationId?: string;
  isRead?: boolean;
  isSeen?: boolean;
  classroomId?: string;
  programId?: string;
}

type NotifierState = {
  notifyJournalFeed: (mentions: Mention[], activityId: string) => void;
  notifyForReview: (activityId: string, isForReview: boolean) => void;
  notifyTeachersForDocumentReview: (
    fileName: string,
    isForReview: boolean
  ) => void;
  notifyStudentForDocumentedReviewed: (
    fileName: string,
    studentId: string
  ) => void;
  notifyTeachersForCalculatorReview: (
    calculatorTitle: string,
    forReview: boolean
  ) => void;
  notifyStudentForCalculatorReviewed: (
    calculatorTitle: string,
    studentId: string
  ) => void;

  notifyFinishedPhase: (phaseName: string, moduleName: string) => void;
};

const NotifierContext = createContext<NotifierState | undefined>(undefined);

export const NotifierContextProvider = ({ children }: PropsWithChildren) => {
  const { feedClient } = useApi();

  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const teachers = useAppSelector(selectCurrentUserClassroomTeachers);
  const userEntities = useAppSelector(selectUsersEntities);

  const addNotification = useCallback(
    (
      mentionId: string,
      object: string,
      verb: NotificationSupportedVerbs,
      message?: string
    ) => {
      const currentStreamUser = feedClient?.currentUser ?? '';

      const notificationItem: NotificationItem = {
        actor: currentStreamUser,
        verb,
        objectType: NotificationObjects.Stream,
        object,
        classroomId: currentClassroom?.id,
        programId: currentProgram?.id,
        message,
      };

      feedClient
        ?.feed(FeedSlugs.notification, mentionId)
        .addActivity(notificationItem);
    },
    [currentClassroom?.id, currentProgram?.id, feedClient]
  );

  const mapMentionUsers = useCallback(
    (mentions: Mention[]) => {
      return mentions
        .map((m) => userEntities[m.url])
        .filter((user): user is User => !!user);
    },
    [userEntities]
  );

  const isTeacherOrAdminRole = (roles: UserRoles[]) =>
    roles.some((role) => [UserRoles.admin, UserRoles.teacher].includes(role));

  const notifyJournalFeed = useCallback(
    (mentions: Mention[], activityId: string) => {
      const users = mapMentionUsers(mentions);

      users.forEach((user) => {
        const verb = isTeacherOrAdminRole(user.roles)
          ? NotificationSupportedVerbs.taggedTeacherJournalFeed
          : NotificationSupportedVerbs.taggedStudentJournalFeed;

        addNotification(user.uid, activityId, verb);
      });
    },
    [mapMentionUsers, addNotification]
  );

  const notifyForReview = useCallback(
    (activityId: string, isForReview: boolean) => {
      const verb = isForReview
        ? NotificationSupportedVerbs.addedForReview
        : NotificationSupportedVerbs.removedFromReview;

      teachers.forEach((teacher) => {
        addNotification(teacher.uid, activityId, verb);
      });
    },
    [teachers, addNotification]
  );

  const notifyFinishedPhase = useCallback(
    (phaseName: string, moduleName: string) => {
      teachers.forEach((teacher) => {
        addNotification(
          teacher.uid,
          JSON.stringify({ phaseName, moduleName }),
          NotificationSupportedVerbs.finishedPhase
        );
      });
    },
    [teachers, addNotification]
  );

  const notifyTeachersForDocumentReview = useCallback(
    (fileName: string, isForReview: boolean) => {
      const verb = isForReview
        ? NotificationSupportedVerbs.addedDocumentForReview
        : NotificationSupportedVerbs.removedDocumentFromReview;

      teachers.forEach((teacher) => {
        addNotification(teacher.uid, JSON.stringify({ fileName }), verb);
      });
    },
    [teachers, addNotification]
  );

  const notifyTeachersForCalculatorReview = useCallback(
    (calculatorTitle: string, forReview: boolean) => {
      const verb = forReview
        ? NotificationSupportedVerbs.addedCalculatorForReview
        : NotificationSupportedVerbs.removedCalculatorFromReview;

      teachers.forEach((teacher) => {
        addNotification(teacher.uid, JSON.stringify({ calculatorTitle }), verb);
      });
    },
    [teachers, addNotification]
  );

  const notifyStudentForCalculatorReviewed = useCallback(
    (calculatorTitle: string, studentId: string) => {
      const verb = NotificationSupportedVerbs.reviewedCalculator;

      addNotification(studentId, JSON.stringify({ calculatorTitle }), verb);
    },
    [addNotification]
  );

  const notifyStudentForDocumentedReviewed = useCallback(
    (fileName: string, studentId: string) => {
      const verb = NotificationSupportedVerbs.reviewedDocument;

      addNotification(studentId, JSON.stringify({ fileName }), verb);
    },
    [addNotification]
  );

  return (
    <NotifierContext.Provider
      value={{
        notifyJournalFeed,
        notifyForReview,
        notifyTeachersForDocumentReview,
        notifyStudentForDocumentedReviewed,
        notifyTeachersForCalculatorReview,
        notifyStudentForCalculatorReviewed,
        notifyFinishedPhase,
      }}
    >
      {children}
    </NotifierContext.Provider>
  );
};

export const useNotifier = () => {
  const context = useContext(NotifierContext);

  if (!context) {
    throw new Error(
      'useNotifier must be used within a NotifierContextProvider'
    );
  }

  return context;
};
