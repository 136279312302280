import { useEffect } from 'react';
import {
  collection,
  documentId,
  onSnapshot,
  Query,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { chunk } from 'lodash';

import {
  ClassroomRepository,
  firebaseService,
  loggerService,
  ProgramRepository,
  subscriptionManager,
} from '@xq/infrastructure';
import {
  ClassroomMapper,
  FbClassroomDto,
  CollectionNames,
} from '@xq/shared/data-access';
import { allEqual } from '@xq/shared/utils';
import { filterStudentClassrooms } from '@xq/domain';

import { useMemoCompare } from './useMemoCompare';
import { classroomActions, useAppDispatch } from '../store';

const programRepo = new ProgramRepository();
const classroomRepo = new ClassroomRepository();
const classroomMapper = ClassroomMapper.getInstance();

interface ClassroomsSubscriptionProps {
  classroomIds: string[];
  isStudent: boolean;
}

export const useClassroomsSubscription = ({
  classroomIds,
  isStudent,
}: ClassroomsSubscriptionProps) => {
  const dispatch = useAppDispatch();
  const shouldCreateQuery = useMemoCompare(classroomIds, (prev, next) => {
    if (!prev) {
      return false;
    }

    return allEqual(next, prev);
  });

  const mapClassroomsData = async (classroomsData: FbClassroomDto[]) => {
    const activeProgramsPerClassroom: Record<string, string[]> = {};

    let classrooms = await Promise.all(
      classroomsData.map(async (classroom) => {
        const activeProgramIds = await programRepo.filterInactiveProgramsByIds(
          classroom.programIds
        );

        activeProgramsPerClassroom[classroom.id] = activeProgramIds;

        const studentFolderIdByEmailByProgramId =
          await classroomRepo.getStudentFolderIdByEmailByProgramId(
            classroom.id
          );

        return classroomMapper.toDomain(
          classroom,
          studentFolderIdByEmailByProgramId
        );
      })
    );

    if (isStudent) {
      classrooms = filterStudentClassrooms(
        classrooms,
        activeProgramsPerClassroom
      );
    }

    dispatch(classroomActions.setMany(classrooms));

    dispatch(classroomActions.setLoading(false));
  };

  useEffect(() => {
    const queries = chunk(classroomIds, 10).map((ids: string[]) => {
      return query(
        collection(firebaseService.db, CollectionNames.classrooms),
        where(documentId(), 'in', ids)
      );
    });

    const subscriptions: Unsubscribe[] = [];

    const subscribe = (q: Query) => {
      const sub = onSnapshot(
        q,
        (data) => {
          const resRooms = data.docs
            ?.map((doc) => ({ ...doc.data(), id: doc.id } as FbClassroomDto))
            .sort((a, b) => a.name.localeCompare(b.name));

          mapClassroomsData(resRooms);
        },
        (e) => {
          loggerService.error('CLASSROOMS SUBSCRIPTION ERROR:', e);
        }
      );

      subscriptions.push(sub);
    };

    queries.forEach(subscribe);

    subscriptionManager.add(subscriptions);

    return () => subscriptionManager.remove(subscriptions);
  }, [shouldCreateQuery]);
};
