import { uniqBy } from 'lodash';

import { User, UserRoles } from '../User';
import { FeedSlugs } from './Feed';
import { Mention } from './Mention';

type HandleSuggestionsProps = {
  currentUser: User;
  feedSlug: FeedSlugs;
  author?: User;
  students: User[];
  teachers: User[];
  currentRole: UserRoles;
};

type SuggestionHandler = (props: HandleSuggestionsProps) => Mention[];

type StrategyPerRole = Record<UserRoles, SuggestionHandler>;

const getUserSuggestions = (users: User[]): Mention[] => {
  return uniqBy(users, 'email').map((user) => ({
    name: user.displayName,
    value: user.email,
    url: user.uid,
  }));
};

const teacherAndAdminStrategy = (props: HandleSuggestionsProps) => {
  if (props.feedSlug === FeedSlugs.student && props.author) {
    return getUserSuggestions([props.author]);
  }
  return getUserSuggestions(props.students);
};

const strategyPerRole: StrategyPerRole = {
  [UserRoles.student]: (props: HandleSuggestionsProps) => {
    if (props.feedSlug === FeedSlugs.student) {
      return getUserSuggestions(props.teachers);
    }

    return getUserSuggestions([...props.teachers, ...props.students]).filter(
      (value) => value.name !== props.currentUser.displayName
    );
  },
  [UserRoles.admin]: (props: HandleSuggestionsProps) => {
    return teacherAndAdminStrategy(props);
  },
  [UserRoles.teacher]: (props: HandleSuggestionsProps) => {
    return teacherAndAdminStrategy(props);
  },
  [UserRoles.viewer]: () => [],
};

export const getMentionSuggestions = (props: HandleSuggestionsProps) => {
  return strategyPerRole[props.currentRole](props);
};
