import { z } from 'zod';

export const ProgramSchema = z.object({
  id: z.string(),
  slug: z.string(),
  title: z.string(),
  createdAt: z.string(),
  modifiedAt: z.string(),
  ordering: z.number().optional(),
  duration: z.number().optional(),
  courseId: z.string().optional(),
  isActive: z.boolean(),
});

export type Program = z.infer<typeof ProgramSchema>;

export const programFactory = (program: Partial<Program>): Program => {
  return {
    createdAt: program.createdAt ?? '',
    isActive: program.isActive ?? true,
    modifiedAt: program.modifiedAt ?? '',
    slug: program.slug ?? '',
    title: program.title ?? '',
    id: program.id ?? '',
    courseId: program.courseId ?? '',
    duration: program.duration ?? 0,
    ordering: program.ordering ?? 0,
  };
};
