import { initializeApp, FirebaseApp, FirebaseOptions } from 'firebase/app';
import {
  getFunctions,
  Functions,
  HttpsCallable,
  connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';
import {
  getAuth,
  Auth,
  AuthCredential,
  connectAuthEmulator,
  GoogleAuthProvider,
} from 'firebase/auth';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import {
  initializeFirestore,
  Firestore,
  connectFirestoreEmulator,
  getFirestore,
  setLogLevel,
} from 'firebase/firestore';

const FIREBASE_OPTIONS: FirebaseOptions = {
  apiKey:
    process.env.NX_PUBLIC_FIREBASE_API_KEY ||
    process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain:
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ||
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId:
    process.env.NX_PUBLIC_FIREBASE_PROJECT_ID ||
    process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket:
    process.env.NX_PUBLIC_FIREBASE_STORAGE_BUCKET ||
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.NX_PUBLIC_FIREBASE_MESSAGING_ID ||
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_ID,
  appId:
    process.env.NX_PUBLIC_FIREBASE_APP_ID ||
    process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId:
    process.env.NX_PUBLIC_FIREBASE_MEASUREMENT_ID ||
    process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export interface IFirebaseService {
  app: FirebaseApp;
  db: Firestore;
  auth: Auth;
  functions: Functions;
  storage: FirebaseStorage;
  prepareAuthCredential(idToken?: string, accessToken?: string): AuthCredential;
  prepareFunctionCaller<P, T>(functionName: string): HttpsCallable<P, T>;
}

export class FirebaseService implements IFirebaseService {
  app: FirebaseApp;

  db: Firestore;

  auth: Auth;

  functions: Functions;

  storage: FirebaseStorage;

  private static instance: IFirebaseService;

  constructor() {
    this.app = initializeApp(FIREBASE_OPTIONS);

    // DELETE THIS FOR ACTIVE DEVELOPMENT
    setLogLevel('silent');

    this.auth = getAuth(this.app);
    this.functions = getFunctions(this.app);
    this.storage = getStorage(this.app);

    if (process.env.NODE_ENV !== 'production') {
      this.db = initializeFirestore(this.app, {
        experimentalForceLongPolling: true,
      });

      connectFunctionsEmulator(this.functions, '127.0.0.1', 5001);

      if (process.env.NX_FIRESTORE_DB === 'local')
        connectFirestoreEmulator(this.db, '127.0.0.1', 8080);

      if (process.env.NX_FIREBASE_AUTH === 'local')
        connectAuthEmulator(this.auth, 'http://127.0.0.1:9099');
    } else {
      this.db = getFirestore(this.app);
    }
  }

  prepareFunctionCaller<P, T>(functionName: string) {
    return httpsCallable<P, T>(this.functions, functionName);
  }

  prepareAuthCredential(idToken: string, accessToken: string): AuthCredential {
    return GoogleAuthProvider.credential(
      idToken,
      accessToken
    ) as AuthCredential;
  }

  public static getInstance(): IFirebaseService {
    if (!FirebaseService.instance) {
      FirebaseService.instance = new FirebaseService();
    }
    return FirebaseService.instance;
  }
}

export const firebaseService: IFirebaseService = FirebaseService.getInstance();
