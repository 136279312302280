import { captureException, withScope } from '@sentry/react';
import { serializeError } from 'serialize-error';

export interface LoggerService {
  log(message?: any, ...optionalParams: any[]): void;
  error(message?: any, ...optionalParams: any[]): void;
}

class ConsoleLogger implements LoggerService {
  log(message?: any, ...optionalParams: any[]) {
    console.log(message, ...optionalParams);
  }

  error(message?: any, ...optionalParams: any[]): void {
    const { systemCheckError, initStructureError, userId } =
      optionalParams[0] || {};
    console.error(serializeError(message), ...optionalParams);

    withScope((scope) => {
      scope.setExtras({ systemCheckError, initStructureError, userId });
      captureException(serializeError(message));
    });
  }
}

export const loggerService = new ConsoleLogger();
