import { createSelector } from '@reduxjs/toolkit';

import { taggedFileAdapter, TaggedFileState } from './tagged-file-slice';
import { selectTaggedFileState } from '../index';

export const {
  selectEntities: selectTaggedFileEntities,
  selectById: selectTaggedFileByUserId,
  selectAll: selectAllTaggedFiles,
} = taggedFileAdapter.getSelectors(selectTaggedFileState);

export const extractTaggedFiles = (allTaggedFiles: TaggedFileState[]) => {
  return allTaggedFiles
    .map((s) => s.files)
    .reduce(
      (prevValue, currValue) => ({
        ...prevValue,
        ...currValue,
      }),
      {}
    );
};

export const selectTaggedFileById = createSelector(
  [selectAllTaggedFiles, (_, fileId: string) => fileId],
  (state, fileId) => {
    const allFiles = extractTaggedFiles(state);

    return allFiles[fileId] ?? [];
  }
);
