import {
  Classroom,
  ClassroomSchema,
  IStudentFolderIdByEmailByProgramId,
  ProviderType,
} from '@xq/domain';

import { FbClassroomDto, FbClassroomSchema } from '../dto/fb-classroom.dto';
import { Mapper } from './mapper';

export class ClassroomMapper extends Mapper<Classroom, FbClassroomDto> {
  private static instance: ClassroomMapper;

  toDomain(
    classroom: FbClassroomDto,
    studentFolderIdByEmailByProgramId?: IStudentFolderIdByEmailByProgramId
  ): Classroom {
    return ClassroomSchema.parse({
      ...classroom,
      participantEmails: classroom.participantEmails ?? [],
      teacherEmails: classroom.teacherEmails ?? [],
      viewerEmails: classroom.viewerEmails ?? [],
      isArchived: classroom.isArchived ?? false,
      programIds: classroom.programIds ?? [],
      id: classroom.id ?? '',
      classroomFolderId: classroom.classroomFolderId ?? '',
      feedFolderId: classroom.feedFolderId ?? '',
      folderIdByProgramId: classroom.folderIdByProgramId ?? {},
      createdAt: this.convertTimestamp(classroom.createdAt)?.toString() ?? null,
      modifiedAt:
        this.convertTimestamp(classroom.modifiedAt)?.toString() ?? null,
      providerModifiedAt:
        this.convertTimestamp(classroom.modifiedAt)?.toString() ?? null,
      archivedOn:
        this.convertTimestamp(classroom.archivedOn!)?.toString() ?? null,
      studentFolderIdByEmailByProgramId:
        studentFolderIdByEmailByProgramId ?? {},
      chatFolderId: classroom.chatFolderId ?? '',
      chatFolderIdByStudentId: classroom?.chatFolderIdByStudentId ?? {},
      providerType: classroom.providerType ?? null,
      providerIdentifier: classroom.providerIdentifier ?? null,
      providerLastSyncStatus: classroom.providerLastSyncStatus ?? null,
    });
  }

  toPersistence(classroom: Classroom): FbClassroomDto {
    return FbClassroomSchema.parse({
      ...classroom,
      providerModifiedAt: this.transformToDate(
        classroom.providerModifiedAt ?? ''
      ),
      providerType: classroom.providerType ?? ProviderType.XQ,
      archivedOn: classroom.archivedOn
        ? this.transformToDate(classroom.archivedOn)
        : null,
      createdAt: this.transformToDate(classroom.createdAt),
      modifiedAt: this.transformToDate(classroom.modifiedAt),
    });
  }

  static getInstance(): ClassroomMapper {
    if (!ClassroomMapper.instance) {
      ClassroomMapper.instance = new ClassroomMapper();
    }
    return ClassroomMapper.instance;
  }
}
