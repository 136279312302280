import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, IconButton, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { cx } from '@emotion/css';
import { cond } from 'lodash';
import PrintIcon from '@mui/icons-material/PrintOutlined';

import { wordPressService } from '@xq/infrastructure';
import { ContentTypes, ACF, Audiences } from '@xq/shared/data-access';

import styles from './resource-content.module.scss';
import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay';
import { usePhase } from '../context';
import { decodeEntities } from '../utils/decodeEntities';
import { TABLET_MEDIA_QUERY } from '../utils';
import { usePrint } from '../hooks';

export const ResourceContent = () => {
  const { t } = useTranslation();
  const { slug = '' } = useParams();
  const navigate = useNavigate();
  const { contentClickHandler } = usePhase();

  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  const resourceContentRef = useRef<HTMLDivElement | null>(null);

  const { startPrinting } = usePrint({
    ref: resourceContentRef,
    canvasOptions: {
      onclone: (document: Document) => {
        const images = document.querySelectorAll('img');

        images.forEach((i) => {
          i.style.width = '100%';
          i.style.height = '100%';
        });
      },
    },
  });

  const getType = cond([
    [
      (acf: ACF) =>
        acf.audience === Audiences.teacher && acf.type === ContentTypes.main,
      () => t('teacher_guide'),
    ],
    [
      (acf: ACF) =>
        acf.audience === Audiences.student && acf.type === ContentTypes.main,
      () => t('student_content'),
    ],
    [
      (acf: ACF) => acf.type === ContentTypes.justInTime,
      () => t('additional-resources'),
    ],
    [() => true, () => ''],
  ]);

  useEffect(() => {
    setIsLoading(true);

    wordPressService
      .fetchSlugContent(slug)
      .then((data) => {
        if (data[0]) {
          setContent(data[0].content.rendered);
          setTitle(data[0].title.rendered);
          setType(getType(data[0].acf));
        }
      })
      .finally(() => setIsLoading(false));
  }, [slug]);

  return (
    <div ref={resourceContentRef} className={styles.root}>
      <LoadingOverlay isLoading={isLoading} width={'100%'} height={'100%'}>
        <Container className={cx(styles.container, styles.header)}>
          <div className={styles.titleAndButtonWrapper}>
            <div style={{ display: 'flex' }}>
              <ArrowBackIcon
                className={styles.arrowBack}
                onClick={() => {
                  navigate(-1);
                }}
                fontSize="small"
              />
              <div>
                <h1 className={styles.title}>{decodeEntities(title)}</h1>
                <h2 className={styles.subTitle}>{type}</h2>
              </div>
            </div>

            {!isTablet && (
              <IconButton onClick={startPrinting}>
                <PrintIcon style={{ color: 'var(--black)' }} />
              </IconButton>
            )}
          </div>
        </Container>
        <Container
          onClick={contentClickHandler}
          className={styles.container}
          dangerouslySetInnerHTML={{ __html: content }}
        ></Container>
      </LoadingOverlay>
    </div>
  );
};
