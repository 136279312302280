import {
  FollowedUpUsersProps,
  IClassroomRepository,
  ClassroomRepository,
} from '@xq/infrastructure';

export class PersistFollowedStudentsUseCase {
  classroomRepository: IClassroomRepository;

  constructor() {
    this.classroomRepository = new ClassroomRepository();
  }

  async execute(props: FollowedUpUsersProps): Promise<Array<string>> {
    return this.classroomRepository.persistFollowedUpUsersByClassroomId(props);
  }
}
