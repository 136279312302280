import { findIndex, orderBy } from 'lodash';

import type { User } from './User';
import type { Document } from '../Document';

const getClassroomIdWithProgramId = (
  classroomId: string,
  programId: string
) => {
  return `${classroomId}-${programId}`;
};

export const getJournalTagFiles = (
  classroomId: string,
  programId: string,
  student: User
) => {
  if (student.journalTagFilesPerClassroom) {
    const files =
      student.journalTagFilesPerClassroom[
        getClassroomIdWithProgramId(classroomId, programId)
      ];

    if (!files) {
      return [];
    }

    return [...Object.values(files).flat()];
  }

  return [];
};

export const getJournalTagPosts = (
  classroomId: string,
  programId: string,
  student: User
) => {
  if (student.journalTagPostsPerClassroom) {
    const tags =
      student.journalTagPostsPerClassroom[
        getClassroomIdWithProgramId(classroomId, programId)
      ];

    if (!tags) {
      return [];
    }

    return [...Object.values(tags).flat()];
  }

  return [];
};

export const orderDocumentsByTemplateId = (
  documents: Document[],
  templateIds: string[]
) => {
  return orderBy(
    documents,
    [
      (doc) =>
        findIndex(templateIds, (id) => id === doc.properties?.templateId),
      'createdTime',
    ],
    ['asc', 'desc']
  );
};

export const orderDocumentsByDate = (
  documents: Document[],
  modifier: 'createdTime' | 'modifiedTime' = 'createdTime'
) => {
  return orderBy(documents, [modifier], ['desc']);
};

export const getUniqueJournalTags = (
  classroomId: string,
  programId: string,
  student: User
) => {
  const tagFiles = getJournalTagFiles(classroomId, programId, student);
  const tagPost = getJournalTagPosts(classroomId, programId, student);

  return [...new Set([...tagFiles, ...tagPost])];
};

export const getAllJournalTags = (
  classroomId: string,
  programId: string,
  student: User
) => {
  const tagFiles = getJournalTagFiles(classroomId, programId, student);
  const tagPost = getJournalTagPosts(classroomId, programId, student);

  return [...tagFiles, ...tagPost];
};
