import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { WizardContent } from '@xq/domain';
import { fetchWizardContent } from './wizard-content-actions';

type WizardContentSliceState = WizardContent & { body: string };

export const wizardContentAdapter =
  createEntityAdapter<WizardContentSliceState>({
    selectId: (wizardContent) => wizardContent.slug,
  });

export const wizardContentSlice = createSlice({
  name: 'wizard-content',
  initialState: wizardContentAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWizardContent.fulfilled, (state, action) => {
      wizardContentAdapter.setMany(state, action.payload);
    });
  },
});

export const wizardContentActions = wizardContentSlice.actions;
