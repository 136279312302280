import { ModalBody } from '../SimpleModal/ModalBody';
import { SimpleModal } from '../SimpleModal/SimpleModal';
import { useModal } from '../context';

export const Modal = () => {
  const {
    resetModal,
    onConfirm,
    onCancel,
    onClose,
    dispatchModal,
    state: {
      isOpen,
      bodyText,
      titleText,
      actionButtonText,
      loading,
      showIcon,
      showActionButton,
      cancelButtonText,
      hideCancel,
      width,
      resetOnConfirm = true,
    },
  } = useModal();

  const handleOnClose = async () => {
    await onClose?.current?.();
    resetModal();
  };

  const handleOnConfirm = async () => {
    await onConfirm?.current?.();
    if (resetOnConfirm) {
      resetModal();
    }
  };

  const handleOnCancel = () => {
    dispatchModal({ isOpen: false });
    onCancel?.current?.();
    resetModal();
  };

  return (
    <SimpleModal onClose={handleOnClose} open={isOpen}>
      <ModalBody
        width={width}
        loading={loading}
        handleAction={handleOnConfirm}
        handleClose={handleOnClose}
        handleCancel={hideCancel ? undefined : handleOnCancel}
        bodyText={bodyText}
        titleText={titleText}
        actionButtonText={actionButtonText}
        cancelButtonText={cancelButtonText}
        showActionButton={showActionButton}
        showIcon={showIcon}
      />
    </SimpleModal>
  );
};
