import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cx } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';
import { uniq } from 'lodash';

import {
  AdministrationTabs,
  currentUserActions,
  InfoField,
  LoadingOverlay,
  newSchoolActions,
  selectCurrentUserState,
  selectNewSchoolState,
  StyledButton,
  StyledFooter,
  StyledLayoutContainer,
  StyledNextButton,
  useAppDispatch,
  useAppSelector,
  useGoogleScopesChecker,
  useNavigationWarningModal,
  useToaster,
} from '@xq/web-components';
import { CreateSchoolUseCase, SendEmailUseCase } from '@xq/usecases';
import { RETOOL_URL, TEACHER_APP_ROUTES } from '@xq/web-config';
import { EmailMessageObject, extractUserDomain } from '@xq/domain';
import { CreateSchoolRequestDto } from '@xq/shared/data-access';

import styles from '../ClassroomSummary/classroom-summary.module.scss';
import { CreateFormHeader } from '../../components/CreateFormHeader';

const XQ_EMAIL = process.env.NX_PUBLIC_MAILCHIMP_RECEIVER_EMAIL || '';

export const SchoolSummary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [shouldNavigate, setShouldNavigate] = useState(false);

  const currentUser = useAppSelector(selectCurrentUserState);
  const { domains, name, state, city, website } =
    useAppSelector(selectNewSchoolState);

  const { requestScopes } = useGoogleScopesChecker();

  const [shouldShowNavigationModal, setShouldShowNavigationModal] =
    useState<boolean>(true);

  useBeforeUnload();

  const isConfirmEnabled = name && state;

  useNavigationWarningModal({
    when: shouldShowNavigationModal,
    safeRoute: [
      TEACHER_APP_ROUTES.getWhitelistDomains(),
      TEACHER_APP_ROUTES.getAdministration(AdministrationTabs.schools),
    ],
  });

  const createSchool = async ({
    adminEmail,
    domains,
    name,
    state,
    website,
    city,
  }: CreateSchoolRequestDto) => {
    if (!isConfirmEnabled) return;

    setShouldShowNavigationModal(false);

    if (!(await requestScopes())) {
      return;
    }

    const response = await CreateSchoolUseCase.execute({
      adminEmail,
      domains,
      name,
      state,
      website,
      city,
    });

    if (response?.data) {
      const {
        id,
        adminEmail,
        name: schoolName,
        website: schoolWebsite,
        domains: schoolDomains,
      } = response.data;

      const emailMessage: EmailMessageObject = {
        from_email: 'No-reply@xqinstitute.org',
        subject: t('create_school_email_subject'),
        html: t('create_school_email_body', {
          schoolName,
          schoolWebsite,
          schoolDomains: schoolDomains.join(', '),
          adminEmail,
          retoolUrl: RETOOL_URL,
        }),
        to: [
          {
            email: XQ_EMAIL,
            type: 'to',
          },
        ],
      };

      await SendEmailUseCase.execute([emailMessage]);

      dispatch(
        currentUserActions.updateCurrentSchoolId({
          currentSchoolId: id,
        })
      );

      dispatch(newSchoolActions.resetNewSchool());

      setShouldNavigate(true);
    } else {
      setShouldShowNavigationModal(true);
    }
  };

  const { status, execute } = useToaster<CreateSchoolRequestDto, void>({
    successMessage: t('create_school_success'),
    errorMessage: t('create_school_error'),
    callback: createSchool,
  });

  useEffect(() => {
    if (shouldNavigate)
      navigate(
        TEACHER_APP_ROUTES.getAdministration(AdministrationTabs.schools)
      );
  }, [shouldNavigate]);

  return (
    <LoadingOverlay
      isLoading={status === 'loading'}
      height={'100%'}
      width={'100%'}
    >
      <StyledLayoutContainer>
        <CreateFormHeader
          activeIndex={3}
          completeIndexes={[0, 1, 2]}
          title={t('school_summary')}
          exitPath={TEACHER_APP_ROUTES.getAdministration(
            AdministrationTabs.schools
          )}
        />
        <div style={{ display: 'flex' }}>
          <div
            className={styles.infoContainer}
            style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}
          >
            <h1 className={cx(styles.title)}>{t('school_information')}</h1>
            <InfoField
              classes={{
                root: cx(styles.pb16, styles.pr16),
              }}
              label={t('city_town')}
              text={city}
            />
            <InfoField label={t('state')} text={state} />
            <InfoField
              classes={{
                root: cx(styles.pb16, styles.pr16),
              }}
              label={t('school_name')}
              text={name}
            />
            <InfoField
              classes={{
                root: cx(styles.pb16, styles.pr16),
              }}
              label={t('school_website')}
              text={website}
            />
          </div>
          <div className={styles.infoContainer} style={{ paddingTop: 55 }}>
            <InfoField label={t('school_domain')} text={domains?.join(', ')} />
            <InfoField
              label={t('domain_link')}
              text={extractUserDomain(currentUser.email)}
            />
          </div>
        </div>

        <StyledFooter>
          <StyledButton
            onClick={() => {
              navigate(TEACHER_APP_ROUTES.getWhitelistDomains());
            }}
          >
            {t('back')}
          </StyledButton>
          <StyledNextButton
            onClick={async () => {
              await execute({
                name,
                adminEmail: currentUser.email,
                city,
                domains: uniq([
                  ...(domains?.filter(Boolean) || []),
                  extractUserDomain(currentUser.email),
                ]),
                state,
                website,
              });
            }}
            disabled={!isConfirmEnabled}
          >
            {t('confirm')}
          </StyledNextButton>
        </StyledFooter>
      </StyledLayoutContainer>
    </LoadingOverlay>
  );
};
