import { firebaseService } from '@xq/infrastructure';
import { callWithRetry } from '@xq/shared/utils';

export class CheckUserDomainForSchoolAdminUseCase {
  static async execute() {
    try {
      const checkSchoolDomainsForAdmin = firebaseService.prepareFunctionCaller<
        Record<string, never>,
        boolean
      >('checkSchoolDomainsForAdmin');
      const res = await callWithRetry(() => checkSchoolDomainsForAdmin({}));

      return res.data;
    } catch (e) {
      throw new Error('Error checking user domain for school admin');
    }
  }
}
