import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const initGoogleAPIs = () => {
  if (!gapi?.client) {
    gapi.load('client', async () => {
      await gapi?.client?.init({
        apiKey: process.env.NX_PUBLIC_FIREBASE_API_KEY,
      });

      gapi.load('client:picker', async () => {
        await gapi?.client?.load(
          'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
        );
      });
    });
  }
};

export const initSentry = (appDNS: string) => {
  if (appDNS) {
    init({
      dsn: appDNS,
      autoSessionTracking: false,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: process.env.NX_PUBLIC_SENTRY_ENV,
      maxValueLength: 600,
      debug:
        process.env.NX_PUBLIC_NODE_ENV === 'development' ||
        process.env.NX_PUBLIC_SENTRY_ENV === 'development',
    });
  }
};
