import { z } from 'zod';

import { GenerateTemplateStatusEnum, SchoolStatusEnum } from '@xq/domain';

export const FbSchoolSchema = z.object({
  adminEmail: z.string(),
  city: z.string(),
  classroomsIds: z.array(z.string().min(1)),
  domains: z.string().array(),
  driveRootFolderId: z.string().optional(),
  id: z.string(),
  name: z.string(),
  state: z.string(),
  demoTeacherWhiteList: z.string().array().optional(),
  demoStudentWhiteList: z.record(z.string(), z.string()).optional(),
  templatesFolderId: z.string().optional(),
  folderIdByTeacherEmail: z.record(z.string(), z.string()).optional(),
  website: z.string(),
  status: SchoolStatusEnum,
  generateTemplatesStatus: GenerateTemplateStatusEnum.optional(),
  generateTeacherTemplatesStatus: GenerateTemplateStatusEnum.optional(),
});

export type FbSchoolDto = z.infer<typeof FbSchoolSchema>;
