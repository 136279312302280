import { StreamClient } from 'getstream';

import { FeedMapper, FeedService, StreamFeedService } from '@xq/infrastructure';
import { ReactionKinds } from '@xq/domain';
import { StreamCommentReaction } from '@xq/shared/data-access';

export type GetActivityCommentsProps = {
  streamToken: string;
  activityId: string;
  idLt?: string;
};

export class GetActivityCommentsUseCase {
  static async execute({
    activityId,
    streamToken,
    idLt,
  }: GetActivityCommentsProps) {
    const client = StreamFeedService.getInstance(streamToken).client;

    const response = await FeedService.getInstance(
      client as StreamClient
    ).getReactions<StreamCommentReaction>(
      activityId,
      ReactionKinds.comment,
      idLt
    );

    return FeedMapper.toDomainComments(response);
  }
}
