import { FirebaseError } from 'firebase/app';

import { firebaseService } from '@xq/infrastructure';
import { Errors, User } from '@xq/domain';

import {
  CreateSchoolStructureRequestDto,
  CreateSchoolStructureResponseDto,
} from '@xq/shared/data-access';

import { CheckUserScopesUseCase } from './CheckUserScopesUseCase';
import { callWithRetry } from '@xq/shared/utils';

const DRIVE_FILE_SCOPE = process.env.NX_PUBLIC_DRIVE_FILE_SCOPE || '';

export class CreateSchoolDriveStructureUseCase {
  static async execute(adminEmail: string, schoolId: string): Promise<string> {
    const hasScopes = await CheckUserScopesUseCase.execute(DRIVE_FILE_SCOPE);

    if (!hasScopes) {
      throw new Error(Errors.noGoogleDriveScope);
    }

    const createSchoolStructure = firebaseService.prepareFunctionCaller<
      CreateSchoolStructureRequestDto,
      CreateSchoolStructureResponseDto
    >('createClassroomStructure');

    const { token_type: tokenType, access_token: accessToken } =
      gapi.client.getToken() as unknown as {
        token_type: string;
        access_token: string;
      };

    try {
      const res = await callWithRetry(() =>
        createSchoolStructure({
          tokenType,
          accessToken,
          email: adminEmail,
          schoolId,
        })
      );

      return res?.data?.message;
    } catch (err: unknown) {
      if (
        err instanceof FirebaseError &&
        err.code === 'functions/already-exists'
      ) {
        throw new Error(Errors.structureAlreadyCreated);
      }

      throw new Error(Errors.structureInitializationFailed);
    }
  }
}
