import { MouseEvent, KeyboardEvent } from 'react';
import { EventTypes } from '../enums/EventTypes';

export const onKeyDownEnter = (
  event:
    | KeyboardEvent<HTMLDivElement>
    | MouseEvent<SVGSVGElement>
    | KeyboardEvent<SVGSVGElement>
    | KeyboardEvent
) => {
  if (
    event.type === EventTypes.keyDown &&
    (event as KeyboardEvent).key === 'Enter'
  )
    return true;
};
