import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const StyledAppContainer = styled('div')`
  color: var(--grey-dark);
`;

type StyledGridProps = {
  height: string;
  width: string;
};

export const StyledGrid = styled(Grid)<StyledGridProps>(
  ({ height, width }) => ({
    width,
    float: 'right',
    height,
    position: 'relative',
    overflow: 'auto',
  })
);

export const StyledBanner = styled('div')(() => ({
  fontWeight: 'bold',
  color: 'white',
  textTransform: 'uppercase',
  '&:hover': {
    cursor: 'pointer',
  },
}));
