import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

import { FormInputField } from '@xq/web-components';
import { School, SCHOOL_STATES } from '@xq/domain';

import { StyledFieldRow } from '../Layout/FieldRow';

type SchoolInfoEditProps<T> = {
  formik: FormikProps<T>;
};

export const SchoolInfoEdit = <
  T extends Pick<School, 'name' | 'state' | 'city' | 'website'>
>({
  formik,
}: SchoolInfoEditProps<T>) => {
  const { t } = useTranslation();

  return (
    <StyledFieldRow>
      <FormInputField
        fieldName="name"
        label={t('school_name')}
        formik={formik}
        onBlur={(e) => {
          formik.setFieldError('name', undefined);
          formik.handleBlur(e);
        }}
      />
      <FormInputField
        fieldName="state"
        label={t('state')}
        formik={formik}
        select
      >
        {SCHOOL_STATES.map((schoolState) => (
          <MenuItem key={schoolState} value={schoolState}>
            {schoolState}
          </MenuItem>
        ))}
      </FormInputField>
      <FormInputField fieldName="city" label={t('city_town')} formik={formik} />
      <FormInputField
        fieldName="website"
        label={t('school_website')}
        formik={formik}
        helperText={t('needed_to_get_approval_for_your_school')}
      />
    </StyledFieldRow>
  );
};
