import { useMemo } from 'react';

import { FeedSlugs, getMentionSuggestions } from '@xq/domain';

import {
  selectCurrentRole,
  selectCurrentUserClassroomStudents,
  selectCurrentUserClassroomTeachers,
  selectCurrentUserState,
  selectUserById,
  useAppSelector,
} from '../store';

export const useMentionSuggestions = (
  feedSlug: FeedSlugs,
  authorId?: string
) => {
  const currentUser = useAppSelector(selectCurrentUserState);
  const author = useAppSelector((state) =>
    selectUserById(state, authorId ?? '')
  );
  const students = useAppSelector(selectCurrentUserClassroomStudents);
  const teachers = useAppSelector(selectCurrentUserClassroomTeachers);
  const currentRole = useAppSelector(selectCurrentRole);

  const mentionSuggestions = useMemo(
    () =>
      getMentionSuggestions({
        currentUser,
        author,
        feedSlug,
        students,
        teachers,
        currentRole,
      }),
    [currentUser, author, feedSlug, students, teachers, currentRole]
  );

  return mentionSuggestions;
};
