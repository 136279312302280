import { styled } from '@mui/material';
import { Paper, MenuItem, MenuList } from '@mui/material';

interface Item {
  itemName: string;
  itemIcon: JSX.Element;
  onClick: () => void;
}
export interface MobileModalProps {
  menuItems: Item[];
  menuHeader: Item;
}
const StyledItem = styled(MenuItem)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledHeader = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  padding: 6px 16px;
  font-size: 16px;
  min-height: 48px;
`;

export const StyledItemName = styled('span')`
  padding: 6px 16px;
`;

export const MobileModalBody = ({
  menuItems,
  menuHeader,
}: MobileModalProps) => {
  return (
    <Paper sx={{ width: 320, maxWidth: '100%' }}>
      <StyledHeader>
        <span>{menuHeader.itemName}</span>
        {menuHeader.itemIcon}
      </StyledHeader>
      <MenuList>
        {menuItems.map((item, index) => (
          <StyledItem key={index} onClick={() => item.onClick()}>
            {item.itemIcon}
            <StyledItemName>{item.itemName}</StyledItemName>
          </StyledItem>
        ))}
      </MenuList>
    </Paper>
  );
};
