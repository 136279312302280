import { UserRoles } from '../User';
import { Scopes } from './scopes';
import {
  adminScopes,
  studentScopes,
  teacherScopes,
  viewerScopes,
} from './scopesPerRole';

export const PERMISSIONS = {
  [UserRoles.student]: studentScopes,
  [UserRoles.viewer]: viewerScopes,
  [UserRoles.teacher]: teacherScopes,
  [UserRoles.admin]: adminScopes,
};

export const hasPermission = ({
  permissions,
  scopes,
}: {
  permissions: Scopes[];
  scopes: Scopes[];
}) => scopes.every((s) => permissions.includes(s));
