import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Phase } from '@xq/domain';

import {
  programPostActions,
  SelectedPhasePayload,
  useAppDispatch,
} from '../../store';

type SubPhaseState = {
  phase: Phase;
  tabIndex: string;
  subPhaseIndex: string;
  subPhaseContentIndex: string;
  setFocusedSubPhaseIndex: (
    newSubPhaseIndex: string,
    newSubPhaseContentIndex?: string
  ) => void;
  setFocusedSubPhaseContentIndex: (
    newSubPhaseIndex: string,
    newSubPhaseContentIndex: string
  ) => void;
  onNextSubPhase: () => void;
  onPreviousSubPhase: () => void;
  hasNext: boolean;
  hasPrevious: boolean;
};

const SubPhaseContext = createContext<SubPhaseState | undefined>(undefined);

type SubPhaseContextProviderProps = PropsWithChildren<{
  phasePath: (
    phaseIndex: string,
    tab: string,
    subPhase: string,
    subPhaseContentIndex: string
  ) => string;
  phase: Phase;
  tabIndex: string;
  subPhaseIndex: string;
  subPhaseContentIndex: string;
}>;

export const SubPhaseContextProvider = ({
  children,
  phasePath,
  phase,
  tabIndex,
  subPhaseIndex,
  subPhaseContentIndex,
}: SubPhaseContextProviderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { phaseIndex = '0' } = useParams();

  const setSelectedPhase = useCallback(
    (payload: SelectedPhasePayload) => {
      navigate(
        phasePath(
          payload.phaseIndex,
          payload.tabIndex,
          payload.subPhaseIndex,
          payload.subPhaseContentIndex
        )
      );

      dispatch(programPostActions.setSelectedPhase(payload));
    },
    [dispatch, navigate, phasePath]
  );

  const onSubPhaseIndexChange = useCallback(
    (newSubPhaseIndex: string, newSubPhaseContentIndex: string) => {
      const calculatedSubPhaseContentIndex =
        subPhaseContentIndex !== newSubPhaseContentIndex &&
        newSubPhaseContentIndex
          ? newSubPhaseContentIndex
          : '-1';

      const calculatedSubPhaseIndex =
        (subPhaseIndex !== newSubPhaseIndex && newSubPhaseIndex) ||
        calculatedSubPhaseContentIndex !== '-1'
          ? newSubPhaseIndex
          : '-1';

      const payload: SelectedPhasePayload = {
        phaseIndex,
        tabIndex,
        subPhaseIndex: calculatedSubPhaseIndex,
        subPhaseContentIndex: calculatedSubPhaseContentIndex,
      };

      setSelectedPhase(payload);
    },
    [phaseIndex, setSelectedPhase]
  );

  const onNextSubPhase = useCallback(() => {
    if (Array.isArray(phase) || !phase.subPhaseValues) {
      return;
    }

    const currentSubPhase = phase.subPhaseValues[+subPhaseIndex];

    const isLastPhaseIndex = +subPhaseIndex <= phase.subPhaseValues?.length - 1;
    const hasMoreSubPhaseContent =
      +subPhaseContentIndex <
      (currentSubPhase?.subPhaseContent?.length ?? 1) - 1;

    const parentIndex =
      (isLastPhaseIndex && !hasMoreSubPhaseContent) || subPhaseIndex === '-1'
        ? +subPhaseIndex + 1
        : +subPhaseIndex;

    const childIndex = hasMoreSubPhaseContent
      ? +subPhaseContentIndex + 1
      : +subPhaseContentIndex;

    onSubPhaseIndexChange(
      parentIndex.toString(),
      parentIndex !== +subPhaseIndex ? '-1 ' : childIndex.toString()
    );
  }, [onSubPhaseIndexChange, phase, subPhaseContentIndex, subPhaseIndex]);

  const onPreviousSubPhase = useCallback(() => {
    if (Array.isArray(phase) || !phase.subPhaseValues) {
      return;
    }

    const decrementedSubPhaseContentIndex = +subPhaseContentIndex - 1;
    const decrementedSubPhaseIndex = +subPhaseIndex - 1;

    const childIndex =
      decrementedSubPhaseContentIndex >= 0
        ? decrementedSubPhaseContentIndex
        : -1;
    const parentIndex =
      decrementedSubPhaseIndex >= 0 ? decrementedSubPhaseIndex : +subPhaseIndex;

    const previousSubPhase = phase.subPhaseValues[parentIndex];
    const previousSubPhaseContentLength =
      previousSubPhase?.subPhaseContent.length;

    const calculatedChildIndex =
      parentIndex !== +subPhaseIndex && previousSubPhaseContentLength
        ? previousSubPhaseContentLength - 1
        : childIndex;

    onSubPhaseIndexChange(
      parentIndex.toString(),
      calculatedChildIndex.toString()
    );
  }, [onSubPhaseIndexChange, phase, subPhaseContentIndex, subPhaseIndex]);

  const setFocusedSubPhaseIndex = useCallback(
    (newSubPhaseIndex: string, newSubPhaseContentIndex = '-1') => {
      const payload: SelectedPhasePayload = {
        phaseIndex,
        tabIndex,
        subPhaseIndex:
          subPhaseIndex === newSubPhaseIndex ? '-1' : newSubPhaseIndex,
        subPhaseContentIndex:
          subPhaseContentIndex === newSubPhaseContentIndex
            ? '-1'
            : newSubPhaseContentIndex,
      };
      setSelectedPhase(payload);
    },
    [phaseIndex, setSelectedPhase]
  );

  const setFocusedSubPhaseContentIndex = useCallback(
    (newSubPhaseIndex: string, newSubPhaseContentIndex: string) => {
      const payload: SelectedPhasePayload = {
        phaseIndex,
        tabIndex,
        subPhaseIndex: newSubPhaseIndex,
        subPhaseContentIndex:
          subPhaseContentIndex !== newSubPhaseContentIndex
            ? newSubPhaseContentIndex
            : '-1',
      };

      setSelectedPhase(payload);
    },
    [phaseIndex, setSelectedPhase]
  );

  const hasNext = useMemo(() => {
    const focusedSubPhaseIndex = +subPhaseIndex;
    const focusedSubPhaseContentIndex = +subPhaseContentIndex;

    const currentSubPhaseContentLength =
      phase.subPhaseValues?.[focusedSubPhaseIndex]?.subPhaseContent.length ?? 0;

    const hasMoreSubPhases =
      phase.subPhaseValues?.length &&
      focusedSubPhaseIndex < phase.subPhaseValues?.length - 1;

    const hasMoreSubPhasesContent =
      focusedSubPhaseContentIndex < currentSubPhaseContentLength - 1;

    return hasMoreSubPhases || hasMoreSubPhasesContent;
  }, [phase.subPhaseValues, subPhaseContentIndex, subPhaseIndex]);

  const hasPrevious = useMemo(() => {
    const focusedSubPhaseIndex = +subPhaseIndex;
    const focusedSubPhaseContentIndex = +subPhaseContentIndex;

    return focusedSubPhaseIndex > 0 || focusedSubPhaseContentIndex > 0;
  }, [subPhaseContentIndex, subPhaseIndex]);

  return (
    <SubPhaseContext.Provider
      value={{
        phase,
        tabIndex,
        subPhaseIndex,
        subPhaseContentIndex,
        setFocusedSubPhaseContentIndex,
        setFocusedSubPhaseIndex,
        hasNext,
        hasPrevious,
        onPreviousSubPhase,
        onNextSubPhase,
      }}
    >
      {children}
    </SubPhaseContext.Provider>
  );
};

export const useSubPhase = () => {
  const context = useContext(SubPhaseContext);

  if (!context) {
    throw new Error(
      'useSubPhase must be used within a SubPhaseContextProvider'
    );
  }

  return context;
};
