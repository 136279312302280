import {
  UpdateSchoolRequestDto,
  UpdateSchoolResponseDto,
} from '@xq/shared/data-access';
import { firebaseService } from '@xq/infrastructure';
import { callWithRetry } from '@xq/shared/utils';

export class UpdateSchoolUseCase {
  static async execute(payload: UpdateSchoolRequestDto) {
    const updateSchool = firebaseService.prepareFunctionCaller<
      UpdateSchoolRequestDto,
      UpdateSchoolResponseDto
    >('updateSchool');

    return callWithRetry(() => updateSchool(payload));
  }
}
