import { StreamClient } from 'getstream';

import { FeedService, StreamFeedService } from '@xq/infrastructure';

export class GetFeedActivitiesUseCase {
  static async execute(streamToken: string, id_lt?: string) {
    const client = StreamFeedService.getInstance(streamToken).client;
    return FeedService.getInstance(client as StreamClient).getFeedActivities({
      withReactionCounts: true,
      id_lt,
    });
  }
}
