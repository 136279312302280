import {
  ChatScopes,
  AppsAccessScopes,
  ClassroomsAdministrationScopes,
  JournalFeedScopes,
  MyFilesScopes,
  NotificationsScopes,
  ResourcesScopes,
  ClassroomsScopes,
  SchoolsScopes,
  CalculatorScopes,
} from '../scopes';

export const teacherScopes = [
  AppsAccessScopes.CAN_LOG_IN_TEACHER_APP,
  ChatScopes.CAN_VIEW,
  ClassroomsAdministrationScopes.CAN_VIEW,
  ClassroomsAdministrationScopes.CAN_CREATE_CLASSROOM_MANUALLY,
  ClassroomsAdministrationScopes.CAN_IMPORT_GOOGLE_CLASSROOM,
  JournalFeedScopes.CAN_COMMENT,
  JournalFeedScopes.CAN_VIEW,
  JournalFeedScopes.CAN_VIEW_TAGS,
  JournalFeedScopes.CAN_FILTER_BY_TAGS,
  MyFilesScopes.CAN_VIEW,
  MyFilesScopes.CAN_VIEW_TAGS,
  MyFilesScopes.CAN_DELETE_TAGS,
  MyFilesScopes.CAN_MARK_FOR_REVIEW,
  NotificationsScopes.CAN_VIEW,
  NotificationsScopes.CAN_DELETE,
  ResourcesScopes.CAN_VIEW,
  ResourcesScopes.CAN_ASSIGN_MODULE_TO_CLASSROOM,
  ClassroomsScopes.CAN_VIEW_OWN,
  ClassroomsScopes.CAN_UPDATE_OWN,
  ClassroomsScopes.CAN_CREATE,
  SchoolsScopes.CAN_VIEW_OWN,
  SchoolsScopes.CAN_VIEW_BY_DOMAIN,
  SchoolsScopes.CAN_UPDATE_OWN,
  SchoolsScopes.CAN_UPDATE_BY_DOMAIN,
  JournalFeedScopes.CAN_REACT_WITH_EMOJIS,
  CalculatorScopes.CAN_MARK_CALCULATOR_FOR_REVIEW
];
