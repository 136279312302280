import { User } from '@firebase/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  PersistUserCurrentSchoolUseCase,
  SetAnalyticsUserPropertiesUseCase,
} from '@xq/usecases';
import { loggerService, UserRepository } from '@xq/infrastructure';
import { UserRoles } from '@xq/domain';

import { currentUserActions } from './current-user-slice';
import { fetchPrograms, fetchSchools, RootState } from '../index';

const userRepository = new UserRepository();

export const persistCurrentClassroomId = createAsyncThunk(
  'current-user/persistCurrentClassroomId',
  async (
    payload: {
      userId: string;
      currentClassroomId: string;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const prevClassroomId = state.currentUser.currentClassroomId ?? '';

    try {
      dispatch(
        currentUserActions.setCurrentClassroomId(payload.currentClassroomId)
      );
      await userRepository.persistUserCurrentClassroomId(
        payload.userId,
        payload.currentClassroomId
      );

      const currentRole =
        state.currentUser?.roleByClassroomId?.[payload.currentClassroomId] ||
        state.currentUser.roles[0];

      const currentClassroom =
        state.classrooms.entities[payload.currentClassroomId];

      const schoolId = currentClassroom?.schoolId;

      if (!schoolId) return;

      const currentSchoolName = state.school.entities[schoolId]?.name;

      SetAnalyticsUserPropertiesUseCase.execute({
        userId: payload.userId,
        properties: {
          role: currentRole,
          classroom: currentClassroom?.name,
          school: currentSchoolName,
        },
      });
    } catch (e) {
      loggerService.error(e);
      dispatch(currentUserActions.setCurrentClassroomId(prevClassroomId));
    }
  }
);

export const persistCurrentProgramId = createAsyncThunk(
  'current-user/persistCurrentProgramId',
  async (
    payload: {
      userId: string;
      classroomId: string;
      programId: string;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const prevProgramId =
      state.currentUser.currentProgramIdByClassroomId[payload.classroomId] ??
      '';

    try {
      dispatch(
        currentUserActions.setCurrentProgramId({
          programId: payload.programId,
          classroomId: payload.classroomId,
        })
      );
      await userRepository.persistUserCurrentProgramId(
        payload.userId,
        payload.classroomId,
        payload.programId
      );
    } catch (e) {
      loggerService.error(e);
      dispatch(
        currentUserActions.setCurrentProgramId({
          programId: prevProgramId,
          classroomId: payload.classroomId,
        })
      );
    }
  }
);

export const persistCurrentSchool = createAsyncThunk(
  'current-user/persistCurrentSchool',
  async (
    payload: { userId: string; schoolId: string },
    { dispatch, getState }
  ) => {
    const useCase = new PersistUserCurrentSchoolUseCase();
    await useCase.execute(payload.userId, payload.schoolId);
    dispatch(
      currentUserActions.updateCurrentSchoolId({
        currentSchoolId: payload.schoolId,
      })
    );

    const state = getState() as RootState;

    const currentClassroomId = state.currentUser.currentClassroomId;

    if (!currentClassroomId) return;

    const currentRole =
      state.currentUser?.roleByClassroomId?.[currentClassroomId] ||
      state.currentUser.roles[0];

    const currentClassroomName =
      state.classrooms.entities[currentClassroomId]?.name;

    const currentSchoolName = state.school.entities[payload.schoolId]?.name;

    SetAnalyticsUserPropertiesUseCase.execute({
      userId: payload.userId,
      properties: {
        role: currentRole,
        classroom: currentClassroomName,
        school: currentSchoolName,
      },
    });
  }
);

type GetCurrentUserDataPayload = {
  fbAuthUser: User;
};

export const getCurrentUserData = createAsyncThunk(
  'current-user/getCurrentUserData',
  async ({ fbAuthUser }: GetCurrentUserDataPayload, { dispatch }) => {
    try {
      const user = await userRepository.getCurrentUser();

      if (!user) {
        return;
      }

      const tokenResult = await fbAuthUser.getIdTokenResult(true);

      const roles = tokenResult?.claims?.roles as UserRoles[];

      const currentUser = {
        ...user,
        roles,
      };

      dispatch(
        currentUserActions.setCurrentUser({
          currentUser,
        })
      );

      dispatch(fetchPrograms());
      await dispatch(fetchSchools(fbAuthUser.email || ''));

      return currentUser;
    } catch (e) {
      loggerService.error(e);
    }
  }
);
