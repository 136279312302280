import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import { cx } from '@emotion/css';

import { Document } from '@xq/domain';
import {
  fetchProgramPost,
  LoadingOverlay,
  PhaseTypeIdentifier,
  programPostActions,
  selectCurrentUserClassroom,
  selectCurrentUserClassroomStudents,
  selectCurrentUserProgram,
  selectDocumentsByPhase,
  selectProgramPost,
  selectProgramPostLoading,
  selectProgramPostSelectedSlug,
  StyledTitle,
  toggleMarkForReview,
  useAppDispatch,
  useAppSelector,
  useNotifier,
  useStudentsFilesSubscription,
  useStudentsSubscriptionByIds,
} from '@xq/web-components';
import { FileForReviewLabel } from '@xq/web-components/FileForReviewLabel/FileForReviewLabel';

import {
  StyledPhase,
  StyledPhaseTitle,
  StyledContainer,
  StyledRoot,
  StyledGrid,
  StyledPhaseTitleContainer,
} from './StudentFilesPage.styles';
import { StudentFilesAccordion } from '../../components/StudentFilesAccordion/StudentFilesAccordion';

const StudentFilesPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { notifyStudentForDocumentedReviewed } = useNotifier();

  const [selectedPhase, setSelectedPhase] =
    useState<PhaseTypeIdentifier>('other');

  const selectedSlug = useAppSelector(selectProgramPostSelectedSlug);
  const programPostLoading = useAppSelector(selectProgramPostLoading);
  const programPost = useAppSelector(selectProgramPost);
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const students = useAppSelector(selectCurrentUserClassroomStudents);

  const classroomStudentIds = useMemo(
    () => students.map((student) => student.uid),
    [students]
  );

  const documentsByPhase = useAppSelector((state) =>
    selectDocumentsByPhase(
      state,
      classroomStudentIds,
      currentClassroom?.id ?? '',
      currentProgram?.id ?? ''
    )
  );

  useStudentsSubscriptionByIds({
    userIds: classroomStudentIds,
  });

  useStudentsFilesSubscription({
    userIds: classroomStudentIds,
    programId: currentProgram?.id,
    classroomId: currentClassroom?.id,
  });

  useEffect(() => {
    if (selectedSlug) {
      dispatch(
        fetchProgramPost({
          slug: selectedSlug,
          isTeacher: false,
        })
      );
    }
  }, [selectedSlug]);

  useEffect(() => {
    if (currentProgram?.slug) {
      dispatch(programPostActions.setSelectedSlug(currentProgram.slug));
    }
  }, [currentProgram?.slug]);

  const phaseName = useMemo(() => {
    const phase = programPost?.studentPhaseValues?.find(
      (f) => f.id === selectedPhase
    );

    return phase ? phase.name : t('other');
  }, [programPost, selectedPhase]);

  const onMarkForReview = (document: Document, userId: string) => {
    if (!currentClassroom?.id || !currentProgram?.id) return;

    const isForReview = !document.properties?.isForReview;

    dispatch(
      toggleMarkForReview({
        fileId: document.id,
        classroomId: currentClassroom.id,
        isForReview,
        programId: currentProgram.id,
        userId,
      })
    );

    notifyStudentForDocumentedReviewed(document.name, userId);
  };

  const filesForReviewByPhase = useMemo(() => {
    return Object.entries(documentsByPhase).reduce(
      (prev, [phaseId, usersDocuments]) => {
        const docsForReview = Object.values(usersDocuments).flatMap((docs) =>
          docs.filter((d) => !!d.properties?.isForReview)
        ).length;

        return {
          ...prev,
          [phaseId]: docsForReview,
        };
      },
      {} as Record<string, number>
    );
  }, [documentsByPhase]);

  return (
    <StyledRoot container spacing={2}>
      <StyledGrid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <StyledContainer>
          <LoadingOverlay
            isLoading={programPostLoading}
            width={'100%'}
            height={'100%'}
          >
            {programPost?.studentPhaseValues?.map((phase) => (
              <StyledPhase
                key={phase.id}
                component="div"
                onClick={() => setSelectedPhase(phase.id)}
              >
                <StyledPhaseTitleContainer>
                  <StyledPhaseTitle
                    className={cx({
                      active: phase.id === selectedPhase,
                    })}
                  >
                    {phase.name}
                  </StyledPhaseTitle>
                  {!!filesForReviewByPhase[phase.id] && (
                    <FileForReviewLabel
                      count={filesForReviewByPhase[phase.id]}
                    />
                  )}
                </StyledPhaseTitleContainer>
                <Divider />
              </StyledPhase>
            ))}
            <StyledPhase
              component="div"
              onClick={() => setSelectedPhase('other')}
            >
              <StyledPhaseTitleContainer>
                <StyledPhaseTitle
                  className={cx({
                    active: 'other' === selectedPhase,
                  })}
                >
                  {t('other')}
                </StyledPhaseTitle>
                {!!filesForReviewByPhase['other'] && (
                  <FileForReviewLabel count={filesForReviewByPhase['other']} />
                )}
              </StyledPhaseTitleContainer>
            </StyledPhase>
          </LoadingOverlay>
        </StyledContainer>
      </StyledGrid>
      <StyledGrid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <StyledContainer>
          <StyledTitle sx={{ margin: '12px 0' }}>{phaseName}</StyledTitle>

          <div>
            {students.map((student) => (
              <StudentFilesAccordion
                key={student.uid}
                student={student}
                documents={
                  documentsByPhase?.[selectedPhase]?.[student.uid] ?? []
                }
                markForReview={onMarkForReview}
              />
            ))}
          </div>
        </StyledContainer>
      </StyledGrid>
    </StyledRoot>
  );
};

export default StudentFilesPage;
