import { ChangeEvent } from 'react';

import { ProgramProgress, SubPhase, SubPhaseContent } from '@xq/domain';

import { Accordions } from '../Accordions/Accordions';
import { PhaseContentFooter } from '../PhaseContentFooter/PhaseContentFooter';
import {
  StyledAccordionsContainer,
  StyleTabContainer,
} from './TabContent.styles';
import { useSubPhase } from '../../context';
import { useSubPhasesRef } from '../../hooks';

type StudentContentProps = {
  programProgress?: ProgramProgress;
  handleSubPhaseCheck?: (
    event: ChangeEvent<HTMLInputElement>,
    subPhase: SubPhase,
    subPhaseContent?: SubPhaseContent
  ) => void;
  panelIndex: number;
  onScroll?: () => void;
};

export const StudentContentPanel = ({
  handleSubPhaseCheck,
  programProgress,
  onScroll,
  panelIndex,
}: StudentContentProps) => {
  const { phase } = useSubPhase();
  const { assignSubPhaseRef } = useSubPhasesRef(panelIndex.toString());

  return (
    <>
      {phase?.subPhaseValues?.length && (
        <StyleTabContainer>
          <StyledAccordionsContainer onScroll={onScroll}>
            <Accordions
              assignSubPhaseRef={assignSubPhaseRef}
              programProgress={programProgress}
              handleSubPhaseCheck={handleSubPhaseCheck}
            />
          </StyledAccordionsContainer>

          <PhaseContentFooter />
        </StyleTabContainer>
      )}
    </>
  );
};
