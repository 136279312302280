import { ChangeEvent } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import {
  ContentType,
  ProgramProgress,
  SubPhase,
  SubPhaseContent,
} from '@xq/domain';

import './PhaseContent.styles';
import { PhaseTabs } from './PhaseTabs';
import { TabsPanels } from './TabsPanels';
import { usePhase } from '../context';
import { StyledAppBar, StylePhaseContainer } from './PhaseContent.styles';
import {
  programPostActions,
  SelectedPhasePayload,
  selectPhaseContent,
  selectPhaseSelections,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { EventTypes } from '../enums/EventTypes';
import { PersistSubPhasePayload } from '../JournalPhase/JournalPhase';

export interface PhaseContentProps {
  programProgress?: ProgramProgress;
  persistSubPhase?(payload: PersistSubPhasePayload): void;
}

export const PhaseContent = ({
  programProgress,
  persistSubPhase,
}: PhaseContentProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { tabIndex = '0', subPhaseIndex = '0', phaseIndex = '0' } = useParams();
  const { phaseTabs, phasePath } = usePhase();

  const isHeaderVisible: boolean = useOutletContext();

  const phasesContent = useAppSelector((state) =>
    selectPhaseContent(state, +phaseIndex)
  );
  const phaseSelections = useAppSelector(selectPhaseSelections);

  const tab = phaseTabs[+tabIndex]?.name ?? ContentType.PhaseContent;
  const phase = phasesContent?.[tab];

  const handleTabChange = (index: number) => {
    const newTabIndex = index.toString();
    const selectedPhase = phaseSelections[phaseIndex];
    const selectedSubPhaseContentIndex =
      selectedPhase?.tabs?.[selectedPhase.selectedTab ?? '']?.subPhases[
        subPhaseIndex
      ] ?? '-1';

    const payload: SelectedPhasePayload = {
      phaseIndex,
      tabIndex: newTabIndex,
      subPhaseIndex:
        selectedPhase?.tabs?.[newTabIndex]?.selectedSubPhase ?? '-1',
      subPhaseContentIndex: selectedSubPhaseContentIndex,
    };

    navigate(
      phasePath(
        payload.phaseIndex,
        payload.tabIndex,
        payload.subPhaseIndex,
        payload.subPhaseContentIndex
      )
    );

    dispatch(programPostActions.setSelectedPhase(payload));
  };

  const subPhaseCheckHandler = (
    event: ChangeEvent<HTMLInputElement>,
    subPhase: SubPhase,
    subPhaseContent?: SubPhaseContent
  ) => {
    if (persistSubPhase && !Array.isArray(phase) && phase) {
      const isCompleted =
        event.type === EventTypes.keyDown
          ? !event.target.checked
          : event.target.checked;

      const programProgressSubPhaseContent = subPhaseContent
        ? programProgress?.phases?.[subPhase.phaseId]?.subPhases?.[subPhase.id]
            ?.content
        : [];

      const currentSubPhaseContent = Object.values(
        programProgressSubPhaseContent ?? {}
      ).filter((c) => c.id !== subPhaseContent?.id);

      const isSubPhaseComplete = Boolean(
        subPhaseContent
          ? currentSubPhaseContent?.every((c) => c.isCompleted) &&
              currentSubPhaseContent.length + 1 ===
                subPhase.subPhaseContent.length &&
              isCompleted
          : isCompleted
      );

      const subPhaseContentIds = !subPhaseContent
        ? subPhase.subPhaseContent.map((c) => c.id)
        : [subPhaseContent?.id ? subPhaseContent?.id : []].flat();

      persistSubPhase({
        completed: isSubPhaseComplete,
        phaseId: subPhase.phaseId,
        phaseName: phase.name,
        subPhaseId: subPhase.id,
        isSubPhaseContentCompleted: subPhaseContent
          ? Boolean(subPhaseContent && isCompleted)
          : undefined,
        subPhaseContentIds,
      });
    }
  };

  return (
    <StylePhaseContainer isHeaderVisible={isHeaderVisible}>
      <StyledAppBar position="relative" color="default">
        {isHeaderVisible ? (
          <PhaseTabs
            activeTabIndex={phaseTabs[+tabIndex] ? +tabIndex : false}
            handleTabChange={handleTabChange}
          />
        ) : null}
      </StyledAppBar>

      <TabsPanels
        handleChangeTabIndex={handleTabChange}
        handleSubPhaseCheck={subPhaseCheckHandler}
        programProgress={programProgress}
      />
    </StylePhaseContainer>
  );
};
