import {
  createContext,
  Dispatch,
  MouseEvent,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isArray, isNumber, last } from 'lodash';

import { Phase } from '@xq/domain';

import {
  PhaseTab,
  selectPhasesContent,
  selectPhaseTabs,
  selectProgramPost,
  useAppSelector,
} from '../../store';
import { IN_RESOURCES_TABS_ORDER, TABS_ORDER } from '../index';

const REDIRECT_URLS = process.env.NX_PUBLIC_REDIRECT_URLS?.split(' ') ?? [];

type PhaseState = {
  resourcePath: (slug: string) => string;
  phasePath: (
    phaseIndex: string,
    tab: string,
    subPhase: string,
    subPhaseContentIndex: string
  ) => string;
  phaseTabs: PhaseTab[];
  inResources?: boolean;
  activePhaseIndex?: number;
  contentClickHandler: (event: MouseEvent<HTMLElement>) => void;
  getSearchParams: () => string;
  setHasExpandedSubphase: Dispatch<SetStateAction<boolean>>;
  hasExpandedSubphase: boolean;
};

const PhaseContext = createContext<PhaseState | undefined>(undefined);

type PhaseContextProviderProps = PropsWithChildren<{
  resourcePath: (slug: string) => string;
  phasePath: (
    phaseIndex: string,
    tab: string,
    subPhase: string,
    subPhaseContentIndex: string
  ) => string;
  inResources?: boolean;
  activePhaseIndex?: number;
}>;

export const PhaseContextProvider = ({
  resourcePath,
  phasePath,
  activePhaseIndex,
  inResources = false,
  children,
}: PhaseContextProviderProps) => {
  const navigate = useNavigate();
  const { studentId } = useParams();

  const [hasExpandedSubphase, setHasExpandedSubphase] = useState(false);

  const programPost = useAppSelector(selectProgramPost);
  const phaseTabs = useAppSelector(selectPhaseTabs);
  const phasesContent = useAppSelector(selectPhasesContent);

  const getSearchParams = () => {
    if (!inResources && !studentId) {
      return '';
    }

    let result = '?';

    if (studentId) {
      result += `studentId=${studentId}`;
    }

    if (inResources) {
      result += `inResources=${inResources}`;
    }

    return result;
  };

  const contentClickHandler = (event: MouseEvent<HTMLElement>) => {
    const contentParent = (event.target as HTMLElement)
      .parentElement as HTMLAnchorElement;

    const href =
      (event.target as HTMLAnchorElement).href ?? contentParent?.href;

    if (!href) {
      return;
    }

    event.preventDefault();

    const slug = last(href.split('/').filter(Boolean)) ?? '';

    let phaseIndex: number | undefined;
    let tabIndex: number | undefined;
    let subPhaseIndex: number | undefined;
    let phase: undefined | Phase;
    let subPhaseContentIndex: number | undefined;

    phasesContent.forEach((phContent) => {
      Object.values(phContent).forEach((value) => {
        if (value && !isArray(value) && value.subPhaseValues) {
          value.subPhaseValues.forEach((subPhase, subPhIndex) => {
            const found = subPhase.slug === slug;

            if (found) {
              phase = value;
              tabIndex = inResources
                ? IN_RESOURCES_TABS_ORDER.indexOf(value.contentType)
                : TABS_ORDER.indexOf(value.contentType);
              subPhaseIndex = subPhIndex;
              subPhaseContentIndex = -1;
            }
          });
        }
      });
    });

    if (phase) {
      const phaseValues = inResources
        ? programPost?.teacherPhaseValues
        : programPost?.studentPhaseValues;
      phaseIndex = phaseValues?.findIndex((p) => p.name === phase?.name);

      if (
        isNumber(phaseIndex) &&
        isNumber(tabIndex) &&
        isNumber(subPhaseIndex) &&
        isNumber(subPhaseContentIndex)
      ) {
        return navigate(
          phasePath(
            phaseIndex.toString(),
            tabIndex.toString(),
            subPhaseIndex.toString(),
            subPhaseContentIndex.toString()
          )
        );
      }
    }

    if (REDIRECT_URLS.some((url) => href.includes(url))) {
      return navigate(`${resourcePath(slug)}${getSearchParams()}`);
    }

    return window.open(href, '_blank', 'noopener,noreferrer');
  };

  return (
    <PhaseContext.Provider
      value={{
        resourcePath,
        phaseTabs,
        phasePath,
        activePhaseIndex,
        contentClickHandler,
        inResources,
        getSearchParams,
        setHasExpandedSubphase,
        hasExpandedSubphase,
      }}
    >
      {children}
    </PhaseContext.Provider>
  );
};

export const usePhase = () => {
  const context = useContext(PhaseContext);

  if (!context) {
    throw new Error('usePhase must be used within a PhaseContextProvider');
  }

  return context;
};
