import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

export const StyledTabsContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

export const StyledTab = styled(Tab)(() => ({
  minWidth: 72,
  minHeight: 40,
  marginTop: 8,
  textTransform: 'none',
  color: 'var(--gray)',
  padding: '0px 16px',

  '&:first-of-type': {
    marginLeft: '25px',
  },
})) as typeof Tab;
