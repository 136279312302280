import { StreamClient, StreamUser } from 'getstream';

import { Activity, FeedSlugs, getJournalActivityId } from '@xq/domain';
import {
  ClassroomRepository,
  FeedMapper,
  FeedService,
} from '@xq/infrastructure';

export class AddPhaseJournalActivityUseCase {
  static async execute(
    client: StreamClient,
    activity: Activity,
    streamUser: StreamUser
  ) {
    const feedService = FeedService.getInstance(client);
    const classroomRepo = new ClassroomRepository();
    const journalLinkId = activity.journalLink?.link.split('#')[1];

    if (!journalLinkId || !activity.programId) {
      return;
    }

    const id = getJournalActivityId({
      classroomId: activity.classroomId,
      programId: activity.programId,
      journalActivityId: journalLinkId,
    });
    const feed = feedService.getStreamFeed(FeedSlugs.phaseJournal, id);

    if (!feed) {
      return;
    }

    const payload = {
      ...FeedMapper.toStreamActivity(activity),
      actor: streamUser,
    };

    const response = await feed.addActivity(payload);

    await classroomRepo.addPhaseJournalStudentResponse({
      classroomId: activity.classroomId,
      feedId: id,
      userId: activity.actor.id,
      activityId: response.id,
    });

    return response;
  }
}
