import { CreateFileProps, GoogleDriveUploadService } from '@xq/infrastructure';
import { DocumentMapper } from '@xq/shared/data-access';

export class CreateFileInDriveUseCase {
  static async execute(props: CreateFileProps) {
    const createFile = (await GoogleDriveUploadService.createFile(props))
      .result;

    return DocumentMapper.fromDrive(createFile);
  }
}
