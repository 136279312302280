import { firebaseService } from '@xq/infrastructure';
import { CreateUserRequestDto, UserRecord } from '@xq/shared/data-access';
import { callWithRetry } from '@xq/shared/utils';

export class CreateUserUseCase {
  static async execute(record: UserRecord) {
    const createUser = firebaseService.prepareFunctionCaller<
      CreateUserRequestDto,
      void
    >('createUser');

    return callWithRetry(() => createUser({ record }));
  }
}
