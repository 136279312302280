import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import styles from './import-csv-button.module.scss';

type ImportCSVButtonProps = {
  onImport: (cols: string[]) => void;
};

export const ImportCSVButton = ({ onImport }: ImportCSVButtonProps) => {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (progressEvent) => {
        const text = progressEvent?.target?.result;

        if (typeof text === 'string') {
          const csvCols = text
            .split(/[\n, ;|\t]+/)
            .map((value) => value.replace(/[\r"]/g, ''))
            .filter(Boolean);
          onImport(csvCols);
        }
      };

      fileReader.readAsText(file);
    }
  };

  const openFileInput = () => {
    fileInput.current?.click();
  };

  return (
    <>
      <input
        data-cy="importCsvFile"
        className={styles.fileInput}
        ref={fileInput}
        type={'file'}
        accept={'.csv'}
        onChange={onChange}
      />
      <Button
        className={styles.button}
        color="inherit"
        type="button"
        onClick={openFileInput}
      >
        {t('import-csv')}
      </Button>
    </>
  );
};
