import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cx } from '@emotion/css';
import { useMediaQuery } from '@mui/material';
import { useTitle } from 'react-use';

import { FeedSlugs, JournalFeedScopes, User, UserRoles } from '@xq/domain';
import { ConnectToPersonalFeedUseCase } from '@xq/usecases';

import styles from './journal-feed.module.scss';
import { FeedInput } from '../ClassFeed/components/FeedInput/FeedInput';
import {
  selectCurrentUserProgram,
  useAppSelector,
  activityActions,
  taggedFileActions,
  taggedPostActions,
  useAppDispatch,
  selectActivitiesByIds,
  selectIsCurrentUserStudent,
  selectCurrentRole,
  selectFeedIsLoading,
  selectFeedActivityIds,
  feedActions,
} from '../../store';
import { extractTaggable, useUserSubscription } from '../../hooks';
import { ActivityList } from '../../ActivityList/ActivityList';
import { NoActivitiesMessage } from '../NoActivitiesMessage/NoActivitiesMessage';
import { RestrictView } from '../../RestrictView/RestrictView';
import { MOBILE_MEDIA_QUERY, PageTitles } from '../../utils';
import { useFeed } from '../../context';
import { useApi } from '../../ApiProvider/ApiProvider';

type JournalFeedProps = {
  user: User;
};

export const JournalFeed = ({ user }: JournalFeedProps) => {
  useTitle(PageTitles.PROJECT_JOURNAL);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const { subscribeToFeed, fetchActivities, feedId } = useFeed();
  const { feedClient } = useApi();
  const { userData } = useUserSubscription({ userId: user.uid });

  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const feedActivityIds = useAppSelector(selectFeedActivityIds);
  const isCurrentUserStudent = useAppSelector(selectIsCurrentUserStudent);
  const loadingActivities = useAppSelector(selectFeedIsLoading);
  const activities = useAppSelector((state) => {
    return selectActivitiesByIds(state, feedActivityIds, '');
  });
  const currentRole = useAppSelector(selectCurrentRole);

  const getEditorPlaceholder = () => {
    if (loadingActivities) {
      return '';
    }

    return activities?.length
      ? t('post_here')
      : t('start_working_on_your_first_project');
  };

  useEffect(() => {
    if (!userData) return;
    extractTaggable(
      (extracted) => dispatch(taggedFileActions.setOne(extracted)),
      userData.uid,
      {
        ...userData.feedTagFilesPerClassroom,
        ...userData.journalTagFilesPerClassroom,
      }
    );

    extractTaggable(
      (extracted) => dispatch(taggedPostActions.addTaggedPost(extracted.files)),
      userData.uid,
      userData.journalTagPostsPerClassroom
    );
  }, [userData]);

  useEffect(() => {
    if (feedClient && feedId) {
      const payload = ConnectToPersonalFeedUseCase.execute(
        feedClient,
        FeedSlugs.student,
        feedId
      );

      dispatch(feedActions.initFeed(payload));
      const promise = fetchActivities();
      const sub = subscribeToFeed(feedId);

      return () => {
        promise.abort();
        sub?.();
        dispatch(activityActions.resetActivities());
      };
    }
  }, [feedClient, feedId]);

  const renderActivities = () => {
    if (!currentProgram) {
      return null;
    }

    if (!activities.length && !loadingActivities) {
      if (currentRole !== UserRoles.student) {
        return <NoActivitiesMessage message={t('student_has_no_posts_yet')} />;
      }

      if (isMobile || currentRole === UserRoles.student) {
        return <NoActivitiesMessage message={getEditorPlaceholder()} />;
      }
    }

    return (
      <ActivityList activities={activities} currentProgram={currentProgram} />
    );
  };

  return (
    <div
      className={cx(styles.journalFeed, {
        [styles.withFeedInput]: isCurrentUserStudent,
      })}
    >
      {!isMobile && (
        <RestrictView scopes={[JournalFeedScopes.CAN_POST]}>
          <FeedInput placeholder={getEditorPlaceholder()} />
        </RestrictView>
      )}
      {renderActivities()}
    </div>
  );
};
