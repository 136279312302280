import { UserRoles } from '@xq/domain';
import { z } from 'zod';

export const RecordValidation = z.object({
  displayName: z.string(),
  email: z.string(),
  photoUrl: z.string().nullable().optional(),
  uid: z.string(),
  role: z.nativeEnum(UserRoles).optional(),
});

export const CreateUserSchema = z.object({
  record: RecordValidation,
});

export type UserRecord = z.infer<typeof RecordValidation>;

export type CreateUserRequestDto = z.infer<typeof CreateUserSchema>;
