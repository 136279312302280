import { z } from 'zod';

export enum WizardContentType {
  WEB = 'web',
  MOBILE = 'mobile',
}

export const WizardContentSchema = z.object({
  id: z.string(),
  slug: z.string(),
  createdAt: z.string(),
  positionSelector: z.string(),
  type: z.nativeEnum(WizardContentType),
});

export type WizardContent = z.infer<typeof WizardContentSchema>;

export const wizardContentFactory = (
  wizardContent: Partial<WizardContent>
): WizardContent => {
  return {
    createdAt: wizardContent.createdAt ?? '',
    slug: wizardContent.slug ?? '',
    id: wizardContent.id ?? '',
    positionSelector: wizardContent.positionSelector ?? '',
    type: wizardContent.type ?? WizardContentType.WEB,
  };
};
