import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import { useModal } from '@xq/web-components';

const StyledText = styled(FormHelperText)`
  color: var(--green-primary);
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 16px;
`;

export const SchoolDomainExplanation = () => {
  const { t } = useTranslation();

  const { dispatchModal, onCancel, resetModal } = useModal();

  const showSchoolDomainExplanation = () => {
    onCancel.current = async () => resetModal();

    dispatchModal({
      cancelButtonText: t('got_it'),
      titleText: t('what_is_school_domain'),
      bodyText: t('explain_school_domain'),
      showActionButton: false,
      showIcon: true,
      isOpen: true,
    });
  };

  return (
    <StyledText onClick={showSchoolDomainExplanation}>
      {t('what_is_school_domain')}
    </StyledText>
  );
};
