import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';
import { cx } from '@emotion/css';

import { Activity, Comment, JournalFeedScopes, Mention } from '@xq/domain';

import styles from './comment-item.module.scss';
import {
  selectCurrentUserState,
  selectUserById,
  useAppSelector,
} from '../../../../store';
import { ReadOnlyTextEditor } from '../../../../TextEditor';
import { HideMenu } from '..//HideMenu/HideMenu';
import { useFeed } from '../../../../context';
import { RestrictView } from '../../../../RestrictView/RestrictView';
import { CommentActionsMenu } from '../../../ActionsMenu/CommentActionsMenu';

type CommentContentProps = {
  activity: Activity;
  comment: Comment;
  mentionSuggestions: Mention[];
};

export const CommentItem = ({
  comment,
  mentionSuggestions,
  activity,
}: CommentContentProps) => {
  const { t } = useTranslation();
  const { editComment, isHideMenuVisible } = useFeed();

  const currentUser = useAppSelector(selectCurrentUserState);

  const [truncatedComment, setTruncatedComment] = useState(true);
  const commentCreator = useAppSelector((state) =>
    selectUserById(state, comment.creatorId)
  );

  const handleTruncation = () => setTruncatedComment(!truncatedComment);

  const handleHideComment = (
    text: string,
    commentId: string,
    hidden: boolean
  ) => {
    editComment({ text, commentId, hidden });
  };

  return (
    <div className={styles.contentContainer}>
      <Avatar
        className={styles.profilePicture}
        src={commentCreator?.photoUrl ?? ''}
        alt={t('user_profile_photo')}
      />
      <div>
        <div className={styles.displayName}>
          {commentCreator?.displayName || t('unknown_user')}
        </div>
        <div
          data-cy="comment-content"
          onClick={handleTruncation}
          className={cx(styles.message, {
            [styles.truncateOverflow]: truncatedComment,
          })}
        >
          <ReadOnlyTextEditor htmlString={comment.body} />
        </div>
      </div>

      <div className={styles.menuContainer}>
        {/*<HideMenu*/}
        {/*  itemToHide={comment}*/}
        {/*  shouldShowHideMenu={isHideMenuVisible(comment.creatorId)}*/}
        {/*  handleHiddenState={() =>*/}
        {/*    handleHideComment(comment.body, comment.id, !comment.hidden)*/}
        {/*  }*/}
        {/*/>*/}
        <RestrictView
          scopes={[
            JournalFeedScopes.CAN_DELETE_POST,
            JournalFeedScopes.CAN_EDIT_POST,
          ]}
        >
          {currentUser?.uid === comment.creatorId && (
            <CommentActionsMenu
              activityId={activity.id}
              comment={comment}
              mentionSuggestions={mentionSuggestions}
            />
          )}
        </RestrictView>
      </div>
    </div>
  );
};

export const MemoizedCommentItem = memo(CommentItem);
