import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const StyledButton = styled(Button)``;

StyledButton.defaultProps = {
  type: 'button',
  color: 'inherit',
  variant: 'outlined',
};

export const StyledPrimaryButton = styled(Button)``;

StyledPrimaryButton.defaultProps = {
  type: 'button',
  color: 'primary',
  variant: 'contained',
};

export const StyledPrimaryLoadingButton = styled(LoadingButton)``;

StyledPrimaryLoadingButton.defaultProps = {
  type: 'button',
  color: 'primary',
  variant: 'contained',
};

StyledPrimaryButton.defaultProps = {
  type: 'button',
  color: 'primary',
  variant: 'contained',
};
