import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  PropsWithChildren,
  ForwardedRef,
  HTMLAttributes,
} from 'react';

interface GraphingCalculatorOptions {
  zoomMode?: 'auto' | 'manual';
  expressions?: Array<{ id: string; latex: string }>;
  pasteGraphLink?: boolean;
}

export type DesmosOptions = GraphingCalculatorOptions & {
  attributes?: HTMLAttributes<HTMLDivElement>;
  calculatorState?: string;
};

declare global {
  interface Window {
    Desmos: {
      GraphingCalculator: (
        elt: HTMLDivElement,
        options?: GraphingCalculatorOptions
      ) => Desmos.GraphingCalculator;
    };
  }
}

interface Expression {
  type: string;
  id: string;
  color?: string;
  latex?: string;
  hidden?: boolean;
}

interface calculatorState {
  version: number;
  randomSeed: string;
  graph: {
    viewport: {
      xmin: number;
      ymin: number;
      xmax: number;
      ymax: number;
    };
  };
  expressions: {
    list: Expression[];
  };
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Desmos {
    export interface GraphingCalculator {
      destroy(): void;
      getState(): calculatorState;
      setState(calculatorState: calculatorState): void;
      setBlank(): void;
      updateSettings(settings: GraphingCalculatorOptions): void;
      domChangeDetector: {
        elt: HTMLDivElement;
      };
    }
  }
}

export const DesmosCalculator = forwardRef<
  Desmos.GraphingCalculator,
  PropsWithChildren<DesmosOptions>
>(function GraphingCalculator(
  { calculatorState, attributes, children, ...options },
  ref
) {
  const [calculator, setCalculator] =
    useState<Desmos.GraphingCalculator | null>(null);
  const div = useRef<HTMLDivElement>(null);
  const [isInited, setIsInited] = useState(false);
  const [calcState, setCalcState] = useState('');

  useEffect(() => {
    const calc = window.Desmos.GraphingCalculator(
      div.current as HTMLDivElement,
      {
        ...options,
        pasteGraphLink: true,
      }
    );
    applyRef(ref, calc);
    setCalculator(calc);

    return () => calc.destroy();
  }, []);

  useEffect(() => {
    calculator?.updateSettings(options);

    if (calculatorState && !isInited && calculator) {
      setIsInited(true);
      loadCalculator(calculatorState);
    }
  }, [options, calculator, calculatorState, isInited]);

  const saveCalculator = () => {
    const state = calculator?.getState();
    setCalcState(JSON.stringify(state));
  };

  const loadCalculator = (stringState: string) => {
    if (stringState) {
      calculator?.setState(JSON.parse(stringState) as calculatorState);
    }
  };

  const resetCalculator = () => {
    calculator?.setBlank();
  };

  return (
    <div ref={div} {...attributes} style={{ minHeight: '100%', width: '100%' }}>
      {calculator ? children : null}

      {/*<button onClick={saveCalculator}>SAVE</button>*/}
      {/*<button onClick={() => loadCalculator(calcState)}>LOAD</button>*/}
      {/*<button onClick={resetCalculator}>RESET</button>*/}
    </div>
  );
});

function applyRef<T>(ref: ForwardedRef<T>, val: T) {
  if (typeof ref === 'function') {
    ref(val);
  } else if (ref) {
    ref.current = val;
  }
}
