import { useEffect } from 'react';
import {
  doc,
  DocumentReference,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore';

import {
  CollectionNames,
} from '@xq/shared/data-access';
import {
  firebaseService,
  loggerService,
  subscriptionManager,
} from '@xq/infrastructure';

import { useAppDispatch, userCalculatorsActions } from '../store';
import { Calculator, FbCalculator } from "@xq/domain";

type Props = {
  classroomId?: string;
  programId?: string;
};

export const useStudentsCalculatorsSubscription = ({
 classroomId,
 programId,
}: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!classroomId || !programId) {
      return;
    }

    const query = doc(
        firebaseService.db,
        `${CollectionNames.classrooms}/${classroomId}/${CollectionNames.userCalculatorsByProgramId}/${programId}`
      );

    const subscriptions: Unsubscribe[] = [];

    // TODO Compare queries to see if they are the same before subscribing
    const subscribe = (query: DocumentReference) => {
      const sub = onSnapshot(
        query,
        (snapshot) => {
          if (snapshot.exists() && snapshot.ref.parent.parent?.id) {
            const calculators: Calculator[] = Object.values<FbCalculator>(snapshot.data()).map(
              (calcDto) => {
                return {
                  id: `${calcDto.userId}-${classroomId}-${programId}-${calcDto.phaseId}-${calcDto.wpPostId}`,
                  wpPostId: calcDto.wpPostId,
                  phaseId: calcDto.phaseId,
                  programId,
                  classroomId,
                  userId: calcDto.userId,
                  title: calcDto.title as string,
                  updatedAt: calcDto.updatedAt.toDate().toString(),
                  calculatorState: calcDto.calculatorState,
                  forReview: !!calcDto.forReview,
                };
              }
            );

            dispatch(
              userCalculatorsActions.setCalculators({
                classroomId,
                programId,
                calculators,
              })
            );
          }
        },
        (e) => {
          loggerService.error('STUDENT CALCULATORS SUBSCRIPTION ERROR: ', e);
        }
      );

      subscriptions.push(sub);
    };

    subscribe(query);
    subscriptionManager.add(subscriptions);

    return () => subscriptionManager.remove(subscriptions);
  }, [classroomId, programId]);
};
