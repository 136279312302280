import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
} from 'firebase/auth';

import { z } from 'zod';

import { LoginUser } from '@xq/infrastructure';
import { callWithRetry } from '@xq/shared/utils';

import { ImpersonateUserUseCase } from './ImpersonateUserUseCase';
import { LogAnalyticsEventUseCase } from '../LogAnalyticsEventUseCase';

const reloadAppOnUserChange = (
  currentUserEmail?: string,
  newUserEmail?: string | null
) => {
  if (currentUserEmail && currentUserEmail !== newUserEmail) {
    window.location.reload();
  }
};

export const LoginTeacherAppUserSchema = z.object({
  code: z.string(),
  impersonate: z.boolean().optional(),
  currentUserEmail: z.string().email().optional(),
  isTeacherApp: z.boolean().optional(),
});

export class LoginUserUseCase {
  async execute(payload: z.infer<typeof LoginTeacherAppUserSchema>) {
    const { code, currentUserEmail, impersonate, isTeacherApp } = payload;
    const tokenResponse = await callWithRetry(() => LoginUser(code));
    const auth = getAuth();
    const authCredential = GoogleAuthProvider.credential(
      tokenResponse.data.idToken,
      tokenResponse.data.accessToken
    );

    if (impersonate) {
      await callWithRetry(() =>
        ImpersonateUserUseCase.execute(
          tokenResponse.data.userEmail,
          isTeacherApp
        )
      );
      return;
    }

    const userCredential = await signInWithCredential(auth, authCredential);

    gapi?.client?.setToken({ access_token: tokenResponse.data.accessToken });

    reloadAppOnUserChange(currentUserEmail, userCredential?.user?.email);

    LogAnalyticsEventUseCase.execute({
      login: {},
    });
  }
}
