import {
  AnalyticsEventParameters,
  AnalyticsUserParameters,
  UserParameterValue,
} from './analyticsParameters';

export enum AnalyticsEventNames {
  login = 'login',
  page_view = 'page_view',
  number_of_journal_posts = 'number_of_journal_posts',
  number_of_class_feed_posts = 'number_of_class_feed_posts',
  number_of_journal_comments = 'number_of_journal_comments',
  number_of_class_feed_comments = 'number_of_class_feed_comments',
  number_of_files_uploaded = 'number_of_files_uploaded',
  number_of_files_viewed = 'number_of_files_viewed',
  number_of_chat_messages = 'number_of_chat_messages',
  number_of_mark_documents_for_review = 'number_of_mark_documents_for_review',
}

export type AnalyticsEventScope = {
  [Key in keyof typeof AnalyticsEventNames]?: Partial<{
    [Parameter in keyof typeof AnalyticsEventParameters]: Parameter extends 'users'
      ? UserParameterValue
      : string;
  }>;
};

export type AnalyticsUserScope = {
  [Key in keyof typeof AnalyticsUserParameters]?: Key extends 'role'
    ? UserParameterValue
    : string;
};
