import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/material';

const size = '55px';
const halfSize = `calc(${size} / 2)`;

export const StyledContainer = styled('div')`
  position: fixed;
  right: 0px;
  z-index: 99;
  cursor: pointer;
`;

export const StyledFab = styled('div')`
  width: ${halfSize};
  height: ${size};
  background-color: var(--green-primary);
  border-bottom-left-radius: ${halfSize};
  border-top-left-radius: ${halfSize};
`;

export const StyledArrowIcon = styled(ArrowBackIosIcon)`
  color: var(--white);
  position: relative;
  top: 16px;
  left: 10px;
`;
