import { LogAnalyticsEventUseCase } from '@xq/usecases';

type EventParams = {
  classroomName: string;
  schoolName: string;
};

export const logAnalyticsEventOnTitleChange = ({
  classroomName,
  schoolName,
}: EventParams) => {
  const titleNode = document.querySelector('title');

  if (titleNode) {
    const observer = new MutationObserver(([{ target }]) => {
      const title = target.textContent;

      if (!title) return;

      LogAnalyticsEventUseCase.execute({
        page_view: {
          classroom: classroomName,
          school: schoolName,
          page_title: title,
          page_path: window.location.pathname,
        },
      });
    });

    observer.observe(titleNode, {
      childList: true,
    });
  }
};
