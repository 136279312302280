import { createSelector } from '@reduxjs/toolkit';

import { selectAppState } from '../index';

export const selectIsImpersonating = createSelector(
  [selectAppState],
  (state) => state.isImpersonating
);

export const selectVisibleBanners = createSelector(
  [selectAppState],
  (state) => state.visibleBanners
);

export const selectIsFullscreen = createSelector(
  [selectAppState],
  (state) => state.isFullscreen
);

export const selectIsPrinting = createSelector(
  [selectAppState],
  (state) => state.isPrinting
);

export const selectIsTeacherApp = createSelector(
  [selectAppState],
  (state) => state.xqApp === 'teacher'
);

export const selectIsNewUser = createSelector(
  [selectAppState],
  (state) => state.isNewUser
);

export const selectShowedWizard = createSelector(
  [selectAppState],
  (state) => state.showedWizard
);
