import { PropsWithChildren, MouseEvent } from 'react';
import { Button } from '@mui/material';
import { cx } from '@emotion/css';

import styles from './toolbar-button.module.scss';

type ToolbarButtonProps = {
  isActive: boolean;
  onClick: (e: MouseEvent) => void;
};

export const ToolbarButton = ({
  isActive,
  onClick,
  children,
}: PropsWithChildren<ToolbarButtonProps>) => {
  return (
    <Button
      className={cx(styles.toolbarItem, {
        [styles.active]: isActive,
      })}
      onClick={(e) => {
        onClick(e);
      }}
      type="button"
      variant="outlined"
      color="info"
    >
      {children}
    </Button>
  );
};
