import { User } from '@xq/domain';
import { WordPressService, loggerService } from '@xq/infrastructure';

export class WordPressCheckUseCase {
  async execute(programsSlugs: string[], currentUser: User) {
    try {
      const wpService = WordPressService.getInstance();
      for (const slug of programsSlugs) {
        await wpService.getProgram(slug, false);
      }
    } catch (e) {
      loggerService.error(
        `System check wordpress error isSystemCheckError for user with ID ${currentUser.uid}: ${e}`,
        { systemCheckError: true, userId: currentUser.uid }
      );
      return false;
    }

    return true;
  }
}
