import { Button, SvgIcon, styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export const StyledModalBody = styled('div')`
  background: #fff;
  margin: 0 auto;
  padding: 16px;
  position: relative;
  top: 25%;
  text-align: center;
`;

export const StyledGenericIcon = styled(SvgIcon)`
  color: var(--green-primary);
  width: 30px;
  height: 30px;
`;

export const StyledCloseIconWrapper = styled('div')`
  display: flex;
  flex-direction: row-reverse;
`;

export const StyledHeader = styled('header')`
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 16px;
`;

export const StyledBody = styled('main')`
  white-space: pre-line;
  text-align: left;
`;

export const StyledFooter = styled('footer')`
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 20px;
`;

export const StyledCancelButton = styled(Button)`
  background: #fff;
  border-radius: 0;
  color: #000;
  border: 1px solid #000;
  box-shadow: none;
  font-weight: bolder;
`;

export const StyledDeleteButton = styled(LoadingButton)`
  border-radius: 0;
  font-weight: bolder;
  box-shadow: none;
  background-color: var(--green-primary);
`;
