import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { MathTagTypes } from '@xq/domain';

export type TaggedFileState = {
  userId: string;
  files: Record<string, MathTagTypes[]>;
};

export const taggedFileAdapter = createEntityAdapter<TaggedFileState>({
  selectId: (model) => model.userId,
});

export const taggedFileSlice = createSlice({
  name: 'tagged-file',
  initialState: taggedFileAdapter.getInitialState(),
  reducers: {
    setOne: taggedFileAdapter.setOne,
  },
});

export const taggedFileActions = taggedFileSlice.actions;
