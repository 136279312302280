import {
  FunctionsApi,
  RemoveTagFromResourcesRequestDto,
} from '@xq/shared/data-access';

import {
  RemoveTagFromFile,
  RemoveUserTagsFromPost,
  TagFilesProps,
  TagResourcePayload,
} from './TagService.interface';
import { FeedService } from '../FeedService';
import { firebaseService } from '../FirebaseService';
import { loggerService } from '../LoggerService';
import { extractTaggableDocuments } from '@xq/domain';
import { callWithRetry } from '@xq/shared/utils';

class TagsService {
  async tagResource({ resourcesForTagging, client }: TagResourcePayload) {
    const feedService = FeedService.getInstance(client);
    await feedService.onTag(resourcesForTagging);
  }

  async tagFiles({
    files,
    userId,
    classroomId,
    programId,
    client,
    feedSlug,
  }: TagFilesProps) {
    const resourcesForTagging = extractTaggableDocuments(files).map((file) => ({
      mimeType: file.mimeType,
      resourceId: file.id,
      userId,
      classroomId,
      programId,
      feedSlug,
    }));

    if (resourcesForTagging.length)
      await this.tagResource({
        client,
        resourcesForTagging,
      });
  }

  async removeTagFromFile(resources: RemoveTagFromFile[]) {
    const removeTagFromDocumentCaller = firebaseService.prepareFunctionCaller<
      RemoveTagFromResourcesRequestDto,
      string
    >(FunctionsApi.RemoveTagsFromResources);

    try {
      await callWithRetry(() =>
        removeTagFromDocumentCaller({
          resources,
        })
      );
    } catch (e) {
      loggerService.error(e);
    }
  }

  async removeUserTagsFromPost(resources: RemoveUserTagsFromPost[]) {
    const removeTagFromDocumentCaller = firebaseService.prepareFunctionCaller<
      RemoveTagFromResourcesRequestDto,
      string
    >(FunctionsApi.RemoveTagsFromResources);

    try {
      await callWithRetry(() =>
        removeTagFromDocumentCaller({
          resources,
        })
      );
    } catch (e) {
      loggerService.error(e);
    }
  }
}

export const tagsService = new TagsService();
