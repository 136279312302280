export const emailDuplicatedValidation = (
  email: string,
  newEmails: string[],
  addedEmails: string[],
  validEmails: string[]
) => {
  const existsInAddedEmails = addedEmails.includes(email);

  if (existsInAddedEmails) {
    return existsInAddedEmails;
  }

  const existsMoreTheOnceInNewEmails =
    newEmails.filter((x) => x === email).length > 1;

  return existsMoreTheOnceInNewEmails && validEmails.includes(email);
};
