import { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import { cx } from '@emotion/css';

import { Calculator } from '@xq/domain';
import {
  fetchProgramPost,
  LoadingOverlay,
  programPostActions,
  selectCurrentUserClassroom,
  selectCurrentUserClassroomStudents,
  selectCurrentUserProgram,
  selectProgramPost,
  selectProgramPostLoading,
  selectProgramPostSelectedSlug,
  selectUserCalculatorsByClassroomIdByProgramId,
  selectUserCalculatorsByClassroomIdByProgramIdByPhaseId,
  setUserCalculator,
  StyledTitle,
  useAppDispatch,
  useAppSelector,
  useNotifier,
  useStudentsCalculatorsSubscription,
  useStudentsSubscriptionByIds,
} from '@xq/web-components';
import { FileForReviewLabel } from '@xq/web-components/FileForReviewLabel/FileForReviewLabel';

import {
  StyledPhase,
  StyledPhaseTitle,
  StyledContainer,
  StyledRoot,
  StyledGrid,
  StyledPhaseTitleContainer,
} from './StudentCalculatorsPage.styles';
import { StudentCalculatorsAccordion } from '../../components/StudentCalculatorsAccordion/StudentCalculatorsAccordion';

const StudentCalculatorsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { notifyStudentForCalculatorReviewed } = useNotifier();

  const [selectedPhase, setSelectedPhase] =
    useState<number>(22);

  const selectedSlug = useAppSelector(selectProgramPostSelectedSlug);
  const programPostLoading = useAppSelector(selectProgramPostLoading);
  const programPost = useAppSelector(selectProgramPost);
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const students = useAppSelector(selectCurrentUserClassroomStudents);
  const userCalculatorsByProgram = useAppSelector((state) =>
    selectUserCalculatorsByClassroomIdByProgramId(
      state,
      currentClassroom?.id ?? '',
      currentProgram?.id ?? '',
    )
  );

  const userCalculatorsByProgramByPhaseId = useAppSelector((state) =>
    selectUserCalculatorsByClassroomIdByProgramIdByPhaseId(
      state,
      currentClassroom?.id ?? '',
      currentProgram?.id ?? '',
      selectedPhase,
    )
  );

  const classroomStudentIds = useMemo(
    () => students.map((student) => student.uid),
    [students]
  );

  useStudentsSubscriptionByIds({
    userIds: classroomStudentIds,
  });

  useStudentsCalculatorsSubscription({
    programId: currentProgram?.id,
    classroomId: currentClassroom?.id,
  });

  useEffect(() => {
    if (selectedSlug) {
      dispatch(
        fetchProgramPost({
          slug: selectedSlug,
          isTeacher: false,
        })
      );
    }
  }, [selectedSlug]);

  useEffect(() => {
    if (currentProgram?.slug) {
      dispatch(programPostActions.setSelectedSlug(currentProgram.slug));
    }
  }, [currentProgram?.slug]);

  const phaseName = useMemo(() => {
    const phase = programPost?.studentPhaseValues?.find(
      (f) => f.id === selectedPhase
    );

    return phase ? phase.name : '';
  }, [programPost, selectedPhase]);

  const onMarkForReview = (calculator: Calculator, userId: string) => {
    if (!currentClassroom?.id || !currentProgram?.id) return;

    dispatch(setUserCalculator({
      userId: calculator.userId,
      classroomId: calculator.classroomId,
      programId: calculator.programId,
      phaseId: calculator.phaseId,
      wpPostId: calculator.wpPostId,
      calculatorState: calculator.calculatorState,
      title: calculator.title,
      forReview: !calculator.forReview,
      updatedAt: new Date(),
    }));

    notifyStudentForCalculatorReviewed(calculator.title, userId);
  };

  const calcForReviewCount = useCallback((phaseId: number) => {
    return userCalculatorsByProgram?.filter(c => c?.forReview && phaseId === c?.phaseId).length ?? 0;
  }, [userCalculatorsByProgram]);

  return (
    <StyledRoot container spacing={2}>
      <StyledGrid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <StyledContainer>
          <LoadingOverlay
            isLoading={programPostLoading}
            width={'100%'}
            height={'100%'}
          >
            {programPost?.studentPhaseValues?.map((phase) => (
              <StyledPhase
                key={phase.id}
                component="div"
                onClick={() => setSelectedPhase(phase.id)}
              >
                <StyledPhaseTitleContainer>
                  <StyledPhaseTitle
                    className={cx({
                      active: phase.id === selectedPhase,
                    })}
                  >
                    {phase.name}
                  </StyledPhaseTitle>
                  {
                    calcForReviewCount(phase.id) ?
                      <FileForReviewLabel
                        count={calcForReviewCount(phase.id)}
                        text={`${calcForReviewCount(phase.id)} CALCULATORS FOR REVIEW`}
                      /> : null
                  }
                </StyledPhaseTitleContainer>
                <Divider />
              </StyledPhase>
            ))}
          </LoadingOverlay>
        </StyledContainer>
      </StyledGrid>
      <StyledGrid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <StyledContainer>
          <StyledTitle sx={{ margin: '12px 0' }}>{phaseName}</StyledTitle>
          <div>
            {students.map((student) => (
              <StudentCalculatorsAccordion
                key={student.uid}
                student={student}
                calculators={userCalculatorsByProgramByPhaseId?.filter((f) => f?.userId === student.uid) as Calculator[] ?? []}
                markForReview={onMarkForReview}
              />
            ))}
          </div>
        </StyledContainer>
      </StyledGrid>
    </StyledRoot>
  );
};

export default StudentCalculatorsPage;
