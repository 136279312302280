import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

const StyledLoaderContainer = styled('div')`
  background-color: #fff;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1000;
`;

export const LoaderLogo = () => {
  const { t } = useTranslation();

  return (
    <StyledLoaderContainer data-cy="loader-logo">
      <img
        src="/assets/animation_200_kst0gzrm.gif"
        alt={t('loading_XQ_logo')}
      />
    </StyledLoaderContainer>
  );
};
