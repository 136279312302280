import { PropsWithChildren } from 'react';
import { Modal } from '@mui/material';

export interface ModalProps {
  open: boolean;
  onClose?(): void;
}

export const SimpleModal = ({
  open,
  onClose,
  children,
}: PropsWithChildren<ModalProps>) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose?.()}
      aria-describedby="simple-modal-description"
    >
      <>{children}</>
    </Modal>
  );
};
