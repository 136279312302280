import { ElementType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';
import { Button, Icon } from '@mui/material';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { STUDENT_APP_ROUTES, TEACHER_APP_ROUTES } from '@xq/web-config';

import { loggerService } from '@xq/infrastructure';
import { LoginUserUseCase } from '@xq/usecases';

import styles from './login.module.scss';
import { PageTitles } from '../utils/pageTitles';
import { GoogleSignInBtn } from '../GoogleSignInBtn/GoogleSignInBtn';
import { LoaderLogo } from '../LoaderLogo/LoaderLogo';
import { ImgWithFallback } from '../ImgWithFallback/ImgWithFallback';
import { FbAuthUserState } from '../ApiProvider/ApiProvider';

const NX_DRIVE_FILE_SCOPE = process.env.NX_PUBLIC_DRIVE_FILE_SCOPE || '';

export interface LoginProps {
  logo: ElementType;
  isTeacherApp: boolean;
  colorTheme: 'loginWrapperTeacher' | 'loginWrapperStudent';
  impersonate?: boolean;
  imageSrc: string;
  imageFallbackSrc: string;
  userAuthState: FbAuthUserState;
  setUserAuthState: (userAuthState: FbAuthUserState) => void;
}

const HELPDESK_URL = 'https://xqinstitutehelp.zendesk.com';

export const Login = ({
  logo,
  colorTheme,
  isTeacherApp,
  imageSrc,
  imageFallbackSrc,
  impersonate = false,
  userAuthState,
  setUserAuthState,
}: LoginProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const loginUseCase = new LoginUserUseCase();
  useTitle(PageTitles.LOGIN);
  const navigate = useNavigate();

  const handleGoogleLogin = async (
    codeResponse: Omit<
      CodeResponse,
      'error' | 'error_description' | 'error_uri'
    >
  ) => {
    setLoading(true);
    try {
      setUserAuthState('loading');
      await loginUseCase.execute({
        code: codeResponse.code,
        impersonate,
        isTeacherApp,
      });

      setUserAuthState('loggedIn');
      navigate(
        isTeacherApp
          ? TEACHER_APP_ROUTES.getRoot()
          : STUDENT_APP_ROUTES.getJournalPath()
      );
    } catch (e) {
      setLoading(false);
      setUserAuthState('loggedOut');
      loggerService.error(`Logging in error: ${e}`);
      const errorRoute = isTeacherApp
        ? TEACHER_APP_ROUTES.getErrorScreen()
        : STUDENT_APP_ROUTES.getErrorScreen();

      navigate(errorRoute);
      window.location.reload();
    }
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleLogin,
    onError: (errorResponse) => {
      loggerService.error(`Login in Google failed with: ${errorResponse}`);
    },
    flow: 'auth-code',
    select_account: true,
    scope: NX_DRIVE_FILE_SCOPE,
  });

  const getPolicyUrl = (page: string) => `${window.location.origin}${page}`;

  const loginWelcomeText = isTeacherApp
    ? 'login_welcome_text_teacher'
    : 'login_welcome_text';

  const loginText = isTeacherApp ? 'login_text_teacher' : 'login_text';

  if (userAuthState === 'loading' || loading) {
    return <LoaderLogo />;
  }

  return (
    <div className={styles[colorTheme]}>
      <h3>Login in the app is currently disabled</h3>
      {/*<div className={styles.contentContainer}>*/}
      {/*  <Icon className={styles.logo} component={logo} />*/}

      {/*  <div className={styles.welcome}>{t(loginWelcomeText)}</div>*/}

      {/*  <div className={styles.text}>{t(loginText)}</div>*/}

      {/*  <ImgWithFallback*/}
      {/*    className={styles.loginImg}*/}
      {/*    src={imageSrc}*/}
      {/*    fallback={imageFallbackSrc}*/}
      {/*    alt={t('XQ_logo')}*/}
      {/*    width={322}*/}
      {/*    height={267}*/}
      {/*  />*/}

      {/*  <GoogleSignInBtn*/}
      {/*    handleClick={async () => {*/}
      {/*      await login();*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <div className={styles.policy}>*/}
      {/*    {isTeacherApp && (*/}
      {/*      <Button*/}
      {/*        className={styles.policyButton}*/}
      {/*        href={HELPDESK_URL}*/}
      {/*        target="_blank"*/}
      {/*        rel="noopener"*/}
      {/*      >*/}
      {/*        {t('helpdesk')}*/}
      {/*      </Button>*/}
      {/*    )}*/}
      {/*    <Button*/}
      {/*      className={styles.policyButton}*/}
      {/*      href={getPolicyUrl('/terms-of-service')}*/}
      {/*      target="_blank"*/}
      {/*      rel="noopener"*/}
      {/*    >*/}
      {/*      {t('terms_of_service')}*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      className={styles.policyButton}*/}
      {/*      href={getPolicyUrl('/privacy-policy')}*/}
      {/*      target="_blank"*/}
      {/*      rel="noopener"*/}
      {/*    >*/}
      {/*      {t('privacy_policy')}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
