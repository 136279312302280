import { EmailErrors, InvalidEmail, ValidationTestResult } from './index';

export type EmailBackendValidationProps = {
  backendValidation: (emails: string[]) => Promise<{
    invalidEmails: string[];
    alreadyViewers: { email: string; classroomNames: string[] }[];
  }>;
  previousInvalidEmails: InvalidEmail[];
  newEmails: string[];
};

export const emailBackendValidation = async ({
  newEmails,
  previousInvalidEmails,
  backendValidation,
}: EmailBackendValidationProps): Promise<ValidationTestResult> => {
  const out: ValidationTestResult = {
    valid: [],
    inValid: [],
  };

  if (newEmails.length) {
    const emailsForValidation = newEmails.filter(
      (x) => !previousInvalidEmails.map((em) => em.email).includes(x)
    );
    const { invalidEmails = [], alreadyViewers = [] } = emailsForValidation.length
      ? await backendValidation(emailsForValidation)
      : { invalidEmails: [], alreadyViewers: [] };

    out.valid = newEmails.filter(
      (x) =>
        !alreadyViewers.map((d) => d.email).includes(x) &&
        !invalidEmails.includes(x)
    );
    out.inValid = invalidEmails.map((em) => ({
      email: em,
      error: EmailErrors.wrong_role,
    }));
    out.inValid = out.inValid.concat(
      alreadyViewers.map(({ email, classroomNames }) => ({
        email,
        classroomNames,
        error: EmailErrors.already_viewer,
      }))
    );
  }

  return out;
};
