import { z } from 'zod';

export const DownloadDriveArchiveSchema = z.object({
  parents: z.string().array(),
  userEmails: z.string().array(),
  programIds: z.string().array(),
  classroomId: z.string(),
});

export type DownloadDriveArchiveRequestDto = z.infer<
  typeof DownloadDriveArchiveSchema
>;

export type DownloadDriveArchiveResponseDto = { archivePath: string };
