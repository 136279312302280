import { TFunction } from 'react-i18next';

import { EmailMessageObject, School } from '@xq/domain';
import { RETOOL_URL } from '@xq/web-config';

import { SendEmailUseCase } from './SendEmailUseCase';

const NX_MAILCHIMP_RECEIVER_EMAIL =
  process.env.NX_PUBLIC_MAILCHIMP_RECEIVER_EMAIL || '';

export class UpdateSchoolEmailUseCase {
  static async execute(school: School, userEmail: string, t: TFunction) {
    if (school.status === 'rejected' || school.status === 'pending') {
      const emailMessage: EmailMessageObject = {
        from_email: 'No-reply@xqinstitute.org',
        subject: t('update_school_email_subject', { schoolName: school.name }),
        html: t('update_school_email_body', {
          schoolName: school.name,
          schoolWebsite: school.website,
          schoolDomains: school.domains.join(', '),
          userEmail,
          retoolUrl: RETOOL_URL,
        }),
        to: [
          {
            email: NX_MAILCHIMP_RECEIVER_EMAIL,
            type: 'to',
          },
        ],
      };

      await SendEmailUseCase.execute([emailMessage]);
    }
  }
}
