export const STUDENT_APP_ROUTES = {
  getRoot: () => '/',
  getJournalPath: () => '/journal',
  getJournalPhase: (
    phaseIndex = ':phaseIndex',
    tabIndex = ':tabIndex',
    subPhaseIndex = ':subPhaseIndex',
    subPhaseContentIndex = ':subPhaseContentIndex'
  ) =>
    `/journal/${phaseIndex}/${tabIndex}/${subPhaseIndex}/${subPhaseContentIndex}`,
  getLogin: () => '/login',
  getTermsOfService: () => '/terms-of-service',
  getPrivacyPolicy: () => '/privacy-policy',
  getErrorScreen: () => '/login-error-screen',
  getYourClassrooms: () => '/your-classrooms',
  getAddFiles: () => '/add-files',
  getResourceContent: (slug = ':slug') => `/resource-content/${slug}`,
  getPostToJournal: () => '/post-to-journal',
  getJournalFeed: () => '/student-feed',
  getStudentFiles: () => '/student-files',
  getDemoLogin: () => '/demo-login',
  getDocumentViewer: (id = ':documentId') => `/document-viewer/${id}`,
  getJournalFeedComments: (id = ':activityId') =>
    `/journal-feed-comments/${id}`,
  getMobileWizardGuide: () => '/mobile-wizard-guide',
} as const;
