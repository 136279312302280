export class LoadingState<T> {
  private state: { id: string; data: T }[] = [];

  get currentState() {
    return this.state;
  }

  add(state: { data: T; id: string }) {
    this.state = [...this.state, state];
  }

  remove(id: string) {
    this.state = this.state.filter((s) => s.id !== id);
  }

  clearStateWithIds(ids: string[]) {
    this.state = this.state.reduce((accumulator, current) => {
      if (ids.includes(current.id)) {
        return accumulator;
      }

      return [...accumulator, current];
    }, [] as { id: string; data: T }[]);
  }

  clearState() {
    this.state = [];
  }
}
