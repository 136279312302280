import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { StreamClient } from 'getstream';
import {
  WordPressCheckUseCase,
  GoogleDriveCheckUseCase,
  StreamFeedCheckUseCase,
} from '@xq/usecases';
import { useApi } from '../ApiProvider/ApiProvider';
import { useEffectOnce } from '../hooks/useEffectOnce';
import { useModal } from '../context/modal-context/modal-context';
import {
  selectAllPrograms,
  selectCurrentUserState,
  useAppSelector,
} from '../store';

import {
  StyledText,
  ErrorIcon,
  SuccessIcon,
  StyledContainer,
} from './SystemCheckModalBody.styles';

enum FeatureStatus {
  loading,
  error,
  success,
}

export const SystemCheckModalBody = () => {
  const { t } = useTranslation();
  const { feedClient } = useApi();
  const { dispatchModal, onConfirm } = useModal();
  const currentUser = useAppSelector(selectCurrentUserState);
  const programs = useAppSelector(selectAllPrograms);
  const [feedCheck, setFeedCheck] = useState<FeatureStatus>(
    FeatureStatus.loading
  );
  const [googleDriveCheck, setGoogleDriveCheck] = useState<FeatureStatus>(
    FeatureStatus.loading
  );
  const [wordPressCheck, setWordPressCheck] = useState<FeatureStatus>(
    FeatureStatus.loading
  );

  const setFeatureCheck = (res: boolean) => {
    return res ? FeatureStatus.success : FeatureStatus.error;
  };

  const statusIcons = {
    [FeatureStatus.error]: <ErrorIcon />,
    [FeatureStatus.success]: <SuccessIcon />,
    [FeatureStatus.loading]: (
      <CircularProgress style={{ marginRight: '5px' }} size={18} />
    ),
  };

  const viewDiagnosticResults = (hasError: boolean) => {
    dispatchModal({
      hideCancel: false,
      cancelButtonText: t('close'),
      showActionButton: false,
      titleText: t('system_check_results'),
      bodyText: hasError ? t('system_check_error') : t('system_check_success'),
    });
  };

  useEffect(() => {
    const checks = [feedCheck, googleDriveCheck, wordPressCheck];
    const isLoading = checks.includes(FeatureStatus.loading);
    const hasError = checks.includes(FeatureStatus.error);

    dispatchModal({
      loading: isLoading,
    });

    if (!isLoading) {
      onConfirm.current = () => viewDiagnosticResults(hasError);
    }
  }, [feedCheck, googleDriveCheck, wordPressCheck]);

  useEffectOnce(() => {
    dispatchModal({
      loading: true,
    });

    new StreamFeedCheckUseCase()
      .execute(feedClient as StreamClient, currentUser)
      .then((res) => {
        setFeedCheck(setFeatureCheck(res));
      });
    new GoogleDriveCheckUseCase().execute(currentUser).then((res) => {
      setGoogleDriveCheck(setFeatureCheck(res));
    });
    new WordPressCheckUseCase()
      .execute(
        programs.map((p) => p.slug),
        currentUser
      )
      .then((res) => {
        setWordPressCheck(setFeatureCheck(res));
      });
  });

  return (
    <StyledContainer>
      <StyledText>{t('check_system_text')}</StyledText>
      <StyledText>
        {statusIcons[feedCheck]} {t('stream_check_text')}
      </StyledText>
      <StyledText>
        {statusIcons[googleDriveCheck]} {t('google_drive_check_text')}
      </StyledText>
      <StyledText>
        {statusIcons[wordPressCheck]} {t('wordpress_check_text')}
      </StyledText>
    </StyledContainer>
  );
};
