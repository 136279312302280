import { StreamClient } from 'getstream';

import { NotificationFeedService } from '@xq/infrastructure';
import { NOTIFICATION_FEED_SLUGS } from '@xq/domain';

export class DeleteNotificationUseCase {
  static async execute(client: StreamClient, id: string) {
    return Promise.all(
      NOTIFICATION_FEED_SLUGS.map(async (slug) => {
        const notificationFeedService =
          NotificationFeedService.getInstance(client);

        const { removed: removedNotificationId } =
          await notificationFeedService.delete(id, slug);

        return removedNotificationId;
      })
    );
  }
}
