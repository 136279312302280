import { Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { cx } from '@emotion/css';
import { useAppSelector, selectIsFullscreen } from '../store';
import styles from './hide-header.module.scss';

type HideHeaderProps = {
  isHeaderVisible: boolean;
  setIsHeaderVisible: Dispatch<SetStateAction<boolean>>;
};

export const HideHeader = ({
  isHeaderVisible,
  setIsHeaderVisible,
  children,
}: PropsWithChildren<HideHeaderProps>) => {
  const { t } = useTranslation();

  const isFullscreen = useAppSelector(selectIsFullscreen);

  return (
    <div
      className={cx({
        [styles.headerButtons]: isHeaderVisible,
        [styles.hiddenHeaderButtons]: !isHeaderVisible,
      })}
    >
      {isFullscreen ? (
        <div
          className={styles.headerVisibilityButtonContainer}
          onClick={() => {
            setIsHeaderVisible(!isHeaderVisible);
          }}
        >
          <Typography className={styles.headerVisibilityButtonTitle}>
            {t(isHeaderVisible ? 'hide_header' : 'show_header')}
          </Typography>
          <div style={{ display: 'flex' }}>
            {isHeaderVisible ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </div>
        </div>
      ) : null}
      {children}
    </div>
  );
};
