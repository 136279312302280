import { StreamClient } from 'getstream';
import { v4 as uuidv4 } from 'uuid';

import {
  Activity,
  Actor,
  Classroom,
  FeedSlugs,
  getUserRole,
  Document,
  Program,
  User,
  JournalLink,
} from '@xq/domain';
import {
  ClassroomRepository,
  FeedMapper,
  loggerService,
  ResourceForTagging,
  tagsService,
} from '@xq/infrastructure';

import { AddActivityUseCase } from './AddActivityUseCase';
import { AddPhaseJournalActivityUseCase } from './AddPhaseJournalActivityUseCase';
import { LogAnalyticsEventUseCase } from '../../LogAnalyticsEventUseCase';

interface OnAddActivityProps {
  isForReview: boolean;
  selectedFiles: Document[];
  feedClient: StreamClient;
  message: string;
  currentProgram: Program | undefined | null;
  currentUser: User;
  currentClassroom: Classroom;
  feedSlug: FeedSlugs;
  journalLink: JournalLink | null;
}

export class OnAddActivityUseCase {
  static async execute({
    isForReview,
    selectedFiles,
    feedClient,
    message,
    currentProgram,
    currentUser,
    currentClassroom,
    feedSlug,
    journalLink,
  }: OnAddActivityProps) {
    try {
      const currentStreamUser = feedClient.currentUser;
      const currentUserId = currentStreamUser?.id;
      const { isStudent } = getUserRole(currentUser);

      if (!currentStreamUser) throw new Error('Stream user is undefined');

      const activity: Activity = {
        id: '',
        autoTags: [],
        feedSlug,
        classroomId: currentClassroom.id,
        programId: currentProgram?.id || '',
        actor: currentStreamUser as unknown as Actor,
        verb: 'add',
        object: currentUserId,
        attachedFiles: FeedMapper.toAttachedFiles(selectedFiles),
        message,
        isForReview,
        hidden: false,
        reaction_counts: {
          comment: 0,
          emoji: 0,
        },
        time: new Date().toISOString(),
        foreign_id: uuidv4(),
        ...(journalLink && { journalLink }),
      };

      const addedActivity = await AddActivityUseCase.execute(
        feedClient,
        activity,
        currentStreamUser,
        currentUser
      );

      if (journalLink) {
        await AddPhaseJournalActivityUseCase.execute(
          feedClient,
          activity,
          currentStreamUser
        );
      }

      if (isStudent) {
        // commented due to requirement to remove auto-tagging and tagging 23.10.2023
        // const activityForTagging: ResourceForTagging = {
        //   classroomId: currentClassroom.id,
        //   resourceId: addedActivity.id,
        //   programId: currentProgram?.id || '',
        //   userId: currentUser.uid,
        //   feedSlug,
        // };

        // commented due to requirement to remove auto-tagging and tagging 23.10.2023
        // tagsService
        //   .tagResource({
        //     resourcesForTagging: [activityForTagging],
        //     client: feedClient,
        //   })
        //   .catch((e) => loggerService.error(e));

        if (
          currentProgram?.slug &&
          feedSlug === FeedSlugs.student &&
          currentUserId
        ) {
          const classroomRepo = new ClassroomRepository();

          classroomRepo
            .persistActivitySummaryCount({
              userId: currentUserId,
              classroomId: currentClassroom?.id,
              slug: currentProgram.slug,
              postsOperator: '+',
            })
            .catch((e) => loggerService.error(e));

          LogAnalyticsEventUseCase.execute({
            number_of_journal_posts: {
              classroom: currentClassroom.name,
              school: currentClassroom.schoolId,
            },
          });
        } else {
          LogAnalyticsEventUseCase.execute({
            number_of_class_feed_posts: {
              classroom: currentClassroom.name,
              school: currentClassroom.schoolId,
            },
          });
        }
      }

      return addedActivity;
    } catch (e) {
      loggerService.error('Error while adding activity: ', e);
      throw new Error(`Error while adding activity: ${e}`);
    }
  }
}
