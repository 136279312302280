import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';

import { DesmosCalculator } from './DesmosCalculator';
import {
  StyledCloseIcon,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogContentContainer,
  StyledDialogHeader,
  StyledMarkForReviewButton,
  StyledSaveButton,
} from './DesmosDialog.styles';
import {useMemo, useRef} from 'react';
import { selectIsCurrentUserStudent, useAppSelector } from '../store';
import { decodeEntities } from '../utils/decodeEntities';

type DesmosDialogProps = {
  isOpen: boolean;
  closeDialog: () => void;
  content: string;
  title: string;
  desmosCalculator: string;
  forReview?: boolean;
  onSave?: (calculator: string) => Promise<void>;
  onMarkForReview?: (calculatorState: string, forReview: boolean) => Promise<void>;
};

export const DesmosDialog = ({
  isOpen,
  closeDialog,
  content,
  title,
  desmosCalculator,
  onSave,
  forReview,
  onMarkForReview,
}: DesmosDialogProps) => {
  const { t } = useTranslation();
  const isCurrentUserStudent = useAppSelector(selectIsCurrentUserStudent);

  const handleCloseDialog = async () => {
    if (isCurrentUserStudent) {
      await onSave?.(JSON.stringify(calculatorRef?.current?.getState()));
    }
    closeDialog();
  };

  const handleMarkForReview = async (forReview: boolean) => {
    await onMarkForReview?.(JSON.stringify(calculatorRef?.current?.getState()), forReview);
    closeDialog();
  }

  const calculatorRef = useRef<Desmos.GraphingCalculator | null>(null);

  const decodedContent = useMemo(
    () => decodeEntities(content),
    [content]
  );

  const decodedTitle = useMemo(
    () => decodeEntities(title),
    [title]
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={isOpen}
      onClose={handleCloseDialog}>
      <StyledDialogHeader>
        <span style={{ fontSize: '18px' }}>
          <b>{decodedTitle}</b>
        </span>
        <StyledCloseIcon onClick={() => handleCloseDialog()} />
      </StyledDialogHeader>
      <StyledDialogBody>
        <StyledDialogContentContainer>
          <StyledDialogContent dangerouslySetInnerHTML={{ __html: decodedContent }} />
          {isCurrentUserStudent && (
            <StyledDialogActions>
              <StyledSaveButton
                onClick={async () => {
                  await onSave?.(
                    JSON.stringify(calculatorRef?.current?.getState())
                  );
                  await handleCloseDialog();
                }}
              >
                {t('save')}
              </StyledSaveButton>
              <StyledMarkForReviewButton
                onClick={() => handleMarkForReview(!forReview)}
              >
                {forReview ? t('unmark_for_review') : t('mark_for_review')}
              </StyledMarkForReviewButton>
            </StyledDialogActions>
          )}
        </StyledDialogContentContainer>
        <DesmosCalculator
          ref={calculatorRef}
          calculatorState={desmosCalculator}
        />
      </StyledDialogBody>
    </Dialog>
  );
};
