import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { CircularProgress } from '@mui/material';
import { useHash } from 'react-use';

import { Activity, Program } from '@xq/domain';

import styles from './activity-list.module.scss';
import { MemoizedFeedOutput } from '../StreamFeedComponents/ClassFeed/components/FeedOutput/FeedOutput';
import { useFeed } from '../context';
import {
  selectCurrentUserClassroomId,
  selectLoadingActivities,
  useAppSelector,
} from '../store';

type ActivityListProps = {
  activities: Activity[];
  currentProgram?: Program;
  currentTabId?: string;
};

export const ActivityList = ({
  activities,
  currentProgram,
  currentTabId,
}: ActivityListProps) => {
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [commentId] = useHash();
  const [activityId, setActivityId] = useState<string>();

  const { fetchActivities } = useFeed();
  const loading = useAppSelector(selectLoadingActivities);

  useLayoutEffect(() => {
    virtuoso.current?.scrollToIndex({
      index: 0,
      behavior: 'smooth',
      align: 'start',
    });
  }, [currentProgram, currentTabId]);

  const currentClassroomId = useAppSelector(selectCurrentUserClassroomId);

  useEffect(() => {
    if (!commentId) {
      return;
    }

    setActivityId(commentId.replace('#', ''));
  }, [commentId, currentClassroomId]);

  useLayoutEffect(() => {
    if (activityId && activities.length) {
      const index = activities.findIndex((v) => v.id === activityId);

      if (index) {
        setActivityId(undefined);
        setTimeout(() => {
          virtuoso.current?.scrollToIndex({
            index,
            behavior: 'smooth',
            align: 'start',
          });
        }, 500);
      }
    }
  }, [activityId, activities]);

  return (
    <div className={styles.activityList}>
      <Virtuoso
        ref={virtuoso}
        data={activities}
        endReached={fetchActivities}
        components={{
          Footer: () => {
            if (!loading) {
              return null;
            }

            return (
              <div className={styles.loadingContainer}>
                <CircularProgress />
              </div>
            );
          },
        }}
        itemContent={(index, activity) => {
          return <MemoizedFeedOutput key={activity.id} activity={activity} />;
        }}
      />
    </div>
  );
};
