import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUserCalculatorByClassroomIdByProgramIdBySubPhaseIdProps,
  loggerService
} from '@xq/infrastructure';

import {
  GetUserCalculatorsByClassroomIdByProgramIdUseCase,
  SetUserCalculatorByClassroomIdByProgramIdBySubPhaseIdUseCase,
  GetUserCalculatorByClassroomIdByProgramIdBySubPhaseIdUseCase,
} from '@xq/usecases';
import { FbCalculatorDto } from '@xq/shared/data-access';

export const fetchUserCalculators = createAsyncThunk(
  'userCalculators/fetchUserCalculators',
  async (arg: { classroomId: string; programId: string }) => {
    const getUserCalculatorsByClassroomIdByProgramIdUseCase =
      new GetUserCalculatorsByClassroomIdByProgramIdUseCase();

    try {
      return await getUserCalculatorsByClassroomIdByProgramIdUseCase.execute(arg);
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  }
);

export const setUserCalculator = createAsyncThunk(
  'userCalculators/setUserCalculator',
  async (arg: FbCalculatorDto) => {
    const setUserCalculatorByClassroomIdByProgramIdBySubPhaseIdUseCase =
      new SetUserCalculatorByClassroomIdByProgramIdBySubPhaseIdUseCase();

    try {
      await setUserCalculatorByClassroomIdByProgramIdBySubPhaseIdUseCase.execute(arg);
      return {
        ...arg,
        id: `${arg.userId}-${arg.classroomId}-${arg.programId}-${arg.phaseId}-${arg.wpPostId}`,
        updatedAt: arg.updatedAt.toString(),
      };
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  }
);

export const fetchUserCalculator = createAsyncThunk(
  'userCalculators/fetchUserCalculator',
  async (arg: getUserCalculatorByClassroomIdByProgramIdBySubPhaseIdProps) => {
    const getCalculator =
      new GetUserCalculatorByClassroomIdByProgramIdBySubPhaseIdUseCase();

    try {
      return (await getCalculator.execute(arg)) || {};
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  }
);
