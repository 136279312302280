import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { User, UserRoles } from '@xq/domain';

const initialCurrentUserState = {} as User;

export const currentUserSlice = createSlice({
  name: 'current-user',
  initialState: initialCurrentUserState,
  reducers: {
    setCurrentUser: (
      state,
      {
        payload: { currentUser },
      }: PayloadAction<{
        currentUser: User;
      }>
    ) => {
      return { ...state, ...currentUser };
    },
    setCurrentClassroomId: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.currentClassroomId = payload;
    },
    setCurrentProgramId: (
      state,
      {
        payload: { programId, classroomId },
      }: PayloadAction<{ programId: string; classroomId: string }>
    ) => {
      state.currentProgramIdByClassroomId[classroomId] = programId;
    },
    updateCurrentSchoolId: (
      state,
      {
        payload: { currentSchoolId },
      }: PayloadAction<{
        currentSchoolId: string;
      }>
    ) => {
      state.currentSchoolId = currentSchoolId;
    },
    setClassroomIds: (state, { payload }: PayloadAction<string[]>) => {
      state.classroomIds = payload;
    },
    setRole: (state, { payload }: PayloadAction<UserRoles[]>) => {
      state.roles = uniq([...(state.roles || []), ...payload]);
    },
    setRoleByClassroomId: (
      state,
      { payload }: PayloadAction<Record<string, UserRoles>>
    ) => {
      state.roleByClassroomId = payload;
    },
    resetCurrentUser: () => initialCurrentUserState,
  },
});

export const currentUserActions = currentUserSlice.actions;
