export enum PageTitles {
  LOGIN = 'XQ Math - Login',
  DASHBOARD = 'XQ Math - Dashboard',
  CLASS_FEED = 'XQ Math - Class Feed',
  CHAT = 'XQ Math - Chat',
  RESOURCES = 'XQ Math - Resources',
  CLASSROOM_ADMIN = 'XQ Math - Classroom Admin',
  PROJECT_JOURNAL = 'XQ Math - Project Journal',
  MY_CLASSROOMS = 'XQ Math - My Classrooms',
  CLASSROOM_PROFILE = 'XQ Math - Classroom Profile',
  MY_FILES = 'XQ Math - My Files',
  SCHOOL_ADMIN = 'XQ Math - School Admin',
  ARCHIVED_CLASSROOM_ADMIN = 'XQ Math - Archived Classroom Admin',
}
