import { useEffect } from 'react';
import {
  useAppDispatch,
  useAppSelector,
  appActions,
  selectIsPrinting,
} from '../store';

interface Html2PdfOptions {
  image?: {
    type: 'jpeg';
    quality: number;
  };
  html2canvas?: {
    scale?: number;
    useCORS?: boolean;
    width?: number;
  };
  jsPDF?: {
    format?: [number, number];
  };
}

interface Html2Pdf {
  set(options: Html2PdfOptions): Html2Pdf;
  from(content: string | HTMLElement): Html2Pdf;
  toPdf(): Html2Pdf;
  get(outputType: 'pdf'): Promise<{
    autoPrint: () => void;
    output: (type: string) => string;
  }>;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const html2pdf: () => Html2Pdf = require('html2pdf.js');

type PrintProps = {
  ref: React.RefObject<HTMLDivElement | null>;
  // https://html2canvas.hertzen.com/configuration
  canvasOptions?: {
    scale?: number;
    width?: number;
    onclone?: (document: Document) => void;
  };
  // https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
  pdfOptions?: {
    format?: [number, number];
  };
};

export const usePrint = ({ ref, canvasOptions, pdfOptions }: PrintProps) => {
  const dispatch = useAppDispatch();

  const isPrinting = useAppSelector(selectIsPrinting);

  const startPrinting = () => {
    dispatch(appActions.setIsPrinting(true));
  };

  const handlePrint = async () => {
    if (!ref.current) return;

    const content = ref.current;

    if (!content) return;

    const pdf = await html2pdf()
      .set({
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 3, useCORS: true, ...canvasOptions },
        jsPDF: pdfOptions,
      })
      .from(content)
      .toPdf()
      .get('pdf');

    pdf.autoPrint();

    window.open(pdf.output('bloburl'), '_blank');

    dispatch(appActions.setIsPrinting(false));
  };

  useEffect(() => {
    if (isPrinting) handlePrint();
  }, [isPrinting]);

  return { startPrinting } as const;
};
