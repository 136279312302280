import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../lib/translations/en.json';

export const resources = {
  en: {
    translation: enTranslation,
  },
} as const;

export const initializeI18n = (debug: boolean) => {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug,
      detection: {
        order: ['queryString', 'cookie'],
        caches: ['cookie'],
      },
      interpolation: {
        escapeValue: false,
      },
      resources,
    });
};
