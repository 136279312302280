import { UserRoles } from '@xq/domain';
import {
  AnalyticsUserScope,
  FirebaseService,
  GoogleAnalyticsService,
} from '@xq/infrastructure';

type Payload = {
  userId: string;
  properties: Omit<AnalyticsUserScope, 'role'> & { role: UserRoles };
};

export class SetAnalyticsUserPropertiesUseCase {
  private static googleAnalyticsService = new GoogleAnalyticsService(
    FirebaseService.getInstance()
  );

  static execute(payload: Payload) {
    const analyticsRole =
      this.googleAnalyticsService.mapUserRoleToAnalyticsRole(
        payload.properties.role
      );
    this.googleAnalyticsService.setProperties({
      userId: payload.userId,
      properties: {
        ...payload.properties,
        role: analyticsRole,
      },
    });
  }
}
