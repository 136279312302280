import { createSelector } from '@reduxjs/toolkit';

import { getProgramProgressId, isProgramProgressEmpty } from '@xq/domain';

import { selectUserProgramProgressState } from '../index';
import { selectCurrentUserClassroomStudents } from '../current-user';

export const selectUserProgramProgress = createSelector(
  [
    selectUserProgramProgressState,
    (_, userId: string) => userId,
    (_, __, classroomId) => classroomId,
    (_, __, ___, programId) => programId,
  ],
  (state, userId, classroomId, programId) => {
    return state[`${userId}-${classroomId}-${programId}`];
  }
);

export const selectIsProgramProgressEmpty = createSelector(
  [
    selectUserProgramProgressState,
    selectCurrentUserClassroomStudents,
    (_, classroomId?: string) => classroomId,
    (_, __, programId?: string) => programId,
  ],
  (programProgress, students, classroomId, programId) => {
    if (!classroomId || !programId) return true;

    return students.some((s) => {
      const programProgressId = getProgramProgressId({
        userId: s.uid,
        classroomId,
        programId,
      });

      const studentProgramProgress = programProgress?.[programProgressId] || {};

      return isProgramProgressEmpty(studentProgramProgress);
    });
  }
);
