import { cond, stubTrue, defaultTo } from 'lodash';

import { Notification } from '@xq/domain';
import { NotificationSupportedVerbs } from '@xq/shared/data-access';

import { NotificationPaths } from '../NotificationsDrawer/NotificationsDrawer';

export const MY_FILES_PATH = 'MY_FILES';
export const MY_CALCULATORS_PATH = 'MY_CALCULATORS';

type Path = {
  path: string;
  hash: string;
};
// todo extend verbToPath with admin paths
export const verbToPath = (
  paths: NotificationPaths,
  notification: Notification,
  isMobile: boolean
) => {
  const hash =
    typeof notification.object === 'string' ? notification.object : '';

  const determinePath = cond<NotificationSupportedVerbs, Path>([
    [
      (verb) =>
        [
          NotificationSupportedVerbs.addedForReview,
          NotificationSupportedVerbs.removedFromReview,
          NotificationSupportedVerbs.taggedTeacherJournalFeed,
          NotificationSupportedVerbs.finishedPhase,
        ].includes(verb),
      () => ({
        path: defaultTo(paths.getJournal?.(notification.actorId), ''),
        hash,
      }),
    ],
    [
      (verb) =>
        [
          NotificationSupportedVerbs.addedDocumentForReview,
          NotificationSupportedVerbs.removedDocumentFromReview,
        ].includes(verb),
      () => ({
        path: defaultTo(paths.getJournal?.(notification.actorId), ''),
        hash: MY_FILES_PATH,
      }),
    ],
    [
      (verb) =>
        [
          NotificationSupportedVerbs.addedCalculatorForReview,
          NotificationSupportedVerbs.removedCalculatorFromReview,
        ].includes(verb),
      () => ({
        path: defaultTo(paths.getJournal?.(notification.actorId), ''),
        hash: MY_CALCULATORS_PATH,
      }),
    ],
    [
      (verb) =>
        [NotificationSupportedVerbs.reviewedDocument].includes(verb) &&
        isMobile,
      () => ({
        path: defaultTo(paths.getStudentFiles?.(), ''),
        hash,
      }),
    ],
    [
      (verb) => [NotificationSupportedVerbs.reviewedDocument].includes(verb),
      () => ({
        path: defaultTo(paths.getJournal?.(notification.actorId), ''),
        hash: MY_FILES_PATH,
      }),
    ],

    [
      (verb) => [NotificationSupportedVerbs.reviewedCalculator].includes(verb),
      () => ({
        path: defaultTo(paths.getJournal?.(notification.actorId), ''),
        hash: MY_CALCULATORS_PATH,
      }),
    ],

    [
      (verb) => [NotificationSupportedVerbs.sentAChatMessage].includes(verb),
      () => ({
        path: defaultTo(paths.getChats?.(notification.actorId), ''),
        hash,
      }),
    ],
    [
      (verb) =>
        [NotificationSupportedVerbs.taggedStudentJournalFeed].includes(verb),
      () => ({
        path: defaultTo(paths.getJournal?.(), ''),
        hash,
      }),
    ],
    [stubTrue, () => ({ path: '', hash })],
  ]);

  return determinePath(notification.verb as NotificationSupportedVerbs);
};
