export enum CollectionNames {
  users = 'users',
  usersFiles = 'files',
  classrooms = 'classrooms',
  classroomFiles = 'files',
  programs = 'programs',
  projectFiles = 'projectFiles',
  schools = 'schools',
  schoolsFiles = 'files',
  userCalculatorsByClassroomId = 'userCalculatorsByClassroomId',
  userCalculatorsByProgramId = 'userCalculatorsByProgramId',
  programProgress = 'programProgress',
  followedUpUsersByClassroomId = 'followedUpUsersByClassroomId',
  studentFolderIdByEmailByProgramId = 'studentFolderIdByEmailByProgramId',
  templates = 'templates',
  userRefreshTokens = 'userRefreshTokens',
  phaseJournal = 'phaseJournal',
  wordPressCache = 'wordPressCache',
  wizardContent = 'wizardContent',
}
