import { useTranslation } from 'react-i18next';

import { Document, convertDocumentSizeToMb } from '@xq/domain';

import { FileListPreviewStyles } from './FileListPreview.styles';
import { FilePreview } from '../FilePreview/FilePreview';

type FilePreviewProps = {
  uploadingFiles?: Document[];
  uploadedFiles: Document[];
  handleDeleteFile(fileId: string): void;
  mbSizeLimitation?: number;
};

export const FileListPreview = ({
  uploadingFiles = [],
  uploadedFiles = [],
  handleDeleteFile,
  mbSizeLimitation,
}: FilePreviewProps) => {
  const { t } = useTranslation();

  const renderSizeLimitationError = (document: Document) => {
    if (!mbSizeLimitation || !document.size) return null;

    const fileSizeInMb = convertDocumentSizeToMb(document.size);

    if (fileSizeInMb > mbSizeLimitation) {
      return (
        <p style={{ color: 'red' }}>
          {t('file_size_limitation_error', { mbSize: mbSizeLimitation })}
        </p>
      );
    }

    return null;
  };

  return (
    <FileListPreviewStyles>
      {uploadingFiles?.map((file) => (
        <FilePreview key={file.id} file={file} isUploading />
      ))}

      {uploadedFiles.map((file) => (
        <div key={file.id}>
          {renderSizeLimitationError(file)}
          <FilePreview file={file} onDelete={handleDeleteFile} />
        </div>
      ))}
    </FileListPreviewStyles>
  );
};
