import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ClassroomRepository,
  FBProgramProgress,
  loggerService,
  ProgramProgressMapper,
} from '@xq/infrastructure';

import {
  ProgramProgressPayload,
  UpdateProgramProgressPayload,
} from './user-program-progress.interface';

const classroomRepository = new ClassroomRepository();

export const fetchUserProgramProgress = createAsyncThunk(
  'user-program-progress/fetchProgramProgress',
  async (payload: ProgramProgressPayload) => {
    const response = await classroomRepository.getUserProgramProgressByUserId(
      payload
    );

    try {
      const programProgress = ProgramProgressMapper.toDomain(
        response.data() as FBProgramProgress
      );

      return {
        userId: payload.userId,
        programProgress,
        programId: payload.programId,
        classroomId: payload.classroomId,
      };
    } catch (e) {
      loggerService.error(e);

      throw e;
    }
  }
);

export const updateUserProgramProgress = createAsyncThunk(
  'user-program-progress/updateProgramProgress',
  async (payload: UpdateProgramProgressPayload) => {
    return classroomRepository.persistUserProgramProgress(payload);
  }
);
