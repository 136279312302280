import {
  AuthCredential,
  GoogleAuthProvider,
  signInWithCredential,
} from 'firebase/auth';

import { firebaseService } from '@xq/infrastructure';
import {
  ImpersonateUserRequestDto,
  ImpersonateUserResponseDto,
} from '@xq/shared/data-access';
import { callWithRetry } from '@xq/shared/utils';

export class ImpersonateUserUseCase {
  static async execute(googleUserEmail: string, isTeacherApp?: boolean) {
    const impersonateUser = firebaseService.prepareFunctionCaller<
      ImpersonateUserRequestDto,
      ImpersonateUserResponseDto
    >('impersonateUser');
    const {
      data: { idToken, accessToken },
    } = await callWithRetry(() =>
      impersonateUser({
        email: googleUserEmail,
        teacherApp: !!isTeacherApp,
      })
    );

    gapi.client.setToken({
      access_token: accessToken,
    });

    const credential = GoogleAuthProvider.credential(
      idToken,
      accessToken
    ) as AuthCredential;

    await signInWithCredential(firebaseService.auth, credential);
  }
}
