import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';

import { Document } from '@xq/domain';

import { FilesLoaderStyles } from './FilesLoader.styles';

type FilesLoaderProps = {
  files: Document[];
  loadingLabel?: string;
};

export const FilesLoader = ({ files, loadingLabel }: FilesLoaderProps) => {
  const { t } = useTranslation();

  return (
    <FilesLoaderStyles>
      {files.map((file) => (
        <div key={file.id} className="file-loader-wrapper">
          <div className="label-container">
            <div className="file-name">{file.name}</div>
            <div className="upload-label">{loadingLabel || t('uploading')}</div>
          </div>
          <LinearProgress className="progress-bar" />
        </div>
      ))}
    </FilesLoaderStyles>
  );
};
