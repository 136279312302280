import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams, Outlet, useNavigate } from 'react-router-dom';
import { GridProps, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { ExitFullscreenButton } from '../ExitFullscreenButton';
import {
  StyledFullscreenButton,
  StyledGrid,
  StyledPhases,
  StyledProgramSelectContainer,
} from './Phases.styles';
import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay';
import { ProgramsSelect } from '../ProgramsSelect/ProgramsSelect';
import {
  appActions,
  fetchProgramPost,
  programPostActions,
  selectCurrentUserProgram,
  selectIsFullscreen,
  selectIsNewUser,
  selectProgramPostLoading,
  selectProgramPostSelectedSlug,
  selectShowedWizard,
  selectCurrentUserClassroom,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { MOBILE_MEDIA_QUERY } from '../utils';
import { HideHeader } from '../HideHeader';
import { JournalTab } from '../Journal/JournalTabs';
import { STUDENT_APP_ROUTES } from '@xq/web-config';

const defaultGridProps: GridProps = {
  container: true,
  item: true,
  xs: 12,
  sm: 12,
  md: 6,
  lg: 7,
  xl: 7,
};

const collapsedGridProps: GridProps = {
  container: true,
};

type PhasesProps = {
  className?: string;
  isJournalCollapsed?: boolean;
  setActiveTabIndex: Dispatch<SetStateAction<JournalTab>>;
};

export const Phases = ({
  className,
  isJournalCollapsed = false,
  setActiveTabIndex,
}: PhasesProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { studentId } = useParams();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const isLoading = useAppSelector(selectProgramPostLoading);
  const selectedSlug = useAppSelector(selectProgramPostSelectedSlug);
  const isFullscreen = useAppSelector(selectIsFullscreen);
  const isNewUser = useAppSelector(selectIsNewUser);
  const programPostLoading = useAppSelector(selectProgramPostLoading);
  const showedWizard = useAppSelector(selectShowedWizard);
  const classroom = useAppSelector(selectCurrentUserClassroom);

  useEffect(() => {
    if (selectedSlug) {
      dispatch(
        fetchProgramPost({
          slug: selectedSlug,
          isTeacher: !!studentId,
        })
      );
    }
  }, [selectedSlug, studentId]);

  useEffect(() => {
    if (currentProgram?.slug) {
      dispatch(programPostActions.setSelectedSlug(currentProgram.slug));
    }
  }, [currentProgram]);

  useEffect(() => {
    if (
      !currentProgram ||
      !classroom ||
      programPostLoading ||
      !isNewUser ||
      !isMobile ||
      showedWizard
    )
      return;

    navigate(STUDENT_APP_ROUTES.getMobileWizardGuide());

    dispatch(appActions.setShowedWizard(true));
  }, [classroom, currentProgram, isNewUser, programPostLoading]);

  const hidden = isFullscreen || isJournalCollapsed;

  return (
    <StyledGrid {...(!hidden ? defaultGridProps : collapsedGridProps)}>
      <LoadingOverlay isLoading={isLoading} width={'100%'} height={'100%'}>
        <StyledPhases className={className}>
          {!isMobile && (
            <StyledProgramSelectContainer>
              {isHeaderVisible ? <ProgramsSelect /> : <div></div>}
              <HideHeader
                isHeaderVisible={isHeaderVisible}
                setIsHeaderVisible={setIsHeaderVisible}
              >
                {!isFullscreen && (
                  <StyledFullscreenButton
                    endIcon={<FullscreenIcon />}
                    variant="text"
                    onClick={() => {
                      dispatch(appActions.setIsFullscreen(true));
                    }}
                    size="large"
                  >
                    {t('fullscreen')}
                  </StyledFullscreenButton>
                )}
              </HideHeader>
            </StyledProgramSelectContainer>
          )}
          {isFullscreen && (
            <ExitFullscreenButton
              onClick={() => {
                if (!isHeaderVisible) {
                  setIsHeaderVisible(true);
                }

                setActiveTabIndex(JournalTab.MyJournal);
              }}
            />
          )}
          <Outlet context={isHeaderVisible} />
        </StyledPhases>
      </LoadingOverlay>
    </StyledGrid>
  );
};
