import { createSelector } from '@reduxjs/toolkit';

import { orderDocumentsByTemplateId } from '@xq/domain';

import { TEMPLATE_SOURCE, templatesAdapter } from './templates-slice';
import { selectCurrentUserProgram, selectTemplatesState } from '../index';

export const {
  selectAll: selectAllTemplates,
  selectById: selectTemplateById,
  selectEntities: selectTemplateEntities,
} = templatesAdapter.getSelectors(selectTemplatesState);

export const selectTemplatedIdsByProgramSlug = createSelector(
  [selectTemplatesState, (_, programSlug: string) => programSlug],
  (state, programSlug) => state.templatedIdsByProgram[programSlug] ?? []
);

export const selectTemplatedIdsByCurrentUser = createSelector(
  [selectTemplatesState, selectCurrentUserProgram],
  (state, program) => state.templatedIdsByProgram?.[program?.slug ?? ''] ?? []
);

export const selectWpTemplates = createSelector(
  [selectTemplatesState],
  (state) => state.wpTemplates
);

export const selectOrderedTemplatesByProgramAndSchool = createSelector(
  [
    selectAllTemplates,
    selectTemplatedIdsByProgramSlug,
    (_, __, schoolId?: string) => schoolId,
  ],
  (templates, fileIds, schoolId) => {
    const filteredTemplates = templates.filter(
      (t) =>
        t.properties?.templateId && fileIds.includes(t.properties?.templateId)
    );
    const teacherTemplates = filteredTemplates.filter(
      (t) => t.source === TEMPLATE_SOURCE.TEACHER
    );
    const teacherTemplateIds = new Set(
      teacherTemplates.map((t) => t.properties?.templateId)
    );

    const schoolTemplates = filteredTemplates.filter(
      (t) =>
        t.source === TEMPLATE_SOURCE.SCHOOL &&
        t.schoolId === schoolId &&
        !teacherTemplateIds.has(t.properties?.templateId)
    );

    return orderDocumentsByTemplateId(
      schoolTemplates.concat(teacherTemplates),
      fileIds
    );
  }
);

export const selectTemplateAudience = createSelector(
  [selectTemplatesState, (_, templateId: string) => templateId],
  (state, templateId) => state.wpTemplates[templateId]?.audience
);
