import { SubscribeCallback } from 'faye';
import {
  GetFeedOptions,
  ReactionAPIResponse,
  RealTimeMessage,
  StreamClient,
  StreamFeed,
  StreamUser,
} from 'getstream';

import {
  DeletePostRequestDto,
  FunctionsApi,
  StreamActivity,
  StreamCommentReaction,
  UpdateCommentRequestDto,
  UpdateCommentResponseDto,
  UpdatePostRequestDto,
} from '@xq/shared/data-access';
import { Activity, FeedSlugs, ReactionKinds } from '@xq/domain';

import { FeedMapper } from '../mappers/Feed.mapper';
import { firebaseService } from './FirebaseService';
import { loggerService } from './LoggerService';
import { StreamFeedService } from './StreamIo/StreamFeedService';
import { callWithRetry } from '@xq/shared/utils';

export interface ResourceForTagging {
  mimeType?: string;
  resourceId: string;
  userId: string;
  classroomId: string;
  programId: string;
  feedSlug: FeedSlugs;
}

export type CommentData = {
  commentId: string;
  text: string;
  hidden?: boolean;
};

export class FeedService {
  private static instance: FeedService;

  private feed: StreamFeed | undefined;

  private activityLimit = 100;

  private reactionLimit = 25;

  private constructor(private readonly client: StreamClient) {}

  async updatePost(payload: UpdatePostRequestDto) {
    const updatePost = firebaseService.prepareFunctionCaller<
      UpdatePostRequestDto,
      void
    >(FunctionsApi.UpdatePost);

    try {
      await callWithRetry(() => updatePost(payload));
    } catch (e) {
      loggerService.error(e);
    }
  }

  async deletePost(payload: DeletePostRequestDto) {
    const deletePost = firebaseService.prepareFunctionCaller<
      DeletePostRequestDto,
      void
    >(FunctionsApi.DeletePost);

    try {
      await callWithRetry(() => deletePost(payload));
    } catch (e) {
      loggerService.error(e);
    }
  }

  async onTag(resourcesForTagging: ResourceForTagging[]) {
    const onTagCaller = firebaseService.prepareFunctionCaller('onTag');
    try {
      return await callWithRetry(() => onTagCaller(resourcesForTagging));
    } catch (e) {
      loggerService.error("Couldn't scan and tag file from journal feed: ", e);
      return {};
    }
  }

  getStreamFeed(slug: FeedSlugs, userId: string) {
    if (this.client?.userToken) {
      return StreamFeedService.getInstance(this.client.userToken)?.client?.feed(
        slug,
        userId
      );
    }

    throw new Error('Could not get Stream feed because token is not defined');
  }

  createJournalFeed(
    activities: StreamActivity[],
    slug: FeedSlugs,
    userId: string
  ) {
    this.feed = this.getStreamFeed(slug, userId);

    if (this.feed) {
      return {
        activities: FeedMapper.toDomainActivities(activities),
        slug,
        feedId: userId,
      };
    }
    throw new Error(
      'Could not create journal feed because Stream feed is not defined'
    );
  }

  async getFeedActivities(options?: GetFeedOptions) {
    if (this.feed) {
      try {
        const response = await this.feed.get({
          ...options,
          limit: this.activityLimit,
        });

        return FeedMapper.toDomainActivities(
          response.results as StreamActivity[]
        );
      } catch (e) {
        loggerService.error(`Error getting feed activities: ${e}`);
        return [];
      }
    }
    throw new Error(
      'Could not get activities because Stream feed is not defined'
    );
  }

  async getFeedActivity(id: string) {
    if (this.feed) {
      const response = await this.feed.getActivityDetail(id, {
        withReactionCounts: true,
      });

      return FeedMapper.toDomainActivities(
        response.results as StreamActivity[]
      );
    }
    throw new Error(
      'Could not get activities because Stream feed is not defined'
    );
  }

  subscribe(callback: SubscribeCallback<RealTimeMessage>) {
    try {
      if (this.feed) return this.feed.subscribe(callback);
    } catch (e) {
      loggerService.log(
        `Error subscribing to feed with ID ${this?.feed?.id}: ${e}`
      );
    }
    throw new Error('Could not subscribe because Stream feed is not defined');
  }

  unsubscribe() {
    if (this.feed) return this.feed.unsubscribe();
    throw new Error('Could not unsubscribe because Stream feed is not defined');
  }

  addActivity(activity: Activity, streamUser: StreamUser) {
    if (this.feed) {
      const payload = {
        ...FeedMapper.toStreamActivity(activity),
        actor: streamUser,
      };

      return this.feed.addActivity(payload);
    }
    throw new Error(
      'Could not add activity because Stream feed is not defined'
    );
  }

  async getReactions<TData = ReactionAPIResponse>(
    activityId: string,
    kind: ReactionKinds,
    idLt?: string
  ) {
    if (this.feed) {
      const response = await this.feed.client.reactions.filter({
        activity_id: activityId,
        kind,
        limit: this.reactionLimit,
        id_lt: idLt,
      });
      return response.results as TData[];
    }

    return [];
  }

  async addEmojiReaction(activityId: string, emoji: string) {
    if (this.feed)
      return this.feed.client.reactions.add(ReactionKinds.emoji, activityId, {
        emoji,
      });
    throw new Error(
      'Could not add emoji reaction because Stream feed is not defined'
    );
  }

  async removeEmojiReaction(emojiId: string) {
    if (this.feed) return this.feed.client.reactions.delete(emojiId);
    throw new Error(
      'Could not remove emoji reaction because Stream feed is not defined'
    );
  }

  addComment(activityId: string, text: string) {
    if (this.feed)
      return this.feed.client.reactions.add(ReactionKinds.comment, activityId, {
        text,
      }) as Promise<StreamCommentReaction>;
    throw new Error('Could not add comment because Stream feed is not defined');
  }

  editComment(data: CommentData) {
    if (this.feed) {
      const editCommentCaller = firebaseService.prepareFunctionCaller<
        UpdateCommentRequestDto,
        UpdateCommentResponseDto
      >('updateComment');

      return callWithRetry(() => editCommentCaller(data));
    }

    throw new Error('Could not add comment because Stream feed is not defined');
  }

  async deleteComment(commentId: string) {
    if (this.feed) return this.feed.client.reactions.delete(commentId);
    throw new Error(
      'Could not delete comment because Stream feed is not defined'
    );
  }

  static getInstance(client: StreamClient) {
    if (!FeedService.instance) {
      FeedService.instance = new FeedService(client);
    }
    return FeedService.instance;
  }
}
