import { Program, ProgramSchema } from '@xq/domain';

import {FbProgramDto, FbProgramSchema } from '../dto';
import { Mapper } from './mapper';

export class ProgramMapper extends Mapper<Program, FbProgramDto> {
  toDomain(program: FbProgramDto): Program {
    return ProgramSchema.parse({
      ...program,
      isActive: program.isActive ?? true,
      createdAt: this.convertTimestamp(program.createdAt)?.toString() ?? null,
      modifiedAt: this.convertTimestamp(program.modifiedAt)?.toString() ?? null,
    });
  }

  toPersistence(program: Program): FbProgramDto {
    return FbProgramSchema.parse({
      ...program,
      createdAt: this.transformToDate(program.createdAt),
      modifiedAt: this.transformToDate(program.modifiedAt),
    });
  }
}
