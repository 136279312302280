import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { cx } from '@emotion/css';

import styles from './drive-folder-button.module.scss';
import { ReactComponent as GoogleDriveIcon } from '../assets/drive-icon.svg';

export const DriveFolderButton = ({
  driveFolderId,
  className,
}: {
  driveFolderId?: string;
  className?: string;
}) => {
  const { t } = useTranslation();
  const openGoogleDriveFolder = () => {
    window.open(`https://drive.google.com/drive/u/3/folders/${driveFolderId}`);
  };

  if (!driveFolderId) {
    return null;
  }

  return (
    <Button
      // disabled={isImpersonating}
      color="info"
      variant="outlined"
      className={cx(className, styles.driveButton)}
      onClick={openGoogleDriveFolder}
    >
      {t('go_to_drive')}
      <GoogleDriveIcon className={styles.googleDriveIcon} />
    </Button>
  );
};
