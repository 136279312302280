import { ChangeEvent, KeyboardEvent } from 'react';
import { FormControlLabel, Checkbox, styled } from '@mui/material';

import { EventTypes } from '../../enums/EventTypes';
import { CheckableProgramContent } from './StudentPhaseAccordion';

interface StudentLeftHeaderContentProps {
  handleClick: (
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>
  ) => void;
  isDisabled: boolean;
  value: boolean;
  checkable: CheckableProgramContent;
}

const StyledAcknowledgeControl = styled(FormControlLabel)`
  position: relative;
  height: 27px;
  margin-right: 0;
`;

export const SubAccordionConnectionContainer = styled('div')`
  margin-top: 5px;
  margin-right: 5px;
`;

export const StudentLeftHeaderContent = ({
  handleClick,
  isDisabled,
  value,
  checkable,
}: StudentLeftHeaderContentProps) => {
  const handleEnter = (
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>
  ) => {
    if (
      event.type === EventTypes.click ||
      (event.type === EventTypes.keyDown &&
        (event as KeyboardEvent).key === 'Enter')
    ) {
      handleClick(event);
    }
  };
  return (
    <StyledAcknowledgeControl
      aria-label="Acknowledge"
      onClick={(e) => {
        e.stopPropagation();
      }}
      control={
        <Checkbox
          color="primary"
          disabled={isDisabled}
          checked={value}
          onChange={handleClick}
          onKeyDown={handleEnter}
          name={checkable}
        />
      }
      label=""
    />
  );
};
