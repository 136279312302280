import { firebaseService, UserRepository } from '@xq/infrastructure';

import { Document } from '@xq/domain';
import { writeBatch } from 'firebase/firestore';

type PersistStudentFileProps = {
  userId: string;
  classroomId: string;
  programId: string;
  fileValues: Document;
};

export class PersistStudentFileUseCase {
  static async execute({
    userId,
    classroomId,
    programId,
    fileValues,
  }: PersistStudentFileProps) {
    const userRepo = new UserRepository();
    const batch = writeBatch(firebaseService.db);

    userRepo.setFile({
      batch,
      fileData: { ...fileValues, autoTags: [], ownerId: userId },
      userId,
      classroomId,
      programId,
    });

    await batch.commit();

    return fileValues;
  }
}
