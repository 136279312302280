import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type VisibleBanners = {
  viewer: boolean;
  demo: boolean;
};

type XQApp = 'teacher' | 'student';

interface AppState {
  isImpersonating: boolean;
  visibleBanners: VisibleBanners;
  isFullscreen: boolean;
  isPrinting: boolean;
  xqApp: XQApp;
  isNewUser: boolean;
  showedWizard: boolean;
}

const initialState: AppState = {
  isImpersonating: false,
  visibleBanners: {
    viewer: false,
    demo: false,
  },
  isFullscreen: false,
  isPrinting: false,
  xqApp: 'student',
  isNewUser: false,
  showedWizard: false,
};

export const appSlice = createSlice({
  name: 'app-slice',
  initialState,
  reducers: {
    setIsImpersonating(state, value: PayloadAction<boolean>) {
      state.isImpersonating = value.payload;
    },
    setBannersVisibility(state, value: PayloadAction<Partial<VisibleBanners>>) {
      state.visibleBanners = { ...state.visibleBanners, ...value.payload };
    },
    setIsFullscreen(state, { payload }: PayloadAction<boolean>) {
      state.isFullscreen = payload;
    },
    setIsPrinting(state, { payload }: PayloadAction<boolean>) {
      state.isPrinting = payload;
    },
    setXqApp: (state, { payload }: PayloadAction<XQApp>) => {
      state.xqApp = payload;
    },
    setIsNewUser: (state, { payload }: PayloadAction<boolean>) => {
      state.isNewUser = payload;
    },
    setShowedWizard: (state, { payload }: PayloadAction<boolean>) => {
      state.showedWizard = payload;
    },
  },
});

export const appActions = appSlice.actions;
