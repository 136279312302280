import { useTranslation } from 'react-i18next';

import styles from './file-for-review-label.module.scss';

type FileForReviewLabelProps = {
  count?: number;
  text?: string;
};

export const FileForReviewLabel = ({ count = 1, text }: FileForReviewLabelProps) => {
  const { t } = useTranslation();

  return (
    <label className={styles.fileForReview}>
      {!text ? t('file_for_review', { count }) : text}
    </label>
  );
};
