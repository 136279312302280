import { ChangeEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';
import { cx } from '@emotion/css';
import { Tab, useMediaQuery } from '@mui/material';

import { MOBILE_MEDIA_QUERY } from '../utils';

import styles from './journal-tabs.module.scss';
import { CreateDocument } from './CreateDocument/CreateDocument';
import { DriveFolderButton } from './DriveFolderButton';
import { selectIsCurrentUserStudent, useAppSelector } from '../store';
import { StudentWizardStep } from '../hooks/useStudentWizardGuide';

const tabProps = (index: number) => ({
  id: `full-width-tab-${index}`,
});

export enum JournalTab {
  MyJournal,
  MyFiles,
  DesmosCal
}

type JournalTabsProps = {
  activeTabIndex: number;
  onTabChange: (event: ChangeEvent<unknown>, newValue: number) => void;
  rootFolderId: string;
};

export const JournalTabs = ({
  onTabChange,
  activeTabIndex,
  rootFolderId,
}: JournalTabsProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const isCurrentUserStudent = useAppSelector(selectIsCurrentUserStudent);

  const renderDriveFolderButton = () => {
    if (activeTabIndex !== JournalTab.MyFiles || isCurrentUserStudent) {
      return null;
    }

    return (
      <DriveFolderButton
        driveFolderId={rootFolderId}
        className={styles.createDocumentContainer}
      />
    );
  };

  const renderCreateDocument = () => {
    if (
      isMobile ||
      activeTabIndex !== JournalTab.MyFiles ||
      !isCurrentUserStudent
    ) {
      return null;
    }

    return (
      <div
        className={styles.createDocumentContainer}
        id={StudentWizardStep.MY_FILES_CREATE_FILE}
      >
        <CreateDocument />
      </div>
    );
  };

  return (
    <>
      <div
        className={cx(styles.journalTabContainer, {
          [styles.mobileContainer]: isMobile,
        })}
      >
        <Tabs
          value={activeTabIndex}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab
            className={styles.journalTab}
            label={t('my_journal')}
            {...tabProps(JournalTab.MyFiles)}
          />
          <Tab
            data-cy="my-files-tab"
            className={styles.journalTab}
            label={t('my_files')}
            {...tabProps(JournalTab.MyFiles)}
          />

          <Tab
            data-cy="desmos-calc"
            className={styles.journalTab}
            label="My Calculators"
            {...tabProps(JournalTab.DesmosCal)}
          />
        </Tabs>
      </div>
      {renderDriveFolderButton()}
      {renderCreateDocument()}
    </>
  );
};
