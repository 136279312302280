import { useEffect } from 'react';
import {
  useNavigationType,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { isEmpty } from 'lodash';

import { calcActivePhaseId, ContentType } from '@xq/domain';

import {
  programPostActions,
  selectCurrentUserClassroom,
  selectCurrentUserProgram,
  selectCurrentUserState,
  selectPhaseContent,
  selectPhaseSelections,
  selectProgramPost,
  selectUserProgramProgress,
  updateUserProgramProgress,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { RibbonNavigation } from '../RibbonNavigation/RibbonNavigation';
import { PhaseContent } from '../PhaseContent/PhaseContent';
import {
  getFilteredTabs,
  PhasesContent,
} from '../context/phase-context/getPhaseTabs';
import { usePhase, useNotifier } from '../context';

export type PersistSubPhasePayload = {
  phaseId: number;
  phaseName: string;
  subPhaseId: number;
  completed: boolean;
  subPhaseContentIds?: number[];
  isSubPhaseContentCompleted?: boolean;
};

export const JournalPhase = () => {
  const dispatch = useAppDispatch();
  const { phaseIndex = '0', studentId } = useParams();
  const { activePhaseIndex } = usePhase();
  const navigationType = useNavigationType();

  const isHeaderVisible: boolean = useOutletContext();

  const { notifyFinishedPhase } = useNotifier();

  const currentUser = useAppSelector(selectCurrentUserState);
  const programPost = useAppSelector(selectProgramPost);
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const userProgramProgress = useAppSelector((state) =>
    selectUserProgramProgress(
      state,
      studentId ?? currentUser.uid,
      currentClassroom?.id ?? '',
      currentProgram?.id ?? ''
    )
  );
  const phasesContent = useAppSelector((state) =>
    selectPhaseContent(state, +phaseIndex)
  );
  const phaseSelections = useAppSelector(selectPhaseSelections);

  const persistSubPhase = ({
    phaseId,
    phaseName,
    subPhaseId,
    completed,
    subPhaseContentIds,
    isSubPhaseContentCompleted,
  }: PersistSubPhasePayload) => {
    if (
      !currentClassroom ||
      !currentProgram ||
      !userProgramProgress?.currentPhaseId ||
      !programPost
    ) {
      return;
    }

    const currentPhaseId = calcActivePhaseId(
      phaseId,
      subPhaseId,
      programPost.studentPhaseValues ?? [],
      userProgramProgress,
      completed
    );

    dispatch(
      updateUserProgramProgress({
        userId: studentId || currentUser.uid,
        classroomId: currentClassroom.id,
        slug: currentProgram.slug,
        programId: currentProgram.id,
        phaseId,
        phaseName,
        subPhaseId,
        completed,
        currentPhaseId,
        subPhaseContentIds,
        isSubPhaseContentCompleted,
        ...(userProgramProgress.currentPhaseId !== currentPhaseId && {
          prevPhaseId: userProgramProgress.currentPhaseId,
        }),
      })
    );

    if (userProgramProgress.currentPhaseId !== currentPhaseId) {
      notifyFinishedPhase(phaseName, currentProgram.title);
    }
  };

  useEffect(() => {
    dispatch(
      programPostActions.setPhaseTabs(getFilteredTabs({ phasesContent }))
    );
  }, [phasesContent]);

  useEffect(() => {
    const payload = programPost?.studentPhaseValues?.map((phase) => {
      const data: PhasesContent = {
        [ContentType.PhaseContent]: phase,
        [ContentType.AdditionalResources]: phase.additionalResources ?? [],
        [ContentType.StudentContent]: null,
        [ContentType.TeacherResources]: null,
      };

      return data;
    });

    dispatch(programPostActions.setPhasesContent(payload ?? []));

    if (navigationType === 'PUSH' || isEmpty(phaseSelections)) {
      dispatch(programPostActions.resetSelectedPhase());
    }
  }, [programPost]);

  return (
    <>
      {isHeaderVisible ? (
        <RibbonNavigation
          activePhaseIndex={
            !activePhaseIndex || activePhaseIndex === -1 ? 0 : activePhaseIndex
          }
          programProgress={userProgramProgress}
        />
      ) : null}
      <PhaseContent
        programProgress={userProgramProgress}
        persistSubPhase={persistSubPhase}
      />
    </>
  );
};
