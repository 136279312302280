import { MouseEvent, KeyboardEvent } from 'react';
import { Divider, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatDistance } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { Notification } from '@xq/domain';

import {
  StyledAvatar,
  StyledDeleteIcon,
  StyledListItem,
  StyledNew,
  StyledNewContainer,
  StyledNotification,
  StyledNotificationActionsContainer,
  StyledNotificationContainer,
  StyledNotificationName,
  StyledNotificationText,
  StyledNotificationTime,
  StyleItemText,
} from './NotificationListItem.styles';
import { selectClassroomById, selectUserById, useAppSelector } from '../store';
import { useNotifications } from '../context';
import {
  isTemplateNotification,
  NotificationSupportedVerbs,
} from '@xq/shared/data-access';

interface NotificationListItemProps {
  isStudentFeed: boolean;
  handleNotificationClick: (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => void;
  notification: Notification;
}

export const NotificationListItem = ({
  handleNotificationClick,
  isStudentFeed,
  notification,
}: NotificationListItemProps) => {
  const { t } = useTranslation();

  const user = useAppSelector((state) =>
    selectUserById(state, notification.actorId)
  );
  const classroom = useAppSelector((state) =>
    selectClassroomById(state, notification.classroomId ?? '')
  );

  const { removeNotification } = useNotifications();

  const getTranslateOptions = () => {
    if (typeof notification.object !== 'string') {
      return {};
    }

    try {
      return JSON.parse(notification.object) as object;
    } catch (e) {
      return {};
    }
  };

  const notificationText = ` ${
    notification.message
      ? notification.message
      : t(`notification_${notification.verb}` as any, getTranslateOptions())
  }`;

  const notificationDate = formatDistance(
    zonedTimeToUtc(notification.time, 'UTC'),
    new Date(),
    {
      addSuffix: true,
      includeSeconds: true,
    }
  );

  const compareTarget = (
    e: KeyboardEvent | MouseEvent,
    compare: 'DIV' | 'SPAN' | 'svg' | 'BUTTON' | 'path'
  ) => (e.target as { nodeName?: string })?.nodeName === compare;

  const isTemplate = isTemplateNotification(
    notification.verb as NotificationSupportedVerbs
  );

  return (
    <StyledListItem
      showClassroomIcon={!isStudentFeed && !isTemplate}
      disableGutters
      data-classroom-index={classroom?.shortName}
    >
      {!isTemplate ? (
        <StyledAvatar
          onClick={handleNotificationClick}
          src={user?.photoUrl || ''}
          alt={t('user_profile_photo')}
        />
      ) : null}
      <StyledNotification
        onKeyDown={(e) => {
          if (compareTarget(e, 'DIV') && e.key === 'Enter') {
            handleNotificationClick(e);
          }
        }}
        tabIndex={0}
        onClick={(e) => {
          const isSvg = compareTarget(e, 'svg');
          const isButton = compareTarget(e, 'BUTTON');
          const isPath = compareTarget(e, 'path');

          if (!isSvg && !isButton && !isPath) handleNotificationClick(e);
        }}
      >
        <StyledNotificationContainer>
          <StyleItemText
            primary={
              <StyledNotificationText isRead={!!notification.isRead}>
                {!isTemplate ? (
                  <StyledNotificationName>
                    {user?.displayName || ''}
                  </StyledNotificationName>
                ) : null}
                <span>{notificationText}</span>
              </StyledNotificationText>
            }
          />

          <StyledNotificationTime>{notificationDate}</StyledNotificationTime>
        </StyledNotificationContainer>
        <StyledNotificationActionsContainer>
          <StyledNewContainer isRead={!!notification.isRead}>
            <StyledNew>{t('new')}</StyledNew>
          </StyledNewContainer>
          <IconButton
            tabIndex={0}
            onClick={async () => {
              await removeNotification(notification.id);
            }}
          >
            <StyledDeleteIcon />
          </IconButton>
        </StyledNotificationActionsContainer>
      </StyledNotification>
      <Divider />
    </StyledListItem>
  );
};
