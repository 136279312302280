import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getProgramProgressId, ProgramProgress } from '@xq/domain';

import { SetProgramProgressPayload } from './user-program-progress.interface';
import {
  fetchUserProgramProgress,
  updateUserProgramProgress,
} from './user-program-progress.actions';

export type UserProgramProgressState = Record<string, ProgramProgress>;

const initialUserProgramProgressState: UserProgramProgressState = {};

export const userProgramProgressSlice = createSlice({
  name: 'user-program-progress',
  initialState: initialUserProgramProgressState,
  reducers: {
    setProgramProgress: (
      state,
      {
        payload: { userId, programProgress, programId, classroomId },
      }: PayloadAction<SetProgramProgressPayload>
    ) => {
      const id = getProgramProgressId({ userId, programId, classroomId });
      state[id] = programProgress;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserProgramProgress.pending, (state, { meta }) => {
      const {
        userId,
        classroomId,
        phaseId,
        programId,
        subPhaseId,
        completed,
        isSubPhaseContentCompleted,
        subPhaseContentIds,
        phaseName,
        currentPhaseId,
      } = meta.arg;
      const id = getProgramProgressId({ userId, programId, classroomId });
      const programProgress = state[id];

      if (!programProgress) {
        return;
      }

      programProgress.currentPhaseId = currentPhaseId;

      const phase = programProgress.phases[phaseId];

      if (!phase) {
        programProgress.phases[phaseId] = {
          id: phaseId,
          phaseName,
          lastActionSubPhaseId: subPhaseId,
          subPhases: {},
        };
      }

      const subPhase = programProgress.phases[phaseId].subPhases[subPhaseId];

      if (subPhase) {
        subPhase.isCompleted = completed;
      } else {
        programProgress.phases[phaseId].subPhases[subPhaseId] = {
          id: subPhaseId,
          phaseId,
          isCompleted: completed,
          content: {},
        };
      }

      subPhaseContentIds?.forEach((subPhaseContentId) => {
        const isCompleted = completed || Boolean(isSubPhaseContentCompleted);
        const subPhaseContent =
          programProgress.phases[phaseId].subPhases[subPhaseId].content![
            subPhaseContentId
          ];

        if (subPhaseContent) {
          subPhaseContent.isCompleted = isCompleted;
        } else {
          programProgress.phases[phaseId].subPhases[subPhaseId].content![
            subPhaseContentId
          ] = {
            id: subPhaseContentId,
            isCompleted,
            subPhaseId: subPhaseId,
          };
        }
      });
    });
    builder.addCase(updateUserProgramProgress.rejected, (state, { meta }) => {
      const {
        userId,
        classroomId,
        phaseId,
        programId,
        subPhaseId,
        completed,
        isSubPhaseContentCompleted,
        subPhaseContentIds,
        currentPhaseId,
        prevPhaseId,
      } = meta.arg;
      const id = getProgramProgressId({ userId, programId, classroomId });
      const programProgress = state[id];

      programProgress.currentPhaseId = prevPhaseId || currentPhaseId;
      const subPhase = programProgress.phases[phaseId].subPhases[subPhaseId];

      subPhase.isCompleted = !completed;

      subPhaseContentIds?.forEach((subPhaseContentId) => {
        subPhase.content![subPhaseContentId].isCompleted = !(
          completed || Boolean(isSubPhaseContentCompleted)
        );
      });
    });
    builder.addCase(
      fetchUserProgramProgress.fulfilled,
      (
        state,
        { payload: { userId, programProgress, programId, classroomId } }
      ) => {
        const id = getProgramProgressId({ userId, programId, classroomId });
        state[id] = programProgress;
      }
    );
  },
});

export const userProgramProgressActions = userProgramProgressSlice.actions;
