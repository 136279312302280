import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddToDriveOutlinedIcon from '@mui/icons-material/AddToDriveOutlined';

import styles from './file-upload-buttons.module.scss';
import { FileWithGooglePermissions } from '../../../../FileUpload/FileUpload';
import { GooglePickerWithPermissionChecker } from '../../../../GooglePicker/GooglePicker';
import { selectCurrentUserClassroom, useAppSelector } from '../../../../store';
import { ChangeEvent } from 'react';
import { DocumentObject } from '@xq/infrastructure';
import { useTranslation } from 'react-i18next';
import { TooltipExplanation } from '../../../../TooltipExplanation';

type FileUploadButtonsProps = {
  addFiles: (event: ChangeEvent<HTMLInputElement>) => void;
  addFilesFromPicker: (files: DocumentObject[]) => Promise<void>;
  isAttachButtonDisabled: boolean;
  requestScopes: () => Promise<boolean>;
  loading: boolean;
};

export const FileUploadButtons = ({
  addFilesFromPicker,
  addFiles,
  isAttachButtonDisabled,
  requestScopes,
  loading,
}: FileUploadButtonsProps) => {
  const { t } = useTranslation();

  const classroom = useAppSelector(selectCurrentUserClassroom);

  if (!classroom) {
    return null;
  }

  return (
    <TooltipExplanation
      title={t('attachment_limitations')}
      body={t('post_limitations_tooltip_message')}
    >
      <div className={styles.uploadButtonsContainer}>
        <FileWithGooglePermissions
          className={styles.uploadButton}
          onFileSelect={addFiles}
          text={<AttachFileIcon />}
          hideEndIcon
          color="info"
          variant="outlined"
          disabled={isAttachButtonDisabled}
          requestScopes={requestScopes}
          loading={loading}
        />
        <GooglePickerWithPermissionChecker
          className={styles.uploadButton}
          classroom={classroom}
          buttonText={<AddToDriveOutlinedIcon />}
          onFilesSelect={addFilesFromPicker}
          color="info"
          variant="outlined"
          disabled={isAttachButtonDisabled}
          requestScopes={requestScopes}
          loading={loading}
        />
      </div>
    </TooltipExplanation>
  );
};
