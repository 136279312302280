import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  AdministrationTabs,
  selectNewSchoolState,
  StyledButton,
  StyledFooter,
  StyledLayoutContainer,
  StyledMain,
  StyledNextButton,
  useAppSelector,
  useNavigationWarningModal,
} from '@xq/web-components';
import { TEACHER_APP_ROUTES } from '@xq/web-config';
import { CreateFormHeader } from '../../components/CreateFormHeader';
import { WhitelistDomainsContent } from './WhitelistDomainsContent';

export const WhitelistDomains = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const newSchoolState = useAppSelector(selectNewSchoolState);

  const goToSchoolSummary = async () => {
    navigate(TEACHER_APP_ROUTES.getSchoolSummary());
  };

  const { isDirty } = newSchoolState;

  useNavigationWarningModal({
    when: isDirty,
    safeRoute: [
      TEACHER_APP_ROUTES.getNewSchoolSetup(),
      TEACHER_APP_ROUTES.getSchoolSummary(),
    ],
  });

  return (
    <StyledLayoutContainer>
      <CreateFormHeader
        activeIndex={1}
        completeIndexes={[0]}
        title={t('step_two_whitelist_domains')}
        exitPath={TEACHER_APP_ROUTES.getAdministration(
          AdministrationTabs.schools
        )}
      />
      <StyledMain>
        <WhitelistDomainsContent />
      </StyledMain>
      <StyledFooter>
        <StyledButton
          onClick={() => {
            navigate(TEACHER_APP_ROUTES.getNewSchoolSetup());
          }}
        >
          {t('back')}
        </StyledButton>
        <StyledNextButton onClick={goToSchoolSummary}>
          {t('next')}
        </StyledNextButton>
      </StyledFooter>
    </StyledLayoutContainer>
  );
};
