import { lazy } from 'react';
import { User } from '@firebase/auth';
import { TEACHER_APP_ROUTES } from '@xq/web-config';
import {
  ErrorPage,
  FbAuthUserState,
  GuardedRoute,
  JournalPhase,
  Login,
  PhaseContent,
} from '@xq/web-components';
import loginImgSrc from '../images/teacher-img.webp';
import loginImgFallbackSrc from '../images/teacher-img.png';
import { ReactComponent as logo } from '../images/teacher-logo-no-sub.svg';
import { Navigate, Outlet } from 'react-router-dom';
import App from './App';
import { PhaseContentList } from './components/PhaseContentList/PhaseContentList';
import { Classroom } from './Screens/Classroom/Classroom';
import StudentFilesPage from './Screens/StudentFilesPage/StudentFilesPage';
import PhasesAndJournal from './Screens/PhasesAndJournal/PhasesAndJournal';
import CreateNewClassroom from './Screens/CreateNewClassroom/CreateNewClassroom';
import { AddTeachersToNewClassroom } from './Screens/AddTeachersToNewClassroom/AddTeachersToNewClassroom';
import { AddStudentsToNewClassroom } from './Screens/AddStudentsToNewClassroom/AddStudentsToNewClassroom';
import { ClassroomSummary } from './Screens/ClassroomSummary/ClassroomSummary';
import { CreateNewSchool } from './Screens/CreateNewSchool/CreateNewSchool';
import { SchoolSummary } from './Screens/SchoolSummary/SchoolSummary';
import { ClassroomRepository, SchoolRepository } from '@xq/infrastructure';
import { Errors, UserRoles } from '@xq/domain';
import { WhitelistDomains } from './Screens/WhitelistDomains';
import StudentCalculatorsPage from './Screens/StudentCalculatorsPage/StudentCalculatorsPage';

const TermsOfService = lazy(
  () => import('./Screens/TermsOfService/TermsOfService')
);
const PrivacyPolicy = lazy(
  () => import('./Screens/PrivacyPolicy/PrivacyPolicy')
);
const ContactHelpDesk = lazy(
  () => import('./Screens/ContactHelpDesk/ContactHelpDesk')
);
const Resources = lazy(() => import('./Screens/Resources/Resources'));
const ClassroomDetails = lazy(
  () => import('./Screens/ClassroomDetails/ClassroomDetails')
);
const DashboardPage = lazy(
  () => import('./Screens/DashboardPage/DashboardPage')
);
const JournalActivityPage = lazy(
  () => import('./Screens/JournalActivityPage/JournalActivityPage')
);
const PostContent = lazy(() => import('./Screens/PostContent/PostContent'));

const SchoolDetails = lazy(
  () => import('./Screens/SchoolDetails/SchoolDetails')
);
const Administration = lazy(
  () => import('./Screens/Administration/Administration')
);

const PRIVACY_POLICY_URL = process.env.NX_PUBLIC_PRIVACY_POLICY_URL || '';
const TERMS_OF_SERVICE_URL = process.env.NX_PUBLIC_TERMS_OF_SERVICE_URL || '';
const APP_USER_ROLES = [UserRoles.teacher, UserRoles.viewer, UserRoles.admin];

const handleErrors = async (authUser: User) => {
  const { claims } = await authUser.getIdTokenResult();
  const classroomRepo = new ClassroomRepository();
  const schoolRepo = new SchoolRepository();
  const roles = (claims?.roles as UserRoles[]) || [];
  const userSchools = await schoolRepo.getSchoolsByDomain(
    authUser.email as string
  );

  const correctRole = roles.some((r: UserRoles) => APP_USER_ROLES.includes(r));

  const hasValidSchool = userSchools.find((sc) => sc.driveRootFolderId);

  const viewerUserHasNoRooms =
    roles.includes(UserRoles.viewer) &&
    !(await classroomRepo.checkUserRoleByFieldPath(
      authUser.email,
      'viewerEmails'
    ));

  if (roles.length && !correctRole) {
    throw new Error(Errors.userWrongRole);
  }

  if (viewerUserHasNoRooms) {
    throw new Error(Errors.accountHasNoRooms);
  }

  if (
    userSchools.length &&
    !hasValidSchool &&
    !roles.includes(UserRoles.admin) &&
    !roles.includes(UserRoles.viewer)
  ) {
    throw new Error(Errors.classroomHasNoFolder);
  }
};

export const getUnAuthorisedRoutes = (
  isImpersonating: boolean,
  userAuthState: FbAuthUserState,
  setUserAuthState: (userAuthState: FbAuthUserState) => void,
  authUser: User | null
) => {
  return [
    {
      path: TEACHER_APP_ROUTES.getRoot(),
      element: <Outlet />,
      errorElement: <ErrorPage isTeacherApp />,
      loader: async () => {
        if (authUser && userAuthState !== 'loggedOut') {
          await handleErrors(authUser);
        }
        return null;
      },
      children: [
        {
          path: TEACHER_APP_ROUTES.getRoot(),
          element: (
            <Login
              userAuthState={userAuthState}
              setUserAuthState={setUserAuthState}
              isTeacherApp
              logo={logo}
              imageSrc={loginImgSrc}
              imageFallbackSrc={loginImgFallbackSrc}
              colorTheme="loginWrapperTeacher"
              impersonate={isImpersonating}
            />
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getLogin(),
          element: (
            <Login
              userAuthState={userAuthState}
              setUserAuthState={setUserAuthState}
              isTeacherApp
              logo={logo}
              imageSrc={loginImgSrc}
              imageFallbackSrc={loginImgFallbackSrc}
              colorTheme="loginWrapperTeacher"
            />
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getDemoLogin(),
          element: (
            <Login
              userAuthState={userAuthState}
              setUserAuthState={setUserAuthState}
              isTeacherApp
              logo={logo}
              imageSrc={loginImgSrc}
              imageFallbackSrc={loginImgFallbackSrc}
              colorTheme="loginWrapperTeacher"
              impersonate
            />
          ),
          errorElement: <ErrorPage isTeacherApp />,
        },
        {
          path: TEACHER_APP_ROUTES.getContactHelpDesk(),
          element: <ContactHelpDesk userAuthState={userAuthState} />,
        },
        {
          path: TEACHER_APP_ROUTES.getTermsOfService(),
          element: <TermsOfService url={TERMS_OF_SERVICE_URL} />,
        },
        {
          path: TEACHER_APP_ROUTES.getPrivacyPolicy(),
          element: <PrivacyPolicy url={PRIVACY_POLICY_URL} />,
        },
        {
          path: TEACHER_APP_ROUTES.getErrorScreen(),
          element: <ErrorPage isTeacherApp />,
        },
        {
          path: '*',
          element: (
            <Navigate
              to={
                isImpersonating
                  ? TEACHER_APP_ROUTES.getDemoLogin()
                  : TEACHER_APP_ROUTES.getLogin()
              }
              replace
            />
          ),
        },
      ],
    },
  ];
};

export const getAuthorisedRoutes = (
  isNotViewer: boolean,
  userAuthState: FbAuthUserState,
  authUser: User | null
) => {
  return [
    {
      path: TEACHER_APP_ROUTES.getRoot(),
      element: <App />,
      errorElement: <ErrorPage isTeacherApp={true} />,
      loader: async () => {
        if (authUser) {
          await handleErrors(authUser);
        }
        return null;
      },
      children: [
        {
          path: TEACHER_APP_ROUTES.getResources(),
          element: <Resources />,
          children: [
            {
              path: TEACHER_APP_ROUTES.getResourceListView(),
              element: <PhaseContentList />,
            },
            {
              path: TEACHER_APP_ROUTES.getResourceTabsView(),
              element: <PhaseContent />,
            },
          ],
        },
        {
          path: TEACHER_APP_ROUTES.getResourceContent(),
          element: <PostContent />,
        },
        {
          path: TEACHER_APP_ROUTES.getClassroom(),
          element: <Classroom />,
          children: [
            {
              path: TEACHER_APP_ROUTES.getDashboard(),
              element: <DashboardPage />,
            },
            {
              path: TEACHER_APP_ROUTES.getJournalActivity(),
              element: <JournalActivityPage />,
            },
            {
              path: TEACHER_APP_ROUTES.getStudentFiles(),
              element: <StudentFilesPage />,
            },
            {
              path: TEACHER_APP_ROUTES.getStudentCalculators(),
              element: <StudentCalculatorsPage />,
            },
          ],
        },
        {
          path: TEACHER_APP_ROUTES.getJournal(':studentId'),
          element: <PhasesAndJournal />,
          children: [
            {
              path: TEACHER_APP_ROUTES.getJournalPhase(),
              element: <JournalPhase />,
            },
          ],
        },
        {
          path: TEACHER_APP_ROUTES.getNewClassroomSetup(),
          element: (
            <GuardedRoute isAllowed={isNotViewer}>
              <CreateNewClassroom />
            </GuardedRoute>
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getAddingTeachersToNewClassroom(),
          element: (
            <GuardedRoute isAllowed={isNotViewer}>
              <AddTeachersToNewClassroom />
            </GuardedRoute>
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getAddingStudentsToNewClassroom(),
          element: (
            <GuardedRoute isAllowed={isNotViewer}>
              <AddStudentsToNewClassroom />
            </GuardedRoute>
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getClassroomSummary(),
          element: (
            <GuardedRoute isAllowed={isNotViewer}>
              <ClassroomSummary />
            </GuardedRoute>
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getAdministration(':adminTab'),
          element: (
            <GuardedRoute isAllowed={isNotViewer}>
              <Administration />
            </GuardedRoute>
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getClassroomDetails(':classroomId'),
          element: (
            <GuardedRoute isAllowed={isNotViewer}>
              <ClassroomDetails />
            </GuardedRoute>
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getNewSchoolSetup(),
          element: (
            <GuardedRoute isAllowed={isNotViewer}>
              <CreateNewSchool />
            </GuardedRoute>
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getSchoolSummary(),
          element: (
            <GuardedRoute isAllowed={isNotViewer}>
              <SchoolSummary />
            </GuardedRoute>
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getSchoolDetails(),
          element: (
            <GuardedRoute isAllowed={isNotViewer}>
              <SchoolDetails />
            </GuardedRoute>
          ),
        },
        {
          path: TEACHER_APP_ROUTES.getContactHelpDesk(),
          element: <ContactHelpDesk userAuthState={userAuthState} />,
        },
        {
          path: TEACHER_APP_ROUTES.getWhitelistDomains(),
          element: <WhitelistDomains />,
        },
      ],
    },
    {
      path: TEACHER_APP_ROUTES.getTermsOfService(),
      element: <TermsOfService url={TERMS_OF_SERVICE_URL} />,
    },
    {
      path: TEACHER_APP_ROUTES.getPrivacyPolicy(),
      element: <PrivacyPolicy url={PRIVACY_POLICY_URL} />,
    },
    {
      path: '*',
      element: <Navigate to={TEACHER_APP_ROUTES.getRoot()} replace />,
    },
  ];
};
