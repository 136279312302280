import { z } from 'zod';

export const CalculatorSchema = z.object({
  id: z.string(),
  wpPostId: z.number(),
  phaseId: z.number(),
  programId: z.string(),
  classroomId: z.string(),
  userId: z.string(),
  title: z.string(),
  updatedAt: z.string(),
  calculatorState: z.string(),
  forReview: z.boolean(),
});

export type Calculator = z.infer<typeof CalculatorSchema>;

export const FbCalculatorSchema = z.object({
  title: z.string().optional(),
  phaseId: z.number(),
  calculatorState: z.string(),
  forReview: z.boolean().optional(),
  updatedAt: z.any(),
  wpPostId: z.number(),
  userId: z.string(),
});

export type FbCalculator = z.infer<typeof FbCalculatorSchema>;
