import {
  arrayRemove,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  documentId,
  arrayUnion,
  Timestamp,
  DocumentSnapshot,
  DocumentReference,
  updateDoc,
  limit,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { chunk } from 'lodash';

import {
  Calculator,
  Classroom,
  IFolderByProgramId,
  IStudentFolderIdByEmailByProgramId,
} from '@xq/domain';
import {
  ClassroomMapper,
  FbClassroomDto,
  CollectionNames,
  FbUserDto,
  Optional,
  FbCalculatorDto,
} from '@xq/shared/data-access';

import { firebaseService } from '../../services/FirebaseService';
import {
  ActivePhaseProps,
  ActivitySummaryCountProps,
  DeleteClassFileProps,
  FollowedUpUsersProps,
  IClassroomRepository,
  IProgramProgressFields,
  PhaseJournalStudentResponseProps,
} from './ClassroomRepository.interfaces';
import { loggerService } from '../../services/LoggerService';
import { getUserCalculatorByClassroomIdByProgramIdBySubPhaseIdProps } from '../user';
import { convertTimestamp } from '@xq/shared/utils';

type ClassroomRoleTypeFieldPaths =
  | 'viewerEmails'
  | 'participantEmails'
  | 'teacherEmails';

export class ClassroomRepository implements IClassroomRepository {
  async getStudentFolderIdByEmailByProgramId(classroomId: string) {
    const classroomRef = doc(
      firebaseService.db,
      `${CollectionNames.classrooms}/${classroomId}`
    );
    const classroomSnapshot = await getDoc(classroomRef);

    if (!classroomSnapshot.exists()) {
      throw new Error(
        `Classroom not found with the provided classroomId: ${classroomId}`
      );
    }

    const studentFoldersCollectionRef = collection(
      firebaseService.db,
      `${CollectionNames.classrooms}/${classroomId}/studentFolderIdByEmailByProgramId`
    );

    const studentsFoldersCollection = await getDocs(
      studentFoldersCollectionRef
    );

    const result: IStudentFolderIdByEmailByProgramId = {};

    studentsFoldersCollection.docs.forEach((snap) => {
      result[snap.id] = {
        folderByProgramId: {
          ...(snap.data() as IFolderByProgramId),
        },
      };
    });

    return result;
  }

  async assignProgram(classroomIds: string[], programId: string) {
    return Promise.all(
      chunk(classroomIds, 10).map(async (ids: string[]) => {
        const queryResult = query(
          collection(firebaseService.db, CollectionNames.classrooms),
          where(documentId(), 'in', ids)
        );

        await Promise.all(
          (
            await getDocs(queryResult)
          ).docs.map((classroomSnapshot) =>
            updateDoc(classroomSnapshot.ref, {
              programIds: arrayUnion(...[programId]),
            })
          )
        );
      })
    );
  }

  async createClassroom(
    classroomSnapshot: QueryDocumentSnapshot
  ): Promise<Classroom> {
    if (!classroomSnapshot.exists()) {
      throw new Error(`Classroom not found`);
    }

    const studentFoldersCollectionRef = collection(
      firebaseService.db,
      `${CollectionNames.classrooms}/${classroomSnapshot.id}/studentFolderIdByEmailByProgramId`
    );

    const studentsFoldersCollection = await getDocs(
      studentFoldersCollectionRef
    );

    const studentFolderIdByEmailByProgramId: IStudentFolderIdByEmailByProgramId =
      {};
    studentsFoldersCollection.docs.forEach((snap) => {
      studentFolderIdByEmailByProgramId[snap.id] = {
        folderByProgramId: {
          ...(snap.data() as IFolderByProgramId),
        },
      };
    });

    return ClassroomMapper.getInstance().toDomain(
      {
        ...(classroomSnapshot.data() as FbClassroomDto),
        id: classroomSnapshot.id,
      },
      studentFolderIdByEmailByProgramId
    );
  }

  async getClassroomsBySchoolId(schoolId: string): Promise<Classroom[]> {
    const queryResult = query(
      collection(firebaseService.db, CollectionNames.classrooms),
      where('schoolId', '==', schoolId)
    );

    return (await getDocs(queryResult)).docs.map((document) =>
      ClassroomMapper.getInstance().toDomain({
        ...document.data(),
        id: document.id,
      } as FbClassroomDto)
    );
  }

  async initProgressForUserPhase({
    userId,
    currentPhaseId,
    classroomId,
    slug,
  }: Optional<ActivePhaseProps, 'currentPhaseId'>): Promise<DocumentSnapshot> {
    const requiredProgressFields = {
      slug,
      phases: {},
      postsCount: 0,
      commentsCount: 0,
    };

    const program = {
      ...requiredProgressFields,
      ...(currentPhaseId && { currentPhaseId }),
    };

    await setDoc(
      doc(
        firebaseService.db,
        `${CollectionNames.users}/${userId}/${
          CollectionNames.programProgress
        }/${this.getProgramProgressId(slug, classroomId)}`
      ),
      program
    );

    return this.getUserProgramProgressByUserId({
      slug,
      classroomId,
      userId,
      currentPhaseId,
    });
  }

  async persistFollowedUpUsersByClassroomId({
    userId,
    classroomId,
    studentId,
    programId,
  }: FollowedUpUsersProps): Promise<Array<string>> {
    const docRef = await this.getFollowedUpUsersByUserId(classroomId, userId);

    let docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) {
      await this.initFollowUpUsers({
        userId,
        classroomId,
        programId,
      });

      docSnapshot = await getDoc(docRef);
    }

    const followedUpUsersInProgram = docSnapshot.data() as {
      byProgramId: { [programId: string]: string[] };
    };

    const programFollowUpUsersExists = Boolean(
      followedUpUsersInProgram.byProgramId[programId]
    );

    const isStudentFollowed =
      programFollowUpUsersExists &&
      followedUpUsersInProgram?.byProgramId[programId].includes(studentId);

    try {
      await updateDoc(
        docSnapshot.ref,
        programFollowUpUsersExists
          ? {
              [`byProgramId.${programId}`]: isStudentFollowed
                ? arrayRemove(...[studentId])
                : arrayUnion(...[studentId]),
            }
          : {
              byProgramId: {
                ...followedUpUsersInProgram.byProgramId,
                [programId]: [studentId],
              },
            }
      );

      return docSnapshot.data()?.byProgramId[programId];
    } catch (e) {
      loggerService.error(e);
    }
    return [];
  }

  private async getUserSnapshot(fieldPath: string, value: string) {
    const queryResult = query(
      collection(firebaseService.db, CollectionNames.users),
      where(fieldPath, '==', value),
      limit(1)
    );

    return (await getDocs(queryResult)).docs?.[0];
  }

  async getFollowedUpUsersByUserId(
    classroomId: string,
    uid: string
  ): Promise<DocumentReference> {
    return doc(
      firebaseService.db,
      `${CollectionNames.users}/${uid}/${CollectionNames.followedUpUsersByClassroomId}/${classroomId}`
    );
  }

  initFollowUpUsers({
    userId,
    classroomId,
    programId,
  }: Omit<FollowedUpUsersProps, 'studentId'>): Promise<void> {
    return setDoc(
      doc(
        firebaseService.db,
        `${CollectionNames.users}/${userId}/${CollectionNames.followedUpUsersByClassroomId}/${classroomId}`
      ),
      {
        byProgramId: {
          [programId]: [],
        },
      }
    );
  }

  async getUserProgramProgressByUserId({
    userId,
    slug,
    currentPhaseId,
    classroomId,
  }: Optional<ActivePhaseProps, 'currentPhaseId'>): Promise<DocumentSnapshot> {
    const programProgressSnapshot = await getDoc(
      doc(
        firebaseService.db,
        `${CollectionNames.users}/${userId}/${
          CollectionNames.programProgress
        }/${this.getProgramProgressId(slug, classroomId)}`
      )
    );

    if (programProgressSnapshot.exists()) {
      return programProgressSnapshot;
    }

    return this.initProgressForUserPhase({
      userId,
      classroomId,
      slug,
      currentPhaseId,
    });
  }

  async persistUserProgramProgress({
    slug,
    userId,
    completed,
    currentPhaseId,
    phaseId,
    phaseName,
    subPhaseId,
    classroomId,
    subPhaseContentIds,
    isSubPhaseContentCompleted,
  }: IProgramProgressFields): Promise<void> {
    const userProgramProgress = await this.getUserProgramProgressByUserId({
      slug,
      classroomId,
      userId,
    });

    if (userProgramProgress.exists()) {
      let mergedSubPhaseContent = {};

      subPhaseContentIds?.forEach((subPhaseContentId) => {
        mergedSubPhaseContent = {
          ...mergedSubPhaseContent,
          [`phases.${phaseId}.subPhases.${subPhaseId}.content.${subPhaseContentId}.id`]:
            subPhaseContentId,
          [`phases.${phaseId}.subPhases.${subPhaseId}.content.${subPhaseContentId}.isCompleted`]:
            completed || Boolean(isSubPhaseContentCompleted),
          [`phases.${phaseId}.subPhases.${subPhaseId}.content.${subPhaseContentId}.subPhaseId`]:
            subPhaseId,
        };
      });

      try {
        await updateDoc(userProgramProgress.ref, {
          currentPhaseId,
          [`phases.${phaseId}.id`]: phaseId,
          [`phases.${phaseId}.phaseName`]: phaseName,
          [`phases.${phaseId}.lastActionSubPhaseId`]: subPhaseId,
          [`phases.${phaseId}.subPhases.${subPhaseId}.isCompleted`]: completed,
          [`phases.${phaseId}.subPhases.${subPhaseId}.id`]: subPhaseId,
          [`phases.${phaseId}.subPhases.${subPhaseId}.phaseId`]: phaseId,
          ...mergedSubPhaseContent,
        });
      } catch (e) {
        loggerService.error("Couldn't persist user program progress: ", e);
      }
    }
  }

  async persistActivitySummaryCount({
    userId,
    classroomId,
    slug,
    postsOperator,
    commentsOperator,
    deletedCount = 1,
  }: ActivitySummaryCountProps): Promise<void> {
    const userProgramProgress = await this.getUserProgramProgressByUserId({
      slug,
      classroomId,
      userId,
    });

    const { postsCount = 0, commentsCount = 0 } =
      userProgramProgress?.data() as {
        postsCount: number;
        commentsCount: number;
      };

    const updateCount = (operator: string | undefined, count: number) => {
      let updatedCount = count;
      if (operator === '+') {
        updatedCount += 1;
      } else if (operator === '-' && count >= deletedCount) {
        updatedCount -= deletedCount;
      }
      return updatedCount;
    };

    if (userProgramProgress.exists()) {
      await updateDoc(userProgramProgress.ref, {
        postsCount: updateCount(postsOperator, postsCount),
        commentsCount: updateCount(commentsOperator, commentsCount),
      });
    }
  }

  private dateToTimestamp(date: Date): Timestamp {
    return Timestamp.fromDate(date);
  }

  async persistLastPost(userId: string, lastPost: Date): Promise<void> {
    const userSnapshot = await this.getUserSnapshot('uid', userId);

    if (userSnapshot.exists()) {
      await updateDoc(userSnapshot.ref, {
        lastPost: this.dateToTimestamp(lastPost),
      });
    }
  }

  async persistIsArchived(
    classroomId: string,
    isArchived: boolean
  ): Promise<void> {
    const classroomSnapshot = await getDoc(
      doc(firebaseService.db, `${CollectionNames.classrooms}/${classroomId}`)
    );

    if (classroomSnapshot.exists()) {
      await updateDoc(classroomSnapshot.ref, {
        isArchived,
        archivedOn: this.dateToTimestamp(new Date()),
      });
    }
  }

  async persistFeedCommentsCount(
    userId: string,
    classroomId: string,
    operator: string,
    deletedCommentsCount = 1
  ): Promise<void> {
    const userSnapshot = (
      await getDocs(
        query(
          collection(firebaseService.db, CollectionNames.users),
          where('uid', '==', userId),
          limit(1)
        )
      )
    ).docs[0];

    let count =
      (userSnapshot.data() as FbUserDto).classFeedCommentsCountByClassroomId?.[
        classroomId
      ] || 0;

    if (operator === '+') {
      count += 1;
      if (userSnapshot.exists()) {
        await updateDoc(userSnapshot.ref, {
          [`classFeedCommentsCountByClassroomId.${classroomId}`]: count,
        });
      }
    } else if (operator === '-' && count >= deletedCommentsCount) {
      count -= deletedCommentsCount;
    }

    if (userSnapshot.exists()) {
      await updateDoc(userSnapshot.ref, {
        [`classFeedCommentsCountByClassroomId.${classroomId}`]: count,
      });
    }
  }

  private getProgramProgressId(programSlug: string, classroomId: string) {
    return `${programSlug}-${classroomId}`;
  }

  async checkUserRoleByFieldPath(
    email: string | null,
    fieldPath: ClassroomRoleTypeFieldPaths
  ) {
    const queryResult = query(
      collection(firebaseService.db, CollectionNames.classrooms),
      where(fieldPath, 'array-contains', email),
      limit(1)
    );

    return Boolean((await getDocs(queryResult)).docs?.length);
  }

  async checkForValidStudentClassrooms(email: string) {
    const queryResult = query(
      collection(firebaseService.db, CollectionNames.classrooms),
      where('participantEmails', 'array-contains', email)
    );

    const roomDocs = (await getDocs(queryResult)).docs;

    return Boolean(
      roomDocs.filter((doc) => {
        const data = doc.data();
        return (
          data.programIds.length && data.classroomFolderId && !data.isArchived
        );
      }).length
    );
  }

  addPhaseJournalStudentResponse({
    classroomId,
    activityId,
    userId,
    feedId,
  }: PhaseJournalStudentResponseProps) {
    const ref = doc(
      firebaseService.db,
      `${CollectionNames.classrooms}/${classroomId}/${CollectionNames.phaseJournal}/${feedId}`
    );

    return setDoc(
      ref,
      {
        studentResponses: {
          [userId]: arrayUnion(activityId),
        },
      },
      {
        merge: true,
      }
    );
  }

  removePhaseJournalStudentResponse({
    classroomId,
    activityId,
    userId,
    feedId,
  }: PhaseJournalStudentResponseProps) {
    const ref = doc(
      firebaseService.db,
      `${CollectionNames.classrooms}/${classroomId}/${CollectionNames.phaseJournal}/${feedId}`
    );

    return setDoc(
      ref,
      {
        studentResponses: {
          [userId]: arrayRemove(activityId),
        },
      },
      {
        merge: true,
      }
    );
  }

  getPhaseJournalById(classroomId: string, id: string) {
    const ref = doc(
      firebaseService.db,
      `${CollectionNames.classrooms}/${classroomId}/${CollectionNames.phaseJournal}/${id}`
    );

    return getDoc(ref);
  }

  async setUserCalculatorByClassroomIdByProgramIdByWpPostId({
    userId,
    classroomId,
    programId,
    wpPostId,
    calculatorState,
    phaseId,
    title,
    forReview,
    updatedAt,
  }: FbCalculatorDto) {
    try {
      const path = `${CollectionNames.classrooms}/${classroomId}/${CollectionNames.userCalculatorsByProgramId}/${programId}`;
      const document = await getDoc(doc(firebaseService.db, path));
      if (!document.exists()) {
        await setDoc(
          doc(firebaseService.db, path),
          {
            [`${userId}-${phaseId}-${wpPostId}`]: {
              calculatorState,
              wpPostId,
              phaseId,
              userId,
              updatedAt: this.dateToTimestamp(updatedAt),
              title,
              forReview,
            },
          },
          { merge: true }
        );
        return;
      } else {
        const data = document.data();
        if (data[`${userId}-${phaseId}-${wpPostId}`]) {
          await updateDoc(
            document.ref,
            {
              [`${userId}-${phaseId}-${wpPostId}`]: {
                calculatorState,
                wpPostId,
                phaseId,
                userId,
                updatedAt: this.dateToTimestamp(updatedAt),
                title,
                forReview,
              },
            },
          );
          return;
        }
      }

      await setDoc(
        doc(
          firebaseService.db,
          path
        ),
        {
          [`${userId}-${phaseId}-${wpPostId}`]: {
            calculatorState,
            wpPostId,
            phaseId,
            userId,
            updatedAt: this.dateToTimestamp(updatedAt),
            title,
            forReview,
          },
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  async getUserCalculatorByClassroomIdByProgramIdBySubPhaseId({
    userId,
    classroomId,
    programId,
    wpPostId,
    phaseId,
  }: getUserCalculatorByClassroomIdByProgramIdBySubPhaseIdProps): Promise<Calculator | undefined> {
    try {
      const path = `/${CollectionNames.classrooms}/${classroomId}/${CollectionNames.userCalculatorsByProgramId}/${programId}`;
      const snapshot = await getDoc(doc(firebaseService.db, path));
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (data[`${userId}-${phaseId}-${wpPostId}`]) {
          return {
            id: `${userId}-${classroomId}-${programId}-${data[`${userId}-${wpPostId}`].phaseId}-${wpPostId}`,
            wpPostId,
            phaseId: data[`${userId}-${wpPostId}`].phaseId,
            programId,
            classroomId,
            userId,
            calculatorState: data[`${userId}-${wpPostId}`].calculatorState,
            title: data[`${userId}-${wpPostId}`].title,
            forReview: data[`${userId}-${wpPostId}`].forReview,
            updatedAt: data[`${userId}-${wpPostId}`].updatedAt.toDate(),
          };
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getUserCalculatorsByClassroomIdByProgramId({
    classroomId,
    programId,
    // userId, todo need one more method for all per user
  }: {
    // userId: string;
    classroomId: string;
    programId: string;
  }): Promise<Calculator[]> {
    try {
      const path = `/${CollectionNames.classrooms}/${classroomId}/${CollectionNames.userCalculatorsByProgramId}/${programId}`;
      const snapshot = await getDoc(doc(firebaseService.db, path));
      if (snapshot.exists()) {
        const data = snapshot.data();
        return Object.keys(data).map((id) => {
          const fbCalc = data[id];
          // todo NEED A MAPPER!!!
          return {
            id: `${fbCalc.userId}-${classroomId}-${programId}-${fbCalc.phaseId}-${fbCalc.wpPostId}`,
            wpPostId: Number(fbCalc.wpPostId),
            phaseId: fbCalc.phaseId,
            programId,
            classroomId,
            userId: fbCalc.userId,
            calculatorState: fbCalc.calculatorState,
            title: fbCalc.title,
            forReview: fbCalc.forReview,
            updatedAt: convertTimestamp(fbCalc.updatedAt).toString(),
          }
        });
      }
      return [];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
}
