import { School, SchoolStatusEnum } from '@xq/domain';

export const schools: School[] = [
  {
    adminEmail: 'test.admin@xqlabs.io',
    classroomsIds: ['NkdYskjpi4dD6ShvREC0', '7bkv7WFVgz1Sl6N6LXVk'],
    domains: ['xqlabs.io', 'xqlabsdev.io'],
    driveRootFolderId: '',
    name: 'School 1',
    state: 'California',
    id: '',
    city: 'City 1',
    website: 'https://test.test',
    status: SchoolStatusEnum.Enum.approved,
  },
];
