import { NewActivity, StreamUser } from 'getstream';
import { isString } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  Reaction,
  Activity,
  Actor,
  ReactionKinds,
  User,
  Comment,
  AttachedFile,
  Document,
} from '@xq/domain';
import {
  StreamActivity,
  StreamCommentReaction,
  StreamEmojiReaction,
} from '@xq/shared/data-access';

export class FeedMapper {
  static toDomainActivity(
    activity: StreamActivity,
    currentUser?: User
  ): Activity {
    // Stream uses string refs for the actor object so we need to replace it with the user object if it is supplied
    const actor =
      isString(activity.actor) && currentUser && currentUser.createdAt
        ? {
            id: currentUser.uid,
            created_at: currentUser.createdAt,
            updated_at: currentUser.modifiedAt,
            data: { name: currentUser?.displayName },
          }
        : (activity.actor as unknown as Actor);

    return {
      id: activity.id,
      actor,
      feedSlug: activity.feedSlug,
      programId: activity.programId,
      classroomId: activity.classroomId,
      object: activity.object,
      attachedFiles:
        activity.attachedFiles ?? this.parseAttachedFiles(activity.object),
      message: activity?.message as string,
      isForReview: !!activity?.isForReview,
      verb: activity.verb,
      hidden: !!activity.hidden,
      foreign_id: activity.foreign_id,
      reaction_counts: {
        comment: activity.reaction_counts?.comment ?? 0,
        emoji: activity.reaction_counts?.emoji ?? 0,
      },
      time: activity.time,
      autoTags: activity.mathTags || [],
      journalLink: activity.journalLink,
    };
  }

  static toDomainActivities(activities: StreamActivity[]) {
    return activities.map((activity) => FeedMapper.toDomainActivity(activity));
  }

  static toDomainReactions(data: StreamEmojiReaction[]): Reaction[] {
    return data.map((e) => ({
      body: e.data.emoji,
      creatorId: e.user_id,
      createdAt: e.created_at,
      kind: ReactionKinds.emoji,
      id: e.id,
    }));
  }

  static toDomainComments(data: StreamCommentReaction[]): Comment[] {
    return data.map((c) => ({
      body: c.data.text,
      hidden: c.data.hidden,
      creatorId: c.user_id,
      createdAt: c.created_at,
      updatedAt: c.updated_at,
      kind: ReactionKinds.comment,
      id: c.id,
      activityId: c.activity_id,
    }));
  }

  static toStreamActivity(activity: Activity): NewActivity {
    const time = activity.time ?? new Date().toISOString();
    const foreign_id = activity.foreign_id ?? uuidv4();

    return {
      feedSlug: activity.feedSlug,
      programId: activity.programId,
      classroomId: activity.classroomId,
      verb: activity.verb,
      actor: activity.actor as unknown as StreamUser,
      object: activity.object,
      message: activity.message,
      isForReview: activity.isForReview,
      hidden: activity.hidden,
      journalLink: activity.journalLink,
      foreign_id,
      attachedFiles: activity.attachedFiles,
      time,
    };
  }

  static toAttachedFiles(documents: Document[]): AttachedFile[] {
    return documents.map((file) => ({
      id: file.id,
      name: file.name,
      size: file.size,
      iconLink: file.iconLink,
      mimeType: file.mimeType,
      webViewLink: file.webViewLink,
    }));
  }

  private static parseAttachedFiles(data: unknown) {
    if (typeof data === 'string') {
      try {
        return JSON.parse(data) as AttachedFile[];
      } catch (e) {
        return [];
      }
    }

    return [];
  }
}
