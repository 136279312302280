import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchWizardContentBodyUseCase } from '@xq/usecases';
import { loggerService, WizardContentRepository } from '@xq/infrastructure';

export const fetchWizardContent = createAsyncThunk(
  'wizard-content/fetchWizardContent',
  async () => {
    try {
      const wizardContentRepository = new WizardContentRepository();
      const wizardContent = await wizardContentRepository.getWizardContent();

      const body = await FetchWizardContentBodyUseCase.execute();

      return wizardContent.map((c) => {
        const content = body.find(({ acf }) => acf['wizard-index'] === c.slug);

        return {
          ...c,
          body: content?.content?.rendered ?? '',
        };
      });
    } catch (e) {
      loggerService.error(e);

      throw e;
    }
  }
);
