import { Button, styled } from '@mui/material';

export const StyledPhaseFooter = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
  border-top: 1px solid #e6e6e6;
  height: 41px;
`;

const renderButtonStyles = (visible: 'true' | 'false') => `
  font-weight: normal;
  color: #3eb650;
  text-transform: none;
  line-height: 1;
  ${visible === 'false' && 'display: none;'}
`;

export const StyledPhaseFooterButton = styled(Button)<{
  visible: 'true' | 'false';
}>`
  ${({ visible }) => renderButtonStyles(visible)}
`;

export const StyledButtonContent = styled('span')<{
  visible: 'true' | 'false';
}>`
  ${({ visible }) => renderButtonStyles(visible)}
`;
