import { ReactNode, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GooglePickerService } from '@xq/infrastructure';
import { Classroom } from '@xq/domain';

import { MOBILE_MEDIA_QUERY } from '../utils';
import { StyledPrimaryLoadingButton } from '../Layout';
import {
  selectCurrentUserClassroomPrograms,
  selectCurrentUserState,
  useAppSelector,
} from '../store';

interface GooglePickerWithPermissionCheckerProps {
  buttonText: ReactNode | string;
  onFilesSelect(files: gapi.client.drive.File[]): Promise<void>;
  classroom: Classroom;
  className?: string;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
  requestScopes: () => Promise<boolean>;
  loading: boolean;
}

export const GooglePickerWithPermissionChecker = ({
  buttonText,
  onFilesSelect,
  classroom,
  className,
  color = 'primary',
  variant = 'contained',
  disabled = false,
  requestScopes,
  loading,
}: GooglePickerWithPermissionCheckerProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const currentUser = useAppSelector(selectCurrentUserState);
  const programs = useAppSelector(selectCurrentUserClassroomPrograms);

  const driveFolders = useMemo(() => {
    const { folderByProgramId = {} } =
      classroom.studentFolderIdByEmailByProgramId?.[currentUser.email] ?? {};

    return Object.entries(folderByProgramId).reduce(
      (previousValue, [programId, folderId]) => {
        const program = programs.find(({ id }) => id === programId);

        if (program) {
          return [
            ...previousValue,
            {
              name: program.title,
              folderId,
            },
          ];
        }

        return previousValue;
      },
      classroom.feedFolderId
        ? [
            {
              name: t('class_feed'),
              folderId: classroom.feedFolderId,
            },
          ]
        : []
    );
  }, [currentUser.email, classroom, programs]);

  const onClickHandler = async () => {
    const hasScopes = await requestScopes();

    if (hasScopes) {
      const tokenObject = gapi.client.getToken();

      if (tokenObject) {
        const picker = new GooglePickerService(
          tokenObject.access_token,
          onFilesSelect,
          isMobile
        );

        picker.createPicker(driveFolders);
        picker.showPicker();
      }
    }
  };

  return (
    <StyledPrimaryLoadingButton
      className={className}
      onClick={onClickHandler}
      variant={variant}
      color={color}
      name="googlePicker"
      loading={loading}
      disabled={disabled}
    >
      {buttonText}
    </StyledPrimaryLoadingButton>
  );
};
