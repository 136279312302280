export enum GoogleClassroomRole {
  Teachers = 'teachers',
  Students = 'students',
}

export interface GetCurrentUserGoogleClassroomsRequestDto {
  token: string;
  includes?: GoogleClassroomRole[];
}

export class GoogleClassroomParticipant {
  constructor(
    public id?: string | null,
    public name?: string | null,
    public emailAddress?: string | null
  ) {}
}

export class GetCurrentUserGoogleClassroom {
  public constructor(
    public id: string,
    public name: string,
    public teachers: GoogleClassroomParticipant[] = [],
    public students: GoogleClassroomParticipant[] = []
  ) {}
}

export type GetCurrentUserGoogleClassroomsResponseDto =
  GetCurrentUserGoogleClassroom[];
