import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogActions } from '@mui/material';

export const StyledCloseIcon = styled(CloseIcon)`
  align-self: self-end;
  cursor: pointer;
  font-size: 34px;
`;

export const StyledDialogHeader = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledDialogBody = styled('div')`
  display: flex;
  width: auto;
  height: 90vh;
  padding: 10px;
`;

export const StyledDialogContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDialogContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  padding: 0 15px;
  max-height: calc(100vh - 280px);
  overflow-y: auto;
`;

export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 0 0;
`;

export const StyledSaveButton = styled(Button)`
  width: 40%;
`;

StyledSaveButton.defaultProps = {
  type: 'button',
  color: 'primary',
  variant: 'contained',
};

export const StyledMarkForReviewButton = styled(Button)`
  color: #000000;
  border: 1px solid #000000;
  width: 55%;

  &:hover {
    border-color: #000000;
    color: #000000;
  }
`;
