import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './tooltip-title.module.scss';

type TooltipTitleProps = {
  classroomName: string;
  studentsCount: number;
};

export const TooltipTitle = ({
  classroomName,
  studentsCount,
}: TooltipTitleProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.tooltipWrapper}>
      <Divider orientation="vertical" flexItem className={styles.divider} />
      <div className={styles.classroomInfoWrapper}>
        <div className={styles.classroomName}>{classroomName}</div>
        <div className={styles.studentsCount}>
          {studentsCount} {t('students_title')}
        </div>
      </div>
    </div>
  );
};
