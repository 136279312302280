import { Calculator } from '@xq/domain';
import { CalculatorItem } from './CalculatorItem';

type CalculatorListProps = {
  calculators: Calculator[];
  onMarkForReview?: (calculator: Calculator) => void;
  openCalculator: (calculator: Calculator) => Promise<void>;
  onScroll?: () => void;
};

export const CalculatorList = ({
   calculators,
   onMarkForReview,
   onScroll,
   openCalculator
}: CalculatorListProps) => {
  return (
    <div
      onScroll={onScroll}
    >
      {calculators.map((calculator) => (
        <CalculatorItem
          onMarkForReview={(calculator) => onMarkForReview?.(calculator)}
          openCalculator={openCalculator}
          key={calculator.id}
          calculator={calculator} />
      ))}
    </div>
  );
};
