import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';

import {
  AddEmails,
  AdministrationTabs,
  emailBackendValidation,
  InvalidEmail,
  newClassroomActions,
  selectCurrentUserState,
  selectNewClassroomState,
  selectSchoolById,
  StyledButton,
  StyledFooter,
  StyledLayoutContainer,
  StyledMain,
  StyledNextButton,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  useNavigationWarningModal,
} from '@xq/web-components';
import { ValidateEmailsUseCase } from '@xq/usecases';
import { getUserRole, UserRoles } from '@xq/domain';
import { TEACHER_APP_ROUTES } from '@xq/web-config';

import { CreateFormHeader } from '../../components/CreateFormHeader';

export const AddTeachersToNewClassroom = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const newClassroomState = useAppSelector(selectNewClassroomState);
  const school = useAppSelector((state) =>
    selectSchoolById(state, newClassroomState.schoolId)
  );

  const [invalidEmails, setInvalidEmails] = useState<InvalidEmail[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useBeforeUnload();

  const validateTeachersEmails = (emails: string[]) => {
    return ValidateEmailsUseCase.execute({
      teacherEmails: emails,
      participantEmails: [],
    });
  };

  const addTeachersEmails = (emails: string[]) => {
    dispatch(newClassroomActions.addTeacherEmails(emails));
  };

  const deleteTeacherEmails = (emails: string[]) => {
    dispatch(newClassroomActions.removeTeacherEmails(emails));
  };

  const roleLabel = t('teacher(s)');
  const roleLabelPlural = t('teachers').toLowerCase();
  const inputTitle = t('add_teacher_to_classroom');
  const inputDescription = t('press_enter_when_you_are_ready');

  const validate = async () => {
    setIsLoading(true);

    const { valid, inValid } = await emailBackendValidation({
      newEmails: newClassroomState.teacherEmails,
      backendValidation: validateTeachersEmails,
      previousInvalidEmails: invalidEmails,
    });

    setIsLoading(false);

    if (!inValid.length) {
      return navigate(TEACHER_APP_ROUTES.getAddingStudentsToNewClassroom());
    }

    setInvalidEmails(inValid);
    deleteTeacherEmails(inValid.map((em: InvalidEmail) => em.email));
    dispatch(
      newClassroomActions.setNewClassroom({
        teacherEmails: valid,
      })
    );
  };

  const { isDirty } = newClassroomState;

  const disabledNext =
    !newClassroomState.teacherEmails.length || !!invalidEmails.length;

  const currentUser = useAppSelector(selectCurrentUserState);
  const { isTeacher } = getUserRole(currentUser);

  useNavigationWarningModal({
    when: isDirty,
    safeRoute: [
      TEACHER_APP_ROUTES.getAddingStudentsToNewClassroom(),
      TEACHER_APP_ROUTES.getNewClassroomSetup(),
    ],
  });

  useEffectOnce(() => {
    if (
      isTeacher &&
      !newClassroomState.teacherEmails.includes(currentUser.email)
    ) {
      dispatch(newClassroomActions.addTeacherEmails([currentUser.email]));
    }
  });

  return (
    <StyledLayoutContainer>
      <CreateFormHeader
        activeIndex={1}
        completeIndexes={[0]}
        title={t('step_two_add_teachers')}
        exitPath={TEACHER_APP_ROUTES.getAdministration(
          AdministrationTabs.classrooms
        )}
      />
      <StyledMain>
        <AddEmails
          predefinedEmails={newClassroomState.teacherEmails}
          domains={school?.domains}
          addedEmails={newClassroomState.teacherEmails}
          invalidEmails={invalidEmails}
          setInvalidEmails={setInvalidEmails}
          usedEmails={newClassroomState.participantEmails}
          validateEmails={validateTeachersEmails}
          roleLabel={roleLabel}
          roleLabelPlural={roleLabelPlural}
          inputTitle={inputTitle}
          inputDescription={inputDescription}
          addEmails={addTeachersEmails}
          removeEmails={deleteTeacherEmails}
          wrongRoleError={t('wrong_role', { role: UserRoles.student })}
        />
      </StyledMain>
      <StyledFooter>
        <StyledButton
          onClick={() => {
            navigate(TEACHER_APP_ROUTES.getNewClassroomSetup());
          }}
        >
          {t('back')}
        </StyledButton>
        <StyledNextButton
          loading={isLoading}
          disabled={disabledNext}
          onClick={validate}
          data-cy="add-teacher-next-button"
        >
          {t('next')}
        </StyledNextButton>
      </StyledFooter>
    </StyledLayoutContainer>
  );
};
