import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { RealTimeMessage } from 'getstream';

import { UserRoles } from '@xq/domain';
import {
  GetNotificationsUseCase,
  SubscribeToNotificationFeedUseCase,
} from '@xq/usecases';
import { loggerService, NotificationFeedMapper } from '@xq/infrastructure';

import {
  deleteAllNotifications,
  deleteNotification,
  fetchNotifications,
  notificationsActions,
  selectCurrentUserState,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { useApi } from '../../ApiProvider/ApiProvider';

export type NotificationsState = {
  markAsReadNotifications: (markAsReadIds: string[]) => void;
  markAllAsRead: () => void;
  getNotifications: (beforeId?: string) => void;
  removeNotification: (id: string) => void;
  removeAllNotifications: (userId: string) => void;
};

const NotificationsContext = createContext<NotificationsState | undefined>(
  undefined
);

export const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const { feedClient } = useApi();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(selectCurrentUserState);

  const getNotifications = useCallback(
    (beforeId?: string) => {
      if (feedClient) {
        dispatch(fetchNotifications({ feedClient, beforeId }));
      }
    },
    [feedClient]
  );

  const markAsReadNotifications = useCallback(
    async (markAsReadIds: string[]) => {
      if (feedClient) {
        await GetNotificationsUseCase.execute(feedClient, {
          markAsReadIds,
        });
        dispatch(notificationsActions.markAsReadNotifications(markAsReadIds));
        getNotifications();
      }
    },
    [feedClient]
  );

  const markAllAsRead = useCallback(async () => {
    if (feedClient) {
      GetNotificationsUseCase.execute(feedClient, {
        markAsReadIds: true,
      });
      dispatch(notificationsActions.markAllAsRead());
    }
  }, [feedClient]);

  const removeNotification = useCallback(
    async (id: string) => {
      if (feedClient) {
        await dispatch(deleteNotification({ feedClient, id }));
      }
    },
    [feedClient]
  );

  const removeAllNotifications = useCallback(
    async (userId: string) => {
      dispatch(deleteAllNotifications({ userId }))
        .then(({ payload }) => {
          if ((payload as string[]).length) {
            dispatch(notificationsActions.removeMany(payload as string[]));
          } else {
            dispatch(notificationsActions.deleteAll());
          }
        })
        .catch((e) => {
          loggerService.error('DELETE ALL NOTIFICATIONS ERROR: ', e);
        })
        .finally(() => {
          markAllAsRead();
          dispatch(notificationsActions.resetBeingDeleted());
        });
    },
    [feedClient]
  );

  const notificationCallback = (realTimeNotifications: RealTimeMessage) => {
    const { deleted, new: newNotifications } = realTimeNotifications;

    if (deleted.length) {
      dispatch(notificationsActions.removeMany(deleted));
    }

    if (newNotifications.length) {
      dispatch(
        notificationsActions.setNotifications(
          NotificationFeedMapper.realTimeToDomain(realTimeNotifications)
        )
      );
    }
  };

  useEffect(() => {
    if (feedClient && !currentUser?.roles?.includes(UserRoles.viewer)) {
      SubscribeToNotificationFeedUseCase.execute(
        feedClient,
        notificationCallback
      );

      const promise = dispatch(fetchNotifications({ feedClient }));

      return () => {
        promise.abort();
        // Unsubscribe not working as expected: not clearing the notificationCallback
        // UnsubscribeFromNotificationFeedUseCase.execute(feedClient);
      };
    }
  }, [currentUser.roles, feedClient]);

  return (
    <NotificationsContext.Provider
      value={{
        markAsReadNotifications,
        markAllAsRead,
        getNotifications,
        removeNotification,
        removeAllNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      'useNotifications  must be used within a NotificationsProvider'
    );
  }

  return context;
};
