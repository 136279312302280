import { PropsWithChildren, SyntheticEvent, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Divider,
  styled,
  Typography,
  AccordionSummary,
  Accordion,
} from '@mui/material';

const StyledRoot = styled('div')`
  width: 100%;
`;

const StyledAccordion = styled(Accordion)`
  &.Mui-expanded.Mui-disabled {
    background-color: transparent;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &.Mui-disabled {
    opacity: 1;
  }
`;

const StyledMarkAsDoneCheckBoxLabel = styled(Typography)`
  font-weight: bold;
  color: var(--shark);
  font-size: 18px;
  display: flex;
`;

type XqAccordionProps = {
  label: string;
  opened: boolean;
  left?: JSX.Element;
  right?: JSX.Element | null;
  onAccordionToggle: (e: SyntheticEvent, isOpened: boolean) => void;
  disabled?: boolean;
};

export const XqAccordion = ({
  opened,
  onAccordionToggle,
  label,
  left,
  right,
  disabled,
  children,
}: PropsWithChildren<XqAccordionProps>) => {
  const [isOpened, setIsOpened] = useState<boolean>(opened);

  useEffect(() => {
    setIsOpened(opened);
  }, [opened]);

  return (
    <StyledRoot>
      <StyledAccordion
        expanded={isOpened}
        onChange={(e) => {
          onAccordionToggle(e, isOpened);
          setIsOpened(!isOpened);
        }}
        elevation={0}
        TransitionProps={{
          timeout: 0,
        }}
        disabled={disabled}
      >
        <StyledAccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                visibility: disabled ? 'hidden' : 'visible',
              }}
            />
          }
          aria-label="Expand"
          id={`${label}-accordion-summary`}
        >
          {left}
          <StyledMarkAsDoneCheckBoxLabel>{label}</StyledMarkAsDoneCheckBoxLabel>
          {right}
        </StyledAccordionSummary>
        {children}
      </StyledAccordion>
      <Divider />
    </StyledRoot>
  );
};
