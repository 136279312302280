import { KeyboardEvent, MouseEvent, PropsWithChildren } from 'react';

import { StyledMenu, StyledMoreVertIcon } from './ActionsMenu.styles';

type ActionsMenuProps = {
  anchorEl: Element | null;
  isDialogOpen: boolean;
  openMenu: (
    event: MouseEvent<SVGSVGElement> | KeyboardEvent<SVGSVGElement>
  ) => void;
  closeMenu: () => void;
};

export const ActionsMenu = ({
  anchorEl,
  isDialogOpen,
  openMenu,
  closeMenu,
  children,
}: PropsWithChildren<ActionsMenuProps>) => {
  return (
    <>
      <StyledMoreVertIcon
        aria-haspopup="true"
        onClick={openMenu}
        tabIndex={isDialogOpen ? -1 : 0}
        onKeyDown={openMenu}
        data-cy="edit-menu"
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {children}
      </StyledMenu>
    </>
  );
};
