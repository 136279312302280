import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const StyledHeader = styled('header')`
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

export const StyledTitle = styled(Typography)`
  min-height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const StyledTitleTwo = styled(Typography)`
  min-height: 40px;
  display: flex;
  align-items: center;
  color: var(--grey-semi-dark);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const StyledSmallTitle = styled(Typography)`
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--grey-semi-dark);
  margin-bottom: 16px;
`;

StyledSmallTitle.defaultProps = {
  variant: 'h1',
};
