import {
  createContext,
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useContext,
  useReducer,
  useRef,
} from 'react';

export type ModalStateValues = {
  isOpen: boolean;
  isConfirmed: boolean;
  bodyText: string | JSX.Element;
  titleText?: string;
  actionButtonText: string;
  cancelButtonText: string;
  loading: boolean;
  showActionButton: boolean;
  showIcon: boolean;
  hideCancel: boolean;
  width: string;
  resetOnConfirm?: boolean;
};

export type ModalState = {
  onCancel: MutableRefObject<(() => Promise<void> | void) | undefined>;
  onClose: MutableRefObject<(() => Promise<void> | void) | undefined>;
  resetModal: () => void;
  onConfirm: MutableRefObject<(() => Promise<unknown> | void) | undefined>;
  state: ModalStateValues;
  dispatchModal: Dispatch<Partial<ModalStateValues>>;
};

const ModalContext = createContext<ModalState | undefined>(undefined);

const initialState: ModalStateValues = {
  isOpen: false,
  isConfirmed: false,
  bodyText: '',
  titleText: undefined,
  actionButtonText: '',
  cancelButtonText: '',
  loading: false,
  showActionButton: true,
  showIcon: true,
  hideCancel: false,
  width: 'min(100vw, 400px)',
};

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const modalReducer = (
    state: typeof initialState,
    payload: Partial<ModalStateValues>
  ) => ({ ...state, ...payload });

  const [state, dispatchModal] = useReducer(modalReducer, initialState);

  const onCancel = useRef<() => Promise<void>>();
  const onClose = useRef<() => Promise<void>>();
  const onConfirm = useRef<() => Promise<void> | void>();

  const resetModal = useCallback(() => {
    dispatchModal(initialState);
    onCancel.current = undefined;
  }, []);

  return (
    <ModalContext.Provider
      value={{
        onConfirm,
        onCancel,
        onClose,
        resetModal,
        state,
        dispatchModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
};
