import { Menu, styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const StyledMoreVertIcon = styled(MoreVertIcon)`
  margin-left: auto;
  cursor: pointer;
  fill: var(--grey-semi-dark);
`;

export const StyledPostOptions = styled('p')`
  padding: 0px;
  font-size: 12px;
  margin: 5px;
`;

export const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    box-shadow: none;
    border: 1px solid var(--alto);
    border-radius: 0;
  }

  .MuiButtonBase-root {
    border-bottom: 1px solid var(--alto);
    width: 230px;
    margin-left: 8px;
    font-weight: 400;
    font-size: 12px;
    height: 31px;
  }

  .MuiList-root {
    padding-right: 10px !important;
  }
`;
