import { styled } from '@mui/material/styles';

export const StyledMain = styled('main')`
  padding: 24px;
  height: 100%;
`;

export const StyledInnerContainer = styled('div')`
  min-height: 100%;
  background-color: var(--white);
  padding: 16px;
`;

export const StyledTableContainer = styled('div')`
  height: 100%;
  position: relative;
  padding: 8px;
  background: var(--white);
`;
