import { StreamClient } from 'getstream';

import { FeedService, StreamFeedService } from '@xq/infrastructure';

export class GetFeedActivityUseCase {
  static async execute(streamToken: string, activityId: string) {
    const client = StreamFeedService.getInstance(streamToken).client;
    const feedService = FeedService.getInstance(client as StreamClient);

    return feedService.getFeedActivity(activityId);
  }
}
