import {
  Container,
  LinesContainer,
  StyledBottomLine,
  StyledDot,
  StyledUpperLine,
} from './SubAccordionConnection.styles';

type SubAccordionConnectionProps = {
  isLast: boolean;
  isExpanded: boolean;
};

export const SubAccordionConnection = ({
  isLast,
  isExpanded,
}: SubAccordionConnectionProps) => {
  return (
    <Container>
      <LinesContainer
        sx={{
          gridTemplateRows: isExpanded ? '34px 1fr' : '26px 1fr',
        }}
      >
        <StyledUpperLine />
        <StyledBottomLine
          sx={{
            display: isLast ? 'none' : 'block',
          }}
        />
      </LinesContainer>
      <StyledDot sx={{ top: isExpanded ? '31px' : '23px' }} />
    </Container>
  );
};
