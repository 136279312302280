import { cond, uniq } from 'lodash';
import { z } from 'zod';
import { User, UserRoles } from '..';
import { GenerateTemplateStatusEnum } from './GenerateTemplateStatus';

export interface IFolderByProgramId {
  [key: string]: string;
}

export interface IFolderIdByEmailByProgramId {
  folderByProgramId: IFolderByProgramId;
}

export interface IStudentFolderIdByEmailByProgramId {
  [email: string]: IFolderIdByEmailByProgramId;
}

type RoleResult = Pick<User, 'roles' | 'roleByClassroomId'>;

export enum ProviderType {
  XQ = 'XQ',
  GoogleClassroom = 'GoogleClassroom',
  Clever = 'Clever',
  Manual = 'Manual',
}

export enum ProviderLastSyncStatus {
  Success = 'Success',
  Error = 'Error',
}

export const ClassroomSchema = z.object({
  archivedOn: z.string().nullable().optional(),
  classroomFolderId: z.string(),
  chatFolderId: z.string(),
  createdAt: z.string().nullable(),
  feedFolderId: z.string(),
  folderIdByProgramId: z.record(z.string(), z.string()),
  chatFolderIdByStudentId: z.record(z.string(), z.string()),
  id: z.string(),
  isArchived: z.boolean(),
  modifiedAt: z.string(),
  name: z.string(),
  participantEmails: z.array(z.string().min(1)),
  programIds: z.array(z.string().min(1)),
  providerIdentifier: z.string().nullable(),
  providerLastSyncStatus: z.nativeEnum(ProviderLastSyncStatus).nullable(),
  providerModifiedAt: z.string().nullable().optional(),
  providerType: z.nativeEnum(ProviderType).nullable(),
  schoolId: z.string(),
  shortName: z.string(),
  teacherEmails: z.array(z.string().min(1)),
  viewerEmails: z.array(z.string().min(1)),
  studentFolderIdByEmailByProgramId: z.record(
    z.string(),
    z.record(z.string(), z.record(z.string(), z.string()))
  ),
  generateStudentTemplatesStatus: z
    .record(z.string(), GenerateTemplateStatusEnum)
    .optional(),
  latestGeneratedTemplatesByProgramId: z
    .record(z.string(), z.array(z.record(z.string(), z.string())))
    .optional(),
});

export type ClassroomListValues =
  | 'participantEmails'
  | 'teacherEmails'
  | 'viewerEmails'
  | 'programIds';

export type Classroom = z.infer<typeof ClassroomSchema>;

export const getClassroomsEmails = (classrooms: Classroom[]) => {
  return [
    ...new Set(
      classrooms
        .map((c) => [
          ...c.participantEmails,
          ...c.teacherEmails,
          ...c.viewerEmails,
        ])
        .flat()
    ),
  ];
};

export const filterStudentClassrooms = (
  classrooms: Classroom[],
  activeProgramsPerClassroom: Record<string, string[]>
) =>
  classrooms.filter(
    (c) =>
      c.classroomFolderId &&
      c.programIds.length &&
      !c.isArchived &&
      activeProgramsPerClassroom[c.id]?.length
  );

export const computeRoles = (
  userEmail: string,
  classrooms: Classroom[]
): RoleResult => {
  const output: RoleResult = {
    roles: [],
    roleByClassroomId: {},
  };

  const setRoles = cond<Classroom, void>([
    [
      (classroom) => classroom.participantEmails.includes(userEmail),
      (classroom) => {
        output.roles.push(UserRoles.student);
        output.roleByClassroomId[classroom.id] = UserRoles.student;
      },
    ],
    [
      (classroom) => classroom.teacherEmails.includes(userEmail),
      (classroom) => {
        output.roles.push(UserRoles.teacher);
        output.roleByClassroomId[classroom.id] = UserRoles.teacher;
      },
    ],
    [
      (classroom) => classroom.viewerEmails.includes(userEmail),
      (classroom) => {
        output.roles.push(UserRoles.viewer);
        output.roleByClassroomId[classroom.id] = UserRoles.viewer;
      },
    ],
  ]);

  classrooms.forEach((classroom) => {
    setRoles(classroom);
  });

  return {
    ...output,
    roles: uniq(output.roles),
  };
};

export const getClassroomTeacherNames = (users: User[], classroom: Classroom) =>
  users
    .filter((user) => classroom.teacherEmails.includes(user.email))
    .map((user) => user.displayName)
    .join(', ') || '';
