import { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';

interface TabPanelProps {
  index: number | string;
  value: number | string;
}

export function TabPanel({
  children,
  value,
  index,
}: PropsWithChildren<TabPanelProps>) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}-${value}`}
      aria-labelledby={`full-width-tab-${index}-${value}`}
      style={{ height: '100%', overflow: 'auto' }}
    >
      {value === index && <Box sx={{ height: '100%' }}>{children}</Box>}
    </div>
  );
}
