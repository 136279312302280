import { School, SchoolSchema } from '@xq/domain';

import { FbSchoolDto, FbSchoolSchema } from '../dto';
import { Mapper } from './mapper';

class SchoolMapper extends Mapper<School, FbSchoolDto> {
  toDomain(school: FbSchoolDto): School {
    return SchoolSchema.parse({
      ...school,
      folderIdByTeacherEmail: school.folderIdByTeacherEmail ?? {},
      driveRootFolderId: school.driveRootFolderId ?? '',
      website: school.website ?? '',
    });
  }

  toPersistence(school: School): FbSchoolDto {
    return FbSchoolSchema.parse(school);
  }
}

export const schoolMapper = new SchoolMapper();
