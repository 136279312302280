import {
  CreateSchoolRequestDto,
  CreateSchoolResponseDto,
} from '@xq/shared/data-access';
import { firebaseService } from '@xq/infrastructure';
import { callWithRetry } from '@xq/shared/utils';

export class CreateSchoolUseCase {
  static async execute(payload: CreateSchoolRequestDto) {
    const createSchool = firebaseService.prepareFunctionCaller<
      CreateSchoolRequestDto,
      CreateSchoolResponseDto
    >('createSchool');

    return callWithRetry(() => createSchool(payload));
  }
}
