import { Errors } from '@xq/domain';
import { GetAccessTokenUseCase } from '../UserUseCases/GetAccessTokenUseCase';
import axios from 'axios';

const getUserScopes = async (token: string): Promise<string> => {
  try {
    const res = await axios.post<{ scope: string }>(
      `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${token}`
    );
    return res?.data?.scope || '';
  } catch (e) {
    throw new Error(Errors.accessTokenError);
  }
};

export class CheckUserScopesUseCase {
  static async execute(scopes: string) {
    let userScope = '';
    try {
      const accessToken = (await GetAccessTokenUseCase.execute()).data;
      userScope = await getUserScopes(accessToken);
    } catch (e) {
      if (e instanceof Error && e?.message === Errors.accessTokenError) {
        const newAccessToken = await GetAccessTokenUseCase.execute();
        if (newAccessToken.data) {
          userScope = await getUserScopes(newAccessToken.data);
        }
      }
    }

    if (!userScope) return false;

    if (scopes.includes(' ')) {
      return scopes.split(' ').every((scope) => userScope.includes(scope));
    }

    return !!userScope.includes(scopes);
  }
}
