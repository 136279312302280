import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { ClassroomsState } from './classrooms.interface';

export const classroomsAdapter = createEntityAdapter<ClassroomsState>({
  selectId: (classroom) => classroom.id,
});

export const classroomsSlice = createSlice({
  name: 'classrooms',
  initialState: classroomsAdapter.getInitialState({ loading: true }),
  reducers: {
    setMany: classroomsAdapter.setMany,
    removeMany: classroomsAdapter.removeMany,
    setAll: classroomsAdapter.setAll,
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
});

export const classroomActions = classroomsSlice.actions;
