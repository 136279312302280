import { useMemo, useState, MouseEvent, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AccordionDetails, Button, styled, useMediaQuery } from '@mui/material';

import {
  STUDENT_APP_ROUTES,
  TENOR_EMBED_URL,
  TENOR_URL,
  TITKTOK_EMBED_URL,
  TITKTOK_URL,
} from '@xq/web-config';
import { ContentType, getUserRole, SubPhase, SubPhaseContent } from '@xq/domain';

import { useEmbeddedContent } from '../hooks';
import { useNotifier, usePhase, useSubPhase } from '../context';
import { SubAccordion } from './SubAccordion';
import { decodeEntities, MOBILE_MEDIA_QUERY } from '../utils';
import { SubAccordionConnection } from '../PhaseContent/SubAccordionConnection';
import { LinkToPostTooltip } from '../LinkToPostTooltip/LinkToPostTooltip';
import {
  feedActions,
  selectCurrentUserClassroom,
  selectCurrentUserProgram,
  selectCurrentUserState,
  selectIsTeacherApp, selectPhaseContent,
  selectUserCalculatorByClassroomIdByProgramIdBySubPhaseId, setUserCalculator,
  useAppDispatch,
  useAppSelector,
} from '../store';
import {format} from "date-fns-tz";
import { DesmosDialog } from '../DesmosCalculator/DesmosDialog';

const StyledContent = styled('div')`
  margin-top: -20px;
  margin-left: 2px;

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

const StyledSubPhaseContainer = styled('div')`
  position: relative;
`;

interface XqAccordionContentProps {
  footer?: (subPhaseContent?: SubPhaseContent) => JSX.Element;
  subAccordionLabel?: JSX.Element;
  left?: (subPhaseContent?: SubPhaseContent) => JSX.Element;
  isSubAccordionOpened: (index: number) => boolean;
  subPhase: SubPhase;
  classes?: {
    root?: string;
  };
}

export const XqAccordionContent = ({
  footer,
  subAccordionLabel,
  left,
  isSubAccordionOpened,
  subPhase,
  classes,
}: XqAccordionContentProps) => {
  const location = useLocation();
  const {
    tabIndex = '0',
    phaseIndex = '0',
    subPhaseContentIndex = '-1',
    subPhaseIndex = '0',
  } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const { notifyTeachersForCalculatorReview } = useNotifier();
  const currentUser = useAppSelector(selectCurrentUserState);
  const { isStudent  } = getUserRole(currentUser);
  const [isDesmosDialogOpen, setIsDesmosDialogOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { studentId = '' } = useParams();

  const content = useMemo(
    () => decodeEntities(subPhase.content),
    [subPhase.content]
  );

  useEmbeddedContent({
    content,
    contentUrlString: TITKTOK_URL,
    embedUrl: TITKTOK_EMBED_URL,
  });

  useEmbeddedContent({
    content,
    contentUrlString: TENOR_URL,
    embedUrl: TENOR_EMBED_URL,
  });

  const { contentClickHandler } = usePhase();
  const { setFocusedSubPhaseContentIndex } = useSubPhase();

  const hasSubPhaseContent = Boolean(subPhase.subPhaseContent.length);

  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null);

  const isTeacherApp = useAppSelector(selectIsTeacherApp);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const phaseContent = useAppSelector((state) =>
    selectPhaseContent(state, +phaseIndex)
  );

  const userCalculator = useAppSelector((state) =>
    selectUserCalculatorByClassroomIdByProgramIdBySubPhaseId(
      state,
      isTeacherApp ? studentId : currentUser.uid,
      currentClassroom?.id ?? '',
      currentProgram?.id ?? '',
      subPhase.id,
    )
  );

  const handleCalculatorSave = async (calculatorState: string) => {
    dispatch(setUserCalculator({
      userId: currentUser.uid,
      classroomId: currentClassroom?.id as string,
      programId: currentProgram?.id as string,
      phaseId: (phaseContent?.[ContentType.PhaseContent]?.id) as number,
      wpPostId: subPhase.id,
      calculatorState,
      title: subPhase.title,
      forReview: !!(userCalculator?.forReview),
      updatedAt: new Date(),
    }));
  };

  const handleMarkForReview = async (calculatorState: string, forReview: boolean) => {
    dispatch(setUserCalculator({
      userId: currentUser.uid,
      classroomId: currentClassroom?.id as string,
      programId: currentProgram?.id as string,
      phaseId: (phaseContent?.[ContentType.PhaseContent]?.id) as number,
      wpPostId: subPhase.id,
      calculatorState,
      title: subPhase.title,
      forReview,
      updatedAt: new Date(),
    }));

    if (isStudent) {
      return notifyTeachersForCalculatorReview(subPhase.title, forReview);
    }
  };

  const onContentClick = (e: MouseEvent<HTMLElement>) => {
    contentClickHandler(e);

    const element = e.target as HTMLElement;

    const selectableElement = element.hasAttribute('data-selectable-paragraph')
      ? element
      : element.parentElement?.hasAttribute('data-selectable-paragraph')
      ? element.parentElement
      : null;

    if (selectableElement) {
      const text =
        selectableElement.innerText || selectableElement.textContent || '';

      setIsOpen(true);
      setText(text);
      setRootElement(selectableElement);
    }
  };

  const onTooltipClickHandler = () => {
    if (text && rootElement?.id) {
      const journalLink = {
        text,
        link: `/${phaseIndex}/${tabIndex}/${subPhaseIndex}/${subPhaseContentIndex}/#${rootElement.id}`,
      };

      dispatch(feedActions.updateJournalLink(journalLink));

      if (isMobile) {
        return navigate(STUDENT_APP_ROUTES.getPostToJournal());
      }

      return navigate(location.pathname);
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        const element = document.getElementById(
          window.location.hash.substring(1)
        );

        element?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [window.location.hash]);

  return (
    <AccordionDetails
      classes={{
        root: classes?.root,
      }}
    >
      {!isTeacherApp && (
        <LinkToPostTooltip
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          rootElement={rootElement}
          onTooltipClick={onTooltipClickHandler}
        />
      )}
      <StyledContent onClick={onContentClick}>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
        {hasSubPhaseContent
          ? subPhase.subPhaseContent.map((c, i) => (
              <StyledSubPhaseContainer key={c.id}>
                <SubAccordionConnection
                  isLast={i === subPhase.subPhaseContent.length - 1}
                  isExpanded={c.isStickyPost || isSubAccordionOpened(i)}
                />
                <SubAccordion
                  wpTemplateCalculator={c.desmosCalculator || ''}
                  subPhaseContent={c}
                  footer={footer?.(c)}
                  left={left?.(c)}
                  onToggle={() => {
                    setFocusedSubPhaseContentIndex(subPhaseIndex, i.toString());
                  }}
                  subAccordionLabel={subAccordionLabel}
                  opened={isSubAccordionOpened(i)}
                />
              </StyledSubPhaseContainer>
            ))
          : null}
      </StyledContent>
      {/* todo make it a separate component reuse in SubAccordion.tsx*/}
      {userCalculator?.calculatorState || subPhase.desmosCalculator ? (
        <div>
          <DesmosDialog
            forReview={!!userCalculator?.forReview}
            onMarkForReview={handleMarkForReview}
            title={subPhase.title}
            onSave={handleCalculatorSave}
            content={content}
            isOpen={isDesmosDialogOpen}
            desmosCalculator={
            userCalculator?.calculatorState || subPhase.desmosCalculator || ''
          }
            closeDialog={() => setIsDesmosDialogOpen(false)}
          />
          <Button
            onClick={() => setIsDesmosDialogOpen(true)}
            variant="contained"
            sx={{ display: 'inline-block' }}
          >
            Open Calculator
          </Button>
          <span
            style={{
              fontSize: '14px',
              marginLeft: '15px',
              display: 'inline-block',
              opacity: '0.6',
            }}
          >
            {userCalculator?.updatedAt ? `Last edited on ${format(new Date(userCalculator.updatedAt), 'MM/dd/yyyy, H:mm')}` : ''}
          </span>
        </div>
      ) : null
      }
      {!hasSubPhaseContent ? footer?.() : null}
    </AccordionDetails>
  );
};
