import { Avatar, Divider, Menu, MenuItem, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    background: var(--blackcurrant);
    width: 224px;
    box-shadow: none;
    border-radius: 0;
    border: none;
  }
`;

const StyledSeparator = styled(Divider)`
  background: var(--haiti);
  margin-top: 5px;
  margin-bottom: 5px;
`;

const StyledMenuItem = styled(MenuItem)`
  background: var(--blackcurrant);
  border: 1px solid var(--blackcurrant);
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #9888a0;

  svg {
    margin-right: 10px;
    width: 20px;
  }

  &:hover {
    background: var(--blackcurrant);
    color: white;
  }
`;

const StyledAnchorTag = styled('a')`
  text-decoration: none;
  color: inherit;
  display: flex;
`;

const StyledUserImage = styled(Avatar)`
  width: 27px;
  height: 27px;
  margin-right: 8px;
  border-radius: 50%;
  opacity: 1;

  svg {
    margin-right: 0;
  }
`;

const StyledTruncateData = styled('div')`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export const DropDownMenu = {
  StyledMenu,
  StyledSeparator,
  StyledMenuItem,
  StyledAnchorTag,
  StyledUserImage,
  StyledTruncateData,
  StyledLink,
};
