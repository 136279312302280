import { GoogleFilesMimeTypes } from '@xq/domain';

export type FileTypeLabel =
  | 'document'
  | 'spreadsheet'
  | 'presentation'
  | 'form'
  | 'jamboard';

type FleTypesOption = {
  label: FileTypeLabel;
  value: GoogleFilesMimeTypes;
  iconLink: string;
};

export const FILE_TYPES_OPTIONS: FleTypesOption[] = [
  {
    label: 'document',
    value: GoogleFilesMimeTypes['application/vnd.google-apps.document'],
    iconLink:
      'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.document',
  },
  {
    label: 'spreadsheet',
    value: GoogleFilesMimeTypes['application/vnd.google-apps.spreadsheet'],
    iconLink:
      'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.spreadsheet',
  },
  {
    label: 'presentation',
    value: GoogleFilesMimeTypes['application/vnd.google-apps.presentation'],
    iconLink:
      'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.presentation',
  },
  {
    label: 'form',
    value: GoogleFilesMimeTypes['application/vnd.google-apps.form'],
    iconLink:
      'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.form',
  },
  {
    label: 'jamboard',
    value: GoogleFilesMimeTypes['application/vnd.google-apps.jam'],
    iconLink:
      'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.jam',
  },
];
