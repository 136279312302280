import {
  FeedAPIResponse,
  NotificationActivity,
  RealTimeMessage,
  StreamUser,
} from 'getstream';

import { Notification } from '@xq/domain';
import { NotificationObjects } from '@xq/shared/data-access';

export class NotificationFeedMapper {
  static toDomainNotifications(response: FeedAPIResponse): {
    unread: number;
    result: Notification[];
  } {
    const notifications = response.results as NotificationActivity[];
    const result: Notification[] = notifications
      .map(
        ({
          is_read: isRead,
          is_seen: isSeen,
          id: notificationId,
          activities,
          group,
        }) => [
          ...activities.map(
            ({
              verb,
              actor,
              id,
              time,
              object,
              schoolId,
              classroomId,
              programId,
              sentryIssueId,
              message,
            }): Notification => ({
              verb,
              isRead,
              isSeen,
              notificationId,
              actorId: (actor as unknown as StreamUser).id || actor,
              id,
              time,
              group,
              object,
              schoolId: schoolId as string | undefined,
              classroomId: classroomId as string | undefined,
              programId: programId as string | undefined,
              sentryIssueId: sentryIssueId as string | undefined,
              message: message as string | undefined,
            })
          ),
        ]
      )
      .flat();

    return { result, unread: response.unread ?? 0 };
  }

  static realTimeToDomain(newNotifications: RealTimeMessage) {
    return newNotifications.new.map((n) => ({
      actorId: (n?.actor as { id: string })?.id,
      classroomId: n.classroomId,
      programId: n.programId,
      message: n.message,
      verb: n.verb,
      time: n.time,
      id: n.id,
      isRead: false,
      isSeen: false,
      objectType: NotificationObjects.Stream,
      object: n.object,
      group: n.group,
      notificationId: n.id,
    })) as Notification[];
  }
}
