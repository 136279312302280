/**
 * helper
 */
interface Timestamp {
  toDate(): Date;
}

/**
 * verify if value is timestamp
 */
function isTimestamp(value: any): boolean {
  const hasSeconds =
    // eslint-disable-next-line no-prototype-builtins
    value.hasOwnProperty('seconds') && value.hasOwnProperty('nanoseconds');
  const hasUnderscoreSeconds =
    // eslint-disable-next-line no-prototype-builtins
    value.hasOwnProperty('_seconds') && value.hasOwnProperty('_nanoseconds');

  return (
    (hasSeconds || hasUnderscoreSeconds) && typeof value.toDate === 'function'
  );
}

/**
 * convert any value
 */
export function convertTimestamp<T extends object>(value: T): T | Date {
  if (value === null || typeof value === 'undefined') return value;

  if (isTimestamp(value)) {
    try {
      return (value as Timestamp).toDate();
    } catch {
      return value;
    }
  }

  return value;
}

/**
 * convert any timestamp properties to js date format
 */
export function convertTimestamps<T>(firebaseObject: T | T[]): T | T[] {
  if (!firebaseObject) return firebaseObject;

  // if an array was passed
  if (Array.isArray(firebaseObject)) {
    return firebaseObject.map((item: any) => convertTimestamps(item));
  }

  // if its a map (object)
  if (firebaseObject instanceof Object) {
    // iterate object properties
    for (const [key, value] of Object.entries(firebaseObject)) {
      // convert simple properties
      if (value && isTimestamp(value)) {
        firebaseObject = {
          ...firebaseObject,
          [key]: convertTimestamp(value),
        };
        continue;
      }

      // recursively convert items inside array
      if (value && Array.isArray(value)) {
        firebaseObject = {
          ...firebaseObject,
          [key]: value.map((item) =>
            isTimestamp(item)
              ? convertTimestamp(item as object)
              : convertTimestamps(item)
          ),
        };
        continue;
      }

      // recursively convert inner objects (maps)
      if (value && typeof value === 'object') {
        firebaseObject = {
          ...firebaseObject,
          [key]: convertTimestamps(value),
        };
        continue;
      }
    }
  }
  return firebaseObject;
}
