import { z } from 'zod';

export const FbCalculatorSchema = z.object({
  title: z.string().optional(),
  calculatorState: z.string(),
  forReview: z.boolean().optional(),
  programId: z.string(),
  wpPostId: z.number(),
  phaseId: z.number(),
  userId: z.string(),
  classroomId: z.string(),
  updatedAt: z.date(),
});

export type FbCalculatorDto = z.infer<typeof FbCalculatorSchema>;
