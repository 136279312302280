import { ChangeEvent, KeyboardEvent } from 'react';

import {
  getUserRole,
  ProgramProgress,
  SubPhase,
  SubPhaseContent,
} from '@xq/domain';

import { StudentPhaseAccordion } from '../Student/StudentPhaseAccordion';
import { TeacherPhaseAccordion } from '../Teacher/TeacherPhaseAccordion';
import { decodeEntities } from '../../utils/decodeEntities';
import { selectCurrentUserState, useAppSelector } from '../../store';
import { usePhase, useSubPhase } from '../../context';

export type AccordionsProps = {
  programProgress?: ProgramProgress;
  handleSubPhaseCheck?(
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>,
    subPhase: SubPhase,
    subPhaseContent?: SubPhaseContent
  ): void;
  assignSubPhaseRef: (element: HTMLDivElement, index: number) => void;
  rightAccordionHeader?: JSX.Element;
};

export const Accordions = ({
  programProgress,
  handleSubPhaseCheck,
  assignSubPhaseRef,
  rightAccordionHeader,
}: AccordionsProps) => {
  const {
    phase,
    setFocusedSubPhaseIndex,
    subPhaseContentIndex,
    subPhaseIndex,
  } = useSubPhase();

  const currentUser = useAppSelector(selectCurrentUserState);

  const { isStudent } = getUserRole(currentUser);

  const { setHasExpandedSubphase } = usePhase();

  const isOpened = (index: number) => index === +subPhaseIndex;

  const isSubAccordionOpened = (index: number) =>
    index === +subPhaseContentIndex;

  if (!phase.subPhaseValues?.length) {
    return null;
  }

  const renderTeacherPhaseAccordion = (subPhase: SubPhase, index: number) => {
    const isOpen = isOpened(index);

    return (
      <TeacherPhaseAccordion
        key={subPhase.id}
        ref={(el) => {
          if (el) assignSubPhaseRef(el, index);
        }}
        subPhase={subPhase}
        label={decodeEntities(subPhase.title)}
        opened={isOpen}
        onAccordionToggle={(_, opened) => {
          setHasExpandedSubphase(!opened);
          setFocusedSubPhaseIndex(index.toString());
        }}
        isSubAccordionOpened={isSubAccordionOpened}
        rightAccordionHeader={rightAccordionHeader}
      />
    );
  };

  const renderStudentPhaseAccordion = (subPhase: SubPhase, index: number) => {
    if (!programProgress) {
      return null;
    }

    return (
      <StudentPhaseAccordion
        key={subPhase.id}
        ref={(el) => {
          if (el) assignSubPhaseRef(el, index);
        }}
        subPhase={subPhase}
        programProgress={programProgress}
        label={decodeEntities(subPhase.title)}
        opened={isOpened(index)}
        onAccordionToggle={(_, opened) => {
          setHasExpandedSubphase(!opened);
          setFocusedSubPhaseIndex(index.toString());
        }}
        handleSubPhaseCheck={(event, subPhaseContent) =>
          handleSubPhaseCheck?.(event, subPhase, subPhaseContent)
        }
        isSubAccordionOpened={isSubAccordionOpened}
      />
    );
  };

  if (!phase.subPhaseValues) {
    return null;
  }

  return (
    <>
      {phase.subPhaseValues.map((subPhase, index) =>
        isStudent
          ? renderStudentPhaseAccordion(subPhase, index)
          : renderTeacherPhaseAccordion(subPhase, index)
      )}
    </>
  );
};
