import { programs } from './entities';

const programSlugs = ['launch', 'close-enough'] as const;

export type ProgramSlug = (typeof programSlugs)[number];

export type TestProgram = (typeof programs)[number];

export const TEST_PROGRAMS = programs.reduce(
  (acc, program) => ({
    ...acc,
    [program.slug]: program,
  }),
  {} as Record<ProgramSlug, TestProgram>
);
