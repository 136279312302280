import {
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuProps,
  styled,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DrawerProps } from '@mui/material/Drawer/Drawer';

export const StyledDrawer = styled((props: DrawerProps) => (
  <Drawer classes={{ paper: 'paper' }} {...props} />
))`
  .paper {
    width: 296px;
    background-color: var(--haiti);
    color: var(--white);
    overflow-y: hidden;
  }
`;

export const StyledNotificationHeader = styled('header')`
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  justify-content: space-between;
`;

export const StyledNotificationTitle = styled('p')`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.15px;
  cursor: pointer;
`;

export const StyledListItem = styled(ListItem)`
  position: relative;
  align-items: self-start;
  justify-content: space-between;
  margin-top: 0;
  padding-top: 10px;
  padding-left: 16px;
  padding-right: 23px;
`;

export const StyledMessageContainer = styled('div')`
  padding: 0 16px;
`;

export const StyledMarkAllButton = styled(Button)`
  font-size: 12px;
  text-transform: none;
  color: var(--mamba);

  &:hover {
    color: var(--white);
  }
`;

const notificationHeaderHeight = '56px';

export const StyledScrollContainer = styled(List)(() => ({
  height: `calc(100vh - ${notificationHeaderHeight})`,
  overflowY: 'auto',
}));

export const StyledNotificationsMenu = styled('div')`
  display: inline-block;
  margin-top: 8px;
`;

export const StyledIconButton = styled(IconButton)`
  color: var(--white);
`;

export const StyledMoreHorizIcon = styled(MoreHorizIcon)`
  margin-left: auto;
  cursor: pointer;
  font-size: 30px;
`;

export const StyledMenu = styled((props: MenuProps) => (
  <Menu classes={{ paper: 'paper' }} {...props} />
))`
  .paper {
    background-color: var(--haiti);
    color: var(--white);
    overflow-y: hidden;
  }

  .MuiButtonBase-root {
    font-size: 12px;
    padding: 0 10px 0 10px;
  }
`;
