import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  DeletePhaseJournalActivityUseCase,
  DeletePostJournalFeedUseCase,
  EditPostUseCase,
  GetFeedActivitiesUseCase,
  GetFeedActivityUseCase,
  HandleHidePostUseCase,
  OnAddActivityUseCase,
  PersistLastPostUseCase,
  SetReviewStateUseCase,
} from '@xq/usecases';

import {
  EditActivity,
  HideActivity,
  MarkAsReview,
  PostActivity,
  DeleteJournalActivity,
  GetActivitiesPayload,
  GetActivityByIdPayload,
} from './activity.interface';
import { RootState, usersActions } from '../index';
import { loggerService } from '@xq/infrastructure';

export const fetchActivityById = createAsyncThunk(
  'activity/fetchActivityById',
  async ({ activityId, streamToken }: GetActivityByIdPayload) => {
    const activities = await GetFeedActivityUseCase.execute(
      streamToken,
      activityId
    );

    return {
      activity: activities[0],
    };
  }
);

export const fetchActivities = createAsyncThunk(
  'activity/fetchActivities',
  async ({ feedId, streamToken }: GetActivitiesPayload, { getState }) => {
    const state = getState() as RootState;
    const feed = state.feed;
    const currentLastPostId = feed.id_lt;

    const activities = await GetFeedActivitiesUseCase.execute(
      streamToken,
      currentLastPostId
    );

    return {
      activities,
    };
  },
  {
    condition: ({ feedId }, { getState }) => {
      if (!feedId) {
        return true;
      }

      const state = getState() as RootState;
      const feed = state.feed;

      const hasNextPage = feed.hasNextPage;
      const loading = feed.loading;
      const currentLastPostId = feed.id_lt;

      const shouldCancel = (!hasNextPage && currentLastPostId) || loading;

      return !shouldCancel;
    },
  }
);

export const postActivity = createAsyncThunk(
  'class-feed/postActivity',
  async (
    {
      currentClassroom,
      currentProgram,
      currentUser,
      feedClient,
      isForReview,
      feedSlug,
      message,
      selectedFiles,
      feedId,
      journalLink,
    }: PostActivity,
    { dispatch }
  ) => {
    const addedActivity = await OnAddActivityUseCase.execute({
      currentClassroom,
      currentProgram,
      currentUser,
      feedClient,
      isForReview,
      feedSlug,
      message,
      selectedFiles,
      journalLink,
    });

    const date = new Date();
    const persistLastPostUseCase = new PersistLastPostUseCase();
    persistLastPostUseCase.execute(currentUser.uid, date).catch((e) => {
      loggerService.error(e);
    });

    dispatch(
      usersActions.userUpdated({
        id: currentUser.uid,
        changes: {
          lastPost: date.toString(),
        },
      })
    );

    return { addedActivity, classroomId: currentClassroom.id, feedId };
  }
);

export const editActivity = createAsyncThunk(
  'class-feed/editActivity',
  async ({
    activity,
    classroomId,
    editedValue,
    feedSlug,
    programId,
    streamToken,
    attachedFiles,
  }: EditActivity) => {
    const editPostUseCase = new EditPostUseCase();
    await editPostUseCase.execute(
      {
        id: activity.id,
        message: editedValue,
        userId: activity.actor?.id,
        classroomId,
        foreign_id: activity.foreign_id,
        hidden: activity.hidden,
        programId,
        feedSlug,
        attachedFiles,
      },
      streamToken
    );

    return { activityId: activity.id, editedValue, attachedFiles };
  }
);

export const deleteJournalActivity = createAsyncThunk(
  'journal-feed/deleteJournalActivity',
  async ({
    activity,
    classroomId,
    programId,
    feedClient,
  }: DeleteJournalActivity) => {
    await new DeletePostJournalFeedUseCase().execute({
      postData: {
        id: activity.id,
        classroomId,
        programId,
        userId: activity.actor.id,
      },
      client: feedClient,
    });

    if (activity.journalLink) {
      await new DeletePhaseJournalActivityUseCase().execute({
        postData: {
          id: activity.id,
          classroomId,
          userId: activity.actor.id,
          programId,
          journalActivityId: activity.journalLink.link.split('#')[1],
        },
        client: feedClient,
      });
    }

    return { activityId: activity.id, classroomId };
  }
);

export const hideActivity = createAsyncThunk(
  'class-feed/hideActivity',
  async ({
    activity,
    currentClassroomId,
    hidden,
    programId,
    streamToken,
  }: HideActivity) => {
    await HandleHidePostUseCase.execute({
      activity,
      hidden,
      programId,
      streamToken,
      currentClassroomId,
    });

    return { activityId: activity.id, hidden };
  }
);

export const markForReview = createAsyncThunk(
  'activity/markAsReview',
  async ({
    activity,
    currentClassroom,
    streamToken,
    newReviewState,
    programId,
  }: MarkAsReview) => {
    await SetReviewStateUseCase.execute({
      activity,
      currentClassroom,
      newReviewState,
      programId,
      streamToken,
    });

    return {
      activityId: activity.id,
      isForReview: newReviewState,
    };
  }
);
