import { DocumentService, GoogleDriveAdapter } from '@xq/infrastructure';

export class UploadToDriveUseCase {
  static async execute(fileData: File, folderId: string) {
    if (fileData) {
      return DocumentService.getInstance(
        GoogleDriveAdapter.getInstance()
      ).uploadDocumentToStorage(fileData, folderId);
    }

    return Promise.reject(new Error('A file must be provided'));
  }
}
