import { z } from 'zod';

import { WizardContent } from '@xq/domain';

export const CreateWizardContentSchema = z.object({
  slug: z.string(),
  positionSelector: z.string(),
});

export type CreateWizardContentRequestDto = z.infer<
  typeof CreateWizardContentSchema
>;

export type CreateWizardContentResponseDto = WizardContent | null;
