import { memoize } from 'lodash';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { AttachedFile } from '@xq/domain';

import styles from './file-preview.module.scss';
import { FileSize } from '../FileSize/FileSize';

type FileInfoProps = {
  iconLink: string;
  name: string;
  size?: string;
};

const FileInfo = ({ name, iconLink, size }: FileInfoProps) => {
  const { t } = useTranslation();

  const getHigherResImg = memoize((url: string) => url.replace('16', '128'));

  return (
    <>
      <img
        className={styles.fileIcon}
        src={getHigherResImg(iconLink || '')}
        alt={t('preview_file_icon')}
      />
      <span className={styles.fileName}>{name}</span>
      <FileSize className={styles.fileSize} size={size} />
    </>
  );
};

type FilePreviewProps = {
  file: AttachedFile;
  onDelete?(fileId: string): void;
  isUploading?: boolean;
};

export const FilePreview = ({
  file,
  onDelete,
  isUploading = false,
  children,
}: PropsWithChildren<FilePreviewProps>) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.fileInfoContainer}>
        {file.webViewLink && (
          <a
            className={styles.fileInfo}
            href={file.webViewLink}
            target="_blank"
            rel="noreferrer"
          >
            <FileInfo
              name={file.name}
              iconLink={file.iconLink}
              size={file.size}
            />
          </a>
        )}

        {!file.webViewLink && (
          <div className={styles.fileInfo}>
            <FileInfo
              iconLink={file.iconLink}
              name={file.name}
              size={file.size}
            />
          </div>
        )}

        {!isUploading && onDelete && (
          <IconButton
            className={styles.removeFile}
            onClick={() => onDelete(file.id)}
          >
            <HighlightOffIcon />
          </IconButton>
        )}
      </div>

      {isUploading && (
        <div>
          <small className={styles.fileSize}>{t('uploading')}</small>
          <LinearProgress className="progress-bar" />
        </div>
      )}

      {children}
    </div>
  );
};
