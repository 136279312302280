import axios from 'axios';

const BASE_URL =
  process.env.NX_PUBLIC_REACT_STUDENT_CLOUD_FUNCTIONS_BASE_URL ||
  process.env.NEXT_PUBLIC_REACT_STUDENT_CLOUD_FUNCTIONS_BASE_URL ||
  '';

export const restClient = axios.create({
  baseURL: BASE_URL,
});
