import { z } from 'zod';

export const FbProgramSchema = z.object({
  id: z.string(),
  slug: z.string(),
  title: z.string(),
  createdAt: z.date(),
  modifiedAt: z.date(),
  courseId: z.string(),
  ordering: z.number().optional(),
  duration: z.number().optional(),
  isActive: z.boolean(),
});

export type FbProgramDto = z.infer<typeof FbProgramSchema>;
