import { PropsWithChildren } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

type GuardedRouteType = {
  isAllowed: boolean;
};

export const GuardedRoute = ({
  isAllowed,
  children,
}: PropsWithChildren<GuardedRouteType>) => {
  const navigate = useNavigate();

  if (!isAllowed) {
    navigate(-1);
    return null;
  }

  return children ? <>{children}</> : <Outlet />;
};
