import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';

import {
  StyledSelect,
  StyledSelectProgramItem,
  StyledMenuTitle,
  StyledListSubheader,
} from './ProgramSelect.styles';

import { WordPressService } from '@xq/infrastructure';

import {
  selectCurrentUserState,
  useAppSelector,
  selectCurrentUserClassroomPrograms,
  selectCurrentUserProgramId,
  persistCurrentProgramId,
  useAppDispatch,
} from '../store/index';
import { SelectChangeEvent, useMediaQuery } from '@mui/material';
import { MOBILE_MEDIA_QUERY } from '../utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type WpProgramsType = {
  [key: string]: {
    module_ordering: number;
  };
};

const wordpressService = new WordPressService();

export function ProgramSelect() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUserState);
  const selectedProgramId = useAppSelector(selectCurrentUserProgramId);
  const [wpPrograms, setWpPrograms] = useState<WpProgramsType>({});
  const programs = useAppSelector(selectCurrentUserClassroomPrograms);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const { t } = useTranslation();

  useEffect(() => {
    wordpressService.fetchPrograms().then((res) => {
      if (!res) return;
      setWpPrograms(res);
    });
  }, []);

  const algebraPrograms = useMemo(
    () =>
      programs
        .map((program) => {
          return {
            ...program,
            order:
              program.ordering || wpPrograms[program.slug]?.module_ordering,
          };
        })
        .filter((p) => p.courseId === 'ALG')
        .sort((a, b) => (a.order < b.order ? -1 : 1)),
    [programs, wpPrograms]
  );

  const geometryPrograms = useMemo(
    () =>
      programs
        .map((program) => {
          return {
            ...program,
            order: wpPrograms[program.slug]?.module_ordering,
          };
        })
        .filter((p) => p.courseId === 'GEO')
        .sort((a, b) => (a.order < b.order ? -1 : 1)),
    [programs, wpPrograms]
  );

  const handleChange = (event: SelectChangeEvent<any>) => {
    if (!currentUser.currentClassroomId) {
      return;
    }

    dispatch(
      persistCurrentProgramId({
        userId: currentUser.uid,
        classroomId: currentUser.currentClassroomId,
        programId: event.target.value,
      })
    );
  };

  useEffect(() => {
    const programExists = programs.find((p) => p.id === selectedProgramId);

    if (!programExists && programs.length && currentUser.currentClassroomId) {
      dispatch(
        persistCurrentProgramId({
          userId: currentUser.uid,
          classroomId: currentUser.currentClassroomId,
          programId: programs[0].id,
        })
      );
    }
  }, [selectedProgramId, programs, currentUser.currentClassroomId]);

  const selectLabel = useMemo(() => {
    if (selectedProgramId && programs.length) {
      const program = programs.find((p) => p.id === selectedProgramId);
      return `${program?.courseId} Course`;
    }
    return '';
  }, [selectedProgramId, programs]);

  if (!programs.length || !selectedProgramId) return null;

  return (
    <div>
      <InputLabel htmlFor="grouped-select">{selectLabel}</InputLabel>
      <StyledSelect
        id="grouped-select"
        disableUnderline
        ismobile={`${isMobile}`}
        value={selectedProgramId}
        IconComponent={ExpandMoreIcon}
        onChange={handleChange}
      >
        <StyledListSubheader>{t('algebra_course')}</StyledListSubheader>
        {algebraPrograms.map((p, index) => (
          <StyledSelectProgramItem value={p.id} key={p.id}>
            <StyledMenuTitle>
              {index + 1}. {p.title}
            </StyledMenuTitle>
          </StyledSelectProgramItem>
        ))}
        <StyledListSubheader>{t('geometry_course')}</StyledListSubheader>
        {geometryPrograms.map((p, index) => (
          <StyledSelectProgramItem value={p.id} key={p.id}>
            <StyledMenuTitle>
              {index + 1}. {p.title}
            </StyledMenuTitle>
          </StyledSelectProgramItem>
        ))}
      </StyledSelect>
    </div>
  );
}
