import { PropsWithChildren, useMemo } from 'react';
import { uniq } from 'lodash';

import { hasPermission, PERMISSIONS, Scopes } from '@xq/domain';
import { selectCurrentUserRoles, useAppSelector } from '../store';

interface RestrictViewProps {
  scopes: Scopes[];
}

export const RestrictView = ({
  children,
  scopes,
}: PropsWithChildren<RestrictViewProps>) => {
  const roles = useAppSelector(selectCurrentUserRoles);

  const canView = useMemo(() => {
    if (scopes && roles) {
      const permissions = uniq(roles?.map((r) => PERMISSIONS[r]).flat());

      return hasPermission({ permissions, scopes });
    }
  }, [roles, scopes]);

  return canView ? <>{children}</> : null;
};
