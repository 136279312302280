import { useEffect, useState } from 'react';
import { onSnapshot, doc, DocumentReference } from 'firebase/firestore';

import {
  firebaseService,
  loggerService,
  subscriptionManager,
} from '@xq/infrastructure';
import { FbUserDto, UserMapper, CollectionNames } from '@xq/shared/data-access';
import { allEqual } from '@xq/shared/utils';
import { User } from '@xq/domain';

import { useMemoCompare } from './useMemoCompare';

const userMapper = new UserMapper();

type Props = {
  userId?: string;
};

export const useUserSubscription = ({ userId }: Props) => {
  const [user, setUser] = useState<User | null>(null);

  const shouldCreateQuery = useMemoCompare(userId, (prev, next) =>
    !prev ? false : allEqual([next], [prev])
  );

  useEffect(() => {
    if (!userId) return;

    const docRef = doc(firebaseService.db, CollectionNames.users, userId);

    const subscribe = (userQuery: DocumentReference) =>
      onSnapshot(
        userQuery,
        (snapshot) => {
          if (!snapshot.exists()) return;

          setUser(userMapper.toDomain(snapshot.data() as FbUserDto));
        },
        (e) => {
          loggerService.error('USER SUBSCRIPTION ERROR: ', e);
        }
      );

    const unsubscribe = subscribe(docRef);
    subscriptionManager.add([unsubscribe]);

    return () => subscriptionManager.remove([unsubscribe]);
  }, [shouldCreateQuery]);

  return { userData: user };
};
