import { IdProps, JournalActivityIdProps } from '../Interfaces';

export enum FeedSlugs {
  notification = 'notification',
  student = 'student',
  templatesNotification = 'templatesNotification',
  phaseJournal = 'phaseJournal',
  adminNotification = 'adminNotification',
}

export const NOTIFICATION_FEED_SLUGS = [
  FeedSlugs.notification,
  FeedSlugs.templatesNotification,
  FeedSlugs.adminNotification,
];

export const getJournalId = ({ userId, programId, classroomId }: IdProps) =>
  `${userId}-${classroomId}-${programId}`;

export const getJournalActivityId = ({
  journalActivityId,
  programId,
  classroomId,
}: JournalActivityIdProps) =>
  `${classroomId}-${programId}-${journalActivityId}`;
