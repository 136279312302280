import { useEffect } from 'react';
import { $getRoot, $isRootNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateNodesFromDOM } from '@lexical/html';

type InsertHTMLPluginProps = {
  htmlString: string;
};

export const InsertHTMLPlugin = ({ htmlString }: InsertHTMLPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      const selection = root.select();

      root.clear();

      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlString, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);

      if ($isRootNode(selection.anchor.getNode())) {
        selection.insertParagraph();
      }
      selection.insertNodes(nodes);
    });
  }, [htmlString]);

  return null;
};
