import { connect, StreamClient } from 'getstream';

export interface IStreamFeedService {
  client?: StreamClient | null;
}

export class StreamFeedService implements IStreamFeedService {
  private static instance: StreamFeedService | null;

  client: StreamClient | null = null;

  constructor(token: string) {
    this.connectClient(token);
  }

  private connectClient(token: string) {
    const apiKey = process.env.NX_PUBLIC_STREAM_API_KEY || process.env.NEXT_PUBLIC_STREAM_API_KEY || '';
    const appId = process.env.NX_PUBLIC_STREAM_AP_ID || process.env.NEXT_PUBLIC_STREAM_AP_ID || '';
    this.client = token !== '' ? connect(apiKey, token, appId) : null;
  }

  static getInstance(token: string): StreamFeedService {
    if (!StreamFeedService.instance) {
      StreamFeedService.instance = new StreamFeedService(token);
    }
    return StreamFeedService.instance;
  }

  static deleteInstance() {
    this.instance = null;
  }
}
