import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { Comment } from '@xq/domain';

import {
  createComment,
  deleteComment,
  fetchComments,
  updateComment,
} from './comment.actions';

export const commentAdapter = createEntityAdapter<Comment>({
  selectId: (comment) => comment.id,
  sortComparer: (a, b) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

export const commentSlice = createSlice({
  name: 'comment',
  initialState: commentAdapter.getInitialState(),
  reducers: {
    addComment: commentAdapter.addOne,
    removeComment: commentAdapter.removeOne,
    updateComment: commentAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchComments.fulfilled, (state, { payload }) => {
      commentAdapter.setMany(state, payload);
    });
    builder.addCase(createComment.fulfilled, (state, { payload }) => {
      commentAdapter.addOne(state, payload);
    });
    builder.addCase(updateComment.fulfilled, (state, { payload }) => {
      commentAdapter.updateOne(state, { id: payload.id, changes: payload });
    });
    builder.addCase(deleteComment.fulfilled, (state, { payload }) => {
      commentAdapter.removeOne(state, payload);
    });
  },
});

export const commentActions = commentSlice.actions;
