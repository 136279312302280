import {
  ClassroomRepository,
} from '@xq/infrastructure';
import { FbCalculatorDto } from '@xq/shared/data-access';

export class SetUserCalculatorByClassroomIdByProgramIdBySubPhaseIdUseCase {
  private classroomRepository: ClassroomRepository;
  constructor() {
    this.classroomRepository = new ClassroomRepository();
  }
  async execute(
    payload: FbCalculatorDto
  ) {
    return this.classroomRepository.setUserCalculatorByClassroomIdByProgramIdByWpPostId(
      payload
    );
  }
}
