import { PropsWithChildren } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import styles from './banner.module.scss';

export interface BannerProps {
  text: string;
  backgroundColor: string;
  onClose?(): void;
  isHidden: boolean;
}

export const Banner = ({
  children,
  onClose,
  text,
  isHidden,
  backgroundColor,
}: PropsWithChildren<BannerProps>) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return !isHidden ? (
    <Toolbar
      className={styles.banner}
      sx={{
        background: backgroundColor,
      }}
    >
      <Typography className={styles.toolbarMessage}>{text}</Typography>
      {children}
      <IconButton
        className={styles.closeIcon}
        onClick={handleClose}
        data-cy="bannerCloseBtn"
      >
        <CloseIcon />
      </IconButton>
    </Toolbar>
  ) : null;
};
