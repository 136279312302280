import {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { useTitle } from 'react-use';
import { Calculator, getUserRole } from '@xq/domain';

import { useNotifier } from '../context';
import { PageTitles } from '../utils';
import {
  selectCurrentUserState,
  useAppSelector,
  useAppDispatch,
  selectUserCalculatorsByUserIdByClassroomIdByProgramId,
  fetchUserCalculators,
  setUserCalculator,
} from '../store';
// todo wizard step for the calculator?
// todo mobile view for the calculator?
import { CalculatorList } from './CalculatorList';
import { DesmosDialog } from '../DesmosCalculator/DesmosDialog';
import { WordPressService } from "@xq/infrastructure";
import { useStudentsCalculatorsSubscription } from '../hooks/UseUserCalculatorsSubscription';

const wpService = new WordPressService();

export const StudentCalculatorList = ({ studentId } : { studentId: string }) => {
  useTitle(PageTitles.MY_FILES);

  const { t } = useTranslation();
  const {
    notifyTeachersForCalculatorReview
  } = useNotifier();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUserState);
  const [isDesmosDialogOpen, setIsDesmosDialogOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const [openedCalculator, setOpenedCalculator] = useState<Calculator | null>(null);
  const currentClassroomId = currentUser.currentClassroomId ?? '';
  const currentProgramId =
    currentUser.currentProgramIdByClassroomId[currentClassroomId] ?? '';
  const { isStudent } = getUserRole(currentUser);

  const userCalculatorsByProgram = useAppSelector((state) =>
    selectUserCalculatorsByUserIdByClassroomIdByProgramId(
      state,
      studentId,
      currentClassroomId,
      currentProgramId
    )
  );

  useStudentsCalculatorsSubscription({
    programId: currentProgramId,
    classroomId: currentClassroomId,
  });

  useEffect(() => {
    // todo change to fetchUserCalculatorsByProgramIdByClassroomIdByUserId
    dispatch(fetchUserCalculators(
      {
        classroomId: currentClassroomId,
        programId: currentProgramId,
      }
    ));
  }, [studentId, currentClassroomId, currentProgramId]);

  if (isNil(currentUser)) {
    return null;
  }

  const toggleMarkForReviewHandler = (calculator: Calculator) => {
    const forReview = !calculator.forReview;
    dispatch(setUserCalculator({
      userId: calculator.userId,
      classroomId: calculator.classroomId,
      programId: calculator.programId,
      phaseId: calculator.phaseId,
      wpPostId: calculator.wpPostId,
      calculatorState: calculator.calculatorState,
      title: calculator.title,
      forReview: forReview,
      updatedAt: new Date(),
    }));

    if (isStudent) {
      return notifyTeachersForCalculatorReview(calculator.title, forReview);
    }
  };

  const openCalculatorHandler = async (calculator: Calculator) => {
    // TODO this can be done with a selector no need to fech again!!!
    const post = await wpService.fetchPostById(`${calculator.wpPostId}`);
    setContent(post.content.rendered);
    setOpenedCalculator(calculator);
    setIsDesmosDialogOpen(true);
  }

  // TODO need calculator context and to extract all these functions there
  const handleCalculatorSave = async (calculatorState: string) => {
    if (!openedCalculator) return;
    dispatch(setUserCalculator({
      userId: openedCalculator?.userId,
      classroomId: openedCalculator?.classroomId,
      programId: openedCalculator?.programId,
      phaseId: openedCalculator?.phaseId,
      wpPostId: openedCalculator?.wpPostId,
      calculatorState,
      title: openedCalculator?.title,
      forReview: openedCalculator?.forReview,
      updatedAt: new Date(),
    }));
  };

  const handleMarkForReview = async (calculatorState: string, forReview: boolean) => {
    if (!openedCalculator) return;
    dispatch(setUserCalculator({
      userId: openedCalculator.userId,
      classroomId: openedCalculator.classroomId,
      programId: openedCalculator.programId,
      phaseId: openedCalculator.phaseId,
      wpPostId: openedCalculator.wpPostId,
      calculatorState,
      title: openedCalculator?.title,
      forReview,
      updatedAt: new Date(),
    }));

    if (isStudent) {
      return notifyTeachersForCalculatorReview(openedCalculator?.title, forReview);
    }
  }

  return (
    <div>
      {openedCalculator && (
        <DesmosDialog
          forReview={openedCalculator.forReview}
          onMarkForReview={handleMarkForReview}
          title={openedCalculator.title}
          onSave={handleCalculatorSave}
          content={content}
          isOpen={isDesmosDialogOpen}
          desmosCalculator={openedCalculator.calculatorState}
          closeDialog={() => setIsDesmosDialogOpen(false)}
        />
      )}

      <CalculatorList
        openCalculator={openCalculatorHandler}
        calculators={userCalculatorsByProgram as Calculator[]}
        onMarkForReview={toggleMarkForReviewHandler}
      />
    </div>
  );
};
