import { v4 as uuidv4 } from 'uuid';

import { LoadingState } from './LoadingState';
import { LoadingStateDataModel } from './LoadingStateModel';
import { LoadingStateType } from './LoadingStateType';

export abstract class LoadingStateService {
  private observers: Array<(state: LoadingStateDataModel[]) => void> = [];

  private loadingState = new LoadingState<{
    name: string;
    type: LoadingStateType;
  }>();

  trackLoadingState<T>(
    promise: Promise<T>,
    data: { name: string; type: LoadingStateType }
  ): { trackedPromise: Promise<T>; id: string } {
    const id = uuidv4();
    const trackedPromise = this.trackPromiseState(promise, { id, data });

    return {
      trackedPromise: new Promise((resolve, reject) => {
        trackedPromise.then(resolve).catch(reject);
      }),
      id,
    };
  }

  clearStateWithIds(ids: string[]) {
    this.loadingState.clearStateWithIds(ids);
  }

  clearState() {
    this.loadingState.clearState();
  }

  get currentLoadingState() {
    return this.loadingState.currentState;
  }

  subscribeToChanges(callback: (state: LoadingStateDataModel[]) => void) {
    this.observers = [...this.observers, callback];
  }

  unsubscribeToChanges(callback: (state: LoadingStateDataModel[]) => void) {
    this.observers = this.observers.filter((observer) => observer !== callback);
  }

  private notifySubscribers(state: LoadingStateDataModel[]) {
    this.observers.forEach((callback) => callback(state));
  }

  private trackPromiseState<T>(
    promise: Promise<T>,
    state: LoadingStateDataModel
  ) {
    this.addToState(state);

    return promise.finally(() => {
      this.removeFromState(state.id);
    });
  }

  private addToState(state: LoadingStateDataModel) {
    this.loadingState.add(state);
    this.notifySubscribers(this.currentLoadingState);
  }

  private removeFromState(id: string) {
    this.loadingState.remove(id);
    this.notifySubscribers(this.currentLoadingState);
  }
}
