import { createSelector } from '@reduxjs/toolkit';

import { selectUserCalculators } from '../index';

export const selectUserCalculatorsByUserIdByClassroomIdByProgramId = createSelector(
  [
    selectUserCalculators,
    (_, userId: string) => userId,
    (_, __, classroomId: string) => classroomId,
    (_, __, ___, programId: string) => programId,
  ],
  (userCalculators, userId, classroomId, programId) => {
    return Object.values(userCalculators.entities).filter((uc) => {
      return uc?.userId === userId && uc?.classroomId === classroomId && uc?.programId === programId;
    });
  }
);

export const selectUserCalculatorsByClassroomIdByProgramId = createSelector(
  [
    selectUserCalculators,
    (_, classroomId: string) => classroomId,
    (_, __, programId: string) => programId,
  ],
  (userCalculators, classroomId, programId) => {
    return Object.values(userCalculators.entities).filter((uc) => {
      return uc?.classroomId === classroomId && uc?.programId === programId;
    });
  }
);

export const selectUserCalculatorByClassroomIdByProgramIdBySubPhaseId = createSelector(
  [
    selectUserCalculators,
    (_, userId: string) => userId,
    (_, __, classroomId: string) => classroomId,
    (_, __, ___, programId: string) => programId,
    (_, __, ___, ____, subPhaseId: number) => subPhaseId,
  ],
  (userCalculators, userId, classroomId, programId, subPhaseId) => {
    return Object.values(userCalculators.entities).find((uc) => {
      return userId === uc?.userId && uc?.classroomId === classroomId && uc?.programId === programId && uc?.wpPostId === Number(subPhaseId);
    });
  }
);

export const selectUserCalculatorsByClassroomIdByProgramIdByPhaseId = createSelector(
  [
    selectUserCalculators,
    (_, classroomId: string) => classroomId,
    (_, __, programId: string) => programId,
    (_, __, ___, phaseId: number) => phaseId,
  ],
  (userCalculators, classroomId, programId, phaseId) => {
    return Object.values(userCalculators.entities).filter((uc) => {
      return uc?.classroomId === classroomId && uc?.programId === programId && uc?.phaseId === phaseId;
    });
  }
);
