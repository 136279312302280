import { z } from 'zod';

export const CourseSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  duration: z.number(),
});

export type Course = z.infer<typeof CourseSchema>;

export const courseFactory = (course: Partial<Course>): Course => {
  return {
    description: course.description ?? '',
    duration: course.duration ?? 0,
    id: course.id ?? '',
    title: course.title ?? '',
  };
};
