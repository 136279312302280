import { IUserRepository, UserRepository } from '@xq/infrastructure';

export class PersistUserCurrentSchoolUseCase {
  userRepository: IUserRepository;

  constructor() {
    this.userRepository = new UserRepository();
  }

  execute(userId: string, currentSchoolId: string): Promise<void> {
    return this.userRepository.persistUserCurrentSchool(
      userId,
      currentSchoolId
    );
  }
}
