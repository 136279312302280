import { School, SchoolStatusEnum } from '@xq/domain';

export const schools: School[] = [
  {
    adminEmail: 'test.admin@xqlabs.io',
    classroomsIds: ['NkdYskjpi4dD6ShvREC0', '7bkv7WFVgz1Sl6N6LXVk'],
    domains: ['xqlabs.io', 'xqlabsdev.io'],
    driveRootFolderId: '',
    name: 'School 1',
    state: 'California',
    id: '',
    city: 'City 1',
    website: 'https://test.test',
    status: SchoolStatusEnum.Enum.approved,
  },
];

const schoolNames = ['School 1'] as const;

export type SchoolName = (typeof schoolNames)[number];

export type TestSchool = (typeof schools)[number];

export const TEST_SCHOOLS = schools.reduce(
  (acc, school) => ({
    ...acc,
    [school.name]: school,
  }),
  {} as Record<SchoolName, TestSchool>
);
