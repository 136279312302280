import {
  ClassroomRepository,
  getUserCalculatorByClassroomIdByProgramIdBySubPhaseIdProps,
} from '@xq/infrastructure';

export class GetUserCalculatorByClassroomIdByProgramIdBySubPhaseIdUseCase {
  private classroomRepository: ClassroomRepository;
  constructor() {
    this.classroomRepository = new ClassroomRepository();
  }

  async execute(
    payload: getUserCalculatorByClassroomIdByProgramIdBySubPhaseIdProps
  ) {
    return this.classroomRepository.getUserCalculatorByClassroomIdByProgramIdBySubPhaseId(
      payload
    )
  }
}
