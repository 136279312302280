import { LoadingStateService } from './LoadingStateService';

export class MyFilesLoadingStateService extends LoadingStateService {
  protected static instance: LoadingStateService;

  static getInstance() {
    if (!MyFilesLoadingStateService.instance) {
      MyFilesLoadingStateService.instance = new MyFilesLoadingStateService();
    }

    return MyFilesLoadingStateService.instance;
  }
}
