import { CSSProperties, PropsWithChildren } from 'react';
import AppBar from '@mui/material/AppBar';

type TopBarProps = {
  flexDirection?: string;
  className?: string;
  maxHeight?: string;
};

export function TopBar({
  children,
  flexDirection = 'row',
  className,
  maxHeight = 'calc(var(--vh) * 100)',
}: PropsWithChildren<TopBarProps>) {
  return (
    <AppBar
      style={
        {
          flexDirection: flexDirection,
          height: maxHeight,
        } as CSSProperties
      }
      position="static"
      className={className}
      data-cy="app-bar"
    >
      {children}
    </AppBar>
  );
}
