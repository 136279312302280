import { EmailErrors, InvalidEmail, ValidationTestResult } from './index';

type EmailDomainValidationProps = {
  domains: string[];
  newEmails: string[];
};

export const emailDomainValidation = ({
  newEmails,
  domains,
}: EmailDomainValidationProps): ValidationTestResult => {
  if (!domains.length) return { valid: newEmails, inValid: [] };

  const domainErrorEmails = newEmails.filter(
    (em) => !domains.some((domain) => em.endsWith(domain))
  );
  const invalidEmails: InvalidEmail[] = domainErrorEmails.map((email) => ({
    email,
    error: EmailErrors.invalid_domain,
  }));

  return {
    valid: newEmails.filter((em) =>
      domains.some((domain) => em.endsWith(domain))
    ),
    inValid: invalidEmails,
  };
};
