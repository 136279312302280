import { useEffect } from 'react';
import {
  doc,
  DocumentData,
  DocumentReference,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore';
import { CollectionNames } from '@xq/shared/data-access';

import {
  firebaseService,
  loggerService,
  subscriptionManager,
} from '@xq/infrastructure';
import { allEqual } from '@xq/shared/utils';

import { useAppDispatch } from '../store';
import { useMemoCompare } from './useMemoCompare';
import { followedUpUsersActions } from '../store/followed-up-users';

type Props = {
  userIds: string[];
  classroomId?: string;
  programId?: string;
};

export const useFollowedUpUserSubscription = ({
  userIds,
  classroomId,
}: Props) => {
  const dispatch = useAppDispatch();
  const shouldCreateQuery = useMemoCompare(userIds, (prev, next) => {
    if (!prev) {
      return false;
    }

    return allEqual(next, prev);
  });

  useEffect(() => {
    if (!classroomId) {
      return;
    }

    const queries = userIds.map((id: string) =>
      doc(
        firebaseService.db,
        `${CollectionNames.users}/${id}/${CollectionNames.followedUpUsersByClassroomId}/${classroomId}`
      )
    );

    const subscriptions: Unsubscribe[] = [];

    const subscribe = (query: DocumentReference<DocumentData>) => {
      const sub = onSnapshot(
        query,
        (snapshot) => {
          if (snapshot.exists() && snapshot.ref.parent.parent?.id) {
            const data = snapshot.data();
            const followedUpUsers = Object.entries(
              data?.byProgramId ?? {}
            ).reduce((previousValue, [programId, ids]) => {
              return {
                ...previousValue,
                [`${classroomId}-${programId}`]: ids,
              };
            }, {});

            dispatch(
              followedUpUsersActions.setFollowedUpUsers({
                userId: snapshot.ref.parent.parent.id,
                followedUpUsers,
              })
            );
          }
        },
        (e) => {
          loggerService.error('FOLLOWED UP USERS SUBSCRIPTION ERROR: ', e);
        }
      );

      subscriptions.push(sub);
    };

    queries.forEach(subscribe);
    subscriptionManager.add(subscriptions);

    return () => subscriptionManager.remove(subscriptions);
  }, [shouldCreateQuery, classroomId]);
};
