import { signOut } from 'firebase/auth';

import {
  StreamFeedService,
  firebaseService,
  subscriptionManager,
  NotificationFeedService,
} from '@xq/infrastructure';

import { UnsubscribeFromNotificationFeedUseCase } from '../Notifications/UnsubscribeFromNotificationFeedUseCase';

export class LogoutUserUseCase {
  static async execute(streamToken: string) {
    subscriptionManager.removeAll();
    await signOut(firebaseService.auth);

    const streamFeedService = StreamFeedService.getInstance(streamToken);

    if (streamFeedService.client) {
      const unsubscribe = UnsubscribeFromNotificationFeedUseCase.execute;
      unsubscribe(streamFeedService.client);
    }

    StreamFeedService.deleteInstance();
    NotificationFeedService.deleteInstance();
  }
}
