import { FeedService } from '@xq/infrastructure';
import { RealTimeMessage, StreamClient } from 'getstream';

export type HandleNewActivityProps = {
  feedId: string;
  data: RealTimeMessage;
};

export class SubscribeToFeedUseCase {
  static execute(
    client: StreamClient,
    callback: ({ data, feedId }: HandleNewActivityProps) => void,
    feedId: string
  ) {
    return FeedService.getInstance(client).subscribe((data) =>
      callback({ data, feedId })
    );
  }
}
