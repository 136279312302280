import {
  ClassroomsAdministrationScopes,
  AppsAccessScopes,
  ResourcesScopes,
  ClassroomsScopes,
  SchoolsScopes,
  SchoolsAdministrationScopes,
  NotificationsScopes,
  ChatScopes,
} from '../scopes';

export const adminScopes = [
  AppsAccessScopes.CAN_LOG_IN_TEACHER_APP,
  ClassroomsAdministrationScopes.CAN_VIEW,
  ClassroomsAdministrationScopes.CAN_CREATE_CLASSROOM_MANUALLY,
  ClassroomsAdministrationScopes.CAN_IMPORT_GOOGLE_CLASSROOM,
  ResourcesScopes.CAN_VIEW,
  ResourcesScopes.CAN_ASSIGN_MODULE_TO_CLASSROOM,
  ClassroomsScopes.CAN_VIEW_BY_SCHOOL,
  ClassroomsScopes.CAN_UPDATE_BY_SCHOOL,
  ClassroomsScopes.CAN_CREATE,
  SchoolsScopes.CAN_CREATE,
  SchoolsScopes.CAN_VIEW_OWN,
  SchoolsScopes.CAN_VIEW_BY_DOMAIN,
  SchoolsScopes.CAN_UPDATE_OWN,
  SchoolsScopes.CAN_UPDATE_BY_DOMAIN,
  SchoolsAdministrationScopes.CAN_VIEW,
  NotificationsScopes.CAN_VIEW,
  NotificationsScopes.CAN_DELETE,
  ChatScopes.CAN_VIEW,
];
