import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Phases,
  useAppSelector,
  selectUserById,
  selectCurrentUserClassroom,
  PhaseContextProvider,
  selectProgramPost,
  selectUserProgramProgress,
  selectCurrentUserProgram,
  MemoizedJournal,
  fetchUserProgramProgress,
  useAppDispatch,
  useNavigateToActivePhase,
  useStudentsProgramProgressSubscription,
  selectProgramsByIds,
} from '@xq/web-components';
import { getActivePhaseIndex } from '@xq/domain';
import { TEACHER_APP_ROUTES } from '@xq/web-config';
import { JournalTab } from '@xq/web-components/Journal/JournalTabs';

const PhasesAndJournal = () => {
  const { studentId = '' } = useParams();
  const dispatch = useAppDispatch();

  const [isJournalCollapsed, setIsJournalCollapsed] = useState(false);

  const [activeTabIndex, setActiveTabIndex] = useState(JournalTab.MyJournal);

  const currentStudent = useAppSelector((state) =>
    selectUserById(state, studentId)
  );
  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const classroom = useAppSelector(selectCurrentUserClassroom);
  const programPost = useAppSelector(selectProgramPost);
  const userProgramProgress = useAppSelector((state) =>
    selectUserProgramProgress(
      state,
      studentId,
      classroom?.id ?? '',
      currentProgram?.id ?? ''
    )
  );
  const programs = useAppSelector((state) =>
    selectProgramsByIds(state, classroom?.programIds || [])
  );

  const activePhaseIndex = useMemo(
    () => getActivePhaseIndex(programPost, userProgramProgress),
    [programPost, userProgramProgress]
  );

  useNavigateToActivePhase();

  useStudentsProgramProgressSubscription({
    userIds: [studentId],
    programs,
    classroom,
  });

  useEffect(() => {
    if (currentProgram && classroom?.id && programPost) {
      dispatch(
        fetchUserProgramProgress({
          slug: currentProgram.slug,
          classroomId: classroom.id,
          currentPhaseId: programPost.studentPhaseValues?.[0]?.id ?? 0,
          userId: studentId,
          programId: currentProgram.id,
        })
      );
    }
  }, [currentProgram, classroom?.id, programPost]);

  return (
    <>
      <PhaseContextProvider
        resourcePath={TEACHER_APP_ROUTES.getResourceContent}
        phasePath={(...args) =>
          TEACHER_APP_ROUTES.getJournalPhase(studentId, ...args)
        }
        activePhaseIndex={activePhaseIndex}
      >
        <Phases
          setActiveTabIndex={setActiveTabIndex}
          isJournalCollapsed={isJournalCollapsed}
        />
      </PhaseContextProvider>
      {currentStudent && (
        <MemoizedJournal
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          setIsJournalCollapsed={setIsJournalCollapsed}
          isJournalCollapsed={isJournalCollapsed}
          currentStudent={currentStudent}
        />
      )}
    </>
  );
};

export default PhasesAndJournal;
