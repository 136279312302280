import { WordPressPhaseContent } from './word-press-phase-content.dto';
import { ContentType } from '@xq/domain';

export enum Audiences {
  teacher = 'Teacher',
  student = 'Student',
}

export enum ContentTypes {
  main = 'Main',
  justInTime = 'Just in time',
  summary = 'summary',
  template = 'Template',
  wizard = 'Wizard',
}

export type WordPressCategoryName =
  | 'Phase I: Activate'
  | 'Phase II: Pitch'
  | 'Phase III: Prototype'
  | 'Phase IV: Finish and Share'
  | 'Phase V: Reflect'
  | 'Preparation'
  | 'Uncategorized';

export enum PhaseSlugs {
  Preparation = 'preparation',
  PhaseOne = 'phase-i-activate',
  PhaseTwo = 'phase-ii-pitch',
  PhaseThree = 'phase-iii-prototype',
  PhaseFour = 'phase-iv-finish-share',
  PhaseFive = 'phase-v-reflect',
  UnCategorized = 'uncategorized',
}

export type ACF = {
  program: string;
  type: ContentTypes;
  audience: Audiences;
  'parent-phase': string;
  'external-title': string;
  'sticky-post': boolean;
};

export interface WordPressCategory {
  id: number;
  count: number;
  description: string;
  link: string;
  name: WordPressCategoryName;
  slug: PhaseSlugs;
  taxonomy: string;
  patent: number;
  meta: string[];
  acf: ACF[];
}

export interface MapCategoryToPhasePayload {
  category: WordPressCategory;
  programSlug: string;
  programContent: WordPressPhaseContent[];
  contentType: ContentType.StudentContent | ContentType.PhaseContent;
}

export interface AssociateSubPhaseContentPayload {
  subPhase: WordPressPhaseContent;
  programSlug: string;
  programContent: WordPressPhaseContent[];
}

export interface FilterPhasePayload {
  audience: string;
  slug: PhaseSlugs;
}

export type WPTemplate = {
  audience: Audiences;
  program: string;
};

export type WPTemplateFile = {
  id: string;
  categories: number[];
};
