import { cond, isEmpty } from 'lodash';
import { z } from 'zod';

import { Phase } from './ProgramPhases/Phase';
import { IdProps } from './Interfaces';
import { SubPhase } from './ProgramPhases';

export const ProgramSubPhaseContentSchema = z.object({
  id: z.number(),
  subPhaseId: z.number(),
  isCompleted: z.boolean(),
});

export type ProgramSubPhaseContent = z.infer<
  typeof ProgramSubPhaseContentSchema
>;

export const ProgramSubPhaseSchema = z.object({
  id: z.number(),
  phaseId: z.number(),
  isCompleted: z.boolean(),
  content: z.record(z.string(), ProgramSubPhaseContentSchema).optional(),
});

export type ProgramSubPhase = z.infer<typeof ProgramSubPhaseSchema>;

export const ProgramPhaseSchema = z.object({
  id: z.number(),
  phaseName: z.string(),
  lastActionSubPhaseId: z.number().optional(),
  subPhases: z.record(z.string(), ProgramSubPhaseSchema),
});

export type ProgramPhase = z.infer<typeof ProgramPhaseSchema>;

export const ProgramProgressSchema = z.object({
  slug: z.string(),
  currentPhaseId: z.number().optional(),
  phases: z.record(z.string(), ProgramPhaseSchema),
  postsCount: z.number(),
  commentsCount: z.number(),
});

export type ProgramProgress = z.infer<typeof ProgramProgressSchema>;

export const isPhaseComplete = (
  phases: Phase[],
  phaseId: number,
  programProgress?: ProgramProgress
): boolean => {
  if (!programProgress || !programProgress?.phases) {
    return false;
  }

  const programPhase = phases.find((ph) => ph.id === phaseId);

  if (!programPhase) return false;

  const progressPhase = programProgress?.phases?.[phaseId];

  if (!progressPhase) return false;

  return Boolean(
    !programPhase?.subPhaseValues?.some((subPh) => {
      const progressSubPhase = progressPhase?.subPhases[subPh.id];
      return !progressSubPhase?.isCompleted;
    }) ||
      programPhase?.id <
        (programProgress?.currentPhaseId || programProgress?.phases?.[0]?.id)
  );
};

export const isSubPhaseComplete = (
  phaseId: number,
  subPhaseId: number,
  phases: Record<string, ProgramPhase>
): boolean => {
  const phase = phases[phaseId];
  const subPhase = phase?.subPhases[subPhaseId];

  return !!subPhase?.isCompleted;
};

export const isSubPhaseContentComplete = (
  phaseId: number,
  subPhaseId: number,
  subPhaseContentId: number,
  phases: Record<string, ProgramPhase>
): boolean => {
  const phase = phases[phaseId];
  const subPhase = phase?.subPhases[subPhaseId];

  return !!subPhase?.content?.[subPhaseContentId]?.isCompleted;
};

export const isPhaseCompleted = (
  phaseId: number,
  phases: Phase[],
  programPhases: Record<string, ProgramPhase>,
  completedSubPhaseId?: number
): boolean => {
  const phase = phases.find((ph) => ph.id === phaseId);
  const programPhase = programPhases[phaseId];

  if (!phase?.subPhaseValues) {
    return false;
  }

  return phase.subPhaseValues.every((supPhase) => {
    const programSubPhase = programPhase?.subPhases[supPhase.id];

    if (!programSubPhase) return completedSubPhaseId === supPhase.id;

    return (
      programSubPhase.isCompleted || programSubPhase.id === completedSubPhaseId
    );
  });
};

export const calcActivePhaseId = (
  phaseId: number,
  subPhaseId: number,
  postStudentPhases: Phase[],
  userProgramProgress: ProgramProgress,
  completed: boolean
): number => {
  if (phaseId !== userProgramProgress.currentPhaseId) {
    return userProgramProgress.currentPhaseId ?? 0;
  }

  const phaseCompleted = isPhaseCompleted(
    phaseId,
    postStudentPhases,
    userProgramProgress.phases,
    completed ? subPhaseId : undefined
  );

  const currentIndex = postStudentPhases.findIndex(
    (phase) => phase.id === userProgramProgress.currentPhaseId
  );
  let nextActivePhaseIndex = currentIndex;

  if (phaseCompleted) {
    for (let i = currentIndex + 1; i < postStudentPhases.length; i++) {
      if (i === postStudentPhases.length - 1) {
        nextActivePhaseIndex = i;
        break;
      }

      if (
        !isPhaseCompleted(
          postStudentPhases[i].id,
          postStudentPhases,
          userProgramProgress.phases
        )
      ) {
        nextActivePhaseIndex = i;
        break;
      }
    }
  }

  return postStudentPhases[nextActivePhaseIndex].id;
};

export const getProgramProgressId = ({
  userId,
  programId,
  classroomId,
}: IdProps) => `${userId}-${classroomId}-${programId}`;

export const isProgramProgressEmpty = (programProgress: ProgramProgress) =>
  Object.entries(programProgress).some(([key, value]) =>
    cond([
      [
        () =>
          Boolean(
            key === 'phases' &&
              !isEmpty(value) &&
              (Object.values(value) as ProgramPhase[]).find((p) =>
                Object.values(p.subPhases).find((s) => s.isCompleted)
              )
          ),
        () => true,
      ],
      [() => key === 'currentPhaseId', () => false],
      [
        () => (key === 'postsCount' || key === 'commentsCount') && value !== 0,
        () => true,
      ],
      [() => true, () => false],
    ])()
  );

export const getCompletedSubPhasesCount = (
  currentProgramProgress: ProgramProgress,
  currentPhaseId?: number
) =>
  currentPhaseId
    ? Object.values(
        currentProgramProgress?.phases?.[currentPhaseId]?.subPhases ?? {}
      ).filter?.((s) => s.isCompleted)?.length || 0
    : 0;

export const getCompletedSubphases = (
  currentProgramProgressSubPhases?: Record<string, ProgramSubPhase>,
  currentSubphases?: SubPhase[]
) => {
  return currentSubphases?.filter((subphase) =>
    Object.values(currentProgramProgressSubPhases ?? {}).some(
      (progress) => progress.id === subphase.id && progress.isCompleted
    )
  );
};
