import { styled } from '@mui/material/styles';

export const StyleTabContainer = styled('div')`
  display: grid;
  grid-template-rows: 1fr min-content;
  height: 100%;
  overflow: auto;
`;

export const StyledAccordionsContainer = styled('div')`
  overflow: auto;
  position: relative;
`;
