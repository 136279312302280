import { styled } from '@mui/material/styles';

export const StyledContainer = styled('div')`
  display: block;
  padding: 5px 16px 10px 16px;
  margin: 10px;
  border: 1px solid var(--alto);
  box-shadow: 0px -1px 0px 0px var(--athens-gray) inset;
`;

export const StyledRowContent = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const StyledTiltle = styled('p')`
  color: #1E1F20;
  padding: 0 15px;
  cursor: pointer;
`;

export const StyledDate = styled('div')`
  color: #898989;
  font-size: 10px;
`;

export const StyledBottomRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
