import { forwardRef, SyntheticEvent } from 'react';
import { Divider, styled } from '@mui/material';

import { SubPhase } from '@xq/domain';

import { XqAccordion } from '../../XqAccordion/XqAccordion';
import { XqAccordionContent } from '../../XqAccordion/XqAccordionContent';
import { selectIsPrinting, useAppSelector } from '../../store';
import { usePhase } from '../../context';

interface TeacherPhaseAccordionProps {
  label: string;
  opened: boolean;
  onAccordionToggle(event: SyntheticEvent, isOpened: boolean): void;
  isSubAccordionOpened: (index: number) => boolean;
  subPhase: SubPhase;
  rightAccordionHeader?: JSX.Element;
}

const StyledDivider = styled(Divider)`
  background: var(--green-primary);
  width: 5px;
  margin-right: 8px;
`;

export const TeacherPhaseAccordion = forwardRef<
  HTMLDivElement,
  TeacherPhaseAccordionProps
>(
  (
    {
      label,
      opened,
      onAccordionToggle,
      isSubAccordionOpened,
      subPhase,
      rightAccordionHeader,
    },
    ref
  ) => {
    const { hasExpandedSubphase } = usePhase();

    const isPrinting = useAppSelector(selectIsPrinting);

    if (isPrinting && !opened && hasExpandedSubphase) return null;

    return (
      <div ref={ref}>
        <XqAccordion
          label={label}
          opened={subPhase.isStickyPost || opened}
          disabled={subPhase.isStickyPost}
          onAccordionToggle={onAccordionToggle}
          left={<StyledDivider orientation="vertical" flexItem />}
          right={rightAccordionHeader}
        >
          <XqAccordionContent
            subPhase={subPhase}
            subAccordionLabel={rightAccordionHeader}
            isSubAccordionOpened={isSubAccordionOpened}
          />
        </XqAccordion>
      </div>
    );
  }
);
